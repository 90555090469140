<template>
  <div
    class="main-div"
    v-shortkey.once="{
      stepPrevious: ['arrowleft'],
      stepNext: ['arrowright'],
      close: ['esc'],
    }"
    @shortkey="onShortkey"
  >
    <div class="button-container">
          <div
            class="main-button-container"
            v-show="inputStep === 0 && !markerCategories"
          >
            <v-btn
              :disabled="!projectLoaded"
              :small="calcTransportStyle.hide"
              @click="$emit('request-drop-marker', local_persistentMarkerType)"
            >
              <v-icon
                :color="local_persistentMarkerType.color"
                size="1em"
                style="transform: rotate(45deg); margin-right: 8px"
                >mdi-square</v-icon
              >
              <div>Drop Marker</div>
            </v-btn>
          </div>
        <!-- <span>Drop a marker on the Current Location [ return ]</span> <br /> -->

      <div
        v-show="markerCategories === true"
        class="marker-edit-category-buttons-container"
      >
        <v-btn
          x-small
          v-for="(a, index) in commentTypes"
          :key="index"
          class="markertype elevation-5 rounded gray-border"
          :class="{ selected: isSelectedCommentType(commentTypes[index]) }"
          @click="onChangeCommentType(commentTypes[index])"
          :style="{
            background: commentTypes[index].color,
            color: commentTypes[index].textColor,
          }"
        >
          {{ commentTypes[index].name.substring(0, 3) }}
        </v-btn>
      </div>
      <div
        v-show="inputStep === 2 && !markerCategories"
        class="text-input-container"
      >
        <textarea
          ref="description"
          class="custom-textarea"
          :style="
            calcTransportStyle.shrink1
              ? { 'font-size': '0.8em', width: '90%' }
              : ''
          "
          :value="getMarkerDescription()"
          @input="onChangeMarkerDescription($event)"
          @keydown="handleKeydown($event)"
          @keyup="handleKeyup($event)"
          v-shortkey.once="{
            stepPrevious: ['arrowleft'],
            stepNext: ['arrowright'],
            close: ['esc'],
          }"
          @shortkey="onShortkey"
          placeholder="Write a comment"
        >
        </textarea>
      </div>

      <div class="small-button-container">
            <div class="small-button-container">
              <button
                :disabled="!projectLoaded"
                class="small-custom-button left-button"
                :style="{
                  'background-color': local_persistentMarkerType.color,
                }"
                @click="toggleShowCategories()"
              >
                <div class="icon-container">
                  <v-icon
                    size="1em"
                    v-if="
                      getComponentState() === 0 || getComponentState() === 2
                    "
                    >arrow_left</v-icon
                  >
                  <v-icon
                    size="1em"
                    v-if="
                      getComponentState() === 1 || getComponentState() === 3
                    "
                    >arrow_right</v-icon
                  >
                </div>
              </button>
            </div>
          <!-- <span>Open marker type selector [ 1-8 ]</span> -->

            <div class="small-button-container">
              <button
                :disabled="
                  getComponentState() === 0 || getComponentState() === 1
                "
                class="small-custom-button right-button"
                @click="saveMarker(true)"
              >
                <div class="icon-container">
                  <v-icon size="1em">keyboard_return</v-icon>
                </div>
              </button>
            </div>
          <!-- <span>Save the marker [ Return ]</span> -->
      </div>
    </div>
  </div>
</template>
<script>
// @ts-nocheck
export default {
  props: ['commentTypes', 'projectLoaded', 'calcTransportStyle'],
  data() {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      inputStep: 0,
      // local copies from selectedMarker
      local_persistentMarkerType: this.commentTypes[0],
      local_selectedMarker: undefined,
      markerCategories: false,
      shiftPressed: false,
    }
  },
  methods: {
    /* 
    ::::::::::::::Exported methods below::::::::::::::::::
    */

    getSelectedMarkerType() {
      return this.local_persistentMarkerType || this.commentTypes[0]
    },

    setSelectedMarkerType(markerType) {
      this.local_persistentMarkerType = markerType
    },

    selectMarker(event) {
      console.log('selctmarker', event)
      let markerType
      // [1] Save any data changed and deselect
      if (event.marker === undefined) {
        this.saveMarker()
        this.local_selectedMarker = undefined
        this.inputStep = 0
        return undefined
      }
      // [2] Save + Show description input and focus it
      else if (event.action === 'select') {
        markerType = Object.values(this.commentTypes).find((obj) => {
          return obj.name === event.marker.category_select
        })
        this.setSelectedMarkerType(markerType)
        this.saveMarker()
        this.local_selectedMarker = _.cloneDeep(event.marker)
        this.selectDescriptionInput(true)
        return this.local_selectedMarker
      }
      // [3] Show description input and focus it, save is not needed
      else if (event.action === 'open') {
        this.local_selectedMarker = _.cloneDeep(event.marker)
        this.selectDescriptionInput(true)
        return this.local_selectedMarker
      } else if (event.action === 'silent') {
        this.local_selectedMarker = _.cloneDeep(event.marker)
        this.selectDescriptionInput(false)
        return this.local_selectedMarker
      } else {
        console.warn('disregarding a marker selection', event)
        return undefined
      }
    },

    // called from parent component
    onShortkey(e) {
      console.log(e.srcKey)
      if (e.srcKey === 'stepPrevious') {
        this.$emit('request-step-through-markers', -1)
        return
      } else if (e.srcKey === 'stepNext') {
        this.$emit('request-step-through-markers', +1)
        return
      } else if (e.srcKey === 'close') {
        this.returnToFirstStep()
        return
      } else if (e.srcKey === 'dropMarker') {
        this.$emit('request-drop-marker', this.local_persistentMarkerType)
      } else {
        // silent marker button pressed
        let markerType = undefined

        if (e.srcKey === 'silentA') {
          markerType = this.getSelectedMarkerType()
        } else {
          markerType = Object.values(this.commentTypes).find((obj) => {
            return obj.name === e.srcKey
          })
          this.setSelectedMarkerType(markerType)
          console.log('selected markertype from shortkey', markerType)
        }
        this.$emit('request-drop-marker', markerType)
      }
    },

    /* 
    ::::::::::::::Not exported below::::::::::::::::::
    */
    handleKeydown(event) {
      console.log(event)
      if (event.key === 'Escape') {
        this.onShortkey({ srcKey: 'close' })
      }
      if (event.key === 'ArrowLeft') {
        this.onShortkey({ srcKey: 'stepPrevious' })
      }
      if (event.key === 'ArrowRight') {
        this.onShortkey({ srcKey: 'stepNext' })
      }
      if (event.key === 'Shift') {
        this.shiftPressed = true
        return
      }
      if (event.key === 'ArrowDown') {
        this.onShortkey({ srcKey: 'close' })
      }

      if (event.key === 'Enter') {
        if (this.shiftPressed) {
          // Allow newline
          this.onChangeMarkerDescription(event)
        } else {
          event.preventDefault()
          this.$emit('request-stop-propagation-of-enter-key', true)
          this.saveMarker(true)
        }
      }
    },
    handleKeyup(event) {
      if (event.key === 'Shift') {
        this.shiftPressed = false
      }
    },

    selectDescriptionInput(focusFlag) {
      this.inputStep = 2
      this.$nextTick(() => {
        if (focusFlag === true) {
          this.$refs.description.focus()
        }
      })
    },

    getMarkerDescription() {
      return this.local_selectedMarker?.description
    },

    isSelectedCommentType(markerType) {
      return this.local_persistentMarkerType === markerType
    },

    returnToFirstStep() {
      this.$emit('request-select-marker', {
        marker: undefined,
        action: 'close',
      })
      this.inputStep = 0
    },

    toggleShowCategories() {
      this.markerCategories = !this.markerCategories
    },

    onChangeCommentType(commentType) {
      this.local_persistentMarkerType = commentType

      if (this.getComponentState() > 1) {
        this.$emit('request-update-marker-category', {
          ['.key']: this.local_selectedMarker['.key'],
          category_select: commentType.name,
        })
        this.selectDescriptionInput(true)
      }
      this.inputStep =
        this.getComponentState() === 0 || this.getComponentState() === 1 ? 0 : 2
      this.markerCategories = false
    },

    onChangeMarkerDescription(e) {
      this.local_selectedMarker.description = e.target.value
    },

    /*
    STATES: 
    0. no marker selected - shows drop marker and marker color selector
    1. no marker selected - color selector open
    2. marker selected and color selector is closed
    3. marker selected and color selector is open 
    */
    getComponentState() {
      if (this.local_selectedMarker === undefined && !this.markerCategories) {
        return 0
      } else if (
        this.local_selectedMarker === undefined &&
        this.markerCategories
      ) {
        return 1
      } else if (
        this.local_selectedMarker !== undefined &&
        !this.markerCategories
      ) {
        return 2
      } else {
        return 3
      }
    },

    // saveMarker handles copying the local state to an object and saving it
    // any new selection from outside will save the previous marker and display the new data
    saveMarker(closeFlag) {
      if (this.local_selectedMarker === undefined) {
        return
      }
      this.local_selectedMarker.category_select =
        this.local_persistentMarkerType.name
      this.$emit('request-save-marker', this.local_selectedMarker)
      // intentionally keeping the state of the markerType
      // for marker type persistance during session
      this.local_selectedMarker.description = undefined
      if (closeFlag === true) {
        this.inputStep = 0
        this.local_selectedMarker = undefined
        this.$emit('request-hide-markerselector')
      }
    },
  },
}
</script>

<style lang="scss" sscoped>
@import '@/assets/global.scss';

.main-div {
  width: 210px;
  min-height: 50px;
  display: flex;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.main-button-container {
  height: 100%;
  width: 88%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-edit-category-buttons-container {
  height: 100%;
  width: 86%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.text-input-container {
  max-height: 2.8em;
  display: flex;
  flex-direction: column-reverse; /* Make it grow upwards */
}

.custom-textarea {
  color: $color-mx-white;
  background-color: $color-mx-panel; /* Gray background */
  border-radius: 4px;
  border: none;
  resize: none;
  min-height: 34px; /* Minimum height */
  overflow-y: auto;
  padding: 8px;
  margin: 1px 4px 0 2px;
}

// .small-button-container {
//   height: 100%;
//   width: 3em;
//   display: flex;
//   align-items: center;
//   border-radius: 4px;
// }
.small-button-container {
  height: 100%;
  width: 2.8em; /* Adjust the width if needed */
  display: flex;
  align-items: center;
}

.small-custom-button {
  flex: 1;
  border: none;
  height: 65%;
  cursor: pointer;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.left-button {
  height: 60%;
  max-width: 1em;
  border-radius: 4px 0 0 4px;
}

.right-button {
  border-radius: 0 4px 4px 0;
  min-width: 1.8em;
  background-color: $color-mx-panel;
}

.markertype {
  width: 16px;
  margin: 1.8px;
  color: $color-mx-black;
}

.selected {
  border: 2px solid white;
}
</style>
