/*
"MTC timing quarterLock" = got all 8 quarter frames
*/

const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const MTC_MMC_notes_Logic = require('../spotterfish_library/MTC_MMC_notes_Logic')
const MTC_MMC_notes_Nuendo = require('../spotterfish_library/MTC_MMC_notes_Nuendo')
const MTC_MMC_notes_ProTools = require('../spotterfish_library/MTC_MMC_notes_ProTools')
const Constants = require('../spotterfish_library/consts.js')
const Timecode = require('../spotterfish_library/utils/Timecode')
const MMC = require('../spotterfish_library/MMC')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const _ = require('lodash')

////////////////////////////////    MTC FRAME RATE

function frameRateToIndex (frameRate) {
  assert(frameRate === 24 || frameRate === 25 || frameRate === 29.97 || frameRate === 30)
  const rate = ({
    24: 0,
    25: 1,
    29.97: 2,
    30: 3
  })[frameRate]
  assert(rate !== undefined)
  return rate
}


// function resolveRateIndex(index){
//   if (index === 0) { return 24 }
//   if (index === 1) { return 25 }
//   if (index === 2) { return 29.97 }
//   if (index === 3) { return 30 }
//   assert(false)
//   return 0
// }

function getRateFrameDivision(index){
  if (index === 0) { return 24 }
  if (index === 1) { return 25 }
  if (index === 2) { return 30 }
  if (index === 3) { return 30 }
  assert(false)
  return 0
}



////////////////////////////////    MTC QUARTER FRAME



//  See MIDI SPECIFICATION 1.0 p.116

/*
30 fps => 120 QF/s = 8.3 ms

0xF1: MTC quarter frame message

"It is specifically noted that MIDI System Real-Time Messages may actually occur in the middle of other
messages in the input stream; in this case, the System Real-Time messages will be dispatched as
they occur, while the normal messages will be buffered until they are complete (and then dispatched)."
(single-byte messages)


F1 <message>
  System Common status byte

  0nnn dddd

  nnn
  Message Type:
  0 = Frame count LS nibble
  1 = Frame count MS nibble
  2 = Seconds count LS nibble
  3 = Seconds count MS nibble
  4 = Minutes count LS nibble
  5 = Minutes count MS nibble
  6 = Hours count LS nibble
  7 = Hours count MS nibble and SMPTE Type
  4 bits of binary data for this Message Type
*/

function checkQF(value){
  assert(SpotterfishCore.isObjectInstance(value))

  assert(_.isNumber(value.messageType) && value.messageType >= 0 && value.messageType < 8)
  assert(_.isNumber(value.data4) && value.data4 >= 0 && value.data4 < 16)
  return true
}

//  midi data as ArrayBuffer
//  Checks length and MTC QUARTER FRAME header
function isMTCQuarterFrame(buffer){
  assert(SpotterfishCore.isObjectInstance(buffer))

  if(buffer.byteLength === 2){
    const view = new DataView(buffer)

    const status = view.getUint8(0)
    return status === 0xf1
  }
  else { return false }
}
function prove__isMTCQuarterFrame0(){
  unitTestAssert(isMTCQuarterFrame(new Uint8Array([ 0xf0, 0x00 ]).buffer) === false)
}
function prove__isMTCQuarterFrame1(){
  unitTestAssert(isMTCQuarterFrame(new Uint8Array([ 0xf1, 0x00 ]).buffer) === true)
}


//  buffer is ArrayBuffer.
//  Throws if invalid format.
//  Expects isMTCQuarterFrame() to have passed
function unpackMTCQuarterFrame(buffer){
  assert(SpotterfishCore.isObjectInstance(buffer))
  assert(isMTCQuarterFrame(buffer))

  const view = new DataView(buffer)

  const message_0nnndddd = view.getUint8(1)
  const messageType = (message_0nnndddd & 0x70) >> 4
  const data4 = message_0nnndddd & 0xf

  const result = { messageType, data4 }
  assert(checkQF(result))
  return result
}

function prove__unpackMTCQuarterFrame0(){
  unitTestAssert(_.isEqual(
    { messageType: 0, data4: 0x0 },
    unpackMTCQuarterFrame(new Uint8Array([ 0xf1, 0x00 ]).buffer)
  ))
}
function prove__unpackMTCQuarterFrame1(){
  unitTestAssert(_.isEqual(
    { messageType: 7, data4: 0x0 },
    unpackMTCQuarterFrame(new Uint8Array([ 0xf1, 0x70 ]).buffer)
  ))
}
function prove__unpackMTCQuarterFrame2(){
  unitTestAssert(_.isEqual(
    { messageType: 0, data4: 0xf },
    unpackMTCQuarterFrame(new Uint8Array([ 0xf1, 0x0f ]).buffer)
  ))
}



////////////////////////////////    MTC FULL FRAME



function checkFullFrame(value){
  assert(SpotterfishCore.isObjectInstance(value))
  return true
}

//  midi data as ArrayBuffer
//  Checks length and MTC FULL FRAME sysex header
function isMTCFullFrame(buffer){
  assert(SpotterfishCore.isObjectInstance(buffer))

  if(buffer.byteLength === 10){
    const view = new DataView(buffer)

    const header0 = view.getUint8(0)
    const header1 = view.getUint8(1)
    const mtcSubID1 = view.getUint8(3)
    const mtcSubID2 = view.getUint8(4)
    const eox = view.getUint8(9)

    return header0 === 0xf0 && header1 === 0x7f && mtcSubID1 === 0x01 && mtcSubID2 === 0x01 && eox === 0xf7
  }
  else { return false }
}

//  buffer is ArrayBuffer.
//  Throws if invalid format.
//  Expects isMTCFullFrame() to have passed
function unpackMTCFullFrame(buffer){
  assert(SpotterfishCore.isObjectInstance(buffer))
  assert(isMTCFullFrame(buffer))

  const view = new DataView(buffer)

  const deviceID = view.getUint8(2)

  const hoursAndType_0yyzzzzz = view.getUint8(5)
  const minutes = view.getUint8(6)
  const seconds = view.getUint8(7)
  const frames = view.getUint8(8)

  if(deviceID === 0x7f && hoursAndType_0yyzzzzz < 0x80 && minutes < 0x80 && seconds < 0x80 && frames < 0x80){
    const type = (hoursAndType_0yyzzzzz & 0x60) >> 5
    const hours = hoursAndType_0yyzzzzz & 0x1f
    assert(type >= 0 && type < 4)

    if(hours < 24 && minutes < 60 && seconds < 60 && frames < 30){
      return { type, hours, minutes, seconds, frames }
    }
    else {
      throw Error('Invalid MTC full frame message')
    }
  }
  else {
    throw Error('Invalid MTC full frame message')
  }
}

function prove__unpackMTCFullFrame0(){
  unitTestAssert(_.isEqual(
    { type: 0, hours: 0, minutes: 0, seconds: 0, frames: 0 },
    unpackMTCFullFrame(new Uint8Array([ 0xf0, 0x7f, 0x7f, 0x01, 0x01, 0, 0, 0, 0, 0xf7 ]).buffer)
  ))
}
function prove__unpackMTCFullFrame1(){
  unitTestAssert(_.isEqual(
    { type: 3, hours: 23, minutes: 59, seconds: 59, frames: 29 },
    unpackMTCFullFrame(new Uint8Array([ 0xf0, 0x7f, 0x7f, 0x01, 0x01, (3 << 5) | 23, 59, 59, 29, 0xf7 ]).buffer)
  ))
}

//  Nuendo
//20:46:02.362  From IAC Driver Bus 1 SysEx   Universal Real Time 10 bytes  F0 7F 7F 01 01 61 00 00 00 F7
function prove__unpackMTCFullFrame_nuendo_01_00_00_00_30fps(){
  unitTestAssert(_.isEqual(
    { type: 3, hours: 1, minutes: 0, seconds: 0, frames: 0 },
    unpackMTCFullFrame(new Uint8Array([ 0xf0, 0x7f, 0x7f, 0x01, 0x01, 0x61, 0x00, 0x00, 0x00, 0xf7 ]).buffer)
  ))
}




////////////////////////////////    MTC ANALYSIS



//  Only responsible for tracking quarter frames.

/*
  type0   type
  0       1       +1
  1       2       +1
  2       3       +1
  3       4       +1
  4       5       +1
  5       6       +1
  6       7       +1
  7       0       -7
                
  0       7       +7
  7       6       -1
  6       5       -1
  5       4       -1
  4       3       -1
  3       2       -1
  2       1       -1
  1       0       -1
  0       7       +7

  1       2       +1
  2       3       +1
  3       0       -3    sync-unlock
  4       1       +1
  5       2       +1
*/

function checkDir(type, type0){
  assert(_.isNumber(type0) && type0 >= 0 && type0 < 8)
  assert(_.isNumber(type) && type >= 0 && type < 8)

  if(type0 === 7 && type === 0){
    return 'forward'
  }
  else if(type0 === 0 && type === 7){
    return 'backward'
  }

  const delta = type - type0
  if(delta === 1){
    return 'forward'
  }
  else if(delta === -1){
    return 'backward'
  }
  else {
    return 'sync-unlock'
  }
}

function unpack8QFPayloads(quarterFrames){
  const frameCount_xxxyyyyy = quarterFrames[0] | (quarterFrames[1] << 4)
  const secondsCount_xxyyyyyy = quarterFrames[2] | (quarterFrames[3] << 4)
  const minutesCount_xxyyyyyy = quarterFrames[4] | (quarterFrames[5] << 4)
  const hoursCount_xyyzzzzz = quarterFrames[6] | (quarterFrames[7] << 4)

  const hour = hoursCount_xyyzzzzz & 0x1f
  const timeCodeType = (hoursCount_xyyzzzzz >> 5) & 3

  const minute = minutesCount_xxyyyyyy & 0x3f
  const second = secondsCount_xxyyyyyy & 0x3f
  const frame = frameCount_xxxyyyyy & 0x1f

  return { timeCodeType, hour, minute, second, frame }
}

//  0xF1 96, 0xF1 118
//  0xF1 0x61, 0xF1 0x76
function prove__unpack8QFPayloads_30fps(){
  const result = unpack8QFPayloads([ 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x1, 0x6])

  console.dir(result, { depth: null })
  unitTestAssert(_.isEqual(result, { timeCodeType: 3, hour: 1, minute: 0, second:0, frame: 0 }))
}



function payloadToPosQF(pay){
  assert(SpotterfishCore.isObjectInstance(pay))

  const frameDivision = getRateFrameDivision(pay.timeCodeType)
  const posFrames = Timecode.assembleFrameIndex(pay.hour, pay.minute, pay.second, pay.frame, frameDivision)

  //  Timecode embeded in the 8 quarterframes represents the position of the FIRST of the 8 QFs, so are now 2 frames old
  const posQF = posFrames * 4 + (2 * 4)
  return posQF
}



////////////////////////////////    MTC ACCUMULATOR


function checkQuarterFrameAcc(acc){
  assert(SpotterfishCore.isObjectInstance(acc))
  return true
}

function makeQuarterFrameAcc(){
  const result = {
    //  #0 is qf0, #6 is qf6
    quarterFrames: [ undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined ],

    //  undefined / 'forward', 'backward', 'sync-unlock', 'relocate'
    dir: undefined,

    //  Store previous QF so we can detect direction
    qf: undefined,

    consecutiveCount: 0,

    //  Monotronically increasing for each QF
    qfIndex: 0,

    //  If the QF has run enough to collect all 8 QF:s, this contains that info.
    quarterLock: undefined
  }

  assert(checkQuarterFrameAcc(result))
  return result
}

function flushQFAcc(acc){
  assert(checkQuarterFrameAcc(acc))

  return {
    quarterFrames: [ undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined ],
    dir: undefined,
    qf: undefined,
    consecutiveCount: 0,
    qfIndex: acc.qfIndex,
    quarterLock: undefined
  }
}

function recordQF(acc, qf){
  assert(checkQuarterFrameAcc(acc))
  assert(checkQF(qf))

  let acc2 = _.cloneDeep(acc)

  let dir = acc2.qf !== undefined ? checkDir(qf.messageType, acc2.qf.messageType) : undefined

  acc2.quarterFrames[qf.messageType] = qf.data4

  if(dir === undefined || dir === 'sync-unlock'){
    //  Now we only trust the last QF
    acc2.consecutiveCount = 1
    acc2.quarterLock = undefined
  }
  else if(dir === 'forward'){
    acc2.consecutiveCount++

    //  NOTICE: The quarterFrames-array can ONLY be interpreted AT THIS MOMENT, when we:
    //    1) play forward, 2) get the last QF of the 8 and 3) have 8+ consecutive QF:s.
    if(qf.messageType === 7 && acc2.consecutiveCount >= 8){
      const payloads = unpack8QFPayloads(acc2.quarterFrames)
      const posQF = payloadToPosQF(payloads)
 
      const quarterLock = {
        //  Record *when* we got the quarterLock.
        qfIndex: acc.qfIndex,

        posQF: posQF,
        timeCodeType: payloads.timeCodeType
      }

      if(acc2.quarterLock !== undefined){
        //  Detect jumps in the embedded time code. Maybe a loop or an error.
        if(quarterLock.posQF - (2 * 4) !== acc2.quarterLock.posQF){
          dir = 'relocate'
        }
      }
      acc2.quarterLock = quarterLock
    }
  }
  else if(dir === 'backward'){
  }
  else {
    assert(false)
  }

  acc2.dir = dir
  acc2.qf = _.cloneDeep(qf)
  acc2.qfIndex++

  return acc2
}

function getAccOutput(acc){
  assert(checkQuarterFrameAcc(acc))

  //  Calculate the position based on the most current quarterLock, if any.
  if(acc.quarterLock !== undefined){
    const delta = (acc.qfIndex - 1) - acc.quarterLock.qfIndex
    return {
      posQF: acc.quarterLock.posQF + delta,
      frameRate: acc.quarterLock.timeCodeType
    }
  }
  else {
    return undefined
  }
}

function prove__quarterFrameAcc_scenario0(){
  let acc = makeQuarterFrameAcc()
  unitTestAssert(getAccOutput(acc) === undefined)

  acc = recordQF(acc, { messageType: 0, data4: 0 })
  acc = recordQF(acc, { messageType: 1, data4: 0 })
  acc = recordQF(acc, { messageType: 2, data4: 0 })
  acc = recordQF(acc, { messageType: 3, data4: 0 })

  acc = recordQF(acc, { messageType: 4, data4: 0 })
  acc = recordQF(acc, { messageType: 5, data4: 0 })
  acc = recordQF(acc, { messageType: 6, data4: 0 })
  acc = recordQF(acc, { messageType: 7, data4: 0 })

  const result = getAccOutput(acc)
  console.dir(acc, { depth: null })
  console.dir(result, { depth: null })
  unitTestAssert(_.isEqual(result, { posQF: 8, frameRate: 0 } ))
}

function prove__quarterFrameAcc_scenario1(){
  let acc = makeQuarterFrameAcc()
  unitTestAssert(getAccOutput(acc) === undefined)

  acc = recordQF(acc, { messageType: 0, data4: 10 })
  acc = recordQF(acc, { messageType: 1, data4: 0 })
  acc = recordQF(acc, { messageType: 2, data4: 11 })
  acc = recordQF(acc, { messageType: 3, data4: 0 })

  acc = recordQF(acc, { messageType: 4, data4: 12 })
  acc = recordQF(acc, { messageType: 5, data4: 0 })
  acc = recordQF(acc, { messageType: 6, data4: 13 })
  acc = recordQF(acc, { messageType: 7, data4: 0 })

  const result = getAccOutput(acc)
  console.dir(acc, { depth: null })
  console.dir(result, { depth: null })

  unitTestAssert(_.isEqual(
    acc,
    {
      quarterFrames: [
        10, 0, 11, 0,
        12, 0, 13, 0
      ],
      dir: 'forward',
      qf: { messageType: 7, data4: 0 },
      consecutiveCount: 8,
      qfIndex: 8,
      quarterLock: { qfIndex: 7, posQF: 4563024, timeCodeType: 0 }
    }
  ))
  assert(_.isEqual(result, { posQF: 4563024, frameRate: 0 } ))
}

function prove__quarterFrameAcc_scenario2(){
  let acc = makeQuarterFrameAcc()
  assert(getAccOutput(acc) === undefined)

  acc = recordQF(acc, { messageType: 0, data4: 10 })
  acc = recordQF(acc, { messageType: 1, data4: 0 })
  acc = recordQF(acc, { messageType: 2, data4: 11 })
  acc = recordQF(acc, { messageType: 3, data4: 0 })

  acc = recordQF(acc, { messageType: 4, data4: 12 })
  acc = recordQF(acc, { messageType: 5, data4: 0 })
  acc = recordQF(acc, { messageType: 6, data4: 13 })
  acc = recordQF(acc, { messageType: 7, data4: 0 })

  acc = recordQF(acc, { messageType: 0, data4: 12 })

  const result = getAccOutput(acc)
  console.dir(acc, { depth: null })
  console.dir(result, { depth: null })

  assert(_.isEqual(
    acc,
    {
      quarterFrames: [
        12, 0, 11, 0,
        12, 0, 13, 0
      ],
      dir: 'forward',
      qf: { messageType: 0, data4: 12 },
      consecutiveCount: 9,
      qfIndex: 9,
      quarterLock: { qfIndex: 7, posQF: 4563024, timeCodeType: 0 }
    }
  ))
  assert(_.isEqual(result, { posQF: 4563025, frameRate: 0 } ))
}

function prove__quarterFrameAcc_scenario3(){
  let acc = makeQuarterFrameAcc()
  unitTestAssert(getAccOutput(acc) === undefined)

  for(let i = 0; i < 5 ; i++){

    //  TWO frames
    acc = recordQF(acc, { messageType: 0, data4: 3 + i * 2 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 1, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 2, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 3, data4: 0 })




    acc = recordQF(acc, { messageType: 4, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 5, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 6, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })

    acc = recordQF(acc, { messageType: 7, data4: 0 })
    console.dir({ acc, output: getAccOutput(acc) }, { depth: null })
  }
}




////////////////////////////////    SyncSnapshot




function checkInvariantSyncSnapshot (value) {
  assert(SpotterfishCore.isObjectInstance(value))

  assert(_.isBoolean(value.playFlag))

  //  Position specified in number-of-frames with a decimal (fractional frames). Can be undefined
  assert(_.isNumber(value.posFramesDecimal) && value.posFramesDecimal >= 0)
  assert(_.isNumber(value.mtcFrameRateIndex) && value.mtcFrameRateIndex >= 0 && value.mtcFrameRateIndex < 4)
  return true
}





////////////////////////////////    SyncAcc




//  Complete handling of MTC, includuding QF and full messages

function checkInvariantSyncAcc (value) {
  assert(SpotterfishCore.isVueReactive(value) === false)
  assert(SpotterfishCore.isObjectInstance(value))
  assert(checkInvariantSyncSnapshot(value.syncSnapshot))
  assert(checkQuarterFrameAcc(value.mtcAcc))

  assert(value.fullframe === undefined || SpotterfishCore.isObjectInstance(value.fullframe))

  return true
}

function makeMTCSyncAcc(){
  const result = {

    //  Output for clients
    syncSnapshot: {
      playFlag: false,

      posFramesDecimal: 0,

      // default to 24 fps
      mtcFrameRateIndex: 0
    },

    mtcAcc: makeQuarterFrameAcc(),

    /*
      {
        posQF,
        timeCodeType,

        //  *when* we set the fullframe. Unit is QF index since creation of syncAcc.
        qfIndex
        }
    */
    fullframe: undefined 
  }
  return result
}

function calcPlayback(mtcAcc, fullframe){
  if(mtcAcc.quarterLock !== undefined){
    const deltaQFs = (mtcAcc.qfIndex - 1) - mtcAcc.quarterLock.qfIndex
    return {
      frameRate: mtcAcc.quarterLock.timeCodeType,
      posQF: mtcAcc.quarterLock.posQF + deltaQFs
    }
  }
  else if(fullframe !== undefined){
    const deltaQFs = (mtcAcc.qfIndex - 1) - fullframe.qfIndex
    return {
      frameRate: fullframe.timeCodeType,
      posQF: fullframe.posQF + deltaQFs
    }
  }
  else {
    return undefined
  }
}


// ??? Clear fullframe on unlock
function onSyncAccQuarterFrame(syncAcc0, qf){
  assert(checkInvariantSyncAcc(syncAcc0))
  assert(checkQF(qf))

/*
  //  When we get a QF and there is a pending fullframe / relocated,
  //  we START and assume the QF represents the fullframe. If timecode in QF later mismatches, that's handled as a relocate
  if(syncAcc0.fullframe !== undefined){
    //  According to MTC spec, first QF after full frame shall be #0
    if(qf.messageType !== 0){
      console.log('MTC quarter frame expected to be #0 after full frame')
    }
  }
*/

  const mtcAcc = recordQF(syncAcc0.mtcAcc, qf)

  const posAndFR = calcPlayback(mtcAcc, syncAcc0.fullframe)
  const syncSnapshot = posAndFR !== undefined
    ? {
        playFlag: true,
        posFramesDecimal: posAndFR.posQF / 4.0,
        mtcFrameRateIndex: posAndFR.frameRate
      }
    : {
        playFlag: false,
        posFramesDecimal: syncAcc0.syncSnapshot.posFramesDecimal,
        mtcFrameRateIndex: syncAcc0.syncSnapshot.mtcFrameRateIndex
      }

  const fullframe = mtcAcc.quarterLock !== undefined ? undefined : syncAcc0.fullframe

  const syncAcc = {
    syncSnapshot: syncSnapshot,
    mtcAcc: mtcAcc,
    fullframe: fullframe
  }
  assert(checkInvariantSyncAcc(syncAcc))
  return syncAcc
}

// ??? Using (fullframe.qf_index - mtcAcc.qfIndex) could be unreliable if mtcAcc starts running backwards.

function onSyncAccFullframe(syncAcc0, posFramesDec, mtcType){
  assert(checkInvariantSyncAcc(syncAcc0))
  assert(_.isNumber(posFramesDec))
  assert(_.isNumber(mtcType) && mtcType >= 0 && mtcType < 4)

  const fullframe = { posQF: posFramesDec * 4.0, timeCodeType: mtcType, qfIndex: syncAcc0.mtcAcc.qfIndex }

  // IDEA: separate QFindex | mtchAcc so we just make a new mtcAcc here.
  const mtcAcc = flushQFAcc(syncAcc0.mtcAcc)

  const syncAcc = {
    syncSnapshot: {
      playFlag: false,
      posFramesDecimal: fullframe.posQF / 4.0,
      mtcFrameRateIndex: fullframe.timeCodeType
    },
    mtcAcc: mtcAcc,
    fullframe: syncAcc0.fullframe
  }
  assert(checkInvariantSyncAcc(syncAcc))
  return syncAcc
}

function onSyncAccStop(syncAcc0){
  assert(checkInvariantSyncAcc(syncAcc0))

  const mtcAcc = flushQFAcc(syncAcc0.mtcAcc)

  const syncAcc = {
    syncSnapshot: {
      playFlag: false,
      posFramesDecimal: syncAcc0.syncSnapshot.posFramesDecimal,
      mtcFrameRateIndex: syncAcc0.syncSnapshot.mtcFrameRateIndex
    },
    mtcAcc: mtcAcc,
    fullframe: syncAcc0.fullframe
  }
  assert(checkInvariantSyncAcc(syncAcc))
  return syncAcc
}


////////////////////////////////    LOGGING TABLES


//  INPUT: array of array of strings
//  RETURN: array of table-widths
function measureMatrixColumnWidths(m){
  assert(_.isArray(m))

  const columnCount = m[0].length

  let result = new Array(columnCount).fill(0);
  assert(result.length === columnCount)

  for(let i = 0; i < m.length; i++){
    const line = m[i]
    assert(_.isArray(line))
    assert(line.length === columnCount)

    for(let columnIndex = 0; columnIndex < columnCount ; columnIndex++){
      const cell = line[columnIndex]
      assert(_.isString(cell))

      result[columnIndex] = Math.max(result[columnIndex], cell.length)
    }
  }
  return result
}

function prove_measureMatrixColumnWidths(){
  const result = measureMatrixColumnWidths([ ['1', 'Wednesday'], [ '2', 'Now'] ])
  console.dir(result, { depth: null })
  unitTestAssert(_.isEqual(result, [ 1, 9]))
}

function logMatrix(m){
  const columnWidths = measureMatrixColumnWidths(m)
  for(let i = 0; i < m.length; i++){
    const line = m[i]

    let lineAcc = ''
    for(let columnIndex = 0; columnIndex < columnWidths.length ; columnIndex++){
      const s = line[columnIndex]
      const s2 = s.padEnd(columnWidths[columnIndex], ' ')
      lineAcc = lineAcc + '|' + s2
    }
    console.log(lineAcc)
  }
}


////////////////////////////////    PARSE MIDI MONTOR REPORTS



function skip(s, wanted){
  assert(_.isString(s))
  assert(_.isString(wanted))

  if(s.startsWith(wanted)){
    const len = wanted.length
    return s.substring(len)
  }
  else {
    return undefined
  }
}

function prove_skip0(){
  const result = skip('MTC Quarter Frame   8', 'MTC Quarter Frame')
  console.dir(result, { depth: null })
  unitTestAssert(result === '   8')
}


function parseMTCQuarterFrameString(more, mode){
  assert(_.isString(more))

  const data0 = parseSysex(more, mode)
  const data = data0.filter(e => e !== undefined)
  if(data[0] === 0xf1){
    return data
  }
  else {
    return [0xf1].concat(data)
  }
}

function prove_parseMTCQuarterFrameString(){
  const result = parseMTCQuarterFrameString('   8', 'hex')
  console.dir(result, { depth: null })

  unitTestAssert(_.isEqual(result, [ 0xf1, 0x08 ]))
}


function parseSysex(more, mode){
  const strs = more.trim().split(' ').filter(e => e !== undefined)
  const values = strs.map(e => parseInt(e, mode === 'hex' ? 16 : 10))
  return values
}

//  returns string up until first whitespace
function readUntilWhitespace(s){
  for(let i = 0; i < s.length; i++){
    const ch = s[i]
    if(ch === ' ' || ch === '\t'){
      return s.substring(0, i)
    }
  }
  return ''
}

function prove_readUntilWhitespace0(){
  const result = readUntilWhitespace('')
  unitTestAssert(result === '')
}
function prove_readUntilWhitespace1(){
  const result = readUntilWhitespace('abc def')
  console.dir(result, { depth: null })
  unitTestAssert(result === 'abc')
}
function prove_readUntilWhitespace2(){
  const result = readUntilWhitespace('abc\tdef')
  console.dir(result, { depth: null })
  unitTestAssert(result === 'abc')
}
function prove_readUntilWhitespace3(){
  const result = readUntilWhitespace('abc  \t  \t\tdef')
  console.dir(result, { depth: null })
  unitTestAssert(result === 'abc')
}


function parseTimeStamp(s){
/*
  if((skip(s, '0 ') !== undefined || skip(s, '0\t') !== undefined)){
    return s.substring(0, 1)
  }
  else {
  const spaceIndex = s.indexOf(' ')
  const tabIndex = s.indexOf('\t')
  const index = Math.max(spaceIndex, tabIndex)
  assert(index != -1)

  const t = s.substring(0, index)
*/
  return readUntilWhitespace(s)
  //const time = (skip(s, '0 ') !== undefined || skip(s, '0\t') !== undefined) ? s.substring(0, 1) :  s.substring(0, 12)
}


function parseMIDIMonitorLineSimple(s, bus, mode){
  const time = parseTimeStamp(s)
  const timeRest = s.substring(time.length)
  const m0 = skip(timeRest.trim(), bus)

  if(false){
    console.log('s:', s, ', time:', time, ', timeRest:', timeRest, ', m0:', m0)
  }


  assert(m0 !== undefined)
  const m = m0.trim()

  const mtcQF = skip(m, 'MTC Quarter Frame')
  if(mtcQF !== undefined){
    return { time: time, midi: parseMTCQuarterFrameString(mtcQF, mode) }
  }

  const sysexMore = skip(m, 'SysEx')
  if(sysexMore !== undefined){
    const mmcLocateFormat2 = skip(sysexMore.trim(), 'Universal Real Time 13 bytes')
    if(mmcLocateFormat2 !== undefined){
      return { time: time, midi: parseSysex(mmcLocateFormat2.trim(), mode) }
    }

    const rawSysex = skip(sysexMore.trim(), 'F0')
    if(rawSysex !== undefined){
      const data = parseSysex(sysexMore.trim(), mode)
      //0 From IAC Driver Bus 1 SysEx   F0 7F 7F 01 01 61 2B 35 08 F7
      return { time: time, midi: data }
    }

    else {
      console.log('Unknown sysex')
    }
  }

  return undefined
}

function prove_parseMIDIMonitorLineSimple(){
  console.log('prove_parseMIDIMonitorLineSimple')
  const result = parseMIDIMonitorLineSimple("12:23:46.719  To IAC Driver Bus 1 MTC Quarter Frame   8", 'To IAC Driver Bus 1', 'dec')

  console.log('xxx')
  console.dir(result, { depth: null })

  unitTestAssert(_.isEqual(result, { time: '12:23:46.719', midi: [ 0xf1, 0x08 ] }))
}

function prove_parseMIDIMonitorLineSimple2(){
  const result = parseMIDIMonitorLineSimple(
    "12:18:44.368 To IAC Driver Bus 1 SysEx   Universal Real Time 13 bytes  F0 7F 7F 06 44 06 01 61 00 00 20 00 F7",
    'To IAC Driver Bus 1',
    'hex'
  )

  console.log('yyy')
  console.dir(result, { depth: null })

  unitTestAssert(_.isEqual(result, { time: '12:18:44.368', midi: [ 0xf0, 0x7F, 0x7F, 0x06, 0x44, 0x06, 0x01, 0x61, 0x00, 0x00, 0x20, 0x00, 0xF7 ] }))
}




function prove__DAWtestInclude(){
  unitTestAssert(MTC_MMC_notes_Logic.kLogic_playingShortLoop !== undefined)
  unitTestAssert(MTC_MMC_notes_Logic.kLogic_locates !== undefined)
  unitTestAssert(MTC_MMC_notes_Logic.kLogic_playLinearAndStop !== undefined)
  unitTestAssert(MTC_MMC_notes_Logic.kLogic_loop2 !== undefined)

  unitTestAssert(MTC_MMC_notes_Nuendo.kNuendo_stopProblem !== undefined)
  unitTestAssert(MTC_MMC_notes_Nuendo.kNuendo_playingShortLoop !== undefined)
  //unitTestAssert(MTC_MMC_notes_Nuendo.kNuendo_locates !== undefined)
  unitTestAssert(MTC_MMC_notes_Nuendo.kNuendo_playLinearAndStop !== undefined)

  unitTestAssert(MTC_MMC_notes_ProTools.kProTools_playingShortLoop !== undefined)
  unitTestAssert(MTC_MMC_notes_ProTools.kProTools_locates !== undefined)
  unitTestAssert(MTC_MMC_notes_ProTools.kProTools_playLinearAndStop !== undefined)
  unitTestAssert(MTC_MMC_notes_ProTools.kProTools_loop2 !== undefined)
}

function decorateMIDIEvents(midiEvents){
  const midiEvents2 = midiEvents.map(
    function(e) {
      const midi2 = new Uint8Array(e.midi).buffer
      if(isMTCQuarterFrame(midi2)){
        const qf = unpackMTCQuarterFrame(midi2)
        return { type: 'MTC-QF', contents: qf }
      }
      else if(e.midi[0] === 0xf0){
        const sysex = midi2

        if(isMTCFullFrame(sysex)){
          const full = unpackMTCFullFrame(sysex)
          return { type: 'MTC-Fullframe', contents: full }
        }

        //  MMC?
        else if(MMC.isMMCCommand(sysex)){
          const mmcCommand = MMC.extractMCCCommandBody(sysex)
          if(MMC.isMMCCommand_STOP(mmcCommand)){
            return { type: 'MMC-STOP', contents: undefined }
          }

          //  LOCATEFormat2?
          else if(MMC.isMMCCommand_LOCATEFormat2(mmcCommand)){
            const loc = MMC.unpackMMCCommand_LOCATEFormat2(mmcCommand)
            return { type: 'MMC-LOCATE-f2', contents: loc }
          }
          else {
            assert(false)
          }
        }
        else {
          assert(false)
        }
      }
      else {
        assert(false)
      }
      return { type: 'UNKNOWN', contents: undefined }
    }
  )
  return midiEvents2
}

const zip = (a, b) => a.map((k, i) => [k, b[i]])


function processEvents(syncAcc0, midiEvents){
  let syncAcc = _.cloneDeep(syncAcc0)
  for(let i = 0 ; i < midiEvents.length ; i++){
    const event = midiEvents[i]
    assert(event !== undefined)

    //@ts-ignore
    const midi2 = new Uint8Array(event.midi).buffer

    //@ts-ignore
    if(event.midi[0] === 0xf0){
      syncAcc = onSysex(midi2, syncAcc)
    }

    //@ts-ignore
    else if(event.midi[0] === 0xf1){
      syncAcc = onMTCQuarterFrame(midi2, syncAcc)
    }
    else {
      console.log(event)
      console.log(midi2)
      assert(false)
    }
  }
  return syncAcc
}




/*
  if(false){
    console.log('MIDI EVENTS')
    midiEvents.map(e => console.log(e))
    console.log('---')
  }

  if(false){
    console.log('MIDI EVENTS2')
    //console.dir(midiEvents2, { depth: null })
    console.table(midiEvents2)
    //midiEvents2.map(e => console.dir(e, { depth: null }))
    console.log('---')
  }
*/
  //const report = lines.map((e, i) => [e, JSON.stringify(midiEvents[i]), JSON.stringify(decore[i]), JSON.stringify(result[i])])
  //const report = lines.map((e, i) => [e, midiEvents[i], decore[i], result[i]])

function executeTestReport(reportStr, bus, mode){
  const lines = reportStr.trim().split('\n')
  const midiEvents = lines.map(e => parseMIDIMonitorLineSimple(e, bus, mode))
  const decore = decorateMIDIEvents(midiEvents)

  const s0 = makeMTCSyncAcc()
  let sAcc = s0
  const result = midiEvents.map(
    function(e) {
      sAcc = processEvents(sAcc, [ e ])
      return sAcc
    }
  )

  const report = lines.map(
    function(e, i){
      const delta = i > 0 ? result[i].syncSnapshot.posFramesDecimal - result[i - 1].syncSnapshot.posFramesDecimal : -1
      return [
        i.toString(),
        delta.toString(),
        JSON.stringify(decore[i]),
        JSON.stringify(result[i].syncSnapshot),
        JSON.stringify(result[i].mtcAcc),
        result[i].fullframe !== undefined ? JSON.stringify(result[i].fullframe) : ''
      ]
    }
  )
  return {
    lines: lines,
    midiEvents: midiEvents,
    decore: decore,
    s0: s0,
    report: report,
    s: sAcc
  }
}


function prove__Nuendo_stopProblem(){
  console.log('prove__Nuendo_stopProblem()')

  const report = executeTestReport(MTC_MMC_notes_Nuendo.kNuendo_stopProblem, 'From IAC Driver Bus 1', 'hex')
  logMatrix(report.report)
  console.dir(report.s, { depth: null })

  //assert(false)
}


/*
  When Pro Tools plays in a loop, it generates MTC that is monotonically incrementing.
  This means the MTC does not have enough info for Spotterfish to loop along.

  https://duc.avid.com/showthread.php?t=237091
      Old 01-20-2009, 11:22 AM
      sinukus sinukus is offline
      Member
        
      Join Date: May 2004
      Location: San Francisco, CA
      Posts: 159
      Default Re: PT and looping to MTC
      Note that he is talking about MTC not MBC.

      PT doesn't loop MTC ever, never has.

      S
      __________________
      Below Zero Beats on 92.7 FM San Francisco
      Sundays 8pm - Midnight
      http://belowzerobeats.com
      Reply With Quote
*/
function prove__ProTools_loop2(){
  console.log('prove__ProTools_loop2()')

  const report = executeTestReport(MTC_MMC_notes_ProTools.kProTools_loop2, 'From IAC Driver Bus 1', 'hex')
  logMatrix(report.report)
  console.dir(report.s, { depth: null })

//  assert(false)
}


// data is ArrayBuffer
function onMTCQuarterFrame(data, syncAcc0){
  assert(SpotterfishCore.isObjectInstance(data))
  assert(checkInvariantSyncAcc(syncAcc0))

  const qf = unpackMTCQuarterFrame(data)
  return onSyncAccQuarterFrame(syncAcc0, qf)
}

// sysex is ArrayBuffer
function onSysex(sysex, syncAcc0) {
  assert(SpotterfishCore.isObjectInstance(sysex))
  assert(checkInvariantSyncAcc(syncAcc0))

  //  MTC fullframe?
  if(isMTCFullFrame(sysex)){
    const full = unpackMTCFullFrame(sysex)
    const frk = Timecode.mtcFrameRateIndexToKey(full.type)
    const frkInfo = Timecode.kFrameRateKeyCatalog[frk]
    const posFrames = Timecode.assembleFrameIndex(full.hours, full.minutes, full.seconds, full.frames, frkInfo.frameDivision)
    return onSyncAccFullframe(syncAcc0, posFrames, full.type)
  }

  //  MMC?
  else if(MMC.isMMCCommand(sysex)){
    const mmcCommand = MMC.extractMCCCommandBody(sysex)
    if(MMC.isMMCCommand_STOP(mmcCommand)){
      return onSyncAccStop(syncAcc0)
    }

    //  LOCATEFormat2?
    else if(MMC.isMMCCommand_LOCATEFormat2(mmcCommand)){
      const loc = MMC.unpackMMCCommand_LOCATEFormat2(mmcCommand)
      const frk = Timecode.mtcFrameRateIndexToKey(loc.timeType)
      const frkInfo = Timecode.kFrameRateKeyCatalog[frk]
      const frameIndex = Timecode.assembleFrameIndex(
        loc.hours,
        loc.minutes,
        loc.seconds,
        loc.frames + (loc.fractionalFrames / 100.0),
        frkInfo.frameDivision
      )

      return onSyncAccFullframe(syncAcc0, frameIndex, loc.timeType)
    }
  }
  else {
  }

  //  NOP
  return syncAcc0
}





function runUnitTests () {
  console.log('MTC -- start')

  prove__isMTCQuarterFrame0()
  prove__isMTCQuarterFrame1()

  prove__unpackMTCQuarterFrame0()
  prove__unpackMTCQuarterFrame1()
  prove__unpackMTCQuarterFrame2()

  prove__unpackMTCFullFrame0()
  prove__unpackMTCFullFrame1()
  prove__unpackMTCFullFrame_nuendo_01_00_00_00_30fps()

  prove__unpack8QFPayloads_30fps()

  prove__quarterFrameAcc_scenario0()
  prove__quarterFrameAcc_scenario1()
  prove__quarterFrameAcc_scenario2()
  prove__quarterFrameAcc_scenario3()

  prove_measureMatrixColumnWidths()


  prove_readUntilWhitespace0()
  prove_readUntilWhitespace1()
  prove_readUntilWhitespace2()
  prove_readUntilWhitespace3()

  prove_skip0()
  prove_parseMTCQuarterFrameString()

  prove_parseMIDIMonitorLineSimple()
  prove_parseMIDIMonitorLineSimple2()

  prove__DAWtestInclude()

  prove__Nuendo_stopProblem()
  prove__ProTools_loop2()

  console.log('MTC -- end')
}

module.exports = {
  frameRateToIndex,
  getRateFrameDivision,

  checkQF,
  isMTCQuarterFrame,
  unpackMTCQuarterFrame,

  checkFullFrame,
  isMTCFullFrame,
  unpackMTCFullFrame,

  makeQuarterFrameAcc,
  checkQuarterFrameAcc,
  recordQF,

  checkInvariantSyncSnapshot,

  checkInvariantSyncAcc,
  makeMTCSyncAcc,
  onSyncAccQuarterFrame,
  onSyncAccFullframe,
  onSyncAccStop,

  onMTCQuarterFrame,
  onSysex,

  runUnitTests
}
