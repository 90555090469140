const Timecode = require('../utils/Timecode')

const SpotterfishCore = require('../SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert
const compareObjects = SpotterfishCore.compareObjects

const MimeTypes = require('./MimeTypes')
const _ = require('lodash');

function resolveVideoAndAudioFiles (videoList, audioList) {
  // Returns an object with one main video object and 0-3 additional audio objects for
  // use in the video player and associated audio players. The passed video list can
  // only contain one item at the moment, the audio from that video file is used as
  // main audio too unless the video file is not present in the list of audio files
  // since that means the user has deselected the video audio.

  /**
   * @type {{
   *   mainObject: {src: string, type: string} | null,
   *   mainAudioMuted: boolean,
   *   additionalObjects: Array<{src: string, type: string}>
   * }}
   */
  const av = {
    mainObject: null,
    mainAudioMuted: false,
    additionalObjects: []
  }

  if ((videoList.length === 0 && audioList.length === 0) || (!videoList[0] && !audioList[0])) {
    av.mainAudioMuted = true
    return av
  }

  let shouldMute = true

  // Check if we got a video file and if so, set it as the main object. Note that
  // passing more than one video will keep the main object null, we don't support
  // multiple videos at the moment.
  if (videoList.length === 1) {
    av.mainObject = {
      src: videoList[0].src,
      type: videoList[0].metadata.contentType
    }
  } else if (videoList.length === 0) {
    for (const audio of Object.values(audioList)) {
      const contentType = MimeTypes.mimes.find(x => x.m === audio.metadata.contentType)
      if (!contentType) {
        throw new Error(`Unsupported content type ${audio.metadata.contentType}`)
      }
      if (contentType.hasVideo) {
        av.mainObject = {
          src: audio.src,
          type: audio.metadata.contentType
        }
      }
    }
    // We didn't find any video file among the audio files so just set the main object
    // to the first audio file in the list.
    if (av.mainObject === null) {
      av.mainObject = {
        src: audioList[0].src,
        type: audioList[0].metadata.contentType
      }
      // Remove the audio file we just added so it won't show up as additional too.
      audioList.shift()
      shouldMute = false
    }
  }

  // Check if the video file is also passed as an audio file. If the video is not
  // present it means its audio was unselected and should be muted.
  for (const audio of Object.values(audioList)) {
    if (!av.mainObject) {
      throw new Error('No main AV object even though there are audio files')
    }
    if (audio.src === av.mainObject.src) {
      shouldMute = false
    }
  }
  av.mainAudioMuted = shouldMute

  // This means there are audio files but none of them are the video file, so they
  // should be added as additional tracks.
  for (const audio of Object.values(audioList)) {
    if (!av.mainObject) {
      throw new Error('No main AV object even though there are audio files')
    }
    if (audio.src !== av.mainObject.src) {
      av.additionalObjects.push({
        src: audio.src,
        type: audio.metadata.contentType
      })
    }
  }

  return av
}

function runUnitTests () {
  console.log('VideoPlayerUtils.js -- START')

  let videoList
  let audioList
  let av

  // 0 video, 0 audio -> main object null and muted, 0 additional objects
  videoList = []
  audioList = []
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: null,
    mainAudioMuted: true,
    additionalObjects: []
  }))

  // 1 video, 1 audio (same as video) -> main object unmuted, 0 additional objects
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: false,
    additionalObjects: []
  }))

  // 1 video, 0 audio -> main object muted, 0 additional objects
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = []
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: true,
    additionalObjects: []
  }))

  // 0 video, 1 audio (is video type) -> main object unmuted (audio file), 0 additional objects
  videoList = []
  audioList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: false,
    additionalObjects: []
  }))

  // 0 video, 1 audio (is audio type) -> main object unmuted (audio file), 0 additional objects
  videoList = []
  audioList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'audio/wav'},
    mainAudioMuted: false,
    additionalObjects: []
  }))

  // 1 video, 1 audio (not same as video) -> main object muted, 1 additional object
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/another/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: true,
    additionalObjects: [
      {src: '/another/path', type: 'audio/wav'}
    ]
  }))

  // 1 video, 2 audio (one is same as video) -> main object unmuted, 1 additional object
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}},
    {src: '/another/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: false,
    additionalObjects: [
      {src: '/another/path', type: 'audio/wav'}
    ]
  }))

  // 1 video, 2 audio (none same as video) -> main object muted, 2 additional objects
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/yet/another/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: true,
    additionalObjects: [
      {src: '/another/path', type: 'audio/wav'},
      {src: '/yet/another/path', type: 'audio/wav'}
    ]
  }))

  // 1 video, 3 audio (one is same as video) -> main object unmuted, 2 additional object
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}},
    {src: '/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/third/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: false,
    additionalObjects: [
      {src: '/another/path', type: 'audio/wav'},
      {src: '/third/path', type: 'audio/wav'}
    ]
  }))

  // 1 video, 3 audio (none same as video) -> main object muted, 3 additional objects
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/yet/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/third/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: true,
    additionalObjects: [
      {src: '/another/path', type: 'audio/wav'},
      {src: '/yet/another/path', type: 'audio/wav'},
      {src: '/third/path', type: 'audio/wav'}
    ]
  }))

  // 0 video, 2 audio (all audio type, not same as video) -> main object null muted, 0 additional objects
  videoList = []
  audioList = [
    {src: '/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/yet/another/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/another/path', type: 'audio/wav'},
    mainAudioMuted: false,
    additionalObjects: [
      {src: '/yet/another/path', type: 'audio/wav'}
    ]
  }))

  // 0 video, 3 audio (all audio type, not same as video) -> main object null muted, 0 additional objects
  videoList = []
  audioList = [
    {src: '/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/yet/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/third/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/another/path', type: 'audio/wav'},
    mainAudioMuted: false,
    additionalObjects: [
      {src: '/yet/another/path', type: 'audio/wav'},
      {src: '/third/path', type: 'audio/wav'}
    ]
  }))

  // 1 video, 3 audio (1 video, 2 audio, none same as video) -> main object video unmuted, 3 additional objects
  videoList = [
    {src: '/some/path/to/video/file', metadata: {contentType: 'video/mp4'}}
  ]
  audioList = [
    {src: '/another/path', metadata: {contentType: 'video/mp4'}},
    {src: '/yet/another/path', metadata: {contentType: 'audio/wav'}},
    {src: '/third/path', metadata: {contentType: 'audio/wav'}}
  ]
  av = resolveVideoAndAudioFiles(videoList, audioList)
  unitTestAssert(compareObjects(av, {
    mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
    mainAudioMuted: true,
    additionalObjects: [
      {src: '/another/path', type: 'video/mp4'},
      {src: '/yet/another/path', type: 'audio/wav'},
      {src: '/third/path', type: 'audio/wav'}
    ]
  }))

  console.log('VideoPlayerUtils.js -- END')
}


// ??? WARNING: Hardcoded 2 second buffering

// Checks player position and loops through time ranges already buffered in player. 
//  returns: { 'buffering: true/false, 'bufferedSecondsFromPlayPosition': undefined/number }
function calculateBufferingMessage (playbackPosition, bufferedTimeRanges, videoLength) {
  assert(SpotterfishCore.isNumberInstance(playbackPosition))
  assert(SpotterfishCore.isObjectInstance(bufferedTimeRanges))
  assert(SpotterfishCore.isNumberInstance(videoLength))
  if (bufferedTimeRanges.length === 0) {
    return {
      buffering: true
    }
  }
  // tolerance is needed because position report from player may be slightly off
  const tolerance = 0.1
  for (let i = 0; i < bufferedTimeRanges.length; i++) {
    if (bufferedTimeRanges.start(i) < playbackPosition + tolerance && playbackPosition < bufferedTimeRanges.end(i)) {
      // we are playing within a buffered range.
      if ((bufferedTimeRanges.end(i) - playbackPosition) > 2 - tolerance) {
        // We have about 2 seconds or more buffered.
        return {
          buffering: false,
          bufferedSecondsFromPlayPosition: (bufferedTimeRanges.end(i) - playbackPosition)
        }
      } else {
        // we have less than 2 seconds buffered
        if (Math.round(bufferedTimeRanges.end(i)) === Math.round(videoLength)) {
          console.log('[[videoPlayer]] buffered to the end of movie')
          return {
            buffering: false
          }
        } else {
          return {
            buffering: true, // possibly buffering, we need to make a timeout
            bufferedSecondsFromPlayPosition: (bufferedTimeRanges.end(i) - playbackPosition)
          }
        }
      }
    }
  }  
  return {
    // not in any of the ranges, we are buffering
    buffering: true
  }
}

function getPlaybackBufferingPercentage(playbackPosition, bufferedTimeRanges, videoLength){
  const r = calculateBufferingMessage(playbackPosition, bufferedTimeRanges, videoLength)

  if(r.buffering === false){
    return 100.0
  }
  else {
    if(_.inRange(playbackPosition, videoLength - 0.2, videoLength + 0.2)){
      return 100.0
    }
    if(r.bufferedSecondsFromPlayPosition){
      return Math.round((100.0 * r.bufferedSecondsFromPlayPosition) / 2.0)
    }
    else {
      return 0.0
    }
  }
}

function checkInvariantAVObject(value){
  assert(SpotterfishCore.isObjectInstance(value))

  assert(value.movieObject !== undefined)
  assert(SpotterfishCore.isBooleanInstance(value.audioTrackInVideoMuted))
  assert(SpotterfishCore.isObjectInstance(value.audioSourceD))
  assert(SpotterfishCore.isObjectInstance(value.audioSourceM))
  assert(SpotterfishCore.isObjectInstance(value.audioSourceE))

  return true
}

function captureScreenshot (videoPlayer) {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    canvas.height = videoPlayer.videoHeight * 0.3
    canvas.width = videoPlayer.videoWidth * 0.3
    // @ts-ignore: Object is possibly 'null'
    canvas.getContext('2d').drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
    let resultingImage = canvas.toDataURL('image/jpg')
    resolve(resultingImage)
  }).catch(error => {
    console.error(error)
  })
}



function sniffWebsocketForMCORPEvents(window) {
  const OriginalWebsocket = window.WebSocket
  const ProxiedWebSocket = function() {
    const ws = new OriginalWebsocket(...arguments)
    ws.addEventListener("message", function (e) {
      // Only intercept
      // console.log(`[[Sync]] MCORP COMM: ${e.data}`)
    })
    return ws
  }
  window.WebSocket = ProxiedWebSocket
}

// STRINGS
const LOCALFILESTRING =
{
  line1: 'This version uses a local file, which is not uploaded to our server, please make sure to select the right one',
  line2: ``,
  line3: `Options:`,
  line4: ``,
  line5: `1. Select the correct file`,
  line6: '3. Ask the moderator to provide you with the right file',
  date: ``
}

async function generateAudioWaveform(input, canvas, audioCtx, context, color) {
  try {
    context.clearRect(0, 0, canvas.width, canvas.height);

    let fileSize = 0;
    let urlToFetch = '';

    if (typeof input === 'string') {
      // If it's a URL, we can't determine the size this way. You might choose to set a default size,
      // or implement another method to estimate the file size from a URL.
      urlToFetch = input;
    } else if (input instanceof File) {
      // Use the size property directly for File objects
      fileSize = input.size;
      urlToFetch = URL.createObjectURL(input);
    } else {
      throw new Error('Unsupported input type');
    }

    const memoryLimit = 700 * 1024 * 1024; // 700 MB 

    if (fileSize > memoryLimit) {
      // Fetch metadata only
      const response = await fetch(urlToFetch)
      const audioData = await response.arrayBuffer()
      const audioBuffer = await audioCtx.decodeAudioData(audioData)
      const numberOfChannels = audioBuffer.numberOfChannels

      // File too large, print number of channels on canvas
      drawTextOnCanvas(context, canvas, `${numberOfChannels} channel audio file`, color)
    } else {
      const response = await fetch(urlToFetch);
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioCtx.decodeAudioData(audioData);
      const numberOfChannels = audioBuffer.numberOfChannels;

      for (let channelIndex = 0; channelIndex < numberOfChannels && channelIndex < 8; channelIndex++) {
        drawAudioWaveform(audioBuffer, context, canvas, numberOfChannels, channelIndex, color);
      }
    }

    return canvas.toDataURL('image/jpeg');
  } catch (error) {
    throw error;
  }
}

function drawAudioWaveform(audioBuffer, context, canvas, numberOfChannels, channelIndex, color) {
  const pcmData = audioBuffer.getChannelData(channelIndex)
  const channelHeight = canvas.height / numberOfChannels
  const yOffset = channelHeight * channelIndex
  context.fillStyle = color
  context.lineWidth = 2

  // Adjust the step size to reduce the resolution for large files
  const step = Math.ceil(pcmData.length / canvas.width)
  const amp = channelHeight / 2

  for (let i = 0; i < canvas.width; i++) {
    let min = 1.0
    let max = -1.0
    for (let j = 0; j < step; j++) {
      const dataIndex = i * step + j
      if (dataIndex < pcmData.length) {
        const value = pcmData[dataIndex]
        if (value < min) min = value
        if (value > max) max = value
      }
    }
    context.fillRect(i, yOffset + (1 + min) * amp, 1, Math.max(1, (max - min) * amp))
  }
}

function drawTextOnCanvas(context, canvas, text, color) {
  context.fillStyle = color;
  context.font = '22px Arial';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(text, canvas.width / 2, canvas.height / 2);
}


module.exports = {
  resolveVideoAndAudioFiles,
  checkInvariantAVObject,
  getPlaybackBufferingPercentage,
  runUnitTests,
  captureScreenshot,
  sniffWebsocketForMCORPEvents,
  LOCALFILESTRING,
  generateAudioWaveform,
  drawAudioWaveform
}
