<template>
  <v-app>
    <router-view />
    <global-alert-component ref="globalAlert"></global-alert-component>
    <global-video-autoplay-helper-component ref="globalVideoAutoplayHelper" v-if="$store.state.modals.videoAutoPlayFix" />
    <global-confirm-component ref="globalConfirm"></global-confirm-component>
    <global-prompt-component ref="globalPrompt"></global-prompt-component>
    <global-select-component ref="globalSelect"></global-select-component>

  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/database'
import 'firebase/analytics'
// @ts-ignore
import 'regenerator-runtime/runtime'
import globalAlert from '@/components/UI/globalAlert.vue'
import globalVideoAutoplayHelper from '@/components/UI/globalVideoAutoplayHelper.vue'
import globalConfirm from '@/components/UI/globalConfirm.vue'
import globalPrompt from '@/components/UI/globalPrompt.vue'
import globalSelect from '@/components/UI/globalSelect.vue'

import { isDevelopment, isTesting } from '@/../source_files/spotterfish_library/SpotterfishCore'
import Constants from '@/../source_files/spotterfish_library/consts'

import { mapGetters } from 'vuex'

import { AnalyticsBrowser } from '@segment/analytics-next'

import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'

let config

if (isDevelopment()) {
  config = require('../config/firebaseCfg').devConfig
} else if (
  window.location.hostname === 'app.mixstage.io' ||
  window.location.hostname === 'mixstage.web.app' ||
  window.location.hostname === 'mixstage.firebaseapp.com'
) {
  config = require('../config/firebaseCfg').mixstageProdConfig
} else {
  config = require('../config/firebaseCfg').mixstageStagingConfig
}

console.warn('current host: ' + window.location.host)
console.warn('current hostname: ' + window.location.hostname)

const firebaseApp = firebase.initializeApp(config)

let db = firebaseApp.database() // only used for transport linking via firebase, we now use websockets
let storageRef = firebaseApp.storage().ref()
let AAFStorage = firebase.app().storage('gs://aafexport/')

const firestoreDB = firebase.firestore()
// As of now 2022-09-03 we can write undefined values from client without errors, they are just ignored
firestoreDB.settings({
  ignoreUndefinedProperties: true
})

if (isTesting()) {
  firebase.firestore().settings({ experimentalForceLongPolling: true })
}

if (isDevelopment()) {
  firestoreDB.settings({
    host: 'localhost:8081',
    ssl: false,
    merge: true,
  })
}

const firebaseStorage = firebase.storage()
const firebaseFunctions = firebase.functions()
if (isDevelopment()) {
  firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
}
const firebaseAnalytics = firebase.analytics()

const segmentAnalytics = AnalyticsBrowser.load({ 
  writeKey: Constants.SEGMENT_WRITE_KEY,
  // @ts-ignore
  cookie: {
    domain: '.mixstage.io'
  }
})

const s = SpotterfishSession.makeSpotterfishSessionObject(
  firebase,
  firestoreDB,
  firebaseAnalytics,
  segmentAnalytics,
  undefined
)

export default {
  name: 'App',
  DAWsteamerWindow: undefined,
  spotterfishSession: s,
  Firebase: firebase,
  db,
  firestoreDB,
  storageRef,
  AAFStorage,
  firebaseStorage,
  firebaseFunctions,
  firebaseAnalytics,
  segmentAnalytics,
  firebase: {
    // Old firebase binding. We use this to create the list of Interested USers
    interestedUsers: db.ref('interestedUsers/'),
  },
  components: {
    'global-alert-component': globalAlert,
    'global-video-autoplay-helper-component': globalVideoAutoplayHelper,
    'global-confirm-component': globalConfirm,
    'global-prompt-component': globalPrompt,
    'global-select-component': globalSelect,

  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    /*
      We use he regular expression (/Null value error/) to 
      test whether the message property of the error object (event.reason.message) contains the substring 'Null value error'. 
      The .test() method returns a boolean.
    */
    handleFirebaseErrors(event) {
        console.log(event)
      if (event.reason.message.includes('Unable to establish IDB database file')) {
        console.log('IndexedDB issue detected.')
        event.preventDefault()
        }
      if (event.reason.name === 'FirebaseError' &&
        (/Null value error/.test(event.reason.message) || /false for 'list'/.test(event.reason.message))) {
        console.log('Caught a FirebaseError related to Null value or list.')
        event.preventDefault()
      }
      event.preventDefault()
    }
  },
  beforeMount() {
    window.addEventListener('unhandledrejection', this.handleFirebaseErrors) 
    window.addEventListener('unhandledpromiserejection', this.handleFirebaseErrors) 
  },
  mounted() {
    // ??? Todo - check that user exists in db, log out if not
    // @ts-ignore
    this.$root.$globalAlert = this.$refs.globalAlert
    // @ts-ignore
    this.$root.$globalConfirm = this.$refs.globalConfirm
    // @ts-ignore
    this.$root.$globalPrompt = this.$refs.globalPrompt
    // @ts-ignore
    this.$root.$globalSelect = this.$refs.globalSelect


  },
  destroyed() {
    window.removeEventListener('unhandledrejection', this.handleFirebaseErrors)
    window.addEventListener('unhandledpromiserejection', this.handleFirebaseErrors) 
  },
}
</script>

<style>
#app {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;

  background-image: url('~@/assets/images/spotterfishIcons/Logo_fishicon-blackbg-min.png');
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;

}
</style>
