<template>
  <v-app>
    
            <purchaseAdditionalUsers />
          
    
    
  </v-app>
</template>

<script>
//@ts-nocheck
// import App from '@/App.vue'
import purchaseAdditionalUsers from '@/components/payment/purchaseAdditionalUsers.vue'

export default {
  name: 'admin',
  components: {
    'purchaseAdditionalUsers': purchaseAdditionalUsers
  },
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      
  
    }
  },
  computed: {

  },

  methods: {
   
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/global.scss';

</style>
