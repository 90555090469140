/*
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::Put all global colors here and require them with:::::::::::::::::
:::::::::;;;:::;:::colors: require('@/lib/ui/colors.js').colors:::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::etc::::::::::::::::::::::::::::::::::::::::
*/

exports.colors = {
  mainAccent: '#9D88B2', //'#6391CA',
  vividViolet: '#7B2FA0',
  amethyst: '#9169C1',
  amethyst_smoke: '#9D88B2',
  mixStage_main: '#A149FC',
  affair: '#8C4E92',
  zircon: '#F5F8FF',
  sisal: '#D5CABD',
  oldRose: '#B32B45',
  darkTerracotta: '#D54759',
  sunglo: '#ED6767',
  keppel: '#61AF9D',
  // violentViolet: '#33284D',
  fringyflower: '#B0DCC2',
  spacerColor: '#212121',
  darkGray: '#303030', // '#383838',
  lightGray: '#AAAAAA',
  white: '#EEEEEE',
  borderBlue: '#1C335C',
  borderGray: '#0F0F0F',
  black: '#010101',
  red: '#C83733',
  smoke: '#C1C1C1',

  tooltipBackground: 'rgba(30, 30, 30, 0.99)',

  // These are copied to global.cscc also, to be used in different places (These are javascript values)
  mx: {
    white: '#FFFFFF',
    primary: '#FF00ED', // Pink
    secondary: '#A149FC', // Purple
    xLight: '#50525b',
    light: '#43454C',
    panel: '#303136',
    midPanel: '#212124',
    dark: '#202124',
    xDark: '#1A1A1A',
    black: '#0A0A0B',
    dawStreamerButton: '#9D88B2',
    buffered: '#9D88B2AA',
  }
}
