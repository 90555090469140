<template>
    <v-dialog
        v-model="dialog"
        v-if="dialog"
        width="400"
        height="300"
        class="dark-panel"
    >
      <div>
        <video width="400" style="aspect-ratio: 16/9;" id="previewVideo" controls crossorigin="anonymous">
          <source :src="mediaUrl" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <v-btn v-if="file.has_video" @click="getThumbnail()" small style="margin: 10px;"><v-icon style="padding: 4px;">camera</v-icon>{{ file.has_video ? 'Capture thumbnail image' : 'Generate audio waveform' }}</v-btn>
        <canvas id="canvas" width="480" height="270" style="display: none;"></canvas>

        <canvas ref="audioCanvas" width="480" height="270" style="display:none;" crossorigin="anonymous"></canvas>
      </div>
    </v-dialog>
</template>
  
<script>
// @ts-nocheck
import VideoPlayerUtils from '@/../source_files/spotterfish_library/utils/VideoPlayerUtils'

export default {
    name: 'videoPreview',
    props: [],
    data() {
    return {
        logos: require('@/lib/ui/logos.js').logos,
        colors: require('@/lib/ui/colors.js').colors,
        dialog: false,
        projectId: undefined,
        mediaUrl: '', // This will hold the video URL
        file: ''
    }
    },
    methods: {
    async open(data, fileDB) {
        this.spinner = true
        this.dialog = true
        this.file = fileDB
        console.log(fileDB)
        // Set mediaUrl based on the fileId
        this.mediaUrl = data.urls[fileDB['.key']]

        this.$nextTick(() => {
        document.getElementById('previewVideo').load()
        })

        this.spinner = false

        return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        })
    },
    
    async download(data, fileDB) {
        this.file = fileDB
        console.log(fileDB)
        // Set mediaUrl based on the fileId
        return data.urls[fileDB['.key']]
    },

    async getThumbnail() {
        if (this.file.has_video) {
            const video = document.getElementById('previewVideo')
            const canvas = document.getElementById('canvas')
            const context = canvas.getContext('2d')
            canvas.width *= 0.5
            canvas.height *= 0.5
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            const imageUrl = canvas.toDataURL('image/jpg')
            canvas.width *= 2
            canvas.height *= 2
            this.$emit('on-thumbnail-created', imageUrl)
        } else {
            // const canvas = this.$refs.audioCanvas
            // const context = canvas.getContext('2d')
            // const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
            // console.log(this.mediaUrl)
            // const audioWaveformURL = await VideoPlayerUtils.generateAudioWaveform(this.mediaUrl, canvas, audioCtx, context, this.colors.mx.secondary)
            // console.log(audioWaveformURL)
            // this.$emit('on-thumbnail-created', audioWaveformURL)
        }
    }
    },
    async mounted() {
    }
}
</script>
  