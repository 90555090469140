module.exports.prodConfig = {
  projectId: 'audiopostr',
  apiKey: 'AIzaSyD5ErIrIypExwEgQFOgF8qPnck7G0NOylE',
  authDomain: 'audiopostr.firebaseapp.com',
  databaseURL: 'https://audiopostr.firebaseio.com',
  storageBucket: 'audiopostr.appspot.com',
  messagingSenderId: '406542618401',
  appId: '1:406542618401:web:9e4e4d6a1f4b8b80',
  measurementId: 'G-B8QLCTD2SH'
}

module.exports.mixstageProdConfig = {
  projectId: 'audiopostr',
  apiKey: 'AIzaSyBVsBw757GWb1zVw0uXm6DZAnI-rFbQNxQ',
  authDomain: 'audiopostr.firebaseapp.com',
  databaseURL: 'https://audiopostr.firebaseio.com',
  storageBucket: 'audiopostr.appspot.com',
  messagingSenderId: '406542618401',
  appId: '1:406542618401:web:f3d9268184395cb83b7860',
  measurementId: 'G-BN4H03DM65'
}

module.exports.stagingConfig = {
  apiKey: 'AIzaSyD43Uc_yMM2v6TDlbjhAroeL22xF7B6CbQ',
  authDomain: 'audiopostrdev.firebaseapp.com',
  databaseURL: 'https://audiopostrdev.firebaseio.com',
  projectId: 'audiopostrdev',
  storageBucket: 'audiopostrdev.appspot.com',
  messagingSenderId: '860968322319',
  appId: '1:860968322319:web:fb0577e88179656206aa7a',
  measurementId: 'G-01963CKQLM'
}

module.exports.mixstageStagingConfig = {
  apiKey: 'AIzaSyD43Uc_yMM2v6TDlbjhAroeL22xF7B6CbQ',
  authDomain: 'audiopostrdev.firebaseapp.com',
  databaseURL: 'https://audiopostrdev.firebaseio.com',
  projectId: 'audiopostrdev',
  storageBucket: 'audiopostrdev.appspot.com',
  messagingSenderId: '860968322319',
  appId: '1:860968322319:web:15f5a499d290402006aa7a',
  measurementId: 'G-HSCK2JYJHK'
};

module.exports.devConfig = {
  // Using the staging environment for now
  projectId: 'audiopostrdev',
  apiKey: 'AIzaSyD43Uc_yMM2v6TDlbjhAroeL22xF7B6CbQ',
  authDomain: 'audiopostrdev.firebaseapp.com',
  databaseURL: 'http://localhost:9002?ns=audiopostrdev',
  storageBucket: 'audiopostrdev.appspot.com',
  messagingSenderId: '860968322319',
  appId: '1:860968322319:web:fb0577e88179656206aa7a',
  measurementId: 'G-01963CKQLM'
}

