// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// @ts-nocheck

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'

import ElementUI from 'element-ui'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/global.scss'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import VueHotkey from 'v-hotkey'
import VueClipboard from 'vue-clipboard2'

import { isProduction } from '@/../source_files/spotterfish_library/SpotterfishCore'

if (isProduction()) {
  Sentry.init({
    dsn: 'https://6a692013b97f418cabffe8b2cc1364ba@o296473.ingest.sentry.io/1546959',
    release: `MixStage ${process.env.VUE_APP_SENTRY_ENVIRONMENT}`,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ],
    debug: process.env.VUE_APP_SENTRY_ENVIRONMENT === 'development',
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
  })
}

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(Vuetify)
Vue.use(require('vue-shortkey'), { prevent: ['input', 'textarea'] })
Vue.use(VueHotkey)
Vue.use(VueClipboard)

let app

firebase.auth().onAuthStateChanged(function (user) {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify: new Vuetify({
        icons: {
          iconfont: 'mdi'
        },
        theme: {
          dark: true
        }
      }),
      render: h => h(App)
    }).$mount('#app')
  }
})
