<!-- projectEditedData and projectOriginalData is retreived from the DB on mount -->
<template>
  <v-dialog
      v-model="dialog"
      v-if="dialog"
      width="800"
      persistent
      overlay-opacity="0.8"
      overlay-color="black"

  >
    <div class="project-settings-dialog pt-10" v-if="projectEditedData">
      <!-- <v-card-title :style="{ background: colors.amethyst_smoke }">
        <div style="margin: auto; text-align: center">
          <h2 class="noSelect">EDIT PROJECT</h2>
        </div>
      </v-card-title> -->
      <div class="project-settings">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div class="project-info">

              <v-text-field
                dense
                outlined
                color="white"
                v-model="projectEditedData.projectDB.project_name"
                ref="projectNameInput"
                label="Project Name"
                maxlength="100"
                required
              />
              
              <v-textarea
                dense
                height="80"
                outlined
                color="white"
                v-model="projectEditedData.projectDB.project_description"
                label="Project description" 
              />
              
              <v-combobox
                height="40"
                outlined
                color="white"
                v-model="projectEditedData.projectDB.project_description_tags"
                label="Tags (optional)"
                multiple
                chips
                small-chips
              />
              <v-row class="d-flex justify-space-around">
                <v-col cols="4" style="text-align: left;">
                  <span>Project TC Start</span>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-center align-center" style="background: none;">
                    <!-- Changes 'version_timecode_offset_seconds' field instead 2022-09-01, video_file_start_smpte will always be 00:00:00:00 on new videos -->
                    <v-text-field
                      append-icon="mdi-close"
                      @click:append="onSMPTEinputChanged(resetProjectTimecodeOffset(projectOriginalData))"
                      validate-on-blur
                      outlined
                      color="white"
                      dense
                      hide-details
                      :value="getProjectTimecodeOffset(projectEditedData)"
                      @change="onSMPTEinputChanged($event)"
                      :rules="[ rules.required, rules.validSMPTE ]"
                      label="tc"
                      :error-messages="startSMPTEErrors"
                      required>
                    </v-text-field>
                  </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around">
                <v-col cols="4" style="text-align: left;">
                  <span>Project frame rate</span>
                </v-col>
                <v-col cols="8" class="d-flex justify-center align-center">
                  <v-select
                    outlined
                    color="white"
                    dense
                    hide-details
                    v-model="projectEditedData.versionDB.video_framerate"
                    :items="getFramerates"
                    :error-messages="framerateErrors"
                    label="fps"
                    required>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    color="white"
                    dense
                    v-model="projectEditedData.versionDB.name"
                    :error-messages="versionNameErrors"
                    label="Version Name"
                    required
                    hide-details
                    style="margin-top: 1.5rem;"
                  />
                </v-col>
              </v-row>
              <v-row style="margin-bottom: 10px;">
                <v-col>
                  <!-- <div style="display: flex; justify-content: space-between;">
                    <v-btn style="width: 48%;" color="#666667" @click="getArchivedVersions()">Archived Versions</v-btn>
                    <v-btn style="width: 48%;" color="#666667" @click.prevent="copyToArchive()">
                      Copy to archive
                    </v-btn>
                  </div> -->
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <projectPeople
              class="x-dark-panel"
              :projectID="projectID"
              :projectDB="projectEditedData?.projectDB"
              @request-open-purchase-dialog="$emit('request-open-purchase-dialog', $event)"
            />
            <div class="project-files">
              <v-row>
                <v-col>
                  <uploadwidget :projectUID="projectEditedData.projectDB['.key']" @project-uploaded="onFileUploaded" caller="projectEdit"></uploadwidget>
                </v-col>
              </v-row>
              
              <v-row class="d-flex justify-center">
                <v-col>
                  <div class="project-file-list sfish-scrollbars ">
                    <v-row class="header-row" no-gutters>
                      <v-col class="text-left" cols="1"></v-col> 
                      <v-col class="text-left filename-header" cols="9">
                          <strong>A/V Matrix</strong> <span style="font-size: 0.8em">- 1 Video & up to 3 Audio Stems</span>
                      </v-col>
                      <v-col class="text-center filename-header-small" cols="2">Video Audio</v-col>
                    </v-row>

                    <v-row no-gutters
                        v-for="(item) in projectEditedData.files" 
                        :key="item['.key']"
                        class="text-left file-row"
                    >
                      <!-- thumbnail -->
                      <v-col class="text-left" cols="1">                                
                        <div style="width: 30px; display: flex; align-items: center; justify-content: center;"
                        >
                          <v-icon :disabled="!item.has_video" style="font-size: 1.6em;" class="" @click="showVideo(projectOriginalData, item)">play_circle</v-icon>
                        </div>
                      </v-col>

                      <v-col class="text-left" cols="1">   
                        <div style="width: 20px; display: flex; align-items: center; justify-content: center;">
  
                          <v-menu left offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon style="font-size: 1em;" v-bind="attrs" v-on="on">more_vert</v-icon>
                            </template>
                            
                            <v-list>
                              <v-list-item @click="downloadItem(projectOriginalData, item)">
                                <v-list-item-title>Download</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="deleteItem(projectEditedData, item)">
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>     
                        </div>                      
                      </v-col>
                      

                      <!-- Filename with ellipsis -->
                      <v-col class="text-left ellipsis-filename" cols="8">
                          {{ item.file_name }}
                      </v-col>

                      <v-col cols="2" class="av-buttons-col">
                          <v-btn @click.stop="toggleSelectVideo(item['.key'])" 
                            icon
                            outlined
                            x-small 
                            :disabled="!item.has_video" 
                            :class="{selected: item['.key'] === selectedVideoFile && item.has_video}"
                          >
                          <v-tooltip bottom :color="colors.tooltipBackground">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon style="font-size: 1em;" v-bind="attrs" v-on="on">theaters</v-icon>
                            </template>
                            <span>{{`${ item['.key'] === selectedVideoFile ? 'Deactivate this video track' : 'Activate this video track'}`}}</span>
                          </v-tooltip>
                          </v-btn>

                          <v-btn @click.stop="toggleSelectAudio(item['.key'])" 
                          icon
                          outlined
                          x-small 
                          :disabled="!item.has_audio" :class="{selected: isSelectedAudio(item['.key']) && item.has_audio}">
                            <v-tooltip bottom :color="colors.tooltipBackground">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon style="font-size: 1em;" v-bind="attrs" v-on="on">speaker</v-icon>
                              </template>
                              <span>{{`${isSelectedAudio(item['.key']) ? 'Deactivate this audio track' : 'Activate this audio track'}`}}</span>
                            </v-tooltip>
                          </v-btn>
                      </v-col>
                      
                    </v-row>
                  
                      
                  </div>
                </v-col>

    
              </v-row>
            </div>
          </v-col>

        </v-row>
        <div class="buttons">
          <v-btn
            :color="hasNoChanges() ? colors.gray : colors.gray"
            @click.prevent="clearAndClose()" style="margin-right: 10px;">
            {{hasNoChanges() ? 'Close' : 'Cancel'}}
          </v-btn>
          <v-btn class="justify-self-end"
            :disabled="hasNoChanges()"
            :style="[hasNoChanges() ? {} : {background: colors.mixStage_main}]"
            @click.prevent="save(false)">
            Save
          </v-btn>
          <v-btn class="justify-self-end"
            :disabled="!hasNoChanges()"
            style="margin-left: 10px;"
            :style="[!hasNoChanges() ? {} : {background: colors.mixStage_main}]"
            @click.prevent="loadInMixStage()">
            {{ isSessionStarted ? 'Load Project in Session' : 'Start Session with Project' }}
          </v-btn>
        </div>

        <!-- ************* -->
        <!-- MODAL WINDOW -->
        <!-- *************-->
        <v-dialog
          width="880"
          v-model="earlierVersionsDialog"
          overlay-opacity="0.95"
        >
          <v-card style="background: #222222; padding: 1rem;">
            <v-card-title>
              <h3 style="text-align: center; width: 100%;">Archived Versions</h3>
            </v-card-title>
            <div style="overflow: auto;">
              <div style="border: 1px solid white; width: 100%; height: auto;">
                <v-simple-table style="width: 100%; padding: 0; margin: 0; height: 100%;">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in archivedVersions" :key="item.archived_date.seconds"
                          style="text-align: left;"
                          :class="{ 'selected-row': item === selectedArchive }"
                          @click="selectArchivedVersion(item)">
                        <td>{{ formatDate(item.archived_date.seconds * 1000) }}</td>
                        <td>{{ item.name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
            <div style="display: flex; justify-content: space-around; padding: 1rem;">
              <v-btn @click="archivedVersions = undefined, earlierVersionsDialog = false" color="#666667">Cancel</v-btn>
              <v-btn @click="restoreFromArchive()" :color="colors.mixStage_main">Restore from Archive</v-btn>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="loader" v-if="spinner">
      <v-overlay
        persistent
        color="#080808"
        opacity="0.65"
      >
        <mixstage-spinner
          :style="{ 'background-color': 'transparent' }"
          :showSpinner="spinner"
          :loadingMessage="'Please wait...'"
        ></mixstage-spinner>
      </v-overlay>
    </div>
    <video-preview ref="videoPreview" @on-thumbnail-created="setProjectThumbnail($event)" />
  </v-dialog>


</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import moment from 'moment'

import uploadwidget from '@/components/projectscomponents/uploadWidget_restyle.vue'
import videoPreview from '@/components/versionscomponents/videoPreview.vue'

import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import CommonUtils from '@/../source_files/spotterfish_library/utils/CommonUtils'

import CalcDeltaSecondsFromUIString from '@/../source_files/spotterfish_library/CalcDeltaSecondsFromUIString'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'

import videoIcon from '@/assets/images/spotterfishIcons/video_icon2020.svg'
import CloudClient from '@/../source_files/web_client/CloudClient'
import { isArrayInstance, isStringInstance, assert, isObjectInstance } from '@/../source_files/spotterfish_library/SpotterfishCore'
import projectPeople from './projectPeople.vue'
import mixStageSpinner from '@/components/dashboard/mixStageSpinner.vue'


// Broken out logic - move to separate file

function returnArrayFromAudioFilesStringOrArray (projectData) {
  assert(isObjectInstance(projectData))

  const audioFile0 = projectData.versionDB.audio_file
  let sanitized_audioFilesArray = []
  if (isStringInstance(audioFile0)) {
    sanitized_audioFilesArray.push(audioFile0)
  }
  else if (isArrayInstance(audioFile0)) {
    sanitized_audioFilesArray = projectData.versionDB.audio_file
  }
  return sanitized_audioFilesArray

}

/*
  as returned from CFgetProjectVersionAndFiles
  projectData: {
    projectDB,
    versionDB,
    files
  }
*/

function getFramerateKeyFromProject(projectData) {
  assert(isObjectInstance(projectData))
  return Timecode.findFrameRateKeyFromNickname(projectData.versionDB.video_framerate)
}

function getFramerateKeyFromVideoFile(projectData) {
  assert(isObjectInstance(projectData))

  // newly uploaded videos (from v 1.22 2020-09-01) will have the property video_frk on all uploaded videos
  // if created before, we return the data (legacy) from versionDB.video_framerate
  // versionDB.video_framerate is no longer editable
  const videoFileId = projectData.versionDB.video_file
  if (videoFileId) {
    const file = projectData.files?.find(file => file['.key'] === videoFileId) 
    const frameRateKey = file?.video_frk ? file.video_frk : Timecode.findFrameRateKeyFromNickname(projectData.versionDB.video_framerate)

    return frameRateKey
  }
  else {
    return Timecode.findFrameRateKeyFromNickname(projectData.versionDB.video_framerate)
  }
}

function calcTimecodeOffsetFromProject (projectData) {
  assert(isObjectInstance(projectData))

  const frk = getFramerateKeyFromProject(projectData)
  return projectData.versionDB.version_timecode_offset_seconds !== undefined ? 
    Timecode.secondsToSMPTEString(projectData.versionDB.version_timecode_offset_seconds, frk) 
    : projectData.versionDB.video_file_start_smpte
}

function calcSecondsFromUISMPTEString (uiSMPTEstring, projectData) {
  
  const frk = getFramerateKeyFromProject(projectData)
  const frameRateNickname = Timecode.getFrameRateNickname(frk)
  
  const validatedValue = Timecode.isValidSMPTETimeCode(
    uiSMPTEstring, frameRateNickname) ? 
    uiSMPTEstring : 
    CalcDeltaSecondsFromUIString.addSMPTEseparators(uiSMPTEstring) 
  console.log(validatedValue)
  if (Timecode.isValidSMPTETimeCode(validatedValue, frameRateNickname)) {
    return Timecode.SMPTEStringToSeconds(validatedValue, frk)
  }
  else {
    throw new Error('invalid input')
  }
}

export default {
  name: 'versionsDialog',
  // props: [''],
  components: {
    projectPeople,
    uploadwidget,
    videoIcon,
    'video-preview': videoPreview,
    'mixstage-spinner': mixStageSpinner
  },
  data () {
    return {
      dialog: false,
      projectID: undefined, 

      // store a new copy of all data here
      projectOriginalData: undefined,
      projectEditedData: undefined,

      selectedAudio: [],
      selectedVideo: [],
      
      hover: false,
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      /* ::::::::::::::::::::::::::::::::UI:::::::::::::::::::::::::::::::::: */
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      avatarColor: require('@/lib/ui/colors.js').colors.keppel,
      spacerColor: require('@/lib/ui/colors.js').colors.spacerColor,
      spinner: true,
      // Models Archives etc
      restoredMarkerLanes: undefined,
      selectedVideoFile: undefined, // index in list
      selectedAudioFiles: [], // index in list
      selectedArchive: undefined,
      earlierVersionsDialog: false,
      archivedVersions: undefined,
      // Error/Validation checks
      versionNameErrors: '',
      framerateErrors: '',
      startSMPTEErrors: '',
      selectErrors: '',
      rules: {
        required: value => !!value,
        validSMPTE: value => { return Timecode.isValidSMPTETimeCode(value, this.projectEditedData.versionDB.video_framerate, false) }
      },
    }
  },
  computed: {
    getFramerates () {
      let arr = []
      for (var frameRateKey in Timecode.kFrameRateKeyCatalog) {
        const nickname = Timecode.getFrameRateNickname(frameRateKey)
        arr.push(parseFloat(nickname))
      }
      return arr
    },

    getSampleRates () {
      return [44100, 48000, 88200, 96000, 192000]
    },
  },
  methods: {
    preventRightClicks() {
      return false
    },
    isSessionStarted () {
      return localStorage.getItem('sessionRunning')
    },
    loadInMixStage () {
      const projId = this.projectOriginalData.projectDB['.key']
      console.log('project wants to be loaded', projId)
      this.$emit('request-load-in-mixstage', projId)
      this.clearAndClose()
    },

    setProjectThumbnail(imageUrl) {
      
      this.projectEditedData.projectDB.thumb = imageUrl
      this.$forceUpdate()
    },

    showVideo (projectDB, fileDB) { 
      console.log(projectDB)
      // TODO - get the url for this project - needs to be called from cloud function.
      this.$refs.videoPreview.open(projectDB, fileDB)
    },
    async downloadItem (projectDB, fileDB) {
      console.log(projectDB)
      // TODO - get the url for this project - needs to be called from cloud function.
      const url = await this.$refs.videoPreview.download(projectDB, fileDB)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'

      a.download = fileDB.name

      document.body.appendChild(a)
      a.click()    
      document.body.removeChild(a)
    },

    async deleteItem (projectEditedData, file) {
      console.log(projectEditedData)

      if (this.isSelectedAudio(file['.key']) || this.selectedVideoFile === file['.key']) {
        await this.$root.$globalAlert.open(
          'Can not delete active file',
          {
            line1: 'You cannot delete a file that is currently selected as active in project.',
            line3: 'Please make another file active and press save first.'
          },
          { color: this.colors.mx.secondary }
        )
        return
      }

      if (projectEditedData.files.length === 1) {
        await this.$root.$globalAlert.open(
          'Delete file',
          {
            line1: 'You cannot delete the last file in a project.',
            line3: 'Please delete the entire project instead.'
          },
          { color: this.colors.mx.secondary }
        )
        return
      }

      const confirm = await this.$root.$globalConfirm.open(
        `Delete ${file.file_name}`,
        {
          line1: 'It will not be deleted from your storage until you delete the entire project, but it will become unaccessible for all users.',
          line3: 'Yes will Delete, Cancel will abort.'
        },
        { color: this.colors.mx.secondary }
      )
      if (confirm) {
        const a = await CloudClient.call_CFsoftDeleteFile(App.spotterfishSession.firebase, this.projectID, file['.key'])
        console.log(a)
        await this.updateFiles()
      } else {
        return
      }
    },

    formatDate (value) {
      if (value) {
        if (moment(value).format(' HH:MM ') !== 'Invalid date') {
          if (navigator.languages !== undefined) {
            moment.locale(navigator.languages[0])
          } else {
            moment.locale(navigator.language)
          }
          return moment(value).calendar()
        } else {
          return 'error'
        }
      }
    },

    hasNoChanges () {
      // 2022-09-02: MSR: hack to update UI if version_timecode_offset_seconds is not set on project. Lowdash does not react to added props, only changed???
      return _.isEqual(this.projectOriginalData, this.projectEditedData) 
        && _.isEqual(this.projectOriginalData.version_timecode_offset_seconds, this.projectEditedData.version_timecode_offset_seconds
        && (_.isEqual(this.projectOriginalData.projectDB.thumb, this.projectEditedData.projectDB.thumb))
      )
    },

    isDemoProject(){
      return this?.projectOriginalData?.projectDB.is_demo_project
    },

    isEmptyProject(proj){
      return proj?.projectDB?.project_name === ''
    },

    async open (projectID, isNewProject) {
      assert(projectID !== undefined && _.isString(projectID))

      this.spinner = true
      this.dialog = true
      this.projectID = projectID
      
      try {
        await this.getSavedDataFromDB()        
      } catch (error) {
        throw(error)
      }
      if (isNewProject) {
        this.$refs.projectNameInput.focus()
      }
      // this.getProjectPeople()
      // this.getProjectModerators()

      this.spinner = false

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    async clearAndClose () {
      this.close()
    },

    close () {
      this.spinner = false
      this.projectID = undefined
      this.projectOriginalData = undefined
      this.projectEditedData = undefined
      this.selectedAudioFiles = undefined
      this.selectedVideoFile = undefined
      this.dialog = false

      this.resolve()
    },

    getFrameRateNicknameForVideo (projectData) {
      const frk = getFramerateKeyFromVideoFile(projectData)
      return Timecode.getFrameRateNickname(frk)
    },

    resetProjectTimecodeOffset (projectData) {
      const frk = getFramerateKeyFromProject(projectData)
      return projectData.versionDB.video_file_start_smpte === 0 ? 
        Timecode.secondsToSMPTEString(projectData.versionDB.version_timecode_offset_seconds, frk) 
        : 0
    },
    getProjectTimecodeOffset (projectData) {
      return calcTimecodeOffsetFromProject(projectData)
    },

    async updateFiles () {
      this.spinner = true
      if (this.projectEditedData) {
        const p = await CloudClient.call_CFgetProjectVersionAndFiles(App.spotterfishSession.firebase, this.projectID)
        this.projectEditedData.files = p.files
      }
      this.spinner = false
    },

    async getSavedDataFromDB () {
      try{
        const p = await CloudClient.call_CFgetProjectVersionAndFiles(App.spotterfishSession.firebase, this.projectID)
        console.log('getSavedData', p)
        this.projectOriginalData = _.cloneDeep(p)
        this.projectEditedData = p
        console.log('Project:', this.projectEditedData)
        this.selectCurrentAudioAndVideo(this.projectEditedData.versionDB)
      }
      catch (error) {
        throw error
      }
    
    },
    
    // value input is a SMPTE String
    // force reload UI to display new version_timecode_offset_seconds as SMPTE string
    async onSMPTEinputChanged (value) {
      try {
        const valueAsSeconds = calcSecondsFromUISMPTEString(value, this.projectEditedData)
        this.projectEditedData.versionDB.version_timecode_offset_seconds = valueAsSeconds
        this.$forceUpdate()
      } catch (error) {
        await this.$root.$globalAlert.open(
            'Timecode input error',
            {
              line1: `Please input a valid SMPTE Time Code as start of project`,
            },
            { color: this.colors.mainAccent }
          )
      }
    },

    getFileNameFromUID (uid) {
      if (uid) {
        const val = CommonUtils.getFromArray(this.projectEditedData.files, uid)
        if (val) {
          return val.file_name
        }
      }
    },

    isSelectedAudio (uid) {
      return CommonUtils.findInArray(this.selectedAudioFiles, uid)
    },

    // ???TODO - no need for the separate props this.selectedVideoFile etc
    toggleSelectVideo (uid) {
      if (this.selectedVideoFile === uid) {
        this.projectEditedData.versionDB.video_file = undefined
        this.selectedVideoFile = undefined
      } else {
        this.projectEditedData.versionDB.video_file = uid
        this.selectedVideoFile = uid
      }
    },

    // ???todo no need for these extra props
    // remember this.projectEditedData.versionDB.audioFile is an array despite it's name
    // returnArrayFromAudioFilesStringOrArray sanitizes the string OR array that may be this.projectEditedData.versionDB.audioFile
    toggleSelectAudio (uid) {
      const sanitized_audioFilesArray = returnArrayFromAudioFilesStringOrArray(this.projectEditedData)

      if (CommonUtils.findInArray(this.selectedAudioFiles, uid)) {
        CommonUtils.removeFromArray(this.selectedAudioFiles, uid)
        CommonUtils.removeFromArray(sanitized_audioFilesArray, uid)
      } else {
        if (this.selectedAudioFiles.length === 3) {
          this.selectedAudioFiles.pop()
          sanitized_audioFilesArray.pop()
        }
        this.selectedAudioFiles.push(uid)
        sanitized_audioFilesArray.push(uid)
      }
      this.projectEditedData.versionDB.audio_file = sanitized_audioFilesArray
    },

    async onFileUploaded (e) {
      await this.updateFiles()
    },

    selectArchivedVersion (version) {
      this.selectedArchive = version
    },
    
    // TODO: no need for extra props, read from projectEditedData directly
    selectCurrentAudioAndVideo (version) {

      this.selectedVideoFile = undefined
      this.selectedAudioFiles = []

      this.selectedVideoFile = version.video_file

      const sanitized_audioFilesArray = returnArrayFromAudioFilesStringOrArray(this.projectEditedData)

      for (let i = 0; i < sanitized_audioFilesArray.length; i++) {
        this.selectedAudioFiles.push(sanitized_audioFilesArray[i])
      }
    },

    async save (closePanel) {
      this.spinner = true
      // never save the urls, they expire anyway
      const versionUpdateObject = this.projectEditedData.versionDB
      const projectUpdateObject = this.projectEditedData.projectDB

      if (versionUpdateObject.video_file === undefined && versionUpdateObject.audio_file.length === 0) {
        this.spinner = false
        await this.$root.$globalAlert.open('Error', {
            line1: `Audio-Only playback from a video file is currently not supported. Select one video source, or upload the audio as PCM or mp3.`
          },
          { color: this.colors.mainAccent })
        return
      }

      SpotterfishSession.trackEvent(
        App.spotterfishSession, 
        'updated_version', 
          {
            userUID: App.spotterfishSession.userSession.firebaseCurrentUser.uid,
            project: this.projectID,
            videoFramerate: this.projectEditedData.versionDB.video_framerate,
            audioTrackCount: this.selectedAudioFiles.length
          }
      )

      try {
        // Reset the project listener - to keep updates happen after changes has been made, 
        // screeningroom then checks if edited project is loaded, so it is safe to run either way
        const projKey = this.projectOriginalData.projectDB['.key']

        await CloudClient.call_CFupdateProjectAndVersion(App.spotterfishSession.firebase, projectUpdateObject, versionUpdateObject)
        await this.getSavedDataFromDB()

        this.spinner = false
        if (closePanel) { this.clearAndClose()}
      } catch (error) {
        alert(error)
        this.spinner = false
        if (closePanel) { this.clearAndClose()}
      }
    },

    async copyToArchive () {
      this.spinner = true
      // Create version archive MUTATES the version object
      const versionUpdateObject = _.cloneDeep(this.projectEditedData.versionDB)
      try {
        // ??? TODO - move this to cloud function
        await this.$store.dispatch('create_version_archive', { versionObject: versionUpdateObject, projectID: this.projectID })
        
        this.spinner = false
        SpotterfishSession.trackEvent(
          App.spotterfishSession, 
          'created_version_archive', 
            {
              userUID: App.spotterfishSession.userSession.firebaseCurrentUser.uid,
              projectUID: this.projectID
            }
        )       

      } catch (error) {

        console.log(error)
        alert('We encountered an error while archiving your version. The spotterfish team has been notified')
        this.spinner = false

      }
    },
    // ???TODO - cleanup separate props
    async restoreFromArchive () {
      const agree = confirm('This will replace all your current markers. Are you sure?')
      if (agree) {

        this.spinner = true

        SpotterfishSession.trackEvent(
          App.spotterfishSession, 
          'restored_from_archive', 
            {
              userUID: App.spotterfishSession.userSession.firebaseCurrentUser.uid,
              archiveUID: this.selectedArchive.uid
            }
        )

        const itemSnapshot = await App.firestoreDB.collection('archived_versions').doc(this.selectedArchive.uid).get()
        const archiveItem = itemSnapshot.data().versionObject
        this.selectCurrentAudioAndVideo(archiveItem)
        this.projectEditedData.versionDB.video_file_start_smpte = archiveItem.video_file_start_smpte
        this.projectEditedData.versionDB.video_framerate = archiveItem.video_framerate
        this.projectEditedData.versionDB.name = archiveItem.name
        this.projectEditedData.versionDB.description = archiveItem.description
        this.restoredMarkerLanes = archiveItem.lanes
        this.earlierVersionsDialog = false

        try {
          await CloudClient.call_CFrestoreVersionFromJSONArchive(App.Firebase, this.projectEditedData.projectDB, archiveItem)
          await this.getSavedDataFromDB()
          this.spinner = false          
        } catch (error) {
          this.spinner = false
          await this.$root.$globalAlert.open('Error', {
            line1: `Could not restore version (${error.message})`
          },
          { color: this.colors.mainAccent })
        }
      }
    },

    getArchivedVersions () {
      this.archivedVersions = []

      return App.firestoreDB.collection('projects').doc(this.projectID).get().then((doc) => {
        const proj = doc.data()
        for (let item of proj.previous_versions) {
          this.archivedVersions.push(item)
        }
        this.selectedArchive = this.archivedVersions[this.archivedVersions.length - 1]
        this.earlierVersionsDialog = true
      })
    }
  },

  async mounted(){
    // this.open('xy4vifqCdYlFGQfxac4y')
  }
}

</script>

<style scoped lang="scss">

@import '@/assets/global.scss';

.project-settings-dialog{
  border: 1px solid $color-mx-panel;
  background-color: $color-mx-dark-panel;
  border-radius: 8px;
}
.project-settings{
  padding: 2rem 1rem;
  background-color: $color-mx-dark-panel;
  width: 100%;
  border-radius: 8px;
}
.project-info{
  padding: 0 1rem;
  border-right: 2px solid $color-mx-x-dark-panel;
}



.selected {
  background-color: $color-mx-secondary !important;
}

.selected-row {
  background-color: $color-mx-secondary !important;
}
.buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background: rgba(0,0,0,.4);
  .label {
    display: block;
    color: rgb(99, 145, 202);
  }
}

td {
    word-wrap: break-word; // this is the older property
    overflow-wrap: break-word; // this is the newer, preferred property
}

.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px;
  width: 82%;
}
.ellipsis-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-files{
  padding: 0 1rem;
}
.project-file-list{
  background-color: $color-mx-x-dark-panel;
  height: 38vh;
  overflow: auto;
}

.header-row {
  background-color: $color-mx-dark-panel;
  border: 1px solid $color-mx-panel;
}


.av-buttons-col {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.filename-header {
  font-size: 0.8em;
}

.filename-header-small {
  font-size: 0.6em;
}

.file-row {
  height: 34px; 
  display: flex; 
  align-items: center; 
  border-bottom: 1px solid $color-mx-panel;
}
.av-button {
  width: 9% !important; 
  padding: 2px 0; 
  margin: 0;
  display: flex; 
  width: 9% !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    width: 100% !important;
  }
}

</style>
