<template>
  <v-app
    dark
    style="background: black"
    :class="isMobilePhone ? 'mobile-style' : 'none'"
  >
    <v-layout justify-center>
      <v-flex
        xs12
        sm10
        md6
        lg3
        xl3
        id="login"
        style="margin: auto"
        :class="isMobilePhone ? 'mobile-style' : 'none'"
      >
      <v-row no-gutters>
          <v-col cols="12" 
            style="display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 6px 6px 0 0; 
            height: 80px;
            border: 1px solid"
            :style="{ 'background-color': colors.mx.dark, 'border-color': colors.mx.white}">
              <img style="width: 120px;" :src="logos.mixStageLogoWithWhiteText" />
          </v-col>
          <v-col cols="12">
         
            <v-card
              class="elevation-0"
              style="
                background: #eeeeee;
                margin: auto;
                margin-top: 0;
                border-radius: 0 0 6px 6px; 
                height: auto;
                color: black;
              "
              :class="isMobilePhone ? 'mobile-style' : 'none'"
            >
              <v-card-text>
                <v-layout justify-center light>
                  <v-flex xs12 style="color: black">
                    <v-form v-model="signupFormIsValid" ref="signupForm" :class="{'show': showForm}">
                      <v-layout
                        wrap
                        align-center
                        justify-center
                        justify-content-center
                      >
                        <p 
                          style="
                              width: 100%;
                              color: black;
                              font-size: 16px;
                            "
                        >
                        Sign up {{ getTierMessage }}
                        </p>
                        <span v-if="startingTier1">
                          <v-flex
                            xs12
                            style="text-align: left; margin: 0 16px 20px 16px"
                          >
                            <p style="color: black; font-size: 17px">
                              We're giving you a free 2-seat screening room.
                            </p>
                            <p
                              style="
                                color: black;
                                font-size: 17px;
                                margin-top: -10px;
                              "
                            >
                              Want a bigger room? Purchase
                              <router-link
                                :to="{ name: 'signup', query: { tier2: 'true' } }"
                                >here</router-link
                              >
                            </p>
                          </v-flex>
                        </span>
                        <span v-else-if="startingPurchase">
                        </span>
                        

                        <v-flex xs12>
                          <v-text-field
                            autocomplete="email"
                            outlined
                            color="#919191"
                            light
                            dense
                            autofocus
                            class="mt-6"
                            type="text"
                            :rules="[
                              validationRules.email_required,
                              validationRules.email_valid,
                            ]"
                            v-model="userObject.email"
                            placeholder="Email"
                            data-testid="signup-email-input"
                            @keyup.enter="signUp"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            autocomplete="name"
                            outlined
                            color="#919191"
                            light
                            dense
                            class="mb-1"
                            type="text"
                            :rules="[validationRules.username_required]"
                            v-model="userObject.user_name"
                            ref="displaynNameField"
                            placeholder="Display Name"
                            name="displayname"
                            data-testid="signup-username-input"
                            @keyup.enter="signUp"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            light
                            outlined
                            color="#919191"
                            ref="passwordfield"
                            dense
                            class="mb-1"
                            autocomplete="new-password"
                            :rules="[
                              validationRules.password_required,
                              validationRules.password_min_length,
                            ]"
                            v-model="userObject.password"
                            :append-icon="showPW ? 'visibility' : 'visibility_off'"
                            :type="showPW ? 'text' : 'password'"
                            @click:append="showPW = !showPW"
                            placeholder="Password (8 characters minimum)"
                            data-testid="signup-pwd-input"
                            @keyup.enter="signUp"
                          />
                          
                        </v-flex>

                        <v-flex xs12 v-if="startingPurchase" text-align="left">
                          <paymentForm
                            ref="paymentForm"
                            @payment-complete="handlePaymentComplete"
                            @payment-popup-failed="handleFailedPaymentPopup"
                            @input-name-changed="handleInputNameChanged"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-checkbox
                            light
                            dense
                            v-model="userObject.newsletter"
                            label="Sign up for MixStage™ news letter"
                            style="color: black; margin-top: 0"
                          />
                        </v-flex>

                        <v-flex xs12 style="padding-bottom: 30px">
                          <v-btn
                            :disabled="!signupFormIsValid || !fullNameValid"
                            v-if="!paymentComplete"
                            dark
                            large
                            :style="{
                              background: colors.mx.secondary + ' !important',
                              width: '100%',
                            }"
                            class="lighten-2 mt-5 elevation-5"
                            v-on:click="signUp"
                            data-testid="create-account-btn"
                          >
                            Create Account
                          </v-btn>
                          <span v-if="!startingTier1 && !startingPurchase">
                            <v-flex class="mt-1s" xs12 style="color: black; font-size: 16px" v-if="isLoggedInAsAnonymous()">You are entering a room that requires a full account.</v-flex>
                            <v-flex class="mt-1" xs12 style="color: black;" :style="{ color: colors.mx.light, 'margin-top': '4px' }">
                              Already have a MixStage™ account?
                              <strong style="cursor: pointer;" @click="pushToLogin()">Log in</strong>
                            </v-flex>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <div class="progress" :class="{'show': !showForm}">
                      <div class="item" v-if="creatingAccount">
                        <v-progress-circular
                          :size="20"
                          :width="2"
                          color="primary"
                          indeterminate
                        />
                        Creating account
                      </div>
                      <div class="item" v-if="accountCreated">
                        <v-icon
                          class="verified-badge"
                          color="green"
                          small
                        >
                          mdi-check-decagram
                        </v-icon>
                        Account created
                      </div>
                      <div class="item" v-if="checkingOut">
                        <v-progress-circular
                          :size="20"
                          :width="2"
                          color="primary"
                          indeterminate
                        />
                        Checking out
                        <p v-if="openingPaymentLinkFailed">It seems like your browser has blocked the checkout page popup, click <a :href="paymentLink" target="_blank">here</a> to continue</p>
                      </div>
                      <div class="item" v-if="paymentComplete">
                        <v-icon
                          class="verified-badge"
                          color="green"
                          small
                        >
                          mdi-check-decagram
                        </v-icon>
                        Payment accepted
                      </div>
                      <div class="item complete" v-if="paymentComplete">
                        <p>Thank you for your purchase!</p>
                        <v-btn @click="gotoDashboard">To dashboard</v-btn>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-overlay
          v-if="spinner"
          persistent
          style="z-index: 1010; background-color: rgba(0, 0, 0, 0.8)"
        >
        <mixstage-spinner
          :showSpinner="true"
          :loadingMessage="creatingAccount ? 'Creating account...' : 'Logging in...'"
          style="background-color: transparent; width: 300px; height: 100px;">
        </mixstage-spinner>
        </v-overlay>

        <alert ref="alert"></alert>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { mapGetters } from 'vuex'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import VueRecaptcha from 'vue-recaptcha'
import alert from '@/components/UI/alert.vue'
import { isMobile } from 'mobile-device-detect'

import { decryptSecret } from '@/../source_files/web_client/ScreeningRoomSession'

import CloudClient from '@/../source_files/web_client/CloudClient'
import paymentForm from '../payment/paymentForm.vue'
import constants from '@/../source_files/spotterfish_library/consts'
import spinner from '@/components/dashboard/mixStageSpinner.vue'

const SUBSCRIPTION_TYPES = ['Yearly ($9/mo)', 'Monthly ($11/mo)']

const TAX_TYPES = ['Business', 'Individual']

export default {
  name: 'login',
  components: {
    VueRecaptcha,
    alert,
    paymentForm,
    'mixstage-spinner': spinner
  },
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      showPW: false,
      userObject: {
        deleted_account: false,
        email: '',
        user_name: '',
        password: '',
        newsletter: false,
        promo_code: '',
        tier1: '',
        subscription_details: {
          full_name: '',
          subscription_type:
            this.$route.query.payment === 'yearly'
              ? SUBSCRIPTION_TYPES[0]
              : SUBSCRIPTION_TYPES[1],
          tax_type: TAX_TYPES[0],
        },
      },
      newRoomId: '',
      promoCodeError: '',
      signupFormIsValid: false,
      validationRules: {
        email_required: (value) => !!value || 'E-mail is required',
        email_valid: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'E-mail must be valid',
        username_required: (value) => !!value || 'Username is required',
        password_required: (value) => !!value || 'Password is required',
        password_min_length: (value) =>
          value.length >= 8 || 'Password must be at least 8 characters',
        full_name_required: (value) => !!value || 'Full name is required',
      },
      spinner: false,
      analytics: {
        didLogClickCreate: false,
        didLogFacebookViewContent: false,
        didLogFacebookSignedUp: false,
      },
      inputSubscriptionTaxTypes: TAX_TYPES,
      inputSubscriptionTypeItems: SUBSCRIPTION_TYPES,
      authListener: undefined,

      paymentFormInputName: undefined,
      showForm: true,
      creatingAccount: false,
      accountCreated: false,
      checkingOut: false,
      openingPaymentLink: false,
      paymentLinkOpened: false,
      openingPaymentLinkFailed: false,
      paymentComplete: false,
      paymentLink: undefined
    }
  },

  computed: {
    isIndividualCustomer() {
      return (
        this.userObject.subscription_details.tax_type ===
        this.inputSubscriptionTaxTypes[1]
      )
    },
    currentUser() {
      return (
        App.spotterfishSession.userSession &&
        App.spotterfishSession.userSession.firebaseCurrentUser
      )
    },

    startingTier1() {
      return this.$route.query.tier1 === 'free'
    },
    startingPurchase() {
      return this.$route.query.tier2 === 'true' || this.$route.query.team === 'true'
    },
    startingTeam() {
      return this.$route.query.team === 'true'
    },
    passedInEmail () {
      return this.$route.query.email
    },
    getTierMessage () {
      if (this.$route.query.tier2 === 'true') {
        return constants.TIER_MESSAGES.tier2
      } else if (this.$route.query.tier3 === 'true'){
        return constants.TIER_MESSAGES.tier3
      }
      else if (this.$route.query.tier4 === 'true'){
        return constants.TIER_MESSAGES.tier4
      }
       else {
        return constants.TIER_MESSAGES.tier1
      }
    },
    isMobilePhone() {
      return isMobile
    },
    paymentFormBusinessName(){
      return this.$refs?.paymentForm?.inputName
    },
    fullNameValid(){
      if(this.startingPurchase && !Boolean(this.paymentFormInputName)){
        return false
      }
      return true
    },

  },
  methods: {
    pushToLogin() {
      this.$router.push({ 
          name: 'login', 
          query: {
          hash: this.$route.query.hash,
          },
        }).catch((e) => {
          console.log(e)
        })
    },
    logClickCreate() {
      if (this.analytics.didLogClickCreate === false) {
        console.debug(
          `Logging event 'click_create_account' with email = ${this.userObject.email}, userName = ${this.userObject.user_name}`
        )

        const params = {
          email: this.userObject.email,
          userName: this.userObject.user_name,
          invitedBy: this.getInvitedBy()
        }

        SpotterfishSession.trackEvent(App.spotterfishSession, 'create_account_button_clicked', params)

        this.analytics.didLogClickCreate = true
      }
    },
    handleInputNameChanged(e){
      this.paymentFormInputName = e
    },
    logFacebookViewContent() {
      if (this.analytics.didLogFacebookViewContent === false) {
        // eslint-disable-next-line
        fbq('track', 'ViewContent')
        this.analytics.didLogFacebookViewContent = true
      }
    },

    logFacebookSignedUp() {
      if (this.analytics.didLogFacebookSignedUp === false) {
        // eslint-disable-next-line
        fbq('track', 'Subscribe', {
          value: '0.00',
          currency: 'USD',
          predicted_ltv: '0.00',
        })
        this.analytics.didLogFacebookSignedUp = true
      }
    },

    getInvitedBy() {
      if (this.$route.query.hash) {
        const link = decryptSecret(this.$route.query.hash)
        return link.invitedBy ? link.invitedBy : ''
      }
      return ''
    },

    async handleMobileClient() {
      try {
        await CloudClient.call_CFsendWelcomeEmailWithLinkToNewRoom(
          App.Firebase,
          this.userObject.email,
          result.newRoomId
        )
        window.location.href =
          'https://www.mixstage.io/'
      } catch (error) {
        console.log(error)
        this.spinner = false
        await this.$refs.alert.open(
          'Error',
          {
            line1: error.message || error,
          },
          { color: this.colors.mainAccent }
        )
        this.spinner = false
      }
      return
    },
    handleFailedPaymentPopup(e) {
      console.log('Popup failed', e)
      this.openingPaymentLinkFailed = true
      this.paymentLink = e
    },

    signUp() {
      // don't send subscription_details unless user subscribes
      if (!this.startingPurchase) {
        delete this.userObject.subscription_details
      }

      this.showForm = false
      this.creatingAccount = true

      this.logClickCreate()
      this.createAccount()
    },

    gotoDashboard() {
      this.$router.push({ name: 'dashboard' })
    },

    async createSpotterfishUser(invitedBy) {

      let idToken
      let upggradedUser
      if (this.isLoggedInAsAnonymous()) {
        const credential = App.Firebase.auth.EmailAuthProvider.credential(this.userObject.email, this.userObject.password)
        await App.Firebase.auth().currentUser.linkWithCredential(credential)
        idToken = await App.Firebase.auth().currentUser.getIdToken()
        console.log(App.Firebase.auth().currentUser)
        upggradedUser = true
      }
      
      const result = await CloudClient.call_CFinitializeUser(
        App.Firebase,
        this.userObject,
        invitedBy,
        idToken
      )
      if (result.status === 'error' && result.error === 'invalid_promo') {
        this.promoCodeError = 'Invalid promo code'
        this.spinner = false
        return
      } else if (
        result.status === 'error' && result.error === 'email_already_exists'
      ) {
        // Redirect user to login instead, with message.
        this.spinner = false
        await this.$refs.alert.open(
          'Could not create a new account',
          {
            line1: `This email already exists, you will be redirected to the Log In page.`,
            line5: `${
              this.startingPurchase
                ? 'Once signed in you may upgrade your account to Premium. Click the UPGRADE button in your dashboard top bar.'
                : ''
            }`,
          },
          { color: this.colors.mx.panel }
        )
        this.$router
          .push({
            name: 'login',
            params: {
              email: this.userObject.email,
            },
          })
          .catch((e) => {
            console.log(e)
          })
        this.spinner = false
        return
      }

      let success
      try {
        success = await CloudClient.call_CFcreateDemoProjects(
          App.Firebase,
          result.newUserId
        )
      } catch (error) {
        console.warn(error)
        success = false
      }
      if (!success) {
        console.error('Could not create demo projects')
      }
      // If the user signed up via mobile, send welcome email and redirect to the thanks page. Else,
      // log in the user and send them to their new screening room.
      if (isMobile) {
        this.handleMobileClient()
      }
      // if we just upgraded user - we were logged in to start with, run onauthstatechanged manually
      if (upggradedUser) {
        this.onFBAuthStateChanged(App.Firebase.auth().currentUser)
      }
    },

    // async upgradeAccount() {
    //   try {
    //     console.log(this.userObject)
    //     const credential = await App.Firebase.auth.EmailAuthProvider.credential(this.userObject.email, this.userObject.password)
    //     const userCred = await App.Firebase.auth().currentUser.linkWithCredential(credential)
    //     const user = userCred.user
    //     console.log('Anonymous account successfully upgraded', user)
    //   } 
    //   catch (error) {
    //     debugger
    //     throw error
    //   }
    // },
    isLoggedInAsAnonymous() {
      return this.currentUser ? this.currentUser.isAnonymous : false
    },

    async createAccount(onCreated) {
      // this.spinner = true
      const invitedBy = this.getInvitedBy()
      if (this.signupFormIsValid) {
        SpotterfishSession.trackEvent(
          App.spotterfishSession, 
          'account_created', 
            {
              mail: this.userObject.email,
              userName: this.userObject.user_name,
              invitedBy: invitedBy,
            }
        )

        this.userObject.email = this.userObject.email.toLowerCase()
        // ??? this is a very unneccesary entry  - do we ever use it???
        this.userObject.tier1 = this.startingTier1
        try {

          await this.createSpotterfishUser(invitedBy)

          if (!this.isLoggedInAsAnonymous()) {
            await App.Firebase.auth().signInWithEmailAndPassword(
              this.userObject.email,
              this.userObject.password
            )
            console.log('New user signed in, will be handled by onAuthstateChanged')
          }
          console.log('Update or create user done')
          this.creatingAccount = false
          this.accountCreated = true
        }
        catch (error) {
          // need to cleanup after any errors
          // await this.deleteSpotterfishUser()
          this.spinner = false
          console.log(error)
          await this.$refs.alert.open(
            'Error',
            {
              line1: error,
            },
            { color: this.colors.mainAccent }
          )
        }
      } else {
        console.log('not valid yet')
        this.spinner = false
      }
    },

    async onFBAuthStateChanged(user) {
      
      if (user) {
        this.logFacebookSignedUp()
        await SpotterfishSession.detectLoggedinTransient(App.spotterfishSession)
        console.log(App.spotterfishSession.userSession.DBIDMapping)
        console.log(
          Object.keys(App.spotterfishSession.userSession.DBIDMapping)[0]
        )
        const roomDBId = Object.keys(
          App.spotterfishSession.userSession.DBIDMapping
        )[0]
        const hash = await this.$store.dispatch('generateHash', {
          sroom: roomDBId,
        })
        this.spinner = false
        // We should check that the account is verified here, before letting the user go further.
        // Unverified accounts should??? not be able to join a room? show warning of unverified??
        if(this.startingPurchase && !this.showForm){
          this.checkingOut = true
          this.$refs.paymentForm.startPayment()
        } else {
          if (this.$route.query.hash) {
            this.$router
              .push({ name: 'screeningroom', query: this.$route.query })
              .catch((e) => {
                console.log(e)
              })
          } else if (hash && this.startingTier1) {
            this.$router
              .push({ name: 'lobby', query: { hash: hash } })
              .catch((e) => {
                console.log(e)
              })
          } else {
            this.$router.push({ name: 'dashboard' }).catch((e) => {
              console.log(e)
            })
          }
        }

      } else {
        console.debug('No user - no redirect will happen')
      }
    },
    handlePaymentComplete(){
      this.paymentComplete = true
      this.checkingOut = false
    },

  },

  async mounted() {
    CloudClient.call_wakeUpAllCloudFUnctions(App.Firebase)
    this.logFacebookViewContent()
    this.userObject.email = this.$route.query?.email ? this.$route.query.email : undefined
    
    // Workaround for buggy form highlights in Safari on iOS.
    // https://github.com/vuetifyjs/vuetify/issues/11942#issuecomment-716015097
    const form = this.$refs.signupForm.$el
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(({ type, target }) => {
        const fieldset = target.querySelector('fieldset')
        if (!fieldset) {
          return
        }
        fieldset.style = ''
        if (
          type === 'attributes' &&
          target.classList.contains('v-input--has-state')
        ) {
          const activeClass = ['success', 'error'].filter((i) =>
            target.classList.contains(`${i}--text`)
          )[0]
          activeClass === 'error'
            ? (fieldset.style = 'border-color: #ff5252 !important;')
            : (fieldset.style = 'border-color: #4caf50 !important;')
        }
      })
    })
    observer.observe(form, { attributes: true, subtree: true })

    if ( this.userObject.email && (this.currentUser?.email !== this.userObject.email)) {
      App.Firebase.auth().signOut()
    }
    
    if (this.currentUser && !this.currentUser.isAnonymous) {

      this.$router.push({ name: 'dashboard', query: { openUpgrade: true } }).catch((e) => {
        console.log(e)
      })
    } else if (this.currentUser && this.currentUser.isAnonymous){
      this.userObject.user_name = this.currentUser.displayName
    }

    this.authListener = App.Firebase.auth().onAuthStateChanged(async (user) => {
      console.debug(`Detected ${ user? 'login' : 'logout' }`)
      if (user?.isAnonymous) {
        console.log('doing nothing at this point, anonymous user that should be upgraded')
      } else {
        this.onFBAuthStateChanged(user)
      }
    })

  },

  beforeDestroy() {
    this.authListener && this.authListener()
  },
}
</script>

<style lang="scss" scoped>
form {
  display: none;
  &.show{
    display: block;
  }
}
h2 {
  font-size: 30px;
  margin: 16px 0;
}
img {
  float: none;
  width: 90%;
  max-width: 280px;
  margin: auto;
}

input {
  margin: 5px 0;
  width: 20%;
  min-width: 240px;
  height: 25px;
  padding: 15px;
  font-family: 'Inter', sans-serif;
}

.progress{
  display: none;
  padding: 1rem 2rem;
  &.show{
    display: block;
  }
  .item{
    margin-bottom: 0.5rem;
    text-align: center;
    p {
      margin: 0;
    }
    &.complete {
      text-align: center;
    }
  }
}

button {
  margin-top: 10px;
  color: black;
  width: 240px;
  cursor: pointer;
}

.status {
  margin-top: 20px;
}

p {
  font-family: 'Inter', sans-serif;
  margin-top: 40px;
  font-size: 14px;
}

p a {
  font-family: 'Inter', sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.inputrow {
  margin: 0;
}

.bull {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.mobile-style {
  padding-top: 10px !important;
  margin-top: 0 !important;
  background: #eeeeee !important;
  border: none !important;
}

@media only screen and (max-width: 400px) {
  .mobile-style {
    padding-top: 0 !important;
  }
}
</style>
