<template>
  <span>
  </span>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import App from '@/App.vue'

export default {
  name: 'signout',
  beforeMount () {
    let self = this
    firebase.auth().signOut().then(() => {
      // should refresh ...
      App.spotterfishSession.userSession = undefined
      self.$router.replace('login')
      self.$forceUpdate()
    })
  }
}
</script>
