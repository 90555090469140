/*
# 2022-05-30 Logix Pro 10.7.1

# Setup Logic + MTC & MMC output:
Preferences / MIDI / Sync / MIDI sync Project Settings
MTC  and MMC Checked for IAC Driver

# RESULT

1. Sends no start message
2. During loop, only QF are sent, no MTC fullframe or MMC
3. At stop, MMC STOP is sent.
4. Never sends MTC full frame
5. Does not send QF in stop mode
6. Sends Note off every second? for C-2

*/

//Playing short loop: 01:00:07:06 --- 01:00:07:24, @30fps
const kLogic_playingShortLoop = `
19:56:38.979	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:38.989	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:39.000	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:39.010	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:39.021	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:39.031	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:39.041	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:39.052	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:39.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:39.075	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:39.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:39.093	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:39.104	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:39.114	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:39.125	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:39.135	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:39.146	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:39.156	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:39.166	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:39.184	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:39.187	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:39.198	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:39.208	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:39.218	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:39.229	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:39.239	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:39.250	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:39.416	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:39.429	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:39.437	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:39.447	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:39.458	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:39.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:39.479	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:39.479	From IAC Driver Bus 1	Note Off	1	C-2	64
19:56:39.989	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.004	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:40.010	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:40.020	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:40.031	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.041	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.052	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.072	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:40.109	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:40.109	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:40.114	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.125	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.135	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.145	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.156	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.166	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:40.177	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:40.187	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:40.214	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.214	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.218	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.229	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.239	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.250	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:40.260	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:40.270	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:40.281	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.291	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.319	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.319	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.322	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.333	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:40.343	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:40.354	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:40.364	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.375	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.385	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.424	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.424	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.424	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:40.427	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:40.437	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:40.447	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:40.458	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:40.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:40.479	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:40.489	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:40.528	From IAC Driver Bus 1	Note Off	1	C-2	64
19:56:41.000	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:41.039	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:41.039	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:41.039	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:41.041	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:41.052	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:41.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:41.072	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:41.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:41.093	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:41.147	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:41.147	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:41.147	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:41.147	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:41.147	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:41.156	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:41.166	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:41.177	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:41.187	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:41.197	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:41.223	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:41.223	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:41.229	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:41.239	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:41.250	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:41.416	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:41.437	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:41.437	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:41.447	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:41.458	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:41.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:41.479	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:41.489	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:41.499	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:41.500	From IAC Driver Bus 1	Note Off	1	C-2	64
19:56:42.010	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:42.035	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:42.035	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.041	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.051	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.072	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:42.093	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:42.104	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:42.140	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.140	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.140	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.145	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.156	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.166	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:42.176	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:42.187	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:42.197	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.208	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.243	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.243	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.243	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.249	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:42.260	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:42.270	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:42.281	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.291	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.301	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.348	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.348	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.348	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:42.348	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:42.354	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:42.364	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.374	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.385	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.395	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.406	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.452	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:42.452	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:42.452	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:42.452	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:42.458	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:42.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:42.479	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:42.489	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:42.499	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:42.510	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:42.513	From IAC Driver Bus 1	Note Off	1	C-2	64
19:56:43.020	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:43.046	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:43.046	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:43.052	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:43.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:43.072	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:43.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:43.093	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:43.104	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:43.114	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:43.144	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:43.144	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:43.145	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:43.156	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:43.166	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:43.177	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:43.187	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:43.197	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:43.208	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:43.218	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:43.248	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:43.248	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:43.249	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:43.416	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:43.454	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:43.455	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:43.455	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:43.458	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:43.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:43.478	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:43.489	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:43.499	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:43.510	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:43.515	From IAC Driver Bus 1	Note Off	1	C-2	64
19:56:44.020	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:44.050	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.050	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.051	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.062	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.072	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:44.083	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:44.093	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:44.103	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:44.114	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.165	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.165	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.165	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.165	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		4
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.244	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:44.299	From IAC Driver Bus 1	MTC Quarter Frame		6
19:56:44.299	From IAC Driver Bus 1	MTC Quarter Frame		17
19:56:44.299	From IAC Driver Bus 1	MTC Quarter Frame		32
19:56:44.299	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.299	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.301	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.312	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.322	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:44.333	From IAC Driver Bus 1	MTC Quarter Frame		0
19:56:44.343	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:44.364	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:44.364	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.374	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.385	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.395	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.405	From IAC Driver Bus 1	MTC Quarter Frame		112
19:56:44.416	From IAC Driver Bus 1	MTC Quarter Frame		2
19:56:44.426	From IAC Driver Bus 1	MTC Quarter Frame		16
19:56:44.437	From IAC Driver Bus 1	MTC Quarter Frame		33
19:56:44.468	From IAC Driver Bus 1	MTC Quarter Frame		49
19:56:44.468	From IAC Driver Bus 1	MTC Quarter Frame		68
19:56:44.468	From IAC Driver Bus 1	MTC Quarter Frame		81
19:56:44.478	From IAC Driver Bus 1	MTC Quarter Frame		97
19:56:44.489	From IAC Driver Bus 1	MTC Quarter Frame		112
`

//# Some locates by clicking timeline in stop mode
const kLogic_locates =
`
19:57:22.094	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:23.099	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:23.722	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 15 0C 00 00 F7
19:57:24.110	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:25.139	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:26.004	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 16 00 00 00 F7
19:57:26.170	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:27.072	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 16 0A 00 00 F7
19:57:27.183	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:27.972	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 16 1E 00 00 F7
19:57:28.200	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:28.838	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 16 32 00 00 F7
19:57:29.220	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:30.154	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 17 0E 00 00 F7
19:57:30.224	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:31.234	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:31.753	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 15 2A 00 00 F7
19:57:32.250	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:32.922	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 15 00 00 00 F7
19:57:33.270	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:34.300	From IAC Driver Bus 1	Note Off	1	C-2	64
19:57:35.313	From IAC Driver Bus 1	Note Off	1	C-2	64
`


//# Play linearly from pos 0. Notice: sends MMC STOP *and* MMC LOCATE when user hits stop
const kLogic_playLinearAndStop =
`
19:58:14.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:14.898	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:14.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:14.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:14.969	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:14.969	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:15.440	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.461	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.461	From IAC Driver Bus 1	MTC Quarter Frame		10
19:58:15.471	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:15.481	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.492	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.502	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.513	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.523	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.534	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.563	From IAC Driver Bus 1	MTC Quarter Frame		12
19:58:15.563	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:15.565	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.575	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.596	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.606	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:15.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:15.670	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.670	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.670	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.679	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:15.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:15.731	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.780	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.780	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.780	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:15.804	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:15.815	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.880	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.880	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.880	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.880	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:15.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:15.898	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:15.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:15.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:15.929	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:15.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:15.982	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:15.982	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:16.461	From IAC Driver Bus 1	MTC Quarter Frame		10
19:58:16.504	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:16.504	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.504	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.504	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.513	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.523	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.534	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.544	From IAC Driver Bus 1	MTC Quarter Frame		12
19:58:16.554	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:16.606	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.606	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.606	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.607	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.607	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:16.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:16.648	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.686	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.686	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:16.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:16.731	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.752	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.790	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.790	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.790	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:16.804	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:16.815	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.856	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.894	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.894	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:16.894	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:16.898	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:16.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:16.929	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:16.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:16.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:16.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:16.997	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:16.997	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:16.997	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:17.492	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.529	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.530	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.530	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.534	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.544	From IAC Driver Bus 1	MTC Quarter Frame		12
19:58:17.554	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:17.565	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.575	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.633	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.633	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.633	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.633	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:17.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:17.648	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.669	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.679	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.733	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.733	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:17.733	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:17.733	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.752	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.814	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:17.814	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:17.815	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.856	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.877	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:17.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:17.918	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.918	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:17.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:17.929	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:17.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:17.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:17.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:17.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:17.982	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:17.992	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.022	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.022	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.022	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:18.523	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.554	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.554	From IAC Driver Bus 1	MTC Quarter Frame		12
19:58:18.554	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:18.565	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.575	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.596	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.607	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.659	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:18.659	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:18.659	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.669	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:18.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:18.762	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.762	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.762	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:18.804	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:18.815	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.865	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.866	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.866	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.866	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.877	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:18.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:18.898	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:18.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:18.947	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:18.947	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:18.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:18.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:18.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:18.982	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:18.992	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.002	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.050	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.050	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:19.544	From IAC Driver Bus 1	MTC Quarter Frame		12
19:58:19.571	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:19.572	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.575	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.596	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.607	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:19.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:19.675	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.675	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.675	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.679	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:19.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:19.732	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.783	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.783	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.783	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:19.804	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:19.815	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.881	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.881	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.881	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:19.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:19.898	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:19.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:19.929	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:19.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:19.993	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:19.993	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:19.993	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:19.993	From IAC Driver Bus 1	MTC Quarter Frame		38
19:58:19.993	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.002	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:20.092	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:20.555	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:20.576	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.576	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.596	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.607	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:20.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:20.648	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.679	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.679	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:20.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:20.732	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.752	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.785	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.785	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.785	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:20.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:20.815	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.889	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.889	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.889	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:20.889	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:20.898	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:20.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:20.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:20.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:20.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:20.995	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:20.995	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:20.995	From IAC Driver Bus 1	MTC Quarter Frame		39
19:58:20.995	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.002	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:21.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:21.100	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:21.565	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.606	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.606	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.606	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.607	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:21.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:21.648	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.710	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.710	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.710	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.710	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:21.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:21.732	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.752	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.788	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.788	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:21.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:21.815	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.892	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:21.892	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:21.898	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:21.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:21.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:21.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:21.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:21.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:21.997	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:21.997	From IAC Driver Bus 1	MTC Quarter Frame		40
19:58:21.997	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.002	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:22.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:22.065	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:22.101	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.101	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:22.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.620	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.620	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.620	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:22.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:22.648	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:22.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.669	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.724	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.724	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.724	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:22.724	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:22.732	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:22.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.752	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.831	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:22.831	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:22.831	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:22.831	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:22.908	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:22.908	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:22.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:22.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:22.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:22.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:22.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:22.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:22.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:22.982	From IAC Driver Bus 1	MTC Quarter Frame		41
19:58:23.013	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.013	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:23.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:23.065	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:23.075	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.080	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:23.586	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.617	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.617	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.627	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:23.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:23.648	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:23.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.669	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.723	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.723	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.723	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:23.723	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:23.732	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:23.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.823	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:23.823	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:23.823	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:23.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:23.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:23.934	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:23.934	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:23.934	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:23.934	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:23.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:23.950	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:23.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:23.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:23.982	From IAC Driver Bus 1	MTC Quarter Frame		42
19:58:24.035	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.035	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.036	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.036	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.036	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:24.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:24.065	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.075	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.111	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.112	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.112	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:24.617	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.641	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:24.641	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:24.648	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.669	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.700	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:24.748	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:24.748	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.748	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:24.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:24.815	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.854	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.854	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.854	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:24.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:24.898	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:24.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:24.957	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:24.957	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:24.957	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:24.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:24.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:24.982	From IAC Driver Bus 1	MTC Quarter Frame		43
19:58:24.992	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.063	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.063	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.063	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:25.063	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:25.065	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.075	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.096	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.168	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:25.628	From IAC Driver Bus 1	MTC Quarter Frame		14
19:58:25.673	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:25.673	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.673	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.673	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.701	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:25.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:25.749	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.749	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.773	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:25.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:25.815	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.825	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.855	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.855	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:25.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:25.898	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:25.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:25.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:25.960	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:25.960	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:25.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:25.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:25.982	From IAC Driver Bus 1	MTC Quarter Frame		44
19:58:25.992	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.061	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.061	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:26.061	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:26.065	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.076	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.096	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.128	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:26.170	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:26.638	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:26.678	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.678	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.678	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.701	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:26.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:26.732	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.782	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.782	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.782	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.782	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:26.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:26.815	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.826	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.859	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.859	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:26.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:26.899	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:26.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:26.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:26.966	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:26.966	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:26.966	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:26.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:26.982	From IAC Driver Bus 1	MTC Quarter Frame		45
19:58:26.992	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.070	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:27.070	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:27.070	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:27.076	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.096	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.128	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:27.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:27.176	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:27.176	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:27.659	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.696	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.696	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.696	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.701	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:27.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:27.732	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:27.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.797	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.797	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.797	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.797	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:27.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:27.815	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:27.826	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.904	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.904	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:27.904	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:27.904	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:27.909	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:27.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:27.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:27.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:27.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:27.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:28.011	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:28.011	From IAC Driver Bus 1	MTC Quarter Frame		46
19:58:28.011	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.011	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.023	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:28.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:28.086	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:28.086	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.096	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.128	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:28.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:28.149	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:28.159	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.192	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.192	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:28.680	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.710	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.710	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:28.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:28.732	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:28.742	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.774	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.815	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.815	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:28.815	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:28.815	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:28.826	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:28.919	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:28.919	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:28.919	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:28.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:28.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:28.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:28.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:28.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:28.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:28.982	From IAC Driver Bus 1	MTC Quarter Frame		47
19:58:29.028	From IAC Driver Bus 1	MTC Quarter Frame		48
19:58:29.028	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.028	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.028	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:29.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:29.065	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.076	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:29.132	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.132	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.132	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.132	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.132	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:29.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:29.149	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.159	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:29.169	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.180	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.208	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:29.690	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.711	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:29.721	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:29.732	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.742	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:29.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.774	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.816	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:29.816	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:29.816	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.826	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:29.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:29.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:29.923	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.923	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:29.923	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:29.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:29.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:29.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:29.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:29.971	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:29.982	From IAC Driver Bus 1	MTC Quarter Frame		32
19:58:29.992	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.027	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.027	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.027	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:30.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:30.065	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.076	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.133	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.133	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.133	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.133	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:30.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:30.149	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.159	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.169	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.180	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.190	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.190	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:30.701	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.743	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:30.743	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:30.743	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.743	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.774	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:30.821	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:30.821	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.826	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.846	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:30.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:30.899	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.928	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:30.928	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:30.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:30.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:30.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:30.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:30.972	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:30.982	From IAC Driver Bus 1	MTC Quarter Frame		33
19:58:30.992	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.032	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.032	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.032	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:31.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:31.065	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.076	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.097	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.139	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.139	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.139	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:31.139	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:31.149	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.159	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.169	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.180	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.190	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.201	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.241	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:31.711	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:31.750	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:31.750	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.750	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.753	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.774	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:31.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:31.855	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.855	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.855	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.855	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.867	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.878	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:31.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:31.899	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.909	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:31.960	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:31.960	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:31.960	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:31.960	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:31.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:31.972	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:31.982	From IAC Driver Bus 1	MTC Quarter Frame		34
19:58:31.992	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.039	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.039	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:32.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:32.065	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.076	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.097	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.144	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.144	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:32.144	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:32.149	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.159	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.169	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.180	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.190	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.201	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.211	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:32.251	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:32.251	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.251	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:32.742	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.775	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.775	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.775	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:32.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:32.815	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.826	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.883	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.883	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.883	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.883	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:32.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:32.899	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.909	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:32.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:32.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:32.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:32.990	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:32.990	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:32.990	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:32.990	From IAC Driver Bus 1	MTC Quarter Frame		35
19:58:32.992	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.024	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:33.092	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:33.092	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.092	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.092	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.097	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.128	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:33.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:33.166	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.166	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.169	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.180	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.190	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.201	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.211	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:33.222	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:33.232	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.242	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.265	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.265	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:33.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.781	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.795	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:33.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:33.815	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.826	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.847	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.881	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.881	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:33.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:33.899	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.909	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:33.919	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:33.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:33.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:33.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:33.961	From IAC Driver Bus 1	MTC Quarter Frame		6
19:58:33.982	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:33.982	From IAC Driver Bus 1	MTC Quarter Frame		36
19:58:33.992	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.003	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.013	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.024	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.034	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.044	From IAC Driver Bus 1	MTC Quarter Frame		0
19:58:34.055	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:34.084	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:34.084	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.086	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.097	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.107	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.117	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.128	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:34.138	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:34.149	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:34.159	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.181	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.181	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.190	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.201	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.211	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:34.222	From IAC Driver Bus 1	MTC Quarter Frame		16
19:58:34.232	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:34.242	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.253	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.260	From IAC Driver Bus 1	Note Off	1	C-2	64
19:58:34.763	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.782	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.784	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.794	From IAC Driver Bus 1	MTC Quarter Frame		2
19:58:34.805	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:34.815	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:34.826	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.836	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.847	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.857	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.880	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.880	From IAC Driver Bus 1	MTC Quarter Frame		4
19:58:34.888	From IAC Driver Bus 1	MTC Quarter Frame		17
19:58:34.899	From IAC Driver Bus 1	MTC Quarter Frame		37
19:58:34.909	From IAC Driver Bus 1	MTC Quarter Frame		49
19:58:34.920	From IAC Driver Bus 1	MTC Quarter Frame		64
19:58:34.930	From IAC Driver Bus 1	MTC Quarter Frame		80
19:58:34.940	From IAC Driver Bus 1	MTC Quarter Frame		97
19:58:34.951	From IAC Driver Bus 1	MTC Quarter Frame		112
19:58:34.988	From IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 01 00 15 15 00 F7
`





//Logic loop between 2:46:30:00 and 2:46:32:00 (start + 4 times loop + stop commanD @30FPS)
const kLogic_loop2 =
`
0.000	From IAC Driver Bus 1	SysEx		F0 7F 7F 06 44 06 01 62 2E 1E 00 00 F7
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
0.000	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
0.000	From IAC Driver Bus 1	SysEx		F0 7F 7F 06 44 06 01 62 2E 1E 03 00 F7
`

module.exports = {
	kLogic_playingShortLoop,
	kLogic_locates,
	kLogic_playLinearAndStop,
	kLogic_loop2
}
