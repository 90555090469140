<template>
  <v-app style="background: black;">
  <v-app-bar app ref="toolbar" fixed clipped-left clipped-right :style="{background: colors.black}">
    <img style="width: 158px" :src="logos.mixStageLogoWithWhiteText" />
    <v-spacer></v-spacer>
  </v-app-bar>
    <v-flex xs10 md6 lg3 xl3 id="login" style="margin: auto;">
      <v-card ref="loginContainer" class="elevation-1" style="background: #EEEEEE; margin: auto; margin-top: 0; border-radius: 6px; height: 440px; color: black;">
        <v-card-text>
          <v-layout justify-center>
            <v-flex xs12>
              <form>
              <v-layout
              wrap
              align-center
              justify-center
              justify-content-center>
              <h2 style="margin: 16px 0 26px 0; color: black; font-size: 30px;">Log In Confirmation</h2>
              <v-flex xs11>
                <v-text-field
                  outlined
                  color="#919191"
                  light
                  dense
                  autofocus
                  autocomplete="email" class="mt-6" style="color: black;" type="text" v-model="email" placeholder="Email" @keyup.enter="login"/>
              </v-flex>
              <v-flex xs11>
                <v-text-field
                  light
                  outlined
                  color="#919191"
                  dense
                  class="mb-1"
                  type="password"
                  v-model="password"
                  autocomplete="current-password"
                  placeholder="Password"
                  @keyup.enter="login"/>
              </v-flex>
              <v-flex xs12>
                <div id="recaptchacontainer"></div>
                <v-btn dark id="sign-in-button" large :style="{background: colors.mainAccent}" class="lighten-2 mt-5 elevation-5" @click="login">
                  Log In
                </v-btn><br/>
                <v-btn large @click.native="PWResetDialog = true">
                  <span v-if="!sendingRequest">Send Password Reset Email</span>
                  <v-progress-circular v-else :size="30" color="primary" indeterminate></v-progress-circular>
                </v-btn>
                <br><br>
              </v-flex>

            </v-layout>
            </form>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <!-- ::::::::::::::PW RESET DIALOG:::::::::::::: -->
    <v-dialog v-model="PWResetDialog" persistent dark max-width="400">
      <v-card dark style="background: #FFFFFF; color: black">
        <v-card-title>
          <span>Please enter your registered email</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                style="margin-top: 20px;"
                outlined
                autofocus
                light
                dense
                autocomplete="current-password"
                v-model="resetEmail"
                :rules="emailRules"
                label="E-mail"
                color="black"
                required>
              </v-text-field>
              <v-btn dark color="black lighten-1" style="max-width: 60px;" @click.native="PWResetDialog = false">Close</v-btn>
              <v-btn dark :color="colors.mainAccent" style="max-width: 60px;" @click="closePasswordResetDialog()">Send</v-btn>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
<alert-component ref="alert"></alert-component>
</v-flex>
</v-app>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import store from '@/store'
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters } from 'vuex'

import CloudClient from '@/../source_files/web_client/CloudClient'
import alert from '@/components/UI/alert.vue'

export default {
  name: 'reauth',
  components: {
    'alert-component': alert,
    VueRecaptcha
  },
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      textRules: [
        v => !!v || 'Required'
      ],
      selectErrors: 'You need to select at least one',
      resetEmail: '',
      password: '',
      logos: require('@/lib/ui/logos.js').logos,
      sendingRequest: false,
      PWResetDialog: false,
      valid: ''
    }
  },
  computed: {
    ...mapGetters([''])
  },
  methods: {
    login () {
      const self = this
      var resolver
      const appVerifier = new App.Firebase.auth.RecaptchaVerifier('recaptchacontainer', {'size': 'invisible'})

      App.Firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
        store.commit('setLoggedInState', true)
        store.commit('setCurrentLoggedInUser', user)
        if (this.$route.query.hash) {
          this.$router.push(
            { name: 'screeningroom',
              query: this.$route.query
            })
            .catch((e) => {
              console.log(e)
            })
        } else {
          this.$router.push({ name: 'dashboard' })
            .catch((e) => {
              console.log(e)
            })
        }
      }).catch(async (error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          resolver = error.resolver
          // Ask user which second factor to use.
          console.log(resolver.hints)
          let hintIndex = 0

          if (resolver.hints.length && resolver.hints.length > 1) {
            hintIndex = await self.$root.$globalPrompt.open(`Two-factor authentication`, 'Please select which phone we should send the pass code to:', resolver.hints, { width: '500px', color: self.colors.mainAccent })
          }
          console.log('hintIndex', hintIndex)
          if (resolver.hints[hintIndex].factorId === App.Firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
            var phoneInfoOptions = { multiFactorHint: resolver.hints[hintIndex], session: resolver.session }
            var phoneAuthProvider = new App.Firebase.auth.PhoneAuthProvider()
            // Send SMS verification code
            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier)
              .then(async function (verificationId) {
                // Ask user for the SMS verification code.
                const verificationCode = await self.$root.$globalPrompt.open(`Enter the verification code`, `We sent the verification code to your registered ${resolver.hints[hintIndex].displayName}`, null, { width: '500px', color: self.colors.mainAccent })
                var cred = App.Firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
                var multiFactorAssertion = App.Firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
                // Complete sign-in.
                return resolver.resolveSignIn(multiFactorAssertion)
              })
              .then(function (userCredential) {
                // User successfully signed in with the second factor phone number.
                if (self.$route.query.hash) {
                  self.$router.push(
                    { name: 'screeningroom',
                      query: self.$route.query
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                } else {
                  self.$router.push({ name: 'dashboard' })
                    .catch((e) => {
                      console.log(e)
                    })
                }
              })
          } else {
            // Unsupported second factor.
            this.$refs.alert.open(
              `Unsupported Second Factor`, {
                line1: error.message
              },
              { color: this.colors.mainAccent })
              .then((confirm) => {})
          }
        } else if (error.code === 'auth/wrong-password') {
          // Handle other errors such as wrong password.
          this.$refs.alert.open(
            `Wrong password`, {
              line1: error.message
            },
            { color: this.colors.mainAccent })
            .then((confirm) => {
              if (confirm) {
              }
            })
        } else {
          this.$refs.alert.open(
            `Error`, {
              line1: error.message
            },
            { color: this.colors.mainAccent })
            .then((confirm) => {
              if (confirm) {
              }
            })
        }
      })
    },

    closePasswordResetDialog: function () {
      if (this.$refs.form.validate()) {
        this.PWResetDialog = false
        this.sendPasswordReset(this.resetEmail)
      }
    },

    // TODO: login.vue and reauth.vue have practically identical functions like this.
    sendPasswordReset: async function (email) {
      this.sendingRequest = true
      try {
        await CloudClient.call_CFsendPasswordReset(App.Firebase, email)
        this.sendingRequest = false
        await this.$refs.alert.open('Success', {
          line1: 'A password reset email was sent to ' + email
        },
        { color: this.colors.mainAccent })
      } catch (error) {
        this.sendingRequest = false
        await this.$refs.alert.open('Error', {
          line1: `Could not send password reset email (${error.message})`
        },
        { color: this.colors.mainAccent })
      }
    }
  },

  async beforeMount () {
    await App.Firebase.auth().signOut()
    App.spotterfishSession.userSession = undefined
  }
}
</script>

<style scoped>
  img {
    float: none;
    width: 90%;
    max-width: 280px;
    margin: auto;
  }

  input {
    margin: 5px 0;
    width: 20%;
    min-width: 240px;
    height: 25px;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
  }

  button {
    margin-top: 10px;
    width: 275px;
    cursor: pointer;
  }

  .status {
    margin-top: 20px
  }

  p {
    font-family: 'Roboto', sans-serif;
    margin-top: 40px;
    font-size: 14px;
  }

  p a {
    font-family: 'Roboto', sans-serif;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
