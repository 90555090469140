<template>
    <v-menu 
      :value="isVisible"
      @input="$emit('context-menu-change', $event)"
      :position-x="getPosition().x"
      :position-y="getPosition().y"
      absolute
      :elevation="0"
      :min-width="'200px'"
      :nudge-right="20"
      :nudge-top="getHeight()"
    >
      <!-- Activator slot is required but will be hidden -->
      <template v-slot:activator="{ on }">
        <div v-on="on"></div>
      </template>

      <div class="context-menu-title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      
      <div class="context-menu-items" @mouseover="showSubmenu = false"
  >
        <slot></slot>
      </div>

      <div 
        class="context-submenu-items"
        @mouseover="showSubmenu = true"
      >
        <slot name="submenu-title"></slot> 

        <div v-show="showSubmenu" class="context-submenu">
          <v-menu 
            v-model="showSubmenu"
            :position-x="getPosition().x + 250"
            :position-y="getPosition().y"
            absolute
            :elevation="0"
            :min-width="'90px'"
            :nudge-top="40"
          >
          <div class="context-submenu-items">
            <slot name="submenu-content"></slot>

          </div>
          </v-menu>
        </div>
      </div>

      <div class="context-menu-actions" v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
    </v-menu>
</template>
   
<script>

export default {
  name: 'ContextMenu',
  props: {
    position: Object,
    isVisible: Boolean,
  },
  data() {
    return {
      showSubmenu: false
    }
  },
  methods: {
    getHeight () {
      return 84
    },
    getPosition () {
      return this.position
    },  
    showMenu(event) {
      this.position.x = event.clientX
      this.position.y = event.clientY
    },
  },
  destroyed() {
    console.log('destroyed')
  },
  mounted() {
    console.log('mounted')
  }
}

</script>
<style lang="scss">

@import '@/assets/global.scss';

  .context-menu-title {
    font-weight: bold;
    padding: 4px 8px;
    cursor: pointer;
    background-color: $color-mx-panel;
  }

  .context-menu-items {
    font-size: 12px;
    background-color: $color-mx-panel;
    text-align: left;
    border: 1px solid $color-mx-light-panel;
    border-radius: 4px;
  }
  .context-menu-items > * {
    padding: 4px 8px;
    cursor: pointer;
  }
  
  .context-menu-items > div:hover {
    background-color: $color-mx-light-panel;
  }

  .context-submenu-items {
    font-size: 12px;
    background-color: $color-mx-panel;
    text-align: left;
    border: 1px solid $color-mx-light-panel;
    border-radius: 4px;
  }

  .context-submenu-items > div:hover {
    background-color: $color-mx-light-panel;
  }

  // .context-menu-items .context-submenu {
  //   position: absolute;
  //   top: 0;
  //   left: 100%;  // This places it to the right of the parent item.
  // }


</style>
  