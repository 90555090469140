/*
  Summary of Error Messages:
  
  Mission Critical Errors (Terminate Session)
  These errors require immediate user notification and session termination 
  because they indicate that the session cannot proceed.

    Error when starting Janus
      console.error('Error when starting Janus: ' + error)
    WebRTC error during plugin handle creation
      console.error('WebRTC error: ' + JSON.stringify(error))

    after several attempt to re-connect, we failed to do so, critical error
      JanusReconnectError
  
  Recoverable errors (should attempt reconnect if applicable):
    These errors indicate issues that might be recoverable. 
    The system should attempt to handle these errors gracefully, and if possible, 
    reconnect or take corrective action without terminating the session.

    iceConnectionError

    Error when checking if room exists
      console.error('Error when checking if room exists: ' + err)
    Error when leaving video room
      console.error('Error when leaving video room: ' + err)
    WebRTC error during DAW stream
      console.error('[[dawstreamStream]] WebRTC error:', error)
    Error when listing participants
      console.error('Error when listing participants: ' + JSON.stringify(err))
    Error when updating number of presenters
      console.error('Error when updating number of presenters: ' + err)
    General video chat error
      console.error('Video chat error: ' + error)
    Video room plugin error during screenshare
      console.error('Video room plugin error in screenshare: ' + error)
    Screenshare error
      console.error('Screenshare error', error)
    
  Non User-Facing Errors
    These errors should be logged and monitored internally. 
    They typically do not impact the user's immediate session experience directly 
    and should not be exposed to the user.

    Error when destroying room
      console.error('Error when destroying room: ' + err)
    Error when unpublishing own feed
      console.error('Error when unpublishing own feed: ' + err)

*/

class WebRTCNotSupportedError extends Error {
  constructor(message) {
    super(message);
    this.name = "WebRTCNotSupportedError";
  }
}

class JanusInitializationError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusInitializationError";
  }
}

class JanusReconnectError extends Error {
  constructor(message){
    super(message);
    this.name = "JanusReconnectError"
  }
}

class UnknownJanusError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnknownJanusError";
  }
}

class IceConnectionError extends Error {
  constructor(message) {
    super(message);
    this.name = "IceConnectionError"
  }
}

class JanusRoomError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusRoomError";
  }
}

class JanusParticipantError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusParticipantError";
  }
}

class JanusWebRTCError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusWebRTCError";
  }
}

class JanusDAWStreamError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusDAWStreamError";
  }
}

class JanusListParticipantsError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusListParticipantsError";
  }
}

class JanusUpdatePresentersError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusUpdatePresentersError";
  }
}

class JanusScreenshareError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusScreenshareError";
  }
}

class JanusGeneralVideoChatError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusGeneralVideoChatError";
  }
}

class JanusDestroyRoomError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusDestroyRoomError";
  }
}

class JanusUnpublishFeedError extends Error {
  constructor(message) {
    super(message);
    this.name = "JanusUnpublishFeedError";
  }
}

export default {
  WebRTCNotSupportedError,
  JanusInitializationError,
  JanusReconnectError,
  UnknownJanusError,
  IceConnectionError,
  JanusRoomError,
  JanusParticipantError,
  JanusWebRTCError,
  JanusDAWStreamError,
  JanusListParticipantsError,
  JanusUpdatePresentersError,
  JanusScreenshareError,
  JanusGeneralVideoChatError,
  JanusDestroyRoomError,
  JanusUnpublishFeedError
}