<template>
  <div class="whip-stream-container">
    <video
      ref="htmlVideoPlayer1"
      class="whip-stream-video"
      playsinline
      autoplay
      crossorigin
      :muted="muted"
      id="htmlVideoPlayer1"
      :poster="getPosterImage()"
    ></video>
  </div>
</template>

<script>
export default {
  name: 'WhipStreamPlayer',
  props: {
    posterImage: {
      type: String,
      default: ''
    },
    muted: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getPosterImage() {
      return this.posterImage;
    },
    setWhipStream(whipStream) {
      const videoElement = this.$refs.htmlVideoPlayer1;
      if (videoElement) {
        // @ts-ignore
        videoElement.srcObject = whipStream;

        // Create a promise to handle the play request after the video has loaded
        const playPromise = new Promise((resolve, reject) => {
          // @ts-ignore
          videoElement.onloadeddata = () => {
            // @ts-ignore
            resolve();
          };
          // @ts-ignore
          videoElement.onerror = (error) => {
            reject(error);
          };
        });

        // Play the video once it has loaded
        playPromise
          .then(() => {
            // @ts-ignore
            videoElement.play().catch((error) => {
              console.error('Error attempting to play:', error);
            });
          })
          .catch((error) => {
            console.error('Error loading video data:', error);
          });
      }
    }
  }
};
</script>

<style scoped>
.whip-stream-container {
  height: 100%;
  width: 100%;
}

.whip-stream-video {
  width: 100%;
  height: 40vh;
}

.lights-off .whip-stream-video {
  height: 100vh;
}
</style>
