<template>
  <div class="sf-user-settings">
    <div class="heading">
      <h3>Account page</h3>
    </div>
    <v-form v-model="formIsValid" @submit.prevent="handleSubmit">

      <div class="settings">

        <div class="profile-picture">
          <h4>Profile image</h4>
          <div class="picture-bubble">
            <vue-avatar
              :image="myUser.pict || logos.centeredSmall"
              :borderRadius=200
              :width=220
              :height=220
              :scale="scale"
              ref="vueavatar"
              @vue-avatar-editor:image-ready="onImageReady">
            </vue-avatar>
          </div>
    
          <div style="width: 300px; margin: auto;">
            <v-slider
              v-model="scale"
              min=1
              max=3
              step=0.02
              append-icon="zoom_in"
              prepend-icon="zoom_out">
            </v-slider>
          </div>
    
        </div>
    
        <div class="input-row">
          <input type="text" />
        </div>
        <div class="input-row">
          <v-text-field outlined label="EMAIL" readonly disabled :value="myUser.email" class="item" />
          <v-btn class="item" :disabled="isVerified" large @click.stop.prevent="sendVerificationEmail">
            <span v-if="isVerified">
              <v-icon
                class="verified-badge"
                color="green"
                small
              >
                mdi-check-decagram
              </v-icon>
              email verified
            </span>
            <span v-if="!isVerified">
              <v-icon
                class="verified-badge"
                color="red"
                small>
                mdi-alert-decagram
              </v-icon>
              Verify email
            </span>
          </v-btn>
        </div>
        <div class="input-row">
          <v-text-field outlined label="DISPLAY NAME" v-model="myUser.user_name" :rules="[hasDisplayName]" />
        </div>
        <div class="input-row">
          <div class="button-with-label item">
            <label>Change password</label>
            <v-btn large @click.stop.prevent="passwordInput = true">Change password</v-btn>
          </div>
          <div class="button-with-label item">
            <label>Two-factor authentication</label>
            <v-btn large :disabled="!isVerified" @click.stop.prevent="$router.push('enroll')">Set up</v-btn>
          </div>
        </div>
    
        <div class="action-buttons">
          <v-btn large @click="$emit('close-button-clicked')">Cancel</v-btn>
          <v-btn type="submit" large :color="colors.mainAccent" :disabled="!formIsValid">Save</v-btn>
        </div>
      </div>
    </v-form>
    <v-dialog v-model="passwordInput" max-width=500>
      <div class="change-password">
        <h4>Change password</h4>
        <v-form v-model="passwordChangeIsValid" @submit.prevent="updatePassword">
          <v-text-field
            outlined
            autofocus
            label="OLD PASSWORD"
            v-model="oldPassword"
            :type="showPW ? 'text' : 'password'"
            placeholder="Enter your old password"
            name="oldpwd"
          />
          <v-text-field
            outlined
            autofocus
            label="NEW PASSWORD"
            v-model="password"
            :type="showPW ? 'text' : 'password'"
            :success="!!password"
            :rules="[passwordRules.required, passwordRules.min]"
            placeholder="Choose a Password, 8 characters min"
            :append-icon="showPW ? 'visibility' : 'visibility_off'"
            @click:append="showPW = !showPW"
            name="newpwd"
          />
          <div class="save-new-password">
            <v-btn :disabled="!passwordChangeIsValid" :color="colors.mainAccent" type="submit">SAVE</v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-overlay v-model="storingspinner" width=100 rounded persistent style="z-index: 1010;">
      <v-progress-circular
        :size="100"
        :width="7"
        :color="colors.mainAccent"
        indeterminate>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import {VueAvatar} from 'vue-avatar-editor-improved'
import firebase from 'firebase/app'
import 'firebase/auth'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'usersettings',
  components: {
    VueAvatar
  },
  data: function () {
    return {
      // UI
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      verificationSpinner: false,
      storingspinner: false,
      displaynameInput: false,
      passwordInput: false,
      userName: '',
      password: '',
      oldPassword: '',
      showPW: false,
      scale: 1,
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`)
      },
      formIsValid: false,
      passwordChangeIsValid: false,
    }
  },
  computed: {
    isVerified () {
      return App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser.emailVerified
    },
    myUser () {
      const aUser = this.$store.state.currentSpotterfishUser
      if (aUser) {
        return {
          email: aUser.email,
          user_name: aUser.user_name,
          pict: aUser.pict,
          uid: aUser.user_id
        }
      } else {
        return {
          email: '',
          user_name: '',
          pict: '',
          uid: ''
        }
      }
    },
  },
  methods: {
    async sendVerificationEmail () {
      this.verificationSpinner = true
      try {
        await CloudClient.call_CFsendVerificationEmail(App.Firebase)
      } catch (error) {
        await this.$root.$globalAlert.open('Error', {
          line1: `Could not send verification email (${error.message})`
        },
        { color: this.colors.mainAccent })
      }
      this.verificationSpinner = false
    },

    onImageReady: function onImageReady () {
      this.scale = 1
    },

    hasValidEmail(value){
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    },
    hasDisplayName(value){
      return value && value.length > 0
    },

    handleSubmit(e){
      if(this.formIsValid){
        this.saveImage()
        this.updateUser()
      }
    },

    updateUser(){
      this.storingspinner = true
      this.$store.dispatch('user_update', {user_id: this.myUser.uid, user_name: this.myUser.user_name}).then(() => {
        this.storingspinner = false
      })
    },

    saveImage () {
      this.storingspinner = true
      const img = this.$refs.vueavatar.getImageScaled()
      img.toBlob((blob) => {
        this.$store.dispatch('user_upload_avatar', {
          fileName: this.myUser.uid,
          file: blob
        }).then((resp) => {
          const updateUser =
            { user_id: this.myUser.uid,
              pict: resp.publicURL
            }
          this.$store.dispatch('user_update', updateUser).then(() => {
            this.storingspinner = false
          })
        })
      })
    },

    updatePassword(){
      this.savePassword(this.password, this.oldPassword)
    },
    savePassword (newPassword, oldPassword) {
      firebase.auth().signInWithEmailAndPassword(App.spotterfishSession.userSession.firebaseCurrentUser.email, oldPassword).then(() => {
        App.spotterfishSession.userSession.firebaseCurrentUser.updatePassword(newPassword).then(() => {
          alert('password changed')
          this.password = ''
          this.oldPassword = ''
          this.passwordInput = false
        }).catch((error) => {
          console.log('error: ', error)
        })
      })
        .catch((error) => {
          console.log(error)
          alert('wrong password')
        })
    },
  },
}
</script>
<style>
#avatarEditorCanvas {
  display: block;
  margin: 20px auto;
}
</style>

<style lang="scss">
.sf-user-settings{
  .heading{
    background-color: #000;
    color: #fff;
    padding: .5rem 0;
    h3{
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .settings{
    background-color: #202020;
    padding: 2rem 6rem;
  }
  .profile-picture{
    display: flex;
    flex-direction: column;
    align-items: center;

    .picture-bubble{
      overflow: hidden;
      width: 220px;
      height: 220px;
      border-radius: 50%;
      canvas#avatarEditorCanvas {
        margin-left: -25px;
        margin-top: -25px;
      }
    }
  }
  .input-row{
    display: flex;
    .item{
      width: 50%;
      &:first-child{
        margin-right:0.5rem;
      }
      &:nth-child(2){
        margin-left:0.5rem;
      }
    }
    .button-with-label {
      text-align: left;
      label{
        display: block;
        text-transform: uppercase;
      }
      button{
        width: 100%;
      }
      width: 50%;
    }
  }
  .action-buttons{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    button {
      width: 7rem;
    }
    button:first-child{
      margin-right: 0.5rem;
    }
    button:nth-child(2){
      margin-left: 0.5rem;
    }
  }
  button {
    height: 56px !important;
  }
  h4{
    text-transform: uppercase;
    font-weight: normal;
    padding-bottom: 1rem;
  }
}

.change-password{
  padding: 1rem;
  background-color: #202020;
  h4{
    text-transform: uppercase;
    font-weight: normal;
    padding-bottom: 1rem;
  }
}
</style>
