const SpotterfishCore = require('./SpotterfishCore')

const _ = require('lodash')

function isValidEmail (email) {
  return SpotterfishCore.isStringInstance(email) && email !== '' && email.split('@').length === 2
}

function prove_isValidEmail () {
  SpotterfishCore.unitTestAssert(!isValidEmail(123))
  SpotterfishCore.unitTestAssert(!isValidEmail(''))
  SpotterfishCore.unitTestAssert(!isValidEmail('foo'))
  SpotterfishCore.unitTestAssert(!isValidEmail('foo@bar@baz.com'))
  SpotterfishCore.unitTestAssert(isValidEmail('foo@bar.com'))
}

function isValidUserName (userName) {
  return SpotterfishCore.isStringInstance(userName) && userName !== ''
}

function prove_isValidUserName () {
  SpotterfishCore.unitTestAssert(!isValidUserName(123))
  SpotterfishCore.unitTestAssert(!isValidUserName(''))
  SpotterfishCore.unitTestAssert(isValidUserName('foo'))
}

function isValidPassword (password) {
  return SpotterfishCore.isStringInstance(password) && password !== ''
}

function prove_isValidPassword () {
  SpotterfishCore.unitTestAssert(!isValidPassword(123))
  SpotterfishCore.unitTestAssert(!isValidPassword(''))
  SpotterfishCore.unitTestAssert(isValidPassword('foo'))
}

function needsProjectReload(newProject, oldProject, userId) {
  if (oldProject === undefined) { 
    return true
  }

  const newProj = _.cloneDeep(newProject)
  const oldProj = _.cloneDeep(oldProject)

  const diff = _.difference(newProject.used_by, oldProject.used_by)
  console.log({diff})
  delete newProj.used_by
  delete oldProj.used_by

  const equalExceptUsedBy = _.isEqual(newProj, oldProj)
  console.log({equalExceptUsedBy})
  const onlyUserChanged = (diff.includes(userId) && diff.length === 1)

  return (onlyUserChanged && equalExceptUsedBy) ? false : true
  
}

function prove__needsProjectReload0() {
  const newP = {
    x: true,
    y: false,
    used_by: []
  }
  const oldP = undefined
  const res = needsProjectReload(newP, oldP, '1234')
  SpotterfishCore.unitTestAssert(res === true)
}

function isLocalStorageAvailable() {
  const testKey = 'localStorageTestKey'
  try {
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

async function alertIfSessionError(alertBox, color, shouldHideAlertForReload) {

  const localStorageWorks = isLocalStorageAvailable()

  if(!localStorageWorks) {
    await alertBox.open(
      'Local Storage Needed',
        {
          line1: 'MixStage needs Chrome local storage for optimal use.',
          line3: 'Enable it and refresh.',
          line5: '*See our knowledge base for help and privacy info.*'
        },
        {
          color: color,
        }
    )
    // does not need forceful reload
    return false
  } else {
    const janusRunning = localStorage.getItem('sessionRunning')
    
    if (janusRunning === 'true') {
      // in lobby, a reload will happen silently
      if (!shouldHideAlertForReload) {
        await alertBox.open(
          'Refreshing',
          {
            line1: 'We hit a snag and need to reload.',
            line3: 'Close this box; we will be right back.',
          },
          {
            color: color,
          }
        )
      }
      localStorage.setItem('sessionRunning', 'false')
      // Needs refresh of the page, session was exited uncleanly
      return true
    }
  }
}


function prove__needsProjectReload1() {
  const oldP = {
    "people_seated": 0,
    "live_screening_room": "null",
    "project_name": "video.mp4",
    "previous_versions": [],
    "project_description_tags": [],
    "project_description": "",
    "current_partially_uploaded_file": "",
    "created_date": {
        "seconds": 1675347580,
        "nanoseconds": 664000000
    },
    "moderators": [
        "W01fWzhW7mb52OCaTLAP2N597Co1"
    ],
    "files": [
        "07ky5jN0MGHiVGZPlMpf"
    ],
    "current_version": "g6sXlrkogYlQAB6FPRK4",
    "versions": [
        "g6sXlrkogYlQAB6FPRK4"
    ],
    "current_version_marker_lane": "3HSQAKswBwc19YWvV5Wy",
    "pending_invites": [],
    "project_people": [
        "W01fWzhW7mb52OCaTLAP2N597Co1"
    ],
    "used_by": [],
    ".key": "YumeQmmMhUM8x2TYT86h"
  }
  const newP = {
    "people_seated": 0,
    "live_screening_room": "null",
    "project_name": "video.mp4",
    "previous_versions": [],
    "project_description_tags": [],
    "project_description": "",
    "current_partially_uploaded_file": "",
    "created_date": {
        "seconds": 1675347580,
        "nanoseconds": 664000000
    },
    "moderators": [
        "W01fWzhW7mb52OCaTLAP2N597Co1"
    ],
    "files": [
        "07ky5jN0MGHiVGZPlMpf"
    ],
    "current_version": "g6sXlrkogYlQAB6FPRK4",
    "versions": [
        "g6sXlrkogYlQAB6FPRK4"
    ],
    "current_version_marker_lane": "3HSQAKswBwc19YWvV5Wy",
    "pending_invites": [],
    "project_people": [
        "W01fWzhW7mb52OCaTLAP2N597Co1"
    ],
    "used_by": [
        "W01fWzhW7mb52OCaTLAP2N597Co1"
    ],
    ".key": "YumeQmmMhUM8x2TYT86h"
  }
  const res = needsProjectReload(newP, oldP, 'W01fWzhW7mb52OCaTLAP2N597Co1')
  SpotterfishCore.unitTestAssert(res === false)
}

function prove__needsProjectReload2() {
  const oldP = {
    y: true,
    used_by: ['abc', 'gbe']
  }
  const newP = {
    y: true,
    used_by: ['abc', '1234', 'gbe']
  }
  const res = needsProjectReload(newP, oldP, '1234')
  SpotterfishCore.unitTestAssert(res === false)
}

function prove__needsProjectReloadX() {
  const newP = {
    y: true,
    used_by: ['abc', 'gbe']
  }
  const oldP = {
    y: true,
    used_by: ['abc', '1234', 'gbe']
  }
  const res = needsProjectReload(newP, oldP, '1234')
  SpotterfishCore.unitTestAssert(res === true)
}

function prove__needsProjectReload3() {
  const a = {
    x: true,
    y: true,
    used_by: ['1234']
  }
  const b = {
    x: true,
    y: true,
    used_by: ['1234', 'abc']
  }
  const res = needsProjectReload(a, b, '1234')
  console.log(res)
  SpotterfishCore.unitTestAssert(res === true)
}


function prove__needsProjectReload4() {
  const a = {
    x: true,
    y: false,
    used_by: ['1234']
  }
  const b = {
    x: true,
    y: true,
    used_by: ['1234']
  }
  const res = needsProjectReload(a, b, '1234')
  console.log(res)
  SpotterfishCore.unitTestAssert(res === true)
}
function runUnitTests () {
  console.log('SpotterfishHelpers.js -- START')
  prove__needsProjectReload0()
  prove__needsProjectReload1()
  prove__needsProjectReload2()
  prove__needsProjectReloadX()
  prove__needsProjectReload3()
  prove__needsProjectReload4()

  prove_isValidEmail()
  prove_isValidUserName()
  prove_isValidPassword()

  console.log('SpotterfishHelpers.js -- END')
}

module.exports = {
  isValidEmail,
  isValidUserName,
  isValidPassword,
  needsProjectReload,
  alertIfSessionError,

  runUnitTests
}
