<template>
  <div
    class="av-settings-wrapper"
  >
    <div class="av-settings">
      <div class="header">
        <v-btn
          class="back-button"
          style="width: 90px; position: absolute; top: 1rem; left: 10px;"
          @click="cancel()"
        >
          <v-icon>mdi-chevron-left</v-icon>Cancel
        </v-btn>
        <p
          style="margin-left: 10px; width: 100px"
        />
        <p style="font-size: 1em; margin-left: -10px; margin-top: 25px; white-space: nowrap;">
          {{ bathroomMirrorTitle }}
        </p>
        <p style="margin-right: 10px; width: 100px"></p>
      </div>
      <div class="content">
        
        <div class="video">
          <videoBubble 
            :videoStream="videoStream"
            :activeInSession="true"
            :isMe="true"
            :videoMuted="!allowedInputs.video"
            :user="currentUser"
            :useRect="true"
            :minHeight="'240px'"
          />
        </div>
        <div class="settings-block">
          <p>Camera</p>
          <div class="settings-row">
            <MxSelect
            v-model="selectedVideoInput"
            :items="videoInputArray"
            @change="storeDeviceSettingsAndRestart"
            :disabled="!allowedInputs.video"
            :placeholder="!allowedInputs.video ? 'Video disabled in browser' : 'Select camera'"
            :no-data-text="'No Video input devices found'"
          />
          </div>
        </div>
        <div class="settings-block">
          <p>Output</p>
          <div class="settings-row">
            <div class="setting">
              <MxSelect
                v-model="selectedAudioOutput"
                :items="audioOutputArray"
                @change="storeDeviceSettingsAndRestart"
                :placeholder="'Select audio output'"
                :no-data-text="'No audio output devices found'"
              />
              <!-- <span>Do you hear the test sound?</span> -->
              <div v-show="!outputSelectionAllowed" style="width: 100%; font-size: 0.7em; text-align: left; ">Using system default audio output</div>
            </div>
            <div class="setting">
              <v-btn width="90%" @click="toggleTestSound()">
                {{isTestSoundPlaying ? 'Stop test sound' : 'Play test sound'}}
              </v-btn>
            </div>
          </div>
        </div>

        <div class="settings-block">
          <p>Input</p>
          <div class="settings-row">
            <div class="setting input-select">
              <MxSelect
                v-model="selectedAudioInput"
                :items="audioInputArray"
                @change="storeDeviceSettingsAndRestart"
                :disabled="!allowedInputs.audio"
                :placeholder="allowedInputs.audio ? 'Select audio source' : 'Audio disabled in browser'"
                :no-data-text="'No audio input devices found'"
              />
            </div>
            <div class="setting">
              <div v-if="allowedInputs.audio" class="volume-check">
                <div class="volume-bar" v-for="n in volumeArray" :key="n" :style="{ background: getVolumeColor(n * 10) }" />
              </div>
              <div></div>
              <div v-if="!allowedInputs.audio">
                <v-icon>mdi-volume-off</v-icon>
              </div>
            </div>
          </div>
        </div>

        <audio ref="audioElement" loop>
          <source src="@/assets/test.mp3" type="audio/mpeg" />
        </audio>
       

        <!-- Bathroom mirror was opened as part of entering the room, i.e. user is in the lobby: -->
        <div v-if="requiresEmailVerification" class="high-security-box">
          <p>
            This is a high-security room. You have to follow the
            steps below to enter.
          </p>
          <table class="high-security-table">
            <tr>
              <td>
                <v-icon :color="isVerified ? 'green' : 'red'">mdi-alert-decagram</v-icon>
              </td>
              <td class="text-left description">
                Email address
                {{ isVerified ? 'is' : 'is not' }} verified
              </td>
              <td class="text-left">
                <a
                  :style="{
                    visibility: isVerified ? 'hidden' : 'visible',
                  }"
                  class="verification-link"
                  @click.once="handleSendVerificationLinkClick()"
                >
                  Send verification email
                </a>
                <span
                  :style="{
                    visibility: isVerified ? 'hidden' : 'visible',
                  }"
                  class="verification-link-sent"
                  style="display: none"
                >
                  Sent!
                </span>
              </td>
            </tr>
            <tr :class="[isVerified ? '' : 'disabled-class']">
              <td>
                <v-icon
                  :color="isTwoFactorEnabled ? 'green' : 'red'"
                >
                  mdi-alert-decagram
                </v-icon>
              </td>
              <td class="text-left description">
                Two-factor authentication
                {{ isTwoFactorEnabled ? 'is' : 'is not' }} enabled
              </td>
              <td class="text-left">
                <router-link
                  v-show="isVerified"
                  :style="{
                    visibility: isTwoFactorEnabled
                      ? 'hidden'
                      : 'visible',
                  }"
                  :to="{
                    name: 'enroll',
                    query: this.$route.query,
                  }"
                  >
                  Enable
                </router-link>
              </td>
            </tr>
            <tr
              :class="[
                isTwoFactorEnabled ? '' : 'disabled-class',
              ]"
            >
              <td>
                <v-icon
                  :color="
                    isLoggedInLast45Minutes ? 'green' : 'red'
                  "
                  >
                  mdi-alert-decagram
                </v-icon>
              </td>
              <td class="text-left description">
                {{
                  isLoggedInLast45Minutes
                    ? 'Logged in recently'
                    : 'Re-authentication is needed'
                }}
              </td>
              <td class="text-left">
                <router-link
                  v-show="isVerified && isTwoFactorEnabled"
                  :style="{
                    visibility: isLoggedInLast45Minutes
                      ? 'hidden'
                      : 'visible',
                  }"
                  :to="{
                    name: 'reauth',
                    query: this.$route.query,
                  }"
                  >
                  Log out to re-authenticate
                </router-link>
              </td>
            </tr>
          </table>
          <div
            style="margin-top: 50px"
            v-if="
              isLoggedInLast45Minutes &&
              isTwoFactorEnabled &&
              isVerified &&
              requiresRoomPin
            "
          >
            <p>
              PLEASE INPUT THE ROOM VERIFICATION CODE SENT TO YOUR
              PHONE
            </p>
            <p>
              <v-btn text @click="$emit('knock-knock')"
                >Did not receive your code? Click here to
                resend</v-btn
              >
            </p>
            <v-text-field
              autofocus
              outlined
              color="white"
              style="
                width: 96px;
                margin: 10px auto 0 auto;
                font-size: 1.5em;
                padding: 0;
              "
              v-model="roomVerificationCode"
              @keyup="
                $emit(
                  'updated-room-verification-code',
                  roomVerificationCode
                )
              "
            >
            </v-text-field>
          </div>
          <v-progress-circular
            indeterminate
            style="margin: 1rem 0"
            v-if="!passesHighSecurityTest"
          ></v-progress-circular>
          <p v-if="!passesHighSecurityTest">
            Waiting for verification…
          </p>
        </div>

        <!-- User does not have access to the room yet: -->
        <h2 class="pt-10" v-if="!approved && roomName" style="font-size: 1em;">Requesting a seat in the Session, please wait for the moderator to let you in. </h2>
        <div class="settings-block advanced-toggle mt-3" @click="toggleAdvancedRow">
          <p style="display: flex; align-items: center;">
            <i class="material-icons" style="margin-right: 2px;">settings</i>
            Advanced
          </p>
          <div class="advanced-row" id="advancedRow">
            <div class="setting">
              <v-checkbox :color="colors.mx.secondary" v-model="echoCancellation" @change="onChangeEchocCancellation" 
              label="Improve Voice Clarity (Disables Surround Sound Output)"></v-checkbox>                    
            </div>
          </div>
        </div>
        <div class="join-buttons">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <span>
              Make sure to allow camera and microphone access
            </span>
          </v-tooltip>
          <div class="button">
            <v-btn
              v-if="approved && !roomName"
              :color="colors.mixStage_main"
              @click="agree()"
            >
              Save settings
            </v-btn>
            <v-btn
              v-else
              :color="colors.mixStage_main"
              :disabled="!approved || !passesHighSecurityTest || !initiated"
              @click="
                // takeYourSeat()
                agree()
              "
            >
            {{ sessionActive ?  'Join session' : 'Start session' }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import * as Sentry from '@sentry/browser'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import CloudClient from '@/../source_files/web_client/CloudClient'
import { 
  checkAllowedInputs, 
  checkAndResetLocalStorage, 
  requestPermissionsAndCheckInputs, 
  createPermissionsMessage, 
  mockNoDevices, 
  measureLevel, 
  gotDevices, 
  attachSinkId, 
  handleError 
} from '@/../source_files/spotterfish_library/utils/VideoChatUtils'

import videoBubble from '@/components/webRTCcomponents/videoBubble'
import MxSelect from '@/components/reusable/mxSelect.vue'
import _ from 'lodash'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'


export default {
  components: {
    videoBubble,
    MxSelect
  },
  props: [
    'approved',
    'sessionActive',
    'roomName',
    'requiresEmailVerification',
    'requiresTwoFactorAuth',
    'requiresRoomPin',
    'onContinue',
    'onCancel'
  ],
  data() {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      initiated: false,
      audioInputArray: [],
      videoInputArray: [],
      audioOutputArray: [],
      echoCancellation: false,
      selectedAudioInput: '',
      selectedVideoInput: '',
      selectedAudioOutput: '',
      volume: 0,
      roomVerificationCode: '',
      verificationBarWaiting: false,
      verificationBarDismissed: false,
      checkVerificationTimerId: undefined,
      isLoggedInLast45Minutes: false,
      currentUser: undefined,
      allowedInputs: {
        video: false,
        audio: false
      },
      analytics: {
        didLogCameraWorksClick: false,
        didLogMicrophoneWorksClick: false,
        didLogTestSoundClick: false,
        didLogTakeYourSeatClick: false
      },
      isTestSoundPlaying: false,
      videoStream: undefined,
      measureLevelAnimFrame: undefined,
      outputSelectionAllowed: false
    }
  },
  computed: {
    isVerified() {
      return firebaseUserHandler.getLoggedInUser().emailVerified
    },
    isTwoFactorEnabled() {
      const multiFactor = firebaseUserHandler.getLoggedInUser().multiFactor
      return multiFactor && multiFactor.enrolledFactors.length > 0
    },
    passesHighSecurityTest() {
      if (!this.requiresTwoFactorAuth) {
        return true
      }
      return this.isVerified && this.isTwoFactorEnabled && this.isLoggedInLast45Minutes
    },
    bathroomMirrorTitle() {
      if (this.roomName) {
        return `${this.roomName} LOBBY`
      } else {
        return 'AUDIO & VIDEO SETTINGS'
      }
    },
    videoElement() {
      return this.$refs.videoElement
    },
    volumeArray() {
      return Array.from(Array(Math.ceil(_.clamp(this.volume, 0, 100) / 10)).keys())
    }
  },
  methods: {
    onChangeEchocCancellation(val) {
      localStorage.setItem('spotterfishUserEchoCancellation', val)
      this.storeDeviceSettingsAndRestart()
    },
    toggleAdvancedRow() {
      const row = document.getElementById('advancedRow')
      if (row.style.display === 'none' || row.style.display === '') {
        row.style.display = 'block'
      } else {
        row.style.display = 'none'
      }
    },
    getVolumeColor(n) {
      if (n <= 30) return 'green'
      if (n > 30 && n < 90) return 'yellow'
      if (n >= 90) return 'red'
    },
    logCameraWorksClick() {
      this.logEvent('clicked_camera_works', 'didLogCameraWorksClick')
    },
    logMicrophoneWorksClick() {
      this.logEvent('clicked_microphone_works', 'didLogMicrophoneWorksClick')
    },
    logTestSoundClick() {
      this.logEvent('clicked_test_sound', 'didLogTestSoundClick')
    },
    logTakeYourSeatClick() {
      this.logEvent('clicked_take_seat', 'didLogTakeYourSeatClick')
    },
    logEvent(eventName, analyticsProp) {
      if (!this.analytics[analyticsProp]) {
        const userId = firebaseUserHandler.getLoggedInUserId()
        SpotterfishSession.trackEvent(App.spotterfishSession, eventName, { userUID: userId })
        this.analytics[analyticsProp] = true
      }
    },
    toggleTestSound(stop) {
      if (stop) {
        this.$refs.audioElement.pause()
        this.isTestSoundPlaying = false
        return
      }
      if (!this.isTestSoundPlaying) {
        this.$refs.audioElement.play()
        this.isTestSoundPlaying = true
      } else {
        this.$refs.audioElement.pause()
        this.isTestSoundPlaying = false
      }
    },
    async handleSendVerificationLinkClick() {
      this.dismissVerificationLink()
      try {
        await CloudClient.call_CFsendVerificationEmail(App.Firebase)
      } catch (error) {
        await this.$root.$globalAlert.open(
          'Error',
          { line1: `Could not send verification email (${error.message})` },
          { color: this.colors.mainAccent }
        )
      }
    },
    async checkVerificationStatus() {
      const self = this
      try {
        self.isLoggedInLast45Minutes = await CloudClient.call_CFcheckIfUserAuthenticatedWithinLast45Minutes(App.Firebase)
      } catch (error) {
        self.isLoggedInLast45Minutes = false
        console.error(error)
      }
      self.$forceUpdate()
      if (self.requiresTwoFactorAuth && !self.passesHighSecurityTest) {
        window.clearTimeout(self.checkVerificationTimerId)
        self.checkVerificationTimerId = window.setTimeout(self.checkVerificationStatus, 3000)
      }
    },
    dismissVerificationBar() {
      const self = this
      self.verificationBarWaiting = true
      window.setTimeout(() => {
        self.verificationBarWaiting = false
        self.verificationBarDismissed = true
      }, 1500)
    },
    dismissVerificationLink() {
      document.querySelector('.verification-link').style.display = 'none'
      document.querySelector('.verification-link-sent').style.display = 'inline'
    },
    agree() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop())
      }
      this.onContinue()
    },
    cancel() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop())
      }
      this.onCancel()
    },
    measureLevel(stream) {
      measureLevel(stream, (volume) => {
        this.volume = volume;
      });
    },
    gotDevices(deviceInfos) {
      
      gotDevices(deviceInfos, this.audioInputArray, this.videoInputArray, this.audioOutputArray)
    },
    attachSinkId(element, sinkId) {
      attachSinkId(element, sinkId, this.audioOutputArray, this.selectedAudioOutput, (allowed) => {
        this.outputSelectionAllowed = allowed;
      });
    },
    changeAudioDestination() {
      this.attachSinkId(this.$refs.audioElement, this.selectedAudioOutput);
    },
    async startInputs(permissionsGranted = { video: true, audio: true }, userConfirmedMissingPermissions = false) {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => {
          track.stop()
        })
      }

      let constraints = {}

      this.selectedAudioInput = localStorage.getItem('spotterfishUserAudioIn')
      this.selectedVideoInput = localStorage.getItem('spotterfishUserVideoIn')

      const audioSource = this.selectedAudioInput
      const videoSource = this.selectedVideoInput

      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()

      if (permissionsGranted.audio) {
        constraints.audio = {
          deviceId: audioSource ? { exact: audioSource } : undefined,
          autoGainControl: supportedConstraints.autoGainControl ? false : undefined,
          echoCancellation: supportedConstraints.echoCancellation ? this.echoCancellation : undefined,
          noiseSuppression: supportedConstraints.noiseSuppression ? true : undefined
        }
      }
      if (permissionsGranted.video) {
        constraints.video = {
          deviceId: videoSource ? { exact: videoSource } : undefined
        }
      }

      this.changeAudioDestination()

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        this.gotStream(stream, permissionsGranted)
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.gotDevices(devices)
      } catch (error) {
        console.error('Error accessing media devices:', error)

        if (!userConfirmedMissingPermissions) {
          let fallbackAudioStream = null
          let fallbackVideoStream = null

          if (permissionsGranted.audio) {
            try {
              fallbackAudioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
              this.gotStream(fallbackAudioStream, { audio: true })
            } catch (audioError) {
              console.error('Fallback audio error:', audioError)
            }
          }

          if (permissionsGranted.video) {
            try {
              fallbackVideoStream = await navigator.mediaDevices.getUserMedia({ video: true })
              this.gotStream(fallbackVideoStream, { video: true })
            } catch (videoError) {
              console.error('Fallback video error:', videoError)
            }
          }

          if (!fallbackAudioStream && !fallbackVideoStream) {
            this.handleError(new Error('No media streams available.'))
          } else {
            const combinedStream = new MediaStream()
            if (fallbackAudioStream) {
              fallbackAudioStream.getAudioTracks().forEach(track => combinedStream.addTrack(track))
            }
            if (fallbackVideoStream) {
              fallbackVideoStream.getVideoTracks().forEach(track => combinedStream.addTrack(track))
            }
            this.gotStream(combinedStream, permissionsGranted)
          }

          const devices = await navigator.mediaDevices.enumerateDevices()
          this.gotDevices(devices)
        }
      }
    },
    gotStream(stream, permissionsGrantedOptional) {
      this.initiated = true
      this.videoStream = stream

      if (permissionsGrantedOptional?.video && stream.getVideoTracks().length > 0) {
        // this.videoElement.srcObject = stream
      }
      if (permissionsGrantedOptional?.audio && stream.getAudioTracks().length > 0) {
        this.measureLevel(stream)
      }

      return navigator.mediaDevices.enumerateDevices()
    },
    storeDeviceSettingsAndRestart() {
      localStorage.setItem('spotterfishUserAudioIn', this.selectedAudioInput)
      localStorage.setItem('spotterfishUserAudioOut', this.selectedAudioOutput)
      localStorage.setItem('spotterfishUserVideoIn', this.selectedVideoInput)
      this.toggleTestSound(true)
      this.startInputs()
    },
    handleError(err) {
      handleError(err, this.allowedInputs, this.startInputs, (error) => {
        Sentry.captureException(error, {
          user: {
            uid: firebaseUserHandler.getLoggedInUserId()
          }
        })
      }, (title, content) => {
        this.$root.$globalAlert.open(title, content, { color: this.colors.mx.darkPanel })
      })
    },
    async init() {
      this.checkVerificationStatus()

      const { permissionsGranted, hasVideoInput, hasAudioInput } = await requestPermissionsAndCheckInputs()

      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.gotDevices(devices)
        checkAndResetLocalStorage(devices)
      } catch (err) {
        this.handleError(err)
      }

      this.allowedInputs = await checkAllowedInputs()

      const audioIn = localStorage.getItem('spotterfishUserAudioIn') || this.audioInputArray[0]?.value || ''
      const videoIn = localStorage.getItem('spotterfishUserVideoIn') || this.videoInputArray[0]?.value || ''
      const audioOut = localStorage.getItem('spotterfishUserAudioOut') || this.audioOutputArray[0]?.value || ''
      this.echoCancellation = localStorage.getItem('spotterfishUserEchoCancellation') === 'false' ? false : true

      localStorage.setItem('spotterfishUserAudioIn', audioIn)
      localStorage.setItem('spotterfishUserVideoIn', videoIn)
      localStorage.setItem('spotterfishUserAudioOut', audioOut)

      this.selectedAudioInput = audioIn
      this.selectedVideoInput = videoIn
      this.selectedAudioOutput = audioOut
      navigator.mediaDevices.ondevicechange = this.handleDeviceChange

      let message = createPermissionsMessage(permissionsGranted, hasVideoInput, hasAudioInput)

      if (message) {
        message += ` You can still join the session, but others will not be able to ${!permissionsGranted.audio ? 'hear' : ''}${!permissionsGranted.audio && !permissionsGranted.video ? ' and ' : ''}${!permissionsGranted.video ? 'see' : ''} you.`

        const userConfirmedMissingPermissions = await this.$root.$globalConfirm.open(
          `Access to ${!permissionsGranted.audio ? 'Microphone' : ''}${!permissionsGranted.audio && !permissionsGranted.video ? ' and ' : ''}${!permissionsGranted.video ? 'Camera' : ''} denied`,
          {
            line1: message,
            line3: `Proceed without ${!permissionsGranted.audio ? 'audio' : ''}${!permissionsGranted.audio && !permissionsGranted.video ? ' and ' : ''}${!permissionsGranted.video ? 'video' : ''}?`
          },
          { color: this.colors.mx.darkPanel }
        )

        if (userConfirmedMissingPermissions) {
          this.startInputs({ video: permissionsGranted.video, audio: permissionsGranted.audio }, true)
        }
      } else {
        this.startInputs({ video: true, audio: true }, false)
      }
    },

    async handleDeviceChange() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.gotDevices(devices)
        checkAndResetLocalStorage(devices)
      } catch (err) {
        this.handleError(err)
      }
    }
  },
  mounted() {
    this.currentUser = this.$store.state.currentSpotterfishUser
    this.init()
    this.$emit('knock-knock')
  },
  beforeDestroy() {
    this.videoStream?.getTracks().forEach(track => {
      console.log({ track })
      track.stop()
    })
    navigator.mediaDevices.ondevicechange = undefined
  },
  destroyed() {
    cancelAnimationFrame(this.measureLevelAnimFrame)
    window.clearTimeout(this.checkVerificationTimerId)
  },
}
</script>


<style scoped lang="scss">
@import '@/assets/global.scss';


.alpha-banner{
  p{
    margin: 0;
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid black;
  border-radius: 8px 8px 0 0;
  .signout-button{
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  p{
    font-size: 2em;
    margin-top: 10px;
  }
}
.av-settings-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-mx-black;
}
.av-settings{
  background-color: $color-mx-dark-panel;
  width: 40%;
  min-width: 500px;
  max-width: 640px;
  margin: 2% auto;
  border-radius: 8px;
  text-align: left;
  .v-text-field {
    margin: 0;
    padding: 0;
  }

  .content{
    padding: 2rem 2rem 3rem 2rem;
  }
}

.volume-check{
  width: 90%;
  background: $color-mx-black;
  display: flex;
  height: 40px;
  padding: 0.3rem;
  border-radius: 4px;
  .volume-bar{
    width: 10%;
    height: 30px;
    border: 2px solid black;
    color: $color-mx-black;
  }
}

.high-security-table{
  width: 100%;
}
.high-security-box {
  background-color: #1e1e1e;
  padding: 1rem;
  margin-bottom: 1rem;
}

.join-buttons{
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.video{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
  min-height: 200px;
  margin: 0 auto 2rem;
  .v-select{
    margin-top: 1.5rem;
    width: 100%;
  }
}
.header {
  position: relative;
  background-color: $color-mx-dark-panel;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advanced-toggle {
  width: 100%;
  border-radius: 4px;
  padding: 4px 4px 0 1px;
  cursor: pointer;
  background-color: $color-mx-dark-panel;

  &:hover {
    color: $color-mx-secondary
  }
}

.advanced-row {
  display: none;
  margin-top: 10px;
}

.settings-block{
  p{
    margin-bottom: 0.5rem;
  }
}
.settings-row {
  display: flex;
  margin-bottom: 1rem;
  .setting {
    background-color:$color-mx-dark-panel;
    min-width: 50%;
    height: 3rem;
    margin-right: 0rem;
    margin-left: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .setting:first-child{
    margin-right: 0.5rem;
  }
  .setting:nth-child(2){
    margin-left: 0.5rem;
  }

}

.high-security-table td {
  padding: 0 3px;
}

.verification-link-sent {
  color: gray;
}

.disabled-class {
  opacity: 0.2;
}
</style>
