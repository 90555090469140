<template>
  <div>
    <v-dialog v-model="showDialog" @input="onCloseDialog" :overlay-color="colors.mx.black" overlay-opacity="0.92" width="380px">
      <v-toolbar dark style="max-height: 54px;">
        <v-spacer></v-spacer>
        <v-toolbar-title class="toolbar-title">{{ getPurchaseData() ? `Purchase Seat for ${ getPurchaseData().name }` : `Upgrade your ${ getAccountName(accountTier, isDawStreamer).readbableName } account` }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn fab dark small style="position: absolute; right: 4px; top: 6px;" @click="$emit('close-upgrade-dialog')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="upgrade-dialog">
        
        <div class="inner-wrapper text-center">
          

          <v-spacer></v-spacer>
          <!-- MixStage Team account era4_tier2-->
          <!-- <div v-if="accountTier === 'tier2' && isDawStreamer">
            <p>You currently have a MixStage <strong>Team</strong> account which includes project sharing within your team, DAW streaming and more.</p>
            <p>Use this form to add more Team seats.</p>
          </div> -->

          <!-- MixStage Free account era4_tier1 (same as era3_tier1)-->
          <!-- <div v-if="accountTier === 'tier1' && !isDawStreamer">
            <p>You currently have a MixStage™ <strong>Reviewer</strong> account.</p>
            <p style="margin-bottom: 0;">Upgrade below to a MixStage™ <strong>Team</strong> account which includes project sharing within your team, DAW streaming and more.</p>
          </div> -->

          <!-- Spotterfish Plus / Premium  account era3_tier2 no daw streaming-->
          <!-- <div v-if="accountTier === 'tier2' && !isDawStreamer">
            <p>You currently have a Spotterfish <strong>Premium</strong> account.</p>
            <p style="margin-bottom: 0;">Upgrade below to a MixStage™ <strong>Team</strong> account which includes project sharing within your team, DAW streaming and more.</p>
          </div> -->
          
          <!-- Spotterfish Custom account-->


          <!-- Spotterfish Free account -->
          <!-- <div v-if="accountTier === 'tier1' || accountTier === 'Premium'">
            <p>You currently have a <strong>{{accountTier === 'tier1' ? 'Free' : 'Plus'}}</strong> account<span v-if="isDawStreamer">, with DAW streaming capabillities</span>.</p>
            <li style="margin-bottom: 0;">A MixStage <strong>Team</strong> account includes includes 20 seats, no "free version" banner, branding tools, project sharing within your team, DAW streaming and more.</li>


            <p class="mt-6"><a :href="contactUsURL" target="_blank">Contact us</a> to add more rooms or upgrade to Enterprise account.</p>
          </div> -->

          <!-- <paymentForm
            ref="paymentForm"
            :dawStreaming="dawStreaming"
            :accountTier="tier1"
            @payment-complete="handlePaymentComplete"
            @input-name-changed="handleInputNameChanged"
          />
          
          <v-btn
            dark
            large
            :color="colors.mainAccent"
            class="lighten-2 elevation-5 payment-cta"
            @click="handleUpgrade"
            :style="{
              background: Boolean(paymentFormInputName)
                ? colors.mainAccent
                : '#aaa !important',
            }"
            :disabled="!Boolean(paymentFormInputName)">
            Upgrade
          </v-btn> -->




          <stripe-pricing-table
            style="width: 700px;"
            v-if="scriptLoaded"
            :pricing-table-id="pricingTableId"
            :publishable-key="getStripeKey()"
            >
          </stripe-pricing-table>

          <div v-if="purchaseData" class="additional-text mt-4 pt-5">
            <v-btn class="mb-4" :color="colors.mx.panel" @click="sendEmailInvite()">Send invitation email</v-btn>
            <v-tooltip bottom :color="colors.tooltipBackground">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon class="pb-4 ml-2">help_outline</v-icon>
              </div>
              </template>
              <span>
                This user {{ purchaseData?.user?.pendingEmail }} does not have a MixStage™ Team account. <br />
                Add this user to your subscription, or send invite for them to sign up.
              </span>
            </v-tooltip>
          </div>
        
        </div>
       
      </div>

    </v-dialog>
    <v-overlay v-model="spinner" width=100 rounded persistent style="z-index: 1010;">
      <v-progress-circular
        :size="100"
        :width="7"
        :color="colors.mainAccent"
        indeterminate>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ts-nocheck

import paymentForm from '../payment/paymentForm.vue'
import StripeUtils from '@/../source_files/spotterfish_library/utils/StripeUtils'


export default {
  name: 'upgradeDialogBox',
  components: {
    paymentForm
  },
  props: [
    'show', 'accountTier', 'isDawStreamer', 'purchaseData'
  ],
  data () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      spinner: false,
      analytics: {
        didLogFacebookUpgradedAccount: false
      },
      showDialog: this.show,
      paymentFormInputName: undefined,
      scriptLoaded: false,
      scriptElement: null,
      pricingTableId: undefined,
    }
  },
  computed: {

    paymentFormIsValid(){
      console.log(this.$refs.paymentForm?.isValid())
      return this.$refs.paymentForm?.isValid()
    }
  },
  methods: {
    getPurchaseData () {
      return this.purchaseData ? { name: this.purchaseData?.user?.email ? this.purchaseData?.user?.email : this.purchaseData?.user?.pendingEmail, project: this.purchaseData.projectId }  : undefined
    },
    async sendEmailInvite () {
      const base_url = window.location.origin + '/#/'
      if (this.getPurchaseData === undefined) { return } 
      // TODO: handle these emails correctly, this user has no userId and should receive the invitation email.    
      await CloudClient.call_CFshareProjectModerator(App.spotterfishSession.firebase, undefined, this.getPurchaseData().projectId, this.getPurchaseData().name, base_url)
    },
    loadStripeScript() {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://js.stripe.com/v3/pricing-table.js'
      script.onload = () => {
        this.scriptLoaded = true
      }
      script.onerror = () => {
        console.error('Failed to load Stripe script')
      }
      document.body.appendChild(script)
      this.scriptElement = script // Save the reference
    },

    unloadStripeScript() {
      if (this.scriptElement) {
        this.scriptElement.remove() // Remove the script from the DOM
      }
    },

    onCloseDialog(event){
      if(!event) this.$emit('close-upgrade-dialog')
    },

    handleInputNameChanged(e){
      this.paymentFormInputName = e
    },

    logFacebookUpgradedAccount () {
      if (this.analytics.didLogFacebookUpgradedAccount === false) {
        // eslint-disable-next-line
        fbq('track', 'Subscribe', {value: '10.00', currency: 'USD', predicted_ltv: '60.00'})
        this.analytics.didLogFacebookUpgradedAccount = true
      }
    },

    async handleUpgrade () {
      this.$refs.paymentForm.startPayment()
    },

    getStripeKey () {
      return StripeUtils.getStripeKey()
    },

    getPricingtable(tier, isDAWstreamer) {
      return StripeUtils.getStripePricingTableId(tier, isDAWstreamer)
    },

    getAccountName (accountTier, isDawStreamer) {
      return StripeUtils.getAccountName(accountTier, isDawStreamer)
    },
    
    handlePaymentComplete(){
      console.log('PAYMENT COMPLETED!')
    },
  },
  mounted (){
    this.pricingTableId = this.getPricingtable(this.accountTier, this.dawStreaming, this.paymentOption)
    this.loadStripeScript()
  },
  beforeDestroy(){
    if (this.scriptLoaded) { this.unloadStripeScript() }
  }
}
</script>

<style lang="scss">

@import '@/assets/global.scss';

.upgrade-dialog{
  overflow: hidden;
  background-color: $color-mx-panel;
  color: $color-mx-white;
  padding-top: 12px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-wrapper {
  min-width: 180%;
}

.additional-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $color-mx-light-panel;
  width: 42%;
  font-size: 0.7em;
  margin: auto;
}

.toolbar-title {
  font-size: 0.7em !important;
}

.account-settings-button {
  color: $color-mx-secondary;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 0;
}

.account-settings-button:hover {
  cursor: pointer;
}

.secondary {
  color: $color-mx-secondary;
}

</style>
