<template>
  <v-container fluid>
    <v-row no-gutters style="margin-top: 0;">
      <v-col cols="12">
        <v-row dense>
          <v-col
            class="darkpanel"
            cols="12"
          > 
            <v-row 
              dense 
              class="project-row" 
              @mouseenter="hoverAdd = true" 
              @mouseleave="hoverAdd = false"
            >
              <projects-row 
                v-if="projectsArray?.myProjects"
                :projects="projectsArray?.myProjects" 
                :searchString="$store.state.dashboardProjectSearch" 
                :userId="userId"
                :isDemoProjectsRow="false"
                :viewOption="viewOption"
                :caller="caller"
                style="max-width: 100%; min-width: 100%; overflow-x: hidden;"
                @on-project-edit-request="onProjectEditRequest($event)"
                @on-new-project-edit-request="onNewProjectEditRequest($event)"
                @request-open-purchase-dialog="$emit('request-open-purchase-dialog', $event)"
                @on-project-people-edit-request="onProjectPeopleEditRequest($event)"
                @on-project-delete-request="deleteProject($event)"
                @project-clicked="$emit('project-clicked', $event)"
              >
              </projects-row>
              
            </v-row> 
            
            <v-row 
              dense 
              class="project-row"
              v-if="caller !== 'screeningRoom'"
            >
            <v-col cols="12" class="mt-10 pl-4" style="text-align: left;" @click="toggleHideDemos()">
              <span>Demo projects</span>
              <v-icon class="ml-4">{{ hiddenDemos ? 'mdi-chevron-right' : 'mdi-chevron-up' }}</v-icon>
            </v-col>
                <projects-row
                  v-if="projectsArray?.demoProjects && !hiddenDemos"
                  :projects="projectsArray?.demoProjects" 
                  :searchsString="$store.state.dashboardProjectSearch" 
                  :userId="userId" 
                  :isDemoProjectsRow="true"
                  :viewOption="viewOption"
                  :caller="caller"
                  style="max-width: 100%; min-width: 100%; overflow-x: hidden;"
                  @on-project-edit-request="onProjectEditRequest($event)"
                  @on-project-delete-request="deleteProject($event)"
                  @on-demo-projects-restore-request="createDemoProjects()">
                </projects-row>

              </v-row>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <project-edit-dialog ref="projectEdit" @request-load-in-mixstage="$emit('request-load-in-mixstage', $event)" @request-open-purchase-dialog="$emit('request-open-purchase-dialog', $event)" @on-project-delete-request="deleteProject($event)" />
    <!-- <projectPeople-dialog
      :projectID="projectID"
      :projectEditedData="projectEditedData"
    /> -->
  </v-container>
</template>

<script>
//@ts-nocheck

import App from '@/App.vue'
import { mapState } from 'vuex'
import CloudClient from '@/../source_files/web_client/CloudClient'

import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import projectEditDialog from '@/components/versionscomponents/projectEdit.vue'
import projectsRow from '@/components/dashboard/projectsRow.vue'
import DisplayUtils from '@/lib/ui/DisplayUtils'

function setupPanelModels(projects) {
  const panelModel = []

  if (projects.myProjects.length > 0) {
    panelModel.push('myProjects')
  }
  if (projects.sharedProjects.length > 0) {
    panelModel.push('sharedProjects')
  }
  if (projects.demoProjects.length > 0) {
    panelModel.push('demoProjects')
  }
  return panelModel
}


export default {
  // caller prop can be 'dashboard' or 'screeningRoom'
  // viewOption can be 'grid' or 'list'
  props: ['setSpinner', 'setLoadingMessage', 'viewOption', 'caller'],
  components: {
    "project-edit-dialog": projectEditDialog,
    "projects-row": projectsRow
  },
  data(){
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      search: '',
      selectedProject: undefined,
      projectsLoaded: undefined,
      projectsArray: [],
      permissions: [],
      connectedUsers: [],
      usersLoaded: false,
      userId: undefined,
      hoverAdd: false,
      hoverAddDemo: false,
      panelModel: [],
      hiddenDemos: false
    }
  },
  computed: {
    ...mapState([
    ]),
  },
  methods: {
    relayEvent (event) {
      this.$emit('request-load-in-mixstage', event)
      console.log(event)
    },
    toggleHideDemos () {
      this.hiddenDemos = !this.hiddenDemos
      localStorage.setItem('mxst-hiddenDemos', this.hiddenDemos)
    },

    async createDemoProjects() {
      this.setLoadingMessage('Restoring demo projects')
      this.setSpinner(true)
      const success = await CloudClient.call_CFcreateDemoProjects(App.Firebase, firebaseUserHandler.getLoggedInUserId())
      if (!success) {
        console.error('Could not create demo projects')
      }
      this.setLoadingMessage('')
      this.setSpinner(false)
    },

    limitCharacters (str, length) {
      const _str = str.toString()
      if (_str.length > length) {
        return _str.slice(0, length + 1) + '...'
      } else {
        return _str
      }
    },

    onProjectPeopleEditRequest (projectDB) {

    },

    async onNewProjectEditRequest (projectId) {
      
      await this.$refs.projectEdit.open(projectId, true)
    },

    async onProjectEditRequest (projectDB) {
      if(!projectDB.moderators.includes(this.userId)){
        const conf = await this.$root.$globalConfirm.open(
            'Read-only access',
            {
              line1: `This project was shared with you. You can watch, but not modify it `,
              line3: `Do you want to open in in a session?`
            },
            { color: this.colors.mainAccent }
          )
        if (conf) {
          this.$emit('request-load-in-mixstage', projectDB['.key'])
          return
        }
        else {
          return
        }

      }
      console.log('opening projectEdit with projectId: ' + projectDB['.key'])
      this.destroyUploadWidget = true
      await this.$refs.projectEdit.open(projectDB['.key'])
    },

    handleGotProjects(projects) {
      const split = DisplayUtils.splitProjectsByType(projects)
      this.projectsArray = split

      if (this.caller === 'screeningRoom') {
        this.projectsArray.myProjects = new Array(...split.myProjects, ...split.demoProjects)
      }

      this.panelModel = setupPanelModels(split)
      console.log(this.projectsArray)
      this.projectsLoaded = new Date()

      // Looping through all connected users to store their data locally

      this.$store
        .dispatch('users_create_array', projects)
        .then((obj) => {
          this.$store
            .dispatch('users_get_connected', obj.connectedUsers)
            .then((users) => {
              const arrayToObject = (array) =>
                array.reduce((obj, item) => {
                  obj[item.user_id] = item
                  return obj
                }, {})

              this.usersLoaded = true
              const userObject = arrayToObject(users)
              this.$store
                .dispatch('generateHash', userObject)
                .then((jsonString) => {
                  localStorage.setItem('users', jsonString)
                  this.connectedUsers = users
                })
                .catch((e) => {
                  console.error(e)
                })
            })
        })
    },

    async deleteProject(projectObj) {
      let conf
      if (projectObj.used_by?.length > 0) {

        const users = await SpotterfishSession.getUserObjects(App.Firebase, projectObj.used_by)
        let userString = ''
        for (const u of users) {
          userString = `${userString} ${u.email}`
        }
        
        conf = await this.$root.$globalConfirm.open('Project is active', {
          line1: `This project is currently used by ${userString}. Do your really want to delete this project and all files associated with it?`,
          line3: 'Sessions using this project will be closed. Proceed?'
        },
        { color: this.colors.mainAccent })

      } else {
        conf = await this.$root.$globalConfirm.open('Delete project and files?', {
          line1: `Do your really want to delete this project and all files associated with it?`,
          line3: 'Gone forever, no chance of undo. Proceed?'
        },
        { color: this.colors.mainAccent })
        
      }
      
      if (conf) {
        this.setLoadingMessage('Deleting project')
        this.setSpinner(true)
        try {
          await CloudClient.call_CFdeleteProject(App.Firebase, projectObj['.key'])
        } catch (error) {
          if (error.message === 'Project does not exist') {
            // Find the index of the project in the array
            const index = this.projectsArray.myProjects.findIndex(project => project['.key'] === projectObj['.key'])
            console.log(index)
            // Check if the project was found
            if (index !== -1) {
              // Remove the project from the array
              this.projectsArray.myProjects.splice(index, 1)
            }
          } else {
            await this.$root.$globalAlert.open(
              'Error',
              {
                line1: `Could not delete project (${error.message})`,
              },
              { color: this.colors.mainAccent }
            )
          }
        }

        this.setLoadingMessage('')
        this.setSpinner(false)
      }
    },

    async setConnectedUsers() {
      if (localStorage.getItem('users')) {
        const hash = localStorage.getItem('users')

        const retreived = await this.$store.dispatch('decryptHash', hash)
        this.connectedUsers = Object.values(retreived);
        this.usersLoaded = true
      }
    }
  },

  async beforeMount(){
    const currentUser = App.spotterfishSession.userSession.firebaseCurrentUser
    this.userId = currentUser.uid
    this.permissions = await this.$store.dispatch(
      'user_check_account_and_permissions',
      { project: undefined, screeningRoom: undefined, user: currentUser }
    )

    if(localStorage.getItem('mxst-hiddenDemos')){
      this.hiddenDemos = true
    }

    App.spotterfishSession.userSession.addSessionListener('projects', this.handleGotProjects, true)
    this.setConnectedUsers()
  },
  destroyed(){
    App.spotterfishSession.userSession.removeSessionListener('projects', this.handleGotProjects)
  }

}
</script>

<style lang="scss" scoped>
  .project-row {
    overflow-y: hidden;
  }

  .fade {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .fade.visible {
    opacity: 1;
  }
</style>