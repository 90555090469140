<template>
  <div>
    <v-select
      style="margin-top: 10px"
      light
      outlined
      dense
      persistent-hint
      hint="Business or individual"
      v-model="inputSubscriptionTaxType"
      :items="inputSubscriptionTaxTypes"
      placeholder="For VAT calculation"
    />
    <v-text-field
      autocomplete="name"
      outlined
      color="#919191"
      light
      dense
      class="mb-1"
      type="text"
      :value="inputName"
      @input="inputNameChanged"
      :hint="`Full Legal Name ${
        isIndividualCustomer ? '' : 'of business'
      }`"
      :placeholder="`Full Legal Name ${
        isIndividualCustomer ? '' : 'of business'
      }`"
    />
    <v-select
      light
      outlined
      dense
      hint="Subscription type (VAT may be added for some regions)"
      persistent-hint
      v-model="selectedProduct"
      :items="subscriptionTypeOptions"
      placeholder="Select subscription period"
    />
    <!-- <div v-if="paymentLink">
      It seems like your browser has blocked the checkout page popup, click <a :href="paymentLink" target="_blank">here</a> to continue
    </div> -->
  </div>
</template>

<script>
//@ts-nocheck
import App from '@/App.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'

const TAX_TYPES = [
  {
    text: 'Business',
    value: 'business'
  },
  {
    text: 'Individual',
    value: 'individual'
  },
]

const sortFn = (a, b) => {
  if(a.name.includes('Daw Streaming')) return 1
  return -1
}

export default {
  props: ['dawStreaming', 'accountTier'],
  data(){
    return {
      inputSubscriptionTaxTypes: TAX_TYPES,
      inputSubscriptionTaxType: TAX_TYPES[0].value,
      selectedProduct: undefined,
      inputName: undefined,
      allLinks: undefined,
    }
  },
  computed: {
    isIndividualCustomer () {
      return this.inputSubscriptionTaxType === this.inputSubscriptionTaxTypes[1].value
    },
    subscriptionTypeOptions () {
      if(this.allLinks){
        const filter = this.inputSubscriptionTaxType === 'business' ? 'Business -' : 'Individual -'
        const links = this.allLinks.filter(link => link.name?.startsWith(filter)).map(link => {
          return {
            text: `${link.name.replace(filter, '')} ($${link.price}/${link.interval})`,
            value: link.id
          }
        })
        if(this.dawStreaming){
          return links.filter(l => !l.text?.includes('Daw Streaming')).sort(sortFn)
        }
        if(this.accountTier === 'tier2'){
          return links.filter(l => !l.text?.includes('Premium')).sort(sortFn)
        }
        return links
      }
      return []
    }   
  },
  methods: {
    onUserBusinessDataChange(newData, oldData){
      this.accountTier = newData.accountTier
      if(this.accountTier === 'tier2' && oldData !== undefined){
        //(_oldData === undefined || _oldData.accountTier === 'tier1')
        this.$emit('payment-complete')
      }
    },
    inputNameChanged(e){
      this.inputName = e
      this.$emit('input-name-changed', e)
    },
    async startPayment(){
      const {uid} = App.spotterfishSession.userSession.firebaseCurrentUser

      const checkoutSession = await CloudClient.call_CFStripeCreateCheckoutSession(App.spotterfishSession.firebase, uid, this.selectedProduct)

      await SpotterfishSession.updateUser(App.spotterfishSession.firestoreDB, uid, {
        payment_data: {
          full_name: this.inputName
        }
      })
      window.location.href = checkoutSession.url
      // const checkoutPage = window.open(checkoutSession.url, 'checkoutPage')
      // if(!checkoutPage){
      //   this.$emit('payment-popup-failed', paymentLink)
      // }
    }
  },
  watch: {
    inputSubscriptionTaxType(newValue){
      const filter = newValue === 'business' ? 'Business -' : 'Individual -'
      this.selectedProduct = this.allLinks.filter(link => link.name?.startsWith(filter))[0].id
    }
  },
  async mounted(){
    this.currentUser = this.$store.state.currentSpotterfishUser
    this.inputName = this.currentUser?.payment_data?.full_name
    this.$emit('input-name-changed', this.inputName)

    this.allLinks = await CloudClient.call_CFStripeGetPaymentLinks(App.spotterfishSession.firebase)
    this.selectedProduct = this.allLinks.sort(sortFn).filter(link => link.name?.startsWith('Business -'))[0]?.id
  },
  destroyed(){
    App.spotterfishSession?.userSession?.removeSessionListener('user_business_data', this.onUserBusinessDataChange)
  }

}
</script>