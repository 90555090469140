<template>
  <v-text-field
    append-icon="mdi-close"
    validate-on-blur
    outlined
    color="white"
    dense
    background-color="#1E1E1E"
    label="00:00:00:00"
    :clearable="false"
    :value="value"
    :rules="[ rules.validSMPTE ]"
    @change="onSMPTEinputChanged($event)"
  />
</template>

<script>
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import CalcDeltaSecondsFromUIString from '@/../source_files/spotterfish_library/CalcDeltaSecondsFromUIString'

function calcSecondsFromUISMPTEString (uiSMPTEstring, framerate) {
  const frk =  Timecode.findFrameRateKeyFromNickname(framerate)
  const frameRateNickname = Timecode.getFrameRateNickname(frk)
  
  const validatedValue = Timecode.isValidSMPTETimeCode(uiSMPTEstring, frameRateNickname) ? 
    uiSMPTEstring : 
    CalcDeltaSecondsFromUIString.addSMPTEseparators(uiSMPTEstring) 
  console.log(validatedValue)
  if (Timecode.isValidSMPTETimeCode(validatedValue, frameRateNickname)) {
    return Timecode.SMPTEStringToSeconds(validatedValue, frk)
  }
  else {
    throw new Error('invalid input')
  }
}
export default {
  props:['framerate', 'onChange'],
  computed: {
    frk(){
      return Timecode.findFrameRateKeyFromNickname(this.framerate)
    }
  },
  data(){
    return {
      rules: {
        validSMPTE: value => { return Timecode.isValidSMPTETimeCode(value, this.framerate, false) }
      },
      value: '',
    }
  },
  methods: {
    onSMPTEinputChanged (value) {
      try{
        const valueAsSeconds = calcSecondsFromUISMPTEString(value, this.framerate)
        this.value = this.secondsToTimecode(valueAsSeconds)
        this.$emit('change', valueAsSeconds)
      } catch (error){
        this.value = this.secondsToTimecode(0)
        console.error('Not a valid timecode')
      }
    },
    secondsToTimecode (seconds) {
      return Timecode.secondsToSMPTEString(seconds, this.frk)
    }
  },

}
</script>