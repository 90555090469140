<template>
  <div class="wrapper">
      <div class="mixContainer" style="margin: 10% 10% 0 0;">
        <div class="mixWrapper"><div class="mix-stage left"></div></div>
        <div class="mixWrapper"><div class="mix-stage center"></div></div>
        <div class="mixWrapper"><div class="mix-stage right"></div></div>
      </div>
    <h3 style="margin: 10% 10% 0 0; font-weight: 400;">{{ loadingMessage }}</h3>
  </div>
</template>

<script>
//@ts-nocheck
export default {
  props: ['showSpinner', 'loadingMessage'],
  data(){
    return {
      colors: require('@/lib/ui/colors.js').colors,
    }
  }
}
</script>

<style lang="scss" scoped>
$boxSize: 8px;
$borderRadius: 20px;
$initialHeight: 40%;
$stretchFactor: 2.8; // Adjust this factor to find the right balance

@keyframes left-indicator {
  25% { transform: scaleY(1.5); border-radius: calc($borderRadius / (1.5 * $stretchFactor)); }
  50% { transform: scaleY(1); border-radius: calc($borderRadius); }
  75% { transform: scaleY(2); border-radius: calc($borderRadius / (2 * $stretchFactor)); }
}

@keyframes right-indicator {
  25% { transform: scaleY(1.75); border-radius: calc($borderRadius / (1.75 * $stretchFactor)); }
  50% { transform: scaleY(1); border-radius: calc($borderRadius); }
  75% { transform: scaleY(1.5); border-radius: calc($borderRadius / (1.5 * $stretchFactor)); }
}

@keyframes mid-indicator {
  25% { transform: scaleY(2.5); border-radius: calc($borderRadius / (2.5 * $stretchFactor)); }
  50% { transform: scaleY(1); border-radius: calc($borderRadius); }
  75% { transform: scaleY(2.8); border-radius: calc($borderRadius / (3 * $stretchFactor)); }
}

.wrapper {
  background: none; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  margin: 0; padding: 0;
  height: 200px;
}

.mixContainer {
  display: flex;
  justify-content: space-between;
  height: 64px;
  --boxSize: #{$boxSize};
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 3);
}

.mixWrapper {
  height: 100%;
  width: var(--boxSize);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mix-stage {
  border-radius: $borderRadius;
  transform: scaleY(1);
  height: $initialHeight;
  width: 100%;
  background: linear-gradient(180deg, rgba(224,17,228,100) 0%, rgba(224,17,228,100) 35%, rgba(155,27,217, 100) 100%);
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.left { animation-name: right-indicator; }
.center { animation-name: mid-indicator; }
.right { animation-name: left-indicator; }

</style>