const Constants = require('../consts')
const isDevelopment = require('../SpotterfishCore').isDevelopment
const SpotterfishCore = require('..//SpotterfishCore')

function getStripePricingTableId (accountTier, isDAWstreamer) {
  
  if (accountTier === 'tier1' && isDAWstreamer) {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[0] : Constants.STRIPE_PRICING_TABLES[0]
  } else if (accountTier === 'tier1' && !isDAWstreamer) {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[1] : Constants.STRIPE_PRICING_TABLES[1]
  } else if (accountTier === 'tier2' && isDAWstreamer) {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[2] : Constants.STRIPE_PRICING_TABLES[2]
  } else if (accountTier === 'tier2' && !isDAWstreamer) {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[3] : Constants.STRIPE_PRICING_TABLES[3]
  } else if (accountTier === 'custom') {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[4] : Constants.STRIPE_PRICING_TABLES[4]
  } else {
    return isDevelopment() ? Constants.STRIPE_PRICING_TABLES_DEV[5] : Constants.STRIPE_PRICING_TABLES[5]
  }
}

function getStripePricingId (actionString) {
  if (actionString === 'additional') {
    return isDevelopment() ? Constants.MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID_DEV : Constants.MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID
  }
  else {
    return isDevelopment() ? Constants.MIXSTAGE_UNLIMITED_PRICE_ID_DEV : Constants.MIXSTAGE_UNLIMITED_PRICE_ID
  }
}

function getStripeKey () {
  return isDevelopment() ? Constants.STRIPE_PUBLISHABLE_KEY_DEV : Constants.STRIPE_PUBLISHABLE_KEY_PROD
}

function getAccountName (accountTier, isDAWstreamer) {

  if (accountTier === 'tier1' && isDAWstreamer) {
    return Constants.ACCOUNT_NAMES[0]
  } else if (accountTier === 'tier1' && !isDAWstreamer) {
    return Constants.ACCOUNT_NAMES[1]
  } else if (accountTier === 'tier2' && isDAWstreamer) {
    return Constants.ACCOUNT_NAMES[2]
  } else if (accountTier === 'tier2' && !isDAWstreamer) {
    return Constants.ACCOUNT_NAMES[3]
  } else if (accountTier === 'custom') {
    return  Constants.ACCOUNT_NAMES[4]
  } else {
    return Constants.ACCOUNT_NAMES[5]
  }
}


class ExpiredTrialError extends Error {
  constructor(message) {
    super(message)
    this.name = "ExpiredTrialError"
  }
}


function calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser) {
  SpotterfishCore.unitTestAssert(SpotterfishCore.isObjectInstance(stripeUserRecord) || !stripeUserRecord )
  SpotterfishCore.unitTestAssert(SpotterfishCore.isStringInstance(accountTier) || !accountTier)
  SpotterfishCore.unitTestAssert(SpotterfishCore.isBooleanInstance(isStripeUser))

  let accountType
  if (accountTier === 'custom') {
    accountType = Constants.ACCOUNT_TYPES.CUSTOM;
  } else if (stripeUserRecord?.hasMadePurchase) {
    accountType = accountTier === 'tier1' || accountTier === 'tier2' ? Constants.ACCOUNT_TYPES.BASIC : Constants.ACCOUNT_TYPES.UNLIMITED;
  } else if (!isStripeUser) {
    accountType = Constants.ACCOUNT_TYPES.BASIC;
  } else if (stripeUserRecord?.daysLeftInTrial !== null && stripeUserRecord?.daysLeftInTrial >= 0) {
    accountType = Constants.ACCOUNT_TYPES.FREE_TRIAL;
  } else {
    accountType = Constants.ACCOUNT_TYPES.REVIEW;
  }

  // Construct the user record
  let userRecord = {
    ...stripeUserRecord,
    accountString: accountType.status,
    userAccountCTA: accountType.cta,
    accountPlan: accountType.plan instanceof Function ? accountType.plan(stripeUserRecord?.daysLeftInTrial) : accountType.plan,
    tooltip: accountType.tooltip,
  };

  // Sentry error logging for discrepancy
  if (!isStripeUser && stripeUserRecord?.id) {
    console.error("User exists in Stripe but has no user record in our database.", { /* Sentry log details */ });
  }

  // Handle expired trial logic
  if (stripeUserRecord?.daysLeftInTrial < 0) {
    userRecord.accountString = Constants.ACCOUNT_TYPES.EXPIRED.status;
    userRecord.userAccountCTA = Constants.ACCOUNT_TYPES.EXPIRED.cta;
    userRecord.accountPlan = Constants.ACCOUNT_TYPES.EXPIRED.plan;
    userRecord.tooltip = Constants.ACCOUNT_TYPES.EXPIRED.tooltip;

    // Optionally: throw an error or update the user record in the database
    // throw new ExpiredTrialError("User's trial period has expired.");
  }

  return userRecord;
}



/*
Test User 1 (Has made a purchase, Has payment method, No free trial days left, Tier: tier1)

accountString: "basic"
userAccountCTA: "Upgrade"
accountPlan: "Basic plan"
Test User 2 (Has not made a purchase, Has payment method, 15 free trial days left, Tier: tier2)

accountString: "freeTrial"
userAccountCTA: "Add Card Details"
accountPlan: "Free trial - 15 days left"
Test User 3 (Has not made a purchase, Does not have a payment method, 5 free trial days left, Tier: custom)

accountString: "freeTrial"
userAccountCTA: "Add Card Details"
accountPlan: "Free trial - 5 days left"
*/

function prove_testUserWithPurchaseTier1() {
  const description = "User with purchase, tier1, and recorded in our database"
  const stripeUserRecord = { hasMadePurchase: true, hasPaymentMethod: true, daysLeftInTrial: null, id: "stripe_user_1" }
  const accountTier = "tier1"
  const isStripeUser = true

  const expected = {
    accountString: Constants.ACCOUNT_TYPES.BASIC.status,
    userAccountCTA: Constants.ACCOUNT_TYPES.BASIC.cta,
    accountPlan: Constants.ACCOUNT_TYPES.BASIC.plan,
    tooltip: Constants.ACCOUNT_TYPES.BASIC.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)

  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}

function prove_testUserInFreeTrialTier2() {
  const description = "User in free trial, tier2, not recorded in our database"
  const stripeUserRecord = { hasMadePurchase: false, hasPaymentMethod: true, daysLeftInTrial: 15, id: "stripe_user_2" }
  const accountTier = "tier2"
  const isStripeUser = false
  const expected = {
    accountString: Constants.ACCOUNT_TYPES.FREE_TRIAL.status, // Corrected to FREE_TRIAL
    userAccountCTA: Constants.ACCOUNT_TYPES.FREE_TRIAL.cta,
    accountPlan: `Free trial - ${stripeUserRecord.daysLeftInTrial} days left`, // Corrected to dynamic value based on daysLeftInTrial
    tooltip: Constants.ACCOUNT_TYPES.FREE_TRIAL.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)

  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}


function prove_testUserInFreeTrialCustom() {
  const description = "User in free trial, custom tier, recorded in our database"
  const stripeUserRecord = { hasMadePurchase: false, hasPaymentMethod: false, daysLeftInTrial: 5, id: "stripe_user_3" }
  const accountTier = "custom"
  const isStripeUser = true
  const expected = {
    accountString: Constants.ACCOUNT_TYPES.CUSTOM.status,
    userAccountCTA: Constants.ACCOUNT_TYPES.CUSTOM.cta,
    accountPlan: Constants.ACCOUNT_TYPES.CUSTOM.plan,
    tooltip: Constants.ACCOUNT_TYPES.CUSTOM.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)

  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}

function prove_testUserInCustom() {
  const description = "User has custom tier, no record in our database"
  const stripeUserRecord = { hasMadePurchase: false, hasPaymentMethod: false, daysLeftInTrial: undefined, id: "stripe_user_4" }
  const accountTier = "custom"
  const isStripeUser = false
  const expected = {
    accountString: Constants.ACCOUNT_TYPES.CUSTOM.status,
    userAccountCTA: Constants.ACCOUNT_TYPES.CUSTOM.cta,
    accountPlan: Constants.ACCOUNT_TYPES.CUSTOM.plan,
    tooltip: Constants.ACCOUNT_TYPES.CUSTOM.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)

  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}

function prove_testUserWithPurchaseTier2() {
  const description = "User with purchase, tier2, and recorded in our database"
  const stripeUserRecord = { hasMadePurchase: true, hasPaymentMethod: true, daysLeftInTrial: null, id: "stripe_user_5" }
  const accountTier = "tier2"
  const isStripeUser = true
  const expected = {
    accountString: Constants.ACCOUNT_TYPES.BASIC.status,
    userAccountCTA: Constants.ACCOUNT_TYPES.BASIC.cta,
    accountPlan: Constants.ACCOUNT_TYPES.BASIC.plan,
    tooltip: Constants.ACCOUNT_TYPES.BASIC.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)
  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}

function prove_testUserInReviewNoTrialOrPurchase() {
  const description = "User in review state with no trial or purchase"
  const stripeUserRecord = { hasMadePurchase: false, hasPaymentMethod: false, daysLeftInTrial: null, id: "stripe_user_6" }
  const accountTier = "review"
  const isStripeUser = true
  const expected = {
    accountString: Constants.ACCOUNT_TYPES.REVIEW.status,
    userAccountCTA: Constants.ACCOUNT_TYPES.REVIEW.cta,
    accountPlan: Constants.ACCOUNT_TYPES.REVIEW.plan,
    tooltip: Constants.ACCOUNT_TYPES.REVIEW.tooltip
  }

  const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)
  SpotterfishCore.unitTestAssert(result.accountString === expected.accountString)
  SpotterfishCore.unitTestAssert(result.userAccountCTA === expected.userAccountCTA)
  SpotterfishCore.unitTestAssert(result.accountPlan === expected.accountPlan)
  SpotterfishCore.unitTestAssert(result.tooltip === expected.tooltip)

  console.log(`Passed: ${description}`)
}

function prove_testUserWithNegativeFreeTrialDays() {
  const description = "User with negative free trial days"
  const stripeUserRecord = { hasMadePurchase: false, hasPaymentMethod: false, daysLeftInTrial: -5, id: "stripe_user_7" }
  const accountTier = "tier2"
  const isStripeUser = true
  
  let passed = false
  
  try {
    const result = calcUserAccountInfo(stripeUserRecord, accountTier, isStripeUser)
    // If the function call above did not throw an exception, check that the accountString is set to "expired".
    SpotterfishCore.unitTestAssert(result.accountString === Constants.ACCOUNT_TYPES.EXPIRED.status)
    SpotterfishCore.unitTestAssert(result.userAccountCTA === Constants.ACCOUNT_TYPES.EXPIRED.cta)
    SpotterfishCore.unitTestAssert(result.accountPlan === Constants.ACCOUNT_TYPES.EXPIRED.plan)
    SpotterfishCore.unitTestAssert(result.tooltip === Constants.ACCOUNT_TYPES.EXPIRED.tooltip)
    passed = true
  } catch (error) {
    if (error instanceof ExpiredTrialError) {
      passed = true // Exception was thrown as expected.
    } else {
      // An unexpected error type was thrown.
      console.error(error)
    }
  }

  if (passed) {
    console.log(`Passed: ${description}`)
  }
}




function runUnitTests () {
  console.log('User.js -- START')
  prove_testUserWithPurchaseTier1()
  prove_testUserInFreeTrialTier2()
  prove_testUserInFreeTrialCustom()
  prove_testUserInCustom()
  prove_testUserWithPurchaseTier2()
  prove_testUserInReviewNoTrialOrPurchase()
  prove_testUserWithNegativeFreeTrialDays()

  console.log('User.js -- END')
}

module.exports = {
  getStripePricingTableId,
  getStripePricingId,
  getStripeKey,
  getAccountName,
  calcUserAccountInfo,
  runUnitTests
}