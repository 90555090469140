const mimes = [
  { m: 'application/ogg', desc: '', hasVideo: false, isSupported: false },
  // { m: 'application/octet-stream', desc: 'Sometimes sent incorrectly as the mime type for ogg files' },
  { m: 'audio/mpeg', desc: 'MP3', hasVideo: false, isSupported: true },
  { m: 'audio/mp4', desc: '', hasVideo: false, isSupported: true },
  { m: 'audio/ogg', desc: '', hasVideo: false, isSupported: true },
  { m: 'audio/wav', desc: '', hasVideo: false, isSupported: true },
  { m: 'audio/webm', desc: '', hasVideo: false, isSupported: false },
  { m: 'audio/webm; codecs=vorbis', desc: '', hasVideo: false, isSupported: false },
  { m: 'audio/ogg; codecs=vorbis', desc: '', hasVideo: false, isSupported: false },
  { m: 'audio/ogg; codecs=speex', desc: '', hasVideo: false, isSupported: false },
  { m: 'audio/ogg; codecs=flac', desc: '', hasVideo: false, isSupported: false },
  { m: 'audio/aiff', desc: 'aiff', hasVideo: false, isSupported: false },
  { m: 'audio/aifc', desc: 'aifc', hasVideo: false, isSupported: false },
  { m: 'audio/caf', desc: 'caf', hasVideo: false, isSupported: false },

  // VIDEO
  { m: 'video/3gpp', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/3gpp2', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/avi', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/mpeg', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/mp4', desc: '', hasVideo: true, isSupported: true },
  { m: 'video/msvideo', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/quicktime', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/ogg', desc: '', hasVideo: true, isSupported: false },
  { m: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"', desc: 'H.264 Simple baseline profile video (main and extended video compatible) level 3 and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/mp4; codecs="avc1.58A01E, mp4a.40.2"', desc: 'H.264 Extended profile video (baseline-compatible) level 3 and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/mp4; codecs="avc1.4D401E, mp4a.40.2"', desc: 'H.264 Main profile video level 3 and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/mp4; codecs="avc1.64001E, mp4a.40.2"', desc: 'H.264 ‘High’ profile video (incompatible with main, baseline, or extended profiles) level 3 and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/mp4; codecs="mp4v.20.8, mp4a.40.2"', desc: 'MPEG-4 Visual Simple Profile Level 0 video and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/mp4; codecs="mp4v.20.240, mp4a.40.2"', desc: 'MPEG-4 Advanced Simple Profile Level 0 video and Low-Complexity AAC audio in MP4 container', hasVideo: true, isSupported: true },
  { m: 'video/3gpp; codecs="mp4v.20.8, samr"', desc: 'MPEG-4 Visual Simple Profile Level 0 video and AMR audio in 3GPP container', hasVideo: true, isSupported: false },
  { m: 'video/ogg; codecs="theora, vorbis"', desc: 'Theora video and Vorbis audio in Ogg container', hasVideo: true, isSupported: false },
  { m: 'video/ogg; codecs="theora, speex"', desc: 'Theora video and Speex audio in Ogg container', hasVideo: true, isSupported: false },
  { m: 'video/ogg; codecs="dirac, vorbis"', desc: 'Dirac video and Vorbis audio in Ogg container', hasVideo: true, isSupported: false },
  { m: 'video/webm', desc: '', hasVideo: true, isSupported: true },
  { m: 'video/webm; codecs="vp8, vorbis"', desc: '', hasVideo: true, isSupported: true },
  { m: 'video/mp2t', desc: 'MPEG transport stream (TS) - HTTP Streaming', hasVideo: true, isSupported: false },
  { m: 'application/x-mpegURL', desc: '.M3U8 - HTTP Streaming', hasVideo: true, isSupported: true },
  { m: 'vnd.apple.mpegURL', desc: '.M3U8 - HTTP Streaming', hasVideo: true, isSupported: false }
]

module.exports = {
  mimes
}
