<template>
  <v-container>
    <v-row>
      <v-col class="mt-4" cols="6" style="margin: auto;">
        <v-card light height="400">
          <v-text-field class="mt-2" label="Enter Email" v-model="currentEmail" @keyup.enter="addEmail" outlined dense clearable></v-text-field>
          <v-btn color="primary" @click="addEmail">Add</v-btn>

          <v-list dense>
            <v-list-item v-for="(email, index) in emails" :key="index">
              <v-list-item-content>{{ email }}</v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeEmail(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//@ts-nocheck
import App from '@/App.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import validator from 'validator'


export default {
  data(){
    return {
      currentEmail: '',
      emails: [],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => validator.isEmail(v) || 'E-mail must be valid'
      ],    }
  },
  computed: {
    
    subscriptionTypeOptions () {
      if(this.allLinks){
        const filter = this.inputSubscriptionTaxType === 'business' ? 'Business -' : 'Individual -'
        const links = this.allLinks.filter(link => link.name?.startsWith(filter)).map(link => {
          return {
            text: `${link.name.replace(filter, '')} ($${link.price}/${link.interval})`,
            value: link.id
          }
        })
        if(this.dawStreaming){
          return links.filter(l => !l.text?.includes('Daw Streaming')).sort(sortFn)
        }
        if(this.accountTier === 'tier2'){
          return links.filter(l => !l.text?.includes('Premium')).sort(sortFn)
        }
        return links
      }
      return []
    }   
  },
  methods: {
    isEmailValid(email) {
      return this.emailRules.every((rule) => typeof rule(email) === 'boolean')
    },
    addEmail() {
      if (this.currentEmail && this.isEmailValid(this.currentEmail) === true) {
        this.emails.push(this.currentEmail)
        this.currentEmail = ''
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1)
    },
    onUserBusinessDataChange(newData, oldData){
      this.accountTier = newData.accountTier
      if(this.accountTier === 'tier2' && oldData !== undefined){
        //(_oldData === undefined || _oldData.accountTier === 'tier1')
        this.$emit('payment-complete')
      }
    },
    inputNameChanged(e){
      this.inputName = e
      this.$emit('input-name-changed', e)
    },
    async startPayment(){
      const {uid} = App.spotterfishSession.userSession.firebaseCurrentUser

      const checkoutSession = await CloudClient.call_CFStripeCreateCheckoutSession(App.spotterfishSession.firebase, uid, this.selectedProduct)

      await SpotterfishSession.updateUser(App.spotterfishSession.firestoreDB, uid, {
        payment_data: {
          full_name: this.inputName
        }
      })
      window.location.href = checkoutSession.url
      // const checkoutPage = window.open(checkoutSession.url, 'checkoutPage')
      // if(!checkoutPage){
      //   this.$emit('payment-popup-failed', paymentLink)
      // }
    }
  },
  async mounted(){
    this.currentUser = this.$store.state.currentSpotterfishUser
    this.inputName = this.currentUser?.payment_data?.full_name
    this.$emit('input-name-changed', this.inputName)

    this.allLinks = await CloudClient.call_CFStripeGetPaymentLinks(App.spotterfishSession.firebase)
    this.selectedProduct = this.allLinks.sort(sortFn).filter(link => link.name?.startsWith('Business -'))[0]?.id
  },
  destroyed(){
    App.spotterfishSession?.userSession?.removeSessionListener('user_business_data', this.onUserBusinessDataChange)
  }

}
</script>