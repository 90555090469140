//??? WARNING: "MTC" is not really MTC, but "SyncData" that is high-level representation of MTC


const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const Constants = require('../spotterfish_library/consts.js')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isObjectInstance = SpotterfishCore.isObjectInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const Timecode = require('../spotterfish_library/utils/Timecode')

const _ = require('lodash')


function makePackedMTCTest1(){
  return new Uint8Array([1, 2, 3, 4, 5,   6, 7, 8,    9, 10, 11, 12]).buffer
}

//  returns ArrayBuffer
function makePackedMTCTest2(){
  return new Uint8Array([ 1, 2, 3, 4, 5,   1, 0, 42,    0x12, 0x34, 0x56, 0x78 ]).buffer
}
function makeMTCTest2(){
  return {
    playInfo: { tc: Timecode.makeTimecodeParts(1, 2, 3, 4, 5), rateIndex: 1 },
    playing: false,
    projectFRK: 42,
    rtpTimestamp: 0x12345678
  }
}

function checkSyncData(value) {
 // ** removed assert ** //  assert(SpotterfishCore.isObjectInstance(value))
 // ** removed assert ** //  assert(value.playInfo === undefined || Timecode.checkTimecodeParts(value.playInfo.tc))
 // ** removed assert ** //  assert(value.playInfo === undefined || (SpotterfishCore.isNumberInstance(value.playInfo.rateIndex) && value.playInfo.rateIndex >= 0 && value.playInfo.rateIndex < 4))
 // ** removed assert ** //  assert(SpotterfishCore.isBooleanInstance(value.playing))
 // ** removed assert ** //  assert(SpotterfishCore.isNumberInstance(value.projectFRK) && value.projectFRK >= 0)
 // ** removed assert ** //  // assert(SpotterfishCore.isNumberInstance(value.rtpTimestamp) && value.rtpTimestamp >= 0)
  return true
}

// Returns 12-byte ArrayBuffer
function packSyncData (syncData) {
 // ** removed assert ** //  assert(checkSyncData(syncData))

  const ab = new ArrayBuffer(Constants.SPOTTERFISH_TIMECODE_BYTELENGTH)
  const view = new DataView(ab)

  if(syncData.playInfo){
    view.setUint8(0, syncData.playInfo.tc.hh)
    view.setUint8(1, syncData.playInfo.tc.mm)
    view.setUint8(2, syncData.playInfo.tc.ss)
    view.setUint8(3, syncData.playInfo.tc.ff)
    view.setUint8(4, syncData.playInfo.tc.sf8)
    view.setUint8(5, syncData.playInfo.rateIndex)
  }
  else {
    //  0xff => no playInfo
    view.setUint8(0, 0xff)

    view.setUint8(1, 0x00)
    view.setUint8(2, 0x00)
    view.setUint8(3, 0x00)
    view.setUint8(4, 0x00)
    view.setUint8(5, 0x00)
  }


  view.setUint8(6, syncData.playing)
  view.setUint8(7, syncData.projectFRK)

  view.setUint32(8, syncData.rtpTimestamp)

  return ab
}

function prove_packSyncData () {
  const result = packSyncData(makeMTCTest2())
  console.log(result)

  // @ts-ignore
  unitTestAssert(_.isEqual(new Uint8Array(result).buffer, makePackedMTCTest2()))
}

//  12 bytes input, as an ArrayBuffer
function unpackSyncData(buffer) {
 // ** removed assert ** //  assert(SpotterfishCore.isObjectInstance(buffer))
 // ** removed assert ** //  assert(buffer.byteLength === Constants.SPOTTERFISH_TIMECODE_BYTELENGTH)

  const view = new DataView(buffer)

  const playing = view.getUint8(6) === 1
  const projectFRK = view.getUint8(7)
  const clockOnWall = view.getUint32(8)     

  const hasPlayInfo = view.getUint8(0) !== 0xff
  if(hasPlayInfo){
    const hh = view.getUint8(0)
    const mm = view.getUint8(1)
    const ss = view.getUint8(2)
    const ff = view.getUint8(3)
    const sf8 = view.getUint8(4)
    const rateIndex = view.getUint8(5)

    return {
      playInfo: {
        tc: Timecode.makeTimecodeParts(hh, mm, ss, ff, sf8),
        rateIndex: rateIndex
      },
      playing: playing,
      projectFRK: projectFRK,
      rtpTimestamp: clockOnWall
    }
  }
  else {
    return {
      playInfo: undefined,
      playing: playing,
      projectFRK: projectFRK,
      rtpTimestamp: clockOnWall
    }
  }
}

function prove_unpackSyncData () {
  const result = unpackSyncData(makePackedMTCTest2())
  console.log(result)

  // @ts-ignore
  unitTestAssert(_.isEqual(result, makeMTCTest2()))
}

// MSR: 2023-10-03: New function, for better real time performance
function concatenateTypedArrays(a, b) {
  // Creates a new array with total length
  const result = new Uint8Array(a.length + b.length);

  // Populates the new array with data from 'a'
  result.set(a);

  // Populates the new array with data from 'b' starting at the end of 'a'
  result.set(b, a.length);

  return result;
}

// Appends 12 byte MTC (as ArrayBuffer) to chunk's data
function appendTimecodeToChunk(chunk, mtcData) {
  const chunkData = new Uint8Array(chunk.data)
  const mtcDataArray = new Uint8Array(mtcData)
  const chunkWithTimeCode = concatenateTypedArrays(chunkData, mtcDataArray)
  
  chunk.data = chunkWithTimeCode.buffer
  return chunk
}

// Appends 12 byte MTC (as ArrayBuffer) to chunk's data
// function appendTimecodeToChunk (chunk, mtcData) {
//   chunk.data = new Uint8Array([ ...new Uint8Array(chunk.data), ...new Uint8Array(mtcData)]).buffer
//   return chunk
// }


function prove_appendTimecodeToChunk () {
  const RTCEncodedAudioFrame = {
    data: new Uint8Array([10, 20, 30]).buffer,
    timestamp: 3943370955
  }
  const result = appendTimecodeToChunk(RTCEncodedAudioFrame, makePackedMTCTest1())

  console.log(result.data)

  // @ts-ignore
  unitTestAssert(_.isEqual(new Uint8Array(result.data), new Uint8Array([10, 20, 30, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])))
}

function getSyncDataTime(syncData){
 // ** removed assert ** //  assert(syncData.playInfo !== undefined)
  const frk = Timecode.mtcFrameRateIndexToKey(syncData.playInfo.rateIndex)
  const frkInfo = Timecode.kFrameRateKeyCatalog[frk]
  return syncData.playInfo ? Timecode.timecodePartsToSeconds(syncData.playInfo.tc, frkInfo.frameDivision) : undefined
}


function runUnitTests () {
  console.log('SyncData -- start')
  prove_packSyncData()
  prove_unpackSyncData()
  prove_appendTimecodeToChunk()
  console.log('SyncData -- end')
}

module.exports = {
  checkSyncData,
  packSyncData,
  unpackSyncData,
  appendTimecodeToChunk,
  getSyncDataTime,

  runUnitTests
}