// User feature bits type (UFB) and helpers

const _ = require('lodash')
const SpotterfishCore = require('./SpotterfishCore')
const UserFeatureBitsModule = require('./userFeatureBits.js')
const TableFormatting = require('./tableFormatting.js')

const isValidISODateString = SpotterfishCore.isValidISODateString
const isArrayInstance = SpotterfishCore.isArrayInstance
const isObjectInstance = SpotterfishCore.isObjectInstance
const isBooleanInstance = SpotterfishCore.isBooleanInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isFunctionInstance = SpotterfishCore.isFunctionInstance




function runUnitTests () {
  console.log('userJournalProducts.js -- START')

/*
  prove_isNumericRoomKey()
*/


  console.log('userJournalProducts.js -- END')
}

module.exports = {
/*
  makeRoomFeaturesString
*/
  runUnitTests
}
