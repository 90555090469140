<template>
<div>
  <h1>TESTS</h1>
  <pre id="output"></pre>
</div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import runUnitTests from '@/../source_files/runUnitTests'

export default {
  name: 'tests',
  methods: {
    appendMessage (text) {
      document.querySelector('#output').innerHTML += text + '<br>'
    },

    // ??? Unify admin vs spotterfishSession
    async testCalculateFeatureBitsForRoomTiming (firebase) {
      console.log('Calling testCalculateFeatureBitsForRoomTiming')

      console.log('Calling calculateFeatureBitsForRoom outside timeout')
      await CloudClient.call_CFcalculateFeatureBitsForRoom(firebase, 'cYcxilr05lBKvhclfiIr')

      setTimeout(() => {
        console.log('Triggering outer timeout inside testCalculateFeatureBitsForRoomTiming')
        setInterval(async () => {
          console.log('Calling calculateFeatureBitsForRoom inside inner interval')
          await CloudClient.call_CFcalculateFeatureBitsForRoom(firebase, 'cYcxilr05lBKvhclfiIr')
        }, 500)
      }, 10000
      )
    }
  },

  async mounted () {
    this.appendMessage('Running all unit tests…')
    runUnitTests.runTests('development')
    const result = await this.$store.dispatch('cf_initialize_user', {
      userObject: {
        deleted_account: false,
        email: `test${Math.floor(1000 + Math.random() * 9000)}@test.com`,
        user_name: 'M',
        password: 'qwerty1234',
        newsletter: false,
        promo_code: ''
      },
      invitedBy: ''
    })
    const success = await CloudClient.call_CFcreateDemoProjects(App.Firebase, result.newUserId)
    if (!success) {
      console.error('Could not create demo projects')
    }
    console.log(result)
    this.appendMessage('Unit tests done, check console for results.')

    // console.log('Running all system tests, check console')
    // this.testCalculateFeatureBitsForRoomTiming(App.Firebase)
  }
}
</script>
