var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"markersContainerDiv",staticClass:"outercontainer"},[_c('div',{staticStyle:{"width":"100%","margin":"-1.1em auto","background":"rgba(255,255,255,0)","height":"48px","display":"block"}},_vm._l((_vm.markers),function(marker){return _c('div',{key:marker['.key'],staticClass:"markers-container"},[(marker.tc_pos)?_c('div',{staticClass:"single-marker-container",class:[
            _vm.belowLine ? 'upsidedown' : '',
            (marker['.key'] === _vm.isHighlightedMarker) ? 'highlighted' : 'unscaled',
            (marker['.key'] === _vm.isSelectedMarker) ? 'scaled' : 'unscaled'
          ],style:({ left: _vm.calcMarkerGUIPosition0(marker.tc_pos) }),attrs:{"id":marker['.key']},on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleRightClick(marker, $event)}}},[_c('div',{staticClass:"icon-inner-container"},[_c('i',{staticClass:"mdi mdi-square",style:({ color: _vm.markertype(marker).color }),on:{"click":function($event){return _vm.clicked(marker)}}})]),_c('div',{staticClass:"marker-tooltip"},[_c('span',[_vm._v(_vm._s(_vm.calcHTMLOutputSMPTE(marker.tc_pos)))]),(marker.title)?_c('br'):_vm._e(),(marker.title)?_c('span',[_vm._v(_vm._s(marker.title))]):_vm._e(),(marker.description)?_c('br'):_vm._e(),(marker.description)?_c('span',[_vm._v(_vm._s(marker.description))]):_vm._e()])]):_vm._e()])}),0),(_vm.contextMenuMarker && _vm.UI_disableTimelineInput === false)?_c('contextMenu',{attrs:{"position":_vm.contextMenuPosition,"isVisible":_vm.contextMenuMarker !== undefined},on:{"context-menu-change":function($event){return _vm.handleContextMenuChanged()}},scopedSlots:_vm._u([{key:"submenu-title",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"mdi mdi-palette pl-2 pr-2"}),_vm._v(" Change Marker Type "),_c('i',{staticClass:"mdi mdi-chevron-right pr-1",staticStyle:{"font-size":"2em"}})])]},proxy:true},{key:"submenu-content",fn:function(){return [_c('div',{staticClass:"marker-edit-category-buttons d-flex align-center"},_vm._l((_vm.fixedCommentTypes),function(item,index){return _c('v-btn',{key:index,staticClass:"markertype elevation-5 rounded gray-border",style:({
                      background: _vm.fixedCommentTypes[index].color,
                      color: _vm.fixedCommentTypes[index].textColor 
                  }),attrs:{"small":""},on:{"click":function($event){return _vm.handleColorSelected(_vm.fixedCommentTypes[index], _vm.contextMenuMarker)}}},[_vm._v(" "+_vm._s(_vm.fixedCommentTypes[index].name)+" ")])}),1)]},proxy:true}],null,false,421654592)},[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.handleItemClick('highlight-marker', _vm.contextMenuMarker['.key'] === _vm.isHighlightedMarker)}}},[_c('i',{staticClass:"mdi mdi-bell-alert-outline pr-1"}),_vm._v(" "+_vm._s(_vm.contextMenuMarker['.key'] === _vm.isHighlightedMarker ? 'Remove highlight from marker' : 'Highlight marker (for all users)')+" ")]),_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.handleItemClick('reposition-marker')}}},[_c('i',{staticClass:"mdi mdi-update pr-1"}),_vm._v(" Move Marker to Playhead Position ")]),_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.handleItemClick('delete-marker')}}},[_c('i',{staticClass:"mdi mdi-delete pr-1"}),_vm._v(" Delete marker ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }