// User feature bits type (UFB) and helpers

const _ = require('lodash')
const SpotterfishCore = require('./SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isObjectInstance = SpotterfishCore.isObjectInstance
const isBooleanInstance = SpotterfishCore.isBooleanInstance
const isStringInstance = SpotterfishCore.isStringInstance
const compareObjectsByValue = SpotterfishCore.compareObjectsByValue
const compareArraysByValue = SpotterfishCore.compareArraysByValue
const compareObjectKeys = SpotterfishCore.compareObjectKeys


/*
  User Feature Bits aka UFB


  {
    //  "user" is required and holds per-user features
    user: {
    - **daw_streaming**
      Enables daw streaming for this user in any session.
      true: on
      false / undefined: off
    }

    // Exactly one key for each room the user owns.
    // If there are no rooms, rooms is empty dictonary.
    // Use getUFBRooms() to always get a dictionary, empty if there are no rooms.
    rooms: {
      // All fields except seats are optional and interpreted as false if undefined
      //  Notice: this means that a false-property can be expressed both as a *missing* property and as a myproperty = false.

      // Room ID is always a string of a number between 1 and 1000, OR, for legacy UFBs
      // the alphanumeric database room ID.
      roomUid: {

      - **seats**
        Required: Number of seats in room. 2, 5 10, 20 or 30 seats.



      - **requires_email_verification**
        True if the room requires every user to have verified their email address.

      - **requires_two_factor_auth**
        True if the room requires every user to have activated two-factor authentication, and
        logged in within the last 40 minutes.

      - **requires_room_pin**
        True if the room requires every user to enter a room pin code each time she enters.

      - **domain_whitelist_enabled**
        Every user email is required to match the domain white list



      - **video_chat_enabled**
        Enables Janus video chat.

      - **daw_streaming_enabled**
        Enables daw straming.



      - **show_free_version_banner**
        Shows the free version banner in the room

      - **custom_background_color**
        Use a custom color for the room background.

      - **custom_logo**
        Allow adding a custom logo in the transport panel.
      }
    }
  }
*/

// Check that the key is of the numeric kind used for e.g. journal room IDs.
function isNumericRoomKey (key) {
  const numInt = parseInt(key, 10)
  return numInt >= 1 && numInt <= 1000
}


function prove_isNumericRoomKey () {
  unitTestAssert(isNumericRoomKey('1') === true)
  unitTestAssert(isNumericRoomKey('999') === true)

  unitTestAssert(isNumericRoomKey('0') === false)
  unitTestAssert(isNumericRoomKey('1001') === false)
  unitTestAssert(isNumericRoomKey('abc') === false)
  unitTestAssert(isNumericRoomKey('h54ih5u4h5ui43h5iu34') === false)
}

// Check that the key is of the alphanumeric kind used for e.g. DB IDs.
function isAlphaNumericRoomKey (key) {
  return isStringInstance(key) && key.match(/^\w{20}$/) !== null
}


function prove_isAlphaNumericRoomKey () {
  unitTestAssert(isAlphaNumericRoomKey('1') === false)
  unitTestAssert(isAlphaNumericRoomKey('999') === false)
  unitTestAssert(isAlphaNumericRoomKey('h54ih5u4h5ui43h5iu34') === true)
  unitTestAssert(isAlphaNumericRoomKey('markus12@example.com') === false)
}

function checkInvariantUFB (ufb) {
  assert(isObjectInstance(ufb))

  assert(isObjectInstance(ufb.user))
  assert(ufb.user.daw_streaming === undefined || isBooleanInstance(ufb.user.daw_streaming))

  assert(isObjectInstance(ufb.rooms))
  for (const [key, value] of Object.entries(ufb.rooms)) {
    assert(checkInvariantRoomFeatureBits(value))
    assert(isStringInstance(key) && key.length > 0)
    assert(isNumericRoomKey(key) || isAlphaNumericRoomKey(key))
  }
  return true
}

function checkInvariantRoomFeatureBits (room) {
  assert(typeof room.seats === 'number' &&(room.seats === 2 || room.seats === 5 || room.seats === 10 || room.seats === 20 || room.seats === 30 || room.seats >= 30))


  assert(room.requires_email_verification === undefined || isBooleanInstance(room.requires_email_verification))
  assert(room.requires_two_factor_auth === undefined || isBooleanInstance(room.requires_two_factor_auth))
  assert(room.requires_room_pin === undefined || isBooleanInstance(room.requires_room_pin))
  assert(room.domain_whitelist_enabled === undefined || isBooleanInstance(room.domain_whitelist_enabled))


  assert(room.video_chat_enabled === undefined || isBooleanInstance(room.video_chat_enabled))
  assert(room.daw_streaming_enabled === undefined || isBooleanInstance(room.daw_streaming_enabled))


  assert(room.show_free_version_banner === undefined || isBooleanInstance(room.show_free_version_banner))
  assert(room.custom_background_color === undefined || isBooleanInstance(room.custom_background_color))
  assert(room.custom_logo === undefined || isBooleanInstance(room.custom_logo))
  return true
}



//  Data is expected to be a valid UFB, or we assert.
function createUFB2 (data) {
  assert(checkInvariantUFB(data))

  return data
}

function prove_createUFB2_blank () {
  const a = createUFB2({ user: {}, rooms: {} })
  unitTestAssert(checkInvariantUFB(a))
}
function prove_createUFB2_complex () {
  let r = createUFB2(
    {
      user: { daw_streaming: true },
      rooms: {
        1000: {
          seats: 5,

          requires_email_verification: true,
          requires_two_factor_auth: true,
          requires_room_pin: true,
          domain_whitelist_enabled: true,


          video_chat_enabled: true,
          daw_streaming_enabled: true,


          show_free_version_banner: true,
          custom_background_color: true,
          custom_logo: true
        }
      }
    }
  )
  unitTestAssert(checkInvariantUFB(r))
}





//  Use this to see if two UFBs are equivalent.
//  Checks the room IDs too
//  The rooms fields are quite flexible (leave out a flag field => it's false)
function compareUFBs (a, b) {
  assert(checkInvariantUFB(a))
  assert(checkInvariantUFB(b))

  const as = makeUFBCompactStrings(a)
  const bs = makeUFBCompactStrings(b)
  return as === bs
}

function compareRoomFeatures(a, b) {
  assert(isObjectInstance(a))
  assert(isObjectInstance(b))

  const as = makeRoomFeaturesString(a)
  const bs = makeRoomFeaturesString(b)
  return as === bs
}


function makeBlankUFB () {
  let r = createUFB2({ user: {}, rooms: {} })
  assert(checkInvariantUFB(r))
  return r
}

function prove_makeBlankUFB () {
  let a = makeBlankUFB()
  assert(checkInvariantUFB(a))
}



// Return rooms from UFB, always as an object.
function getUFBRooms (ufb) {
  assert(checkInvariantUFB(ufb))
  return ufb.rooms
}

function prove_getUFBRooms () {
  assert(compareObjectsByValue(getUFBRooms(makeBlankUFB()), {}))
  assert(compareObjectsByValue(
    getUFBRooms(createUFB2({ user: {}, rooms: { '123': { seats: 2 } } } )),
    { '123': { seats: 2 }}
  ))
}


function makeTier1Example () {
  let a = createUFB2(
    {
      user: {},
      rooms: {
        1000: {
          'seats': 5,
          'video_chat_enabled': true,
          'show_free_version_banner': true
        }
      }
    }
  )

  assert(checkInvariantUFB(a))
  assert(a.rooms[1000].seats === 5)
  return a
}


function make5RoomAllFeatures () {
  let r = createUFB2(
    {
      user: { daw_streaming: true },
      rooms: {
        1000: {
          seats: 5,

          requires_email_verification: true,
          requires_two_factor_auth: true,
          requires_room_pin: true,
          domain_whitelist_enabled: true,


          video_chat_enabled: true,
          daw_streaming_enabled: true,


          show_free_version_banner: true,
          custom_background_color: true,
          custom_logo: true
        }
      }
    }
  )

  assert(checkInvariantUFB(r))
  return r
}

function prove_make5RoomAllFeatures () {
  let a = make5RoomAllFeatures()
  unitTestAssert(checkInvariantUFB(a))
  unitTestAssert(Object.keys(a.rooms).length === 1)
  unitTestAssert(a.rooms[1000].seats === 5)
  unitTestAssert(a.rooms[1000].custom_logo === true)
  unitTestAssert(a.rooms[1000].daw_streaming_enabled === true)
}

function make2RoomsExample () {
/*
const example2roomsUFB = {
"rooms": {
    "1000": {
        "seats": 5,
        "domain_whitelist_enabled": true,
        "video_chat_enabled": true
    },
    "1001": {
        "seats": 20,
        "requires_email_verification": true,
        "video_chat_enabled": true
    }
}
}
*/

  let r = createUFB2(
    {
      user: {},
      rooms: {
        999: {
          'seats': 5,
          'domain_whitelist_enabled': true,
          'video_chat_enabled': true
        },
        1000: {
          'seats': 20,
          'requires_email_verification': true,
          'video_chat_enabled': true
        }
      }
    }
  )

  unitTestAssert(checkInvariantUFB(r))
  return r
}



// CONVERSION FUNCTIONS


function makeUserFeaturesString (user) {
  assert(isObjectInstance(user))

  const s = user.daw_streaming ? 'D' : 'd'
  return s
}

//  Returns a string representing one room's contents
//  Ex return: "5 OOO- O -OO" or "10 OOO- O -OO". Does not include the room ID.
function makeRoomFeaturesString (room) {
  assert(isObjectInstance(room))

  const s = room.seats.toString().padStart(2, ' ') + ' ' +


    (room.requires_email_verification ? 'O' : '-') +
    (room.requires_two_factor_auth ? 'O' : '-') +
    (room.requires_room_pin ? 'O' : '-') +
    (room.domain_whitelist_enabled ? 'O' : '-') +

    ' ' +

    (room.video_chat_enabled ? 'O' : '-') +
    (room.daw_streaming_enabled ? 'O' : '-') +

    ' ' +

    (room.show_free_version_banner ? 'O' : '-') +
    (room.custom_background_color ? 'O' : '-') +
    (room.custom_logo ? 'O' : '-')
  return s
}

//  Returns array of compact strings, one for user-level params PLUS one for each room.
//  Ex return: [ "a011f00  5 OOO- OO -OO", "41aab21 10 OOO- OO -OO" ]
//                         | room.seats
//                           | requires_email_verification
//                            | requires_two_factor_auth
//                             | requires_room_pin
//                              | domain_whitelist_enabled

//                                | video_chat_enabled
//                                 | daw_streaming_enabled

//                                   | show_free_version_banner
//                                    | custom_background_color
//                                     | custom_logo

function makeUFBCompactStrings (ufb) {
  assert(checkInvariantUFB(ufb))

  var result = [ makeUserFeaturesString(ufb.user)]
  for (const [key, value] of Object.entries(getUFBRooms(ufb))) {
    const roomFeaturesString = makeRoomFeaturesString(value)
    const s = key + ' ' + roomFeaturesString
    result.push(s)
  }

  return result
}


function prove_makeUFBCompactString_blank () {
  const a = makeUFBCompactStrings(makeBlankUFB())
  unitTestAssert(compareArraysByValue(a, [ 'd' ]) === true)
}

function prove_makeUFBCompactString_tier1 () {
  const a = makeUFBCompactStrings(makeTier1Example())
  unitTestAssert(compareArraysByValue(a, [ 'd', '1000  5 ---- O- O--' ]) === true)
}

function prove_makeUFBCompactString_5RoomAllFeatures () {
  const a = makeUFBCompactStrings(make5RoomAllFeatures())
  unitTestAssert(compareArraysByValue(a, [ 'D', '1000  5 OOOO OO OOO' ]) === true)
}

function prove_makeUFBCompactString_2rooms () {
  const a = makeUFBCompactStrings(make2RoomsExample())
  unitTestAssert(compareArraysByValue(a,
    [
      'd',
      '999  5 ---O O- ---',
      '1000 20 O--- O- ---'
    ]
  ) === true)
}


//  No rooms => 'blank'
//  1 room => '2-seater', '5-seater', '10-seater', '20-seater', '30-seater'. Other sizes throws exception.
//  2+ rooms => 'custom'
function calcUFBPreset (ufb) {
  assert(checkInvariantUFB(ufb))

  if (Object.keys(ufb.rooms).length === 0) {
    return 'blank'
  } else {
    const roomKeys = Object.keys(ufb.rooms)
    if (roomKeys.length === 1) {
      const key = roomKeys[0]
      // console.log(key)
      const room = ufb.rooms[key]
      const seats = room.seats
      // console.log(seats)
      if (seats === 2) {
        return '2-seater'
      } else if (seats === 5) {
        return '5-seater'
      } else if (seats === 10) {
        return '10-seater'
      } else if (seats === 20) {
        return '20-seater'
      } else if (seats === 30) {
        return '30-seater'
      } else {
        assert(false)
        return 'unknown'
      }
    } else {
      return 'custom'
    }
  }
}


function prove_calcUFBPreset_blank () {
  const a = calcUFBPreset(makeBlankUFB())
  unitTestAssert(a === 'blank')
}

function prove_calcUFBPreset_2_seater () {
  const a = calcUFBPreset(createUFB2({ user: {}, rooms: { '123': { seats: 2 } } }))
  unitTestAssert(a === '2-seater')
}

function prove_calcUFBPreset_5_seater () {
  unitTestAssert(calcUFBPreset(createUFB2({ user: {}, rooms: { '123': { seats: 5 } } })) === '5-seater')
}

function prove_calcUFBPreset_10_seater () {
  unitTestAssert(calcUFBPreset(createUFB2({ user: {}, rooms: { '123': { seats: 10 } } })) === '10-seater')
}

function prove_calcUFBPreset_20_seater () {
  unitTestAssert(calcUFBPreset(createUFB2({ user: {}, rooms: { '123': { seats: 20 } } })) === '20-seater')
}

function prove_calcUFBPreset_30_seater () {
  unitTestAssert(calcUFBPreset(createUFB2({ user: {}, rooms: { '123': { seats: 30 } } })) === '30-seater')
}

function prove_calcUFBPreset_custom () {
  unitTestAssert(calcUFBPreset(createUFB2({ user: {}, rooms: { '100': { seats: 5 }, '200': { seats: 5 } } })) === 'custom')
}



// function overwriteMembers (destDict, modifierDict) {
//   assert(isObjectInstance(destDict))
//   assert(isObjectInstance(modifierDict))




//   const modifierUser = ufbModifier.user === undefined ? {} : ufbModifier.user
//   const modifierRooms = ufbModifier.rooms === undefined ? {} : ufbModifier.rooms

//   //  Cannot check room keys / add or remove rooms YET.
//   assert(compareObjectKeys(ufb.rooms, modifierRooms) === true)

//   const user2 = _.cloneDeep(ufb.user)
//   var rooms2 = _.cloneDeep(ufb.rooms)
//   for (const [roomKey, sourceRoomValue] of Object.entries(modifierRooms)) {
//     //  Copy properties from *modifier* into the room.
//     for (const [propertyKey, propertyValue] of Object.entries(sourceRoomValue)) {
//       rooms2[roomKey][propertyKey] = propertyValue
//     }
//   }
//   return createUFB2({ user: {}, rooms: rooms2 })
// }




//  ??? TODO: Add support for delete/add of rooms.
//  Modifies input UFB and returns an updated copy.
//  ufbModifier = a object of same room-topology as the input UFB.
//  Notice that ufbModifier is not a REAL UFB object, it might omit seats-property etc.
//  Every property included in ufbModifier will be *copied* to output, all other properties will be left as-is.
function modifyUFB (ufb, ufbModifier) {
/*
  console.log('inputs')
  console.log(ufb)
  console.log(ufbModifier)
*/

  assert(checkInvariantUFB(ufb))
  assert(isObjectInstance(ufbModifier))

  const modifierUser = ufbModifier.user === undefined ? {} : ufbModifier.user
  const modifierRooms = ufbModifier.rooms === undefined ? {} : ufbModifier.rooms

  //  Cannot check room keys / add or remove rooms YET.
  assert(compareObjectKeys(ufb.rooms, modifierRooms) === true)

  const user2 = _.cloneDeep(ufb.user)
  var rooms2 = _.cloneDeep(ufb.rooms)
  for (const [roomKey, sourceRoomValue] of Object.entries(modifierRooms)) {
    //  Copy properties from *modifier* into the room.
    for (const [propertyKey, propertyValue] of Object.entries(sourceRoomValue)) {
      rooms2[roomKey][propertyKey] = propertyValue
    }
  }
  return createUFB2({ user: {}, rooms: rooms2 })
}


function prove_modifyUFB () {
  const a = modifyUFB(makeBlankUFB(), {})
  unitTestAssert(Object.keys(a.rooms).length === 0)
}


function prove_modifyUFB2 () {
  const a = makeTier1Example()
  const b = modifyUFB(a, { rooms: { 1000: { seats: 10 } } })
  unitTestAssert(compareObjectsByValue(b,
    {
      user: {},
      rooms: {
        1000: { seats: 10, video_chat_enabled: true, show_free_version_banner: true }
      }
    }
  ) === true)
}


function prove_modifyUFB_2Rooms () {
  const a = make2RoomsExample()
  const b = modifyUFB(a, { rooms: { 999: {}, 1000: {} } })
  unitTestAssert(compareObjectsByValue(a, b) === true)
}


function prove_modifyUFB_2RoomsVideoChat_DisableRoom1VideoChat () {
  const a = make2RoomsExample()
  const b = modifyUFB(a, { rooms: { 999: { video_chat_enabled: false }, 1000: {} } })
  const expected =
    {
      user: {},
      rooms: {
        999: { seats: 5, video_chat_enabled: false, domain_whitelist_enabled: true },
        1000: { seats: 20, video_chat_enabled: true, requires_email_verification: true }
      }
    }
    unitTestAssert(compareObjectsByValue(b, expected) === true)
}


function prove_modifyUFB_2RoomsVideoChat_DisableBothVideoChats () {
  const a = make2RoomsExample()
  const b = modifyUFB(a, { rooms: { 999: { video_chat_enabled: false }, 1000: { video_chat_enabled: false } } })
  const expected =
    {
      user: {},
      rooms: {
        999: { seats: 5, video_chat_enabled: false, domain_whitelist_enabled: true },
        1000: { seats: 20, video_chat_enabled: false, requires_email_verification: true }
      }
    }
  unitTestAssert(compareObjectsByValue(b, expected) === true)
}


function prove_modifyUFB_2RoomsVideoChat_ChangeRoom2Seats () {
  const a = make2RoomsExample()
  const b = modifyUFB(a, { rooms: { 999: {}, 1000: { seats: 30 } } })
  const expected =
    {
      user: {},
      rooms: {
        999: { seats: 5, video_chat_enabled: true, domain_whitelist_enabled: true },
        1000: { seats: 30, video_chat_enabled: true, requires_email_verification: true }
      }
    }
  unitTestAssert(compareObjectsByValue(b, expected) === true)
}


// TEST RUNNER


function runUnitTests () {
  console.log('userFeatureBits.js -- START')

  prove_isNumericRoomKey()
  prove_isAlphaNumericRoomKey()

  prove_createUFB2_blank()
  prove_createUFB2_complex()
  prove_makeBlankUFB()
  prove_getUFBRooms()
  prove_make5RoomAllFeatures()
  make2RoomsExample()

  prove_makeUFBCompactString_blank()
  prove_makeUFBCompactString_tier1()
  prove_makeUFBCompactString_5RoomAllFeatures()
  prove_makeUFBCompactString_2rooms()

  prove_calcUFBPreset_blank()
  prove_calcUFBPreset_2_seater()
  prove_calcUFBPreset_5_seater()
  prove_calcUFBPreset_10_seater()
  prove_calcUFBPreset_20_seater()
  prove_calcUFBPreset_30_seater()
  prove_calcUFBPreset_custom()

  prove_modifyUFB()
  prove_modifyUFB2()
  prove_modifyUFB_2Rooms()
  prove_modifyUFB_2RoomsVideoChat_DisableRoom1VideoChat()
  prove_modifyUFB_2RoomsVideoChat_DisableBothVideoChats()
  prove_modifyUFB_2RoomsVideoChat_ChangeRoom2Seats()

  console.log('userFeatureBits.js -- END')
}

// TODO: Decide what functions should be exported, i.e. what is the public API.
module.exports = {
  isNumericRoomKey,
  isAlphaNumericRoomKey,
  checkInvariantUFB,
  checkInvariantRoomFeatureBits,

  createUFB2,
  compareUFBs,
  compareRoomFeatures,
  makeBlankUFB,
  getUFBRooms,
  modifyUFB,

  makeTier1Example,
  make5RoomAllFeatures,
  make2RoomsExample,
  makeUFBCompactStrings,
  makeRoomFeaturesString,
  runUnitTests
}
