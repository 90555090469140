const BusinessLogic = require('../spotterfish_library/BusinessLogic')

const dougBrokenUser = {
  'rooms': {
    'Eed7bYwv6QTCRff0soO8': {
      'marker_lane': 'SV6R3dIypvLxD92UsvYI',
      'background_color': '#000000FF',
      'MCORP_APPID': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'video_room_server': '',
      'lightsOff': false,
      'motionKey': '',
      'project_load_count': 138,
      'name': "Doug's Screening Room",
      'video_room_id': 4,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false
      ],
      'requesting_access': [],
      'MCORP_MOTION_NAME': '',
      'last_active': {
        'seconds': 1597356186,
        'nanoseconds': 157000000
      },
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'moderator': false,
      'seats': 5,
      'current_project': 'bhnXrmSSUSA2guEgNwgb'
    },
    'XnHQ43zdHdzg1XlgvdhE': {
      'name': 'L3 20 seats 1',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_two_factor_auth': true,
      'seats': 20,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_room_pin': true,
      'video_room_server': '',
      'current_project': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'marker_lane': '',
      'motionKey': '',
      'requires_email_verification': true,
      'MCORP_APPID': '',
      'MCORP_MOTION_NAME': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ]
    },
    'Vcn5vUd9Jy31CG101fof': {
      'name': "Doug's Video Theater",
      'MCORP_MOTION_NAME': '',
      'video_chats_disabled': true,
      'motionKey': '',
      'marker_lane': '',
      'seats': 80,
      'requires_email_verification': false,
      'current_project': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_room_pin': false,
      'MCORP_APPID': '',
      'background_color': '#000000FF',
      'requires_two_factor_auth': false,
      'project_load_count': 50,
      'video_room_server': '',
      'requesting_access': [],
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
    },
    'b1VUKAgAfgFmwy9Jqh8x': {
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'Bryon.Williams@warnerbros.com',
        'gkx6JGsz9Ndamr10xR4vfN7DosB2',
        'XTayshiGxDfvJiyRhHPDi1zRYw83',
        'brandon.cammack@warnerbros.com',
        'G8NM3ULicrfCZz6CWB4pOpCxkt43',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'N33DMh95JYfjS4eJVEUgTeOlXpb2',
        'AtXXfCu6BlPJH8K9GlSjB9Ukf4e2',
        'timc@asgllc.com',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2'
      ],
      'seats': 20,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'Bryon.Williams@warnerbros.com',
        'gkx6JGsz9Ndamr10xR4vfN7DosB2',
        'XTayshiGxDfvJiyRhHPDi1zRYw83',
        'brandon.cammack@warnerbros.com',
        'G8NM3ULicrfCZz6CWB4pOpCxkt43',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'N33DMh95JYfjS4eJVEUgTeOlXpb2',
        'AtXXfCu6BlPJH8K9GlSjB9Ukf4e2',
        'timc@asgllc.com',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2'
      ],
      'video_room_server': '',
      'motionKey': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'moderator': true,
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'AtXXfCu6BlPJH8K9GlSjB9Ukf4e2',
        'N33DMh95JYfjS4eJVEUgTeOlXpb2',
        'timc@asgllc.com',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2'
      ],
      'name': 'Open',
      'video_room_id': 7,
      'marker_lane': 'SruanTlgqCfpHoPFNiMn',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'Bryon.Williams@warnerbros.com',
        'gkx6JGsz9Ndamr10xR4vfN7DosB2',
        'XTayshiGxDfvJiyRhHPDi1zRYw83',
        'brandon.cammack@warnerbros.com',
        'G8NM3ULicrfCZz6CWB4pOpCxkt43',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'N33DMh95JYfjS4eJVEUgTeOlXpb2',
        'AtXXfCu6BlPJH8K9GlSjB9Ukf4e2',
        'timc@asgllc.com',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2',
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'lightsOff': false,
      'MCORP_APPID': '',
      'MCORP_MOTION_NAME': '',
      'current_project': 'DdQyqFKE1DowPA75a9pw'
    },
    'g6miHlhTSz6UZKCF04AP': {
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_MOTION_NAME': '',
      'marker_lane': '',
      'name': 'L2 10 seats 1',
      'current_project': '',
      'requires_two_factor_auth': true,
      'video_room_server': '',
      'requires_email_verification': true,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'motionKey': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seats': 10,
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_room_pin': false,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'MCORP_APPID': ''
    },
    'zCUoXDQWrUKPpBetmhHA': {
      'lightsOff': false,
      'video_room_server': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'nZTAM4V2jYMs77TZFaFEkSlks2a2'
      ],
      'motionKey': '',
      'seats': 10,
      'video_room_id': 3,
      'MCORP_APPID': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'jeremy.peirson@warnerbros.com',
        '84lGV9VAEDYofqrU4GnyFFVezz43',
        'nZTAM4V2jYMs77TZFaFEkSlks2a2',
        'z6LcT9B9lYRFqiMa58lm2pjElHi2'
      ],
      'moderator': true,
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'nZTAM4V2jYMs77TZFaFEkSlks2a2',
        'jeremy.peirson@warnerbros.com',
        '84lGV9VAEDYofqrU4GnyFFVezz43',
        'z6LcT9B9lYRFqiMa58lm2pjElHi2'
      ],
      'MCORP_MOTION_NAME': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'name': 'Warner 09/29',
      'current_project': 'DdQyqFKE1DowPA75a9pw',
      'marker_lane': '38ByudUd2LoXokkochHE',
      'last_active': {
        'nanoseconds': 722000000,
        'seconds': 1596729418
      },
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'nZTAM4V2jYMs77TZFaFEkSlks2a2',
        'jeremy.peirson@warnerbros.com',
        '84lGV9VAEDYofqrU4GnyFFVezz43',
        false,
        false,
        'z6LcT9B9lYRFqiMa58lm2pjElHi2'
      ]
    },
    'pRdKzohq3ddGnQUGb6w7': {
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'garrick@asgllc.com',
        'AtXXfCu6BlPJH8K9GlSjB9Ukf4e2',
        'zbLHa5ME2pfyfvPLaBEHZrVT3qF2',
        'brent@asgllc.com',
        'ed@asgllc.com',
        'jshike@asgllc.com',
        'csullivan@asgllc.com',
        'syoung@asgllc.com',
        'andy@asgllc.com',
        'sQW3AZ7AoiZ5lcMG2iICv2H6oji1',
        '1M3lflPGkPT3EmGlXTsPru8jqmx2',
        'dot3yPoerQhylYNf4GhoAvT8bVg2',
        'kelli@asgllc.com',
        'Xr4aV3mhopca4rgkWX9WhTr2rtp2',
        'mgroh@asgllc.com',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862'
      ],
      'MCORP_MOTION_NAME': '',
      'lightsOff': false,
      'seats': 10,
      'current_project': 'UAK4SHcTNp9S2WSffxNW',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2'
      ],
      'MCORP_APPID': '',
      'moderator': true,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2'
      ],
      'name': 'ASG',
      'requesting_access': [
        'lkxSpqnfmuhlc3ZKF3BlECHq2ia2'
      ],
      'marker_lane': 'sQBDnq9zCqVPdx0JMpRE',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'video_room_server': '',
      'motionKey': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2',
        false,
        false,
        false,
        false,
        false,
        false
      ]
    },
    'oJrgjLTD2gwW17G6oVmG': {
      'seats': 5,
      'current_project': '',
      'MCORP_MOTION_NAME': '',
      'requires_email_verification': true,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_APPID': '',
      'name': 'L3 5 seats 1',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_room_pin': true,
      'video_room_server': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_two_factor_auth': true,
      'motionKey': '',
      'marker_lane': ''
    },
    'CzluKNJR2zy9SJsFqMjy': {
      'seats': 10,
      'background_color': '#000000FF',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'MCORP_MOTION_NAME': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'name': 'Sound Designers',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'video_room_server': '',
      'project_load_count': 10,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'video_room_id': 6,
      'current_project': 'bhnXrmSSUSA2guEgNwgb',
      'high_security': false,
      'motionKey': '',
      'lightsOff': false,
      'security_level': 1,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_APPID': '',
      'requesting_access': [],
      'marker_lane': 'SV6R3dIypvLxD92UsvYI'
    },
    'S6N1qyPmrnR4cKp3ftBh': {
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'QGK4cP2J7zS7FyAZ1svFgPYhQey1',
        '75jC1c2yL6dBZkGQiT9uA1bZSJs2',
        'BrlzKiOBtgbQkrCTsLq8stq5BUG2',
        'Pbm557B5hzebdDVnARoNTeCqYQP2',
        'nVBO068XmeN8zgJVxPRZsNgkcL92',
        'Az3QOvxNrPRfyt77CLl7LzgD7nE2',
        'l6Jtpit4uOT5bkkvQzYRbywSAP83',
        'HJcHo9NsBeT6pGjGNNGQAtn1N3m2',
        'oPjGNOKKwVb64Ae0ZEkCS0Eddvs2',
        'tGTvtsclOlXrRpQA2lwgGkRy7452'
      ],
      'requesting_access': [],
      'moderator': true,
      'seats': 20,
      'video_room_id': 1,
      'current_project': 'MSAXsNtTR3bRMP78AZ50',
      'MCORP_APPID': '',
      'motionKey': '',
      'marker_lane': 'iZ7et2704iwVn1iFF2sv',
      'last_active': {
        'nanoseconds': 704000000,
        'seconds': 1597645874
      },
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'QGK4cP2J7zS7FyAZ1svFgPYhQey1',
        '75jC1c2yL6dBZkGQiT9uA1bZSJs2',
        'BrlzKiOBtgbQkrCTsLq8stq5BUG2',
        'Pbm557B5hzebdDVnARoNTeCqYQP2',
        'nVBO068XmeN8zgJVxPRZsNgkcL92',
        'Az3QOvxNrPRfyt77CLl7LzgD7nE2',
        'l6Jtpit4uOT5bkkvQzYRbywSAP83',
        'HJcHo9NsBeT6pGjGNNGQAtn1N3m2',
        'oPjGNOKKwVb64Ae0ZEkCS0Eddvs2',
        false,
        'tGTvtsclOlXrRpQA2lwgGkRy7452',
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'lightsOff': false,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        '',
        'jgRZskvXj7M4c1MHoHEv2PiXpcF3',
        'QGK4cP2J7zS7FyAZ1svFgPYhQey1',
        '75jC1c2yL6dBZkGQiT9uA1bZSJs2',
        'BrlzKiOBtgbQkrCTsLq8stq5BUG2',
        'Pbm557B5hzebdDVnARoNTeCqYQP2',
        'nVBO068XmeN8zgJVxPRZsNgkcL92',
        'Az3QOvxNrPRfyt77CLl7LzgD7nE2',
        'l6Jtpit4uOT5bkkvQzYRbywSAP83',
        'HJcHo9NsBeT6pGjGNNGQAtn1N3m2',
        'oPjGNOKKwVb64Ae0ZEkCS0Eddvs2',
        'tGTvtsclOlXrRpQA2lwgGkRy7452'
      ],
      'MCORP_MOTION_NAME': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        'NppCCeIhyiUOBBmZ4oWecrNfT232',
        'a9L7JCKXuxdka8xEKBMgNC7u2up2',
        'TU5hl9iDCQX1XUSjrL52ltjqeLh1',
        'A5Rl0zIBgfURT1ZHLvsUAUQCqpn2',
        'P97gLfvjjSWVb7CvvrxsZNs8uAE2',
        'N33DMh95JYfjS4eJVEUgTeOlXpb2',
        'to7TG67vvzUHXc5jeiRFwvnaJjz2',
        'c4OsFZkDrndxB6lVmbSX0Kchw862'
      ],
      'name': '20 Seat Appointments',
      'video_room_server': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
    },
    'Q8x56AAXeJFajbmFztEO': {
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'WusHLzn3BFQ09Qkjke4hOqGZ3rq1'
      ],
      'requesting_access': [],
      'MCORP_APPID': '',
      'marker_lane': 'ZiQ53MVh5ILrhmNHOfVM',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'WusHLzn3BFQ09Qkjke4hOqGZ3rq1'
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'WusHLzn3BFQ09Qkjke4hOqGZ3rq1'
      ],
      'motionKey': '',
      'last_active': {
        'nanoseconds': 780000000,
        'seconds': 1596479451
      },
      'video_room_server': '',
      'current_project': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'video_room_id': 2,
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'lightsOff': false,
      'seats': 2,
      'MCORP_MOTION_NAME': '',
      'name': 'Open'
    },
    'HWzuAOpgLb2cRIkAvHpU': {
      'motionKey': 'BCvsV6BO8V85qb7Zs3tx',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'SW3tPcmBWlTdMSN8u66ZBJ8b1yG3'
      ],
      'MCORP_MOTION_NAME': 'sfish00002',
      'lightsOff': false,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'SW3tPcmBWlTdMSN8u66ZBJ8b1yG3'
      ],
      'requesting_access': [],
      'MCORP_APPID': '4133518239055280247',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'marker_lane': 'fGMWOMRrZRBCnGKmbpEy',
      'video_room_server': 'https://videochat.audiopostr.com:8089/janus',
      'last_active': {
        'seconds': 1597193302,
        'nanoseconds': 206000000
      },
      'seats': 2,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'SW3tPcmBWlTdMSN8u66ZBJ8b1yG3'
      ],
      'video_room_id': 2,
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'eFWPBeUlfOaFQDmtGyyErERIabo1'
      ],
      'current_project': 'p62fON23MQbacenz8sdB',
      'name': 'Chris/Doug'
    },
    'cUBeWuWGZVEqNLWYAPpH': {
      'current_project': '',
      'MCORP_APPID': '',
      'requires_email_verification': true,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'motionKey': '',
      'video_room_server': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seats': 20,
      'requires_room_pin': false,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'MCORP_MOTION_NAME': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_two_factor_auth': true,
      'name': 'L2 20 seats 1',
      'marker_lane': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ]
    },
    'WfpT5f4dPGDbdP1Jg7Gk': {
      'video_room_id': 4,
      'last_active': {
        'seconds': 1597880522,
        'nanoseconds': 210000000
      },
      'video_room_server': '',
      'lightsOff': false,
      'MCORP_MOTION_NAME': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'MCORP_APPID': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'current_project': 'sivtx2z5aRpIiJbxRow8',
      'motionKey': '',
      'marker_lane': 'OB0fCQVASgmPlqN3vc5n',
      'seats': 20,
      'name': 'Open'
    },
    'uMGjUCKwIdCEVLVHSTFC': {
      'MCORP_APPID': '',
      'requires_email_verification': true,
      'marker_lane': '',
      'requires_room_pin': true,
      'name': 'L3 10 seats 2',
      'current_project': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'MCORP_MOTION_NAME': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_two_factor_auth': true,
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'motionKey': '',
      'seats': 10,
      'video_room_server': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
    },
    'uMb4my6xSfbOuntfpdYl': {
      'video_room_server': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'fu8BKp2bHMe6uNBafG6i6ZJWuhF2',
        'CylxhPVKndYNrEo7lRpv0Fz3a9B3',
        'SX2sABbbdGglLk7Jci3J5TgOhfH3',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2',
        false,
        false,
        false,
        false,
        false
      ],
      'current_project': '',
      'name': 'MidiWare 12/16',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'fu8BKp2bHMe6uNBafG6i6ZJWuhF2',
        'CylxhPVKndYNrEo7lRpv0Fz3a9B3',
        'SX2sABbbdGglLk7Jci3J5TgOhfH3',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2'
      ],
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'fu8BKp2bHMe6uNBafG6i6ZJWuhF2',
        'SX2sABbbdGglLk7Jci3J5TgOhfH3',
        'CylxhPVKndYNrEo7lRpv0Fz3a9B3',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2'
      ],
      'MCORP_APPID': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'fu8BKp2bHMe6uNBafG6i6ZJWuhF2',
        'CylxhPVKndYNrEo7lRpv0Fz3a9B3',
        'SX2sABbbdGglLk7Jci3J5TgOhfH3',
        'BffcUuwDH1gJeMgW6tYw6Oa2yLI2'
      ],
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'seats': 10,
      'MCORP_MOTION_NAME': '',
      'marker_lane': '',
      'requesting_access': [],
      'motionKey': ''
    },
    'NurQedA2do4mV5IA05uQ': {
      'seats': 5,
      'requires_two_factor_auth': true,
      'current_project': '',
      'video_room_server': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'marker_lane': '',
      'requires_email_verification': true,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false
      ],
      'name': 'L2 5 seats 1',
      'MCORP_MOTION_NAME': '',
      'MCORP_APPID': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_room_pin': false,
      'motionKey': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ]
    },
    'c5vL84UpK7s3FBTVEkxI': {
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false
      ],
      'marker_lane': '',
      'requires_room_pin': false,
      'requires_email_verification': true,
      'MCORP_MOTION_NAME': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_APPID': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'current_project': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seats': 5,
      'video_room_server': '',
      'name': 'L2 5 seats 2',
      'requires_two_factor_auth': true,
      'motionKey': ''
    },
    'F1JZU4ukQy8tgeVPJEtF': {
      'requires_room_pin': false,
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_email_verification': true,
      'current_project': '',
      'seats': 10,
      'video_room_server': '',
      'requires_two_factor_auth': true,
      'background_color': '#000000FF',
      'marker_lane': '',
      'MCORP_APPID': '',
      'MCORP_MOTION_NAME': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'motionKey': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'name': 'Producers/Directors'
    },
    'YkXdqzlxpwG1felVcnQS': {
      'current_project': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        '84lGV9VAEDYofqrU4GnyFFVezz43',
        'gkx6JGsz9Ndamr10xR4vfN7DosB2',
        'G8NM3ULicrfCZz6CWB4pOpCxkt43',
        'XTayshiGxDfvJiyRhHPDi1zRYw83',
        '0OcVuhTvVvOuQmWE2NWcwLmEv9t2',
        'W6kJoT5HkmWSp1ZnKrr6k6SrUlC3',
        'c4OsFZkDrndxB6lVmbSX0Kchw862',
        'FEbIx5GXbcQYa7qpWHT2PMTKzKA2',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2'
      ],
      'motionKey': '',
      'lightsOff': false,
      'video_room_id': '',
      'requesting_access': [],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2'
      ],
      'marker_lane': 'N8D2AC8hgZOCI9sGKkhM',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'video_room_server': '',
      'name': 'ASG East Coast Team Demo',
      'MCORP_APPID': '',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'moderator': false,
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'DqsgnekZaFfSezGpWm2e4PzKd5A2'
      ],
      'seats': 20,
      'MCORP_MOTION_NAME': ''
    },
    'hvXdiMCE2ve8BbzPKJwD': {
      'marker_lane': '',
      'requires_room_pin': true,
      'current_project': '',
      'MCORP_APPID': '',
      'requires_two_factor_auth': true,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seats': 20,
      'MCORP_MOTION_NAME': '',
      'motionKey': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'requires_email_verification': true,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'name': 'L3 20 seats 1',
      'video_room_server': ''
    },
    'GhzLoIeLvKfVgH2e6Tud': {
      'video_room_server': '',
      'requires_email_verification': true,
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_APPID': '',
      'marker_lane': '',
      'seats': 5,
      'requires_room_pin': true,
      'background_color': '#000000FF',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_MOTION_NAME': '',
      'motionKey': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'name': 'VO Talent',
      'current_project': '',
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false
      ],
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'requires_two_factor_auth': true
    },
    'kmpEXRUjgrN7m1y7fbme': {
      'marker_lane': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'current_project': '',
      'requires_email_verification': true,
      'motionKey': '',
      'MCORP_MOTION_NAME': '',
      'requires_two_factor_auth': true,
      'video_room_server': '',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'seats': 10,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'MCORP_APPID': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'name': 'L3 10 seats 1',
      'requires_room_pin': true
    },
    'KYbpHBzz7TUptQYDR5vX': {
      'MCORP_APPID': '',
      'current_project': 'LQE5zpQV0ACqm1HiEOHe',
      'moderator': false,
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'MCORP_MOTION_NAME': '',
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'cqmFx6l5AwZs6rpQTsjlNpT1wzi1',
        'KE0nMYnvhvOgDKgypDcVkKc9Xb62'
      ],
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'cqmFx6l5AwZs6rpQTsjlNpT1wzi1',
        'KE0nMYnvhvOgDKgypDcVkKc9Xb62'
      ],
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'lRlqw4bxrhfzMeHfr4eH9LEhOtq1',
        'cjuYnf3FK0bMS6XTLl5ltKvLQfv2',
        'mg2NOYLhufPvyRhl1n1jyPH3Tr82',
        'cqmFx6l5AwZs6rpQTsjlNpT1wzi1',
        'KE0nMYnvhvOgDKgypDcVkKc9Xb62'
      ],
      'name': 'Live Demo in Progress',
      'video_room_id': 2,
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        'cqmFx6l5AwZs6rpQTsjlNpT1wzi1',
        'KE0nMYnvhvOgDKgypDcVkKc9Xb62',
        false,
        false
      ],
      'video_room_server': '',
      'seats': 5,
      'lightsOff': false,
      'motionKey': '',
      'marker_lane': 'oxnoFRY3UPxnMB8HEY6z',
      'requesting_access': []
    },
    '3HWHRui8gZhhEPI3gu0V': {
      'marker_lane': '',
      'requires_room_pin': false,
      'background_color': '#000000FF',
      'people_seated': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'seatings': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      'name': 'Wine Documentary',
      'owner': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
      'current_project': '',
      'requires_email_verification': true,
      'video_room_server': '',
      'seats': 20,
      'requires_two_factor_auth': true,
      'motionKey': '',
      'MCORP_MOTION_NAME': '',
      'people_with_moderator_key': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'project_people': [
        'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2'
      ],
      'MCORP_APPID': ''
    }
  },
  'user': {
    'email': 'provisor@yahoo.com',
    'MCORP_MOTION_NAME': 'sfish00001',
    'screening_rooms': [
      'dtybgBK673DfTpsiGMxj',
      'Eed7bYwv6QTCRff0soO8',
      'NsbXFm0Yuo6Qbe6Jskxl',
      '9VlwboXQvWdfzbIahkKd',
      'KYbpHBzz7TUptQYDR5vX',
      'WfpT5f4dPGDbdP1Jg7Gk',
      'YkXdqzlxpwG1felVcnQS',
      'sbgLoQk6ivnpxUr5stjT',
      'CzluKNJR2zy9SJsFqMjy',
      'b1VUKAgAfgFmwy9Jqh8x',
      'HWzuAOpgLb2cRIkAvHpU',
      'Q8x56AAXeJFajbmFztEO',
      'wOBcpA48LkYJhqua4k3b'
    ],
    'temp_videoroom_id': 1725,
    'magic_subscription_data': '',
    'deleted_account': false,
    'subscription_type': 1,
    'user_id': 'JbMo4gyfAvgfwm8iJPIyJ5lSHQF2',
    'online': true,
    'signup_timestamp': 1569515082,
    'pict': 'https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/profile_pics%2FJbMo4gyfAvgfwm8iJPIyJ5lSHQF2?alt=media&token=bc4ea912-7194-4413-8cae-d5539c682e74',
    'guest_account': false,
    'MCORP_APP_ID': '7463748657528765958',
    'subscription_seats': 5,
    'user_name': 'Doug Provisor'
  }
}

function runUnitTests () {
  console.log('dougBrokenUser.js -- START')
  BusinessLogic.calcUFBFromAccount(dougBrokenUser)
  console.log('dougBrokenUser.js -- END')
}

module.exports = {
  runUnitTests
}
