// @ts-nocheck

import Vue from 'vue'
import Router from 'vue-router'
import App from '@/App.vue'

import dashboard from '@/components/dashboard/dashboard.vue'
import enroll from '@/components/API/enrollAuthFactor.vue'
import lobby from '@/components/lobby.vue'
import login from '@/components/API/login.vue'
import quicklogin from '@/components/API/quicklogin.vue'
import upgradeAnonymousAccount from '@/components/API/upgradeAnonymousAccount.vue'
import upgradeDialogBox from '@/components/API/upgradeDialogBox.vue'
import markerEdit from '@/components/commentscomponents/sfMarkerList.vue'
import markerlane from '@/components/commentscomponents/markerlane.vue'
import reauth from '@/components/API/reauth.vue'
import screeningroom from '@/components/screeningroom.vue'
import signout from '@/components/API/signout.vue'
import signup from '@/components/API/signup.vue'
import store from '@/store'
// import uploadWidget from '@/components/projectscomponents/uploadWidget.vue'
import authRedirect from '@/components/API/authRedirect.vue'
import tests from '@/components/tests.vue'

import SpotterfishCore from '@/../source_files/spotterfish_library/SpotterfishCore'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import CloudClient from '@/../source_files/web_client/CloudClient'
import projectorRoom from '@/components/dawstreamingcomponents/projectorRoom.vue'

// Admin views.
import accessDenied from '@/components/API/admin/accessDenied.vue'
import admin from '@/components/API/admin/admin.vue'
import getUsers from '@/components/API/admin/getUsers.vue'

import temp from '@/components/API/admin/temp.vue'


Vue.use(Router)

function isStudent(user) {
  return (user && user.email === 'student@du.se')
}


// links MAY have utm tags
// the following tags are available
/*
  utm_source=Newsletter
  utm_medium=Email
  utm_campaign=introducing+Mixstage
  utm_id=f
  utm_term=f
  utm_content=Email1
*/
async function logPageSwitch(to, from, segmentAnalytics, firestore, window) {

  const absoluteURL = new URL(to.path, window.location.origin).href;

  let res 
  if (to.query?.hash){
    res = await store.dispatch('decryptHash', to.query.hash)
  }
  
  let roomData
  if (res?.sroom) {
    const room = await firestore.collection('screening_rooms').doc(res?.sroom).get()
    roomData = room.data()
  }
  
  const params = {
    title: to.name,
    url: absoluteURL,
    path: to.path,
    referrer: from.name,
    roomId: res?.sroom,
    roomOwner: roomData?.owner,
    utm_source: to.query?.utm_source, 
    utm_medium: to.query?.utm_medium, 
    utm_campaign: to.query?.utm_campaign,
    utm_content: to.query?.utm_content,
    utm_id: to.query?.utm_id,
    utm_term: to.query?.utm_term,
    screen_height: window.innerHeight,
    screen_width: window.innerWidth
  }
  console.log(params)

  segmentAnalytics?.track(
    'page_view',
    params
  )
}

// ??? TODO: refactor this one and put db calls in cloud client
function resolveScreeningRoomPath(to, from, next) {
  store.dispatch('decryptHash', to.query.hash).then(async (link) => {
    if (link.sroom) {
      try {
        // A user not allowed here will not be able to retreive this
        const snapshot = await App.firestoreDB.collection('screening_rooms')
          .where(App.Firebase.firestore.FieldPath.documentId(), '==', link.sroom)
          .where('project_people', 'array-contains', App.spotterfishSession.userSession.firebaseCurrentUser.uid)
          .get()
        const snapshot2 = await App.firestoreDB.collection('screening_rooms')
          .where(App.Firebase.firestore.FieldPath.documentId(), '==', link.sroom)
          .where('audience_members', 'array-contains', App.spotterfishSession.userSession.firebaseCurrentUser.uid)
          .get()
        
        if(!snapshot2.empty && App.spotterfishSession.userSession.firebaseCurrentUser.isAnonymous === true && link.playerOnly) {
          console.log('Audience member is entering room - pushing straight to room, no security')
          next({
            query: {
              hash: to.query.hash
            }
          })
        }
        else if(snapshot.empty && App.spotterfishSession.userSession.firebaseCurrentUser.isAnonymous && !link.playerOnly) {
          console.log('Audience member trying to enter lobby - move to signUp')
          next({
            name: 'signup',
            query: {
              hash: to.query.hash
            }
          })
        }
        else if (snapshot.empty) {
          // No access yet
          next({
            name: 'lobby',
            query: {
              hash: to.query.hash
            }
          })
        } else {
          // OK To enter room
          if (from.name === 'lobby') {
            console.log()
            // OK To enter room
            const arr = []
            snapshot.forEach((doc) => {
              const room = doc.data()
              room['.key'] = doc.id
              arr.push(room)
            })

            if (arr[0].high_security) {
              let result
              try {
                result = await CloudClient.call_CFcheckIfUserAuthenticatedWithinLast45Minutes(App.Firebase)
              } catch (error) {
                alert('Could not check authentication time')
              }
              if (result !== true) {
                next({
                  name: 'reauth',
                  query: {
                    hash: to.query.hash
                  }
                })
              } else if (!(App.spotterfishSession.userSession.firebaseCurrentUser.multiFactor && App.spotterfishSession.userSession.firebaseCurrentUser.multiFactor.enrolledFactors.length)) {
                next({
                  name: 'enroll',
                  query: {
                    hash: to.query.hash
                  }
                })
              } else {
                // Is signed in properly
                console.log('signed in less than 2 minues ago, and use 2 factor auth')
                if (arr[0].security_level === 3) {
                  console.log('verificationCode passed into router: ', to.params.roomVerificationCode)
                  try {
                    if (await store.dispatch('cf_check_room_verification_code', {screeningRoomId: link.sroom, verificationCode: to.params.roomVerificationCode})) {
                      console.log('correct, trying to move to screening room')
                      next({
                        query: {
                          hash: to.query.hash
                        }
                      })
                    } else {
                      alert('Wrong code')
                      next({
                        name: 'lobby',
                        query: {
                          hash: to.query.hash
                        }
                      })
                    }
                  } catch (error) {
                    alert(`Could not verify code (${error.message})`)
                    next({
                      name: 'lobby',
                      query: {
                        hash: to.query.hash
                      }
                    })
                  }
                } else {
                  next({
                    query: {
                      hash: to.query.hash
                    }
                  })
                }
              }
            } else {
              // Normal room
              next({
                query: {
                  hash: to.query.hash
                }
              })
            }
          } else {
            next({
              name: 'lobby',
              query: {
                hash: to.query.hash
              }
            })
          }
        }
      } catch (error) {
        console.error(error)
        next({
          name: 'lobby',
          query: {
            hash: to.query.hash
          }
        })
      }
    } else {
      next({
        name: 'login',
        query: {
          hash: to.query.hash
        }
      })
    }
  }).catch((e) => {
    next('login')
  })
}

async function logOutIfAnonymous (to, from, next) {
  await SpotterfishSession.detectLoggedinTransient(App.spotterfishSession)
  const currentUser = App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser
  // second condition is a temporary fix, student@du.se should never be logged in except as audience
  if (currentUser.isAnonymous === true || isStudent(currentUser)) {
    console.log(`${currentUser.isAnonymous ? 'Anonymous' : 'Student'} user entered dashboard, logging out`)
    next({
      name: 'signout'
    })
  } else {
    next()
  }
}

// Redirect to the access denied page if user is not staff. Note that this is inherently
// unsafe since clients can manipulate client-side code. Any real admin work has to
// happen entirely on the server side. This is just a first simple layer of not exposing
// too much to users not deliberately trying to hack the system.
async function checkStaffAccess (to, from, next) {
  const isAdmin = await CloudClient.call_CFcheckIfCurrentUserIsSpotterfishStaff(App.Firebase)
  if (isAdmin) {
    next()
  } else {
    next({
      name: 'accessDenied'
    })
  }
}

const routerConfig = {
  routes: [
    {
      path: '*',
      redirect: '/main'
    },
    {
      path: '/',
      redirect: '/main'
    },
    {
      path: "/temp",
      name: "temp",
      component: temp
    },
    {
      path: '/main',
      name: 'main',
      component: dashboard,
      meta: {
        requiresAuth: true,
        shouldCheckForUpgrade: true
      },
      beforeEnter: logOutIfAnonymous
    },
    {
      path: '/dawstreamer',
      name: 'dawstreamer',
      component: projectorRoom,
      meta: {
        requiresAuth: true,
        shouldCheckForUpgrade: true,
        requiresDawStreamer: true
      }
    },
    {
      path: '/enroll',
      name: 'enroll',
      component: enroll,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/quicklogin',
      name: 'quicklogin',
      component: quicklogin,
      meta: {
        requiresAuth: false
      },
      beforeEnter: async (to, from, next) => {
        await SpotterfishSession.detectLoggedinTransient(App.spotterfishSession)
        const currentUser = App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser
        // second part is a temporary fix, student@du.se should never be sent to lobby
        // ??? TODO: this happens as soon as you enter if yuo are logged in - nickname only flashes by
        // this entire flow should be controlled with one function
        if (currentUser && !isStudent(currentUser) && !currentUser.isAnonymous) {
          console.log('User is already logged in, sending to regular screening room link')
          console.log(currentUser && !isStudent(currentUser))
          next({
            name: 'screeningroom',
            query: {
              hash: to.query.hash
            }
          })
        } else {
          next()
        }
      }
    },
    {
      path: '/screeningroom',
      name: 'screeningroom',
      component: screeningroom,
      props: true,
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, from, next) => {
        resolveScreeningRoomPath(to, from, next)
      },
      beforeRouteLeave (to, from, next) {
        console.log('ROUTER')
        // Prompt user to save / discard changes
        // If user agrees, save work and call next()
        // Else next()
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: dashboard,
      props: true,
      meta: {
        requiresAuth: true,
        shouldCheckForUpgrade: true
      },
      beforeEnter: logOutIfAnonymous
    },
    {
      path: '/signout',
      name: 'signout',
      component: signout,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      props: true,
      component: login
    },
    {
      path: '/reauth',
      name: 'reauth',
      component: reauth
    },
    {
      path: '/signup',
      name: 'signup',
      props: true,
      component: signup
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      component: upgradeDialogBox,
      meta: {
        requiresAuth: true
      }
      
    },
    {
      path: '/lobby',
      name: 'lobby',
      component: lobby,
      props: true,
      meta: {
        requiresAuth: true,
        shouldCheckForUpgrade: true
      }
    },
    {
      path: '/markerlane',
      name: 'markerlane',
      component: markerlane
    },
    {
      path: '/markeredit',
      name: 'markeredit',
      component: markerEdit,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/access-denied',
      name: 'accessDenied',
      component: accessDenied,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: admin,
      props: true,
      meta: {
        requiresAuth: true
      },
      beforeEnter: checkStaffAccess
    },
    {
      path: '/admin/get_users',
      name: 'adminGetUsers',
      component: getUsers,
      props: true,
      meta: {
        requiresAuth: true
      },
      beforeEnter: checkStaffAccess
    },
    {
      path: '/auth-redirect',
      name: 'authRedirect',
      component: authRedirect,
      props: true,
      meta: {
        requiresAuth: false
      }
    }
  ]
}

// Only include the tests route in safe environments.
if (SpotterfishCore.isDevelopment()) {
  routerConfig.routes.push({
    path: '/tests',
    name: 'tests',
    component: tests
  })
}

let router = new Router(routerConfig)

router.beforeEach(async (to, from, next) => {
  
  if (to.name === 'tests') {
    console.log('Tests route identified, skipping user session.')
    next()
    return
  }
  if (to.name === 'signout') {
    console.log('log out user without looking for logged in transient.')
    App.Firebase.auth().signOut().then(() => {
      window.location.href = 'https://www.mixstage.io'
    }).catch(err => {
      console.log(error)
    })
    return
  }
  
  await SpotterfishSession.detectLoggedinTransient(App.spotterfishSession)
  
  const currentUser = App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser
  
  const requiresDawStreamer = to.matched.some(record => record.meta.requiresDawStreamer)
  if(requiresDawStreamer){
    if(!App.spotterfishSession.userSession.ufb.user.daw_streaming){
      next({
        name: 'accessDenied'
      })
    }
  }
  // completely disable student login - no one should ever enter a room as student@du.se 2022-10-03 ->
  if(currentUser && isStudent(currentUser)) { App.Firebase.auth().signOut() }

  if(!store.state.currentSpotterfishUser && currentUser){
    await store.dispatch('user_get_with_realtime_listener', currentUser)
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    next({
      name: 'login',
      query: to.query
    })
  } else {

    logPageSwitch(to, from, App.spotterfishSession.segmentAnalytics, App.Firebase.firestore(), window)
    
    next({ query: to.query })
  }
})

export default router
