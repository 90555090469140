const BusinessLogic = require('./spotterfish_library/BusinessLogic')
const ClientConfig = require('./spotterfish_library/ClientConfig')
const Crypto = require('./spotterfish_library/Crypto')
const DomainUtils = require('./spotterfish_library/utils/DomainUtils')
const EraTests = require('./tests/eratests')
const PathUtils = require('./spotterfish_library/utils/PathUtils')
const Room = require('./spotterfish_library/Room')
// const ScreeningRoomSession = require('./web_client/ScreeningRoomSession')
const SpotterfishCore = require('./spotterfish_library/SpotterfishCore')
const SpotterfishHelpers = require('./spotterfish_library/SpotterfishHelpers')
const TableFormatting = require('./spotterfish_library/tableFormatting')
const User = require('./spotterfish_library/FirebaseUser')
const UserFeatureBits = require('./spotterfish_library/userFeatureBits')
const UserJournal = require('./spotterfish_library/userJournal')
const UserJournalProducts = require('./spotterfish_library/userJournalProducts')
const VideoPlayerUtils = require('./spotterfish_library/utils/VideoPlayerUtils')
const Timecode = require('./spotterfish_library/utils/Timecode')
const ClockValue = require('./spotterfish_library/ClockValue')
const SyncData = require('./spotterfish_library/SyncData')
const MarkerUtils = require('./spotterfish_library/utils/MarkerUtils')
const MIDIUtils = require('./spotterfish_library/utils/MIDIUtils')

// const MasterClock = require('./spotterfish_library/MasterClock')
const MMC = require('./spotterfish_library/MMC')
const MTC = require('./spotterfish_library/MTC')
const CalcDeltaSecondsFromUIString = require('./spotterfish_library/CalcDeltaSecondsFromUIString')
const VueUtils = require('./spotterfish_library/utils/VueUtils')
const StripeUtils = require('./spotterfish_library/utils/StripeUtils')

function runTests (nodeEnv) {
  // TODO: Disabled, see comment below.
  // const prevNodeEnv = process.env.NODE_ENV
  // process.env.NODE_ENV = nodeEnv


  // IMPORTANT: First test primites, then test features built ontop of the primites.

  SpotterfishCore.runUnitTests()
  SpotterfishHelpers.runUnitTests()
  UserFeatureBits.runUnitTests()
  UserJournal.runUnitTests()
  UserJournalProducts.runUnitTests()

  BusinessLogic.runUnitTests()
  ClientConfig.runUnitTests()
  Crypto.runUnitTests()
  DomainUtils.runUnitTests()
  EraTests.runUnitTests()
  PathUtils.runUnitTests()
  Room.runUnitTests()
  // ScreeningRoomSession.runUnitTests()
  TableFormatting.runUnitTests()
  User.runUnitTests()

  VideoPlayerUtils.runUnitTests()
  Timecode.runUnitTests()
  ClockValue.runUnitTests()
  SyncData.runUnitTests()
  MarkerUtils.runUnitTests()
  MIDIUtils.runUnitTests()

  // MasterClock.runUnitTests()
  MMC.runUnitTests()
  MTC.runUnitTests()
  CalcDeltaSecondsFromUIString.runUnitTests()
  VueUtils.runUnitTest()
  StripeUtils.runUnitTests()
  // TODO: Disabled, see comment below.
  // process.env.NODE_ENV = prevNodeEnv
}

// Check whether we're running in a browser.
if (typeof window === 'undefined') {
  // TODO: We used to save the current process.env.NODE_ENV string, run the tests, then
  // change it back. If we want this back, it needs to be implemented in another way
  // since webpack has the expression "process.env.NODE_ENV" replaced by a string before
  // sending it to the browser. Meaning this:
  //     process.env.NODE_ENV = 'development'
  // gets turned into this:
  //     'development' = 'development'
  // which is an error.

  // runTests('testing')
  runTests('development')
  // runTests('production')
}

module.exports = {
  runTests
}
