/*

TERMINOLOGY

- Spotterfish connection
- Screening room session


SPOTTERFISH API CONTRACT

1. Functions are blocking and return normal values OR return Promises.
   If a function returns a Promise, its name is MyFunctionPromise().
   RED and BLUE functions.

2. Errors in the API throw JavaScript Error objects.
   We guarantee that the `name` field of the Error object is dependable.
   Errors describe API-level concepts.
   TODO: Explicit list of error strings used by this API.

3. Functions use strong exception-safety guarantee or better.

4. Functions are free from user interface assumptions (can be used in terminal app etc.)
   No dependencies on UI libraries, DOM etc.

6. Functions are composable.

7. Avoid callbacks.

8. NO global non-const state is used in API.
   You CAN instantiate SEVERAL API connection.
   Login is not global.

9. You can run 0...many active Spotterfish connections and 0...many screening room sessions.


FUTURE

- Design by contract (asserts)
- Console / logging
- Tests

*/

export default {
  open_spotterfish_connection (firebase) {
    return {
      firebase: firebase
    }
  },

  user_create (spotterfishConnection, userObject) {
    return new Promise(async (resolve, reject) => {
      try {
        const obj = await spotterfishConnection.firebase.auth().createUserWithEmailAndPassword(userObject.email, userObject.password)
        console.log(obj.user)
        resolve(obj.user.uid)
      } catch (error) {
        reject(error)
      }
    })
  }
}
