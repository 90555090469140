var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-wrapper",class:[
  _vm.useRect === true ? 'rectangle': 'circle',
  _vm.talking ? 'talking': ''
]},[_c('video',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.activeInSession &&
      !_vm.inAVSettings &&
      !_vm.videoMuted &&
      _vm.videoStream
    ),expression:"\n      activeInSession &&\n      !inAVSettings &&\n      !videoMuted &&\n      videoStream\n    "}],ref:"videoElements",staticClass:"video-player",class:{
      'is-self': _vm.isMe
    },style:({ minHeight: _vm.minHeight ? _vm.minHeight : 0 }),attrs:{"playsinline":"","autoplay":""},domProps:{"muted":_vm.isMe,"srcObject":_vm.videoStream}}),(_vm.isDAWStreamingActive(_vm.user?.user_id))?_c('div',{staticClass:"daw-stream-text-container"},[_c('p',{staticClass:"daw-stream-text"},[_vm._v(_vm._s(_vm.getDAWStreamTimeCodeDisplay(_vm.user?.user_id)))])]):_vm._e(),_c('div',{staticClass:"icon-parent-container"},[(_vm.activeInSession && _vm.chairHasUser && _vm.audioMuted)?_c('div',{staticClass:"icon-container"},[_c('v-icon',{attrs:{"size":"medium"}},[_vm._v("mdi-microphone-off")])],1):_vm._e(),(_vm.activeInSession && _vm.chairHasUser && _vm.userIsScreensharing(_vm.getUser(_vm.user?.user_id)))?_c('div',{staticClass:"icon-container screenshare-icon",on:{"click":function($event){_vm.handleScreenSharing(_vm.getUser(_vm.user?.user_id))}}},[_c('v-icon',{attrs:{"size":"medium"},on:{"click":function($event){_vm.handleScreenSharing(_vm.getUser(_vm.user?.user_id))}}},[_vm._v("mdi-monitor")])],1):_vm._e(),(_vm.isDAWStreamingActive(_vm.user?.user_id))?_c('div',{staticClass:"icon-container dawstream-icon"},[_c('i',{staticClass:"material-icons daw-stream-icon"},[_vm._v(_vm._s(_vm.getDAWStreamIcon(_vm.user?.user_id)))])]):_vm._e()]),(_vm.activeInSession && _vm.videoMuted && _vm.user?.user_id && _vm.getUserAvatar(_vm.user?.user_id))?_c('div',{staticClass:"user-image",style:({
        backgroundImage: !_vm.user?.user_id ? '' : (_vm.isUserId(_vm.user?.user_id) ? 'url(' + _vm.getUserAvatar(_vm.user?.user_id) + ')' : ''),
       })}):_vm._e(),(_vm.activeInSession && _vm.videoMuted && _vm.user?.user_id && _vm.getUserAvatar(_vm.user?.user_id) === false)?_c('div',{staticClass:"video-muted-initials"},[_c('svg',{attrs:{"viewBox":"0 0 140 100"}},[_c('text',{attrs:{"x":"50%","y":"50%","dominant-baseline":"middle","text-anchor":"middle","fill":"white"}},[_vm._v(" "+_vm._s(_vm.getUserInitials(_vm.user))+" ")])])]):_vm._e(),(!_vm.activeInSession)?_c('div',{staticClass:"video-muted-initials"},[_c('img',{staticStyle:{"width":"100%","margin":"auto"},attrs:{"src":_vm.logos.invitedImage,"alt":""}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }