<template>
  <div class="video-wrapper" :class="[
    useRect === true ? 'rectangle': 'circle',
    talking ? 'talking': ''
  ]">
    <video
      ref="videoElements"
      class="video-player"
      :class="{
        'is-self': isMe
      }"
      :style="{ minHeight: minHeight ? minHeight : 0 }"
      playsinline
      autoplay
      v-show="
        activeInSession &&
        !inAVSettings &&
        !videoMuted &&
        videoStream
      "
      :muted="isMe"
      :src-object.prop.camel="videoStream"
    />
    <div class="daw-stream-text-container" v-if="isDAWStreamingActive(user?.user_id)">
      <p class="daw-stream-text">{{ getDAWStreamTimeCodeDisplay(user?.user_id) }}</p>
    </div>

    <!-- Parent div for icon containers -->
    <div class="icon-parent-container">
      <!-- Place muted microphone icon on top of img/video when user is muted -->
      <div class="icon-container" v-if="activeInSession && chairHasUser && audioMuted">
        <v-icon size="medium">mdi-microphone-off</v-icon>
      </div>

      <!-- Place screenshare icon on top of img/video when user is screensharing -->
      <div class="icon-container screenshare-icon" v-if="activeInSession && chairHasUser && userIsScreensharing(getUser(user?.user_id))" 
      @click="handleScreenSharing(getUser(user?.user_id))">
        <!-- <v-icon size="medium" v-if="getUser(user?.user_id).isMe">mdi-monitor</v-icon> -->
        <v-icon size="medium" @click="handleScreenSharing(getUser(user?.user_id))">mdi-monitor</v-icon>
      </div>
      
      <div class="icon-container dawstream-icon" v-if="isDAWStreamingActive(user?.user_id)">
        <i class="material-icons daw-stream-icon">{{ getDAWStreamIcon(user?.user_id) }}</i>
      </div>
    </div>

    <!-- If user's video is muted and user has a profile img, return profile image -->
    <div v-if="activeInSession && videoMuted && user?.user_id && getUserAvatar(user?.user_id)" class="user-image" 
         :style="{
          backgroundImage: !user?.user_id ? '' : (isUserId(user?.user_id) ? 'url(' + getUserAvatar(user?.user_id) + ')' : ''),
         }">
    </div>

    <!-- Initials as placeholder if user video is muted and has no profile image  -->
    <div v-if="activeInSession && videoMuted && user?.user_id && getUserAvatar(user?.user_id) === false" class="video-muted-initials">
      <svg viewBox="0 0 140 100">
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">
          {{getUserInitials(user)}}
        </text>
      </svg>
    </div>

    <!-- Reserved seat -->
    <div v-if="!activeInSession" class="video-muted-initials">
      <img :src="logos.invitedImage" alt="" style="width: 100%; margin: auto;">
    </div>
  </div>
</template>

<script>
//@ts-nocheck
import Constants from '@/../source_files/spotterfish_library/consts'
import { ref, inject, onMounted } from 'vue' 

const logos = require('@/lib/ui/logos.js').logos
const isWatchingScreenshare = ref(false)
const screenshareWindow = ref(undefined)

export default {
  setup() {
    const dawStreamUpdates = inject('dawStreamUpdates', undefined)
    const UIUsermap = inject('UIUsermap', undefined)
    const templateReactiveData = inject('templateReactiveData', undefined)
    
    const isUserId = (value) => typeof value === 'string' && !isEmail(value)
    const isEmail = (value) => Constants.EMAIL_REGEX.test(value)
    
    const getUser = (userId) => UIUsermap?.value[userId] || {}
    
    const getUserAvatar = (userId) => {
      const user = UIUsermap?.value[userId]
      if (user?.pict) return user.pict
      if (isEmail(userId)) return logos.invitedImage
      return false
    }
    
    const getUserInitials = (user) => {
      if (user?.user_name) {
        return user.user_name.split(' ').map(part => part[0]).join('')
      }
    }
    
    const userIsScreensharing = (user) => Boolean(user?.streams?.screenshare)
    
    const handleScreenSharing = async (user) => {
      // if (user?.isMe) return
      if (!isWatchingScreenshare.value) {
        screenshareWindow.value?.close()
        const newWindow = window.open("/present.html", "_blank", "left=100,top=100,width=640,height=360", "titlebar=no,toolbar=no,location=no,menubar=no,status=no")
        if (!newWindow) {
          alert('Popup blocked, please allow popups for this site')
        } else {
          newWindow.onload = () => {
            newWindow.document.title = `${user.user_name} presenting`
            const videoElem = document.createElement("video")
            videoElem.style.width = '100%'
            videoElem.style.height = '100%'
            videoElem.style.objectFit = 'contain'
            videoElem.autoplay = true
            videoElem.muted = true
            const remoteVideo = newWindow.document.body.appendChild(videoElem)
            remoteVideo.srcObject = user.streams.screenshare
            newWindow.document.body.style.backgroundColor = '#000'
            newWindow.document.body.style.margin = '0'
          }
          screenshareWindow.value = newWindow 
        }
      } else {
        screenshareWindow.value.focus()
      }
      isWatchingScreenshare.value = !isWatchingScreenshare.value
    }
    
    const isDAWStreamingActive = (userId) => templateReactiveData?.value.ui.selectedStreamer === userId
    const getDAWStreamIcon = (userId) => {
      const dawStreamUpdate = dawStreamUpdates?.value[userId]
      if (!dawStreamUpdate) return ''
      return dawStreamUpdate.html_output_DAWplaying ? 'smart_display' : 'pause_presentation'
    }

    const getDAWStreamTimeCodeDisplay = (userId) => {
      return dawStreamUpdates?.value[userId] ? dawStreamUpdates.value[userId].html_output_receivedTimeCodeDisplayValue : '--:--:--:--  @0fps';
    }
    
    const syncUserMediaOut = async () => {
      try {
        const selectedAudioOut = localStorage.getItem('spotterfishUserAudioOut') || 'default'
        const mediaElements = document.querySelectorAll('audio, video')
        await Promise.all([...mediaElements].map(element => element.setSinkId(selectedAudioOut)))
      } catch (error) {
        console.log(error)
      }
    }
    
    onMounted(() => {
      syncUserMediaOut()
      const muteInterval = setInterval(() => {
        const isMe = document.getElementsByClassName('is-self')
        if (isMe.length > 0 && isMe[0].muted === false) {
          console.warn('Muting myself')
          isMe[0].muted = true
          clearInterval(muteInterval)
        } else if (isMe.length > 0 && isMe[0].muted === true) {
          console.warn('User is already muted')
          clearInterval(muteInterval)
        }
      }, 200)
    })
    
    return {
      isUserId,
      isEmail,
      UIUsermap,
      getUserAvatar,
      getUserInitials,
      logos,
      userIsScreensharing,
      getUser, 
      handleScreenSharing,
      isWatchingScreenshare,
      screenshareWindow,
      isDAWStreamingActive,
      getDAWStreamIcon,
      getDAWStreamTimeCodeDisplay,
      templateReactiveData,
      dawStreamUpdates
    }
  },
  props: [
    'user',
    'chairHasUser',
    'activeInSession',
    'talking',
    'videoStream',
    'audioMuted',
    'videoMuted',
    'isMe',
    'inAVSettings',
    'useRect',
    'minHeight',
    'userId'
  ]
}
</script>

<style scoped lang="scss">
@import '@/assets/global.scss';

.video-muted-initials {
  width: 100%;
  height: 100%;
  background: $color-mx-dark-overlay;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  line-height: 1;
}

.user-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #333;
}

.video-wrapper {
  &.rectangle {
    max-width: 600px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    background: #0e0e0e8d;
    border: 2px solid rgba(28, 27, 27, 0.185);
    margin: 0 auto;
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    video {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      &.is-self {
        transform: scaleX(-1);
      }
    }
  }

  .icon-parent-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 0.375rem;
    width: 100%;
    gap: 0.5rem;
    z-index: 10; /* Ensure icons are above the video */
  }

  .icon-container {
    background: rgba(32,33,36,.3);
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 50%;
  }

  .screenshare-icon {
    background: $color-mx-secondary
  }


  .dawstream-icon {
    background: $color-mx-dawstreamer-button
  }

  .daw-stream-text {
    font-size: 0.7em;
    padding: 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .daw-stream-text-container {
    position: absolute; 
    top: 0px; 
    width: 100%;
    background: $color-mx-dawstreamer-button; 
    opacity: 0.75;
    max-height: 1.4em;
    border-radius: 4px 4px 0 0;
  }
  .talking-indicator {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &.talking {
    border: 2px solid white;
  }
}

.daw-stream-icon {
  font-size: 1rem;
}
</style>
