const SpotterfishCore = require('../SpotterfishCore')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const SpotterfishHelpers = require('../SpotterfishHelpers')

function domainMatches (email, domain) {
  console.log(`Checking if email ${email} matches domain ${domain}`)

  assert(SpotterfishHelpers.isValidEmail(email))
  assert(SpotterfishCore.isStringInstance(domain))

  if (domain.length === 0) {
    throw new Error('Domain is empty')
  }

  const emailParts = email.split('@')
  const emailDomain = emailParts[1]

  if (emailDomain.toLowerCase() === domain.toLowerCase()) {
    return true
  }

  return false
}


function prove_domainMatches () {
  // Throw error when email is empty.
  SpotterfishCore.unitTestAssertThrowsError(domainMatches, ['', 'example.com'])

  // Throw error when domain is empty.
  SpotterfishCore.unitTestAssertThrowsError(domainMatches, ['example@example.com', ''])

  // Throw error when email is missing at-sign.
  SpotterfishCore.unitTestAssertThrowsError(domainMatches, ['example', 'example.com'])

  // Throw error when email has multiple at-signs.
  SpotterfishCore.unitTestAssertThrowsError(domainMatches, ['example@example@example.com', 'example.com'])

  // Return true when domain matches.
  unitTestAssert(domainMatches('example@example.com', 'example.com') === true)

  // Return true when domain matches with mixed case input.
  unitTestAssert(domainMatches('example@eXamPle.com', 'exAmple.com') === true)

  // Return false when domain does not match.
  unitTestAssert(domainMatches('example@example.com', 'example2.com') === false)
}

function runUnitTests () {
  console.log('DomainUtils.js -- START')

  prove_domainMatches()

  console.log('DomainUtils.js -- END')
}

module.exports = {
  domainMatches,
  runUnitTests
}
