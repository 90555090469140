<!--
/*
  STATUS INDICATORS

  1) A buffering bullet on each video chat. getBufferedPercentage() -> shared_state -> videochat component
  2) Buffer box over video players: "Buffering 30%". showBuffering() -> videoplayer component
  3) Videojs internal spinner (same screen area as (2) ). Handled by videojs, sometimes gets stuck.
  4) Making popcorn animation. Shown by screeningroom.vue "Making popcorn". When setting/changing video file.
*/
-->

<template>
  <v-app ref="audiopostr" id="audiopostr" dark style="height: 100vh">
    <transition name="fade">
      <div
        v-if="templateReactiveData.UI_showModeratorPanelToggleButton"
        v-show="uiStates.onHover || !templateReactiveData.lightsOff"
        @click="uiStates.moderatorPanel = !uiStates.moderatorPanel"
        class="toggle-moderator-button"
      >
        Projects
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="
          (templateReactiveData.markerListEnabled && uiStates.markerLaneID) ||
          (templateReactiveData.isUserModerator && uiStates.markerLaneID)
        "
        v-show="uiStates.onHover || !templateReactiveData.lightsOff"
        @click="onToggleMakerList()"
        class="toggle-markerlist-button"
      >
        Markers
      </div>
    </transition>

    <v-navigation-drawer
      close-on-click="false"
      disable-resize-watcher
      app
      width="300"
      v-model="uiStates.moderatorPanel"
      absolute
      background
      left
      class="dark-panel shadow"
      style="z-index: 201; box-shadow: 0px 5px 50px 1px black"
      v-if="templateReactiveData.UI_enableModeratorPanel"
    >
      <!-- top tabs -->
      <v-container style="padding: 0">
        <v-tabs
          v-model="uiStates.currentModeratorTab"
          bg-color="panel"
          color="white"
          slider-color="secondary"
          height="40px"
        >
          <v-tab style="width: 50%" class="tabtitle" value="projects">
            <v-icon size="1.8em" left>mdi-folder-play-outline</v-icon>
            Projects
          </v-tab>

          <v-tab style="width: 50%" class="tabtitle" value="rooms">
            <v-icon size="1.8em" left>settings</v-icon>
            Settings
          </v-tab>
        </v-tabs>

        <v-row
          no-gutters
          justify="start"
          style="height: 100%"
          class="dark-panel left-panel"
        >
          <!-- Main Content Column -->
          <v-col cols="12" style="padding: 0" no-gutters>
            <v-tabs-items v-model="uiStates.currentModeratorTab">
              <v-tab-item
                eager
                style="height: 100%; min-height: 0px"
                class="dark-panel"
              >
                <div style="height: calc(100vh - 40px); width: 100%">
                  <projectlist-component
                    v-if="loggedInUserID && moderatorsList"
                    @close-projects="uiStates.moderatorPanel = false"
                    @project-clicked="onRequestProject($event)"
                    @project-edit-clicked="onProjectEditRequest($event)"
                    @on-new-project-uploaded="
                      onProjectEditRequest($event, true)
                    "
                    @request-reset-video-file="resetVideoFile()"
                    @request-toggle-ingest-live-stream="toggleIngestLiveStream()"
                    @request-open-dawstreamer-panel="openDawStreamerPanel()"
                    @request-load-in-mixstage="loadProjectById($event)"
                    :userUID="loggedInUserID"
                    :selected="uiStates.html_output_selectedProjectKey"
                    :moderators="moderatorsList"
                    :templateReactiveData="templateReactiveData"
                  >
                  </projectlist-component>
                </div>
                <!-- <div style="height: 35px; position: absolute; bottom: 0; width: 100%; background: green; display: flex; justify-content: center; align-items: flex-end; flex-direction: row;">
                  <v-btn
                      dark
                      x-small
                      color="gray"
                      @click="moderatorPanel = false"
                    >
                    Hide Projects
                    <v-icon dark>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </div>
                ' -->
              </v-tab-item>
              <v-tab-item eager style="height: 100%" class="dark-panel">
                <div
                  style="
                    height: calc(94vh - 35px);
                    width: 100%;
                    padding: 0 1.5rem 0 1rem;
                  "
                  class="dark-panel"
                >
                  <p style="padding-top: 4rem">
                    <strong>Access Control</strong>
                  </p>
                  <p class="text-left">
                    When an item is checked it is visible to all users,
                    otherwise it is only visible to moderators.
                  </p>
                  <v-checkbox
                    v-model="templateReactiveData.ui.transportFeatureEnabled"
                    @change="onEnableTransportFeature"
                    label="Show transport controls"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="templateReactiveData.ui.markerListFeatureEnabled"
                    @change="onEnableMarkerListFeature"
                    label="Show marker list"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <!-- Close Button Column -->
          <v-col cols="1" class="close-moderator">
            <v-btn
              fab
              dark
              x-small
              color="gray"
              :style="{ top: '48%', transform: 'translate(14%, -50%)' }"
              @click="uiStates.moderatorPanel = false"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
            <!-- <v-btn icon small style="width: 100%; height: 100%; border-radius: 0 !important;" @click="moderatorPanel = false">
              <v-icon size="3em" style="position: absolute; top: 50%; transform: translateY(-50%);">mdi-chevron-left</v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <!-- MARKER NAV DRAWER-->
    <v-navigation-drawer
      close-on-click="false"
      disable-resize-watcher
      app
      style="box-shadow: 0px 5px 50px 1px black; overflow: hidden; z-index: 3"
      width="390"
      v-model="uiStates.markerList"
      absolute
      background
      right
      class="panel shadow"
    >
      <v-container style="padding: 0">
        <v-row
          no-gutters
          justify="start"
          style="height: 100%"
          class="dark-panel right-panel"
        >
          <!-- Close Button Column -->
          <v-col cols="1" class="close-markerlist">
            <v-btn
              fab
              dark
              x-small
              color="gray"
              :style="{
                top: '48%',
                transform: 'translate(-17px, -49%)',
                zIndex: 1002,
              }"
              @click="onMarkerPanelClosed()"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
          <!-- Main Content Column -->
          <v-col cols="12" style="padding: 0">
            <div
              style="height: calc(97vh - 35px); width: 100%"
              class="dark-panel"
            >
              <markeredit-component
                ref="markerList"
                v-if="nonReactiveState.versionDBCopy && uiStates.markerLaneID && nonReactiveState.projectDBCopy"
                @close-markers="uiStates.markerList = false"
                @new-marker-session-clicked="endReview()"
                @marker-lane-selected="setProjectMarkerLane($event)"
                @requested-position-change="onMarklistRepositionRequest($event)"
                @request-screenshot="captureScreenshot($event)"
                @marker-selected-in-list="onRequestHighlightMarker($event)"
                @request-reset-marker="onRequestResetMarker($event)"
                @request-reposition-marker="
                  onResetMarkerPositionToPlayPosition($event)
                "
                @request-delete-marker="onRequestDeleteMarker($event)"
                :spotterfishParams="{ deleteMarkerLane: deleteMarkerLane }"
                :updateprop_clock="clockCopy"
                :projectDBID="nonReactiveState.projectDBCopy['.key']"
                :versionDBCopy="nonReactiveState.versionDBCopy"
                :connectedUsers="getConnectedUsers"
                :availableLanes="uiStates.availableMarkerLanes"
                :markers="uiStates.markers"
                :userUID="loggedInUserID"
                :markerLane="uiStates.markerLaneID"
                :isModerator="templateReactiveData.isUserModerator"
                :templateReactiveData="templateReactiveData"
              >
              </markeredit-component>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <v-main
      :class="{
        'lights-off': templateReactiveData.lightsOff,
        'lights-on': !templateReactiveData.lightsOff,
      }"
    >

        <!-- Button with conditional class for fading effect -->
        <button
          class="exit-button tooltip-trigger" data-tooltip="Exit MixStage session"
          :class="[
            {'fade-transition': true, 'fade-transition-show': uiStates.onHover || (!templateReactiveData.lightsOff && templateReactiveData.UI_showExitButton)}
          ]"
          @click.stop="uiStates.exitDialog = true"
        >
          <i class="material-icons">chevron_left</i>
          Exit
        </button>        

      <div
        class="grid-wrapper"
        :style="{ 'background-color': this.colors.mx.black }"
      >
          <div
            v-if="UI_showAudienceInfoBanner"
            v-show="uiStates.onHover"
            class="audience-info-banner"
            :class="[
            {'fade-transition': true, 'fade-transition-show': UI_showAudienceInfoBanner}
          ]"
          >
            <span class="audience-info-moderators"
              >Moderators: {{ moderatorsInRoom() }}</span
            >
            <span class="audience-info-people-count"
              >Audience: {{ numAudienceMembersInRoom() }} member{{
                numAudienceMembersInRoom() === 1 ? '' : 's'
              }}
              {{ numAudienceMembersInRoom() === 1 ? 'is' : 'are' }} live</span
            >
          </div>

          <div
            v-show="uiStates.onHover || (!templateReactiveData.lightsOff && templateReactiveData.UI_showExitButton)"
            :class="[
              'lights-on-banner',
              {'fade-transition': true, 'fade-transition-show': uiStates.onHover || (!templateReactiveData.lightsOff && templateReactiveData.UI_showExitButton)}
            ]"
          >
            <v-tabs
              v-model="uiStates.currentLightSwitchTab"
              v-show="templateReactiveData.UI_showVideoChatModeTabs"
              centered
              grow
              height="40px"
              color="white"
              slider-color="#A149FC"
              @change="onTabChanged($event)"
            >
              
                  <v-tab key="show"><!--class="tooltip-trigger" data-tooltip="Show video chat & video"-->
                    <i class="material-icons tab-icon">splitscreen</i>
                  </v-tab>

                  <v-tab key="hide"><!--class="tooltip-trigger" data-tooltip="Focus on video, video chat is hidden"-->
                    <i class="material-icons tab-icon">tv</i>
                  </v-tab>
               
                  <v-tab key="only"><!--class="tooltip-trigger" data-tooltip="Focus on video, video chat is hidden"-->
                    <i class="material-icons tab-icon">video_chat</i>
                  </v-tab>
              
            </v-tabs>
          </div>
          
        <div class="talking-banner" v-if="templateReactiveData.lightsOff">
          <div v-for="e in uiStates.talkingUsers" :key="e.user_id" class="talking-user">
            {{ e.user_name }} is talking
          </div>
        </div>
        <div
          v-if="templateReactiveData.lightsOff"
          class="buffering-banner"
          :class="[
            templateReactiveData.isUserModerator &&
            templateReactiveData.ui.otherUserBuffering
              ? 'visible'
              : 'hidden',
          ]"
          :style="{ background: colors.tooltipBackground }"
        >
          {{ templateReactiveData.ui.otherUserBufferingMessage }}
        </div>
        <transition name="fade">
          <userControls
            style="z-index: 2"
            v-if="isSeated() && stateInitialized"
            v-show="uiStates.onHover || !templateReactiveData.lightsOff"
            :openBathroomMirror="openBathroomMirror"
            :handleTabKeyDown="handleTabKeyDown"
            :handleTabKeyUp="handleTabKeyUp"
            :tabIsPressed="uiStates.tabIsPressed"
            :customLogo="customLogo"
            :isScreensharing="uiStates.isScreensharing"
            :numberOfPeopleInLobby="numberOfPeopleInLobby"
            @chat-active="toggleChat()"
            @fullscreen-toggle="toggleFullscreen()"
            @keyboard-shortcuts-button-clicked="
              $refs.keyboardshortcuts.open(keyboardShortcutsList)
            "
            @screenshare-toggle="toggleScreenshare()"
            @request-mute-all="muteEveryone()"
            @request-copy-short-link="copyRoomLink()"
            @request-show-hosting-panel="
              ;(uiStates.hostingPanel = true),
                (uiStates.moderatorPanel = false),
                (uiStates.markerList = false)
            "
          />
        </transition>
        <!-- sfVideoChat -->

        <!--
          #reactive problem
        -->
        <div :class="templateReactiveData?.videochatOnly ? 'video-chat-only' : 'video-chat-row'">
          <transition name="fade">
            <videochat-component
              v-if="isSeated() && stateInitialized && usersExists"
              ref="sfVideoChat"
              :spotterfishParams="{
                onRequestUserMute,
                onDAWStreamPackage,
                handleScreenshareEnded,
                handleLocalScreenshareStarted,
                handleDawStreamerEnded
              }"
              :tabIsPressed="uiStates.tabIsPressed"
              @request-open-people-panel="openPeoplePanel($event)"
              @bathroom-mirror-status="uiStates.bathroomMirrorStatus = $event"
              @data-channel-message-received="
                handleDataChannelMessageReceived($event)
              "
              v-show="uiStates.onHover || !templateReactiveData.lightsOff"
            />
          </transition>
        </div>

        <!-- sfVideoPlayer -->
        <!--
          #reactive problems
          constprop_dawStreamCapabilitiesEnabled
          updateprop_isModerator
        -->

        <div
          :class="templateReactiveData?.videochatOnly ? 'video-player-hidden' : 'video-player-row' "
          id="videoPlayerRow"
          @click="handleVideoPlayerRowClick"
          style="text-align: center; position: relative"
          v-if="!uiStates.videoChatFocusMode"
        >
          <transition name="fade">
            <div
              class="noSelect"
              :class="
                templateReactiveData.lightsOff
                  ? 'project-name-full-screen'
                  : 'project-name'
              "
              v-show="uiStates.onHover && !templateReactiveData.whipStreamActive"
            >
              <h2>{{ templateReactiveData.projectName }}</h2>
              <!-- <p>START: {{ videoFileStartSMPTE }}</p> -->
            </div>
          </transition>
          <sf-video-player
            v-show="!templateReactiveData.whipStreamActive"
            id="sfVideoPlayer"
            ref="sfVideoPlayer"
            :spotterfishParams="{
              setSeekIndicator: videPlayer_setSeekIndicator,
              setBufferingFlag: videoPlayer_setBufferingFlag,
              setCanpLayThroughFlag: videoPlayer_setCanpLayThroughFlag,
              onVideoplayerPlayFlagChange: videoPlayer_onPlayFlagChange,
              onVideoPlayerTimeUpdate: videoPlayer_onTimeUpdate,
            }"
            :syncSource="output_syncSource()"
            :templateReactiveData="templateReactiveData"
            :constprop_dawStreamCapabilitiesEnabled="
              templateReactiveData.dawStreamCapabilitiesEnabled
            "
            :constprop_backgroundColor="colors.mx.black"
            :updateprop_isModerator="templateReactiveData.isUserModerator"
            :updateprop_videoPlayerVolume="getVideoPlayerVolume"
            @file-error="videoPlayer_onFileError()"
          >
          </sf-video-player>
          <whip-stream-player
            ref="whipStreamPlayer"
            :posterImage="logos.logoPosterImageWhipPlayerWhite"
            :muted="!templateReactiveData.whipStreamActive"
            v-show="templateReactiveData.whipStreamActive">
          </whip-stream-player>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="uiStates.screeningRoomSessionNonReactiveExists"
          @mouseenter="uiStates.hoveringTransport = true"
          @mouseleave="uiStates.hoveringTransport = false"
        >
          <message-component
            v-if="uiStates.screeningRoomSessionNonReactiveExists"
            v-show="uiStates.chatActive"
            @request-close-chat="toggleChat()"
            :screeningRoomSession="nonReactiveState.screeningRoomSessionNonReactive"
          >
          </message-component>
        </div>
      </transition>

      <!-- transport -->

      <div
        @mouseenter="uiStates.hoveringTransport = true"
        @mouseleave="uiStates.hoveringTransport = false"
        ref="transportWindow"
      >
        <transition name="fade">
          <transport-component
            v-if="stateInitialized"
            class="transport-position"
            ref="transport"

            :markers="uiStates.markers"
            :onHover="uiStates.onHover"

            @lightSwitch-status="onLightswitchToggled($event)"
            @request-get-active-daw-streamers="listenfForActiveStreamers()"
            @requested-play-state="onTransportRequestPlayFlag($event)"
            @requested-position-change="
              onTransportComponentRepositionRequest($event)
            "
            @request-drop-marker="onRequestDropMarker($event)"
            @request-save-marker="onRequestSaveMarker($event)"
            @request-update-marker-category="
              onRequestSaveMarkerCategory($event)
            "
            @request-reposition-marker="
              onResetMarkerPositionToPlayPosition($event)
            "
            @request-highlight-marker="onRequestHighlightMarker($event)"
            @request-delete-marker="onRequestDeleteMarker($event)"
            @request-toggle-daw-streamer-mute="onRequestToggleDawStreamerMute($event)"
          >
          </transport-component>
        </transition>
      </div>
      <v-overlay
        attach="#videoPlayerRow"
        :value="templateReactiveData.makingPopcornMessage !== undefined"
        persistent
        width="300"
        elevation="0"
        v-if="!uiStates.bathroomMirrorStatus"
        color="#080808"
        :opacity="uiStates.screeningRoomSessionNonReactiveExists ? 0.65 : 0.99"
      >
        <v-row align="center" justify="center">
          <mixstage-spinner
            :style="{ 'background-color': 'transparent' }"
            :showSpinner="
              templateReactiveData.makingPopcornMessage !== undefined
            "
            :loadingMessage="templateReactiveData.makingPopcornMessage"
          ></mixstage-spinner>
        </v-row>
      </v-overlay>

      <v-snackbar
        v-if="uiStates.slowLinkMessage"
        width="100"
        v-model="uiStates.slowLinkMessage"
        bottom
        style="margin-top: 10%"
        :color="colors.darkTerracotta || 'red'"
      >
        <div style="width: 100%; text-align: center">
          Streamer has bandwidth issues
        </div>
      </v-snackbar>

      <v-dialog v-model="showSafari" v-if="showSafari" max-width="400">
        <v-card>
          <v-card-title>Safari is not fully supported.</v-card-title>
          <v-card-text v-if="!isMobile" style="text-align: left">
            You need to enable auto play for this page. Please: <br />
            <p style="margin-top: 10px">
              Click on the "Safari" menu -> Settings for this website -> Allow
              all auto play
            </p>
            <p v-if="isMobile" style="margin-top: 10px">
              If you are using an iPhone or iPad, you need to click the video
              window once to unmute audio
            </p>
            <v-checkbox
              :color="colors.mainAccent"
              v-model="optOutOfWarning"
              label="Do not show me again"
            ></v-checkbox>
            <v-btn
              :color="colors.mx.secondary"
              @click="uiStates.dismissSafariWarning = true"
              >Close</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- projectEdit dialog -->

      <project-edit-dialog
        ref="projectEdit"
        @request-load-in-mixstage="loadProjectById($event)"
      />

      <!-- sfHostingPanel -->

      <v-dialog
        v-model="uiStates.hostingPanel"
        :overlay-color="colors.mx.black"
        overlay-opacity="0.92"
        max-width="1000"
      >
        <hosting-component
          v-if="stateInitialized && uiStates.hostingPanel"
          @close-hosting-clicked="uiStates.hostingPanel = false"
          ref="sfHostingPanel"
          :templateReactiveData="templateReactiveData"
          :allowedDomains="allowedDomains"
          :requiresEmailVerification="requiresEmailVerification"
          :isAudienceRoom="templateReactiveData.isAudienceRoom"
          :requestedSelectedChair="uiStates.requestedSelectedChair"
          @currently-changing-people="updatingPeople = $event"
          @request-copy-short-link="copyRoomLink()"
        >
        </hosting-component>
      </v-dialog>

      <v-dialog v-model="uiStates.exitDialog" max-width="400">
        <v-card>
          <v-toolbar dark style="max-height: 54px">
            <v-spacer></v-spacer>
            <v-toolbar-title>Exit MixStage Session? </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title></v-card-title>
          <v-card-text style="text-align: left">
            Are you sure you want to exit the screening room?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :color="colors.mx.light" @click="uiStates.exitDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :color="colors.mx.secondary"
              @click="onExitScreeningroomClicked()"
              >Exit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <projectconfirm-component
        ref="projectconfirm"
        v-if="uiStates.message"
      ></projectconfirm-component>
      <keyboardshortcuts-component
        ref="keyboardshortcuts"
        @faq-button-clicked="
          thisWindow.open('https://support.mixstage.io', '_blank')
        "
      ></keyboardshortcuts-component>
    </v-main>
  </v-app>
</template>

<script>
// @ts-nocheck

/*
COMPONENT HIEARCHY

ScreeningRoom:
  sfTransport:
     seekSlider
     commentInput
     markerlane
  sfVideoPlayer
    localFileComponent
  sfVideochat
    bathroomMirror
  sfMessage
    emojiPicker
  sfMarkerEdit (rightside marker list pane)
  projectList (the part of moderator panel, the rest of mod panel is inline in screeningroom)
  hosting
  projectconfirm
  keyboardShortcuts
*/

function getInitClock () {
  return {  
    position: 0,
    playFlag: false,
    playbackTransport: {
      playFlag: false,
      pos: 0
    },
    mediaSyncSource: 'timing_provider',
    latencyHiddenFlag_pos: false,
    latencyHiddenFlag_playFlag: false,
    videoFileFrameRateKey: 'PAL',
    videoFileLengthOpt: 3600 * 12,
    videoFileOffset: 0,
    timecodeOffsetSeconds: 3600,
    localTransport: {
      playFlag: false,
      pos: 0
    },
    mcorpTransport: {
      playFlag: false,
      pos: 0
    },
    dawStreamerTransport: {
      pos: 0,
      playFlag: false
    }
  }
}

import _ from 'lodash'
import App from '@/App.vue'
import { computed, watch, ref, reactive, provide } from 'vue';
import store from '@/store'
import { mapGetters } from 'vuex'
import sfVideoPlayer from '@/components/videoplayercomponents/sfVideoPlayer.vue'
import whipStreamPlayer from '@/components/videoplayercomponents/whipStreamPlayer.vue'
import sfTransport from '@/components/videoplayercomponents/sfTransport.vue'
import sfVideochat from '@/components/webRTCcomponents/videoChat/sfVideochat.vue'
import sfMessage from '@/components/messagescomponents/sfMessage.vue'
import sfMarkerList from '@/components/commentscomponents/sfMarkerList.vue'
import projectList from '@/components/projectscomponents/projectList.vue'
import hosting from '@/components/userscomponents/hosting.vue'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import projectconfirm from '@/components/UI/projectConfirm.vue'
import keyboardShortcuts from '@/components/UI/keyboardShortcuts.vue'
import projectEditDialog from '@/components/versionscomponents/projectEdit.vue'

import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import CloudClient from '@/../source_files/web_client/CloudClient'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import JanusSession from '@/../source_files/web_client/JanusSession'
import WebClientImplementation from '@/../source_files/web_client/WebClientImplementation'

import Constants from '@/../source_files/spotterfish_library/consts'
import MasterClock, {
  onDAWStreamPackage,
} from '@/../source_files/spotterfish_library/MasterClock'
import * as Sentry from '@sentry/browser'
import SpotterfishCore from '@/../source_files/spotterfish_library/SpotterfishCore'
import VueUtils from '@/../source_files/spotterfish_library/utils/VueUtils'

import MarkerUtils from '@/../source_files/spotterfish_library/utils/MarkerUtils'

import { assert } from '@/../source_files/spotterfish_library/SpotterfishCore'

import DawStreamerSession from '@/../source_files/web_client/DawStreamerSession'
import { constants } from 'crypto'
import SpotterfishHelpers from '@/../source_files/spotterfish_library/SpotterfishHelpers'
import SharedStateTiming from '@/../source_files/web_client/SharedStateTiming'
import mixStageSpinner from '@/components/dashboard/mixStageSpinner.vue'
import { isSafari, isMobile } from 'mobile-device-detect'

import {
  analytics_log_started_collaboration,
  updateClock,
  calcIsPlayerOnlyFromQuery,
  isModerator0,
  calcTemplateReactiveData,
  loadVideoAtomic,
  doesFileExist,
  isFilePlayable,
  resetFileLoaded,
} from '@/components/screeningroom/screeningroomHelpers'
import userControls from '@/components/webRTCcomponents/videoChat/userControls.vue'

export default {
  name: 'screeningroom',

  components: {
    'transport-component': sfTransport,
    'sf-video-player': sfVideoPlayer,
    'whip-stream-player': whipStreamPlayer,
    'videochat-component': sfVideochat,
    'message-component': sfMessage,
    'markeredit-component': sfMarkerList,
    'projectlist-component': projectList,
    'hosting-component': hosting,
    'projectconfirm-component': projectconfirm,
    'keyboardshortcuts-component': keyboardShortcuts,
    'project-edit-dialog': projectEditDialog,
    userControls: userControls,
    'mixstage-spinner': mixStageSpinner,
  },
  setup() {
    // With the composition api, we can declare anything specificaaly as reactive using the `reactive` function
    // or ref using the `ref` function
    // Provide is used to provide properties to children components without prop drilling (use 'inject' in the child component to access the provided properties)
    const logos = require('@/lib/ui/logos.js').logos
    const colors = require('@/lib/ui/colors.js').colors
    const keyboardShortcutsList = Constants.KEYBOARD_SHORTCUT_LIST
    const animationFrameId = undefined
    // For Vue3, we can use the `ref` function to declare reactive properties, when declared with the same 
    // name as the ref="" in the template, vue connects them automatically
    const sfVideoChat = ref(undefined)
    const markerList = ref(undefined)
    const projectEdit = ref(undefined)
    const keyboardShortcuts = ref(undefined)
    const projectConfirm = ref(undefined)
    const hostingPanel = ref(undefined)


    // this trigger can be called from code / computed props whenever we need to trigger a non-reactive state change
    // Do not use unless in an intermittent state while refactoring
    // Non-reactive data should only be used in business logic, not UI
    const nonReactiveStateChangeTrigger = ref(0);
    function updateNonReactiveState(updates) {
      Object.assign(nonReactiveState, updates);
      // Increment the trigger to indicate a change
      nonReactiveStateChangeTrigger.value++;
    }

    // Non-reactive state
    const nonReactiveState = {
      onScreeningRoomDBChangedCount: 0,
      onScreeningRoomDBChangedDepth: 0,
      onSharedStateChangedDepth: 0,
      onSharedStateChangedCount: 0,
      fileStateXLChangeCount: 0,
      animationFrameCount: 0,
      versionDBCopy: undefined,
      projectDBCopy: undefined,
      roomFeatureBitsCopy: undefined,
      screeningRoomSessionNonReactive: undefined,
    };

    // Reactive state (replaces the data function in vue2)
    const templateReactiveData = ref(undefined);
    const uiStates = reactive({
      projectListener: () => {},
      currentLightSwitchTab: 'show',
      initiatedProjectSelectDBID: undefined,
      screeningRoomSessionNonReactiveExists: false,
      isPlayerOnlyMode: false,
      slowLinkMessage: false,
      timeout: 1000,
      chatActive: false,
      weEnabledChat: false,
      markerList: false,
      moderatorPanel: false,
      html_output_selectedProjectKey: undefined,
      hostingPanel: false,
      requestedSelectedChair: undefined,
      message: false,
      onHover: false,
      hoveringTransport: false,
      dismissSafariWarning: false,
      bathroomMirrorStatus: false,
      exitDialog: false,
      activeStreamers: [],
      selectedStreamer: undefined,
      markerLaneID: undefined,
      currentModeratorTab: 'projector',
      tabIsPressed: false,
      analytics: reactive({
        didLogStartedCollaboration: false,
        didLogCloseMarkerPanelClick: false,
        didLogChangeMarkerTitle: false,
      }),
      talkingUsers: [],
      isScreensharing: false,
      videoChatFocusMode: false,
      availableMarkerLanes: [],
      markers: [],
      highlightedMarker: undefined,
      passedInProjectLoaded: false,
      transportWindowSize: reactive({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    })


    const janusUserMap = ref({})

    const getConnectedUsers = computed(() => store.getters.getConnectedUsers)

    const loggedInUserID = computed(() => {
      return firebaseUserHandler.getLoggedInUserId()
    })

    // This is the central point for handling all UI user state changes.
    // This object is provided to all child components, and can be used to trigger UI state changes
    // without prop drilling. Inject happens immediately, and children can react without waiting for a prop change
    const UIUsermap = ref({})
    const usersExists = ref(false)
    const userStatus = (_UIUsermap, userId, myId) => {
      return {
        audioMuted: _UIUsermap.value[userId].shared.audio_muted,
        connected: _UIUsermap.value[userId].shared.active,
        hasAudioStream: Boolean(_UIUsermap.value[userId].streams.audioStream),
        hasDAWStream: Boolean(_UIUsermap.value[userId].streams.dawStream),
        hasScreenShareStream: Boolean(_UIUsermap.value[userId].streams.screenshare),
        hasVideoStream: Boolean(_UIUsermap.value[userId].streams.videoStream),
        inBathroomMirror: _UIUsermap.value[userId].shared.in_bathroom_mirror,
        isDawStreamer: _UIUsermap.value[userId].shared.daw_streamer,
        isDawStreamReady: _UIUsermap.value[userId].shared.daw_stream_ready,
        isMe: userId === myId,
        remote_feed_connected_trigger: _UIUsermap.value[userId].shared.remote_feed_connected_trigger,
        isTalking: _UIUsermap.value[userId].streams.isTalking,
        tabToTalk: _UIUsermap.value[userId].shared.tab_to_talk,
        userEmail: _UIUsermap.value[userId].shared.user_email,
        userName: _UIUsermap.value[userId].shared.user_name,
        videoChatReady: _UIUsermap.value[userId].shared.video_chat_ready,
        videoMuted: _UIUsermap.value[userId].shared.video_muted,
      }
    }

    const janusUserMapChangedTrigger = ref(0)

    const triggerUserMapUpdate = () => {
      janusUserMapChangedTrigger.value++
    }

    watch(
      [getConnectedUsers, templateReactiveData, janusUserMap, janusUserMapChangedTrigger], 
      () => {
        if (!getConnectedUsers.value || !templateReactiveData.value || !janusUserMap.value || !loggedInUserID.value) {
          console.log('data not available yet')
          return;
        }

        // Use a temporary map for construction
        const newUserMap = {};

        const allUserIds = new Set([
          ...getConnectedUsers.value?.map(u => u.user_id),
          ...Object.keys(templateReactiveData.value?.ui.users),
          ...Object.keys(janusUserMap.value)
        ]);

        allUserIds.forEach(userId => {
          const foundUser = getConnectedUsers.value.find(u => u.user_id === userId) || {};
          const sharedStateUser = templateReactiveData.value?.ui?.users[userId] || {};
          const streams = janusUserMap.value[userId] || {};

          // Build the user object in the new map
          newUserMap[userId] = {
            ...(UIUsermap.value[userId] || {}), // Spread existing data to maintain other properties
            ...foundUser,
            shared: sharedStateUser,
            streams,
            isMe: loggedInUserID.value === userId,
          };
        });

        // Replace the entire UIUsermap with the new map to ensure reactivity
        UIUsermap.value = newUserMap;

        usersExists.value = Object.keys(UIUsermap.value).length > 0;
      }, 
      { deep: true, immediate: true }
    )

    
    const getTalkingUsers = () => {
      const talkingJanusUsers = Object.entries(janusUserMap.value)
      .map(([k, v]) => ({ userId: k, isTalking: v.isTalking }))
      .filter((u) => u.isTalking)
      const talkingUsers = talkingJanusUsers.map((u) =>
      store.getters.getConnectedUsers.find((cu) => cu.user_id === u.userId)
      )
      uiStates.talkingUsers.value = talkingUsers
    }
    
    
    // Clock is assumed to be a reactive property that will be provided to child components
    const clockCopy = reactive(getInitClock())
    // Providing non-reactive and reactive state
    provide('UIUsermap', UIUsermap)
    // Children can inject these properties using the `inject` function without prop drilling
    provide('nonReactiveState', nonReactiveState)
    provide('templateReactiveData', templateReactiveData)
    provide('clock', clockCopy)
    // Used to trigger child components that relies on the same animationframeloop
    provide('getAnimationFrameCount', () => nonReactiveState.animationFrameCount)

    return {
      // Returning reactive states to be used in template

      templateReactiveData,
      uiStates,
      clockCopy,
      // Returning non-reactive data directly, as they won't trigger reactivity
      nonReactiveState,
      // Imported constants used directly in the template
      colors,
      logos,
      keyboardShortcutsList,

      // Refs
      sfVideoChat,
      markerList,
      projectEdit,
      keyboardShortcuts,
      projectConfirm,
      hostingPanel,
      animationFrameId,

      janusUserMap,
      // methods
      triggerUserMapUpdate,
      getTalkingUsers,

      loggedInUserID,
      getConnectedUsers,
      usersExists,
    };
  },

  
  computed: {
    stateInitialized() {
      return this.templateReactiveData && this.templateReactiveData.nonReactiveDataExists && this.templateReactiveData.reactiveDataExists
    },
    ...mapGetters([
      'getUsersInLobby',
      'getVideoPlayerVolume',
    ]),

    videoFileStartSMPTE() {
      if (this.clockCopy) {
        return Timecode.secondsToSMPTEString(
          this.clockCopy.videoFileOffset,
          this.clockCopy.videoFileFrameRateKey
        )
      } else {
        return undefined
      }
    },

    moderatorsList() {
      return this.templateReactiveData?.moderatorsList ? this.templateReactiveData.moderatorsList : []
    },

    allowedDomains() {
      return this.templateReactiveData?.allowedDomains ? this.templateReactiveData.allowedDomains : []
    },

    requiresEmailVerification() {
      if (this.templateReactiveData.roomFeatureBitsCopy) {
        return this.templateReactiveData.roomFeatureBitsCopy.requires_email_verification || false
      } else {
        return true
      }
    },

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::  COMPUTED UI PROPS ::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    isSafari() {
      return isSafari
    },

    isMobile() {
      return isMobile
    },

    showSafari() {
      if (
        !this.uiStates.dismissSafariWarning &&
        this.isSafari &&
        !this.optOutOfWarning
      ) {
        return true
      } else {
        return false
      }
    },

    optOutOfWarning: {
      get() {
        if (localStorage.getItem('optoutSafariWarning')) {
          if (localStorage.getItem('optoutSafariWarning') === 'true') {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      set(e) {
        localStorage.setItem('optoutSafariWarning', e)
      },
    },

    customLogo() {
      if (this.templateReactiveData?.customLogo) {
        return this.templateReactiveData?.customLogo
      } else {
        return undefined
      }
    },

    thisWindow() {
      return window
    },

    // Below are computed properties purely based on client config.

    UI_showLightsOnOffButton() {
      return (
        (this.templateReactiveData.isUserModerator ||
          this.getKeepAliveValue() ||
          this.templateReactiveData.noModerator) &&
        !this.templateReactiveData.isAudienceRoom
      )
    },

    UI_showAudienceInfoBanner() {
      return (
        this.templateReactiveData.isAudienceRoom &&
        this.templateReactiveData.isUserModerator
      )
    },
  },
  watch: {
    async getUsersInLobby(newUsers, oldUsers) {
      if (!newUsers || this.nonReactiveState.screeningRoomSessionNonReactive === undefined) {
        return null
      }

      const newUserLength = newUsers ? newUsers.length : 0
      const oldUserLength = oldUsers ? oldUsers.length : 0

      if (
        isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive) &&
        oldUserLength < newUserLength
      ) {
        const doorBell = new Audio(require('@/assets/doorbell.mp3'))
        doorBell.play()
      }
    }
  },

  methods: {
    unmuteSafari() {
      this.$refs.sfVideoPlayer.unmuteSafari()
    },

    async loadProjectById(projectId) {
      console.log('projectId, as returned to screeningRoom', projectId)
      // restart the project listener and find the projectDB from the passed in id
      const projectsArray = await this.$store.dispatch(
        'projects_get_available_with_realtime_listener',
        this.loggedInUserID
      )
      console.log('projectsArray, as returned to screeningRoom', projectsArray)

      const project = projectsArray.find((p) => p['.key'] === projectId)
      console.log(project)
      this.onRequestProject(project)
    },
    async copyRoomLink() {
      this.popcornMessage = 'copying, please wait'
      let params
      const baseUrl = window.location.origin + '/#/'
      try {
        let userName = 'MixStage User'

        if (!this.nonReactiveState.roomFeatureBitsCopy.video_chat_enabled) {
          const userObject = await CloudClient.call_CFgetUserObject(
            App.Firebase,
            this.loggedInUserID
          )
          userName = userObject.user_name
        }

        params = {
          screeningRoom:
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .screeningRoomDBCopy['.key'],
          email: '',
          invitedBy: this.loggedInUserID,
          baseUrl: baseUrl,
          playerOnly: this.isAudienceRoom,
          inviterUsername: userName,
          roomOwner:
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .screeningRoomDBCopy.owner,
        }
        console.log(params)

        SpotterfishSession.trackEvent(
          App.spotterfishSession,
          'copy_room_link_clicked',
          params
        )

        const url = ScreeningRoomSession.generateURLtoScreeningRoom(params)
        // In dev end we do not try to shorten links - bitly does not allow it.
        try {
          let shortenedUrl = await CloudClient.call_CFshortenUrl(
            App.Firebase,
            url
          )
          await navigator.clipboard.writeText(shortenedUrl)
        } catch (error) {
          console.log(error)
          await navigator.clipboard.writeText(url)
        }

        this.popcornMessage = undefined

        await this.$root.$globalAlert.open(
          `Room ${
            params.playerOnly ? 'quick ' : ''
          }link was copied to clipboard`,
          {
            line1: `${
              params.playerOnly
                ? 'Users will be able to enter without signing up using this link.'
                : 'Users will need to create an account to enter your room.'
            }`,
          },
          {
            color: this.colors.mainAccent,
          }
        )
      } catch (error) {
        this.popcornMessage = undefined

        console.error(error)
        Sentry.captureException(error, {
          user: {
            uid: this.loggedInUserID,
          },
          extra: {
            room_id:
              this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
                .screeningRoomDBCopy['.key'],
          },
        })
        await this.$root.$globalAlert.open(
          `ERROR copying ${params.playerOnly ? 'quick ' : 'room'}link`,
          {
            line1: `Something went wrong, please try copying the link again.`,
          },
          {
            color: this.colors.mx.panel,
          }
        )
      }
    },

    onTabChanged(tabIndex) {
      const tabMapping = ['show', 'hide', 'only']
      const selectedTabKey = tabMapping[tabIndex]

      if (selectedTabKey === 'show') {
        this.onLightswitchToggled(1)
      } else if (selectedTabKey === 'hide') {
        this.onLightswitchToggled(2)
      } else if (selectedTabKey === 'only') {
        console.log('should show video chats only')
        this.onLightswitchToggled(3)
      }
    },


    handleTabKeyDown() {
      if (!this.uiStates.tabIsPressed) {
        this.uiStates.tabIsPressed = true
      }
    },

    handleTabKeyUp() {
      if (this.uiStates.tabIsPressed) {
        this.uiStates.tabIsPressed = false
      }
    },

    moderatorsInRoom() {
      let usernames = []
      if (this.getRefinedSharedState() === undefined) {
        return
      }
      for (const [userId, user] of Object.entries(
        this.getRefinedSharedState().users
      )) {
        if (
          user.active &&
          user.user_id &&
          user.user_name &&
          user.user_email &&
          this.moderatorsList.includes(user.user_id)
        ) {
          usernames.push(user.user_name)
        }
      }

      return usernames.join(', ')
    },

    async openBathroomMirror() {
      this.$refs.sfVideoChat.openBathroomMirror()
    },

    numAudienceMembersInRoom() {
      let numAudienceMembers = 0
      if (this.getRefinedSharedState() === undefined) {
        return
      }
      for (const [userId, user] of Object.entries(
        this.getRefinedSharedState().users
      )) {
        // Audience members are anonymous = they have no email registered
        if (user.active && user.user_email === '') {
          numAudienceMembers++
        }
      }

      return numAudienceMembers
    },

    openPeoplePanel(chairIndex) {
      if (this.isModerator()) {
        this.uiStates.requestedSelectedChair = chairIndex

        // Hosting panel does not exist until opened
        // after that we set the seat index using a method
        this.uiStates.hostingPanel = true

        if (this.$refs.sfHostingPanel) {
          this.$refs.sfHostingPanel.setActiveChair(chairIndex)
        }

        // this.moderatorPanel = false
        // this.uiStates.markerList = false
      }
    },

    onToggleMakerList() {
      SpotterfishSession.trackEvent(
        App.spotterfishSession,
        'user_opened_maker_list',
        {}
      )
      this.uiStates.markerList = !this.uiStates.markerList
    },
    openDawStreamerPanel() {
      this.dawStreamerPanel = window.open(
        `${window.location.origin + '/#/'}dawstreamer`,
        'PROJECTOR ROOM',
        'left=100,top=100,width=640,height=660, titlebar=no, toolbar=no, location=no, menubar=no,status=no'
      )
      this.dawStreamerPanel.document.title = 'Mix Stage DAW Streamer'

      const timer = setInterval(() => {
        if (this.dawStreamerPanel.closed) {
          clearInterval(timer)
          // console.log('daw streamer window was closed')
        }
      }, 1000)
    },
    isSeated() {
      if (this.nonReactiveState.screeningRoomSessionNonReactive === undefined) {
        return false
      }
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      const userID = firebaseUserHandler.getLoggedInUserId()
      return this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy.people_seated.includes(
        userID
      )
    },
    hasModeratorKey() {
      if (this.nonReactiveState.screeningRoomSessionNonReactive === undefined) {
        return false
      }
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      const userID = firebaseUserHandler.getLoggedInUserId()
      return this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy.people_with_moderator_key.includes(
        userID
      )
    },

    isAudienceMember() {
      if (this.nonReactiveState.screeningRoomSessionNonReactive === undefined) {
        return false
      }
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      const userID = firebaseUserHandler.getLoggedInUserId()
      return this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy.audience_members.includes(
        userID
      )
    },

    numberOfPeopleInLobby() {
      if (this.nonReactiveState.screeningRoomSessionNonReactive) {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )

        const usersInLobby = ScreeningRoomSession.getUsersInLobby2(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
        return usersInLobby.length
      } else {
        return 0
      }
    },

    output_syncSource() {
      return this.nonReactiveState.screeningRoomSessionNonReactive
        ? MasterClock.getSyncSource(
            this.nonReactiveState.screeningRoomSessionNonReactive.masterClock
          )
        : undefined
    },

    onEnableTransportFeature(showFlag) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      ScreeningRoomSession.enableTransportFeature(
        this.nonReactiveState.screeningRoomSessionNonReactive,
        showFlag
      )
    },

    onEnableMarkerListFeature(showFlag) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      ScreeningRoomSession.enableMarkerListFeature(
        this.nonReactiveState.screeningRoomSessionNonReactive,
        showFlag
      )
    },

    //  For audience rooms, no sfVideoChat exists.
    updateFromLightmodeChange(onFlag) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive === undefined) {
        return
      }
      const player = this.$refs.sfVideoPlayer?.videojsPlayer
      const whipPlayer = this.$refs.whipStreamPlayer?.videojsPlayer

      if (player && player?.fluid() !== onFlag) {
        this.$refs.sfVideoPlayer.setFluid(onFlag)
      }
      if (whipPlayer && whipPlayer?.fluid() !== onFlag) {
        this.$refs.whipStreamPlayer.setFluid(onFlag)
      }
    },

    isModerator() {
      return this.nonReactiveState.screeningRoomSessionNonReactive === undefined
        ? false
        : isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive)
    },

    onDAWStreamPackage(
      packageTimestamp,
      syncData,
      dawstreamingClientUser,
      jitterbufferOffset,
      timeStamp
    ) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive) {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        const block =
          MasterClock.getSyncSource(
            this.nonReactiveState.screeningRoomSessionNonReactive.masterClock
          ) !== 'daw_streamer'
        if (block) {
          console.log('blocked call')
          return
        } else {
          ScreeningRoomSession.onDAWStreamPackage(
            this.nonReactiveState.screeningRoomSessionNonReactive,
            packageTimestamp,
            syncData,
            dawstreamingClientUser,
            jitterbufferOffset,
            timeStamp
          )
        }
      }
    },

    async deleteMarkerLane(versionID, markerLaneID) {
      await ScreeningRoomSession.deleteMarkerLane(
        this.nonReactiveState.screeningRoomSessionNonReactive,
        versionID,
        markerLaneID
      )
    },

    async onRequestUserMute(muteFlag) {
      await ScreeningRoomSession.setUserMute(
        this.nonReactiveState.screeningRoomSessionNonReactive,
        muteFlag
      )
    },

    copyScreeningRoomDB() {
      return this.nonReactiveState.screeningRoomSessionNonReactive
        ? _.cloneDeep(
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .screeningRoomDBCopy
          )
        : undefined
    },
    copyRefinedSharedState() {
      return this.nonReactiveState.screeningRoomSessionNonReactive !== undefined
        ? _.cloneDeep(
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .refinedSharedState
          )
        : undefined
    },

    getKeepAliveValue() {
      return this.getRefinedSharedState() &&
        this.getRefinedSharedState().users.keep_room_alive
        ? this.getRefinedSharedState().users.keep_room_alive.active
        : false
    },

    getRefinedSharedState() {
      return this.nonReactiveState.screeningRoomSessionNonReactive !== undefined
        ? this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
            .refinedSharedState
        : undefined
    },

    onMarklistRepositionRequest(posSeconds) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      console.log('got a request to seek to pos' + posSeconds)
      const posSeconds2 = Math.max(0, posSeconds)
      MasterClock.userRequestTransport(this.nonReactiveState.screeningRoomSessionNonReactive, {
        pos: posSeconds2,
      })

      updateClock(this, this.nonReactiveState.screeningRoomSessionNonReactive)
    },

    //  Jumps to pos 0 if you request to start playback while at end if video
    onTransportRequestPlayFlag(playFlag) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      const srs = this.nonReactiveState.screeningRoomSessionNonReactive
      MasterClock.userRequestTransport(srs, { playFlag: playFlag })
    },

    onTransportComponentRepositionRequest(posSeconds) {
      console.log('got a request to seek to pos' + posSeconds)

      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      const posSeconds2 = Math.max(0, posSeconds)
      MasterClock.userRequestTransport(this.nonReactiveState.screeningRoomSessionNonReactive, {
        pos: posSeconds2,
      })

      updateClock(this, this.nonReactiveState.screeningRoomSessionNonReactive)
    },

    async onRequestDropMarker(markerType) {
      await this.saveInitialMarker(markerType)
    },

    async onRequestSaveMarker(marker) {
      try {
        const updateObject = _.cloneDeep(marker)
        console.log(marker)

        updateObject.currently_updating_user = ''
        await WebClientImplementation.updateMarker(
          App.Firebase,
          updateObject,
          this.loggedInUserID
        )

        SpotterfishSession.trackEvent(
          App.spotterfishSession,
          'user_updated_marker',
          updateObject
        )

        return
      } catch (error) {
        throw error
      }
    },

    async onRequestSaveMarkerCategory(updateObject) {
      try {
        await WebClientImplementation.updateMarker(
          App.Firebase,
          updateObject,
          this.loggedInUserID
        )
        SpotterfishSession.trackEvent(
          App.spotterfishSession,
          'user_updated_marker',
          updateObject
        )
      } catch (error) {
        throw error
      }
    },

    onResetMarkerPositionToPlayPosition(marker) {
      const offset = this.clockCopy.videoFileOffset
        ? this.clockCopy.videoFileOffset
        : 0
      const pos = Timecode.secondsToSMPTEString(
        this.clockCopy.position + offset,
        this.clockCopy.videoFileFrameRateKey
      )

      marker.tc_pos = pos
      console.log('onResetMarkerPositionToPlayPosition', marker, pos)
      this.onRequestSaveMarker(marker)
    },

    async saveInitialMarker(markerType) {
      const markerLaneID = this.nonReactiveState.projectDBCopy?.current_version_marker_lane

      try {
        if (!markerLaneID) {
          throw new Error('Could not save marker')
        }

        const initialMarkerObject = {
          category_select: markerType.name,
          creator: this.loggedInUserID,
          currently_updating_user: this.loggedInUserID,
          description: '',
          frame_thumbnail: '',
          original_parent_marker_lane: markerLaneID,
          tc_pos: Timecode.secondsToSMPTEString(
            this.clockCopy.position,
            this.clockCopy.videoFileFrameRateKey
          ),
          title: '',
          uid: '',
          updated_by: [],
          updated_date: '',
        }
        console.log(initialMarkerObject)

        const markerId = await WebClientImplementation.addMarker(
          App.Firebase,
          initialMarkerObject
        )

        SpotterfishSession.trackEvent(
          App.spotterfishSession,
          'user_dropped_marker',
          initialMarkerObject
        )

        initialMarkerObject['.key'] = markerId
        this.$refs.transport.onSelectMarker({
          marker: initialMarkerObject,
          action: 'open',
        })
      } catch (error) {
        console.error('saveInitialMarker. Error:', error)
      }
    },

    async captureScreenshot(obj) {
      const screenShotBlob = await this.$refs.sfVideoPlayer.captureScreenshot()
      obj.item.frame_thumbnail = screenShotBlob
      obj.item.frame_thumbnail_tc = obj.item.tc_pos
      obj.cb(obj.item)
    },

    onRequestHighlightMarker(marker) {
      console.log('in screening room', marker)
      const screeningRoomID = this.nonReactiveState.screeningRoomSessionNonReactive
        ? this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
            .screeningRoomDBCopy['.key']
        : undefined
      if (!screeningRoomID) return
      this.$store
        .dispatch('firebase_post_highlited_marker', {
          screeningRoomID: screeningRoomID,
          marker: marker ? marker['.key'] : undefined,
        })
        .catch((error) => {
          debugger
          console.log(error)
        })
    },

    onRequestDeleteMarker(marker) {
      // TODO: Should mark as deleted instead if user did not drop it herself
      const createdByMe = marker.creator === this.loggedInUserID
      console.log({ createdByMe })
      console.log(this.templateReactiveData.isUserProjectModerator)
      if (createdByMe || this.templateReactiveData.isUserProjectModerator) {
        this.$store.dispatch('marker_delete', marker['.key']).then(() => {})
      } else {
        this.$root.$globalAlert.open(
          `Could not delete`,
          { line1: 'You can not delete markers dropped by other users' },
          { color: this.colors.mx.darkPanel }
        )
        return
      }
    },

    onRequestToggleDawStreamerMute(flag) {
      this.$refs.sfVideoChat.export_setDawStreamerMutedState(flag)
    },

    onMarkerPanelClosed() {
      this.uiStates.markerList = false
      this.uiStates.highlightedMarker = undefined
      this.$refs.markerList.toggleExpand(undefined)
    },

    async onRequestResetMarker(marker) {
      const tempMarkers = _.cloneDeep(this.markers)
      let index = tempMarkers.findIndex((obj) => obj['.key'] === marker['.key'])
      if (index !== -1) {
        console.log('replacing ', tempMarkers[index])
        console.log('with', marker)
        tempMarkers[index] = marker
        const processedMarkers = await MarkerUtils.processArray(
          App.Firebase,
          CloudClient,
          this.clockCopy,
          this.getConnectedUsers,
          tempMarkers
        )
        this.uiStates.markers = processedMarkers
      }
    },

    showSlowLink() {
      this.uiStates.slowLinkMessage = true
      setTimeout(() => {
        this.uiStates.slowLinkMessage = false
      }, 1000)
    },

    async checkForDemoProject() {
      // Check if we should load a demo project, and if so, load it. The following
      // criteria need to be met for a demo project to be loaded:
      // 1. The current user is the owner of the room.
      // 2. No project has ever been loaded in this room before.
      // 3. The current user has at least one demo project.

      if (
        ScreeningRoomSession.getScreeningRoomOwner(
          this.nonReactiveState.screeningRoomSessionNonReactive
        ) !== this.loggedInUserID
      ) {
        return
      }

      const loadCount = ScreeningRoomSession.getScreeningRoomProjectLoadCount(
        this.nonReactiveState.screeningRoomSessionNonReactive
      )
      if (loadCount !== 0) {
        return
      }

      console.log(
        'Room has no loaded projects before, attempting to load demo project'
      )

      // If we got this far it means we should try and load a demo project. These are
      // fetched based on the `is_demo_project` property of projects belonging to this
      // user. If no projects match, it might mean the user was created before we
      // introduced this functionality, or that they have deleted their demo projects.

      // Refresh the projects array, and use the result directly in this function.
      const projectsArray = await this.$store.dispatch(
        'projects_get_available_with_realtime_listener',
        this.loggedInUserID
      )

      // console.log('Got new projects array before loading demo project:', projectsArray)

      const demoProjects = []
      for (const project of projectsArray) {
        if (!project.is_demo_project) {
          continue
        }
        demoProjects.push(project)
      }

      if (demoProjects.length > 0) {
        console.log('Found at least one demo project')
        // Sort demo projects then load the earliest one.
        demoProjects.sort(
          (a, b) => a.created_date.seconds - b.created_date.seconds
        )
        this.moderator_RequestProject(demoProjects[0], 168)
      } else {
        console.log('Found no demo projects')
      }
    },

    resetVideoFile() {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      // todo - make sure no error message is displayed if this is intentional
      ScreeningRoomSession.setProjectToError(
        this.nonReactiveState.screeningRoomSessionNonReactive
      )

      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
    },

    async toggleIngestLiveStream() {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      const flag = this.templateReactiveData.whipStreamActive ? false : true
      const srs = this.nonReactiveState.screeningRoomSessionNonReactive

      let format
      
      if (flag === true) {
        format = await this.$root.$globalSelect.open(`Select Audio Format`, `Surround formats will be folded down automatically in stereo environments.`, ['Stereo', '5.1', '7.1'], { width: '340px', color: this.colors.mx.darkPanel })
        
        if (!format) {
          return
        }
      }

      this.popcornMessage = `${flag ? 'Setting up for Stream...' : 'Closing stream' }`

      // Todo: we should set the entire masterclock to a third sync state

      // Closes and updates endpoint if already available
      const res = await ScreeningRoomSession.ingestLiveStream(
        this.nonReactiveState.screeningRoomSessionNonReactive,
        this.loggedInUserID,
        flag,
        format
      )
      this.popcornMessage = undefined
      
      if (flag && res) {
        await navigator.clipboard.writeText(res)
      

        this.$root.$globalAlert.open(
          `Streaming Link Copied to clipboard`,
          {
            line1: `${
              `Paste it into your Streaming Application or Hardware Encoder to start ${format} streaming.`
            }`,
            line3: `${ res }`
          },
          {
            color: this.colors.mainAccent,
          }
        )
      }
    },

    onAnimationFrame() {
      // assert(
      //   ScreeningRoomSession.checkInvariantScreeningRoomSession(
      //     this.nonReactiveState.screeningRoomSessionNonReactive
      //   )
      // )
      const srs = this.nonReactiveState.screeningRoomSessionNonReactive
      ScreeningRoomSession.updateScreeningRoomSession(srs)

      const newSyncSource = this.templateReactiveData
        .dawStreamerSelectedAsSyncSource
        ? 'daw_streamer'
        : 'timing_provider'

      //  Detect change of sync source
      {
        //??? Do not use templateReactiveData in logic!
        const prevSyncSource = MasterClock.getSyncSource(srs.masterClock)
        if (newSyncSource !== prevSyncSource) {
          console.log(
            `[[sync]] added mediasync ${newSyncSource} ${this.templateReactiveData.dawStreamerSelectedAsSyncSource}`
          )

          //  Updates masterClock AND video player
          this.$refs.sfVideoPlayer?.setSyncSource(
            srs.masterClock,
            newSyncSource
          )
        }
      }

      const clockCopy = ScreeningRoomSession.getClockWithDefault(srs)
      const equal = _.isEqual(clockCopy, this.clockCopy)
      if (!equal) {
        updateClock(this, srs)
      }

      //  STOP at end of video
      if (
        newSyncSource === 'timing_provider' &&
        this.nonReactiveState.onSharedStateChangedDepth === 0
      ) {
        if (
          clockCopy.playbackTransport.playFlag === true &&
          clockCopy.playbackTransport.pos >= srs.masterClock.videoLength
        ) {
          MasterClock.userRequestTransport(srs, {
            pos: srs.masterClock.videoLength,
            playFlag: false,
          })
        }
      }

      const tempdata = calcTemplateReactiveData(
        srs,
        this.nonReactiveState.versionDBCopy,
        this.nonReactiveState.projectDBCopy,
        this.uiStates.isPlayerOnlyMode,
        this.nonReactiveState.roomFeatureBitsCopy,
        this.popcornMessage,
        this.loggedInUserID,
        this.uiStates.transportWindowSize,
      )
      if (this.templateReactiveData === undefined) {
        this.templateReactiveData = tempdata
      }
      const equal2 = _.isEqual(tempdata, this.templateReactiveData)
      if (!equal2) {
        if (this.templateReactiveData) {
          try {
            VueUtils.mergeVueObjectsDeep(this.templateReactiveData, tempdata, this)
          } catch (error) {
            Sentry.captureException(error, {
              tempdata: tempdata,
              templateReactiveData: this.templateReactiveData,
            })
          }
        } else {
          this.templateReactiveData = tempdata
        }
      }

      // if((this.animationFrameCount % 10) === 0){
      //   const c = this.clockCopy
      //   assert(this.clockCopy !== undefined)

      //   const s = `localOverrideFlag:${ c.localOverrideFlag }` +
      //   ` videoFileLengthOpt: ${ JSON.stringify(c.videoFileLengthOpt) }` +
      //   ` interactiveTransport: ${ JSON.stringify(c.interactiveTransport) } ` +
      //   ` mcorpTransport:${ JSON.stringify(c.mcorpTransport) }`
      //   console.log(s)
      // }

      this.nonReactiveState.animationFrameCount++
      this.requestNextAnimationFrame()

      // this.animFrame = setTimeout(this.onAnimationFrame, 1000 / 30)

      // assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
    },

    requestNextAnimationFrame () {
      const _requestAnimationFrame = window.requestAnimationFrame
      || window.mozRequestAnimationFrame
      || window.webkitRequestAnimationFrame
      || window.msRequestAnimationFrame
      this.animationFrameId = _requestAnimationFrame(this.onAnimationFrame.bind(this))
    },
    stopAnimationFrame () {
      const _cancelAnimationFrame = window.cancelAnimationFrame
      || window.mozCancelAnimationFrame
      || window.webkitCancelAnimationFrame
      || window.msCancelAnimationFrame
      _cancelAnimationFrame(this.animationFrameId)
      this.animationFrameId = undefined
    },

    async videoPlayer_setBufferingFlag(bufferingFlag) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        await ScreeningRoomSession.setBufferingFlag(
          this.nonReactiveState.screeningRoomSessionNonReactive,
          bufferingFlag
        )
      }
    },

    async videoPlayer_setCanpLayThroughFlag(canPlayThroughFlag) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        await ScreeningRoomSession.setCanPlayThroughFlag(
          this.nonReactiveState.screeningRoomSessionNonReactive,
          canPlayThroughFlag
        )
      }
    },

    async videPlayer_setSeekIndicator(seekFlag) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        await ScreeningRoomSession.setSeekingStatus(
          this.nonReactiveState.screeningRoomSessionNonReactive,
          seekFlag
        )
      }
    },

    videoPlayer_onTimeUpdate() {
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )

        ScreeningRoomSession.onVideoPlayerTimeUpdate(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )

        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
      }
    },

    videoPlayer_onPlayFlagChange(playFlag) {
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
      }
    },

    videoPlayer_onFileError() {
      this.resetVideoFile()
    },

    muteEveryone() {
      if (this.$refs.sfVideoChat) {
        this.$refs.sfVideoChat.muteEveryone()
      }
    },

    /*
    "file_state" : {
        "audioFiles" : [ {
          "audio_file_start_smpte" : "01:00:00:00",
          "audio_samplerate" : 48000,
          "contentType" : "video/mp4",
          "file_id" : "LNZheeH6y5IokDxgTfJS",
          "path" : "s4b5OEo4zd5dI5K8Rqjo/testsrc.mp4",
          "url" : "https://storage.googleapis.com/audiopostrdev.appspot.com/s4b5OEo4zd5dI5K8Rqjo/testsrc.mp4?GoogleAccessId=audiopostrdev%40appspot.gserviceaccount.com&Expires=1613462374&Signature=aB1QS0ePNSAkM4pzfvlcfixjRUh1Fg6kGt22xQ6wy8ifqNJrMMgLZMF%2B1zi1LDH2eLyvP%2BTOMfnRZbqa3RIvfjtMzEtTJbowa7KpYnKrCW37WK8r36EULHswqH20Nar3GoI5BjRBp4jsvHgFpoV90XjjpTODHtcUoUiTdZz3F11ZmbI9Ds%2BCogEBYxTrUpKESvgnk1cI%2FkVkuJ9cgFcAIW0UEzAYRXCnsynfXGdQrnKRO4%2B4%2BOZc9wqJIObsiXDZwPG8Ml%2BL0LsSU5Az%2FClngWEWGZX0NHpoPtYwt4%2B9JxGVaQFWa7KvHNGG3UoPrJ%2FPCdVaK4N1uoaNdsuL%2FjQcew%3D%3D"
        } ],
        "projectId" : "s4b5OEo4zd5dI5K8Rqjo",
        "versionId" : "hbIPxIakBMFlFSRnW1hF",
        "videoFiles" : [ {
          "contentType" : "video/mp4",
          "file_id" : "LNZheeH6y5IokDxgTfJS",
          "path" : "s4b5OEo4zd5dI5K8Rqjo/testsrc.mp4",
          "url" : "https://storage.googleapis.com/audiopostrdev.appspot.com/s4b5OEo4zd5dI5K8Rqjo/testsrc.mp4?GoogleAccessId=audiopostrdev%40appspot.gserviceaccount.com&Expires=1613462374&Signature=aB1QS0ePNSAkM4pzfvlcfixjRUh1Fg6kGt22xQ6wy8ifqNJrMMgLZMF%2B1zi1LDH2eLyvP%2BTOMfnRZbqa3RIvfjtMzEtTJbowa7KpYnKrCW37WK8r36EULHswqH20Nar3GoI5BjRBp4jsvHgFpoV90XjjpTODHtcUoUiTdZz3F11ZmbI9Ds%2BCogEBYxTrUpKESvgnk1cI%2FkVkuJ9cgFcAIW0UEzAYRXCnsynfXGdQrnKRO4%2B4%2BOZc9wqJIObsiXDZwPG8Ml%2BL0LsSU5Az%2FClngWEWGZX0NHpoPtYwt4%2B9JxGVaQFWa7KvHNGG3UoPrJ%2FPCdVaK4N1uoaNdsuL%2FjQcew%3D%3D",
          "video_file_start_smpte" : "01:00:00:00",
          "video_framerate" : 25
        } ]
      },
    */

    //??? clean
    async onScreeningRoomDBChanged(value, value0) {
      assert(this.nonReactiveState.onScreeningRoomDBChangedDepth === 0)
      this.nonReactiveState.onScreeningRoomDBChangedDepth++

      try {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )

        const srs = this.nonReactiveState.screeningRoomSessionNonReactive
        if (true) {
          // console.table({
          //   'EXEC onScreeningRoomDBChanged()': {
          //     count: this.onScreeningRoomDBChangedCount,
          //     v: value,
          //     v0: value0,
          //     srs: srs !== undefined
          //   }
          // })

          // console.log(`EXEC onScreeningRoomDBChanged(), count: ${this.onScreeningRoomDBChangedCount}`)
          // console.log('--> TO')
          // console.table(value)
          // console.log('-->FROM')
          // console.table(value0)

          this.nonReactiveState.onScreeningRoomDBChangedCount++
        }

        if (value) {
          const users = await SpotterfishSession.getUserObjects(
            App.Firebase,
            value.people_seated
          )
          this.$store.commit('setConnectedUsers', users)
          console.log(
            '[[Screening room]] An update was made to room, user is seated',
            this.isSeated()
          )

          //  Eject user
          if (
            !this.isSeated() &&
            !this.hasModeratorKey() &&
            !this.isAudienceMember()
          ) {
            console.log(
              'User is no longer seated or moderator, seated or audience member, send back to lobby.'
            )
            await this.$router
              .push({
                name: 'lobby',
                query: {
                  hash: this.$route.query.hash,
                },
              })
              .catch((e) => {
                debugger
                console.log(e)
              })
          }

          this.nonReactiveState.roomFeatureBitsCopy = _.cloneDeep(
            ScreeningRoomSession.getRoomFeatureBits(srs)
          )
          const tempdata = calcTemplateReactiveData(
            srs,
            this.nonReactiveState.versionDBCopy,
            this.nonReactiveState.projectDBCopy,
            this.uiStates.isPlayerOnlyMode,
            this.nonReactiveState.roomFeatureBitsCopy,
            this.popcornMessage,
            this.loggedInUserID,
            this.uiStates.transportWindowSize,
          )
          if (this.templateReactiveData === undefined) {
            this.templateReactiveData = tempdata
          }
          const equal2 = _.isEqual(tempdata, this.templateReactiveData)
          if (!equal2) {
            if (this.templateReactiveData) {
              try {
                VueUtils.mergeVueObjectsDeep(this.templateReactiveData, tempdata, this)
              } catch (error) {
                Sentry.captureException(error, {
                  tempdata: tempdata,
                  templateReactiveData: this.templateReactiveData,
                })
              }
            } else {
              this.templateReactiveData = tempdata
            }
          }
        }

        const users = ScreeningRoomSession.getUsersInLobby2(srs)
        this.$store.commit('setUsersInLobby', users)
        
        if (!this.uiStates.passedInProjectLoaded) {
          await this.checkForPassedInProject()
        }
      } catch (error) {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        this.nonReactiveState.onScreeningRoomDBChangedDepth--
        assert(this.nonReactiveState.onScreeningRoomDBChangedDepth === 0)

        throw error
      }

      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      this.nonReactiveState.onScreeningRoomDBChangedDepth--
      assert(this.nonReactiveState.onScreeningRoomDBChangedDepth === 0)
    },

    async notifyModeratorOnError() {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      if (!isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive)) {
        return
      }

      await this.$root.$globalAlert.open(
        'Error',
        {
          line1: `Could not load project. The selected file may be broken or expired.`,
        },
        { color: this.colors.mainAccent }
      )
    },

    //Loading shorter video after longer video can cause mcorp position to be output of bounds for short video.
    // clamp mcorp position and daw-position when INTERPRETING the position, not when updating master clock. Changing video gies new meaning to existing clock pos.

    //  Called re-enatrantly, is not reentry-safe
    //  file_state = non-persistent playback settings, as shared between all clients in a screening room.
    async sharedState__onFileStateXLChanged(fileOpt, fileOpt0) {
      assert(this !== undefined)
      const srs = this.nonReactiveState.screeningRoomSessionNonReactive
      assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
      assert(_.isEqual(fileOpt, fileOpt0) === false)

      try {
        if (true) {
          this.nonReactiveState.fileStateXLChangeCount++
        }

        this.popcornMessage = 'Loading project'

        assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
        console.log(fileOpt)
        const projectDB =
          fileOpt !== undefined
            ? await SpotterfishSession.getProjectFromUID(
                srs.screeningRoomConnection.spotterfishSession,
                fileOpt.file_state.projectId
              )
            : undefined

        // IMPORTANT: We can not rely on videoJS to handle errors as an error will break the player
        // We check the files ourselves before sending to videoJS
        const fileExists =
          fileOpt !== undefined ? await doesFileExist(fileOpt) : false
        const fileIsPlayable =
          fileOpt !== undefined ? await isFilePlayable(fileOpt) : false
        // const isResetFile = resetFileLoaded(fileOpt)
        // NOTICE: On 'error' in shared state -> fileOpt will be undefined
        // We load a special error url and unload previous project.
        if (fileOpt === undefined) {
          await this.$refs.sfVideoPlayer.resetVPAtomic()

          this.popcornMessage = undefined
          this.uiStates.html_output_selectedProjectKey = undefined
          this.nonReactiveState.projectDBCopy = undefined
          this.uiStates.markerLaneID = undefined

          // it is ok that all users requests TO update here. Error video should never play.
          const _sync = MasterClock.getSyncSource(srs.masterClock)
          if (_sync === 'timing_provider') {
            MasterClock.userRequestTransport(srs, { playFlag: false, pos: 0 })
          }
          // TODO: do not notify if the fileopt was intentionally changed to "clear player"
          await this.notifyModeratorOnError()
          return
        } else if (fileExists === false || fileIsPlayable === false) {
          this.popcornMessage = undefined
          console.log('Could not load file - expired???')
          // TODO - notify the initiator
          return this.videoPlayer_onFileError()
        }

        //  Did this user initiate loading this project?
        //  IMPORTANT: Cannot do this if our webclient *joined* someone else playing the video.
        //  TODO: Rewind video.
        const thisClientInitiatedProjectSelect =
          this.uiStates.initiatedProjectSelectDBID === projectDB['.key']
            ? projectDB['.key']
            : false

        // Close project listener if this user did not initiate the project selection
        // Otherwise the listener will be open for *another* project when several moderators switch between them.
        if (thisClientInitiatedProjectSelect === false) {
          this.uiStates.projectListener()
          this.uiStates.initiatedProjectSelectDBID = undefined
        }

        const length = await loadVideoAtomic(
          srs.screeningRoomConnection.spotterfishSession,
          this.$refs.sfVideoPlayer,
          fileOpt,
          fileOpt0,
          srs.masterClock
        )

        //  IF this client caused the video to be loaded, we rewind play pos and stop
        //  IMPORTANT: Cannot do this if our webclient *joined* someone else playing the video.
        {
          const syncSource = MasterClock.getSyncSource(srs.masterClock)
          if (
            thisClientInitiatedProjectSelect &&
            syncSource === 'timing_provider'
          ) {
            MasterClock.userRequestTransport(srs, { playFlag: false, pos: 0 })
          }
        }

        MasterClock.setVideoLength(srs.masterClock, length)

        assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))

        this.popcornMessage = undefined

        ////////////////////////////////  START SIDE EFFECTS

        this.uiStates.html_output_selectedProjectKey = projectDB
          ? projectDB['.key']
          : undefined
        this.nonReactiveState.projectDBCopy = projectDB

        this.uiStates.markerLaneID = projectDB
          ? projectDB.current_version_marker_lane
          : undefined
          
        updateClock(this, srs)

        //  Used to trigger child components.
        //  tempVersionDB is a value = no aliasing
        {
          const tempVersionDB = {
            description: '',
            name: 'MixStage',
            video_file: fileOpt.avFiles.videoFile,
            audio_file: fileOpt.avFiles.audioFiles,
            video_file_start_smpte:
              fileOpt.file_state.videoFiles[0].video_file_start_smpte,
            video_framerate: fileOpt.file_state.videoFiles[0].video_framerate,
          }
          tempVersionDB['.key'] = fileOpt.file_state.versionId
          this.nonReactiveState.versionDBCopy = tempVersionDB
        }

        this.popcornMessage = 'Fetching markers'

        // TODO: Get all marker lanes here
        const lanes = await ScreeningRoomSession.getVersionMarkerLanes(
          App.firestoreDB,
          this.nonReactiveState.versionDBCopy['.key']
        )
        this.uiStates.availableMarkerLanes = lanes

        // TODO: set up realtime listener for all markers for this.markerLaneID. pass them back as a prop to markerlane component + markerlist component
        ScreeningRoomSession.listenForMarkers(
          srs,
          App.firestoreDB,
          this.uiStates.markerLaneID,
          async (markers) => {
            const processedMarkers = await MarkerUtils.processArray(
              App.Firebase,
              CloudClient,
              this.clockCopy,
              this.getConnectedUsers,
              markers
            )
            this.popcornMessage = undefined
            this.uiStates.markers = processedMarkers
          }
        )

      } catch (error) {
        debugger
        throw error
      }
    },

    //  Called re-enatrantly, is not reentry-safe
    async onSharedStateChanged(refinedSharedState, value0) {
      assert(this.nonReactiveState.onSharedStateChangedDepth === 0)
      this.nonReactiveState.onSharedStateChangedDepth++

      assert(_.isEqual(undefined, undefined) === true)
      assert(this !== undefined)

      const srs = this.nonReactiveState.screeningRoomSessionNonReactive

      try {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        assert(
          ScreeningRoomSession.proxy_checkInvariantRefinedSharedState(
            refinedSharedState
          )
        )

        if (true) {
          // console.dir({
          //   'EXEC onSharedStateChanged()': {
          //     count: this.onSharedStateChangedCount,
          //     v: refinedSharedState,
          //     v0: value0,
          //     fileStateXL: this.fileStateXL
          //   }
          // })
          this.nonReactiveState.onSharedStateChangedCount++
        }

        //  Detect change of sharedState's video file
        //  file_state = non-persistent playback settings, as shared between all clients in a screening room.
        {
          assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))

          const fileOpt = refinedSharedState.fileStateXL
          // NOTICE: when user selects the same video file again the url changes
          if (_.isEqual(this.fileStateXL, fileOpt) === false) {
            const fileOpt0 = this.fileStateXL
            this.fileStateXL = fileOpt ? _.cloneDeep(fileOpt) : undefined

            await this.sharedState__onFileStateXLChanged(fileOpt, fileOpt0)
          }
        }
        // TODO: Should happen inside screeningroomsession -> not here.
        const sharedStatePlaybackVector =
          SharedStateTiming.createPlaybackVectorForLocalTO(
            refinedSharedState,
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .serverTimeOffset,
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
              .localBaseTime,
            this.cachedPlaybackVector
          )
        if (
          sharedStatePlaybackVector !== undefined &&
          this.nonReactiveState.screeningRoomSessionNonReactive.masterClock.mediaSyncSource ===
            'timing_provider' &&
          this.nonReactiveState.screeningRoomSessionNonReactive.masterClock.timing.timingProvider
            .__timingProviderSource === 'shared_state_timing'
        ) {
          // Set the vector to the local TO
          this.cachedPlaybackVector = refinedSharedState.playback_vector
          MasterClock.onSharedStateVector(
            this.nonReactiveState.screeningRoomSessionNonReactive.masterClock,
            sharedStatePlaybackVector
          )
        }

        //  ANALYTICS
        assert(this.nonReactiveState.screeningRoomSessionNonReactive !== undefined)
        {
          assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
          analytics_log_started_collaboration(
            refinedSharedState,
            srs.screeningRoomConnection.screeningRoomDBCopy,
            this.loggedInUserID,
            this.uiStates.analytics
          )
          assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
        }

        this.nonReactiveState.roomFeatureBitsCopy = _.cloneDeep(
          ScreeningRoomSession.getRoomFeatureBits(srs)
        )

        try {
          const tempdata = calcTemplateReactiveData(
            srs,
            this.nonReactiveState.versionDBCopy,
            this.nonReactiveState.projectDBCopy,
            this.uiStates.isPlayerOnlyMode,
            this.nonReactiveState.roomFeatureBitsCopy,
            this.popcornMessage,
            this.loggedInUserID,
            this.uiStates.transportWindowSize,
          )
          if (this.templateReactiveData === undefined) {
            this.templateReactiveData = tempdata
          }
          const equal2 = _.isEqual(tempdata, this.templateReactiveData)
          if (!equal2) {
            if (this.templateReactiveData) {
              try {
                VueUtils.mergeVueObjectsDeep(this.templateReactiveData, tempdata, this)
              } catch (error) {
                Sentry.captureException(error, {
                  tempdata: tempdata,
                  templateReactiveData: this.templateReactiveData,
                })
              }
            } else {
              this.templateReactiveData = tempdata
            }
          }
          const videoPlayerVolume = this.templateReactiveData.ui.videoPlayerVolume
          this.$store.commit('setVideoPlayerVolume', videoPlayerVolume)
          this.uiStates.selectedStreamer = this.uiStates.selectedStreamer !== srs.screeningRoomConnection.refinedSharedState.streaming_user ? srs.screeningRoomConnection.refinedSharedState.streaming_user : this.uiStates.selectedStreamer
          this.uiStates.highlightedMarker = this.uiStates.highlightedMarker !== this.templateReactiveData.ui.highlightedMarker ? this.templateReactiveData.ui.highlightedMarker : this.uiStates.highlightedMarker
          this.updateFromLightmodeChange(this.templateReactiveData.lightsOff)
          
        } catch (error) {
          console.log('error in merging templateReactiveData broken out props if not equal', error)
        }
      } catch (error) {
        debugger

        if (error === 'Could not open motion app') {
          Sentry.captureException(error, {
            user: {
              uid: srs.screeningRoomConnection.spotterfishSession.userSession
                .firebaseCurrentUser.uid,
            },
            extra: {
              room_id: srs.screeningRoomConnection.screeningRoomDBCopy['.key'],
            },
          })
          await this.$root.$globalAlert.open(
            `Sync error`,
            {
              line1:
                'Sync server Connection failed, the Spotterfish team has been notified. Please try again shortly',
            },
            { color: this.colors.mainAccent }
          )
          window.location.reload()
        } else {
          debugger
          throw error
        }

        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        this.nonReactiveState.onSharedStateChangedDepth--
        assert(this.nonReactiveState.onSharedStateChangedDepth === 0)

        throw error
      }

      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      this.nonReactiveState.onSharedStateChangedDepth--
      assert(this.nonReactiveState.onSharedStateChangedDepth === 0)
    },

    onLightswitchToggled (lightStatus) {
      assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(this.nonReactiveState.screeningRoomSessionNonReactive))


      // if (lightsOffFlag && this.$refs.sfVideoChat) {
      //   this.$refs.sfVideoChat.cacheVideoFrames()
      // }

      const screeningRoomID = (this.nonReactiveState.screeningRoomSessionNonReactive ? this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'] : undefined)
      if(!screeningRoomID) return
      this.$store.dispatch('firebase_post_light_switch_status', { screeningRoomID: screeningRoomID, status: lightStatus })
        .catch((error) => {
          debugger
          console.log(error)
        })
    },

    //??? Also call swapVideoPlayerAtomic() to no-video
    async closeScreeningRoom() {
      if (this.dawStreamerPanel) {
        this.dawStreamerPanel.close()
      }
      if (this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        // Close project listener
        this.uiStates.projectListener()
        let srs = this.nonReactiveState.screeningRoomSessionNonReactive
        await JanusSession.closeVideoChatSession(srs.videoChat)
        this.nonReactiveState.screeningRoomSessionNonReactive = undefined

        await ScreeningRoomSession.closeScreeningRoomSession(srs)
        srs = undefined
      }

      window.removeEventListener('resize', this.handleResize)

      // Unsubscribe from firebase 'active_streamers'-listener
      this.$store.state.activeStreamersListener()

      this.clockCopy = undefined
      this.nonReactiveState.versionDBCopy = undefined
      localStorage.setItem('sessionRunning', 'false')
    },

    async leaveSession() {
      // window.cancelAnimationFrame(this.animFrame)
      // window.clearTimeout(this.animFrame)
      this.stopAnimationFrame()
      await this.closeScreeningRoom()
      try {
        const prevented = await this.$router.replace('dashboard')
        console.log({ prevented })
      } catch (e) {
        console.log({ e })
      }
    },

    async leaveSessionWithMessageId(messageId) {
      try {
        // window.clearTimeout(this.animFrame)
        this.stopAnimationFrame()
        await this.closeScreeningRoom()
      } catch (error) {
        console.log(error)
      }
      await this.$router
        .push({
          name: 'dashboard',
          params: { messageId: messageId },
        })
        .catch((e) => {
          debugger
          console.log(e)
        })
    },

    async onExitScreeningroomClicked() {
      this.uiStates.exitDialog = false
      await this.leaveSession()
    },

    setProjectMarkerLane(markerlaneID) {
      // console.log('selected marker lane' + markerlaneID)
      // should add marker lane to lanes array in current version
      this.$store.dispatch('version_save_marker_lane', {
        markerLaneID: markerlaneID,
        versionDBID: this.nonReactiveState.versionDBCopy['.key'],
      })
      this.$store.dispatch('project_update', {
        projectUID: this.nonReactiveState.projectDBCopy['.key'],
        updateFields: { current_version_marker_lane: markerlaneID },
      })
    },

    async endReview() {
      const textInput = prompt('Save as', 'Spotting Session')
      if (textInput && this.nonReactiveState.screeningRoomSessionNonReactive !== undefined) {
        this.popcornMessage = 'Creating marker lane'
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            this.nonReactiveState.screeningRoomSessionNonReactive
          )
        )

        await CloudClient.call_saveAndCreateNewMarkerSession(
          App.Firebase,
          this.nonReactiveState.projectDBCopy['.key'],
          textInput
        )

        this.popcornMessage = undefined
        SpotterfishSession.trackEvent(
          App.spotterfishSession,
          'user_created_new_marker_list',
          {}
        )
      }
    },

    //??? DEFECT: keeps writing to sharedState even when another moderator selects a project
    //??? Move to ScreeningRoomSession
    //??? Uses listener on DB -- move that functionallity to nonReactiveState.screeningRoomSessionNonReactive
    //  Continously copies THE project into shared_state. Each web client then detects this and loads that project.
    async projectOwner_startSyncingToSharedState(
      projectDBKey,
      expiryTimeMinutes
    ) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      assert(isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive))
      assert(SpotterfishCore.isStringInstance(projectDBKey))

      SpotterfishSession.trackEvent(App.spotterfishSession, 'project_clicked', {
        projectUID: projectDBKey,
      })

      try {
        this.uiStates.projectListener()

        this.uiStates.projectListener = App.firestoreDB
          .collection('projects')
          .doc(projectDBKey)
          .onSnapshot(
            async (projectDBReply) => {
              console.log(projectDBReply)
              if (projectDBReply.exists) {
                const projectDBData = projectDBReply.data()
                const oldProjectData = _.cloneDeep(this.nonReactiveState.projectDBCopy)

                projectDBData['.key'] = projectDBReply.id
                console.log('detected change in project', projectDBData)

                // If changes does not need to trigger a project reload - skip update
                // if (!SpotterfishHelpers.needsProjectReload(projectDBData, oldProjectData, this.loggedInUserID)) {
                //   this.popcornMessage = undefined
                //   console.log('No need for a project update')
                //   this.projectDBCopy = projectDBData
                //   return
                // }
                console.log('updating project')
                try {
                  //  NOTICE: This function syncs the projectDB to sharedState, where all client get the data.
                  //  Only the project owner can do this work.
                  //  Needs to be run continously
                  //??? ALL clients should update their projectDBCopy - not just client that syncs
                  this.nonReactiveState.projectDBCopy = projectDBData

                  await CloudClient.call_CFsetSessionProject(
                    App.Firebase,
                    this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
                      .screeningRoomDBCopy['.key'],
                    projectDBData['.key'],
                    expiryTimeMinutes
                  )
                } catch (error) {
                  debugger
                  this.nonReactiveState.projectDBCopy = oldProjectData
                  this.uiStates.projectListener()

                  if (error.message === 'No access to project') {
                    const lostAccess =
                      this.nonReactiveState.projectDBCopy['.key'] === projectDBData['.key']
                    if (lostAccess) {
                      this.resetVideoFile()
                    }
                  }
                  await this.$root.$globalAlert.open(
                    'Error',
                    { line1: `Could not load project (${error.message})` },
                    { color: this.colors.mainAccent }
                  )
                }
              }
            },
            (error) => {
              console.error('Snapshot listener error', error)
            }
          )
      } catch (error) {
        debugger

        await this.$root.$globalAlert.open(
          'Error',
          {
            line1: 'Could not set up project listener',
          },
          {
            color: this.colors.mainAccent,
          }
        )
      }
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
    },

    //??? DEFECT: keeps writing to sharedState even when another moderator selects a project
    //??? Move to ScreeningRoomSession
    //??? Uses listener on DB -- move that functionallity to screeningRoomSessionNonReactive
    //  Writes a new project into shared_state. Each web client then detects this and loads that project.
    async moderator_RequestProject(projectDB, expiryTimeMinutes) {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      assert(isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive))

      assert(SpotterfishCore.isObjectInstance(projectDB))
      this.popcornMessage = `Selecting Project: ${projectDB.project_name}`
      console.log('selecting project: ', projectDB)

      //  Show selection ahead of time.
      this.uiStates.html_output_selectedProjectKey = projectDB['.key']

      // ??? Change design to allow empty marker-lanes. Add it when saving instead. Reason: selecting project should be read-only.
      if (
        !projectDB.current_version_marker_lane ||
        typeof projectDB.current_version_marker_lane !== 'string'
      ) {
        // No marker lane in project, adding one and setting it active
        const laneId = await this.$store.dispatch('marker_lane_add')

        await this.$store.dispatch('project_update', {
          updateFields: {
            current_version_marker_lane: laneId,
          },
          projectUID: projectDB['.key'],
        })
      }

      this.uiStates.initiatedProjectSelectDBID = projectDB['.key']

      this.projectOwner_startSyncingToSharedState(
        projectDB['.key'],
        expiryTimeMinutes
      )

      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
    },

    async onRequestProject(projectDB) {
      console.log('loading project')
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )
      assert(SpotterfishCore.isObjectInstance(projectDB))
      assert(isModerator0(this.nonReactiveState.screeningRoomSessionNonReactive))

      await this.moderator_RequestProject(projectDB, 168)
    },

    async onProjectEditRequest(projectId, isNewProject) {
      const id = projectId['.key'] ? projectId['.key'] : projectId
      try {
        await this.$refs.projectEdit.open(id, isNewProject)
      } catch (error) {
        alert('Could not load project')
      }
    },

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::  UI METHODS  ::::::::::::::::::::::::::::::
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    toggleFullscreen() {
      if (
        !document.isFullScreen &&
        !document.fullscreenElement &&
        !document.webkitFullscreenElement &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement
      ) {
        const elem = document.getElementById('app')
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
        }
        this.$refs.sfVideoChat.isFullscreen = true
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
        this.$refs.sfVideoChat.isFullscreen = false
      }
    },

    toggleChat() {
      this.uiStates.chatActive = !this.uiStates.chatActive
      this.uiStates.weEnabledChat = this.uiStates.chatActive
    },

    async toggleScreenshare() {
      if (!this.uiStates.isScreensharing) {
        try {
          await this.nonReactiveState.screeningRoomSessionNonReactive?.videoChat.startScreenSharing()          
        } catch (error) {
          console.warn('Screen share fails, user did not give permission?')
        }
      } else {
        await this.nonReactiveState.screeningRoomSessionNonReactive?.videoChat.unpublishOwnScreenshare(
          true
        )
      }
    },

    async stopScreenshare() {
      this.uiStates.isScreensharing = false
      await this.nonReactiveState.screeningRoomSessionNonReactive?.videoChat.unpublishOwnScreenshare(
        true
      )
    },
    
    handleScreenshareEnded() {
      console.log('Screeningroom: screenshare ended!')
      this.uiStates.isScreensharing = false
      this.triggerUserMapUpdate()
    },

    handleLocalScreenshareStarted() {
      console.log('Screeningroom: screenshare started!')
      this.uiStates.isScreensharing = true
      this.triggerUserMapUpdate()
    },
    handleDawStreamerEnded() {
      this.triggerUserMapUpdate()
    },

    handleDataChannelMessageReceived(data) {
      console.log('in sroom', data)
      // const displayWidth = window.innerWidth;
      // const displayHeight = window.innerHeight;

      // const absoluteX = data.normalizedX * displayWidth
      // const absoluteY = data.normalizedY * displayHeight

      // // Create the indicator element
      // const indicator = document.createElement('div')
      // indicator.style.position = 'absolute'
      // indicator.style.width = '20px'
      // indicator.style.height = '20px'
      // indicator.style.backgroundColor = 'rgba(255, 0, 0, 0.6)'
      // indicator.style.borderRadius = '50%'
      // indicator.style.pointerEvents = 'none'
      // indicator.style.transform = 'translate(-50%, -50%)'
      // indicator.style.transition = 'opacity 0.5s'
      // indicator.style.zIndex = '9999'
      // indicator.style.left = `${absoluteX}px`
      // indicator.style.top = `${absoluteY}px`
      // indicator.style.opacity = '1'

      // this.$refs.audiopostr.$el.appendChild(indicator)
      // setTimeout(() => {
      //     indicator.style.opacity = '0'
      //     setTimeout(() => {
      //       this.$refs.audiopostr.$el.removeChild(indicator)
      //     }, 5000)
      // }, 5000)
    },


    handleVideoPlayerRowClick() {
      this.closeCommenting()
      this.unmuteSafari()
    },

    closeCommenting() {
      this.uiStates.highlightedMarker = undefined
      // this.$store.commit('setCommentingActive', false)
    },

    // ??? Move to screeningRoomSession-object
    listenfForActiveStreamers() {
      assert(
        ScreeningRoomSession.checkInvariantScreeningRoomSession(
          this.nonReactiveState.screeningRoomSessionNonReactive
        )
      )

      const screeningRoomDB = this.nonReactiveState.screeningRoomSessionNonReactive
        ? this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection
            .screeningRoomDBCopy
        : undefined
      if (screeningRoomDB === undefined) {
        return
      }
      console.log('setup listener for daw streamers')
      DawStreamerSession.listenForDawStreamers(
        this.$store,
        screeningRoomDB['.key'],
        App.spotterfishSession,
        async (changedActiveStreamers) => {
          console.log(
            'got a callback with daw streamers',
            changedActiveStreamers
          )

          if (
            this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.refinedSharedState.streaming_user !== Constants.STATIC_AUDIO_STRING &&
            changedActiveStreamers.indexOf(this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.refinedSharedState.streaming_user) === -1
          ) {
            await CloudClient.call_CFselectDAWStreamerForRoom(
              App.spotterfishSession.firebase,
              Constants.STATIC_AUDIO_STRING,
              screeningRoomDB['.key']
            )
          }

          console.log(
            '[[stream]] active streamers changed in screening room, new array is',
            this.uiStates.activeStreamers
          )
        },
        (error) => {
          throw new Error('Could not handle active streamers', error)
        }
      )
    },
    async checkForPassedInProject() {
      // TODO: Ask if user wants to load the passed in ID
      const oldHash = ScreeningRoomSession.decryptSecret(this.$route.query.hash)
      if (oldHash.projectId) {
        const projectID = oldHash.projectId
        const shouldLoad = await this.$root.$globalConfirm.open(
          `Load the selected project?`,
          {
            line1: 'It will replace any loaded projects.',
            line3: 'Cancel will not load the project.',
          },
          { color: this.colors.mainAccent }
        )
        if (shouldLoad) {
          this.loadProjectById(projectID)
        }
      }
      this.uiStates.passedInProjectLoaded = true
    },

    // updateJanusUserMap(userMap, users) {
    //   for (const key in userMap) {
    //     if (Object.prototype.hasOwnProperty.call(userMap, key)) {
    //       delete userMap[key]
    //     }
    //   }

    //   Object.assign(userMap, users)
    // },
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::  VUE LIFECYCLE HOOKS  :::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  //??? nonReactiveState.screeningRoomSessionNonReactive => sessionNonReactive = { screeningRoomSession, videoChatSession }
  async beforeMount() {
    // used totrigger forceful reload if session was uncleanly exited
    window.addEventListener('beforeunload', function () {
      if (component.nonReactiveState.screeningRoomSessionNonReactive?.videoChat === undefined) {
        localStorage.setItem('sessionRunning', false)
      }
    })

    // debugger
    const component = this
    try {
      component.templateReactiveData = calcTemplateReactiveData(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'Joining MixStage Session',
        undefined,
        undefined,
      )
      component.uiStates.isPlayerOnlyMode = calcIsPlayerOnlyFromQuery(
        component.$route.query
      )
      assert(component.uiStates.isPlayerOnlyMode !== undefined)

      component.$store.commit('setUsersInLobby', [])

      ///??? Exception safety
      // set initial active streamers (will return only 'static_audio') as soon as we have a session
      component.uiStates.activeStreamers =
        await ScreeningRoomSession.getStreamingUsersArrayFromActiveStreamers(
          App.Firebase,
          []
        )

      const srsErrorCallback = async (error) => {
        // window.clearTimeout(this.animFrame)
        this.stopAnimationFrame()
        if (
          error.message === 'ScreeningRoomConnection-shared-state-kill-command'
        ) {
          console.log(
            'screeningroomvue: kill command => sending user to dashboard'
          )
          await component.leaveSessionWithMessageId('room_changed')
        } else {
          console.log('Should kick this user out, no longer access', error)
          component.stopAnimationFrame()
          await component.closeScreeningRoom()
          try {
            const prevented = await this.$router.replace('dashboard')
            console.log({ prevented })
          } catch (e) {
            console.log({ e })
          }
        }
      }

      try {
        const screeningRoomSession =
          await ScreeningRoomSession.openScreeningRoomSession(
            App.spotterfishSession,
            window.MCorp,
            component.$route.query.hash,
            srsErrorCallback
          )
        console.log('srs', screeningRoomSession)
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            screeningRoomSession
          )
        )

        // 2023-09-20 Added a session flag in localstorage to determine whether we need to reload the page on unclean exits.
        localStorage.setItem('sessionRunning', 'true')

        // If audience room - all users need to have their audio and video muted initially
        // check room featurebits for this flag
        const isAudienceRoom =
          ScreeningRoomSession.getRoomFeatureBits0(
            screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy,
            screeningRoomSession.screeningRoomConnection.refinedSharedState
          ).video_chat_enabled !== true

        const videoChatSession = await JanusSession.openVideoChatSession(
          screeningRoomSession.screeningRoomConnection,
          {
            seats:
              screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy
                .seats,
            isAudienceRoom: isAudienceRoom,
          },
          srsErrorCallback
        )

        // ??? DO NOT DO THIS
        screeningRoomSession.videoChat = videoChatSession

        component.nonReactiveState.screeningRoomSessionNonReactive = screeningRoomSession

        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            component.nonReactiveState.screeningRoomSessionNonReactive
          )
        )
        assert(
          ScreeningRoomSession.checkInvariantScreeningRoomSession(
            screeningRoomSession
          )
        )

        await component.onScreeningRoomDBChanged(
          screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy,
          undefined
        )
        ScreeningRoomSession.setScreeningRoomDBChangedCallback(
          screeningRoomSession,
          async function (value, value0) {
            await component.onScreeningRoomDBChanged(value, value0)
          }
        )

        await component.onSharedStateChanged(
          screeningRoomSession.screeningRoomConnection.refinedSharedState,
          undefined
        )
        ScreeningRoomSession.setSharedStateChangedCallback(
          screeningRoomSession,
          async function (value, value0) {
            await component.onSharedStateChanged(value, value0)
          }
        )

        // ??? All users listen for active streamers - only moderators will display them
        this.listenfForActiveStreamers()

        //  Update UI to reflect connection state
        component.uiStates.moderatorPanel = isModerator0(screeningRoomSession)

        component.uiStates.transportWindowSize.width = window?.innerWidth

        const debouncedHandleResize = _.debounce((entries) => {
          for (let entry of entries) {
            const { inlineSize, blockSize } = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
            component.uiStates.transportWindowSize.width = inlineSize || entry.contentRect.width
            component.uiStates.transportWindowSize.height = blockSize || entry.contentRect.height
          }
          console.log('Debounced resize', component.uiStates.transportWindowSize.width, component.uiStates.transportWindowSize.height)
        }, 250)

        const resizeObserver = new ResizeObserver(debouncedHandleResize)
        
        if (this.$refs.transportWindow) {
          resizeObserver.observe(this.$refs.transportWindow);
        }

        component.$store.commit(
          'setConnectedUsers',
          screeningRoomSession.initialConnectedUsers
        )

        // Load a demo project, potentially.
        component.checkForDemoProject()

        //  TRIGGER VUE TEMPLATES
        component.uiStates.screeningRoomSessionNonReactiveExists = true

        component.uiStates.onHover = true
        component.popcornMessage = undefined
        if (typeof window !== 'undefined') {
          window.addEventListener('offline', async function (e) {
            await component.$root.$globalAlert.open(
              'No internet connection',
              {
                line1:
                  'Your internet connection was lost, this page will be reloaded.',
              },
              {
                color: component.colors.mainAccent,
              }
            )
            window.location.reload()
          })

          window.onmousemove = function (e) {
            component.uiStates.onHover = true
            clearTimeout(component.uiStates.timeout)
            component.uiStates.timeout = setTimeout(function () {
              if (!component.uiStates.hoveringTransport) {
                component.uiStates.onHover = false
              }
            }, 3000)
          }
        }

        videoChatSession.addCallback(
          'onUsers',
          (janusUsers) => {
            console.log('janusUserCB', janusUsers)
            Object.assign(component.janusUserMap, janusUsers)
            component.triggerUserMapUpdate()
          }
        )

        videoChatSession.addCallback(
          'onWhipStream',
          async (janusUsers) => {
            console.log('onWhipStream', janusUsers)
            Object.assign(component.janusUserMap, janusUsers)
            component.triggerUserMapUpdate()

            let whipStreamFound = false

            // Iterate through the user map to find and set the whipStream
            for (const userUid in janusUsers) {
              const user = janusUsers[userUid]
              if (user.whipStream && this.nonReactiveState.screeningRoomSessionNonReactive?.screeningRoomConnection.refinedSharedState.ingested_feed_active === userUid) {
                // Set the whipStream as the source for the main video player
                try {
                  this.$refs.whipStreamPlayer.setWhipStream(user.whipStream)
                  whipStreamFound = true
                  console.log(`WhipStream set as source for main video player from user: ${userUid}`)
                  
                } catch (error) {
                  console.log(error)
                }
                break
              }
            }

            // If no whipStream is found, set the activeWhipStream to undefined
            if (!whipStreamFound) {
              this.activeWhipStream = undefined
              try {
                this.$refs.whipStreamPlayer.setWhipStream(undefined)
              } catch (error) {
                console.log(error)
              }
            }
          }
        )

        videoChatSession.addCallback(
          'onDataChannelSyncMessage',
          (syncMessage) => {
            if (this.nonReactiveState.screeningRoomSessionNonReactive?.screeningRoomConnection.refinedSharedState.ingested_feed_active === syncMessage.userId) {
              ScreeningRoomSession.onWhipStreamPackage (this.nonReactiveState.screeningRoomSessionNonReactive, syncMessage)
            }
          }
        )

        videoChatSession.addCallback(
            'onWebRTCStateChanged',
            (on, medium) => {
              // example = { on: true, medium: 'audio' }
              // example = { on: false, medium: 'video' }
              console.log('WebRTC state changed', on, medium)
              Object.assign(component.janusUserMap, videoChatSession.getJanusUsers())
              component.triggerUserMapUpdate() 
            }
          )

          videoChatSession.addCallback(
            'onMediaStateChanged',
            (on, medium) => {
              // example = { on: true, medium: 'audio' }
              // example = { on: false, medium: 'video' }
              console.log('Media state changed', on, medium)
              Object.assign(component.janusUserMap, videoChatSession.getJanusUsers())
              component.triggerUserMapUpdate() 
            }
          )   

          // only needed if we want the user to interact...
          // videoChatSession.addCallback('onError', (error) => {
          //   console.error('Janus runtime error:', error);
          //   // Handle the error gracefully, attempt to reconnect or provide feedback to the user
          //   MixStageJanusErrors.handleNonCriticalError(error, () => {

          //   });
          // })

        component.janusUserMap = videoChatSession.getJanusUsers()
        // or, when moving to setup function Composition API:
        // Object.assign(this.janusUserMap, screeningRoomSession.videoChat.getJanusUsers())

        // ???

        component.requestNextAnimationFrame()
      } catch (error) {
        debugger
        if (error.message === 'Access denied') {
          await component.$root.$globalAlert.open(
            'Error',
            {
              line1: error.message,
            },
            {
              color: component.colors.mainAccent,
            }
          )
        } else if (error.message === 'Incorrect session') {
          await component.$root.$globalAlert.open(
            'Error',
            {
              line1: error.message,
            },
            {
              color: component.colors.mainAccent,
            }
          )
        } else if (error.message === 'Missing room data') {
          await component.$root.$globalAlert.open(
            'Error',
            {
              line1: error.message,
            },
            {
              color: component.colors.mainAccent,
            }
          )
        } else {
          await component.$root.$globalAlert.open(
            'Error',
            {
              line1: error.message,
            },
            {
              color: component.colors.mainAccent,
            }
          )
        }
        try {
          const prevented = await this.$router.replace('dashboard')
          console.log({ prevented })
        } catch (e) {
          console.log({ e })
        }
        debugger
        throw error
      }
    } catch (error) {
      await component.leaveSessionWithMessageId('room_changed')
    }
  },
  async beforeRouteLeave(to, from, next) {
    console.log('beforerouteleave')
    // window.cancelAnimationFrame(this.animFrame)
    // window.clearTimeout(this.animFrame)
    this.stopAnimationFrame()
    await this.closeScreeningRoom()
    next()
  },
  async beforeDestroy() {
    // window.cancelAnimationFrame(this.animFrame)
    // window.clearTimeout(this.animFrame)
    this.stopAnimationFrame()
    await this.closeScreeningRoom()
  },
  async destroyed() {
    console.log('screeningroom destroyed')
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@/assets/global.scss';

.grid-wrapper {
  height: 100vh;
  background: black;
  overflow: hidden;

  .video-chat-row,
  .video-player-row {
    height: 45vh;
    background-color: $color-mx-black;
  }
  .video-player-hidden {
    display: none;
  }
}

.video-chat-only {
    height: 80vh;
}

.lights-off {
  .video-chat-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    z-index: 1;
  }

  .video-player-row {
    height: 100vh;
  }
}

.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.8s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.transport-position {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.lights-on-banner {
  $width: 280px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 50%;
  margin-left: math.div(-$width, 2);
  z-index: 100;
  padding: 1px 2px;
  width: $width;
  border-radius: 3px;
  background-color: none;
  color: white;
  font-size: 90%;
  text-transform: none;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }

  .v-tabs {
    height: 40px !important;

    .v-slide-group__content {
      height: 40px !important;
      background: none !important;
    }
   

    .v-tab,
    .v-tab__wrapper {
      height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
        color: white !important;

      &:hover {
        background-color: darken(black, 10%) !important;
      }

      &.v-tab--active {
      }
    }

    .v-icon {
      font-size: 24px;
      margin-bottom: 2fuserCopx;
    }
  }
}


.audience-info-banner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 3px 8px;
  margin: 8px;

  .audience-info-people-count {
    margin-left: 2rem;
  }
}

.buffering-banner {
  margin-top: 6px;
  border-radius: 3px;
  padding: 3px 0;
  position: absolute;
  top: 85px;
  left: 40%;
  right: 40%;
  z-index: 1000;
}

.toggle-moderator-button {
  position: absolute;
  z-index: 1;
  padding: 0.25rem 0.65rem;
  top: 50%;
  margin-top: -80px;
  transform: rotate(90deg);
  transform-origin: left bottom;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: $color-mx-panel;
}

.toggle-markerlist-button {
  position: absolute;
  z-index: 1;
  padding: 0.25rem 0.65rem;
  top: 50%;
  right: 0;
  margin-top: -80px;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: $color-mx-panel;
}

.mute-all-button {
  svg {
    cursor: pointer;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.5;
  }

  svg:active {
    outline: none;
    border: none;
  }

  svg:focus {
    outline: none;
  }
}

.light-switch-button {
  margin-top: 2px;
  &:before {
    display: none;
  }
}

.upsideDown {
  transform: rotate(180deg);
}

.top-buttons {
  background-color: black;

  .toggle-button {
    justify-content: center;
  }
}

.tab-row {
  .col {
    padding: 0;
  }

  .tab-button {
    padding: 6px 0;
    background-color: #252525;
    cursor: pointer;
    border-bottom: 2px solid $color-tooltip-background;

    .v-icon {
      font-size: 2.8rem;
    }

    &.tab-button-active {
      background-color: #464646;
      border: 2px solid $color-tooltip-background;
      border-bottom: 0;
    }
  }
}

.talking-banner {
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 55px;

  .talking-user {
    display: inline-block;
    background: $color-dark-gray;
    padding: 2px 6px;
    border-radius: 3px;
    margin: 0 2px;
    z-index: 101;
  }
}

.tab-content {
  background-color: #464646;
}
.project-name {
  font-size: 0.5rem;
  top: 0.5rem;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  text-shadow: 0 0.1px black;
}
.project-name-full-screen {
  font-size: 1.5rem;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  z-index: 3;
  left: 40px;
  //  right: 0;
  top: 14%;
  text-shadow: 0 0.1px black;
}

.scaleable-wrapper {
  resize: both;
  position: relative;
  background: none;
}

.scaler {
  width: 1024px;
  height: 1024px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.85);
  transform-origin: center center;
}

.tabtitle {
  font-size: 0.7rem; /* Adjust font size as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  padding-right: 4px; /* Add some padding if necessary */
  padding-left: 4px; /* Add some padding if necessary */
}

.close-moderator {
  padding: 0;
  background-color: none;
  position: absolute;
  right: 0;
  top: 46px;
  bottom: 10px;
  opacity: 0.1;
}

.left-panel:hover > .close-moderator {
  opacity: 1;
}

.close-markerlist {
  padding: 0;
  background-color: none;
  position: absolute;
  left: 0;
  top: 46px;
  bottom: 10px;
  opacity: 0.1;
}

.right-panel:hover > .close-markerlist {
  opacity: 1;
}

.v-navigation-drawer--mini-variant,
.v-navigation-drawer {
  overflow: visible !important;
}

// .toggle-moderator-button {
//   position: absolute;
//   z-index: 1;
//   padding: 0.25rem;
//   top: 50%;
//   transform-origin: left bottom;
//   pointer-events: all;
//   cursor: pointer;
//   border-top-right-radius: 3px;
//   border-top-left-radius: 3px;
// }

.moderator-button-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-4px, -4px);
  border-radius: 50%;
  padding: 0.25rem;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1;
  height: 1.5rem;
  width: 1.5rem;
}

.exit-button {
  background-color: $color-mx-panel;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  padding: 1px 4px;
  align-items: center;
  z-index: 3;
  color: white;
  top: 7px;
  left: 12px;
  width: 70px;
  height: 30px;
  border-radius: 4px;
}
</style>
