<template>
  <div id="volume-slider-container">
    <input 
      type="range" 
      id="volume-slider" 
      min="0" 
      max="100" 
      :value="value" 
      @mousedown="$emit('dragging-start')"
      @mouseup="$emit('dragging-end', $event.target.value)"
      :disabled="isDisabled"
      class="slider whiteContoured">
      <i class="mdi mdi-volume-high volume-icon"></i>
  </div>
</template>

<script>
export default {
  name: "SimpleSlider",
  props: ['value', 'isDisabled'],
  methods: {
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/global.scss';

#volume-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}

.volume-icon {
  font-size: 1.5rem;
  color: $color-mx-white;
  margin-left: 4px;
  margin-top: 0.1rem;

  @media (max-width: 800px) {
    display: none;
  }
}

.whiteContoured {
  /* Slider Track */
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;   
  background: $color-mx-white;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.whiteContoured:hover {
  opacity: 1;
}

/* Slider Thumb */
.whiteContoured::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%; 
  outline: 1px solid black;
  background: #fff;
  z-index: 1;
  cursor: pointer; 
}

.whiteContoured::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: 1px solid black;
  background: #fff;
  cursor: pointer;
}

</style>

