const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const SpotterfishSession = require('./SpotterfishSession')

const assert = SpotterfishCore.assert
const SyncData = require('../spotterfish_library/SyncData')
const Timecode = require('../spotterfish_library/utils/Timecode.js')
const MTC = require('../spotterfish_library/MTC')
const _ = require('lodash')
const Constants = require('../spotterfish_library/consts.js')
const DawStreamerUtils = require('../spotterfish_library/utils/DawStreamerUtils.js')

import Janus from '@/lib/janus/januscjs'

import CloudClient from '@/../source_files/web_client/CloudClient'

import WebMidi from 'webmidi'


/*

STATES

1. Projector Daw streaming session exists (always when window is open)
2. Connected to room: server has asked us to connect to room, start janus streaming but muted for now
3. Current streamer: server asks us to unmute = be the audio in the meeting.


CLIENTS

- Projector client
- Viewer client


STAKEHOLDERS

- Firestore active_streamers
- Firebase shared_state

- Janus
- Custom packets
- MIDI & MTC packets

*/



// ??? Add more checks
// TODO: Should probably be moved to a new module "session.mjs"
function checkInvariantDawStreamerSession (s) {
  // assert(s !== undefined && s !== null)

  // assert(s.firebase !== undefined && s.firebase !== null)
  // assert(s.firestoreDB !== undefined && s.firestoreDB !== null)
  // assert(isObjectInstance(s.ufb) || s.ufb === undefined)
  // // NOTICE: firebaseAnalytics is optional.
  // assert(s.hasOwnProperty('firebaseAnalytics'))

  return true
}
//  Used by normal client to listen to database.
async function listenForDawStreamers (store, currentRoomId, spotterfishSession, activeStreamersChangedCallback, errorCallback) {
  assert(SpotterfishCore.isObjectInstance(store))
  assert(SpotterfishCore.isStringInstance(currentRoomId))
  assert(SpotterfishSession.checkInvariantSpotterfishSession(spotterfishSession))
  assert(SpotterfishCore.isFunctionInstance(activeStreamersChangedCallback))
  assert(SpotterfishCore.isFunctionInstance(errorCallback))

  const currentUserId = spotterfishSession.userSession.firebaseCurrentUser.uid
  // // clear old listener
  await store.state.activeStreamersListener()
  // checks active_streamers DB for streamers currently active in room, else call backs with empty array
  // if currentUser is present in snapshot - update with current screening room session id  
  store.state.activeStreamersListener = spotterfishSession.firestoreDB.collection('active_streamers').where('current_screening_room_session', '==', currentRoomId).onSnapshot((snapshot) => {
    
    if (!snapshot.empty) {
      console.log('[[stream]] Got a snapshot from active streamers')
      const streamersArray = []
      snapshot.forEach(async (doc) => {
        const streamer = doc.id
        console.log('[[stream]] Checking streamers for userId' + currentUserId)
        if (streamer === currentUserId) {
          console.log('[[stream]] the current user is actively streaming')
        }
        streamersArray.push(streamer)
      })
      activeStreamersChangedCallback(streamersArray)
    }
    else {
      // console.log('[[stream]] Got NO active streamers')
      activeStreamersChangedCallback([])
    }
  },
  (error) => {
    errorCallback(error)
  })

}

function detectChangesInActiveDawStreamer (firestore, userId, onActiveStreamerChangedCB) {
  // assert(SpotterfishSession.checkInvariantSpotterfishSession(spotterfishSession))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isFunctionInstance(onActiveStreamerChangedCB))
  // step 1, set up listener here and use callback to notify
  return firestore.collection('active_streamers').doc(userId)
    .onSnapshot((streamerObject) => {
      if (streamerObject.exists) {
        console.log('change in streamer', streamerObject.data())
        onActiveStreamerChangedCB(streamerObject.data())
      } else {
        console.log('User id is not present in active_streamers')
      }
    })
}

async function detectStreamerSelection (firebase, screeningRoomId, onStreamerSelectedCB) {
  assert(SpotterfishCore.isStringInstance(screeningRoomId))
  assert(SpotterfishCore.isFunctionInstance(onStreamerSelectedCB))
  // step 1, set up listener here and use callback to notify
  console.log('listening for streamer selection in shared state. If no access this will not be called???')
  const streaming_userPath = `shared_state/${screeningRoomId}/streaming_user/`
  const streaming_userRef = firebase.database().ref(streaming_userPath)

  try {
    // !!! NOTICE: If streamer has no access to shared_state this listener will fail silently
    // If below does not throw => we are ok to proceed
    await streaming_userRef.once('value')

    streaming_userRef.on('value', async (snapshot) => {
      if (snapshot.val() && snapshot.val() !== 'error') {
        const selectedUser = snapshot.val()
        try {
          onStreamerSelectedCB(selectedUser)
          return
        } catch (error) {
          console.error(error)
        }
      } else {
        // Error
      }
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function getScreeningRoomFromId (firestore, roomId) {
  assert(SpotterfishCore.isStringInstance(roomId))

  const room = await firestore.collection('screening_rooms').doc(roomId).get()
  const roomData = room.data()
  roomData['.key'] = room.id
  return roomData
}

const DawStreamJanusSession = ({roomId, screeningRoom, pin, user, getSyncAcc, getStream, onConnecting, getSyncOffset, getFrameRateIndex}) => {
  let janus
  let spotterfishVideoRoom
  let dawStreamerJanusId
  let dawStreamerAudioStream
  let previousReport = { timestamp: 0 }
  let encodingTime = 0

  const publishOwnFeed = (useAudio, onSuccess) => {
    spotterfishVideoRoom.createOffer(
      {
        /*
          media: you can use this property to tell the library which media (audio/video/data) you're interested in, and whether you're going to send and/or receive any of them; by default audio and video are enabled in both directions, while the Data Channels are disabled; this option is an object that can take any of the following properties:
            audioSend: true/false (do or do not send audio);
            audioRecv: true/false (do or do not receive audio);
            audio: true/false (do or do not send and receive audio, takes precedence on the above);
            audio: object with deviceId property (specify ID of audio device to capture, takes precedence on the above; devices list can be accessed with Janus.listDevices(callback) );
            videoSend: true/false (do or do not send video);
            videoRecv: true/false (do or do not receive video);
            video: true/false (do or do not send and receive video, takes precedence on the above);
            video: "lowres"/"lowres-16:9"/"stdres"/"stdres-16:9"/"hires"/"hires-16:9" (send a 320x240/320x180/640x480/640x360/1280x720 video, takes precedence on the above; default is "stdres" ) this property will affect the resulting getUserMedia that the library will issue; please notice that Firefox doesn't support the "16:9" variants, which will fallback to the ones; besides, "hires" and "hires-16:9" are currently synonymous, as there's no 4:3 high resolution constraint as of now;
            video: "screen" (use screensharing for video, disables audio, takes precedence on both audio and video);
            video: object with deviceId , width and/or height properties (specify ID of video device to capture and optionally resolution to use, takes precedence on the above; devices list can be accessed with Janus.listDevices(callback) );
            data: true/false (do or do not use Data Channels, default is false)
            failIfNoAudio: true/false (whether a getUserMedia should fail if audio send is asked, but no audio device is available, default is false)
            failIfNoVideo: true/false (whether a getUserMedia should fail if video send is asked, but no video device is available, default is false)
            screenshareFrameRate: in case you're sharing a screen/application, allows you to specify the framerate (default=3);
        */
        stream: getStream(),
        media: { audioSend: useAudio, audioRecv: false, video: false, data: true },
        success: function (jsep) {
          console.log('offer sdp:', jsep.sdp)
          const publish = { request: 'configure', audio: useAudio, video: false}
          spotterfishVideoRoom.send({ message: publish, jsep: jsep })
          if(typeof onSuccess === 'function') onSuccess()
        },
        error: function (error) {
          console.error('WebRTC error:', error)
          alert(`${error}, please reload this page`)
          if (useAudio) {
            // publishOwnFeed(false)
          } else {
            // publishOwnFeed(true)
          }
        },
        senderTransforms: {
          /* eslint-disable-next-line */
          audio: new TransformStream({
            start () {
              // Called on startup.
              console.log('[Sender transform)] Startup')
            },
            flush () {
              // Called when the stream is about to be closed
              console.log('[Sender transform] Closing')
            },
            transform: (chunk, controller) => {
              // We can not declare the transform function as async. Using.then(()=> deliberately)
              getConnectionStats(spotterfishVideoRoom).then((report) => {

                if(previousReport && report['timestamp'] !== previousReport['timestamp']) {
                  encodingTime = report['timestamp'] - previousReport['timestamp']
                  // console.log('Encoding time: ' + (encodingTime))
                  previousReport = _.cloneDeep(report)
                }

                addLocalTC(getSyncAcc(), chunk, controller, getSyncOffset(), getFrameRateIndex(), report)
                sendDataChannelSyncMessage(getSyncAcc(), getSyncOffset(), getFrameRateIndex())
              })
            }
          })
        }

    })
  }

  async function sendDataChannelSyncMessage (acc, syncOffset, framareateIndex) {
    console.log(acc)
    sendData({
      type: 'sync',
      syncData: acc.syncSnapshot,
      syncOffset: syncOffset,
      frameRateIndex: framareateIndex,
      creationTime: acc.creationTime,
      userId: user.user_id
    })
  }

  const sendData = (data) => {

    const message = {
      videoroom: 'message',
      transaction: Janus.randomString(12),
      room: roomId,
      pin,
      text: JSON.stringify(data),
    }
    console.log(message)
    // Note: messages are always acknowledged by default. This means that you'll
    // always receive a confirmation back that the message has been received by the
    // server and forwarded to the recipients. If you do not want this to happen,
    // just add an ack:false property to the message above, and server won't send
    // you a response (meaning you just have to hope it succeeded).
    spotterfishVideoRoom.data({
      text: JSON.stringify(message),
      ack: false,
      error: (reason) => {console.error(reason) },
      success: (e) => { 
        console.log('message delivered')
       }
    })
  }


  const getConnectionStats = async (pluginHandle) => {
    if(pluginHandle.webrtcStuff.pc.getStats) {
      let report
      return pluginHandle.webrtcStuff.pc.getStats(null).then((stats) => {
        stats.forEach((_report) => {
          if (_report.type === 'outbound-rtp' && _report.kind === 'audio') {
            report = _report
          }
        })
        return report
      })
    }
  }

  const unpublishOwnFeed = () => {
    return new Promise((resolve, reject) => {
      // Unpublish our stream
      var unpublish = {
        request: 'unpublish',
        pin,
      }
      spotterfishVideoRoom.send({
        message: unpublish,
        success: (resp) => {
          console.log('Successfully unpublished own feed')
          resolve(resp)
        },
        error: (err) => {
          console.error('Error when unpublishing own feed: ' + err)
          reject(err)
        },
      })
    })
  }

  const registerUsername = () => {
    const register = {
      request: 'join',
      room: roomId,
      pin,
      ptype: 'publisher',
      display: JSON.stringify({ name: 'LIVE STREAMER REGISTERED AS ' + user.user_name, uid: user.user_id, dawstream: true })
    }
    spotterfishVideoRoom.send({ message: register })
  }

  const updateRoomForLiveStreamAndRegisterUsername = () => {
    const updateMessage = {
      request: 'edit',
      room: roomId,
      pin,
      secret: pin,
      // bitrate: Constants.SDP_SETTINGS.MAX_AVERAGE_BITRATE / 2,
      new_bitrate: Constants.SDP_SETTINGS.MAX_AVERAGE_BITRATE * 8,
      new_audiocodec: 'opus,multiopus',
      audiolevel_event: false,
      audiolevel_ext: false
    }
    spotterfishVideoRoom.send({
      message: updateMessage,
      success: (resp) => {
        console.log('Successfully updated room to daw stream enabled: ' + JSON.stringify(resp))
        registerUsername()
      },
      error: function (err) {
        console.error('Error when updating room to daw stream enabled: ' + err)
      }
    })
  }

  function checkIfRoomExists () {
    return new Promise((resolve, reject) => {
      const checkRoomMessage = {
        request: 'exists',
        room: roomId,
        pin
      }
      const timer = setInterval(() => {
        spotterfishVideoRoom.send({
          message: checkRoomMessage,
          success (resp) {
            if(resp.exists) {
              clearInterval(timer)
              resolve('')
            }
          },
          error (err) {
            console.log('Error when checking if room exists:' + err)
          }
        })
      }, 2000)
      
    })
  }

  const attachAudioStreamHandle = (onSuccess) => {
    janus.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: 'spotterfishLiveStream-' + Janus.randomString(12),
      success: async function (pluginHandle) {
        spotterfishVideoRoom = pluginHandle
        try {
          await checkIfRoomExists()
          updateRoomForLiveStreamAndRegisterUsername()
        } catch (error) {
          alert(error)
        }
      },
      error: (error) => {
        alert(error)
      },
      consentDialog: (on) => {
        console.log('Consent dialog should be ' + (on ? 'on' : 'off') + ' now')
      },
      iceState: (state) => {
        console.log('ICE state changed to ' + state)
      },
      mediaState: (medium, on) => {
        console.log('Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium)
      },
      webrtcState: (on) => {
        console.log('Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now')
      },
      onmessage: (msg, jsep) => {
        const {videoroom: event, room, id} = msg
        if (event) {
          if (event === 'joined') {
            // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
            if(typeof onConnecting === 'function') onConnecting()
            dawStreamerJanusId = id
            console.log(`Successfully joined room ${room} with ID ${dawStreamerJanusId}`)
            publishOwnFeed(true, onSuccess)
          } else if (event === 'destroyed') {
            // The room has been destroyed
            console.log('The room has been destroyed!')
          } else if (event === 'event') {
            // Any new feed to attach to?
            // console.log('### EVENT:', event, msg, leaving, dawStreamerJanusId)
          }
        }
        if (jsep) {
          // Relying on that used encoding is our own, this is only for the sending side though, so we can make
          // better checks
          // console.log('forcing stereo to final sdp', jsep.sdp)
          // This is the magic to get Chrome to actually playback stereo!!! 
          // const opusPayload = getPayloadFromSdp(jsep.sdp, 'opus')
          // const redPayload = getPayloadFromSdp(jsep.sdp, 'red')
          // console.log(redPayload)
          // console.log(opusPayload)
          // jsep.sdp = jsep.sdp.replace(
          //   'a=end-of-candidates', 
          //   `a=fmtp:${opusPayload} minptime=${Constants.SDP_SETTINGS.MIN_PACKAGE_TIME};stereo=1;sprop-stereo=1;a=end-of-candidates`)
          // console.log('Modified final sdp', jsep.sdp)

          const opusPayload = DawStreamerUtils.getPayloadFromSdp(jsep.sdp, 'opus');
          console.log(opusPayload)

          // Split the SDP into lines for easier manipulation
          let sdpLines = jsep.sdp.split('\n')

          // Iterate over the SDP lines
          for (let i = 0; i < sdpLines.length; i++) {
              if (sdpLines[i].startsWith('m=audio ')) {
                  // Add the b=AS:192 line after the m=audio line to specify bandwidth
                  sdpLines.splice(i + 1, 0, `b=AS:${Constants.SDP_SETTINGS.AUDIO_BITRATE}`)
              }

              if (sdpLines[i].includes(`a=rtpmap:${opusPayload} opus/48000/2`)) {
                  // Modify the line to include stereo, bitrate parameters, and bandwidth
                  let fmtpLine = `a=fmtp:${opusPayload} minptime=${Constants.SDP_SETTINGS.MIN_PACKAGE_TIME};ptime=${Constants.SDP_SETTINGS.PACKAGE_TIME};stereo=1;sprop-stereo=1;maxaveragebitrate=${Constants.SDP_SETTINGS.MAX_AVERAGE_BITRATE};maxplaybackrate=${Constants.SDP_SETTINGS.MAX_SAMPLE_RATE}`
                  // Insert the modified fmtp line
                  sdpLines.splice(i + 1, 0, fmtpLine)
                  break; // Once the modifications are done, exit the loop
              }
          }

          // Rejoin the modified SDP lines
          jsep.sdp = sdpLines.join('\n')

          console.log('Modified final sdp', jsep.sdp)

          spotterfishVideoRoom.handleRemoteJsep({ jsep })

          // Check if any of the media we wanted to publish has
          // been rejected (e.g., wrong or unsupported codec)
          const audio = msg.audio_codec

          if (dawStreamerAudioStream && dawStreamerAudioStream.getAudioTracks() && dawStreamerAudioStream.getAudioTracks().length > 0 && !audio) {
            // Audio has been rejected
            console.log('Our audio stream has been rejected, viewers wont hear us')
          }
          // initialize as muted unless selected
          // handleMuteState(spotterfishVideoRoom, callingComponent.initAsUnmuted ? 'unmute' : 'mute')
        }
      },
      onlocalstream: function (stream) {
        console.log('Got local stream')
        dawStreamerAudioStream = stream
      },
      onremotestream: function () {
        // The publisher stream is sendonly, we don't expect anything here
      },
      oncleanup: function () {
        dawStreamerAudioStream = null
      }
    })    
  }

  const destroy = () => {
    console.log('Janus destroy')
    janus.destroy()
  }

  const init = async (onSuccess) => {
    const iceServers = Constants.ICE_SERVERS
    Janus.init({
      debug: 'all', 
      callback: function () {
        // Create janus session
        janus = new Janus({
          server: screeningRoom.video_room_server || 'https://sf1.audiopostr.com/janus',
          iceServers,
          success: function () {
            // Attach to VideoRoom plugin
            attachAudioStreamHandle(onSuccess)
          },
          error: function (error) {
            throw new Error(error)
          },
          // destroyed: function () {
          //   callingComponent.streaming = false
          //   console.log('The janus session has been destroyed!')
          // }
        })
      }
    })
  }

  const mute = () => {
    spotterfishVideoRoom.muteAudio()
  }
  const unmute = () => {
    spotterfishVideoRoom.unmuteAudio()
  }

  return {
    init,
    destroy,
    mute,
    unmute,
    publishOwnFeed,
    unpublishOwnFeed,
  }
}




//  Samples current sync info from component.
function snapshotToSyncData (syncSnapshot, syncOffsetFrames, frameRateIndex, rtpTimeStamp) {
  assert(MTC.checkInvariantSyncSnapshot(syncSnapshot))

  const mtcFrameRateIndex = syncSnapshot.mtcFrameRateIndex
  if(syncSnapshot.posFramesDecimal === undefined){
    return {
      projectFRK: 0,
      rtpTimestamp: 0,
      playing: false,
      playInfo: undefined
    }
  }
  else {
    const frk = Timecode.mtcFrameRateIndexToKey(syncSnapshot.mtcFrameRateIndex)
    const posFrames = syncSnapshot.playFlag ? syncSnapshot.posFramesDecimal + syncOffsetFrames : syncSnapshot.posFramesDecimal
    const posSeconds = Timecode.frameIndexToSeconds(posFrames, frk)
    const tc0 = Timecode.secondsToSMPTEString(posSeconds, frk)

    //  Workaround: to transmit subframes8 since receiver expects this.
    //   0 ... 0.9999
    const frameDecimals = (syncSnapshot.posFramesDecimal % 1.0)
    const subframe8Workaround = Math.floor(frameDecimals * 8)

    const tc = Timecode.smpteStringToTimeCodeParts(tc0, subframe8Workaround)

    const ts = String(rtpTimeStamp * 1000)

    const tsTruncated = Number(ts.split('').slice(-9).join(''))

    return {
      projectFRK: frameRateIndex,
      rtpTimestamp: tsTruncated,
      playing: syncSnapshot.playFlag,
      playInfo: { tc: tc, rateIndex: mtcFrameRateIndex }
    }
  }
}


// Use as transformstream transform
// Appends 12 byte MTC to chunk
function addLocalTC (syncAccNonReactive, chunk, controller, syncOffsetFrames, frameRateIndex, report) {
  assert(syncAccNonReactive.syncSnapshot !== undefined)
  const syncSnapshot = _.cloneDeep(syncAccNonReactive.syncSnapshot)
  const syncData = snapshotToSyncData(syncSnapshot, syncOffsetFrames, frameRateIndex, 0)
  const syncDataBuffer = SyncData.packSyncData(syncData)
  const chunkWithTimeCode = SyncData.appendTimecodeToChunk(chunk, syncDataBuffer)
  try {
    if (chunkWithTimeCode.data.byteLength > 1000) {
      console.warn('cant enqueue, too large, ignoring chunk. Chunk is: ' + chunkWithTimeCode.data.byteLength + ' bytes')
    }
    controller.enqueue(chunk)
  } catch (error) {
    console.error(error)
    console.log(chunk.data)
  }
}



/*
NOW
A. Protool
B. Nuendo
C. Logic Audio

LATER
D. Resolve


1. Test stopmode relocate.
2. Test start & synced playback
3. Test stop playback.
4. Test relocate during playback.
5. Test loop jump from later position to earlier position.
6. Test loop jump from earler postion to later position

???
7. Test adjust offset?
8. Test frame rates


  https://nodejs.org/api/events.html#emitteroneventname-listener

  We use webmidijs.org, v2.5.3, for helping with the standard WebMIDI API.
*/

// msg is Uint8Array
function onMTCQuarterFrame(mess, syncAcc0){
  assert(MTC.isMTCQuarterFrame(mess.data.buffer))
  assert(MTC.checkInvariantSyncAcc(syncAcc0))

  return MTC.onMTCQuarterFrame(mess.data.buffer, syncAcc0)
}

// msg is Uint8Array
function onSysex(mess, syncAcc0) {
  assert(mess !== undefined)
  assert(MTC.checkInvariantSyncAcc(syncAcc0))

  return MTC.onSysex(mess.data.buffer, syncAcc0)
}




let prev = 0
const debugArr = []
function logSync(title, syncAcc){
  const val = syncAcc.syncSnapshot.posFramesDecimal
  const delta = val - prev
  debugArr.push({
    Title: title,
    syncAcc: JSON.stringify(syncAcc),
    delta: delta
  })
  prev = val
}


const listenForMidiMessages = (midiInput, handleSysex, handleTimeCode) => {
  midiInput?.addListener(
    'sysex',
    'all',
    handleSysex
  )

  //'timecode' = MTC Quarter frame message
  midiInput?.addListener(
    'timecode',
    'all',
    handleTimeCode
  )
}

// TODO: Split into two functions
const enableMidiAndListDevices = async () => {
  return new Promise(
    (resolve, reject) => {
      WebMidi.enable(
        (err) => {
          if (err) {
            console.log('WebMidi could not be enabled.', err)
            reject(err)
          } else {
            const inputsArray = WebMidi.inputs.map(input => input.name)
            const savedMIDIin = localStorage.getItem('spotterfishUserMIDIin') || ''
            if(inputsArray.length === 0){
              reject('No midi available')
            }
            // May have been stored under a different name - revert to first port
            const validId = WebMidi.getInputById(savedMIDIin)
            const selectedInput =  validId ? validId : WebMidi.getInputByName(inputsArray[0])
              
            resolve({
              selectedInput,
              inputsArray: WebMidi.inputs.map(input => input.name),
            })
          }
        },
        true
      )
    }
  )
}
  
const calcAudioDeviceArrays = (deviceInfos) => {
  const audioInputArray = []
  const audioOutputArray = []
  const videoInputArray = []
  try {
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      if (deviceInfo.kind === 'audioinput') {
        audioInputArray.push(deviceInfo)
      } else if (deviceInfo.kind === 'audiooutput') {
        audioOutputArray.push(deviceInfo)
      }
    }
  } catch (error) {
    throw new Error('Could not traverse devicearray')
  }
  return {
    audioInputArray,
    audioOutputArray,
    videoInputArray
  }
}

const getUserAudioStream = async () => {
  // Always read from local storage - or reset to defaults
  const audioSource = localStorage.getItem('spotterfishUserStreamingAudioIn')
  const videoSource = localStorage.getItem('spotterfishUserVideoIn')

  console.log('started inputs with', audioSource)

  const constraints = {
      audio: {
      deviceId: audioSource ? { exact: audioSource } : undefined,
      autoGainControl: false,
      channelCount: 2,
      echoCancellation: false,
      noiseSuppression: false
    }
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    return stream
  }
  catch(error){
    console.error('Error starting streams', error)
    throw error
  }
}


//??? TODO: ELiminate .then and .catch

const syncUserMediaSettings = async () => {
  return new Promise(async (resolve, reject) => {

    //  Returns web audio-stream
    try {
      await navigator.mediaDevices.getUserMedia(
        {
          audio: {
            autoGainControl: false,
            channelCount: 2,
            echoCancellation: false,
            noiseSuppression: false,
          }
        }
      )
    } catch (error) {
      throw error
    }
    
  
    // Get and push all devices to arrays
    const { audioInputArray, audioOutputArray, videoInputArray } = calcAudioDeviceArrays(await navigator.mediaDevices.enumerateDevices())
  
    // Select either the previously stored device, or store the first one on the list
    const selectedAudioIn = localStorage.getItem('spotterfishUserStreamingAudioIn') || audioInputArray[0].deviceId
    const selectedVideoIn = localStorage.getItem('spotterfishUserVideoIn') || videoInputArray?.[0]?.deviceId
    const selectedAudioOut = localStorage.getItem('spotterfishUserAudioOut') || audioOutputArray[0].deviceId
  
    localStorage.setItem('spotterfishUserStreamingAudioIn', selectedAudioIn)
    localStorage.setItem('spotterfishUserVideoIn', selectedVideoIn)
    localStorage.setItem('spotterfishUserAudioOut', selectedAudioOut)

    // Update the UI
    // this.selectedAudioInput = audioIn
    // this.selectedVideoInput = videoIn
    // this.selectedAudioOutput = audioOut
    // this.changeAudioDestination ()
  
    resolve({
      selectedAudioIn,
      selectedAudioOut,
      selectedVideoIn,
      audioInputArray,
      audioOutputArray,
      videoInputArray
    })
  })
}



// Simplified higher-order function for volume meter updates
const onVuMeterUpdate0 = (f) => (event) => {
  if (event.data.volume !== undefined) {
    const [volL, volR] = event.data.volume;
    // Assuming volume values are normalized between 0 and 1
    f(volL, volR);
  }
};

const attachVuMeterToStream = async (inputStream, onVuMeterUpdate) => {
  if (inputStream.getAudioTracks().length > 0) {
    const audioContext = new AudioContext();
    await audioContext.audioWorklet.addModule('/worklet_vumeter.js');
    const input = audioContext.createMediaStreamSource(inputStream);
    const node = new AudioWorkletNode(audioContext, 'vumeter', { outputChannelCount: [2] });

    node.port.onmessage = onVuMeterUpdate0(onVuMeterUpdate);
    input.connect(node);
    return { node, audioContext };
  }
  return undefined;
};

 const closeVuMeter0 = async (vuMeter) => {
   vuMeter.node.disconnect()
   vuMeter.node.port.close()
   await vuMeter.audioContext.close()
 }
/*
    if(node_?.port) {
      node_.port.postMessage({ message: 'change_source' })
    }
*/





const kConnectedStates = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected',
  CONNECTED_AND_STREAMING: 'connected-and-streaming'
}

const OpenProjectorDawStreamSession0 = ({ onVuMeterUpdate, onSyncSnapshot, onConnectionStateChange, gotDeviceInfo, firestore, firebase, user, getSyncOffset, getFrameRateIndex }) => {
  const kQuarterFrameTimeoutMS = (1000 / (30 * 4)) * 10

  let midiDevices_
  let syncAccNonReactive_ = MTC.makeMTCSyncAcc()
  let quarterFrameNonReactive_ = performance.now() - 10000
  let output_syncSnapShot_
  let syncLoopAnimationFrame_
  let audioStream_
  let deviceInfo_
  let vuMeter_
  let janusSession_
  let changesInStreamerListener_ = undefined
  let streamStarted_ = false
  let isCurrentStreamer_ = false

  let node_;


  const handleSysex = (mess) => {
    syncAccNonReactive_ = onSysex(mess, syncAccNonReactive_)
    logSync('onSysex', syncAccNonReactive_)
  }
  const handleTimeCode = (mess) => {
    syncAccNonReactive_ = onMTCQuarterFrame(mess, syncAccNonReactive_)
    quarterFrameNonReactive_ = performance.now()
    logSync('onQuarterFrame', syncAccNonReactive_)
  }

  const removeMIDIListeners = () => {
    midiDevices_.selectedInput.removeListener('sysex')
    midiDevices_.selectedInput.removeListener('timecode')
  }


  const changeMIDIinput = (value) => {
    // Remove all listeners for old input
    removeMIDIListeners()
    const sel = WebMidi.getInputByName(value) || { id: '' }
    midiDevices_.selectedInput = sel
    localStorage.setItem('spotterfishUserMIDIin', sel.id)
    listenForMidiMessages(midiDevices_.selectedInput, handleSysex, handleTimeCode)
  }

  const activateAudioStream = async (audioStream) => {
    assert (audioStream.getAudioTracks().length > 0)
    if (audioStream.getAudioTracks().length === 0) {
      throw Error()
    }

    assert(vuMeter_ == undefined)


    vuMeter_ = await attachVuMeterToStream(audioStream, onVuMeterUpdate)
    audioStream_ = audioStream
  }

  const closeAudioStream = async () => {
    assert (audioStream_ !== undefined)
    assert (vuMeter_ !== undefined)

    closeVuMeter0(vuMeter_)
    vuMeter_ = undefined

    audioStream_.getTracks().forEach(track => {
      console.log('stopping tracks')
      track.stop()
    })
    audioStream_ = undefined

  }


  const getAudioStream = () => {
    return audioStream_
  }


  const changeAudioInput = async (deviceId) => {
    localStorage.setItem('spotterfishUserStreamingAudioIn', deviceId)

    await CloudClient.call_CFsetOrDeleteActiveDAWStreamer(firebase, false)

    if(audioStream_ !== undefined) {
      await closeAudioStream()
    }

    await janusSession_?.unpublishOwnFeed()

    const audioStream = await getUserAudioStream()
    activateAudioStream(audioStream)

    await CloudClient.call_CFsetOrDeleteActiveDAWStreamer(firebase, true)
  }

  const syncLoop = () => {
    // console.log(syncAccNonReactive_.syncSnapshot)
    const now = performance.now()
    const timeoutFlag = now > (quarterFrameNonReactive_ + kQuarterFrameTimeoutMS)

    if(timeoutFlag && syncAccNonReactive_.syncSnapshot.playFlag === true){
      syncAccNonReactive_ = MTC.onSyncAccStop(syncAccNonReactive_)
    }


    if(_.isEqual(syncAccNonReactive_.syncSnapshot, output_syncSnapShot_) === false){
      output_syncSnapShot_ = _.cloneDeep(syncAccNonReactive_.syncSnapshot)
      onSyncSnapshot(output_syncSnapShot_)
    }
    window.requestAnimationFrame(syncLoop)
  }

  const disableWebMidi = () => {
    WebMidi.disable()
  }
  const disconnectJanus = () => {
    janusSession_?.destroy()
    streamStarted_ = false
  }

  const cleanup = async () => {
    changesInStreamerListener_()

    if(audioStream_ !== undefined){
      await closeAudioStream()
    }

    disableWebMidi()

    await CloudClient.call_CFsetOrDeleteActiveDAWStreamer(firebase, false)

    if(janusSession_ !== undefined){
      onStopStream()
    }
  }

  const destroy = async () => {
    changesInStreamerListener_()

    await closeAudioStream()
    disableWebMidi()

    await CloudClient.call_CFsetOrDeleteActiveDAWStreamer(firebase, false)

    await onStopStream()
  }

  const getSyncAcc = () => {
    return syncAccNonReactive_
  }




  const onStartStream = async (screeningRoomDBDID) => {
    console.log('Should start stream!')

    assert(streamStarted_ === false)

    onConnectionStateChange(kConnectedStates.CONNECTING, undefined)

    streamStarted_ = true
    const screeningRoom = await getScreeningRoomFromId(firestore, screeningRoomDBDID)
    console.log('got screening room', screeningRoom)
    const pin = await CloudClient.call_CFhashInput(firebase, screeningRoomDBDID)

    janusSession_ = DawStreamJanusSession({
      roomId: screeningRoomDBDID,
      screeningRoom,
      pin,
      user,
      getSyncAcc,
      getStream: getAudioStream,
      onConnecting: () => {
        onConnectionStateChange(kConnectedStates.CONNECTING, undefined)
      },
      getSyncOffset,
      getFrameRateIndex,
    })
    janusSession_.init(async () => {
      if(typeof onConnectionStateChange === 'function')  { 
        onConnectionStateChange(kConnectedStates.CONNECTED, screeningRoom) 
      }
      try {
        await detectStreamerSelection(firebase, screeningRoomDBDID, (streamer) => {
          if (screeningRoomDBDID && streamer === user.user_id){
            isCurrentStreamer_ = true
            onConnectionStateChange(kConnectedStates.CONNECTED_AND_STREAMING, undefined)
            janusSession_.unmute()
          }
          else{
            isCurrentStreamer_ = false
            onConnectionStateChange(kConnectedStates.CONNECTED, undefined)
            janusSession_.mute()
          }
        })
      } catch (error) {
        console.log('there is no shared state, we need to handle this')
        onStopStream()
      }
    })
  }

  const onStopStream = async () => {
      onConnectionStateChange(kConnectedStates.DISCONNECTED, undefined)
      disconnectJanus()
  }


  const onActiveDawStreamerChanged = async (activeStreamerDB) => {
    console.log('change in active streamer', activeStreamerDB)

    const screeningRoomDBDID = activeStreamerDB.current_screening_room_session
    if(screeningRoomDBDID === ''){
      onStopStream()
    }
    else {
      onStartStream(screeningRoomDBDID)
    }
  }


  //??? find all "catch", make sure they rethrow


  //??? fix error handling
  const initSecret = async () => {
    console.log('#### INIT #####')
    
    try {
      midiDevices_ = await enableMidiAndListDevices()
      listenForMidiMessages(midiDevices_.selectedInput, handleSysex, handleTimeCode)
    } catch {
      throw new Error('No midi devices available')
    }


    syncLoopAnimationFrame_ = window.requestAnimationFrame(syncLoop)

    const userMediaSettings = await syncUserMediaSettings()


    // Start the camera only at forst display and test
    const audioStream = await getUserAudioStream()
    activateAudioStream(audioStream)


    deviceInfo_ = {
      ...userMediaSettings,
      midi: midiDevices_
    }
    gotDeviceInfo(deviceInfo_)

    await CloudClient.call_CFsetOrDeleteActiveDAWStreamer(firebase, true)

    changesInStreamerListener_ = detectChangesInActiveDawStreamer(firestore, user.user_id, onActiveDawStreamerChanged)
  }

  const printDebugLog = () => {
    console.table(debugArr)
    return debugArr
  }

  const result = {
    initSecret,
    changeMIDIinput,
    changeAudioInput,
    disableWebMidi,
    destroy,
    printDebugLog,
    deviceInfo_,
    
  }
  assert(checkInvariantDawStreamerSession(result))
  return result
}


const OpenProjectorDawStreamSession2 = async (sessionData) => {
  const session = OpenProjectorDawStreamSession0(sessionData)
  try {
    await session.initSecret()
    return session
  }
  catch(error){
    //??? close session
    throw error
  }
}


export default {
  checkInvariantDawStreamerSession,
  listenForDawStreamers,
  OpenProjectorDawStreamSession2,
  kConnectedStates
}
