//@ts-nocheck
import App from '@/App.vue'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import { getUploadPathInfo } from '@/../source_files/web_client/WebClientImplementation'
import Constants from '@/../source_files/spotterfish_library/consts'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import { mimes } from '@/../source_files/spotterfish_library/utils/MimeTypes'
import FileInfo from '@/../source_files/spotterfish_library/FileInfo'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import CloudClient from '@/../source_files/web_client/CloudClient'

const findMimeType = (file) => {
  if(!file.type) throw new Error('This does not seem to be a supported file?')

  const matchingMime = mimes.find(mime => mime.m === file.type)

  if(matchingMime?.isSupported){
    return matchingMime.hasVideo
  }
  throw new Error(`This format ${file.type} is not supported, please convert to a supported format before uploading.`)
}

const getFileInfo = async (file) => {
  findMimeType(file)

  return await FileInfo.getFileInfo(file).catch(error => {
    throw new Error('Something went wrong')
  })
}

const prepareFileForUpload = async (file) => {
  const fileInfo = await getFileInfo(file)
  console.log({fileInfo})
  if(!fileInfo) return false

  const fileHasAudio = fileInfo.spotterfishAVSettings ? Boolean(fileInfo.spotterfishAVSettings.hasAudio) : true
  const fileHasVideo = Boolean(fileInfo?.spotterfishAVSettings?.hasVideo) || findMimeType(file)
  return {
    fileInfo,
    fileHasAudio,
    fileHasVideo
  }
}

const getFileHash = (file) => {
  return Math.abs(Array.from(file.name).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0))
}

const UploadsManager = () => {
  let allUploads = {}
  const get = (fileHash) => allUploads[fileHash]
  const getAll = () => Object.values(allUploads)
  const keys = () => Object.keys(allUploads)
  const set = (fileHash, data) => {
    if(!allUploads[fileHash]) allUploads[fileHash] = {}
    allUploads[fileHash] = {...allUploads[fileHash], ...data}
  }
  const remove = (fileHash) => {
    delete allUploads[fileHash]
  }
  const clear = () => {
    allUploads = {}
  }
  const pauseAll = () => {
    Object.values(allUploads).filter(upload => Boolean(upload?.uploadTask)).forEach(upload => upload.uploadTask.pause())
  }
  const cancelAll = () => {
    Object.values(allUploads).filter(upload => Boolean(upload?.uploadTask)).forEach(upload => upload.uploadTask.cancel())
  }
  const resumeAll = () => {
    Object.values(allUploads).filter(upload => Boolean(upload?.uploadTask)).forEach(upload => upload.uploadTask.resume())
  }
  return {
    get,
    getAll,
    keys,
    set,
    remove,
    clear,
    pauseAll,
    cancelAll,
    resumeAll
  }
}

export const MediaUploader = (uploadObject) => {
  
  const {
    fileList: _fileList, 
    projectId: _projectId, 
    dispatchAction: _dispatchAction, 
    onStatusChange: _onStatusChange, 
    spotterfishSession: _spotterfishSession,
    thumbnailImages: _thumbnailImages
  } = uploadObject
  const _currentUser = firebaseUserHandler.getLoggedInUserId() || ''
  let _uploadsManager = UploadsManager()

  const upload = async (mediaObject, projectId) => {
    const {file, fileHasAudio, fileHasVideo, fileInfo} = mediaObject
    const fileName = file.name
    const projectDB = await _dispatchAction('project_get', projectId)
    const fileObject = {
      parent_project: projectId,
      upload_date: new Date(),
      has_video: fileHasVideo,
      has_audio: fileHasAudio,
      video_frk: fileInfo.spotterfishAVSettings?.frk || Timecode.findFrameRateKeyFromNickname(25),
      file_name: fileName,
      file_metadata_dump: JSON.stringify(fileInfo),
      uploading_user: _currentUser,
      shared_with: projectDB.moderators.filter((mod) => { return mod !== _currentUser }),
      src: '',
    }
  
    let pathInfo
    try {
      pathInfo = await getUploadPathInfo(
        _spotterfishSession,
        fileName,
        projectId
      )
    } catch (error) {
      _onStatusChange('uploadPathError', error.message)
      return
    }
  
    const bucketName = pathInfo.bucketName
    const uploadPath = pathInfo.uploadPath
    const token = pathInfo.token
  
    const storage = App.Firebase.app().storage(`gs://${bucketName}`)
    const storageRef = storage.ref().child(uploadPath)
  
    const userId = firebaseUserHandler.getLoggedInUserId()
    const fileHash = getFileHash(file)
  
    const metadata = {
      customMetadata: {
        token: token,
        ownedby: userId,
        createdby: userId,
      },
    }
  
    const uploadTask = storageRef.put(file, metadata)
    uploadTask.on('state_changed', (sp) => {
      if (sp.state === 'paused') {
        _onStatusChange('paused')
      } else {
        _onStatusChange('uploadingInProgress', {
          fileHash,
          uploadProgress: sp.bytesTransferred / sp.totalBytes < 1 ? Math.floor((sp.bytesTransferred / sp.totalBytes) * 100) : undefined
        })
      }
    }, (error) => {
      console.log('Error uploading', error.code)
      if(error.code === 'storage/canceled'){
        // _uploadsManager.remove(fileHash)
        _uploadsManager.set(fileHash, {canceled: true})
        _onStatusChange('fileCanceled', {fileHash})
        if(_uploadsManager.getAll().reduce((acc, curr) => curr.canceled || curr.done || curr.error ? acc + 1 : acc, 0) === _fileList.length){
          _onStatusChange('uploadCanceled')
        } else {
          _onStatusChange('fileCanceled', {fileHash})
        }
      } else {
        _onStatusChange('firebaseUploadError', error)
      }
    }, () => {
      _uploadsManager.set(fileHash, { done: true })
      _onStatusChange('fileUploaded', {
        fileHash,
        file
      })

      // File uploads needs to be finished, AND the DB needs to be updated properly before we return.
      const i = setInterval(() => {
        if(_uploadsManager.getAll().reduce((acc, curr) => ( curr.done && curr.finalized ) || curr.canceled || curr.error ? acc + 1 : acc, 0) === _fileList.length){
          clearInterval(i)
          _onStatusChange('allFilesUploaded', { projectId, errors: _uploadsManager.getAll().some(upload => upload.error) })
        }
      }, 100)

    })

    _uploadsManager.set(fileHash, { uploadTask })
  
  
    try {
      // status = 'SAVING METADATA FOR FILE'
      fileObject.bucket_name = bucketName
      fileObject.path = uploadPath
      // ??? TODO - SHould be made a transaction instead
      const metadata = await uploadTask.snapshot.ref.getMetadata()
      fileObject.metadata = {
        contentType: metadata.contentType,
        size: metadata.size,
        customMetadata: metadata.customMetadata,
        timeCreated: metadata.timeCreated,
      }

      const fileId = await _dispatchAction('file_add', fileObject)
      fileObject['.key'] = fileId

      _uploadsManager.set(fileHash, { fileObject })
      
      await _dispatchAction('project_update_files', {
        projectUID: projectId,
        fileUID: fileId,
      })
      
      // ??? What is this?
      const projectKeyProp = _projectId
      
      if (projectKeyProp === undefined) {
        await _dispatchAction('project_update_user', {
          projectUID: projectId,
          userUID: _currentUser,
        })
        console.log('project updated and should be visible in users dashboard')
        
        SpotterfishSession.trackEvent(App.spotterfishSession, 'uploaded_file', 
          {
            userUID: _currentUser,
            projectUID: projectId,
            contentType: metadata.contentType,
            size: metadata.size,
          }
        )
  
        const rawObject = {
          created_date: new Date(),
          name: '001',
          description: '',
          video_file: fileId,
          audio_file: fileId,
          audio_samplerate: fileInfo.spotterfishAVSettings?.sampleRate || 48000,
          video_framerate: 25,
          version_timecode_offset_seconds: Constants.STANDARD_VERSION_TIMECODE_OFFSET_SECONDS,
          video_file_start_smpte: '00:00:00:00',
          audio_file_start_smpte: '00:00:00:00',
          // TODO - fix audio skew - does not work as it is
          // audio_file_start_smpte: audiosmpte
        }
  
        const versionId = await _dispatchAction('version_add', rawObject)

        await App.firestoreDB
          .collection('projects')
          .doc(projectId)
          .update({
            versions:
              App.Firebase.firestore.FieldValue.arrayUnion(
                versionId
              ),
            current_version: versionId,
          })
        
        // NOTICE: We need the projects to be fully updated and working before we return them on new uploads,
        // Adding this flag to make sure it is so
        _uploadsManager.set(fileHash, { finalized: true })
      } else {
        
        await _dispatchAction('project_update_files', {
            projectUID: projectId,
            fileUID: fileId,
          })
        SpotterfishSession.trackEvent(App.spotterfishSession, 'uploaded_file', 
          {
            userUID: _currentUser,
            projectUID: projectId,
            contentType: metadata.contentType,
            size: metadata.size,
          }
        )  
        console.log('file was updated with new metadata')
        // NOTICE: We need the projects to be fully updated and working before we return them on new uploads,
        // Adding this flag to make sure it is so
        _uploadsManager.set(fileHash, { finalized: true })
      }
    } catch (error) {
      console.log(error)
      SpotterfishSession.trackEvent(App.spotterfishSession, 'error_uploading_file', 
        {
          userUID: _currentUser,
          error: JSON.stringify(error)  
        }
      )
      let errorText = 'Something went wrong'
      switch (error.code) {
        case 'storage/unauthorized':
          errorText = 'You do not have permission to change this file/path'
          break
        case 'storage/canceled':
          errorText = 'Upload canceled'
          break
        case 'storage/unknown':
          errorText = 'Error, unknown'
          break
        case 'not-found':
          errorText = 'Not found'
          break
      }
      _onStatusChange('uploadError', {text: errorText, code: error.code})
    }  
  }

  const startUpload = () => {
    Object.values(_fileList).forEach(async (file, index) => {
      const _thumbnailImage = _thumbnailImages[index]
      const fileHash = getFileHash(file)
      _onStatusChange('fileAdded', {
        file,
        fileHash,
        preparing: true
      })
      let mediaObject = false;

      try{
        mediaObject = await prepareFileForUpload(file)
        console.log(mediaObject)
        _onStatusChange('prepareDone', {fileHash})
      } catch {
        _uploadsManager.set(fileHash, {
          error: true
        });

        _onStatusChange('addError', {fileHash})        
      }

      if(mediaObject !== false){
        let fileProjectId = _projectId
        if(!fileProjectId){
          // No project id, create new project::
          fileProjectId = await _dispatchAction('project_create_with_file_with_moderator', {
                moderators: [_currentUser],
                projectName: file.name,
                thumb: _thumbnailImage
              })
        }
        _uploadsManager.set(fileHash, {
          projectId: fileProjectId
        });
        upload({file, ...mediaObject}, fileProjectId)
      }
    })
  }

  const cancelUpload = async (fileHash) => {
    const fileUpload = _uploadsManager.get(fileHash)
    const uploadTask = fileUpload.uploadTask
    uploadTask.pause()
    uploadTask.cancel()
    try{
      await CloudClient.call_CFdeleteProject(App.Firebase, fileUpload.projectId)
      console.log(`===> project ${fileUpload.projectId} removed`)
    }
    catch (error){
      console.warn(`Could not delete project ${fileUpload.projectId}`)
      console.log(error)
      throw new Error('Could not delete project')
    }
  }

  const cancelAll = () => {
    let conf = confirm('Are you sure you wish to cancel this upload?')
    if(conf){
      const uploads = _uploadsManager.keys()
      uploads.forEach(fileHash => cancelUpload(fileHash))
    }
  }

  return {
    startUpload,
    cancelUpload,
    cancelAll
  }
}

