
const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const _ = require('lodash')
const Timecode = require('../spotterfish_library/utils/Timecode')


////////////////////////////////    Clock

/*
//  This is a value-type that describes timing at a moment


//  NOTICE: Length can be undefined
function createClock(frameRateKey, offset, length, position, playFlag){
  assert(Timecode.checkFrameRateKey(frameRateKey))
  assert(_.isNumber(offset))
  assert(length === undefined || (_.isNumber(length) && length >= 0))

  const result = {
    frameRateKey: frameRateKey,
    offset: offset,
    length: length,
    position: position,
    playFlag: playFlag
  }

  assert(checkClock(result))
  return result
}
*/

function checkClock(value){
//  assert(SC.isVueReactive(value) === false)
  assert(Timecode.checkFrameRateKey(value.videoFileFrameRateKey))
  assert(_.isNumber(value.videoFileOffset))
  assert(value.videoFileLengthOpt === undefined || (_.isNumber(value.videoFileLengthOpt) && value.videoFileLengthOpt >= 0))
  assert(value.position >= 0)

  assert(_.isBoolean(value.playFlag))
  return true
}

/*
function prove__createClock(){
  const k = createClock('PAL', 0, 0, 0, false)
  unitTestAssert(_.isEqual(k, { frameRateKey: 'PAL', offset: 0, length: 0, position: 0, playFlag: false }))
}
function prove__createClock2(){
  const k = createClock('NTSC', -5000, 1000, 500, true)
  unitTestAssert(_.isEqual(k, { frameRateKey: 'NTSC', offset: -5000, length: 1000, position: 500, playFlag: true }))
}
*/

function runUnitTests () {
  console.log('ClockValue.js -- START')

//  prove__createClock()
//  prove__createClock2()

  console.log('ClockValue.js -- END')
}

module.exports = {
 // createClock,
  checkClock,

  runUnitTests
}
