const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const SpotterfishHelpers = require('../spotterfish_library/SpotterfishHelpers')


/*
  // Exported functions that do not have a corresponding wakeup_ function:
  call_CFStripeGetPaymentLinks
  call_CFaddAnonymousUserToRoom
  
  call_CFaddJournalEntry
  call_CFcheckIfCurrentUserIsSpotterfishStaff
  call_CFcheckIfUserAuthenticatedWithinLast45Minutes
  call_CFcheckRoomVerificationCode
  call_CFcreateDemoProjects
  call_CFdeleteProject
  call_CFgetAccountTier
  call_CFgetAllUsersData
  call_CFgetAllUsersDataAsAccountArray
  call_CFgetUserJournalReport
  call_CFgetUserObject
  call_CFsgetUserObjectsFromProjectModerators
  call_CFmakeUserTier1
  call_CFmakeUserTier2
  call_CFMakeUserDawStreamer
  call_CFupgradeToEra4Beta
  call_CFremoveSecondFactorFromUser
  call_CFrestoreVersionFromJSONArchive
  call_CFsearchUsers
  call_CFuserSelfUpgradeAccountToTier2
  call_CFsendPasscodeSMS
  call_CFsendPasswordReset
  call_CFsendUserInvite
  call_CFsendSpotterfishInvite
  call_CFsendVerificationEmail
  call_CFsendWelcomeEmailWithLinkToNewRoom
  call_CFremoveFromProject
  call_CFStripeCreateCheckoutSession
  
  
*/

async function call_wakeUpAllCloudFUnctions(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

     wakeup_CFaddUsersToSeats(firebase)
     wakeup_CFallocateSession(firebase)
     wakeup_CFauthRedirect(firebase)
     wakeup_CFcalculateAccountFeatureBitsReadOnly(firebase)
     wakeup_CFcalculateFeatureBitsForRoom(firebase)
     wakeup_CFgetUploadPathInfo(firebase)
     wakeup_CFgetUsersList(firebase)
     wakeup_CFgetUsersVerified(firebase)
     wakeup_CFhashInput(firebase)
     wakeup_CFinitializeUser(firebase)
     wakeup_CFremoveUserFromScreeningRoomLobby(firebase)
     wakeup_CFremoveUserFromSeatAndSharedState(firebase)
     wakeup_CFrequestAccessToScreeningRoom(firebase)
     wakeup_CFsetSessionProject(firebase)
     wakeup_CFgetProjectVersionAndFiles(firebase)
     wakeup_CFupdateProjectAndVersion(firebase)
     wakeup_CFshortenUrl(firebase)
    
     wakeup_saveAndCreateNewMarkerSession(firebase)
     wakeup_CFsetOrDeleteActiveDAWStreamer(firebase)
     wakeup_CFupdateActiveDAWStreamer(firebase)
     wakeup_CFcreateProjectAndVersion(firebase)
     wakeup_CFshareProjectModerator(firebase)
     wakeup_CFremoveProjectModerator(firebase)
     wakeup_CFshareProject(firebase)
     wakeup_CFselectDAWStreamerForRoom(firebase)
     wakeup_CFsetUserClaims(firebase)
     wakeup_CFgetaccountInfoIfEmailExist(firebase)
     wakeup_CFgetFileURLs(firebase)
     wakeup_CFcreateStripePortalSession(firebase)

}

async function call_CFStripeGetPaymentLinks(firebase) {
  const result = await firebase.functions().httpsCallable('CFStripeGetPaymentLinks')()
  return result.data
}

async function call_CFaddAnonymousUserToRoom(firebase, userId, roomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(roomId))

  const result = await firebase.functions().httpsCallable('CFaddAnonymousUserToRoom')({
    userId: userId,
    roomId: roomId
  })

  return result.data
}

async function wakeup_CFselectDAWStreamerForRoom(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFselectDAWStreamerForRoom')('wakeup')
}

async function call_CFselectDAWStreamerForRoom(firebase, userId, roomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(roomId))

  const result = await firebase.functions().httpsCallable('CFselectDAWStreamerForRoom')({
    userId: userId,
    roomId: roomId
  })

  return result.data
}

// TODO: Support array of entries.
async function call_CFaddJournalEntry(firebase, userId, journalEntry) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(journalEntry))

  const result = await firebase.functions().httpsCallable('CFaddJournalEntry')({
    userId: userId,
    journalEntry: journalEntry
  })

  return result.data
}



async function call_CFaddUsersToSeats(firebase, userIdsArray, chairIndex, screeningRoomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isArrayInstance(userIdsArray))
  assert(SpotterfishCore.isNumberInstance(chairIndex) || chairIndex === undefined)
  assert(SpotterfishCore.isStringInstance(screeningRoomId))

  const result = await firebase.functions().httpsCallable('CFaddUsersToSeats')({
    userIdStringOrArray: userIdsArray,
    chairIndex: chairIndex,
    screeningRoomId: screeningRoomId
  })

  return result.data
}

async function wakeup_CFaddUsersToSeats(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  
  firebase.functions().httpsCallable('CFaddUsersToSeats')('wakeup')
}


// Open screening room session or join existing one
// returns a spotterfishSessionDB
// TODO: Use pre-defined errors/error strings instead, import from e.g. SpotterfishCore
// ??? use transactions.
async function call_CFallocateSession(firebase, roomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(roomId))

  const CFallocateSession = firebase.functions().httpsCallable('CFallocateSession')
  //  Result is a cloud function value, it has member "data" with our actual result. { data = our stuff, ...? }
  const result0 = await CFallocateSession({ roomId: roomId })

  if (result0.data === undefined) {
    throw new Error('Illegal')
  }

  // This is our return value, format is { status, message, data }.
  const result1 = result0.data

  if (result1.status === 'ok') {

    // assert(checkInvariantScreeningRoomSessionDB(result1.data))
    assert(result1.message === '')

    return result1.data
  } else if (result1.status === 'error') {
    assert(result1.data === undefined)
    assert(SpotterfishCore.isStringInstance(result1.message))

    throw new Error(result1.message)
  } else {
    // ??? 2021-09-14 MZ: This is old comment, we can't understand it. Tiny risk we introduced problem by ignoring it.
    // NOTE: Cloud function sometimes wraps result in an extra DATA object.
    // Make sure the returned session has the correct format.
    throw new Error('Illegal')
  }
}

async function wakeup_CFallocateSession(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFallocateSession')('wakeup')
}

async function call_CFauthRedirect(firebase, loggedInURL, loggedOutURL) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(loggedInURL))
  assert(SpotterfishCore.isStringInstance(loggedOutURL))

  const result = await firebase.functions().httpsCallable('CFauthRedirect')({
    loggedInURL: loggedInURL,
    loggedOutURL: loggedOutURL
  })

  return result.data
}

async function wakeup_CFauthRedirect(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFauthRedirect')('wakeup')
}

// ??? more error checking, thunking
// ??? use transactions.
async function call_CFcalculateAccountFeatureBitsReadOnly(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFcalculateAccountFeatureBitsReadOnly')()

  return result.data
}

// ??? more error checking, thunking
// ??? use transactions.
async function wakeup_CFcalculateAccountFeatureBitsReadOnly(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFcalculateAccountFeatureBitsReadOnly')('wakeup')
}

async function call_CFGetOrAddStripeAccountForUser(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  console.log('calling CFGetOrAddStripeAccountForUser')
  const result = await firebase.functions().httpsCallable('CFGetOrAddStripeAccountForUser')()

  return result.data
}

async function wakeup_CFGetOrAddStripeAccountForUser(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFGetOrAddStripeAccountForUser')('wakeup')
}

async function call_CFcalculateFeatureBitsForRoom(firebase, screeningRoomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(screeningRoomId))

  const result = await firebase.functions().httpsCallable('CFcalculateFeatureBitsForRoom')(screeningRoomId)

  return result.data
}

async function wakeup_CFcalculateFeatureBitsForRoom(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFcalculateFeatureBitsForRoom')('wakeup')
}

async function call_CFcheckIfCurrentUserIsSpotterfishStaff(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFcheckIfCurrentUserIsSpotterfishStaff')()

  return result.data
}

async function call_CFcheckIfUserAuthenticatedWithinLast45Minutes(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFcheckIfUserAuthenticatedWithinLast45Minutes')()

  return result.data
}

async function call_CFcheckRoomVerificationCode(firebase, screeningRoomId, verificationCode) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(screeningRoomId))
  assert(SpotterfishCore.isStringInstance(verificationCode))

  const result = await firebase.functions().httpsCallable('CFcheckRoomVerificationCode')({
    screeningRoomId: screeningRoomId,
    verificationCode: verificationCode
  })

  return result.data
}

async function call_CFcreateDemoProjects(firebase, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))

  const result = await firebase.functions().httpsCallable('CFcreateDemoProjects')({
    userId: userId
  })

  return result.data
}

async function call_CFdeleteProject(firebase, projectId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(projectId))

  try {
    const result = await firebase.functions().httpsCallable('CFdeleteProject')(projectId)
    return result.data
    
  } catch (error) {
    console.log(error)
    throw error    
  }
}

async function call_CFsyncRoomFromUBD(firebase, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))

  const result = await firebase.functions().httpsCallable('CFsyncRoomFromUBD')({
    userId: userId
  })

  return result.data
}

async function call_CFsoftDeleteFile(firebase, projectId, fileId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(SpotterfishCore.isStringInstance(fileId))

  const result = await firebase.functions().httpsCallable('CFsoftDeleteFile')({ projectId: projectId, fileId: fileId })

  return result.data
}

async function call_CFgetAccountTier(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFgetAccountTier')()
  return result.data
}

async function call_CFgetAllUsersData(firebase, format) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(format))

  const result = await firebase.functions().httpsCallable('CFgetAllUsersData')(format)

  return result.data
}

async function call_CFgetAllUsersDataAsAccountArray (firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  const result = await firebase.functions().httpsCallable('CFgetAllUsersDataAsAccountArray')()
  return result.data
}

async function call_CFgetUploadPathInfo(firebase, fileName, projectId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(fileName))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(fileName.length > 0)
  assert(projectId.length > 0)

  const result = await firebase.functions().httpsCallable('CFgetUploadPathInfo')({
    fileName: fileName,
    projectId: projectId
  })

  const resultObj = result.data

  if (resultObj.status === undefined || resultObj.data === undefined) {
    // TODO: Use standardised error code/message.
    throw new Error('Could not get upload path')
  }

  if (resultObj.status === 'error') {
    // TODO: Use standardised error code/message.
    throw new Error(resultObj.data)
  }

  assert(resultObj.data.bucketName !== undefined)
  assert(resultObj.data.uploadPath !== undefined)
  assert(resultObj.data.token !== undefined)

  return resultObj.data
}

async function wakeup_CFgetUploadPathInfo(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFgetUploadPathInfo')('wakeup')
}

async function call_CFgetUserJournalReport(firebase, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))

  const result = await firebase.functions().httpsCallable('CFgetUserJournalReport')(userId)

  return result.data
}

async function call_CFgetUserObject(firebase, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))

  const result = await firebase.functions().httpsCallable('CFgetUserObject')(userId)

  return result.data
}

async function call_CFgetMultipleUserObjects(firebase, userIds, screeningRoomDBID) {
  assert(SpotterfishCore.isArrayInstance(userIds))
  console.log(userIds)
  userIds.forEach(userId => assert(SpotterfishCore.isStringInstance(userId)))
  
  const result = await firebase.functions().httpsCallable('CFgetMultipleUserObjects')({ userIds: userIds, screeningRoomDBID: screeningRoomDBID })

  return result.data
}

async function wakeup_CFgetMultipleUserObjects(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFgetMultipleUserObjects')('wakeup')
}

async function call_CFgetUsersList(firebase, userList) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isArrayInstance(userList))

  const result = await firebase.functions().httpsCallable('CFgetUsersList')(userList)

  return result.data
}

async function wakeup_CFgetUsersList(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFgetUsersList')('wakeup')
}

async function call_CFgetUsersVerified(firebase, userList) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isArrayInstance(userList))

  const result = await firebase.functions().httpsCallable('CFgetUsersVerified')(userList)

  return result.data
}

async function wakeup_CFgetUsersVerified(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFgetUsersVerified')('wakeup')
}

async function call_CFhashInput(firebase, data) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(data))

  const result = await firebase.functions().httpsCallable('CFhashInput')(data)

  return result.data
}

async function wakeup_CFCreateWhipEndpoint(firebase) {
  firebase.functions().httpsCallable('CFCreateWhipEndpoint')('wakeup')
}

async function call_CFCreateWhipEndpoint(firebase, data) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(data))
  const result = await firebase.functions().httpsCallable('CFCreateWhipEndpoint')(data)
  console.log('as returned from CFCreateWhipEndpoint', result.data)
  return result.data
}

async function wakeup_CFDestroyWhipEndpoint(firebase) {
  firebase.functions().httpsCallable('CFDestroyWhipEndpoint')('wakeup')
}

async function call_CFDestroyWhipEndpoint(firebase, data) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(data))
  const result = await firebase.functions().httpsCallable('CFDestroyWhipEndpoint')(data)
  console.log('as returned from CFDestroyWhipEndpoint', result.data)
  return result.data
}


async function wakeup_CFhashInput(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFhashInput')('wakeup')
}

async function call_CFinitializeUser(firebase, userObject, invitedBy, anonymousIdTokenOptional) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(userObject))
  assert(SpotterfishCore.isStringInstance(invitedBy))
  assert(anonymousIdTokenOptional === undefined || SpotterfishCore.isStringInstance(anonymousIdTokenOptional))
  console.warn('calling CFinitializeUser with data', userObject)
  const result = await firebase.functions().httpsCallable('CFinitializeUser')({
    userObject: userObject,
    invitedBy: invitedBy,
    anonymousIdTokenOptional: anonymousIdTokenOptional
  })

  return result.data
}

async function wakeup_CFinitializeUser(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFinitializeUser')({ userObject: 'wakeup' })
}

async function call_CFmakeUserTier1(firebase, userId, note) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(note))

  const result = await firebase.functions().httpsCallable('CFmakeUserTier1')({
    userId: userId,
    note: note
  })

  return result.data
}

async function call_CFmakeUserTier2(firebase, userId, note) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(note))

  const result = await firebase.functions().httpsCallable('CFmakeUserTier2')({
    userId: userId,
    note: note
  })

  return result.data
}
async function call_CFMakeUserDawStreamer(firebase, userId, note) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(note))

  const result = await firebase.functions().httpsCallable('CFMakeUserDawStreamer')({
    userId: userId,
    note: note
  })

  return result.data
}

async function call_CFupgradeToEra4Beta(firebase, note) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(note))

  const result = await firebase.functions().httpsCallable('CFupgradeToEra4Beta')({
    note: note
  })

  return result.data
}

async function call_CFremoveSecondFactorFromUser(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFremoveSecondFactorFromUser')()

  return result.data
}

async function call_CFremoveUserFromScreeningRoomLobby(firebase, roomId, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(roomId))
  assert(SpotterfishCore.isStringInstance(userId))

  const result = await firebase.functions().httpsCallable('CFremoveUserFromScreeningRoomLobby')({
    room: roomId,
    userId: userId
  })

  return result.data
}

async function wakeup_CFremoveUserFromScreeningRoomLobby(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFremoveUserFromScreeningRoomLobby')('wakeup')
}

async function call_CFremoveUserFromSeatAndSharedState(firebase, screeningRoomId, userIdStringOrArray) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(screeningRoomId))
  assert(SpotterfishCore.isStringInstance(userIdStringOrArray) || SpotterfishCore.isArrayInstance(userIdStringOrArray))

  const result = await firebase.functions().httpsCallable('CFremoveUserFromSeatAndSharedState')({
    screeningRoomId: screeningRoomId,
    userIdStringOrArray: userIdStringOrArray
  })

  return result.data
}

async function wakeup_CFremoveUserFromSeatAndSharedState(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFremoveUserFromSeatAndSharedState')('wakeup')
}

async function call_CFrequestAccessToScreeningRoom(firebase, screeningRoomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFrequestAccessToScreeningRoom')({
    screeningRoomId: screeningRoomId
  })

  return result.data
}

async function wakeup_CFrequestAccessToScreeningRoom(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFrequestAccessToScreeningRoom')('wakeup')
}

async function call_CFrestoreVersionFromJSONArchive(firebase, project, archivedVersion) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(project))
  assert(SpotterfishCore.isObjectInstance(archivedVersion))

  const result = await firebase.functions().httpsCallable('CFrestoreVersionFromJSONArchive')({
    project: project,
    archivedVersion: archivedVersion
  })

  return result.data
}

async function call_CFsearchUsers(firebase, searchString) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(searchString))

  const result = await firebase.functions().httpsCallable('CFsearchUsers')(searchString)

  return result.data
}

// gets user objects for people invited to project => returns array of object
async function call_CFsgetUserObjectsFromProjectModerators (firebase, projectDB) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(projectDB))

  const result = await firebase.functions().httpsCallable('CFsgetUserObjectsFromProjectModerators')(projectDB)

  return result.data
}

async function call_CFuserSelfUpgradeAccountToTier2(firebase, data) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(data))
  assert(SpotterfishCore.isStringInstance(data.name))
  assert(SpotterfishCore.isStringInstance(data.email))
  assert(SpotterfishCore.isStringInstance(data.subscriptionType))
  assert(SpotterfishCore.isStringInstance(data.legalEntity))

  const result = await firebase.functions().httpsCallable('CFuserSelfUpgradeAccountToTier2')(data)

  return result.data
}

async function call_CFsendPasscodeSMS(firebase, roomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(roomId))

  const result = await firebase.functions().httpsCallable('CFsendPasscodeSMS')(roomId)

  return result.data
}

async function call_CFsendPasswordReset(firebase, email) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishHelpers.isValidEmail(email))

  const result = await firebase.functions().httpsCallable('CFsendPasswordReset')(email)

  return result.data
}

async function call_CFsendUserInvite(firebase, email, roomId, base_url) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishHelpers.isValidEmail(email))
  assert(SpotterfishCore.isStringInstance(roomId))
  assert(SpotterfishCore.isStringInstance(base_url))


  const result = await firebase.functions().httpsCallable('CFsendUserInvite')({
    email: email,
    screeningRoom: roomId,
    base_url: base_url
  })

  return result.data
}

async function call_CFshortenUrl(firebase, url) {
  assert(SpotterfishCore.isStringInstance(url))

  const result = await firebase.functions().httpsCallable('CFshortenUrl')(url)

  return result.data
}

async function wakeup_CFshortenUrl(firebase) {
  firebase.functions().httpsCallable('CFshortenUrl')('wakeup')
}

async function call_CFsendSpotterfishInvite(firebase, email, projectId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishHelpers.isValidEmail(email))
  assert(SpotterfishCore.isStringInstance(projectId))

  const result = await firebase.functions().httpsCallable('CFsendSpotterfishInvite')({
    email,
    projectId
  })

  return result.data
}

async function call_CFsendVerificationEmail(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  const result = await firebase.functions().httpsCallable('CFsendVerificationEmail')()

  return result.data
}

async function call_CFsendWelcomeEmailWithLinkToNewRoom(firebase, email, roomId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishHelpers.isValidEmail(email))
  assert(SpotterfishCore.isStringInstance(roomId))

  const result = await firebase.functions().httpsCallable('CFsendWelcomeEmailWithLinkToNewRoom')({
    email: email,
    screeningRoom: roomId
  })

  return result.data
}

async function wakeup_saveAndCreateNewMarkerSession(firebase) {
  firebase.functions().httpsCallable('CFsaveAndCreateNewMarkerSession')('wakeup')
}

async function call_saveAndCreateNewMarkerSession(firebase, projectId, textInput) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(SpotterfishCore.isStringInstance(textInput))

  const result = await firebase.functions().httpsCallable('CFsaveAndCreateNewMarkerSession')({ projectId, textInput })
  console.log(result.data)
  return result.data
}

async function call_CFsetSessionProject(firebase, roomId, projectId, expiryTime) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(roomId))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(SpotterfishCore.isNumberInstance(expiryTime))

  const result = await firebase.functions().httpsCallable('CFsetSessionProject')({
    roomId: roomId,
    projectId: projectId,
    expiryTime: expiryTime
  })

  return result.data
}

async function wakeup_CFsetSessionProject(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFsetSessionProject')('wakeup')
}

async function call_CFsetOrDeleteActiveDAWStreamer(firebase, onOffSwitch) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isBooleanInstance(onOffSwitch))
  const result = await firebase.functions().httpsCallable('CFsetOrDeleteActiveDAWStreamer')({onOff: onOffSwitch})
  console.log(result)
  return result.data
}

async function wakeup_CFsetOrDeleteActiveDAWStreamer(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFsetOrDeleteActiveDAWStreamer')('wakeup')
}

async function call_CFupdateActiveDAWStreamer (firebase, screeningRoomId, onOffSwitch) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(screeningRoomId))
  assert(SpotterfishCore.isBooleanInstance(onOffSwitch))
  console.log(`call_CFupdateActiveDAWStreamer caled with data ${screeningRoomId} and onoffSwitch ${onOffSwitch}`)
  const result = await firebase.functions().httpsCallable('CFupdateActiveDAWStreamer')({screeningRoomId: screeningRoomId, onOffSwitch: onOffSwitch})
  return result.data
}

async function wakeup_CFupdateActiveDAWStreamer(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFupdateActiveDAWStreamer')('wakeup')
}

async function call_CFgetProjectVersionAndFiles(firebase, projectId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(projectId))

  const result = await firebase.functions().httpsCallable('CFgetProjectVersionAndFiles')({
    projectId: projectId
  })
  console.log(result.data)
  return result.data
}

async function wakeup_CFgetProjectVersionAndFiles(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFgetProjectVersionAndFiles')('wakeup')
}

async function call_CFcreateProjectAndVersion(firebase) {
  console.log('firebase is object instance')
  assert(SpotterfishCore.isObjectInstance(firebase))
  console.log('create project and version called')
  const result = await firebase.functions().httpsCallable('CFcreateProjectAndVersion')()
  console.log('got result, ' + result.data)
  return result.data
}

async function wakeup_CFcreateProjectAndVersion(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  firebase.functions().httpsCallable('CFcreateProjectAndVersion')('wakeup')
}

async function call_CFupdateProjectAndVersion(firebase, projectDB, versionDB) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(projectDB))
  assert(SpotterfishCore.isObjectInstance(versionDB))

  await firebase.functions().httpsCallable('CFupdateProjectAndVersion')({
    projectDB: projectDB,
    versionDB: versionDB
  })
  return
}

async function wakeup_CFupdateProjectAndVersion(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  const result = await firebase.functions().httpsCallable('CFgetProjectVersionAndFiles')('wakeup')
  return result.data
}


async function call_CFshareProjectModerator(firebase, userId, projectId, base_url) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(SpotterfishCore.isStringInstance(base_url))

  console.log(`Got a request to add moderator to project: ${projectId} with user: ${userId}`)
  const result = await firebase.functions().httpsCallable('CFshareProjectModerator')({
    userId,
    projectId,
    base_url
  })
  return result.data
}
async function wakeup_CFshareProjectModerator(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFshareProjectModerator')('wakeup')
}

async function call_CFremoveProjectModerator(firebase, userId, projectId, base_url) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(projectId))
  console.log(`Got a request to remove moderator from project: ${projectId} with user: ${userId}`)
  const result = await firebase.functions().httpsCallable('CFremoveProjectModerator')({
    userId,
    projectId,
    base_url
  })
  return result.data
}
async function wakeup_CFremoveProjectModerator(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFremoveProjectModerator')('wakeup')
}


async function call_CFshareProject(firebase, email, projectId, base_url) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(email))
  assert(SpotterfishCore.isStringInstance(projectId))
  assert(SpotterfishCore.isStringInstance(base_url))

  console.log(`Got a request to share project ${projectId} with user with ${email}`)
  const result = await firebase.functions().httpsCallable('CFshareProject')({
    email: email,
    projectId: projectId,
    base_url: base_url
  })
  console.log(result)
  return result.data
}

async function wakeup_CFshareProject(firebase) {
  assert(SpotterfishCore.isObjectInstance(firebase))

  firebase.functions().httpsCallable('CFshareProject')('wakeup')
}

async function call_CFremoveFromProject(firebase, userId, projectId){
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(projectId))
  console.log(`Got a request to remove user ${userId} from project ${projectId}`)
  const result = await firebase.functions().httpsCallable('CFremoveFromProject')({
    userId: userId,
    projectId: projectId
  })
  console.log(result)
  return result.data
}


async function wakeup_CFsetUserClaims(firebase) {
  await firebase.functions().httpsCallable('CFsetUserClaims')('wakeup')
}

async function call_CFsetUserClaims(firebase, email, password) {
  console.log(email)
  console.log(password)
  const result = await firebase.functions().httpsCallable('CFsetUserClaims')({
    email,
    password
  })
  console.log(result)
  return result.data
}

async function wakeup_CFgetaccountInfoIfEmailExist(firebase, email) {
  await firebase.functions().httpsCallable('CFgetaccountInfoIfEmailExist')('wakeup')
}

async function call_CFgetaccountInfoIfEmailExist(firebase, email) {
  console.log(email)
  const result = await firebase.functions().httpsCallable('CFgetaccountInfoIfEmailExist')({
    email
  })
  console.log(result)
  return result.data
}

async function wakeup_CFgetFileURLs (firebase) {
  firebase.functions().httpsCallable('CFgetFileURLs')('wakeup')  
}

async function call_CFgetFileURLs (firebase, projectId, fileId) {
  console.log(firebase)
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(projectId))

  console.log('calling firebase function CFgetFileURLs')
  const result = await firebase.functions().httpsCallable('CFgetFileURLs')({
    projectId: projectId
  })
  console.log(result)
  return result.data
}

async function wakeup_CFcreateStripePortalSession(firebase) {
  await firebase.functions().httpsCallable('CFcreateStripePortalSession')('wakeup')
}

async function call_CFcreateStripePortalSession(firebase, action) {
  console.log('calling firebase function CFcreateStripePortalSession')
  const result = await firebase.functions().httpsCallable('CFcreateStripePortalSession')({ action: action })
  console.log(result.data)
  return result.data
}

async function call_CFStripeCreateCheckoutSession(firebase, priceItem, additionalUsers, allow_promotion_codes, returnUrl){ 
  console.log('calling firebase function CFStripeCreateCheckoutSession')
  const result = await firebase.functions().httpsCallable('CFStripeCreateCheckoutSession')({
    priceItem,
    additionalUsers,
    allow_promotion_codes,
    returnUrl
  })
  console.log(result.data)
  return result.data
}

async function call_CFgetStripeUserInfo(firebase) {
  console.log('calling firebase function CFcreateStripePortalSession')
  const result = await firebase.functions().httpsCallable('CFgetStripeUserInfo')()
  console.log(result.data)
  return result.data
}

async function call_CFgetIntercomData(firebase) {
  console.log('calling firebase function CFgetIntercomData')
  const result = await firebase.functions().httpsCallable('CFgetIntercomData')()
  console.log(result.data)
  return result.data
}


module.exports = {
  call_wakeUpAllCloudFUnctions,

  call_CFStripeGetPaymentLinks,
  call_CFaddAnonymousUserToRoom,
  
  call_CFselectDAWStreamerForRoom,

  call_CFaddJournalEntry,

  call_CFaddUsersToSeats,
  wakeup_CFaddUsersToSeats,

  call_CFallocateSession,
  wakeup_CFallocateSession,

  call_CFauthRedirect,
  wakeup_CFauthRedirect,

  call_CFcalculateAccountFeatureBitsReadOnly,
  wakeup_CFcalculateAccountFeatureBitsReadOnly,

  call_CFGetOrAddStripeAccountForUser,
  wakeup_CFGetOrAddStripeAccountForUser,

  call_CFcalculateFeatureBitsForRoom,
  wakeup_CFcalculateFeatureBitsForRoom,

  call_CFcheckIfCurrentUserIsSpotterfishStaff,

  call_CFcheckIfUserAuthenticatedWithinLast45Minutes,

  call_CFcheckRoomVerificationCode,

  call_CFcreateDemoProjects,

  call_CFdeleteProject,

  call_CFsyncRoomFromUBD,

  call_CFsoftDeleteFile,

  call_CFgetAccountTier,

  call_CFgetAllUsersData,

  call_CFgetAllUsersDataAsAccountArray,

  call_CFgetUploadPathInfo,
  wakeup_CFgetUploadPathInfo,

  call_CFgetUserJournalReport,

  call_CFgetUserObject,

  call_CFgetMultipleUserObjects,
  wakeup_CFgetMultipleUserObjects,

  call_CFsgetUserObjectsFromProjectModerators,

  call_CFgetUsersList,
  wakeup_CFgetUsersList,

  call_CFgetUsersVerified,
  wakeup_CFgetUsersVerified,

  call_CFhashInput,
  wakeup_CFhashInput,
  
  call_CFCreateWhipEndpoint,
  wakeup_CFCreateWhipEndpoint,

  call_CFDestroyWhipEndpoint,
  wakeup_CFDestroyWhipEndpoint,

  call_CFinitializeUser,
  wakeup_CFinitializeUser,

  call_CFmakeUserTier1,

  call_CFmakeUserTier2,

  call_CFMakeUserDawStreamer,

  call_CFupgradeToEra4Beta,

  call_CFremoveSecondFactorFromUser,

  call_CFremoveUserFromScreeningRoomLobby,
  wakeup_CFremoveUserFromScreeningRoomLobby,

  call_CFremoveUserFromSeatAndSharedState,
  wakeup_CFremoveUserFromSeatAndSharedState,

  call_CFrequestAccessToScreeningRoom,
  wakeup_CFrequestAccessToScreeningRoom,

  call_CFrestoreVersionFromJSONArchive,

  call_CFsearchUsers,

  call_CFuserSelfUpgradeAccountToTier2,

  call_CFsendPasscodeSMS,

  call_CFsendPasswordReset,

  call_CFshortenUrl,
  wakeup_CFshortenUrl,

  call_CFsendUserInvite,
  call_CFsendSpotterfishInvite,

  call_CFsendVerificationEmail,

  call_CFsendWelcomeEmailWithLinkToNewRoom,

  wakeup_saveAndCreateNewMarkerSession,
  call_saveAndCreateNewMarkerSession,

  call_CFsetSessionProject,
  wakeup_CFsetSessionProject,

  call_CFsetOrDeleteActiveDAWStreamer,
  wakeup_CFsetOrDeleteActiveDAWStreamer,

  call_CFupdateActiveDAWStreamer,
  wakeup_CFupdateActiveDAWStreamer,

  call_CFgetProjectVersionAndFiles,
  wakeup_CFgetProjectVersionAndFiles,

  call_CFcreateProjectAndVersion,
  wakeup_CFcreateProjectAndVersion,

  call_CFupdateProjectAndVersion,
  wakeup_CFupdateProjectAndVersion,

  call_CFshareProjectModerator,
  wakeup_CFshareProjectModerator,

  call_CFremoveProjectModerator,
  wakeup_CFremoveProjectModerator,

  call_CFshareProject,
  wakeup_CFshareProject,

  call_CFremoveFromProject,

  
  call_CFsetUserClaims,
  
  call_CFgetaccountInfoIfEmailExist,
  
  call_CFgetFileURLs,
  
  call_CFcreateStripePortalSession,
  call_CFStripeCreateCheckoutSession,

  call_CFgetStripeUserInfo,

  call_CFgetIntercomData

}
