<template>
  <div class="outer-container">
    <div class="progress-slider-container" @mouseup="handleMouseUp" @touchend="handleTouchEnd">
      <input
        type="range"
        :id="id"
        class="progress-slider"
        :value="value"
        :min="0"
        :max="max"
        :step="step"
        @input="handleInput($event)"
        @mousedown="handleMouseDown"
        @touchstart="handleTouchStart"
        :disabled="disabled"
      />
      <div v-if="isDragging" class="thumb-label" :style="{ left: thumbLabelPosition + '%' }">
        {{ thumbLabelContent }}
      </div>
    </div>
    <canvas id="buffered" ref="canvasRef" height="5" :style="canvasStyle"></canvas>
</div>
</template>


<script>
//@ts-nocheck
import { inject, ref, computed, watch, onMounted } from 'vue';
import videojs from 'video.js'

export default {
  name: 'ProgressSlider',
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1/25, // Adjust this according to the frame rate or granularity you desire
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: String,
    thumbLabelContent: String,
  },
  setup(props, { emit }) {
    const colors = require('@/lib/ui/colors.js').colors
    const templateReactiveData = inject('templateReactiveData')

    const isDragging = ref(false)
    const canvasRef = ref(null)
    const videojsPlayer = ref(null)

    const canvasStyle = computed(() => {
      if (templateReactiveData.value.UI_transportWindowSize) {
        const windowWidth = parseInt(templateReactiveData.value.UI_transportWindowSize.width, 10);
        const canvasWidth = windowWidth - 40
        console.log({canvasWidth})
        return {
          width: `${canvasWidth}px`,
          position: 'absolute',
          left: '20px',
          bottom: '11px',
          height: '3px',
          marginTop: '27px',
          zIndex: 1,
        }
      }
      return {}
    })

    watch(canvasStyle, (newStyle) => {
      const canvas = canvasRef.value;
      if (canvas) {
        Object.assign(canvas.style, newStyle);
        // Update canvas element size for drawing calculations
        canvas.width = parseInt(newStyle.width, 10);
        canvas.height = parseInt(newStyle.height, 10);
      }
    }, { deep: true });

    let lastCallTime = Date.now()
    const throttleInterval = 1000

    const throttledDrawBufferSegments = () => {
      const now = Date.now()
      if (now - lastCallTime >= throttleInterval) {
        drawBufferSegments()
        lastCallTime = now
      }
    }
    const drawBufferSegments = () => {
      if (!videojsPlayer.value || !canvasRef.value) return

      const canvas = canvasRef.value
      const context = canvas.getContext('2d')
      const duration = videojsPlayer.value.duration()
      const inc = canvas.width / duration // Pixels per second
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.fillStyle = colors.mx.buffered

      // Draw segments based on Video.js buffered time ranges
      for (let i = 0; i < videojsPlayer.value.buffered().length; i++) {
          const startX = videojsPlayer.value.buffered().start(i) * inc
          const endX = videojsPlayer.value.buffered().end(i) * inc
          const width = endX - startX
          context.fillRect(startX, 0, width, canvas.height)
      }
    }



    const handleInput = (event) => {
      if (isDragging.value) {
        // Emit the 'input' event with the new value
        emit('input', parseFloat(event.target.value))
        console.log('Slider value while dragging:', event.target.value)
      }
    }

    const handleMouseDown = () => {
      isDragging.value = true
      emit('start')
    }

    const handleMouseUp = () => {
      if (isDragging.value) {
        isDragging.value = false
        emit('end', parseFloat(props.value))
        
      }
    }

    const handleTouchStart = () => handleMouseDown()
    const handleTouchEnd = () => handleMouseUp()

    const thumbLabelPosition = computed(() => {
      return ((props.value - 0) / (props.max - 0)) * 100
    })

    watch(() => props.value, (newValue) => {
      if (isDragging.value) {
        emit('change', newValue)
      }
    })

    onMounted(() => {
      videojsPlayer.value = videojs('htmlVideoPlayer')
      canvasRef.value.width = document.querySelector('.progress-slider-container')?.clientWidth - 40
      
      videojsPlayer.value.on('progress', drawBufferSegments)
      videojsPlayer.value.on('timeupdate', throttledDrawBufferSegments)

    })

    return {
      canvasRef,
      canvasStyle,
      colors,
      isDragging,
      handleInput,
      handleMouseDown,
      handleMouseUp,
      handleTouchStart,
      handleTouchEnd,
      thumbLabelPosition,
    };
  },
}
</script>

<style scoped>


.progress-slider-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 8px 8px 0 8px;
  z-index: 2
}

.progress-slider {
  -webkit-appearance: none;
  width: 100%;
  height: var(--track-height, 2px);
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #FFF;
  cursor: pointer;
  z-index: 2;
}

.progress-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FFF;
  cursor: pointer;
}

.thumb-label {
  position: absolute;
  background-color: #fff;
  color: #000;
  padding: 2px 5px;
  border-radius: 4px;
  transform: translate(-50%, -110%);
}

/* #buffered {
  position: absolute;
  left: 20px;
  margin: 27px 0 0 20px;
  bottom: 11px;
  height: 3px;
  z-index: 1;
} */

</style>
