<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card style="text-align: left;">
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="subtitle" class="pa-4">{{ subtitle }}</v-card-text>
      <v-select
          v-if="selectionData"
          style="width: 14em; margin: 10px; "
          :items="selectionData"
          v-model="selectedItem"
          label="Audio Format"
          solo>
      </v-select>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="options.color" @click="agree">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// @ts-nocheck

export default {
  data: () => ({
    logos: require('@/lib/ui/logos.js').logos,
    colors: require('@/lib/ui/colors.js').colors,
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    subtitle: null,
    selectionData: null,
    selectedItem: undefined,
    options: {
      color: 'primary',
      width: 250,
      zIndex: 200
    }
  }),

  methods: {
    open (title, subtitle, selectionData, options) {
      this.dialog = true
      this.title = title
      this.subtitle = subtitle
      if (selectionData) {
        this.selectionData = []
        for (const item of selectionData) {
          this.selectionData.push(item)
        }
        this.selectedItem = this.selectionData[0]
      }

      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      // return verificationCode or selected index
      let returnValue = this.selectedItem || this.selectionData[0]
      this.resolve(returnValue)
      this.dialog = false
      this.resolve = null
      this.reject = null
      this.title = null
      this.subtitle = null
      this.selectionData = null
      this.selectedItem = null
    },
    cancel( ) {
      this.agree()
    }
  }
}
</script>
