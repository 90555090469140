const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const isObjectInstance = SpotterfishCore.isObjectInstance
const UserFeatureBits = require('../spotterfish_library/userFeatureBits')
const CloudClient = require('./CloudClient')
const { default: SessionListener } = require('./SessionListener')
const _ = require('lodash')

// ??? Add more checks
// TODO: Should probably be moved to a new module "session.mjs"
function checkInvariantSpotterfishSession (s) {
  assert(s !== undefined && s !== null)

  assert(s.firebase !== undefined && s.firebase !== null)
  assert(s.firestoreDB !== undefined && s.firestoreDB !== null)
  assert(isObjectInstance(s.ufb) || s.ufb === undefined)
  // NOTICE: firebaseAnalytics is optional.
  assert(s.hasOwnProperty('firebaseAnalytics'))
  assert(s.hasOwnProperty('segmentAnalytics'))

  return true
}

function getProjectFromUID (session, projectID) {
  return new Promise((resolve, reject) => {
    session.firestoreDB.collection('projects').doc(projectID).get().then((doc) => {
      if (doc.exists) {
        const proj = doc.data()
        proj['.key'] = doc.id
        resolve(proj)
      } else {
        reject(new Error('Project does not exist'))
      }
    }).catch((error) => {
      console.log('project_get_from_uid: ', error)
      reject(error)
    })
  })
}

// Represents our running Firebase app.
function makeSpotterfishSessionObject (firebase, firestoreDB, firebaseAnalytics, segmentAnalytics, userSession) {
  const r = {
    firebase: firebase,
    firestoreDB: firestoreDB,
    firebaseAnalytics: firebaseAnalytics,
    segmentAnalytics: segmentAnalytics,
    userSession: userSession
  }
  assert(checkInvariantSpotterfishSession(r))
  return r
}


async function validateAccount (session, userId) {
  // If the current user is anonymous and matches the user ID, it's a valid account.
  const firebaseUser = session.firebase.auth().currentUser

  if (firebaseUser.isAnonymous === true && firebaseUser.uid === userId) {
    return true
  }

  // Otherwise, there needs to be a user DB object for this account to be valid.
  const userDBObject = await session.firestoreDB.collection('users').doc(userId).get()

  return userDBObject.exists
}


// This function is responsible for getting from no user session, to existing, complete
// user session.
async function startUserSession (session, firebaseCurrentUser) {
  assert(checkInvariantSpotterfishSession(session))

  const isValidAccount = validateAccount(session, firebaseCurrentUser.uid)

  if (!isValidAccount) {
    console.log(`User ${firebaseCurrentUser.uid} does not exist in spotterfish DB, logging out`)
    session.firebase.auth().signOut()
  }
  const sessionListener = SessionListener(session, firebaseCurrentUser.uid)
  try {
    if (firebaseCurrentUser.isAnonymous === true) {
      session.userSession = {
        ufb: UserFeatureBits.makeBlankUFB(),
        DBIDMapping: {},
        firebaseCurrentUser: firebaseCurrentUser,
        isAnonymousUser: true,
        addSessionListener: () => {},
        removeSessionListener: () => {},
        removeSessionFirebaseListener: () => {},
        removeAllSessionFirebaseListener: () => {},
      }
    } else {
      const result = await CloudClient.call_CFcalculateAccountFeatureBitsReadOnly(session.firebase)
      session.userSession = {
        ufb: result.ufb,
        DBIDMapping: result.DBIDMapping,
        firebaseCurrentUser: firebaseCurrentUser,
        isAnonymousUser: false,
        addSessionListener: sessionListener.add,
        removeSessionListener: sessionListener.remove,
        removeSessionFirebaseListener: sessionListener.stopFirebaseListener,
        removeAllSessionFirebaseListeners: sessionListener.stopFirebaseListeners,
      }
      // NOT 2023-10-12: This is where we make sure user has stripe account, but there is no point
      // let's focus on the webhook and make sure it handles eveything, then rely on stipe
    }

    assert(checkInvariantSpotterfishSession(session))

    return session.userSession
  } catch (error) {
    console.error(error)
    return undefined
  }
}

// ??? Make accessor for spotterfishSession.userSession.firebaseCurrentUser --- it should sample FB
//  and make sure it hasn't changed

// ??? These are being moved to spotterfish session

async function getUserFromEmail (firestoreDB, userEmail) {
  assert(SpotterfishCore.isStringInstance(userEmail))
  const userQuery = await firestoreDB.collection('users').where('email', '==', userEmail).get()
  // console.log(userQuery)
  if (!userQuery.empty) {
    const thisUser = userQuery.docs[0].data()
    thisUser['.key'] = userQuery.id
    thisUser.uid = userQuery.id
    return thisUser
  } else {
    throw new Error('This user does not exist')
  }
}

async function getUserFromId (firestoreDB, userId) {
  assert(SpotterfishCore.isStringInstance(userId))
  const userQuery = await firestoreDB.collection('users').where('user_id', '==', userId).get()
  if (!userQuery.empty) {
    return userQuery.docs[0].data()
  } else {
    throw new Error('This user does not exist')
  }
}

async function getUserObjects (firebase, userIds) {
  let promises = []

  for (const userId of Object.values(userIds)) {
    promises.push(CloudClient.call_CFgetUserObject(firebase, userId))
  }

  return Promise.all(promises)
}

function trackEvent (spotterfishSession, eventName, params){

  assert(SpotterfishCore.isObjectInstance(spotterfishSession))
  assert(SpotterfishCore.isStringInstance(eventName))
  assert(SpotterfishCore.isObjectInstance(params))
  console.log('tracking event: ' + eventName + ' with params ' + JSON.stringify(params))

  const clonedParams = _.cloneDeep(params)

  if (clonedParams.updated_by) {
    clonedParams.updated_by = JSON.stringify(clonedParams.updated_by)
  }
  if (clonedParams.updated_date) {
    clonedParams.updated_date = JSON.stringify(clonedParams.updated_date)
  }

  if (clonedParams.created_date) {
    clonedParams.created_date = JSON.stringify(clonedParams.created_date)
  }

  spotterfishSession.segmentAnalytics.track(
    eventName,
    clonedParams
  )

  spotterfishSession.firebaseAnalytics.logEvent(
    eventName,
    clonedParams
  )

}

// NOTE: Maybe we should detect changed of currentUser without it passing through undefined.
// Checks to see if user's logged-in status has changed.
async function detectLoggedinTransient (spotterfishSession) {
  const currentUser = spotterfishSession.firebase.auth().currentUser

  if (!spotterfishSession.userSession && !currentUser) {
    // ??? Should anything happen here?
  } else if (!spotterfishSession.userSession && currentUser) {

    const userSession = await startUserSession(spotterfishSession, currentUser)
    try {
      const accountTier = await CloudClient.call_CFgetAccountTier(spotterfishSession.firebase)
  
      spotterfishSession.segmentAnalytics.identify(
        userSession.firebaseCurrentUser.uid,
        {
          userId: userSession.firebaseCurrentUser.uid,
          email: userSession.firebaseCurrentUser.email,
          createdAt: new Date(),
          accountTier: accountTier
        }
      )
    } catch (error) {
      console.warn('Segment identification failed')
    }
    
    // console.log(`ufb in Router: ${JSON.stringify(userSession.ufb)}`)
  } else if (spotterfishSession.userSession && !currentUser) {
    spotterfishSession.userSession = undefined
  } else if (spotterfishSession.userSession && currentUser) {
    assert(spotterfishSession.userSession.firebaseCurrentUser === currentUser)
  }
}

async function recalculateUFB (spotterfishSession) {
  const {ufb} = await CloudClient.call_CFcalculateAccountFeatureBitsReadOnly(spotterfishSession.firebase)
  spotterfishSession.userSession.ufb = ufb
}

async function updateUser (firestoreDB, userId, data) {
  return new Promise((resolve, reject) => (
    firestoreDB
      .collection('users')
      .doc(userId)
      .update(data)
      .then(() => {
        resolve(undefined)
      })
      .catch((error) => {
        reject(error)
      })
  ))

}

module.exports = {
  checkInvariantSpotterfishSession,
  getProjectFromUID,
  makeSpotterfishSessionObject,
  startUserSession,

  getUserFromEmail,
  getUserFromId,
  getUserObjects,
  updateUser,

  trackEvent,
  detectLoggedinTransient,
  recalculateUFB
}
