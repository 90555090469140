<template>
  <v-container style="padding: 0;">
    <v-layout row wrap>
      <!-- FILTER/SEARCH PROJECTS -->
      <v-container style="width: 100%;">
        <v-row style="width: 100%; margin: auto; height: 240px;">
          <v-col cols="12">
            <v-text-field
              color="white"
              dark
              compact
              small
              single-line
              hide-details
              solo
              :append-icon="$store.state.dashboardProjectSearch ? 'mdi-close-circle-outline' : 'mdi-magnify'"
              @click:append="$store.state.dashboardProjectSearch = ''"
              style="
                color: white;
              "
              v-model="$store.state.dashboardProjectSearch"
              label="Search Projects"
              placeholder="Search Project"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="accountTier === 'tier1'"
              style="border-radius: 4px; width: 100%;"
              :color="colors.amethyst_smoke"
              @click="$emit('request-open-dawstreamer-panel')"
              small
              dark
            >
              Open DAW streamer
            </v-btn>
          </v-col>
          <div style="margin: 10px auto; width: 100%;">
            <uploadwidget @project-uploaded="$emit('on-new-project-uploaded', $event)" caller="dashboardButton"></uploadwidget>
          </div>
          <v-btn
              style="border-radius: 4px; width: 92%; margin: -2px auto 20px;"
              :color="colors.mx.panel"
              @click="$emit('request-reset-video-file')"
              small
              dark
            >
            Unload project
            </v-btn>
            <v-btn
              v-if="templateReactiveData.UI_showBetaFeatures"
              style="border-radius: 4px; width: 92%; margin: -2px auto 20px;"
              :color="templateReactiveData.whipStreamActive ? colors.mx.secondary : colors.mx.panel"
              @click="$emit('request-toggle-ingest-live-stream')"
              small
              dark
            >
            {{ templateReactiveData.whipStreamActive ? 'Stop live A/V Stream (Beta)' : 'Ingest live A/V Stream (Beta)' }}
            </v-btn>
        </v-row>
      </v-container>
      <v-container>
        <projects 
          style="margin: 0;" 
          ref="filteredProjects" 
          :setSpinner="() => { consoleLog('should set spinner')}" 
          :setLoadingMessage="() => { consoleLog('should set loading message')}" 
          viewOption="list" 
          caller="screeningRoom"
          @project-clicked="$emit('project-clicked', $event)"
          @request-load-in-mixstage="$emit('request-load-in-mixstage', $event)"
        />
      </v-container>
      </v-layout>
    </v-container>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { mapState } from 'vuex'
import uploadwidget from '@/components/projectscomponents/uploadWidget_restyle.vue'
import projects from '@/components/dashboard/projects.vue'
import projectsRow from '@/components/dashboard/projectsRow.vue'

export default {
  props: ['userUID', 'selected', 'moderators', 'accountTier', 'templateReactiveData'],
  components: {
    uploadwidget,
    projects,
    projectsRow
  },
  name: 'projectList',
  data: function () {
    return {
      // UI
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      search: '',
      overlay: false,
    }
  },
  computed: {
    ...mapState(['projectsArray']),
    userId() {
      return App.spotterfishSession.userSession.firebaseCurrentUser.uid
    },
    filteredProjects() {
      if (this.search.length === 0) {
        return this.cleanedProjects
      } else {
        return this.cleanedProjects.filter((project) => {
          if (!project) {
            return this.cleanedProjects
          }
          let tagString = ''
          if (project.project_description_tags) {
            for (
              let index = 0;
              index < project.project_description_tags.length;
              index++
            ) {
              tagString =
                tagString + ', ' + project.project_description_tags[index]
            }
          }
          const searcher =
            tagString.toLowerCase() +
            project.project_name.toLowerCase() +
            ' ' +
            project.project_description.toLowerCase()
          return searcher.match(this.search.toLowerCase())
        })
      }
    },
    filteredProjectsLength() {
      return this.filteredProjects.length
    },
    cleanedProjects() {
      return this.projectsArray.filter(proj => !proj.removed)
    },
  },
  methods: {
    consoleLog(e) {
      console.log(e)
    },
    doNothing() {
      return 0
    },
    isSelected(e) {
      if (e['.key'] === this.selected) {
        return true
      }
    },
  },
  mounted() {
    this.$store.dispatch(
      'projects_get_available_with_realtime_listener',
      this.userUID
    )
  },
  updated() {
    let selectedElement = document.getElementsByClassName('bordered')
    for (let elem of selectedElement) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  },
}
</script>

<style scoped>
.bordered {
  border: 8px solid #6592c8 !important;
}

.plain {
  border: 2px solid rgb(60, 60, 60);
}

.opaque-on-hover {
  opacity: 0.5;
}

.opaque-on-hover:hover {
  opacity: 0.2;
}

.edit-project-overlay {
  height: 3rem;
  transition: 0ms;
}
.project-card .edit-project-overlay {
  visibility: hidden;
}
.project-card:hover .edit-project-overlay {
  visibility: visible;
}
.project-name{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.4rem;
  max-width: 95%;
  display: inline-block;
}
</style>
