<!--

Videojs-player is hard to destroy(). We create the htmlVideo and videojs at component construction, but explicitly make it a NOP when have no video selected.


this.avObject: value means there is an active "video", undefined means nothing is select4ed.


//  Create a DOM element for the video player. This is a workaround for videojs deleting DOM element when disposing its player. See .dispose()
-->


<template>
  <div @click="unmuteSafari()">
    <div ref="htmlVideoDiv">
      <video
        playsinline
        autoplay
        crossorigin
        :muted="isSafari() && isMobile()"
        class="video-js vjs-default-skin audioPostrVideo shadow-level-one noSelect"
        id="htmlVideoPlayer"
        ref="htmlVideoPlayer"
        @playing="onHTMLVideoPlayer_playing"
        @pause="onHTMLVideoPlayer_pause"
        @ended="onHTMLVideoPlayer_ended"
        @seeked="onHTMLVideoPlayer_seeked"
        @seeking="onHTMLVideoPlayer_seeking"
        @error="onHTMLVideoPlayer_error"
        @loadeddata="onHTMLVideoPlayer_loadeddata"
        @timeupdate="onHTMLVideoPlayer_timeupdate"
        @canplay="onHTMLVideoPlayer__canplay($event)"
        @canplaythrough="onHTMLVideoPlayer__canplaythrough($event)"
        @contextmenu.prevent="returnNullHack"
        :poster="getPosterImage()"
        :style="{ background: colors.mx.black }"
        data-setup='
        {
          "playsinline": true,
          "autoplay": true,
          "controls": false,
          "loadingSpinner": true,
          "preload": "auto",
          "errorDisplay": false,
          "notSupportedMessage": "Spotterfish could not read this request-screenshotfile format. \nPlease check the compability list"
        }'>
      </video>
    </div>
    <div>
      <!-- We play up to three different audio stems in sync {{D}} {{M}} {{E}} -->
      <audio controls muted ref="audioPlayerD" style="display: none;" preload="auto"></audio>
      <audio controls muted ref="audioPlayerM" style="display: none;" preload="auto"></audio>
      <audio controls muted ref="audioPlayerE" style="display: none;" preload="auto"></audio>
    </div>
    <transition name="fade">
    <div>
      <div v-if="showBuffering()" class="player-messages">BUFFERING MOVIE {{ getBufferedPercentage() }}%</div>
      <div v-if="showMuted()" class="player-messages"><v-icon size="3em">mdi-volume-mute</v-icon></div>
      <div v-if="showSeeking()" class="player-messages no-bg"><v-icon size="3em">mdi-circle-small</v-icon></div> <!-- mdi-cloud-sync-outline -->      
    </div>
    </transition>
    <insert-local-file-component ref="localfile" @file-added="setLocalFile"></insert-local-file-component>
    <!-- <div v-if="UIshouldSyncToDawTC && !inSync" style="position: absolute; bottom: 20%; left: 50%; font-size: 3em; -webkit-text-stroke-width: 2px; -webkit-text-stroke-color: black;">*</div> -->
  </div>
</template>
<script>
// @ts-nocheck

import 'video.js/dist/video-js.min.css'
import videojs from 'video.js'
import MasterClock from '@/../source_files/spotterfish_library/MasterClock'
import Constants from '@/../source_files/spotterfish_library/consts'

import insertLocalFile from '@/components/UI/insertLocalFile.vue'
import { setTimingsrc } from 'timingsrc'
import { isSafari, isMobile } from 'mobile-device-detect'

import VideoPlayerUtils from '@/../source_files/spotterfish_library/utils/VideoPlayerUtils'
import { assert, isBooleanInstance, isArrayInstance, isStringInstance, isFunctionInstance, isNumberInstance, isObjectInstance } from '@/../source_files/spotterfish_library/SpotterfishCore'
import { setTimeout } from 'timers'

const _ = require('lodash')




function is100Percent(value) { return value === 100.0 }


/* :::::::::::::::::::::::::::::: MEDIA SYNC  :::::::::::::::::::::::::::::::: */



function stopAllMediaSyncs0 (component) {
  // ??? Sometimes videoPlayerMediaSync is undefined?
  if (component.videoPlayerMediaSync) {
    component.videoPlayerMediaSync()
    component.videoPlayerMediaSync = undefined
  }

  if (component.audioPlayerMediaSyncD) {
    component.audioPlayerMediaSyncD()
    component.audioPlayerMediaSyncD = undefined
  }

  if (component.audioPlayerMediaSyncM) {
    component.audioPlayerMediaSyncM()
    component.audioPlayerMediaSyncM = undefined
  }

  if (component.audioPlayerMediaSyncE) {
    component.audioPlayerMediaSyncE()
    component.audioPlayerMediaSyncE = undefined
  }
}

function doesHTMLAudioPlayerHaveSource(player){
    // var D = (' ' + player.src).slice(-4)
    const hasSrc = player.src !== undefined ?  player.src !== '' : false
    return hasSrc
}

const wrap = (mediaElement) => {
    let isActive = true
    let timeout = null

    return {
        get currentTime () {
            return mediaElement.currentTime
        },
        set currentTime (currentTime) {
            mediaElement.currentTime = currentTime

            if (timeout !== null) {
                clearTimeout(timeout)
            } else {
                isActive = true
            }

            timeout = setTimeout(() => {
                isActive = false
                timeout = null
            }, 5000)
        },
        get duration () {
            return mediaElement.duration
        },
        get paused () {
            return mediaElement.paused
        },
        get playbackRate () {
            return mediaElement.playbackRate
        },
        set playbackRate (playbackRate) {
            mediaElement.playbackRate = isActive ? playbackRate : 1
        },
        play: () => {
            const promise = mediaElement.play()

            if (timeout !== null) {
                clearTimeout(timeout)
            } else {
                isActive = true
            }

            timeout = setTimeout(() => {
                isActive = false
                timeout = null
            }, 5000)

            return promise
        },
        pause: () => {
            mediaElement.pause()

            if (timeout !== null) {
                clearTimeout(timeout)

                timeout = null
            } else {
                isActive = true
            }
        }
    }
}

function makeMediaSyncs0 (component, to, audioTO) {
  assert(isObjectInstance(component))
  assert(isObjectInstance(to))
  assert(isObjectInstance(audioTO))

  try {
    // old mediasync this.mediaSync = window.MCorp.mediaSync(audioPostrVideoPlayer, timingObject)
    // new mediasync     component.videoPlayerMediaSync = setTimingsrc(component.$refs.htmlVideoPlayer, to)
    // 2023 - wrap: component.videoPlayerMediaSync = setTimingsrc(wrap(component.$refs.htmlVideoPlayer), to)
    // revert to mcorp
    component.videoPlayerMediaSync = setTimingsrc(component.$refs.htmlVideoPlayer, to)
    // component.videoPlayerMediaSync = window.MCorp.mediaSync(component.$refs.htmlVideoPlayer, to)


    // NOTICE: Audio stem players are always connected to the mcorpServer, even when DAW-streaming
    if (doesHTMLAudioPlayerHaveSource(component.$refs.audioPlayerD)) {
      component.audioPlayerMediaSyncD = setTimingsrc((component.$refs.audioPlayerD), audioTO)
    }
    if (doesHTMLAudioPlayerHaveSource(component.$refs.audioPlayerM)) {
      component.audioPlayerMediaSyncM = setTimingsrc((component.$refs.audioPlayerM), audioTO)
    }
    if (doesHTMLAudioPlayerHaveSource(component.$refs.audioPlayerE)) {
      component.audioPlayerMediaSyncE = setTimingsrc((component.$refs.audioPlayerE), audioTO)
    }
  } catch (error) {
    debugger

    stopAllMediaSyncs0(component)

    throw error
  }
}




let offPositionCount = []

function debugLogDawStreamerSyncDrift (videoplayerTIme, pos, tolerance) {
  // both values are in seconds
  const offMs = 1000 * (videoplayerTIme - pos)
  if (offMs > tolerance) {
    const lastOff = offPositionCount.length ? offPositionCount[0].time : Date.now()
    offPositionCount.push({ off: offMs, time: Date.now() })
    const thisTime = offPositionCount[offPositionCount.length - 1].time
    if (thisTime > lastOff + 1000) {
      offPositionCount = []
    }
    if (offPositionCount.length > 10) {
      console.log(`Re-transmissions possibly occurred, sync has been off more than ${tolerance} ms > 10 times in the last second`)
      offPositionCount = []
    }
  }
}



function calculatePlayerVolume (audioTrackInVideoMuted, volume, syncSource, muteAudioVideoPlayerAudio) {
  const playVideoAudio = (syncSource === 'timing_provider') && (audioTrackInVideoMuted === false)
  const audioPlayers = syncSource === 'timing_provider'

  if (muteAudioVideoPlayerAudio) {
    return {
      videojsPlayer: 0.0,
      audioPlayerD: 0.0,
      audioPlayerM: 0.0,
      audioPlayerE: 0.0
    }
  } else {
    return {
      videojsPlayer: playVideoAudio ? volume : 0.0,
      audioPlayerD: audioPlayers ? volume : 0.0,
      audioPlayerM: audioPlayers ? volume : 0.0,
      audioPlayerE: audioPlayers ? volume : 0.0
    }
  }
}




async function detectIfBrowserBlocksPlay(videojsPlayer){
  assert(isObjectInstance(videojsPlayer))

  // wait for video metadata to be loaded before starting the rest of the app
  // WARNING: We might never get a callback. Maybe the file is already loaded etc.???
  // Warning: this starts the videoplay, which might now be what we want???
  // WARNING: The blocked-flag is not currently used.
  // ??? stop playback if play succeedes
  const blocked = await new Promise(
    (resolve, reject) => {
      videojsPlayer.on(
        'loadedmetadata',
        async () => {
          // Autoplay video, detect if autoplay is blocked by browser
          let blocked = false
          try {
            await videojsPlayer.play()
          }
          catch(error) {
            // debugger

            // ??? Bad test - video may not be playable at all until later
            // console.error('Browser blocked video autoplay')
            blocked = true
          } 
          finally {
            resolve(blocked)
          }
        }
      )
    }
  )
  videojsPlayer.off('loadedmetadata')
  return blocked
}



//  Must no call other code, touch reactive properties etc.
async function setVideoPlayerAtomic (component, masterClock, avObject) {
  assert(isObjectInstance(component))
  assert(MasterClock.checkInvariantMasterClock(masterClock))
  assert(VideoPlayerUtils.checkInvariantAVObject(avObject))

  try {
    assert(component.avObject === undefined)
    
    //  Setup existing video and audio players
    {
      // never returns a promise
      const a = component.videojsPlayer.src(avObject.movieObject)
      assert(a === undefined)
      
      component.$refs.audioPlayerD.src = avObject.audioSourceD.src
      component.$refs.audioPlayerM.src = avObject.audioSourceM.src
      component.$refs.audioPlayerE.src = avObject.audioSourceE.src
      const blocked = await detectIfBrowserBlocksPlay(component.videojsPlayer)
    }

    const length = component.videojsPlayer.duration()

    //  component.avObject defined if video is loaded or not
    component.avObject = avObject

    assert(isNumberInstance(length) && length >= 0.0)

    assert(component.avObject !== undefined)

    const to = MasterClock.getCurrentTO(masterClock)
    // to.addEventListener('readystatechange', (evt) => {
    //   if(to.readyState === 'closed'){
    //     stopAllMediaSyncs0(component)
    //   }
    // })
    makeMediaSyncs0(component, to, masterClock.timing.timingProviderTO)

    return length
  }
  catch(error){
    debugger

    clearVideoPlayerAtomic(component)

    throw error
  }
}

//  Must no call other code, touch reactive properties etc.
//??? Will these have any futher effect? Fire callbacks, run in background etc?
function clearVideoPlayerAtomic (component, errorSrcOpt) {
  // assert(component.avObject !== undefined)

  try {
    stopAllMediaSyncs0(component) 

    component.avObject = undefined

    console.log(JSON.stringify(component.videojsPlayer.currentSrc()))
    assert(component.videojsPlayer !== undefined)

    const temp = component.videojsPlayerCallbackCount

    component.videoFileLoadedFlag = false

    component.videojsPlayer.pause()
    // ??? Setting src to undefined does not result in src changing, setting it to an empty object does
    component.videojsPlayer.src(errorSrcOpt)
    // component.videojsPlayer.src({src: '', type: ''})
    // component.videojsPlayer.load()
    // await videojsPlayer.ready
    console.log(JSON.stringify(component.videojsPlayer.currentSrc()))

    component.$refs.audioPlayerD.pause()
    component.$refs.audioPlayerD.src = ''

    component.$refs.audioPlayerM.pause()
    component.$refs.audioPlayerM.src = ''

    component.$refs.audioPlayerE.pause()
    component.$refs.audioPlayerE.src = ''

    assert(component.avObject === undefined)


    const temp2 = component.videojsPlayerCallbackCount
    assert(temp === temp2)
  }
  catch(error){
    debugger
    throw error
  }
}

//  Must no call other code, touch reactive properties etc.
//  Returns length of video, does not modify masterClock
async function swapVideoPlayerAtomic0 (component, masterClock, avObject0, avObject) {
  assert(isObjectInstance(component))
  assert(MasterClock.checkInvariantMasterClock(masterClock))
  assert(avObject0 === undefined || VideoPlayerUtils.checkInvariantAVObject(avObject0))
  assert(avObject === undefined || VideoPlayerUtils.checkInvariantAVObject(avObject))


  try {
    if(avObject0 !== undefined){
      assert(component.avObject !== undefined)

      clearVideoPlayerAtomic(component)

      assert(component.avObject === undefined)
    }

    assert(component.avObject === undefined)

    if(avObject !== undefined){
      try {
        const length = await setVideoPlayerAtomic(component, masterClock, avObject)

        assert(component.avObject !== undefined)

        return length
      }
      catch(error){
        debugger

        if(avObject0 !== undefined){
          try {
            await setVideoPlayerAtomic(component, masterClock, avObject0)
          }
          catch(error){
            debugger
            throw error
          }
        }
        throw error
      }
    }
  }
  catch(error){
    debugger
    throw error
  }
}

//??? Do not use templateReactiveData in logic!
async function setSyncSource0 (component, masterClock, syncSource) {
  assert(isObjectInstance(component))
  assert(MasterClock.checkInvariantMasterClock(masterClock))

  MasterClock.selectSyncSource(masterClock, syncSource)
  stopAllMediaSyncs0(component)

  const to = MasterClock.getCurrentTO(masterClock)
  makeMediaSyncs0(component, to, masterClock.timing.timingProviderTO) 
}


export default {
  name: 'videoplayercomponent',
  props: [
    'spotterfishParams',
    'syncSource',
    'templateReactiveData',

    'constprop_dawStreamCapabilitiesEnabled',
    'constprop_backgroundColor', 

    //  SETTINGS
    'updateprop_isModerator',
    'updateprop_videoPlayerVolume'
  ],
  inject: ['clock'],
  data () {
    return {
      logos: require('@/lib/ui/logos.js').logos,
      colors: require('@/lib/ui/colors.js').colors,

      videojsPlayer: undefined,
      muteAudioVideoPlayerAudio: true,
      // 0: not buffered, cannot play now. 100: fully buffered, ready to play
      bufferedPercentage: undefined,
      seekingFlag: false,
      localFile: false,

      videoPlayerMediaSync: undefined, 
      audioPlayerMediaSyncD: undefined, 
      audioPlayerMediaSyncM: undefined, 
      audioPlayerMediaSyncE: undefined, 

      browserPreventedAutoPlayCount: 0,
      userWarnedAutoplayIsBlocked: false,

      videoFileLoadedFlag: false
    }
  },

  components: {
    'insert-local-file-component': insertLocalFile
  },


  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::COMPUTED:::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  computed: {

  },


  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::WATCHERS:::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  watch: {

    updateprop_videoPlayerVolume (newVolume, oldVolume) {
      const vol = calculatePlayerVolume(this.avObject?.audioTrackInVideoMuted, newVolume, this.syncSource, this.muteAudioVideoPlayerAudio)
      this.setVolumes(vol)
    }

    // ??? CHANGE: We must allow use of postion == 0.0, remove use of position 0 as no-position.
  },

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::METHODS::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  methods: {
    getPosterImage() {
      const vjsPosterImg = document.querySelector('.vjs-poster img')
      if (vjsPosterImg) {
        vjsPosterImg.style.display = 'none'
      }
      return this.templateReactiveData?.customLogo ? this.templateReactiveData?.customLogo : this.logos.logoPosterImageWhite
    },
    async captureScreenshot () {
      const pic = await VideoPlayerUtils.captureScreenshot(this.$refs.htmlVideoPlayer)
      return pic
    },
    async swapVideoPlayerAtomic (masterClock, avObject0, avObject) {
      assert(MasterClock.checkInvariantMasterClock(masterClock))
      assert(avObject0 === undefined || VideoPlayerUtils.checkInvariantAVObject(avObject0))
      assert(avObject === undefined || VideoPlayerUtils.checkInvariantAVObject(avObject))

      return await swapVideoPlayerAtomic0 (this, masterClock, avObject0, avObject)
    },

    resetVPAtomic () {
      const errorVideoSrc = Constants.ERROR_VIDEO_SOURCE
      return clearVideoPlayerAtomic (this, errorVideoSrc)
    },

    async setSyncSource (masterClock, syncSource) {
      return await setSyncSource0 (this, masterClock, syncSource)
    },


    ////////////////    BUFFERING AND SEEKING INDICATORS

    isBuffering() {
      return this.bufferedPercentage < 100.0 && this.bufferedPercentage > 0
    },

    getBufferedPercentage() {
      return this.bufferedPercentage
    },
    showBuffering() {
      return this.isBuffering() && !this.seekingFlag && this.clock && this.clock.videoFileLengthOpt !== undefined
    },
    showSeeking() {
      return this.seekingFlag && this.clock && this.clock.videoFileLengthOpt !== undefined
    },
    showMuted () {
      return this.videoFileLoadedFlag && this.clock?.playFlag && !this.showBuffering() && !this.showSeeking() && this.muteAudioVideoPlayerAudio 
    },

    setSeekIndicator(value) {
      if(value !== this.seekingFlag){
        this.spotterfishParams.setSeekIndicator(value)
        this.seekingFlag = value
      }
    },

    setBufferedPercentage(value) {
      const bufferingFlag = is100Percent(value) === false
      const bufferingFlag0 = is100Percent(this.bufferedPercentage) === false

      if(bufferingFlag0 !== bufferingFlag) {
        this.spotterfishParams.setBufferingFlag(bufferingFlag)
      }

      this.bufferedPercentage = value
    },



    displaySyncState (averageDiffPlayerToTimecode) {
      console.log(averageDiffPlayerToTimecode)
    },
    setFluid (lightsOff) {
      this.videojsPlayer.fluid(lightsOff)
    },
    setLocalFile (e) {
      this.localFile = e
    },
 



/*
    //??? Warning, opens UX.
    async letUserOverrideVideoFile (videoObject) {
      if (!videoObject.src.includes('::localfile::')) {
        return videoObject
      }
      else {
        const fileName = videoObject.src.replace('::localfile::', '')

        //  Shows UX to select file.
        const confirm = await this.$refs.localfile.open(
          `Insert local file with name: ${ fileName }`, VideoPlayerUtils.LOCALFILESTRING,
          { color: this.colors.mainAccent }
        )

        if (confirm) {
          let file = this.localFile
          videoObject.src = URL.createObjectURL(file)
          videoObject.type = 'video/mp4'
          return videoObject
        }
        else {
          // ??? No video
        }
      }
    },
*/


    /////////////////////////////////////   onHTMLVideoPlayer_


    onHTMLVideoPlayer_playing () {
      this.videojsPlayerCallbackCount++
      this.spotterfishParams.onVideoplayerPlayFlagChange(true)
    },

    onHTMLVideoPlayer_pause () {
      this.videojsPlayerCallbackCount++
      this.spotterfishParams.onVideoplayerPlayFlagChange(false)
    },

    onHTMLVideoPlayer_ended () {
      this.videojsPlayerCallbackCount++
      this.spotterfishParams.onVideoplayerPlayFlagChange(false)
    },

    isSafari () {
      return isSafari
    },
    isMobile () {
      return isMobile
    },

    unmuteSafari () {
      if (this.isSafari) {
        this.$refs.htmlVideoPlayer.muted = false
      } else {
        console.log('test')
        return
      }
    },

    onHTMLVideoPlayer_seeked () {
      this.videojsPlayerCallbackCount++
      // assert(this.avObject !== undefined)

      // Should not be done in safari, since we seek a lot
      if (!isSafari) {
        this.spotterfishParams.onVideoplayerPlayFlagChange(false)
      }
      this.setSeekIndicator(false)
      this.videojsPlayer.loadingSpinner.hide()
    },

    onHTMLVideoPlayer_seeking () {
      this.videojsPlayerCallbackCount++
      // assert(this.avObject !== undefined)

      this.videojsPlayer.loadingSpinner.show()
      this.spotterfishParams.setCanpLayThroughFlag(false)

      //??? Should check if in daw-stream mode, not if cap.
      if (this.syncSource === 'timing_provider') {
        this.setSeekIndicator(true)
      }
    },

    onHTMLVideoPlayer_error (error) {
      debugger

      this.videojsPlayerCallbackCount++

      // assert(this.avObject !== undefined)
      assert(this.videojsPlayer !== undefined)

      // ??? How do we handle these errors?
      console.error(error)
      this.spotterfishParams.onVideoplayerPlayFlagChange(false)

      if (this.updateprop_isModerator) {
        if (this.videojsPlayer.loadingSpinner) {
          this.videojsPlayer.loadingSpinner.hide()
        }

        this.$emit('file-error')
        this.$root.$globalAlert.open(
          `Error loading project`,
          {
            line1: 'The video file is broken or may have expired. Please load the project again, or select another project.'
          },
          { color: this.colors.mainAccent }
        )
      }
      else {
      }
    },

    onHTMLVideoPlayer_loadeddata () {
      this.videojsPlayerCallbackCount++
      // assert(this.avObject !== undefined)

      this.videoFileLoadedFlag = true
    },

    // TimeUpdate is not called frequently enough to handle frame updates,
    // We therefore use requestAnimationFrame for timecode update etc
    // WARNING: SetVideo0 causes calls to timeUpdate because of autoplay detection too early
    onHTMLVideoPlayer_timeupdate () {
      this.videojsPlayerCallbackCount++
      if(this.avObject !== undefined) {
        this.spotterfishParams.onVideoPlayerTimeUpdate()
      }
    },

    onHTMLVideoPlayer__canplay (e) {
      this.videojsPlayerCallbackCount++

      assert(this.videojsPlayer !== undefined)

      const self = this
      this.setSeekIndicator(false)
      // Very old hack to close spinner whne it could not be hidden
      // setTimeout(() => {
      //   self.setSeekIndicator(false)
      //   if (self.videojsPlayer.loadingSpinner) {
      //     self.videojsPlayer.loadingSpinner.hide()
      //   }
      // }, 3000)
      if (this.videojsPlayer.loadingSpinner) {
        this.videojsPlayer.loadingSpinner.hide()
      }

      //  The HTML video player has been to in autoplay to make it preload video data. We need to pause it here.
      this.videojsPlayer.pause()
    },

    onHTMLVideoPlayer__canplaythrough (e) {
      this.videojsPlayerCallbackCount++
      this.spotterfishParams.setCanpLayThroughFlag(true)
    },







    setVolumes (vol) {
      this.videojsPlayer.volume(vol.videojsPlayer)
      this.$refs.audioPlayerD.volume = vol.audioPlayerD
      this.$refs.audioPlayerM.volume = vol.audioPlayerM
      this.$refs.audioPlayerE.volume = vol.audioPlayerE
    },

    closeOverlaysOnTimeOut () {
      assert(this.videojsPlayer !== undefined)

      setTimeout(
        () => {
          assert(this.videojsPlayer !== undefined)
          this.setBufferedPercentage(100.0)
          this.setSeekIndicator(false)
          if (this.videojsPlayer.loadingSpinner) {
            this.videojsPlayer.loadingSpinner.hide()
          }
        },
        3000
      )
      this.setSeekIndicator(false)

      assert(this.videojsPlayer !== undefined)
      if (this.videojsPlayer.loadingSpinner) {
        this.videojsPlayer.loadingSpinner.hide()
      }
    },


    /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
    /* ::::::::::::::::::::::::UTILITY METHODS::::::::::::::::::::::::::::::: */
    /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


    // ??? Remove emit etc to communicate SYNC/transport between components


    updateSyncAndPostStatus () {
      assert(this.videojsPlayer !== undefined)
      
      if(this.avObject !== undefined){

        {
          let muteAudioVideoPlayerAudio = 
            (this.videojsPlayer.preservesPitch === false) && 
            (
              (this.videojsPlayer.playbackRate() > 1.005 || this.videojsPlayer.playbackRate() < 0.995) || 
              (this.$refs.audioPlayerD.playbackRate > 1.005 ||this.$refs.audioPlayerD.playbackRate < 0.995) ||
              (this.$refs.audioPlayerM.playbackRate > 1.005 ||this.$refs.audioPlayerM.playbackRate < 0.995) ||
              (this.$refs.audioPlayerE.playbackRate > 1.005 ||this.$refs.audioPlayerE.playbackRate < 0.995) 
            ) ? 
            true : false

          if(muteAudioVideoPlayerAudio === false && this.muteAudioVideoPlayerAudio === true) {
            // keep muted one more cycle
            muteAudioVideoPlayerAudio = true
            this.muteAudioVideoPlayerAudio = false
          } else {
            this.muteAudioVideoPlayerAudio = muteAudioVideoPlayerAudio
          }

          const vol = calculatePlayerVolume(
            this.avObject.audioTrackInVideoMuted,
            this.updateprop_videoPlayerVolume,
            this.syncSource,
            muteAudioVideoPlayerAudio
          )
          this.setVolumes(vol)
        }

        if (this.userWarnedAutoplayIsBlocked === false) {
          const playFlag = this.clock.playFlag
          const blocked = this.detectBrowserBlockingPlayback(playFlag, this.videojsPlayer.paused() === false, this.syncSource)
          if(blocked) {
            this.userWarnedAutoplayIsBlocked = true
            this.$store.state.modals.videoAutoPlayFix = true
          }
        }

        //  Hack to avoid leaving spinner on
        if (this.videojsPlayer.loadingSpinner && this.showBuffering() === false) {
          this.videojsPlayer.loadingSpinner.hide()
        }
      }

      // Update buffering indications
      //  It's 100% when no video file is active
      // {
      //   const bufferedPercentage = this.avObject !== undefined
      //   ? VideoPlayerUtils.getPlaybackBufferingPercentage(
      //     this.videojsPlayer.currentTime(), 
      //     this.videojsPlayer.buffered(),
      //     // this.videojsPlayer.seekable(), // <-- Change .buffered() to this maybe? https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/buffering_seeking_time_ranges
      //     this.clock.videoFileLengthOpt
      //   )
      //   : 100.0

      //   this.setBufferedPercentage(bufferedPercentage <= 100.0 ? bufferedPercentage : 100.0)
      // }
    },

    onAnimationFrame () {
      this.updateSyncAndPostStatus()

      let requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
      this.animFrame = requestAnimationFrame(this.onAnimationFrame)
    },

    stopAnimationFrame () {
      // CLEARS ABOVE ON PAUSE ETC
      this.browserPreventedAutoPlayCount = 0
      let cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame
      cancelAnimationFrame(this.animFrame)
    },


    // Attempts to detect if browser has blocked autoplay of video
    //  RETURN: true => blocked, false => nonblocked
    detectBrowserBlockingPlayback (wantedPlayFlag, videoPlayerPlayFlag, syncSource) {
      if (wantedPlayFlag === true && videoPlayerPlayFlag === false && syncSource === 'timing_provider') {
        this.browserPreventedAutoPlayCount++

        // 60 = arbituary number of prevents, just made this up but this means it takes a few seconds before the 
        // alert box appears if browser is blocked
        if (this.browserPreventedAutoPlayCount >= 60) {
          return true
        }
      } else if (wantedPlayFlag === false) {
        this.browserPreventedAutoPlayCount = 0
      }
      return false
    },

    returnNullHack () {
      return false
    },
    
    // changes does not seem to "take" after the video player has been initialized
    setPreservesPitchProp (usePitchInsteadOfTimestretch) {
      const htmlVideoPlayer = document.getElementById('htmlVideoPlayer')
      this.$refs.audioPlayerD.preservesPitch = !usePitchInsteadOfTimestretch
      this.$refs.audioPlayerM.preservesPitch = !usePitchInsteadOfTimestretch
      this.$refs.audioPlayerE.preservesPitch = !usePitchInsteadOfTimestretch
      this.$refs.audioPlayerD.mozPreservesPitch = !usePitchInsteadOfTimestretch
      this.$refs.audioPlayerM.mozPreservesPitch = !usePitchInsteadOfTimestretch
      this.$refs.audioPlayerE.mozPreservesPitch = !usePitchInsteadOfTimestretch

      htmlVideoPlayer.mozPreservesPitch = !usePitchInsteadOfTimestretch; //Firefox
      htmlVideoPlayer.preservesPitch = !usePitchInsteadOfTimestretch; //Chrome
      console.log('Videoplayer set to preserve pitch: ' + htmlVideoPlayer.preservesPitch)
      console.log('AudioplayerD set to preserve pitch: ' + this.$refs.audioPlayerD.preservesPitch)
      console.log('AudioplayerM set to preserve pitch: ' + this.$refs.audioPlayerM.preservesPitch)
      console.log('AudioplayerE set to preserve pitch: ' + this.$refs.audioPlayerE.preservesPitch)
      this.preservesPitch = !usePitchInsteadOfTimestretch
    },
    async syncUserMediaOut() {
      try {
        const selectedAudioOut = localStorage.getItem('spotterfishUserAudioOut') || 'default'
        const mediaElements = document.querySelectorAll('audio, video')
        await Promise.all([...mediaElements].map(element => element.setSinkId(selectedAudioOut)))
      } catch (error) {
        console.log(error)
        // Handle the error as needed
      }
    },
    onUnhandledDOMExepion (event) {
      // event.reason contains the rejection reason (the error object)
      if (event.reason.name === 'AbortError') {
        console.log('Caught a DOMException: play() request was interrupted by a call to pause().')
        // Prevent the error from being displayed in the console
        event.preventDefault()
      }
    },
  },

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::LIFECYCLE HOOKS:::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  // Needs to access DOM
  async mounted () {
    this.videojsPlayerCallbackCount = 0

    // to catch DOM exceptions, specifically "DOMException: The play() request was interrupted by a call to pause()"
    // TODO: Handle it
    window.addEventListener('unhandledrejection', this.onUnhandledDOMExepion)
    // Allow preserve pitch to be set by advanced settings
    const usePitchInsteadOfTimestretch = localStorage.getItem('spotterfishUserUsePitch') === 'true'
    // 2023-03-01- do not read this anymore, always use pitch
    this.setPreservesPitchProp(usePitchInsteadOfTimestretch)
    //  Create a videojs-instance for our htmlVideo.
    {
      this.videojsPlayer = await new Promise(
        (resolve, reject) => {
          const videojsPlayer = videojs('htmlVideoPlayer', null, () => resolve(videojsPlayer))
        }
      )
      videojs.addLanguage(
        'en',
        { 'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Spotterfish could not this media, possibly because access was revoked by the owner.' }
      )
    }

    await this.syncUserMediaOut()
   
    // let bufferingTimer
    this.videojsPlayer.on('waiting', () => {
      console.log('===> Waiting')
    })
    this.videojsPlayer.on('playing', () => {
      // Video starts playing
    })

    this.videojsPlayer.on('progress', () => {
      // console.log('Buffered:', Math.round(this.videojsPlayer.bufferedPercent() * 100))

      const bufferedPercentage = this.avObject !== undefined
          ? VideoPlayerUtils.getPlaybackBufferingPercentage(
            this.videojsPlayer.currentTime(), 
            this.videojsPlayer.buffered(),
            // this.videojsPlayer.seekable(), // <-- Change .buffered() to this maybe? https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/buffering_seeking_time_ranges
            this.clock.videoFileLengthOpt
          )
          : 100.0
          this.setBufferedPercentage(bufferedPercentage <= 100.0 ? bufferedPercentage : 100.0)      

    })

    //  Start chain-reaction of animation frames.
    this.onAnimationFrame()
  },

  beforeDestroy () {
    this.stopAnimationFrame()
    if(this.avObject !== undefined){
      clearVideoPlayerAtomic(this)
    }

    if (this.videojsPlayer) {
      //  Docs: https://github.com/videojs/video.js/issues/4397
      //  videojs.dispose() disposes videojs-player AND DOM video player.

      //  https://docs.videojs.com/docs/api/player.html#Methodsdispose
      //  https://github.com/videojs/video.js/issues/4970

      this.videojsPlayer.dispose()
      //this.videojsPlayer.reset()

      this.videojsPlayer = undefined
      window.removeEventListener('unhandledrejection', this.onUnhandledDOMExepion)
    }
  }
}
</script>

<style lang="scss" scoped>
/* transitions to ease the showing of buffering and seeking divs */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-active {
  transition: opacity 0.4s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.player-messages {
  width: 200px;
  position: absolute;
  bottom: 18%;
  left: 50%;
  margin-left: -100px;
  background: rgba(0,0,0,0.3);
  border-radius: 8px;
  padding: 10px;
  text-shadow: 1px 1px #000;
}

.no-bg {
  background: rgba(0, 0, 0, 0);
}

.videoinfobar {
  background: white;
}

.videoPlayerBG {
  background: #0A0A0B;
  display: flex;
  align-items: center;
}

.vidplayeroverlay {
  display: flex;
  margin: auto;
  background: none;
  flex-direction: vertical;
  max-width: 60%;
  align-items: center;
  justify-content: center;
}

#canvas {
  display: none;
}

.audioPostrVideo {
  width: 100%;
  height: 40vh;
}

.lights-off {
  .audioPostrVideo {
    height: 100vh;
  }
}

video {
  width: 100%;
  min-height: 180px;
  height: calc((9 / 16) * 100vw);
  max-height: calc(100vh);
  outline: none;
}

.vjs-poster img {
  display: none !important;
}
</style>
