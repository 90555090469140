<template>
  <div
    :style="{
      borderColor: colors.mx.secondary,
      border: dropIt ? '1px solid' : '',
    }"
    class="drop"
    @dragover.prevent="
      caller !== 'screeningRoom' && !isDemoProjectsRow
        ? (dropIt = true)
        : undefined
    "
    @mouseout.prevent="
      caller !== 'screeningRoom' && !isDemoProjectsRow
        ? (dropIt = false)
        : undefined
    "
    @drop.prevent="onFileDrop"
  >
    <div>
      <!-- ONLY IN GRID MODE -->
      <div
        style="overflow: hidden"
        class="grid-container"
        v-if="viewOption === 'grid'"
      >
        <div
          v-if="!isDemoProjectsRow && caller === 'dashboard'"
          class="grid-item"
        >
          <uploadWidget
            ref="uploadWidget"
            @project-uploaded="onProjectUploaded"
            @upload-stopped="dropIt = false"
          ></uploadWidget>
        </div>

        <div
          class="grid-item"
          v-show="viewOption === 'grid'"
          v-for="project in filteredProjects"
          :key="project['.key']"
          @mouseover="selectProject(project)"
          @mouseleave="selectProject(undefined)"
        >
          <v-card
            :elevation="isSelected(project) ? 0 : 0"
            @click="$emit('on-project-edit-request', project)"
            class="pointer-on-hover"
            :class="[isSelected(project) ? 'selected' : 'text-hover']"
            style="
              background-color: transparent;
              border-radius: 6px;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <v-img
                style="object-fit: fill; border-radius: 8px; aspect-ratio: 16/9"
                v-if="project.thumb"
                :src="project.thumb"
                aspect-ratio="16/9"
              ></v-img>

              <v-img
                style="
                  object-fit: none;
                  width: 100%;
                  border-radius: 10px;
                  aspect-ratio: 16/9;
                  margin-top: 0 !important;
                  border: 1px solid;
                "
                :style="{ borderColor: colors.mx.panel }"
                v-if="!project.thumb"
                :src="logos.mixStageLogoOnlySmall"
                aspect-ratio="16/9"
              ></v-img>
            </div>

            <v-icon
              v-if="isSelected(project) && project.moderators.includes(userId)"
              style="position: absolute; top: 0.7rem; left: 10px"
              tag="button"
              @click.stop="$emit('on-project-delete-request', project)"
            >
              mdi-delete-outline
            </v-icon>

            <project-people
              v-show="isSelected(project)"
              style="position: absolute; top: 1px; right: -10px"
              tag="button"
              @request-open-purchase-dialog="
                $emit('request-open-purchase-dialog', $event)
              "
              :projectID="project['.key']"
              :projectDB="project"
              :small="true"
            />

            <v-col
              class="elevation-0 mt-1 ml-1"
              @click="$emit('on-project-edit-request', project)"
              style="
                font-size: 0.8em;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                padding: 0;
              "
            >
              <v-tooltip top :color="colors.black">
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    style="height: 100%; width: 100%; text-align: left"
                  >
                    <div
                      style="font-size: 13px; font-weight: 700"
                      class="ellipsis-span"
                    >
                      {{ project.project_name }}
                    </div>
                    <div
                      style="font-size: 11px; font-weight: 300"
                      class="ellipsis-span"
                    >
                      {{ normDate(project.created_date) }}
                    </div>
                  </div>
                </template>
                <!-- tooltip content -->
                <tooltip-content
                  class="grid-item"
                  :project="project"
                  :isDemoProjectsRow="isDemoProjectsRow"
                />
              </v-tooltip>
            </v-col>
          </v-card>
        </div>

        <div v-if="isDemoProjectsRow" class="grid-item">
          <v-tooltip bottom :color="colors.black">
            <template v-slot:activator="{ on }">
              <v-card
                v-on="on"
                v-if="!isDemoProjectsRow || projects.length === 0"
                elevation="4"
                class="pointer-on-hover mid-panel"
                style="
                  border-radius: 6px;
                  display: flex;
                  flex-direction: column;
                  background-clip: content-box;
                  align-items: center;
                "
              >
                <span style="padding-top: 10%">{{
                  isDemoProjectsRow ? 'Create demo projects' : 'Add project'
                }}</span>
                <v-icon
                  v-if="!isDemoProjectsRow"
                  @click="$emit('on-project-add-request')"
                  id="createCircle"
                  size="40"
                  style="margin: auto; padding: 10%"
                  >mdi-plus-circle-outline</v-icon
                >
                <v-icon
                  v-if="isDemoProjectsRow"
                  @click="$emit('on-demo-projects-restore-request')"
                  id="createCircle"
                  size="40"
                  style="margin: auto; padding: 10%"
                  >mdi-plus-circle-outline</v-icon
                >
              </v-card>
            </template>
            <div style="padding: 4px 4px 10px 4px">
              <div
                style="
                  text-align: left;
                  line-height: 2;
                  font-weight: 600;
                  color: purple;
                "
              >
                {{
                  isDemoProjectsRow ? 'Create demo projects' : 'Add New Project'
                }}
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
      <!-- END Grid view -->

      <!-- Table view -->
      <div
        v-if="viewOption === 'list'"
        class="panel"
        style="text-align: left; margin: 0 auto 0 auto"
      >
        <span
          v-if="caller !== 'screeningRoom'"
          style="
            margin: 10px 10px 10px 10px;
            padding: 10px 10px 10px 10px;
            font-size: 1.5em;
            font-weight: 500;
          "
        ></span>

        <v-data-table
          mobile-breakpoint="0"
          dark
          style="color: white"
          :style="
            caller === 'screeningRoom'
              ? 'max-width: 290px;'
              : 'max-width: 100%; min-height: 20vh;'
          "
          :headers="
            caller === 'screeningRoom' ? projectsHeaders2 : projectsHeaders
          "
          :hide-default-header="caller === 'screeningRoom'"
          :items="filteredProjects"
          :items-per-page="20"
          :footer-props="{
            'disable-items-per-page': false,
            'items-per-page-options': [20],
            'items-per-page-text': '',
          }"
          item-key=".key"
          class="dark-panel"
          :class="
            caller === 'screeningRoom'
              ? 'no-padding-table projects-table-sidebar'
              : ''
          "
          @click:row="$emit('on-project-edit-request', $event)"
        >
          <template v-slot:item.load="{ item }">
            <div
              style="
                width: 40px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              :style="`background-image: url(${
                item.thumb ? item.thumb : logos.mixStageLogoOnly
              }); background-size: cover; background-position: center;`"
            >
              <v-icon
                style="font-size: 2.2em"
                class="actionButtons"
                @click.stop="$emit('project-clicked', item)"
                >play_circle</v-icon
              >
            </div>
          </template>

          <template v-slot:item.thumb="{ item }">
            <v-img
              style="
                background: black;
                border-bottom: 4px solid rgb(21, 21, 21);
                width: 80px;
                border-radius: 3px;
                aspect-ratio: 16/9;
                margin-top: 0 !important;
              "
              contain
              v-if="item.thumb"
              :src="item.thumb"
              aspect-ratio="16/9"
              class="opaque-on-hover mt-4"
            ></v-img>

            <v-img
              style="
                border-bottom: 4px solid rgb(21, 21, 21);
                width: 80%;
                border-radius-top: 10px;
                aspect-ratio: 16/9;
                margin-top: 0 !important;
              "
              contain
              v-if="!item.thumb"
              :src="logos.mixStageLogoOnly"
              aspect-ratio="16/9"
              class="opaque-on-hover mt-4"
            ></v-img>
          </template>
          <template v-slot:item.name="{ item }">
            <span
              class="ellipsis-span"
              :style="
                caller === 'screeningRoom'
                  ? 'width: 200px; font-size: 0.8em;'
                  : '40%'
              "
              >{{ item.project_name }}</span
            >
          </template>
          <template v-slot:item.description="{ item }">
            <span class="non-essential-column">{{
              item.project_description
            }}</span>
          </template>

          <template v-slot:item.people="{ item }">
            <project-people
              class="non-essential-column"
              :projectID="item['.key']"
              :projectDB="item"
              :small="true"
            />
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
//ts-ignore
import uploadWidget from '@/components/projectscomponents/uploadWidget_restyle.vue'
import projectPeople from '@/components/versionscomponents/projectPeople.vue'
import tooltipContent from '@/components/projectscomponents/tooltipContent.vue'
import DisplayUtils from '@/lib/ui/DisplayUtils'

export default {
  // emits: ['on-project-edit-request', 'on-project-delete-request'],
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchString: {
      type: String,
      required: false,
      default: '',
    },
    userId: {
      type: String,
      required: true,
    },
    isDemoProjectsRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOption: {
      type: String,
      required: false,
      default: 'list',
    },
    caller: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    uploadWidget: uploadWidget,
    'project-people': projectPeople,
    'tooltip-content': tooltipContent,
  },
  data() {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      selectedProject: undefined,
      projectsHeaders: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'thumb',
          width: '2%',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '40%',
        },
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        // { text: 'Logo', align: 'center', sortable: false, value: 'logo', width: '60px'},
        // { text: 'Name', align: 'start', sortable: false, value: 'name', width: '' },
        // { text: 'Seats', align: 'center', sortable: false, value: 'seats', width: '60px'},
        // { text: 'Seated', align: 'center', sortable: false, value: 'seated', width: '60px' },
        // { text: 'Active Project', sortable: false, value: 'active', width: '60px' },
        // { text: 'Date started', value: 'date' },
        // { text: 'Moderators', value: 'moderators' },
        {
          text: 'Sharing',
          sortable: false,
          align: 'center',
          value: 'people',
          width: '5%',
        },
      ],
      projectsHeaders2: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'load',
          width: '10%',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '90%',
        },
      ],
      hovering: false,
      dropIt: false,
    }
  },
  computed: {
    filteredProjects() {
      console.log('detected change in prop')
      return this.projects.filter((project) => {
        let tagString = ''
        if (project.project_description_tags) {
          for (
            let index = 0;
            index < project.project_description_tags.length;
            index++
          ) {
            tagString =
              tagString + ', ' + project.project_description_tags[index]
          }
        }
        const searcher =
          tagString.toLowerCase() +
          project.project_name.toLowerCase() +
          ' ' +
          project.project_description.toLowerCase()

        return this.searchString
          ? searcher.match(this.searchString.toLowerCase())
          : searcher.match('')
      })
    },
  },
  methods: {
    onFileDrop(event) {
      console.log(event)
      console.log(this.$refs.uploadWidget)
      if (this.caller === 'dashboard') {
        console.log('returning the function')
        // @ts-ignore
        this.$refs.uploadWidget.addFiles(event)
      } else {
        console.log('returning undefined')

        return undefined
      }
    },
    normDate(date) {
      return DisplayUtils.prettyFormatDate(DisplayUtils.normalizeDate(date))
    },
    todoLoad(e) {
      console.log(e)
    },
    onProjectUploaded(e) {
      // Should open project edit with this projectId
      this.$emit('on-new-project-edit-request', e)
    },

    limitCharacters(str, length) {
      const _str = str.toString()
      if (_str.length > length) {
        return _str.slice(0, length + 1) + '...'
      } else {
        return _str
      }
    },

    selectProject(project) {
      // @ts-ignore
      this.selectedProject = project
    },

    isSelected(e) {
      // @ts-ignore
      if (!e || !this.selectedProject) return false
      // @ts-ignore
      if (e['.key'] === this.selectedProject['.key']) {
        return true
      }
      return false
    },
  },

  async beforeMount() {},
}
</script>

<style lang="scss">
.mixstage-data-table .v-data-table__wrapper tbody tr {
  max-height: 50px;
  overflow-y: auto;
}

.ellipsis-span {
  display: block; /* or block */
  max-width: 90%; /* or any desired width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.grid-container {
  display: grid;
  align-items: center;
}

.grid-item {
  // border: 3px solid #151515;  /* Just for visualization */
  padding: 10% 5px;
}

/* For xs and default */
.grid-container {
  grid-template-columns: repeat(2, 1fr);
}

/* For sm */
@media (min-width: 600px) and (max-width: 959px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-item {
    max-width: calc((100vw - 4rem) / 3);
  }
}

/* For md */
@media (min-width: 960px) and (max-width: 1263px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-item {
    max-width: calc((100vw - 0.2 * 5rem) / 4);
  }
}

/* For lg */
@media (min-width: 1264px) and (max-width: 1903px) {
  .grid-container {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid-item {
    max-width: calc((100vw - 300px - 0.2 * 7rem) / 6);
  }
}

/* For xl */
@media (min-width: 1904px) {
  .grid-container {
    grid-template-columns: repeat(8, 1fr);
  }
  .grid-item {
    max-width: calc((100vw - 300px - 0.2 * 9rem) / 8);
  }
}

/* For xl */
@media (min-width: 2100px) {
  .grid-container {
    grid-template-columns: repeat(10, 1fr);
  }
  .grid-item {
    max-width: calc((100vw - 300px - 0.2 * 11rem) / 10);
  }
}

@media (max-width: 767px) {
  .v-data-table-header {
    display: none;
  }

  .v-data-table .v-data-table__wrapper td,
  .v-data-table .v-data-table__wrapper th {
    font-size: 10px;
  }
  .v-data-table .v-data-table__wrapper tr {
    height: 40px;
  }
  .v-data-table .v-data-table__wrapper .non-essential-column {
    display: none;
  }
}
tr .actionButtons {
  visibility: hidden;
}

tr:hover .actionButtons {
  visibility: visible;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.8em;
  height: auto;
}

.projects-table-sidebar {
  flex: 1;
  overflow-y: auto;

  .v-data-table__wrapper {
    height: calc(100vh - 325px);
    overflow-y: auto;
  }
  tr {
    cursor: pointer;
  }
}
</style>
