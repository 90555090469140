const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const isArrayInstance = SpotterfishCore.isArrayInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const isObjectInstance = SpotterfishCore.isObjectInstance

const Timecode = require('../spotterfish_library/utils/Timecode')
const _ = require('lodash')

const SpotterfishSession = require('./SpotterfishSession')
const CloudClient = require('./CloudClient')


const kExampleProjectDB =
{
  "people_seated": 0,
  "current_version": "n38C7USL224HqwgVmOZP",
  "live_screening_room": "null",
  "project_name": "Lord of the rings",
  "previous_versions": [],
  "project_description_tags": [],
  "project_people": [
    "W01fWzhW7mb52OCaTLAP2N597Co1"
  ],
  "project_description": "",
  "versions": [
    "n38C7USL224HqwgVmOZP"
  ],
  "current_partially_uploaded_file": "",
  "files": [
    "ph8cwKme09jAYDEsPBAR"
  ],
  "created_date": "2021-02-09T13:16:45.000Z",
  "moderators": [
    "W01fWzhW7mb52OCaTLAP2N597Co1"
  ],
  "current_version_marker_lane": "UN4p6Aqbx2TSbyo5nhyP",
  ".key": "coPwzlQGsuQaXaIPxGa5"
}


const kExampleVersionDB =
{
  "description": "",
  "name": "Read from shared state",
  "video_file": {
    "src": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653645481&Signature=Ctvey0n59vgmhwCjL%2FeF1CsJou0WN5uFrRaYPw4VB4gAHz8U81xzZqK1VzhcWA1jLo%2BoTEXla4oTkGEjv%2FPTQA3cGPHj%2FVpA1nETi8hsCqhuRZwKwzr7t0W3c3gmIpF3jAARdT6HY1diwKgrTKdCsNIf49zVeQa01hdzal5TxkubSKz5na8ZGiQ%2FE17%2Fwxy6kqu2zBWn6OmINWltno8os7h%2FS5R2LH%2BoqQnZy7liERiCZNhF0c62OxY%2BMbxIVtgxkVZo4784Gb6CSQi199jrKkr0c7FoMHdWHRZeJzbZg3B5elcx7H9vhM2XwKdZGn5YXOlAWCnetMAHIqGfa1Jdlg%3D%3D",
    "metadata": {
      "contentType": "video/mp4"
    }
  },
  "audio_file": [
    {
      "src": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653645481&Signature=Ctvey0n59vgmhwCjL%2FeF1CsJou0WN5uFrRaYPw4VB4gAHz8U81xzZqK1VzhcWA1jLo%2BoTEXla4oTkGEjv%2FPTQA3cGPHj%2FVpA1nETi8hsCqhuRZwKwzr7t0W3c3gmIpF3jAARdT6HY1diwKgrTKdCsNIf49zVeQa01hdzal5TxkubSKz5na8ZGiQ%2FE17%2Fwxy6kqu2zBWn6OmINWltno8os7h%2FS5R2LH%2BoqQnZy7liERiCZNhF0c62OxY%2BMbxIVtgxkVZo4784Gb6CSQi199jrKkr0c7FoMHdWHRZeJzbZg3B5elcx7H9vhM2XwKdZGn5YXOlAWCnetMAHIqGfa1Jdlg%3D%3D",
      "metadata": {
        "contentType": "video/mp4"
      }
    }
  ],
  "video_file_start_smpte": "01:00:00:00",
  "video_framerate": 25,
  ".key": "n38C7USL224HqwgVmOZP"
}


// Also: tab_to_talk
const kExampleSharedStateDB =
{
  "file_state": {
    "audioFiles": [
      {
        "audio_file_start_smpte": "01:00:00:00",
        "audio_samplerate": 48000,
        "bucket_name": "audiopostrdev.appspot.com",
        "contentType": "video/mp4",
        "file_id": "ph8cwKme09jAYDEsPBAR",
        "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
        "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D"
      }
    ],
    "projectId": "coPwzlQGsuQaXaIPxGa5",
    "versionId": "n38C7USL224HqwgVmOZP",
    "videoFiles": [
      {
        "bucket_name": "audiopostrdev.appspot.com",
        "contentType": "video/mp4",
        "file_id": "ph8cwKme09jAYDEsPBAR",
        "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
        "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D",
        "video_file_start_smpte": "01:00:00:00",
        "video_framerate": 25
      }
    ]
  },
  "light_switch_status": 1,
  "marker_list_enabled": true,
  "streaming_user": "static_audio",
  "transport_controls_enabled": true,
  "ufb": {
    "rooms": [
      null,
      {
        "custom_background_color": false,
        "custom_logo": false,
        "domain_whitelist_enabled": false,
        "requires_email_verification": false,
        "requires_room_pin": false,
        "requires_two_factor_auth": false,
        "seats": 20,
        "show_free_version_banner": false,
        "video_chat_enabled": false
      },
      {
        "custom_background_color": false,
        "custom_logo": false,
        "domain_whitelist_enabled": false,
        "requires_email_verification": false,
        "requires_room_pin": false,
        "requires_two_factor_auth": false,
        "seats": 5,
        "show_free_version_banner": false,
        "video_chat_enabled": true
      },
      {
        "custom_background_color": false,
        "custom_logo": false,
        "daw_streaming_enabled": true,
        "domain_whitelist_enabled": false,
        "requires_email_verification": false,
        "requires_room_pin": false,
        "requires_two_factor_auth": false,
        "seats": 5,
        "show_free_version_banner": false,
        "video_chat_enabled": true
      }
    ]
  },
  "users": {
    "W01fWzhW7mb52OCaTLAP2N597Co1": {
      "active": true,
      "audio_muted": false,
      "buffering": false,
      "in_bathroom_mirror": false,
      "seat": 1,
      "talking": false,
      "user_email": "martin@spotterfish.io",
      "user_id": "W01fWzhW7mb52OCaTLAP2N597Co1",
      "user_name": "MartinDev2",
      "video_muted": false
    }
  },
  "volume": 1
}

/*
  "buffering_users": [],
  "seeking_users": [],
  "fileStateXL": {
    "file_state": {
      "audioFiles": [
        {
          "audio_file_start_smpte": "01:00:00:00",
          "audio_samplerate": 48000,
          "bucket_name": "audiopostrdev.appspot.com",
          "contentType": "video/mp4",
          "file_id": "ph8cwKme09jAYDEsPBAR",
          "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
          "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D"
        }
      ],
      "projectId": "coPwzlQGsuQaXaIPxGa5",
      "versionId": "n38C7USL224HqwgVmOZP",
      "videoFiles": [
        {
          "bucket_name": "audiopostrdev.appspot.com",
          "contentType": "video/mp4",
          "file_id": "ph8cwKme09jAYDEsPBAR",
          "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
          "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D",
          "video_file_start_smpte": "01:00:00:00",
          "video_framerate": 25
        }
      ]
    },
    "avFiles": {
      "audioFiles": [
        {
          "src": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D",
          "metadata": {
            "contentType": "video/mp4"
          }
        }
      ],
      "videoFile": {
        "src": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D",
        "metadata": {
          "contentType": "video/mp4"
        }
      }
    },
    "frameRateKey": "PAL",
    "offset": 3600
  }
*/

function unpackScreeningRoomDB (snapshotData, snapshotId) {
  assert(snapshotData !== null)

  // data() makes DEEP COPY of screening room = OK for us to modify.
  const screeningRoom = snapshotData
  screeningRoom['.key'] = snapshotId

  console.debug('Got screeningRoom:', screeningRoom)

  assert(checkInvariantScreeningRoomDB(screeningRoom))

  return screeningRoom
}

function checkInvariantScreeningRoomDB (screeningRoomDB) {
  assert(SpotterfishCore.isVueReactive(screeningRoomDB) === false)

  // TODO: Check presence etc. for relevant database fields.
  assert(screeningRoomDB !== null)
  assert(screeningRoomDB['.key'] !== undefined)
  assert(screeningRoomDB['.key'] !== null)
  // TODO: Check type and format of key/id?
  return true
}





/*
  "file_state": {
    "audioFiles": [
      {
        "audio_file_start_smpte": "01:00:00:00",
        "audio_samplerate": 48000,
        "bucket_name": "audiopostrdev.appspot.com",
        "contentType": "video/mp4",
        "file_id": "ph8cwKme09jAYDEsPBAR",
        "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
        "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D"
      }
    ],
    "projectId": "coPwzlQGsuQaXaIPxGa5",
    "versionId": "n38C7USL224HqwgVmOZP",
    "videoFiles": [
      {
        "bucket_name": "audiopostrdev.appspot.com",
        "contentType": "video/mp4",
        "file_id": "ph8cwKme09jAYDEsPBAR",
        "path": "coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4",
        "url": "https://storage.googleapis.com/audiopostrdev.appspot.com/coPwzlQGsuQaXaIPxGa5/The.Lord.of.the.Rings.The.Two.Towers-552c546336.mp4?GoogleAccessId=localdev%40audiopostrdev.iam.gserviceaccount.com&Expires=1653475274&Signature=QEjN%2FE8U6eztLQF7eWVvZN4kmvxnfyVTlypBeIUDKgUmYM64AXSX6xyocoyg8mSqaXMQ85e%2FX%2BJVSJM2IVl1mPv9p0%2F4BmmMyql6rqPr4PtPJrgPnfQI2GnGzOyjwh0z3o%2FybeDi3LAlOCS4B1FWGYjvR5ZnFnzkA0On9iwjMn0Xtmh8uOjabGbqRpvIGOyYvVwwdqdHVU3Ttgi1hCW2F2JZfscepKkV0DRgsPdlBj3LXhVCk7ma%2BMQx2LqFgQCkbH6BmbXj%2FoUESbCNBseQfYcqUuOVCMAIxSRSYRDZ7LgZrJ3BJi3mRTLL1jREb9kthcf4fMehJt%2F3rWtW7n960Q%3D%3D",
        "video_file_start_smpte": "01:00:00:00",
        "video_framerate": 25
      }
    ]
  },
*/
function checkInvariantFileStateDB(val){
  assert(SpotterfishCore.isObjectInstance(val))

  assert(val.audioFiles === undefined || SpotterfishCore.isArrayInstance(val.audioFiles))
  assert(SpotterfishCore.isStringInstance(val.projectId))
  assert(SpotterfishCore.isStringInstance(val.versionId))
  assert(val.videoFiles === undefined || SpotterfishCore.isArrayInstance(val.videoFiles))

  return true
}

function checkInvariantSharedStateDB(val){
  assert(SpotterfishCore.isObjectInstance(val))

  assert(val.file_state === undefined || val.file_state === 'error' || checkInvariantFileStateDB(val.file_state))
  assert(val.light_switch_status === undefined || SpotterfishCore.isNumberInstance(val.light_switch_status))
  assert(SpotterfishCore.isBooleanInstance(val.marker_list_enabled))
  assert(val.streaming_user === undefined || SpotterfishCore.isStringInstance(val.streaming_user))
  assert(val.transport_controls_enabled === undefined || SpotterfishCore.isBooleanInstance(val.transport_controls_enabled))
  assert(SpotterfishCore.isObjectInstance(val.ufb))

  assert(SpotterfishCore.isObjectInstance(val.users))
  assert(SpotterfishCore.isNumberInstance(val.volume))

  return true
}



function checkInvariantFileStateXL(s){
  assert(isObjectInstance(s))
  assert(isObjectInstance(s.file_state))
  assert(isObjectInstance(s.avFiles))
  assert(Timecode.checkFrameRateKey(s.frameRateKey))
  assert(isNumberInstance(s.offset))
  return true
}

//  Notice that refinesSharedState is a sharedStateDB with extra fields
function checkInvariantRefinedSharedState(val){
  assert(SpotterfishCore.isObjectInstance(val))
  
  assert(checkInvariantSharedStateDB(val))

  assert(SpotterfishCore.isArrayInstance(val.buffering_users))
  assert(SpotterfishCore.isArrayInstance(val.seeking_users))
  assert(val.fileStateXL === undefined || checkInvariantFileStateXL(val.fileStateXL))

  return true
}

function detectSharedStateError(sharedStateDB, currentUserId){
  assert(checkInvariantSharedStateDB(sharedStateDB))
  assert(isStringInstance(currentUserId))

  // Immediately end session if the kill command was received.
  if (sharedStateDB.kill === true) {
    console.log('ScreeningRoomConnection-shared-state-kill-command')
    throw new Error('ScreeningRoomConnection-shared-state-kill-command')
  }

  // Check if user is active in shared state - else throw error
  // console.log(!sharedState.users[currentUserId])
  else if (!sharedStateDB.users[currentUserId].active) {
    console.log('user has been removed from session')
    throw new Error('user no longer active in session')
  }
}

function getAVFiles (fileStateDB) {
  assert(fileStateDB !== undefined)

  const videoFile = {
    src: fileStateDB.videoFiles[0].url,
    metadata: {
      contentType: fileStateDB.videoFiles[0].contentType
    }
  }

  const audioFiles = []

  if (fileStateDB.audioFiles) {
    for (const file of fileStateDB.audioFiles) {
      const f = {
        src: file.url,
        metadata: {
          contentType: file.contentType
        }
      }
      audioFiles.push(f)
    }
  } else {
    // If no audio file, use video file start time as audio file start time
  }

  // ??? make new checkInvariant()
  assert(SpotterfishCore.isArrayInstance(audioFiles))

  return {
    audioFiles: audioFiles,
    videoFile: videoFile
  }
}



function calcFileStateXL(sharedStateDB){ 
  assert(checkInvariantSharedStateDB(sharedStateDB))

  const file_stateDB0 = sharedStateDB.file_state
  if (file_stateDB0 !== undefined && file_stateDB0 !== 'error') {
      const file_stateDB = file_stateDB0
      assert(file_stateDB.projectId !== undefined)

      const frk = Timecode.findFrameRateKeyFromNickname(file_stateDB.videoFiles[0].video_framerate)
      // daw stream offset - should be calcualted with the same function as the UI - is 0 on new project 2022-09
      const offset = Timecode.SMPTEStringToSeconds(file_stateDB.videoFiles[0].video_file_start_smpte, frk)
      // ??? XXX
      const fileStateXL = {
        file_state: file_stateDB,
        avFiles: getAVFiles(file_stateDB),
        frameRateKey: frk,
        offset: offset,
        timecodeOffsetSeconds: file_stateDB.timecode_offset_seconds
      }
      assert(checkInvariantFileStateXL(fileStateXL))
      return fileStateXL
  }

  //  No media was selected
  else {
    return undefined
  }
}



/*
  queueRecord: {
    index: 0
    timestampMS: 0,
    type: 'screeningroomDB' or 'sharedstateDB'
    data: screeningroomDB or sharedstateDB
  }
*/
function checkInvariantQueueRecord(val){
  assert(SpotterfishCore.isObjectInstance(val))

  assert(SpotterfishCore.isNumberInstance(val.index) && val.index  >= 0)
  assert(SpotterfishCore.isNumberInstance(val.timestampMS) && val.timestampMS  >= 0)
  assert(SpotterfishCore.isStringInstance(val.type) && (val.type === 'screeningroomDB' || val.type === 'sharedstateDB'))

  if(val.type === 'screeningroomDB'){
    assert(checkInvariantScreeningRoomDB(val.data))
  }
  else if(val.type === 'sharedstateDB'){
    assert(checkInvariantSharedStateDB(val.data))
  }
  else {
  }

  return true
}


function getUserStatues(sharedStateDB){
  assert(checkInvariantSharedStateDB(sharedStateDB))

  let buffering_users = []
  let seeking_users = []

  for (const key in sharedStateDB.users) {
    if (sharedStateDB.users.hasOwnProperty(key)) {
      const value = sharedStateDB.users[key]
      if (value.buffering) {
        buffering_users.push(value)
      }
      if (value.seeking) {
        seeking_users.push(value)
      }
    }
  }
  return { buffering_users, seeking_users }
}

//  Returned "RefinedSharedState"
function unpackSharedState(sharedStateDB){
  assert(checkInvariantSharedStateDB(sharedStateDB))

  let a = _.cloneDeep(sharedStateDB)

  const s = getUserStatues(sharedStateDB)
  a.buffering_users = s.buffering_users
  a.seeking_users = s.seeking_users
  a.fileStateXL = calcFileStateXL(sharedStateDB)

  assert(checkInvariantRefinedSharedState(a))
  return a
}

//  Executes command by updating session's internal state + calling client's callback.
//  Throws on errors
async function executeCommand(session, rec){
  assert(checkInvariantScreeningRoomConnection(session))
  assert(checkInvariantQueueRecord(rec))

  if(rec.type === 'screeningroomDB'){
    const value0 = session.screeningRoomDBCopy
    const value = rec.data
    assert(checkInvariantScreeningRoomDB(value0))
    assert(checkInvariantScreeningRoomDB(value))

    session.screeningRoomDBCopy = value

    if(session.onScreeningRoomDBChanged !== undefined){
      // @ts-ignore
      await session.onScreeningRoomDBChanged(value, value0)
    }
  }
  else if(rec.type === 'sharedstateDB'){
    const value0 = session.refinedSharedState
    const value = rec.data
    assert(checkInvariantSharedStateDB(value0))
    assert(checkInvariantSharedStateDB(value))

    const refinedValue = unpackSharedState(value)
    session.refinedSharedState = refinedValue

    const refinedValue0 = unpackSharedState(value0)

    if(session.onSharedStateChanged !== undefined){
      // @ts-ignore
      await session.onSharedStateChanged(refinedValue, refinedValue0)
    }
  }
  else {
    assert(false)
  }
}


async function transferInputQueueToClient(session){
  assert(checkInvariantScreeningRoomConnection(session))

  if(session.transferDepth === 0){
    session.transferDepth++

    try {
      while(session.inputQueue.length > 0){

        //  MOVE input queue elements to local variable
        const queueCopy = session.inputQueue
        session.inputQueue = []

        for(let i = 0; i < queueCopy.length; i++){
          try {
            await executeCommand(session, queueCopy[i])
          }

          //  On exception, skip this command.
          catch(error){
            console.error(error)
          }
        }
      }

      session.transferDepth--
    }
    catch(error){
      session.transferDepth--
      throw error
    }
  }
}

//  Queue the new snapshot = screeningRoomDB-value
function queueScreeningRoomDBSnapshot_safe(srs, snapshot) {
  assert(checkInvariantScreeningRoomConnection(srs))
  assert(srs.screeningRoomDBDepth >= 0)
  assert(_.isObject(snapshot))

  srs.screeningRoomDBDepth++

  try {
    // data() makes DEEP COPY of screening room = OK for us to modify.
    const value = unpackScreeningRoomDB(snapshot.data(), snapshot.id)

    // IDEA: Store previous value in record?
    const elapsedTimeMS = performance.now() - srs.startTime;
    const rec = {
      index: srs.inputQueueCount,
      timestampMS: elapsedTimeMS,
      type: 'screeningroomDB',
      data: value
    }
    assert(checkInvariantQueueRecord(rec))

    //@ts-ignore
    srs.inputQueue.push(rec)
    srs.inputQueueCount++


    //  NO-THROW

    srs.screeningRoomDBDepth--
    assert(srs.screeningRoomDBDepth >= 0)
  }
  catch(error){
    srs.screeningRoomDBDepth--
    assert(srs.screeningRoomDBDepth >= 0)
    throw error
  }
}

//  Detects special kill-command and throws exception
function queueSharedStateUpdate_safe(srs, sharedStateDB, currentUserId) {
  assert(checkInvariantScreeningRoomConnection(srs))
  assert(srs.sharedStateDepth >= 0)
  assert(_.isObject(sharedStateDB))
  assert(_.isString(currentUserId))

  // file_state = non-persistent playback settings, as shared between all clients in a screening room.

  srs.sharedStateDepth++

  try {
    detectSharedStateError(sharedStateDB, currentUserId)

    const elapsedTimeMS = performance.now() - srs.startTime
    const rec = {
      index: srs.inputQueueCount,
      timestampMS: elapsedTimeMS,
      type: 'sharedstateDB',
      data: sharedStateDB
    }
    assert(checkInvariantQueueRecord(rec))

    //@ts-ignore
    srs.inputQueue.push(rec)
    srs.inputQueueCount++


    //  NO-THROW

    srs.sharedStateDepth--
    assert(srs.sharedStateDepth >= 0)
  }
  catch(error){
    srs.sharedStateDepth--
    assert(srs.sharedStateDepth >= 0)
    throw error
  }
}


const makeSharedStateTimestamperInterval = (firebase, sharedStateID, userID) => {
  const activeInterval = setInterval(() => {
    try {
      updateUserSharedState0(
      	firebase,
      	sharedStateID,
      	userID,
      	{
        active_time: firebase.database.ServerValue.TIMESTAMP
       }
      )
    } catch (error) {
      console.log(error)
    }
  }, 5000)
  return activeInterval
}

//  Will make activeFlag be true/false
// https://cloud.google.com/firestore/docs/solutions/presence
function listenToFirebaseCurrentUserConnected(firebase, activeFlagRef) {
  const ref = firebase.database().ref('.info/connected')

  ref.on('value', function (snapshot) {
    const val = snapshot.val()

    console.debug(`Got a new value for .info/connected: ${ val }`)

    if (val === false) {
      console.debug('Current user is not connected, do nothing')
      return
    }
  
    activeFlagRef.onDisconnect()
    .set(false)
    .then(function () {
      console.debug('Setting up onDisconnect handler and setting status to true')
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect() 
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      activeFlagRef.set(true)
    })
  })
  return ref
}

async function updateUserSharedState0(firebase, screeningRoomID, userID, value){
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isStringInstance(screeningRoomID))
  assert(SpotterfishCore.isStringInstance(userID))
  assert(SpotterfishCore.isObjectInstance(value))

  await new Promise((resolve, reject) => {
    firebase.database().ref(`shared_state/${ screeningRoomID }/users/${ userID }`)
    .update(value)
    .then(() => { resolve(undefined) })
    .catch((error) => {
      reject(error)
    })
  })
}

async function updateUserSharedState2(session, value){
  assert(checkInvariantScreeningRoomConnection(session))

  updateUserSharedState0(
  	session.spotterfishSession.firebase,
  	session.screeningRoomDBCopy['.key'],
  	session.spotterfishSession.userSession.firebaseCurrentUser.uid,
  	value
  )

  assert(checkInvariantScreeningRoomConnection(session))
}

function checkInvariantScreeningRoomConnection (session) {
  // on hot reload screeningRoomSession may be undefined
  // let's allow it
  if (session === undefined) return true

  assert(SpotterfishCore.isVueReactive(session) === false)

  // TODO: Check presence etc. for relevant database fields.
  assert(SpotterfishCore.isObjectInstance(session))


  assert(SpotterfishCore.isNumberInstance(session.startTime) && session.startTime >= 0)

  assert(SpotterfishCore.isNumberInstance(session.activeInterval))

  assert(SpotterfishSession.checkInvariantSpotterfishSession(session.spotterfishSession))

  assert(SpotterfishCore.isArrayInstance(session.inputQueue))
  assert(SpotterfishCore.isNumberInstance(session.inputQueueCount) && session.inputQueueCount >= 0)
  assert(SpotterfishCore.isNumberInstance(session.transferDepth) && session.transferDepth >= 0)

  assert(session.onScreeningRoomDBChanged === undefined || SpotterfishCore.isFunctionInstance(session.onScreeningRoomDBChanged))
  assert(session.onSharedStateChanged === undefined || SpotterfishCore.isFunctionInstance(session.onSharedStateChanged))

  assert(typeof session.sharedStateID === 'string')
  assert(session.sharedStateID !== '')

  // This is a copy. It is kept up to date when DB changes
  assert(checkInvariantScreeningRoomDB(session.screeningRoomDBCopy))

  assert(checkInvariantRefinedSharedState(session.refinedSharedState))

  assert(SpotterfishCore.isFunctionInstance(session.screeningRoomListener))
  assert(session.screeningRoomDBDepth >= 0)

  assert(SpotterfishCore.isObjectInstance(session.sharedStateRef))
  assert(session.sharedStateDepth >= 0)

  assert(SpotterfishCore.isObjectInstance(session.sharedStateUserActiveRef))
  // NOTICE: Something has changed when closing the room - this undefined allowed is temporary
  assert(session.firebaseUserConnectedRef === undefined || SpotterfishCore.isObjectInstance(session.firebaseUserConnectedRef))

  return true
}

/*
  SCREENING ROOM CONNECTION
  A slot for user is allocated in the screening room, a session created if needed.
  Updates are tracked for the session in the databases.


  # fuseBlownFunc
  Called if connection goes down and cannot be restored.
  this.errorFuse has already been set to the error (no longer undefined).
  Screening room connection is still a valid object, but in error-mode and will not update correctly. Client needs to close the screeningRoomConnection at some point.
  fuseBlownFunc is only called ONCE.
  fuseBlownFunc is only called if openScreeningRoomConnection() returns successfully, else openScreeningRoomConnection() throws an exception.
  fuseBlownFunc() can get exception 1 (kill command).

  # Exception
  1. new Error('ScreeningRoomConnection-shared-state-kill-command')
*/
async function openScreeningRoomConnection(spotterfishSession, roomID, fuseBlownFunc){
  //  These are effect-related variables that needs to be closed on exceptions.
  let activeIntervalEffect = undefined
  let sharedStateRefEffect = undefined
  let firebaseUserConnectedRefEffect = undefined
  let sharedStateUserActiveRef = undefined
  let screeningRoomListenerEffect = () => {}
  let sharedStateID = undefined
  let markerListenerEffect = () => {}

  let constructedFlag = false

  // First of all, set up the realtime database session, since write permissions for
  // any data in that state depends on the user ID being in there in the first place.
  const currentUserId = spotterfishSession.userSession.firebaseCurrentUser.uid

  console.log(`[[session]] Initiating session allocation using userID = ${ currentUserId } and roomId = ${ roomID }`)

  try {
    //  About call_CFallocateSession()
    //  Call cloud function to open screening room session: join existing or create new one.
    //  Checks permissions, allocates shared state. Sets THIS user's active-record to TRUE.
    //  GC will close shared_state after last person leaves (all active-flags are false)
    //  User leaves its active-record in shared_state, but sets it to false.
    //  Service uses active-flags inside sharedState to see which users are currently in the screening room session.
    //  Will operate on both Firebase and Firestore databases.
    const allocateResult = await CloudClient.call_CFallocateSession(spotterfishSession.firebase, roomID)
    console.log(`[[session]] Got session object: ${ JSON.stringify(allocateResult) }`)
    /*
      As returned by Cloud function 'CFallocateSession'
      This is an allocation record for a session in screening_sessions-database table
      allocateResult: {
        motion_app_id: "7454224328522145177",
        motion_id: "MBBkGQ4SMDlcDhYaK7aI",
        motion_name: "sfish00034",
        shared_state_id: "2TxLetqwV6n6Ho8ObeRL"
      }
    */
    assert(allocateResult.shared_state_id === roomID)
    sharedStateID = allocateResult.shared_state_id



    // Returns the estimated clock skew between the local machine and the Firebase servers.
    // It is not intended to be used for exact millisecond timing, but it seems to get very close
    const ref = spotterfishSession.firebase.database().ref(`.info/serverTimeOffset`)
    const snap = await ref.once('value')
    const serverTimeOffset = snap.val()
    const localBaseTime = Date.now() - performance.now()

    // start interval to post active time to shared state
    activeIntervalEffect = makeSharedStateTimestamperInterval(spotterfishSession.firebase, sharedStateID, currentUserId)


    // Read initial data.
    const snapshot = await spotterfishSession.firestoreDB.collection('screening_rooms').doc(roomID).get()
    if (!snapshot.exists) {
      throw new Error('Missing room data')
    }
    let screeningRoomDB0 = unpackScreeningRoomDB(snapshot.data(), snapshot.id)
    assert(checkInvariantScreeningRoomDB(screeningRoomDB0))


    const sharedStatePath = `shared_state/${ sharedStateID }`
    sharedStateRefEffect = spotterfishSession.firebase.database().ref(sharedStatePath)

    const sharedStateSnapshot = await sharedStateRefEffect.once('value')
    if(sharedStateSnapshot.exists() === false){
      throw new Error('Internal error - no shared state DB exists')
    }
    detectSharedStateError(sharedStateSnapshot.val(), currentUserId)
    const sharedState0 = unpackSharedState(sharedStateSnapshot.val())

    sharedStateUserActiveRef = spotterfishSession.firebase.database().ref(`shared_state/${ sharedStateID }/users/${ currentUserId }/active`)

    const result = {
      errorFuse: undefined,
      startTime: performance.now(),
      activeInterval: activeIntervalEffect,
      spotterfishSession: spotterfishSession,

      serverTimeOffset: serverTimeOffset,
      localBaseTime: localBaseTime,

      inputQueue: [],
      inputQueueCount: 0,
      transferDepth: 0,

      onScreeningRoomDBChanged: undefined,
      onSharedStateChanged: undefined,

      sharedStateID: sharedStateID,

      screeningRoomDBCopy: screeningRoomDB0,
      refinedSharedState: sharedState0,

      screeningRoomListener: undefined,
      screeningRoomDBDepth: 0,

      sharedStateRef: sharedStateRefEffect,
      sharedStateDepth: 0,

      sharedStateUserActiveRef: sharedStateUserActiveRef,
      firebaseUserConnectedRef: undefined,
      // This is added here, and updated once a project is selected
      markerListener: markerListenerEffect
    }


    const callbackErrorFunc = async (error) => {
      if(result.errorFuse == undefined) {
        result.errorFuse = error
        if(constructedFlag){
          await fuseBlownFunc(error)
        }
      }
    }



    // LISTENS FOR REALTIME CHANGES IN ACTIVE SCREENING ROOM
    // ALL WE NEED TO UPDATE ACTIVE PROJECT ON ALL CONNECTED USERS
    //??? We get a callback for initial snapshot. Unknown if this happens asynchronously.
    // https://firebase.google.com/docs/firestore/query-data/listen
    //  "After an error, the listener will not receive any more events, and there is no need to detach your listener."
    //  Error: we may get a permission error (for example) *directly* or while we're executing openScreeningRoomConnection() before it has returned or long after.
    screeningRoomListenerEffect = spotterfishSession.firestoreDB.collection('screening_rooms')
    .doc(roomID)
    .onSnapshot(

      async (snapshot) => {
        //  Do not throw out of the onSnapshot-function.
        try {
          return queueScreeningRoomDBSnapshot_safe(result, snapshot)
        }
        catch(error){
          await callbackErrorFunc(error)
        }
      },
      async (error) => callbackErrorFunc
    )
    // @ts-ignore
    result.screeningRoomListener = screeningRoomListenerEffect

    // file_state = non-persistent playback settings, as shared between all clients in a screening room.
    sharedStateRefEffect.on(
      'value',

      async function (snapshot) {
        //  Do not throw out of the onSnapshot-function.
        try {
          if (snapshot.val()) {
            queueSharedStateUpdate_safe(result, snapshot.val(), currentUserId)
          }
        }
        catch(error){
          await callbackErrorFunc(error)
        }
      },
      async (error) => callbackErrorFunc
    )

    // Set up a presence check to set the online status of the current user to false
    // if user goes offline. This event is listened to by a cloud function that will
    // clean up the session if all users have gone offline. Clouse listens to sharedStateUserActiveRef
    console.log(`[[session]] Adding presence check for user ${ currentUserId }`)
    firebaseUserConnectedRefEffect = listenToFirebaseCurrentUserConnected(spotterfishSession.firebase, sharedStateUserActiveRef)
    result.firebaseUserConnectedRef = firebaseUserConnectedRefEffect

    assert(checkInvariantScreeningRoomConnection(result))

    if(result.errorFuse){
      throw result.errorFuse
    }

    constructedFlag = true

    return {
      session: result,
      motion_app_id: allocateResult.motion_app_id,
      motion_name: allocateResult.motion_name
    }
  }

  catch(error){
    // TODO: Catch all exceptions and call Sentry.captureException(error) AND reject.

    if(sharedStateUserActiveRef !== undefined) {
      sharedStateUserActiveRef.onDisconnect().cancel()
      sharedStateUserActiveRef.off()
      sharedStateUserActiveRef.set(false)

      sharedStateUserActiveRef = undefined
    }

    if(firebaseUserConnectedRefEffect !== undefined){
      firebaseUserConnectedRefEffect.off()
      firebaseUserConnectedRefEffect = undefined
    }
  
    if(sharedStateRefEffect !== undefined) {
      sharedStateRefEffect.off()
      sharedStateRefEffect = undefined
    }
  
    screeningRoomListenerEffect()
    markerListenerEffect()
    if(activeIntervalEffect !== undefined){
      clearInterval(activeIntervalEffect)
      activeIntervalEffect = undefined
    }

    throw error
  } 
}

async function closeScreeningRoomConnection (session) {
  assert(checkInvariantScreeningRoomConnection(session))

  {
    session.sharedStateUserActiveRef.onDisconnect().cancel()
    session.sharedStateUserActiveRef.off()
    session.sharedStateUserActiveRef.set(false)
  }

  session.firebaseUserConnectedRef.off()  

  session.sharedStateRef.off()
  
  session.screeningRoomListener()
  session.markerListener()
  clearInterval(session.activeInterval)
  session.activeInterval = undefined
}

//  Reads internal queues, updates internal state, calls client callbacks if any
async function updateScreeningRoomConnection(session){
  assert(checkInvariantScreeningRoomConnection(session))

  await transferInputQueueToClient(session)

  assert(checkInvariantScreeningRoomConnection(session))
}

//  f can throw exceptions, those are ignored and the update is considered complete
function setScreeningRoomDBChangedCallback(session, f){
  assert(checkInvariantScreeningRoomConnection(session))

  session.onScreeningRoomDBChanged = f

  assert(checkInvariantScreeningRoomConnection(session))
}

//  f can throw exceptions, those are ignored and the update is considered complete
function setSharedStateChangedCallback(session, f){
  assert(checkInvariantScreeningRoomConnection(session))

  session.onSharedStateChanged = f

  assert(checkInvariantScreeningRoomConnection(session))
}




module.exports = {

  openScreeningRoomConnection,
  closeScreeningRoomConnection,
  updateScreeningRoomConnection,
  setScreeningRoomDBChangedCallback,
  setSharedStateChangedCallback,
  updateUserSharedState2,
  checkInvariantScreeningRoomConnection,
  checkInvariantScreeningRoomDB,
  checkInvariantFileStateXL,
  checkInvariantRefinedSharedState
}

