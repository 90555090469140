import App from '@/App.vue'
import _ from 'lodash'
import SpotterfishCore from '@/../source_files/spotterfish_library/SpotterfishCore'
import { assert, isObjectInstance, isVueReactive } from '@/../source_files/spotterfish_library/SpotterfishCore'
import VideoPlayerUtils from '@/../source_files/spotterfish_library/utils/VideoPlayerUtils'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import Constants from '@/../source_files/spotterfish_library/consts'
import MasterClock from '@/../source_files/spotterfish_library/MasterClock'
import VueUtils from '@/../source_files/spotterfish_library/utils/VueUtils'

export function analytics_log_started_collaboration(sharedState, screeningRoomDB, userID, analytics){
  let rid = screeningRoomDB['.key']
  if (analytics.didLogStartedCollaboration === false && rid !== undefined && Object.keys(sharedState.users).length > 1) {
    console.debug(`[analytics] Logging "started_collaboration" Analytics event for user ${ userID } and room ${ rid }`)
    
    SpotterfishSession.trackEvent(
      App.spotterfishSession, 
      'started_collaboration', 
        {
          userUID: userID,
          screeningRoomUID: rid,
        }
    )
    
    analytics.didLogStartedCollaboration = true
  }
}

export function updateClock(component, screeningRoomSession) {
  assert(isObjectInstance(component))
  assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(screeningRoomSession))

  //  This creates a NEW clock that will become Reactive.
  const clockCopy = ScreeningRoomSession.getClockWithDefault(screeningRoomSession)

  if(component.clockCopy && clockCopy){
    // compareObjectsAndLogChanges(component.clockCopy, clockCopy, 'component.clockCopy')
    VueUtils.mergeVueObjects(component.clockCopy, clockCopy)
  } else {
    component.clockCopy = clockCopy
  }
}

export function calcIsPlayerOnlyFromQuery(query) {
  assert(query !== undefined)
  if (query.hash) {
    const queryParams = ScreeningRoomSession.decryptSecret(query.hash)
    return queryParams.playerOnly === true
  }
  else {
    return undefined
  }
}

export function shouldUsePTMarkerColors (s) {
  if(s === undefined) { return false }
  assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(s))
  return s.screeningRoomConnection.screeningRoomDBCopy.use_PT_Marker_colors !== undefined ? s.screeningRoomConnection.screeningRoomDBCopy.use_PT_Marker_colors : false
}

export function isModerator0 (s) {
  assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(s))

  const userId = firebaseUserHandler.getLoggedInUserId()
  return s.screeningRoomConnection.screeningRoomDBCopy.people_with_moderator_key.includes(userId)
}

function isNoModeratorMode (s, refinedSharedState) {
  if(!refinedSharedState) return false
  return !Object.values(refinedSharedState.users).some(user => user.active && s.screeningRoomConnection.screeningRoomDBCopy.people_with_moderator_key.includes(user.user_id))
}

function refinedStateToUI (sharedState, userID) {
  assert(sharedState === undefined || isObjectInstance(sharedState))

  if(sharedState){
    let bufferingUsers = sharedState.buffering_users
    let seekingUsers = sharedState.seeking_users

    bufferingUsers = bufferingUsers.filter((obj) => {
      return obj.user_id !== userID
    })

    seekingUsers = seekingUsers.filter((obj) => {
      return obj.user_id !== userID
    })

    let otherUserBufferingMessage = undefined
    let otherUserBuffering = undefined

    if (seekingUsers.length > 1) {
      otherUserBufferingMessage = seekingUsers.length + ' USERS BUFFERING'
      otherUserBuffering = true
    }
    else if (seekingUsers.length === 1) {
      otherUserBufferingMessage = seekingUsers[0].user_name + ' IS BUFFERING'
      otherUserBuffering = true
    }
    else if (bufferingUsers.length > 1) {
      otherUserBufferingMessage = bufferingUsers.length + ' USERS BUFFERING'
      otherUserBuffering = true
    }
    else if (bufferingUsers.length === 1) {
      otherUserBufferingMessage = bufferingUsers[0].user_name + ' IS BUFFERING'
      otherUserBuffering = true
    }
    else {
      otherUserBufferingMessage = 'READY'
      otherUserBuffering = false
    }

    // Check if all users are muted, which should trigger a change in visual state of
    // the "Mute all" button in the moderator panel. We do this by comparing the
    // number of active users with the number of muted users, where muted means both
    // muted and not using tab to talk at the moment.
    
    const userMap = {};
    const activeUsers = [];
    const mutedUsers = [];

    if (sharedState?.users) {
      // Assuming sharedState.users is an object
      for (const [userId, user] of Object.entries(sharedState.users)) {
        if (user.active_time) delete user.active_time
        userMap[userId] = user
        
        if (user.active === true) {
          activeUsers.push(userId)
          if (user.audio_muted === true && !user.tab_to_talk) {
            mutedUsers.push(userId)
          }
        }
      }
    }
    


    return {
      videoPlayerVolume: sharedState.volume,
      otherUserBufferingMessage: otherUserBufferingMessage,
      otherUserBuffering: otherUserBuffering,
      activeUsers: activeUsers,
      mutedUsers: mutedUsers,
      users: userMap,
      allMicsMuted: sharedState.mute_all, // activeUsers.length === mutedUsers.length,
      transportFeatureEnabled: sharedState.transport_controls_enabled,
      markerListFeatureEnabled: sharedState.marker_list_enabled,
      selectedStreamer: sharedState.streaming_user,
      highlightedMarker: sharedState.highlited_marker,
      streamingUser: sharedState.streaming_user
    }
  }
  else {
    return {
      videoPlayerVolume: 0,
      otherUserBufferingMessage: undefined,
      otherUserBuffering: undefined,
      activeUsers: [],
      mutedUsers: [],
      users: {},
      allMicsMuted: undefined,
      transportFeatureEnabled: undefined,
      markerListFeatureEnabled: undefined,
      selectedStreamer: undefined,
      highlightedMarker: undefined,
      streamingUser: undefined
    }
  }
}

function calcClientConfig2 (s, isPlayerOnlyMode) {
  if(s !== undefined && s.screeningRoomConnection.refinedSharedState !== undefined && isPlayerOnlyMode !== undefined){
    assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(s))

    const roomId = s.screeningRoomConnection.screeningRoomDBCopy.journal_room_id
    //??? isAudienceRoom already computed
    const isAudienceRoom = s.screeningRoomConnection.refinedSharedState.ufb.rooms[roomId].video_chat_enabled === false
    const isPlayerOnly = isPlayerOnlyMode === true

    return {
      // If the local flag for player only is set, we're always in player only mode
      // regardless of any other arguments.
      isAudienceMember: isPlayerOnly,

      // Audience rooms do not use video chat, not even for moderators.
      showVideoChat: !isAudienceRoom,

      // Show toolbar/buttons.
      toolbarEnabled: true,//!isPlayerOnly,

      // In player only mode, light switch is disabled regardless.
      // If it's an audience room, no one can use the light switch, not even moderators.
      // TODO: This might change later so that moderators can use light switch.
      lightSwitchEnabled: isPlayerOnly ? false : !isAudienceRoom,

      // In player only mode, only moderators get a full timeline, others get read-only.
      readOnlyTimeline: isPlayerOnly
    }

    // console.log('Client config is now:', this.clientConfig)
  }
  else {
    return {
      showVideoChat: undefined,
      lightSwitchEnabled: undefined,
      readOnlyTimeline: undefined,
      toolbarEnabled: undefined,
      isAudienceMember: undefined
    }
  }
}

function calcLightsOff (clientConfig, refinedSharedState) {
  {
    if (!clientConfig.lightSwitchEnabled) {
      // If light switch is disabled, lights are always off.
      return true
    }
    else if (refinedSharedState) {
      return refinedSharedState.light_switch_status === 2
    }
    else {
      return false
    }
  }
}

//  Returns
function getTabToTalkUsers(users){
  let result = []
  for(const key in users){
    if (users.hasOwnProperty(key)) {
      const value = users[key]
      if(value?.tab_to_talk){
        result.push(key)
      }
    }
  }
  return result
}

export function compareObjectsAndLogChanges(obj1, obj2, parentKey = '') {
  _.forEach(obj1, (value1, key) => {
    const value2 = obj2[key];
    const fullPath = parentKey ? `${parentKey}.${key}` : key;

    if (_.isEqual(value1, value2)) {
      // Values are deeply equal, do nothing
    } else if (_.isObject(value1) && _.isObject(value2)) {
      // If both values are objects (including arrays), recurse
      compareObjectsAndLogChanges(value1, value2, fullPath);
    } else {
      // Values are different and not both objects, log the difference
      console.log(`Change detected at "${fullPath}": ${value1} -> ${value2}`);
    }
  });

  // Check for properties in obj2 that are not in obj1
  _.forEach(obj2, (value, key) => {
    if (!_.has(obj1, key)) {
      const fullPath = parentKey ? `${parentKey}.${key}` : key;
      console.log(`New property in obj2: "${fullPath}" with value: ${value}`);
    }
  });
}




// TODO: Rename / reverse all negative names. Ex: isMuted() -> isUnmuted(), isTransportDisabled() -> isTransportEnabled()
export function calcTemplateReactiveData(srs, versionDB, projectDB, isPlayerOnlyMode, roomFeatureBits, popcornMessage, userId, transportWindowSize){
  assert(srs === undefined || ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))
  assert(versionDB === undefined || SpotterfishCore.isObjectInstance(versionDB))
  assert(projectDB === undefined || SpotterfishCore.isObjectInstance(projectDB))
  assert(isPlayerOnlyMode === undefined || SpotterfishCore.isBooleanInstance(isPlayerOnlyMode))
  assert(roomFeatureBits === undefined || SpotterfishCore.isObjectInstance(roomFeatureBits))
  assert(popcornMessage === undefined || SpotterfishCore.isStringInstance(popcornMessage))
  assert(transportWindowSize === undefined || SpotterfishCore.isObjectInstance(transportWindowSize))

  const nonReactiveDataExists = srs !== undefined && srs.screeningRoomConnection !== undefined && roomFeatureBits !== undefined && transportWindowSize !== undefined
  const reactiveDataExists = srs !== undefined && srs.screeningRoomConnection.refinedSharedState !== undefined

  const userID = srs !== undefined ? srs.screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser.uid : undefined
  
  const clientConfig = calcClientConfig2(srs, isPlayerOnlyMode)

  const versionDBCopy = versionDB ? _.cloneDeep(versionDB) : undefined
  const projectDBCopy = projectDB ? _.cloneDeep(projectDB) : undefined
  const screeningRoomDBCopy = srs !== undefined ? _.cloneDeep(srs.screeningRoomConnection.screeningRoomDBCopy) : undefined
  const refinedSharedState = srs !== undefined ? _.cloneDeep(srs.screeningRoomConnection.refinedSharedState) : undefined
  const roomFeatureBitsCopy = roomFeatureBits ? _.cloneDeep(roomFeatureBits) : undefined
  const isUserModerator = srs !== undefined && isModerator0(srs)
  const projectName = projectDBCopy ? projectDBCopy.project_name : undefined

  const customLogo = srs !== undefined ? srs.screeningRoomConnection.screeningRoomDBCopy.custom_logo : undefined
  const screeningRoomDBID = srs !== undefined ? srs.screeningRoomConnection.screeningRoomDBCopy['.key'] : undefined
  const roomName = srs !== undefined ? srs.screeningRoomConnection.screeningRoomDBCopy.name : undefined
  const freeScreeningRoom = roomFeatureBits !== undefined ? roomFeatureBits.show_free_version_banner : undefined
  const isUserProjectModerator = projectDBCopy ? projectDBCopy.moderators.includes(userId) : undefined
  const moderatorsList = screeningRoomDBCopy ? screeningRoomDBCopy.people_with_moderator_key : []
  const allowedDomains = screeningRoomDBCopy ? screeningRoomDBCopy.allowed_domains : []

  const whipStreamActive = refinedSharedState?.ingested_feed_active

  const noModerator = isNoModeratorMode(srs, refinedSharedState)

  const transportControlsEnabled = whipStreamActive ? false : (refinedSharedState && refinedSharedState.transport_controls_enabled === true) || noModerator

  const ui = refinedStateToUI (refinedSharedState, userID)

  const dawStreamerSelectedAsSyncSource = ui ? (ui.selectedStreamer !== Constants.STATIC_AUDIO_STRING) : false

  const markerListEnabled = refinedSharedState && refinedSharedState.marker_list_enabled === true && isPlayerOnlyMode === false

  //  MZ: WARNING, This is only a part of the computation if the lights are EFFECITVELY on in user's room
  const lightsOff = calcLightsOff(clientConfig, refinedSharedState)
  const videochatOnly = refinedSharedState?.light_switch_status === 3
  const UI_disableTimelineInput = whipStreamActive ? true : !projectName || (((!projectName || !transportControlsEnabled || clientConfig.readOnlyTimeline) && !isUserModerator)) || clientConfig.isAudienceMember

  const isAudienceRoom = roomFeatureBits && roomFeatureBits.video_chat_enabled === false

  // !!! this is the big switch that enables live streaming for the component.
  const dawStreamCapabilitiesEnabled = Object.values(refinedSharedState?.users || []).some(user => user.daw_streamer)

  const tabToTalkUsers0 = refinedSharedState ? getTabToTalkUsers(refinedSharedState.users) : []
  const tabToTalkUsers = lightsOff ? tabToTalkUsers0.map(userID => { return { userID: userID, userName: refinedSharedState.users[userID].user_name } }) : []

  const UI_usePTMarkerColors = shouldUsePTMarkerColors(srs)
  
  const usersInLobby = srs !== undefined ? ScreeningRoomSession.getUsersInLobby2(srs) : undefined
  const numberOfPeopleInLobby = usersInLobby?.length ? usersInLobby.length : 0

  const seatings = srs !== undefined ? srs.screeningRoomConnection.screeningRoomDBCopy.seatings : undefined

  const chairStyle = seatings !== undefined ? calculateChairStyle(seatings, videochatOnly) : undefined

  return {
    nonReactiveDataExists: nonReactiveDataExists,
    reactiveDataExists: reactiveDataExists,
    userID: userID,
    isUserModerator: isUserModerator,
    isUserProjectModerator: isUserProjectModerator,
    moderatorsList: moderatorsList,
    allowedDomains: allowedDomains,
    
    // We should not pass these around, break out individual props here
    versionDBCopy: versionDBCopy,

    projectDBCopy: projectDBCopy,
    projectName: projectName,

    screeningRoomDBCopy: screeningRoomDBCopy,
    customLogo: customLogo,
    screeningRoomDBID: screeningRoomDBID,
    roomName: roomName,
    freeScreeningRoom: freeScreeningRoom,
    isAudienceRoom: isAudienceRoom,
    transportControlsEnabled: transportControlsEnabled,
    dawStreamCapabilitiesEnabled: dawStreamCapabilitiesEnabled,
    UI_usePTMarkerColors: UI_usePTMarkerColors,
    lightsOff: lightsOff,
    videochatOnly: videochatOnly,
    whipStreamActive: whipStreamActive,
    refinedSharedState: refinedSharedState,
    roomFeatureBitsCopy: roomFeatureBitsCopy,
    
    clientConfig: clientConfig,
    ui: ui,
    dawStreamerSelectedAsSyncSource: dawStreamerSelectedAsSyncSource,
    
    UI_transportWindowSize: transportWindowSize,
    noModerator,

    UI_disableTimelineInput: UI_disableTimelineInput,
    
    UI_lightsOnStatus: clientConfig.lightSwitchEnabled && isUserModerator && lightsOff,
    UI_showVideoChatAndSeats: clientConfig.showVideoChat && clientConfig.lightSwitchEnabled && !lightsOff,
    UI_showVideoChatControls: clientConfig.toolbarEnabled && clientConfig.showVideoChat,
    UI_disableTransportControls: whipStreamActive ? true : ((!projectName || !transportControlsEnabled || clientConfig.readOnlyTimeline) && !isUserModerator) || clientConfig.isAudienceMember,
    UI_showTransportButtons: whipStreamActive ? false : ((transportControlsEnabled && !clientConfig.readOnlyTimeline) || (isUserModerator && !clientConfig.isAudienceMember)),
    UI_showVolumeSlider: !dawStreamerSelectedAsSyncSource && ((transportControlsEnabled && !clientConfig.readOnlyTimeline) || (isUserModerator && !clientConfig.isAudienceMember)),
    markerListEnabled: markerListEnabled,
    UI_showMarkerListButton: (markerListEnabled || isUserModerator) && projectName && !clientConfig.isAudienceMember,
    UI_showDawStreamerSelector: whipStreamActive ? false : dawStreamCapabilitiesEnabled && isUserModerator && ((transportControlsEnabled && !clientConfig.readOnlyTimeline) || (isUserModerator && !clientConfig.isAudienceMember)),
    UI_showVideoChatModeTabs: isUserModerator || noModerator,
    UI_showModeratorPanelToggleButton: isUserModerator && !clientConfig.isAudienceMember,
    UI_enableModeratorPanel: isUserModerator && !clientConfig.isAudienceMember,

    UI_showExitButton: clientConfig.toolbarEnabled || (isUserModerator && !clientConfig.isAudienceMember),

    UI_showBetaFeatures: isUserModerator && screeningRoomDBCopy.video_room_server,
    // isNoModeratorMode: isNoModeratorMode(srs, refinedSharedState),

    // undefined => hide
    makingPopcornMessage: popcornMessage,

    tabToTalkUsers: tabToTalkUsers,

    numberOfPeopleInLobby: numberOfPeopleInLobby,

    seatings: seatings,

    chairStyle: chairStyle,

  }
}



function calculateChairStyle(seatings, videochatOnly) {
  
  const occupiedSeatsCount = seatings.filter(seat => seat !== false).length
  let chairWidth = 400 

  switch (true) {
    case (occupiedSeatsCount <= 2):
      chairWidth = videochatOnly ? 400 : 200
      break;
    case (occupiedSeatsCount <=5):
      chairWidth = videochatOnly ? 180 : 180
      break;
    case (occupiedSeatsCount <= 20):
      chairWidth = videochatOnly ? 180 : 90
      break;
  }
  
  const aspectRatio = 1;
  const chairHeight = chairWidth / aspectRatio;
  return {
    width: chairWidth,
    height: chairHeight
  }
}

function createMovieAndAudioObjects (videoFile, audioFiles) {
  if (!Array.isArray(videoFile)) {
    videoFile = [videoFile]
  }

  if (!Array.isArray(audioFiles)) {
    audioFiles = [audioFiles]
  }


  /*
    assert(compareObjects(av, {
      mainObject: {src: '/some/path/to/video/file', type: 'video/mp4'},
      mainAudioMuted: true,
      additionalObjects: [
        {src: '/another/path', type: 'audio/wav'},
        {src: '/yet/another/path', type: 'audio/wav'}
      ]
    }))
  */
  const av = VideoPlayerUtils.resolveVideoAndAudioFiles(videoFile, audioFiles)

  const AVobject = {
    movieObject: av.mainObject,
    audioTrackInVideoMuted: av.mainAudioMuted,
    audioSourceD: av.additionalObjects[0] || { src: '', type: '' },
    audioSourceM: av.additionalObjects[1] || { src: '', type: '' },
    audioSourceE: av.additionalObjects[2] || { src: '', type: '' },
  }

  assert(VideoPlayerUtils.checkInvariantAVObject(AVobject))
  return AVobject
}



function fileStateXLToAVObject(file){
  assert(ScreeningRoomSession.proxy_checkInvariantFileStateXL(file))

  const r = createMovieAndAudioObjects(file.avFiles.videoFile, file.avFiles.audioFiles)

  assert(VideoPlayerUtils.checkInvariantAVObject(r))
  return r
}

//  WARNING: Updates videoplayer and performs awaits => other clients can observer videoplayer in intermediate states.
export async function loadVideoAtomic (
  spotterfishSession,
  sfVideoPlayer,
  fileOpt,
  fileOpt0,
  masterClock
) {
  assert(SpotterfishSession.checkInvariantSpotterfishSession(spotterfishSession))
  assert(fileOpt === undefined || ScreeningRoomSession.proxy_checkInvariantFileStateXL(fileOpt))
  assert(fileOpt0 === undefined || ScreeningRoomSession.proxy_checkInvariantFileStateXL(fileOpt0))
  assert(MasterClock.checkInvariantMasterClock(masterClock))

  try {
    const avObject0 = fileOpt0 !== undefined ? fileStateXLToAVObject(fileOpt0) : undefined
    const avObject = fileOpt !== undefined ? fileStateXLToAVObject(fileOpt) : undefined

    assert(avObject0 === undefined || isVueReactive(avObject0) === false)
    assert(isVueReactive(avObject) === false)

    const length = await sfVideoPlayer.swapVideoPlayerAtomic(masterClock, avObject0, avObject)
    return length
  }
  catch(error){
    // debugger
    throw error
  }
}

// TODO - Should loop through files and check all selected
export async function doesFileExist(fileOpt) {
  const avObject = fileOpt !== undefined ? fileStateXLToAVObject(fileOpt) : undefined
  assert(isVueReactive(avObject) === false)

  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    // @ts-ignore
    xhr.open('HEAD', avObject.movieObject.src, false)
    try {
      xhr.send()
      console.log(xhr.status)
      resolve(xhr.status !== 404)
    } catch (error) {
      reject(error)
    }
  })
}

// TODO - Should loop through files and check all selected
export async function isFilePlayable(fileOpt) {
  const avObject = fileOpt !== undefined ? fileStateXLToAVObject(fileOpt) : undefined
  assert(isVueReactive(avObject) === false)

  return new Promise((resolve, reject) => {
    // checks type of file - rejects if wrong
    resolve(true)
  })
}

export function resetFileLoaded (fileOpt) {
  console.log(fileOpt)
  debugger
  return true
}

export async function getReadLatency(srs) {
  assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(srs))

  const firebase = srs.screeningRoomConnection.spotterfishSession.firebase
  const userId = srs.screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser.uid
  const screeningRoomID = srs.screeningRoomConnection.screeningRoomDBCopy['.key']

  const startTime = performance.now()
  await firebase.database().ref(`shared_state/${ screeningRoomID }/users/${ userId }/`).once('value')
  const offsetSnapshot = await firebase.database().ref('.info/serverTimeOffset').once('value')
  const offset = offsetSnapshot.val() || 0
  console.log({offset})
  const serverTime = performance.now() + offset
  const readLatencyMS = serverTime - startTime
  const readLatency = readLatencyMS / 1000
  console.log('Read latency:', readLatency)
  return readLatency
}

export async function getWriteLatency(firebase, screeningRoomID, userID) {
  const startTime = performance.now()
  const newRef = await firebase.database().ref(`shared_state/${ screeningRoomID }/users/${ userID }/connectionStatus`).push()

  await newRef.set({
    statusString: 'latency measurement',
    timestamp: firebase.database.ServerValue.TIMESTAMP
  })

  const snapshot = await firebase.database().ref('.info/serverTimeOffset').once('value')
  const offset = snapshot.val() || 0
  console.log({offset})
  const serverTime = performance.now() + offset
  const latencyMS = (startTime - serverTime)
  const latency = latencyMS / 1000
  return latency
}