<template>
  <div>
    <div class="daw-streamer-wrapper" v-shortkey="['ctrl', 'p']" @shortkey="printDebugLog()">
        
      <div class="daw-stream-content">
        <div class="alpha-banner" :style="{backgroundColor: colors.affair}">
          
        </div>
  
  
        <div class="header">
          <!-- <v-btn class="signout-button" @click="$router.push('signout')"><v-icon>mdi-chevron-left</v-icon>LOG OUT</v-btn> -->
          <p style="font-size: 1.4em; margin-top: 14px;">DAW Streamer</p>
        </div>
        <div class="connection-info">
          <h3>Connection status:</h3>
          <p v-if="connectedState === kConnectedStates.DISCONNECTED">
            Not connected to a room
          </p>
          <p v-if="connectedState === kConnectedStates.CONNECTING">
            Connecting...
          </p>
          <p v-if="connectedState === kConnectedStates.CONNECTED && this.screeningRoom">
            <v-icon
              class="verified-badge"
              color="green"
              small
            >
              mdi-check-decagram
            </v-icon>
            Connected to room {{ this.screeningRoom.name }}</p>
          <p v-if="connectedState === kConnectedStates.CONNECTED_AND_STREAMING">
            <v-icon
              class="verified-badge"
              color="green"
              small
            >
              mdi-check-decagram
            </v-icon>
            Selected as current streamer in {{ this.screeningRoom.name }}
          </p>
        </div>
        <div class="card audio-card">
          <div v-if="connectionErrorMessage" class="connection-error-message">
            {{ connectionErrorMessage }}
            <v-btn style="margin: 10px;" :color="colors.mx.secondary" @click="resetLocalStorage()">Click to reset settings and Reload DAW Streamer</v-btn>
          </div>
          <div class="box-row">
            <div class="box">

              <div class="device-info" style="margin-top: 1.3rem;">
                <div v-if="[kConnectedStates.DISCONNECTED, kConnectedStates.CONNECTED_AND_STREAMING].includes(connectedState)">
                  <div class="volume-check">
                    <div class="volume-bar" v-for="(n, index) in volLeftArr.arr" :key="`left-${n}`" :style="{background: getVolumeColor(index, volLeftArr.arr.length, volLeftArr.isPeak)}" />
                  </div>
                  <div class="volume-check">
                    <div class="volume-bar" v-for="(n, index) in volRightArr.arr" :key="`right-${n}`" :style="{background: getVolumeColor(index, volRightArr.arr.length, volRightArr.isPeak)}" />
                  </div>
                </div>
                <div class="muted-info" v-else>
                  Connected to room and muted
                </div>
              </div>
            </div>
            <div class="box" style="text-align: left; width: 50% !important;">
              <label>Audio input</label>
              <div class="d-flex" >
                <!-- <InputMonitor/> -->
                <v-select
                  v-if="deviceInfo"
                  dense
                  outlined
                  dark
                  hide-details
                  :value="deviceInfo.selectedAudioIn"
                  :item-text="'label'"
                  :item-value="'deviceId'"
                  :items="deviceInfo.audioInputArray"
                  :disabled="connectedState === kConnectedStates.CONNECTING"
                  @change="onChangeAudioInput"
                  style="margin-left: 0"               >
                <template v-slot:item="{ item }">
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
              </div>
            </div>

          </div>
        </div>

        <div class="card midi-card">
          <div class="box-row">
            <div class="box">
              <div class="device-info" style="margin-top: 1.3rem;">
                <h1 class="timecode" :class="{ error: calcSMPTE(output_syncSnapShot) === undefined}">{{ calcSMPTE(output_syncSnapShot) }}</h1>
                <div class="timecode-info">
                  <span> @ {{ calcMTCFrameRateString() }} fps</span> 
                  <v-icon v-if="output_syncSnapShot.playFlag">mdi-play</v-icon>
                  <v-icon v-else>mdi-pause</v-icon>
                </div>
              </div>
            </div>
            <div class="box" style="text-align: left;">
              <label>Midi input</label>
              <v-select
                v-if="deviceInfo"
                dense
                outlined
                dark
                :value="deviceInfo.midi.selectedInput.name"
                :items="deviceInfo.midi.inputsArray"
                :disabled="connectedState === kConnectedStates.CONNECTING"
                @change="onMidiInputChange"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>           
            </div>
          </div>
          <div class="box-row" style="background: #161616; padding-bottom: 5px;">
            <div class="box" style="text-align: center; padding-top: 3px;">
              <label>Timecode offset (1/2 frames)</label>
              <div v-if="deviceInfo" style="width: 66%; margin: auto; height: 2.7em; display: flex; flex-direction: row; justify-content: center; align-items: center; border: 1px solid #545454; border-radius: 3px;"> 
                <v-btn @click="onAdjustTimecodeOffset(-0.5)">
                  -
                </v-btn>
                <p style="width: 2em; text-align: center; font-size: 1.5em; margin-top: 9%;">
                  {{timecodeOffset}}
                </p>
                <v-btn @click="onAdjustTimecodeOffset(0.5)">
                  +
                </v-btn>
              </div>
            </div>
            <div class="box" style="text-align:center; padding-top: 3px;">
              <label>Project Framerate</label>
              <v-select
                style="width: 40%; margin: auto;"
                v-if="deviceInfo"
                dense
                outlined
                dark
                hide-details
                :value="frameRateIndex"
                :items="frameRateOptions"
                :disabled="connectedState === kConnectedStates.CONNECTING"
                @change="onSetFrameRate"
              />
            </div>
          </div>
          <div class="box-row" style="border-bottom: 1px solid #000;">
            <div class="box" style="text-align: left;">
              <label>Final timecode</label>
              <h1 class="timecode" :class="{ error: calcSMPTEWithOffset(output_syncSnapShot) === undefined}">{{ calcSMPTEWithOffset(output_syncSnapShot) }}</h1>
              <div class="timecode-info">
                <span> @ {{ calcFrameRateString() }} fps</span> 
                <v-icon v-if="output_syncSnapShot.playFlag">mdi-play</v-icon>
                <v-icon v-else>mdi-pause</v-icon>
              </div>
            </div>
          </div>
        </div>
  

        <div class="foot">
          <v-icon @click="showInstructions = true">mdi-help-circle-outline</v-icon>
        </div>
      </div>
    </div>
    <v-dialog max-width="800" v-if="showInstructions" v-model="showInstructions">
      <InstructionsDialog />
    </v-dialog>
  </div>
</template>

<script>
// @ts-nocheck
import App from '@/App.vue'

import DawStreamerSession from '@/../source_files/web_client/DawStreamerSession'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import _ from 'lodash'
import InstructionsDialog from './instructionsDialog'
import TimecodeInput from './timecodeInput'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import InputMonitor from '@/components/dawstreamingcomponents/inputMonitor.vue'



//  Timeout is 10x expected time @30 fps


//  IMPORTANT: We cannot store this in Vue, or it will polute with get/set detectiono to make it entire object reactive.
let gDawStreamerSessionNonReactive
const PEAK_THRESHOLD = 0.99

export default {
  components: {
    InstructionsDialog,
    TimecodeInput,
    InputMonitor
  },
  data: () => ({
 
    colors: require('@/lib/ui/colors.js').colors,

    frameRateIndex: 0,
    frameRateOptions: [],
    volumeLeft: 0,
    volumeRight: 0,
    showInstructions: false,
    timecodeOffset: 0,


    ////////    ACTIVE CONNECTION

    //  Copied from DAW streamer session when session changes
    deviceInfo: undefined,
    connectedState: DawStreamerSession.kConnectedStates.DISCONNECTED,
    kConnectedStates: DawStreamerSession.kConnectedStates,
    screeningRoom: undefined,
    connectionErrorMessage: undefined,


    output_syncSnapShot: {
      playFlag: false,
      posFramesDecimal: 0,
      // default to 24 fps
      mtcFrameRateIndex: 0
    },
  }),
  computed: {
    volLeftArr () {
      if (!this.volumeLeft) return [];
      const isPeak = this.volumeLeft >= PEAK_THRESHOLD
      const scaledVolume = Math.ceil(this.volumeLeft * 10); // Scale from 0-1 to 0-10
      return { arr: Array.from(Array(scaledVolume).keys()), isPeak: isPeak }
    },
    volRightArr () {
      if (!this.volumeRight) return [];
      const isPeak = this.volumeRight >= PEAK_THRESHOLD

      const scaledVolume = Math.ceil(this.volumeRight * 10); // Scale from 0-1 to 0-10
      return { arr: Array.from(Array(scaledVolume).keys()), isPeak: isPeak }
    },
  },
  methods: {
    resetLocalStorage() {
      localStorage.removeItem('spotterfishUserStreamingAudioIn')
      localStorage.removeItem('spotterfishUserMIDIin')
      window.location.reload()
    },
    printDebugLog () {
      const debugLog = gDawStreamerSessionNonReactive.printDebugLog()
      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_printed_debug_log', { debugLog: JSON.stringify(debugLog) })
    },

    getVolumeColor(index, totalBars, isPeak) {
      const normalizedValue = (index + 1) / totalBars;
      if (normalizedValue <= 0.6) return '#20A200';
      if (normalizedValue > 0.6 && normalizedValue < 0.99) return '#6EF900';
      if (isPeak) return 'red';
    },



    calcSMPTE (syncSnapshot) {
      const frk = Timecode.mtcFrameRateIndexToKey(syncSnapshot.mtcFrameRateIndex)
      const posSeconds = Timecode.frameIndexToSeconds(syncSnapshot.posFramesDecimal, frk)
      return Timecode.secondsToSMPTEString(posSeconds, frk)
    },

    calcSMPTEWithOffset (syncSnapshot) {
      const frk = Timecode.mtcFrameRateIndexToKey(syncSnapshot.mtcFrameRateIndex)
      const posFrames = syncSnapshot.playFlag ? syncSnapshot.posFramesDecimal + Number(this.timecodeOffset) : syncSnapshot.posFramesDecimal
      const posSeconds = Timecode.frameIndexToSeconds(posFrames, frk)
      return Timecode.secondsToSMPTEString(posSeconds, frk)
    },

    // only printout
    calcFrameRateString(){
      return Timecode.getNicknameFromRateIndex(this.frameRateIndex)
    },

    calcMTCFrameRateString(){
      return Timecode.getNicknameFromRateIndex(this.output_syncSnapShot.mtcFrameRateIndex)
    },

    onMidiInputChange (selectedMidiInput) {
      // TODO: Handle midi input change
      console.log('Midi changed', selectedMidiInput)
      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_selected_MIDI_input', { MIDI_input_name: selectedMidiInput })
      gDawStreamerSessionNonReactive.changeMIDIinput(selectedMidiInput)
    },

    onAdjustTimecodeOffset (val) {
      this.timecodeOffset = this.timecodeOffset + val    
      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_changed_timecode_offset', { offset: this.timecodeOffset })
    },

    onChangeAudioInput (selectedAudioInput) {
      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_selected_Audio_input', { audio_input_name: selectedAudioInput })
      gDawStreamerSessionNonReactive.changeAudioInput(selectedAudioInput)
    },

    onSetFrameRate(newFrameRate){
      let fr = 'unknown'
      if (this.frameRateOptions) {
        const target = this.frameRateOptions.find(option => option.value === newFrameRate)
        fr = target ? target.text : 'unknown'
      }

      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_selected_daw_streamer_frame_rate', { frame_rate: fr })

      window.localStorage.setItem('spotterfish_projectFrameRate', newFrameRate)
      this.frameRateIndex = newFrameRate
    },

    // handleError (err) {
    //   // Sentry.captureException(err)
    //   console.log('error: ', err.message, err.name)
    //   if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
    //     console.error('No webcam and/or microphone was found')
    //   } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
    //     console.error('Webcam and/or microphone is already in use in another application or tab')
    //   } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
    //     // Reset settings and try again instead of showing error, it seems like this
    //     // can happen even when codec is supported.

    //     const userAudioIn = localStorage.getItem('spotterfishUserStreamingAudioIn')
    //     const userVideoIn = localStorage.getItem('spotterfishUserVideoIn')
    //     const userAudioOut = localStorage.getItem('spotterfishUserAudioOut')

    //     if (userAudioIn || userVideoIn || userAudioOut) {
    //       localStorage.removeItem('spotterfishUserStreamingAudioIn')
    //       localStorage.removeItem('spotterfishUserVideoIn')
    //       localStorage.removeItem('spotterfishUserAudioOut')
    //       this.init()
    //     } else {
    //       // Even after clearing settings, it does not work.
    //       console.error('Codec not supported')
    //     }
    //     console.error(err)
    //   } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
    //     console.error('Please allow camera and microphone access and reload this page')
    //   } else if (err.name === 'TypeError' || err.name === 'TypeError') {
    //     // empty constraints object
    //   } else {
    //     // other errors
    //   }
    // },
    
    // stops the user from leaving the window without confirming = gives us time to take down everything properly
    makeSureActiveStreamerIsCleared (event) {
      event.preventDefault()
      gDawStreamerSessionNonReactive.destroy()
      gDawStreamerSessionNonReactive = undefined

      event.returnValue = ''
    },

  },
  async beforeUnmount () {
    gDawStreamerSessionNonReactive.destroy()
    gDawStreamerSessionNonReactive = undefined
  },
  async destroyed () {
    SpotterfishSession.trackEvent(App.spotterfishSession, 'user_left_daw_streamer', {})
    if (gDawStreamerSessionNonReactive) { gDawStreamerSessionNonReactive.destroy() }
    gDawStreamerSessionNonReactive = undefined
  },
  async mounted () {
    // This event listener needs to be here to make sure user is no longer presented as available for daw stream.
    // no way around it 2021-12-17
    /*
      Onbeforeunload is subject of one of bigest missunderstanding in the webdevelopers world :D

      1) It refuses to call all blocking native functions (alert, prompt, confirm). It is obvious from User perspective.

      2) it (according to MDN) should be registered by "addEventListener" (MDN)

      3) It is fired only if there was ANY interaction of the user with the site. Without ANY interaction (even one click anywhere) event onbeforeunload won't be fired.

      4) The aim of this event IS eg. secretly saving data left in forms (or else) on behalf of user (or logging user behavior etc.). It is NOT for blocking refreshing the site !

      Thus there is no way (because it would be for nothing) to show personalised information.

      The only sens is to hide the prompt window while reloading with prior saveing data.

      5) If you want to hide the prompt window just NOT set any value for the event.returnValue field.
    */
    this.frameRateOptions = Object.values(Timecode.kFrameRateKeyCatalog).map(frObj => ({text: frObj.nickname, value: frObj.rateIndex}))
    this.frameRateIndex = Number(window.localStorage.getItem('spotterfish_projectFrameRate')) || 0
    window.addEventListener('beforeunload', this.makeSureActiveStreamerIsCleared)
    document.title = 'MixStage Streamer'
    const userObject = await SpotterfishSession.getUserObjects(App.spotterfishSession.firebase, [App.spotterfishSession.userSession.firebaseCurrentUser.uid])
    
    SpotterfishSession.trackEvent(App.spotterfishSession, 'user_entered_daw_streamer', {})

    const sessionData = {
      onVuMeterUpdate: (volLeft, volRight) => {
        this.volumeLeft = volLeft;
        this.volumeRight = volRight;
      },
      onSyncSnapshot: (output_syncSnapShot) => {
        this.output_syncSnapShot = output_syncSnapShot
      },
      gotDeviceInfo: (deviceInfo) => {

        this.deviceInfo = deviceInfo
        SpotterfishSession.trackEvent(App.spotterfishSession, 'user_gave_permission_for_audio_card_access', { deviceInfoString: JSON.stringify(deviceInfo) })
        console.log('Got device info', this.deviceInfo)
      },
      onConnectionStateChange: (state, screeningRoom = undefined) => {
        this.connectedState = state
        
        SpotterfishSession.trackEvent(App.spotterfishSession, 'daw_streamer_connection_change', { state: state })

        if(screeningRoom){
          this.screeningRoom = screeningRoom
        }
        if(state === this.kConnectedStates.DISCONNECTED){
          this.screeningRoom = undefined
        }
      },
      getSyncOffset: () => this.timecodeOffset,
      getFrameRateIndex: () => this.frameRateIndex,

      firestore: App.spotterfishSession.firestoreDB,
      firebase: App.spotterfishSession.firebase,
      userId: App.spotterfishSession.userSession.firebaseCurrentUser.uid,
      user: userObject[0],
    }

    try {
      gDawStreamerSessionNonReactive = await DawStreamerSession.OpenProjectorDawStreamSession2(sessionData)
      SpotterfishSession.trackEvent(App.spotterfishSession, 'user_gave_permission_for_MIDI_access', {})
    } catch (error) {
      this.connectionErrorMessage = 'Could not find midi or audio input. Check your MIDI settings and:'
    }
  }
}
</script>

<style scoped lang="scss">
.daw-streamer-wrapper{
  
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}
.daw-stream-content{
  background-color: #1e1e1e;
  width: 40%;
  min-width: 600px;
  max-width: 640px;
  margin: 2% auto;
  border-radius: 8px;

}
.alpha-banner{
  p{
    margin: 0;
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid black;
  border-radius: 8px 8px 0 0;
  .signout-button{
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  p{
    font-size: 2em;
    margin-top: 10px;
  }
}
.connection-info{
  padding: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  h3{
    text-transform: uppercase;
    margin-right: 0.5rem;
  }
  p {
    margin: 0;
  }
}

.box-row{
  display: flex;
  .box{
    width: 50%;
    padding: .5rem 1rem;
    text-align: left;
    p{
      padding: 0;
    }
    label{
      text-transform: uppercase;
      font-size: 11px;
      margin-left: 0.5rem;
    }
  }
}
.volume-check{
  width: 100%;
  background: #000;
  height: 50px;
  display: flex;
  padding: 0.5rem;
  .volume-bar{
    width: 10%;
    height: 30px;
    border: 2px solid black;
    color: #000;
  }
}
.timecode{
  background: black;
  margin-bottom: 0.5rem;
  padding-left: 16%;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;
}
.tc-input{
  width: 100%;
}
.timecode-info{
  display: flex;
  justify-content: center;
}
.muted-info {
  text-align: center;
  background: #000;
  padding: 1rem;
}
.foot {
  display: flex;
  padding: 1rem;
}

.connection-error-message{
  border: 1px solid #C83733;
  padding: 1rem;
  margin: 0 1rem;
}

</style>