<template>
  <div style="width: 100%;">
    <v-row no-gutters>
      <v-col>
        <v-row dense>
          <v-col cols="12" style="text-align: left; padding: 25px 25px; margin: 0 auto 0 auto;" class="dark-panel">
            <span style="margin: 10px 10px 10px 10px; padding: 10px 10px 10px 10px; font-size: 1.5em; font-weight: 500;">My Rooms</span>
            <v-data-table
              mobile-breakpoint="0"
              dark
              style="max-width: 100%; color: white; min-height: 20vh;"
              :headers="sessionHeaders"
              :items="screeningRoomsForUser"
              :items-per-page="10"
              :expanded="expanded"
              :footer-props="{
                'disable-items-per-page': true,
                'items-per-page-options': [-1],
                'items-per-page-text': ''
              }"
              item-key=".key"
              class="dark-panel mt-4"
              @click:row="handleRowClick"
            >

              <template v-slot:item.start="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  {{ item.activeUsers?.length ? 'Join' : 'Start' }} a session in this room
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click.stop="enterScreeningRoom(item, undefined)">
                      <span  style="margin-top: 2px;">
                        <v-icon size="2em" >mdi-play</v-icon>
                      </span>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
              

              <template v-slot:item.status="{ item }">
                <v-tooltip bottom :color="colors.tooltipBackground">
                  <span v-show="item.activeUsers?.length">Active session</span>
                  <span v-show="!item.activeUsers?.length">Not active</span>
                  <br />
                  <br />

                  <span v-show="item.activeUsers?.length">Currently in room:</span>
                  <br />
                  <span :key="user['.key']" v-for="user in item.activeUsers">{{ user.user_name }} <br /> </span>

                  <template v-slot:activator="{ on }">
                    <v-icon :color="item.activeUsers?.length ? 'yellow' : '#0c0c0c' " v-on="on" size="2em">bolt</v-icon>
                    <span v-show="item.activeUsers?.length">{{item.activeUsers?.length}}</span>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.logo="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  Custom logo for this room
                  <template v-slot:activator="{ on }">
                    <v-img v-on="on" class="room-logo" :src="item.custom_logo ? item.custom_logo : logos.mixStageLogoOnly"/>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.seated="{ item }">
                <v-tooltip bottom :color="colors.tooltipBackground">
                  <span>Currently in room:</span>
                  <br />
                  <span :key="user['.key']" v-for="user in item.activeUsers">{{ user.user_name }} <br /> </span>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.activeUsers?.length }}</span>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.configure="{ }">
                <v-tooltip top :color="colors.tooltipBackground">
                  Edit room properties
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" tag="button" size="1.5em" style="margin: auto;" >mdi-pencil</v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>


          <v-col cols="12" style="text-align: left; padding: 25px 25px; margin: 0 auto;" class="dark-panel">
            <span style="margin: 10px 10px 10px 10px; padding: 10px 10px 10px 10px; font-size: 1.5em; font-weight: 500;">My invitations</span>           
            <v-data-table
              mobile-breakpoint="0"
              dark
              style="max-width: 100%; color: white;"
              :headers="sessionHeaders2"
              :items="screeningRoomWhereInvited"
              :items-per-page="20"
              :expanded="expanded"
              :footer-props="{
                'disable-items-per-page': true,
                'items-per-page-options': [-1],
                'items-per-page-text': ''
              }"
              item-key=".key"
              class="dark-panel mt-4"
            >

              <template v-slot:item.start="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  {{ item.activeUsers?.length ? 'Join' : 'Start' }} a session in this room
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :color="item.activeUsers?.length ? colors.mx.secondary : 'gray'" @click.stop="enterScreeningRoom(item)">
                      <span  style="margin-top: 2px;">
                        <v-icon size="2em">mdi-play</v-icon>
                      </span>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
              
              <template v-slot:item.status="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  <span v-show="item.activeUsers?.length">Active session</span>
                  <span v-show="!item.activeUsers?.length">Not active</span>

                  <template v-slot:activator="{ on }">
                    <v-icon :color="item.activeUsers?.length ? 'yellow' : '#0c0c0c' " v-on="on" size="2em">bolt</v-icon>
                    <span v-show="item.activeUsers?.length">{{item.activeUsers?.length}}</span>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.logo="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  Custom logo for this room
                  <template v-slot:activator="{ on }">
                    <v-img v-on="on" class="room-logo" :src="item.custom_logo ? item.custom_logo : logos.mixStageLogoOnly"/>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.seated="{ item }">
                <v-tooltip bottom :color="colors.tooltipBackground">
                  <span>Currently in room:</span>
                  <br />
                  <span :key="user['.key']" v-for="user in item.activeUsers">{{ user.user_name }} <br /> </span>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.activeUsers?.length }}</span>
                  </template>
                </v-tooltip>
              </template>

              <!-- <template v-slot:item.configure="{ }">
                <v-tooltip top :color="colors.tooltipBackground">
                  Start/join a session in this room
                  <template v-slot:activator="{ on }">
                  </template>
                </v-tooltip>
              </template> -->
            </v-data-table>
          </v-col>



        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      width="520"
      v-model="editScreeningRoom"
      overlay-opacity="0.95"
      v-if="editScreeningRoom && selectedRoom"
    >
      <screeningRoomEditDialog
        :selectedRoom="selectedRoom"
        :accountTier="accountTier"
        :setSpinner="setSpinner"
        @request-close-edit-screeningroom="editScreeningRoom = false"
        @request-save-screeningroom="saveScreeningRoom($event)"
      />
    </v-dialog>
  </div>

</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import screeningRoomEditDialog from '@/components/dashboard/screeningRoomEditDialog.vue'
import WebClientImplementation from '@/../source_files/web_client/WebClientImplementation'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'

export default {
  name: 'dashboard-screeningrooms',
  props: [
    'setSpinner', 
    'accountTier',
    'isDAWstreamer'
  ],
  components: {
    screeningRoomEditDialog
  },
  data(){
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      hovering: false,
      editScreeningRoom: false,
      selectedRoom: undefined,
      screeningRoomsForUser: [],
      screeningRoomWhereInvited: [],
      allScreeningRooms: [],
      showChairs: undefined,
      currentUserId: undefined,
      sessionHeaders: [
          { text: '', align: 'center', sortable: false, value: 'start', width: '40px'},
          { text: '', align: 'center', sortable: false, value: 'default', width: '40px'},
          { text: 'Status', align: 'center', sortable: false, value: 'status', width: '40px' },
          { text: 'Logo', align: 'center', sortable: false, value: 'logo', width: '60px'},
          { text: 'Name', align: 'start', sortable: false, value: 'name', width: '' },
          { text: 'Seats', align: 'center', sortable: false, value: 'seats', width: '60px'},
          // { text: 'Seated', align: 'center', sortable: false, value: 'seated', width: '60px' },
          // { text: 'Active Project', sortable: false, value: 'active', width: '60px' },
          // { text: 'Date started', value: 'date' },
          // { text: 'Moderators', value: 'moderators' },
          { text: 'Configure', sortable: false, align: 'center', value: 'configure', width: '60px' },
      ],
      expanded: [],
      sharedStateInterval: undefined,
    }
  },
  computed: {
    sessionHeaders2() {
      return this.sessionHeaders.slice(0, -1)
    },
    justifyDependingOnRooms() {
      const totalLength =
        this.screeningRoomsForUser.length +
        this.screeningRoomWhereInvited.length
      if (totalLength === 4) {
        return 'center'
      } else {
        return totalLength < 4 ? 'center' : 'start'
      }
    }
  },
  methods: {

    setDefault (room) {
      localStorage.setItem('mixstage_defaultroom', room['.key'])
    },

    handleRowClick(room) {
      this.selectedRoom = room
      this.editScreeningRoom = true
    },
  
    /* this function accepts 
     1) a screening room object AND a project id 
     2) Both undefined
     3) any combintion of the two
    */
    async enterScreeningRoom(screeningRoom, projectId) {

      let _screeningRoom

      const defaultRoom = localStorage.getItem('mixstage_defaultroom')
      console.log(defaultRoom)

      let exists = true
      if(defaultRoom) {
        exists = await ScreeningRoomSession.checkIfScreeningRoomExists(App.firestoreDB, defaultRoom)
      }

      if (screeningRoom) {
        localStorage.setItem('mixstage_defaultroom', screeningRoom['.key'])
        _screeningRoom = screeningRoom['.key']
      }
      else if (this.screeningRoomsForUser.length === 1) {
        _screeningRoom = this.screeningRoomsForUser[0]['.key']
      } 
      else if (defaultRoom && exists){
        _screeningRoom = defaultRoom
      } 
      else {
        await this.$root.$globalAlert.open('Which room?', {
            line1: `You have multiple rooms. Please choose one to open. It will also become your default.`
          },
          { color: this.colors.mixStage_main })
          console.log(this.$store.state.dashboardTab)
        this.$store.state.dashboardTab = 1
        return
      }

      const originalObject = this.$route.query.hash ? await ScreeningRoomSession.decryptSecret(this.$route.query.hash) : {}
      originalObject.projectId = originalObject.projectId ?  originalObject.projectId : projectId
      originalObject.sroom = originalObject.sroom ? originalObject.sroom : _screeningRoom
      console.log({originalObject})
      const newHash = await ScreeningRoomSession.encryptSecret(originalObject)

      const res = await ScreeningRoomSession.decryptSecret(newHash)
      console.log(res)

      this.$router
        .push({ name: 'lobby', query: { hash: newHash } })
        .catch((e) => {
          console.log(e)
        })
    },
    
    roomDataFromUFB(roomId) {
      const mapping = App.spotterfishSession.userSession.DBIDMapping
      const journalRoomId = mapping[roomId]
      const roomData =
        App.spotterfishSession.userSession.ufb.rooms[journalRoomId]
      return roomData || {}
    },

    async saveScreeningRoom(updateFields) {
      console.log(updateFields) 

      if (this.accountTier === 'tier1') {
        if (updateFields.custom_logo !== '' || (updateFields.background_color.hexa !== '#000000FF') || updateFields.hideChairs === true) {
          await this.$root.$globalAlert.open('Premium account feature', {
            line1: `Screening Room customization is a premium account feature. To use it please upgrade from the UPGRADE ACCOUNT button.`
            },
          { color: this.colors.mainAccent })  
        }
        
        const updateObject = {
            '.key': updateFields['.key'],
            name: updateFields.name
          }
        await this.$store.dispatch('screening_room_update', updateObject)
        this.editScreeningRoom = false
        this.setSpinner(false)

      } else {
        this.setSpinner(true)
        
        try {
          const updateObject = {
              '.key': updateFields['.key'],
              name: updateFields.name,
              custom_logo: updateFields.custom_logo,
              background_color: updateFields.background_color || '#000000FF',
              use_PT_Marker_colors: updateFields.usePTMarkerColors
            }
          await this.$store.dispatch('screening_room_update', updateObject)
          this.editScreeningRoom = false
        } catch (error) {
          console.error(error)
          alert('Could not save screening room settings.')
        } finally {
          this.setSpinner(false)
        }
      }
      updateFields = undefined
      
    },
    clearCustomColor() {
      this.selectedRoom.background_color = '#000000FF'
    },

    clearCustomLogo() {
      if (this.$refs.vueAvatarRoomLogo) {
        this.selectedRoom.custom_logo = ''
        this.selectedRoom.custom_logo_scale = 1
        this.$refs.vueAvatarRoomLogo.resetImage()
        this.isClearingLogo = true
      }
    },


    async updateRoomStatus(roomKey) {
      await WebClientImplementation.getSharedStateStatusForRoom(roomKey, App.spotterfishSession.firebase, (error, returnValue) => {
        const clearRoomInArray = (roomArray, roomKey) => {
          const index = roomArray.findIndex(room => room['.key'] === roomKey)
          if (index !== -1) {
            this.$set(roomArray, index, {
              ...roomArray[index],
              activeUsers: [],
              fileState: null
            })
          }
        }

        if (error) {
          if (error.code === 'permission-denied') {
            console.log('Permission denied for room')
            clearRoomInArray(this.screeningRoomsForUser, roomKey)
            clearRoomInArray(this.screeningRoomWhereInvited, roomKey)
            return
          }
          console.error(`Error updating room status: ${error}`)
          clearRoomInArray(this.screeningRoomsForUser, roomKey)
          clearRoomInArray(this.screeningRoomWhereInvited, roomKey)
          return
        }

        const updateRoomInArray = (roomArray) => {
          const index = roomArray.findIndex(room => room['.key'] === returnValue.uid)
          if (index !== -1) {
            this.$set(roomArray, index, {
              ...roomArray[index],
              activeUsers: returnValue.activeUsers,
              fileState: returnValue.fileState
            })
          }
        }

        updateRoomInArray(this.screeningRoomsForUser)
        updateRoomInArray(this.screeningRoomWhereInvited)
      })
    },

    async readStatusFromSharedStateByInterval(rooms) {
      if (this.sharedStateInterval) {
        clearInterval(this.sharedStateInterval)
      }

      const updateAllRooms = async () => {
        for (const element of rooms) {
          await this.updateRoomStatus(element)
        }
      }

      await updateAllRooms()
      
      // Set a new interval
      this.sharedStateInterval = setInterval(updateAllRooms, 5000)
    },

    handleActiveSessions(activeRooms) {
      console.log('detected room active. Start polling for status', activeRooms)
      this.readStatusFromSharedStateByInterval(activeRooms)
    },

    handleGotRooms(rooms){
      this.allScreeningRooms = rooms
      console.log(rooms)
      // we have the rooms, add listener for active sessions in them

      App.spotterfishSession.userSession.addSessionListener(
        'activeSessions', 
        this.handleActiveSessions,
        this.currentUserId,
        rooms
      )

      this.screeningRoomsForUser = rooms.filter(room => room.owner === this.currentUserId)
      this.screeningRoomWhereInvited = rooms.filter(room => room.owner !== this.currentUserId)
      this.$forceUpdate()
    },

  },


  async beforeMount(){
    this.currentUserId = App.spotterfishSession.userSession.firebaseCurrentUser.uid
    App.spotterfishSession.userSession.addSessionListener('rooms', this.handleGotRooms, true)
  },

  destroyed(){

    if (this.sharedStateInterval) {
      clearInterval(this.sharedStateInterval)
    }
    try {
      App.spotterfishSession.userSession.removeSessionListener('rooms', this.handleGotRooms)
      App.spotterfishSession.userSession.removeSessionListener('activeSssions', this.handleActiveSessions)      
    } catch (error) {
      console.log('Listener not found')
    }
  }
}
</script>

<style lang="scss" scoped>

.room-logo {
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  aspect-ratio: 1/1;
  margin: auto;
  /* border: 1px solid rgba(255, 255, 255, 0.303); */
}

</style>