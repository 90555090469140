var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:({
    stepPrevious: ['arrowleft'],
    stepNext: ['arrowright'],
    close: ['esc'],
  }),expression:"{\n    stepPrevious: ['arrowleft'],\n    stepNext: ['arrowright'],\n    close: ['esc'],\n  }",modifiers:{"once":true}}],staticClass:"main-div",on:{"shortkey":_vm.onShortkey}},[_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputStep === 0 && !_vm.markerCategories),expression:"inputStep === 0 && !markerCategories"}],staticClass:"main-button-container"},[_c('v-btn',{attrs:{"disabled":!_vm.projectLoaded,"small":_vm.calcTransportStyle.hide},on:{"click":function($event){return _vm.$emit('request-drop-marker', _vm.local_persistentMarkerType)}}},[_c('v-icon',{staticStyle:{"transform":"rotate(45deg)","margin-right":"8px"},attrs:{"color":_vm.local_persistentMarkerType.color,"size":"1em"}},[_vm._v("mdi-square")]),_c('div',[_vm._v("Drop Marker")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.markerCategories === true),expression:"markerCategories === true"}],staticClass:"marker-edit-category-buttons-container"},_vm._l((_vm.commentTypes),function(a,index){return _c('v-btn',{key:index,staticClass:"markertype elevation-5 rounded gray-border",class:{ selected: _vm.isSelectedCommentType(_vm.commentTypes[index]) },style:({
          background: _vm.commentTypes[index].color,
          color: _vm.commentTypes[index].textColor,
        }),attrs:{"x-small":""},on:{"click":function($event){return _vm.onChangeCommentType(_vm.commentTypes[index])}}},[_vm._v(" "+_vm._s(_vm.commentTypes[index].name.substring(0, 3))+" ")])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputStep === 2 && !_vm.markerCategories),expression:"inputStep === 2 && !markerCategories"}],staticClass:"text-input-container"},[_c('textarea',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:({
          stepPrevious: ['arrowleft'],
          stepNext: ['arrowright'],
          close: ['esc'],
        }),expression:"{\n          stepPrevious: ['arrowleft'],\n          stepNext: ['arrowright'],\n          close: ['esc'],\n        }",modifiers:{"once":true}}],ref:"description",staticClass:"custom-textarea",style:(_vm.calcTransportStyle.shrink1
            ? { 'font-size': '0.8em', width: '90%' }
            : ''),attrs:{"placeholder":"Write a comment"},domProps:{"value":_vm.getMarkerDescription()},on:{"input":function($event){return _vm.onChangeMarkerDescription($event)},"keydown":function($event){return _vm.handleKeydown($event)},"keyup":function($event){return _vm.handleKeyup($event)},"shortkey":_vm.onShortkey}})]),_c('div',{staticClass:"small-button-container"},[_c('div',{staticClass:"small-button-container"},[_c('button',{staticClass:"small-custom-button left-button",style:({
                'background-color': _vm.local_persistentMarkerType.color,
              }),attrs:{"disabled":!_vm.projectLoaded},on:{"click":function($event){return _vm.toggleShowCategories()}}},[_c('div',{staticClass:"icon-container"},[(
                    _vm.getComponentState() === 0 || _vm.getComponentState() === 2
                  )?_c('v-icon',{attrs:{"size":"1em"}},[_vm._v("arrow_left")]):_vm._e(),(
                    _vm.getComponentState() === 1 || _vm.getComponentState() === 3
                  )?_c('v-icon',{attrs:{"size":"1em"}},[_vm._v("arrow_right")]):_vm._e()],1)])]),_c('div',{staticClass:"small-button-container"},[_c('button',{staticClass:"small-custom-button right-button",attrs:{"disabled":_vm.getComponentState() === 0 || _vm.getComponentState() === 1},on:{"click":function($event){return _vm.saveMarker(true)}}},[_c('div',{staticClass:"icon-container"},[_c('v-icon',{attrs:{"size":"1em"}},[_vm._v("keyboard_return")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }