const SpotterfishCore = require('./SpotterfishCore')
const SpotterfishHelpers = require('./SpotterfishHelpers')
const DomainUtils = require('./utils/DomainUtils')
const UserFeatureBitsModule = require('./userFeatureBits')
const UserJournalModule = require('./userJournal')
const FirebaseUser = require('./FirebaseUser')
const Constants = require('./consts.js')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

// Right now empty business_data  outputs undefined, and rooms ==0  => empty object.

const userSnapshotExample0 = {
  'user': {
    'guest_account': false,
    'user_name': 'MartinDev',
    'deleted_account': false,
    'user_id': 'W01fWzhW7mb52OCaTLAP2N597Co1',
    'screening_rooms': [
      'cYcxilr05lBKvhclfilr'
    ],
    'subscription_type': 2,
    'email': 'martin@spotterfish.io',
    'signup_timestamp': 1588234415
  },
  'rooms': {
  }
}

const userSnapshotExample = {
  'user': {
    'guest_account': false,
    'user_name': 'MartinDev',
    'deleted_account': false,
    'user_id': 'W01fWzhW7mb52OCaTLAP2N597Co1',
    'screening_rooms': [
      'cYcxilr05lBKvhclfilr'
    ],
    'subscription_type': 2,
    'email': 'martin@spotterfish.io',
    'signup_timestamp': 1588234415
  },
  'userBusinessData': {
    'W01fWzhW7mb52OCaTLAP2N597Co1': [
      '{"date":"2021-04-30T15:08:42.592Z","optin_email":true}',
      '{"date":"2021-04-30T15:08:42.802Z","promo_code":"rat-poniard-octane-sheer"}'
    ]
  },
  'rooms': {
    'cYcxilr05lBKvhclfiIr': {
      'owner': 'W01fWzhW7mb52OCaTLAP2N597Co1',
      'people_seated': [
        'W01fWzhW7mb52OCaTLAP2N597Co1',
        'CyjuC1Ljn6SWat2yjWx7sbeBYII3',
        'ZX6rBxuoBpMCYxseQEIfLDRdAGy2',
        'marcus.zetterquist@gmail.com',
        'testdriver@spotterfish.io'
      ],
      'seatings': [
        'W01fWzhW7mb52OCaTLAP2N597Co1',
        'CyjuC1Ljn6SWat2yjWx7sbeBYII3',
        'ZX6rBxuoBpMCYxseQEIfLDRdAGy2',
        'marcus.zetterquist@gmail.com',
        'testdriver@spotterfish.io'
      ],
      'people_with_moderator_key': [
        'W01fWzhW7mb52OCaTLAP2N597Co1'
      ],
      'video_room_server': '',
      'current_project': 'sqXFaIszRMLgtI0OE33E',
      'marker_lane': 'HFxfuyhZCs59x4emaWP5',
      'MCORP_MOTION_NAME': '',
      'seats': 5,
      'video_room_id': 7,
      'last_active': {
        '_seconds': 1596744353,
        '_nanoseconds': 466000000
      },
      'MCORP_APPID': '',
      'motionKey': '',
      'lightsOff': false,
      'background_color': '#000000FF',
      'name': 'MartinDEV',
      'project_people': [
        'W01fWzhW7mb52OCaTLAP2N597Co1',
        'CyjuC1Ljn6SWat2yjWx7sbeBYII3',
        'ZX6rBxuoBpMCYxseQEIfLDRdAGy2'
      ]
    }
  }
}


const kExampleUser2 = {
  "user": {
    "screening_rooms": [
      "r9PjiG4qYJyKFfutPekY"
    ],
    "deleted_account": false,
    "user_id": "vnggGqmSPLbT3zeKAQfS2RSyirD2",
    "user_name": "markus",
    "email": "markus+fff03@polyscopic.works",
    "tier1": false,
    "signup_timestamp": 1620140375
  },
  "userBusinessData": {
    "account_journal": [
      "{\"date\":\"2021-05-04T14:59:42.882Z\",\"optin_email\":false}"
    ]
  },
  "rooms": {
    "r9PjiG4qYJyKFfutPekY": {
      "project_load_count": 1,
      "people_seated": [
        "vnggGqmSPLbT3zeKAQfS2RSyirD2"
      ],
      "current_project": "",
      "marker_lane": "",
      "project_people": [
        "vnggGqmSPLbT3zeKAQfS2RSyirD2"
      ],
      "feature_bit_show_free_version_banner": true,
      "seats": 5,
      "seatings": [
        "vnggGqmSPLbT3zeKAQfS2RSyirD2",
        false,
        false,
        false,
        false
      ],
      "feature_bit_custom_logo": false,
      "name": "markus´s Room",
      "people_with_moderator_key": [
        "vnggGqmSPLbT3zeKAQfS2RSyirD2"
      ],
      "feature_bit_custom_background_color": false,
      "owner": "vnggGqmSPLbT3zeKAQfS2RSyirD2"
    }
  }
}


// Input are { user, userBusinessData, rooms }
function calcUFBFromAccount (userSnapshot) {
  assert(SpotterfishCore.isObjectInstance(userSnapshot))
  assert(SpotterfishCore.isObjectInstance(userSnapshot.user))
  assert(userSnapshot.userBusinessData === undefined || SpotterfishCore.isObjectInstance(userSnapshot.userBusinessData))
  assert(SpotterfishCore.isObjectInstance(userSnapshot.rooms))

  const rooms = userSnapshot.rooms

  var rooms2 = {}

  for (const [key, entry] of Object.entries(rooms)) {
    const key2 = key
    const room2 = {
      seats: entry.seats
    }

    room2.requires_email_verification = entry.requires_email_verification === true
    room2.requires_two_factor_auth = entry.requires_two_factor_auth === true
    room2.requires_room_pin = entry.requires_room_pin === true

    // Warning: in DB-based feature bits there IS NO feature bit for whitelist feature,
    // Since we have no user with whitelist-feature, we just set it to false.
    // room2.domain_whitelist_enabled = entry.allowed_domains !=== undefined
    room2.domain_whitelist_enabled = false

    const videochatDisabled = entry.disable_video_chat === true
    room2.video_chat_enabled = videochatDisabled === false

    room2.show_free_version_banner = entry.feature_bit_show_free_version_banner === true
    room2.custom_background_color = entry.feature_bit_custom_background_color === true

    room2.custom_logo = entry.feature_bit_custom_logo === true

    rooms2[key2] = room2
  }

  let ufb = UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms2 })
  assert(UserFeatureBitsModule.checkInvariantUFB(ufb))
  return ufb
}


function prove_calcUFBFromAccount_NoRooms () {
  let r = calcUFBFromAccount(userSnapshotExample0)
  unitTestAssert(Object.keys(UserFeatureBitsModule.getUFBRooms(r)).length === 0)
  unitTestAssert(SpotterfishCore.compareArraysByValue(UserFeatureBitsModule.makeUFBCompactStrings(r), [ 'd' ]))
}


function prove_calcUFBFromAccount1 () {
  let r = calcUFBFromAccount(userSnapshotExample)
  unitTestAssert(SpotterfishCore.compareArraysByValue(UserFeatureBitsModule.makeUFBCompactStrings(r), [ 'd', 'cYcxilr05lBKvhclfiIr  5 ---- O- ---' ]))
}

/*
function isBlank (ufb) {
  unitTestAssert(UserFeatureBitsModule.checkInvariantUFB(ufb))

  return Object.keys(UserFeatureBitsModule.getUFBRooms(ufb)).length === 0
}

function isEra1Vanilla5Seater (ufb) {
  unitTestAssert(UserFeatureBitsModule.checkInvariantUFB(ufb))

  return SpotterfishCore.compareObjectsByValue(ufb, {
    rooms: {
      1: {
        seats: 5,
        video_chat_enabled: true,
        show_free_version_banner: false
      }
    }
  })
}
*/


/*
// Returns true if the UFB can be automatically upgraded to era2-tier1
function canAutoUpgradeAccountToTier1 (journal) {
  unitTestAssert(UserJournalModule.checkJournal(journal) && UserJournalModule.isJournalMigrated(journal))

  const output = UserJournalModule.renderJournalOutput(journal)
  unitTestAssert(output.length > 0)

  const output2 = output[output.length - 1]
  return isBlank(output2.ufb) || isEra1Vanilla5Seater(output2.ufb)
}

function canAutoUpgradeAccountToTier2 (journal) {
  unitTestAssert(UserJournalModule.checkJournal(journal) && UserJournalModule.isJournalMigrated(journal))

  const output = UserJournalModule.renderJournalOutput(journal)
  unitTestAssert(output.length > 0)

  const output2 = output[output.length - 1]
  return isBlank(output2.ufb) || isEra1Vanilla5Seater(output2.ufb) || isEra2Tier1(output2.ufb)
}
*/

/*
// Returns a number of journal entries that clients needs to append to journal.
function upgradeToTier1 (journal, currentDate, note) {
  unitTestAssert(UserJournalModule.checkJournal(journal))
  unitTestAssert(UserJournalModule.isJournalMigrated(journal) === true)
  unitTestAssert(SpotterfishCore.isValidISODateString(currentDate))

  const entries = [ { date: currentDate, command: 'add_tier1', note: note } ]

  return entries
}

function upgradeToTier2 (journal, currentDate, promoCode, note) {
  unitTestAssert(UserJournalModule.checkJournal(journal))
  unitTestAssert(UserJournalModule.isJournalMigrated(journal) === true)
  unitTestAssert(SpotterfishCore.isStringInstance(promoCode) && promoCode.length > 0)
  unitTestAssert(SpotterfishCore.isValidISODateString(currentDate))

  const entries = [ { date: currentDate, command: 'add_tier2', promo_code: promoCode, note: note } ]
  return entries
}
*/

/*
function logAllUsersUFB () {
  const testUsers = TestUsersModule.getTestUsers()

  var result = []
  for (var i = 0; i < testUsers.length; i++) {
    const userSnapshot = testUsers[i]

    const ufb = calcUFBFromAccount(
      {
        user: userSnapshot,
        userBusinessData: userSnapshot.user_business_data,
        rooms: userSnapshot.rooms
      }
    )
    result.push(ufb)
  }
  console.log(JSON.stringify(result, null, 4))
}

function logAllUsersUFBCompact () {
  const testUsers = TestUsersModule.GetTestUsers()

  for (var i = 0; i < testUsers.length; i++) {
    const userSnapshot = testUsers[i]
    const ufb = UserFeatureBitsModule.calcUFBFromAccount(
      {
        user: userSnapshot,
        userBusinessData: userSnapshot.user_business_data,
        rooms: userSnapshot.rooms
      }
    )
    console.log(UserFeatureBitsModule.makeUFBCompactStrings(ufb).join(';'))
  }
}
*/

// Input is an array of accounts:
// [
//   {
//     userId: <string>,
//     userName: <string>,
//     userEmail: <string>,
//     signupDate: <string>,
//     emailOptinStatus: <string>,
//     ufbStrings: [<string>, <string>, ...],
//   },
//   ...
// ]
function makeReportCSV (accounts) {
  const lines = []

  // Add CSV header line first.
  lines.push([
    'user_id',
    'user_name',
    'email',
    'signup_date',
    'email_optin',
    'rooms'
  ])

  for (const e of Object.values(accounts)) {
    lines.push([
      e.userId,
      e.userName,
      e.userEmail,
      e.signupDate,
      e.emailOptinStatus,
      e.ufbStrings.join(',')
    ])
  }

  let output = ''
  for (const line of lines) {
    output += line.join(';') + '\n'
  }
  return output
}

function prove_makeReportCSV () {
  // One room.
  unitTestAssert(SpotterfishCore.compareValues(
    makeReportCSV([
      {
        userId: 'abc123',
        userName: 'tester',
        userEmail: 'tester@example.com',
        signupDate: '2021-09-15',
        emailOptinStatus: '1',
        ufbStrings: ['abc']
      }
    ]),
    'user_id;user_name;email;signup_date;email_optin;rooms\n' +
    'abc123;tester;tester@example.com;2021-09-15;1;abc\n'
  ))

  // Two rooms.
  unitTestAssert(SpotterfishCore.compareValues(
    makeReportCSV([
      {
        userId: 'abc123',
        userName: 'tester',
        userEmail: 'tester@example.com',
        signupDate: '2021-09-15',
        emailOptinStatus: '1',
        ufbStrings: ['abc', 'def']
      }
    ]),
    'user_id;user_name;email;signup_date;email_optin;rooms\n' +
    'abc123;tester;tester@example.com;2021-09-15;1;abc,def\n'
  ))
}

function makeSharedState (roomOwnerUfbWithMapping, thisUserIsDAWStreamer, userDocData, activeTime) {
  assert(checkUFBWithMapping(roomOwnerUfbWithMapping))
  assert(SpotterfishCore.isObjectInstance(userDocData))
  assert(thisUserIsDAWStreamer !== undefined)

  return {
    ufb: roomOwnerUfbWithMapping.ufb,
    volume: 1.0,
    transport_controls_enabled: true,
    marker_list_enabled: true,
    streaming_user: Constants.STATIC_AUDIO_STRING,
    users: {
      [userDocData.user_id]: {
        active: true,
        active_time: activeTime,
        session_joined_time: activeTime,
        user_name: userDocData.user_name,
        user_id: userDocData.user_id,
        user_email: userDocData.email,
        daw_streamer: thisUserIsDAWStreamer
      }
    }
  }
}

async function cleanupSharedState (sharedState0, roomId, userId, activeTime) {
  assert(SpotterfishCore.isObjectInstance(sharedState0))
  assert(SpotterfishCore.isStringInstance(roomId))
  assert(SpotterfishCore.isStringInstance(userId))

  var result = SpotterfishCore.copyByValue(sharedState0)

  console.log('cleaning up shared state')

  const timeOutLimitInSeconds = 20

  Object.values(result.users).forEach(user => {
    if (user.user_id === userId && user.active) {
      user.active = false
      console.log(`User ${user.user_id} was already active in session. Throwing previous user out`)
    }
    // can not clean up users in the state of connecting right now.
    if(user.active_time) {
      if((activeTime - user.active_time > timeOutLimitInSeconds * 1000) && user.active){
        user.active = false
        console.log(`User ${user.user_id} timed out, cleaning up with active: false`)
      }
      else {
        user.clean = true
      }
    }
  })
  console.log(result)
  return result
}

// ??? Overwrites UFB -- why? Should be setup by first user that joins shared state.
function joinExistingSharedState (sharedState0, roomOwnerUfbWithMapping, thisUserIsDAWStreamer, userDocData, serverTime) {
  assert(SpotterfishCore.isObjectInstance(sharedState0))
  assert(checkUFBWithMapping(roomOwnerUfbWithMapping))
  assert(SpotterfishCore.isObjectInstance(userDocData))

  var result = SpotterfishCore.copyByValue(sharedState0)
  result.ufb = roomOwnerUfbWithMapping.ufb
  result.users[userDocData.user_id] = {
    active: true,
    active_time: serverTime,
    session_joined_time: serverTime,
    user_name: userDocData.user_name,
    user_id: userDocData.user_id,
    user_email: userDocData.email,
    daw_streamer: thisUserIsDAWStreamer
  }

  //  KLUDGE: Nudge stuff to keep them in range.

  // Sometimes this actually happens and we still want these to be present.
  if (result.volume === undefined) {
    result.volume = 1.0
  }
  if (result.transport_controls_enabled === undefined) {
    result.transport_controls_enabled = true
  }
  if (result.marker_list_enabled === undefined) {
    result.marker_list_enabled = true
  }
  console.log(result)
  return result
}

//  Throws if not allowed to join/allocate this room
function throwIfRoomNotJoinable (firebaseUser, userId, roomId, roomDocData, roomFeatureBits, email) {
  assert(SpotterfishCore.isObjectInstance(firebaseUser))
  assert(SpotterfishCore.isStringInstance(userId))
  assert(SpotterfishCore.isStringInstance(roomId))
  assert(SpotterfishCore.isObjectInstance(roomDocData))
  assert(SpotterfishCore.isObjectInstance(roomFeatureBits))

  if (FirebaseUser.isUserAnonymous(firebaseUser)) {
    assert(email === undefined)
  } else {
    assert(SpotterfishHelpers.isValidEmail(email))
  }

  let checkSecurityFeatures = true

  if (FirebaseUser.isUserAnonymous(firebaseUser)) {
    checkSecurityFeatures = false
  }

  // Check that user email is verified, if the room requires it.
  if (checkSecurityFeatures && roomFeatureBits.requires_email_verification) {
    console.log(`Room ${roomId} requires verified email, checking user.`)
    if (firebaseUser.emailVerified === true) {
      console.log(`User ${userId} has a verified email, continuing.`)
    } else {
      throw new Error(`User ${userId} does not have a verified email.`)
    }
  }

  // Check that user has two-factor auth enabled, if the room requires it.
  if (checkSecurityFeatures && roomFeatureBits.requires_two_factor_auth) {
    console.log(`Room ${roomId} requires two-factor authentication, checking user.`)
    if (firebaseUser.multiFactor && firebaseUser.multiFactor.enrolledFactors && firebaseUser.multiFactor.enrolledFactors.length > 0) {
      console.log(`User ${userId} has two-factor auth enabled, continuing.`)
    } else {
      throw new Error(`User ${userId} does not have two-factor auth enabled.`)
    }
  }

  // Check that the user email domain is allowed in this room, if the room has such a
  // restriction set.
  if (checkSecurityFeatures && roomFeatureBits.domain_whitelist_enabled) {
    console.log(`Room ${roomId} has a domain restriction, checking user email.`)

    if (!Array.isArray(roomDocData.allowed_domains)) {
      throw new Error(`Room ${roomId} allowed_domains must be an array.`)
    }

    // TODO: There might be a delay before context.auth.token is updated with new info
    // when changing email or verifying email. This is why we use user.emailVerified
    // above instead. Maybe we should use that here too.
    let matchedDomain = null
    for (let domain of roomDocData.allowed_domains) {
      if (DomainUtils.domainMatches(email, domain)) {
        matchedDomain = domain
        break
      }
    }

    if (matchedDomain === null) {
      throw new Error(`User email ${email} did not match any allowed domain.`)
    } else {
      console.log(`User email ${email} matched allowed domain ${matchedDomain}.`)
    }
  }
}

// Tests JS promises
async function prove_promiseWithoutReturnResolvesToUndefined () {
  let a = 0
  async function f () {
    a = 1
  }
  const val = await f()
  unitTestAssert(a === 1)
  unitTestAssert(val === undefined)
}



// Returns array of journal entires
// businessData must be an { }, checks if account_journal exists, else returns []
function unpackJournalFromBusinessData (businessData) {
  SpotterfishCore.assert(SpotterfishCore.isObjectInstance(businessData))

  return businessData.account_journal ? UserJournalModule.unpackJournal(businessData.account_journal) : []
}

function prove_unpackJournalFromBusinessData () {
  unitTestAssert(
    SpotterfishCore.compareValues(
      unpackJournalFromBusinessData(kExampleUser2.userBusinessData),
      [ { date: '2021-05-04T14:59:42.882Z', optin_email: false } ]
    )
  )
}
function prove_unpackJournalFromBusinessData_empty () {
  unitTestAssert(
    SpotterfishCore.compareValues(
      unpackJournalFromBusinessData({ account_journal: [] }),
      []
    )
  )
}


// userSnapshot


function checkUserSnapshot (userSnapshot) {
  assert(SpotterfishCore.isObjectInstance(userSnapshot))
  assert(SpotterfishCore.isObjectInstance(userSnapshot.user))
  assert(SpotterfishCore.isObjectInstance(userSnapshot.rooms))
  assert(userSnapshot.userBusinessData === undefined || SpotterfishCore.isObjectInstance(userSnapshot.userBusinessData))
  return true
}





/*
  UFB with mapping

  {
    ufb: 
    DBIDMapping {
      roomDBId: journalRoomID
    }
  }

  1. The UFB and the DBIDMapping can be out of sync. Ex: you have added a new journal entry.
  2. UFB Can contain roomIDs that are either journalRoomIDs or roomDBIDs. This is because UFB can
    describe migrated and unmigrated UFBs.
*/

function checkUFBWithMapping (ufbWithMapping) {
  assert(SpotterfishCore.isObjectInstance(ufbWithMapping))
  assert(UserFeatureBitsModule.checkInvariantUFB(ufbWithMapping.ufb))
  assert(SpotterfishCore.isObjectInstance(ufbWithMapping.DBIDMapping))

  // console.dir(ufbWithMapping, { depth: null })

  return true
}

//  Checks if rooms described by UFB is in sync with those in DBIDMapping.
function isUFBWithMappingInSync (ufbWithMapping) {
  assert(checkUFBWithMapping(ufbWithMapping))

  const rooms = UserFeatureBitsModule.getUFBRooms(ufbWithMapping.ufb)
  console.dir(ufbWithMapping, { depth: null })

  // console.log([ 'checkUFBWithMapping', rooms, Object.keys(rooms).length, ufbWithMapping.DBIDMapping, Object.keys(ufbWithMapping.DBIDMapping).length])
  if(Object.keys(rooms).length !== Object.keys(ufbWithMapping.DBIDMapping).length){
    return false
  }

  // Check room IDs match between UFB and mapping
  const journalIDs1 = Object.keys(rooms).map(function (e) { return parseInt(e, 10) }).sort()
  const journalIDs2 = Object.values(ufbWithMapping.DBIDMapping).map(function (e) { return parseInt(e, 10) }).sort()

  // console.log([ 'checkUFBWithMapping', JSON.stringify(journalIDs1), JSON.stringify(journalIDs2)])
  if (!SpotterfishCore.compareValues(journalIDs1, journalIDs2)) {
    return false
  }

  return true
}

function checkMigrationUFBWithMappingXXX (ufbWithMapping, oldUFB) {
  assert(checkUFBWithMapping(ufbWithMapping))
  assert(UserFeatureBitsModule.checkInvariantUFB(oldUFB))

  const roomsFromMapping = UserFeatureBitsModule.getUFBRooms(ufbWithMapping.ufb)
  const roomsFromOldUFB = UserFeatureBitsModule.getUFBRooms(oldUFB)

  assert(Object.keys(roomsFromMapping).length === Object.keys(roomsFromOldUFB).length)

  for (const [oldRoomId, oldRoomData] of Object.entries(roomsFromOldUFB)) {
    const mappedId = ufbWithMapping.DBIDMapping[oldRoomId]
    if (mappedId === undefined) {
      assert(SpotterfishCore.compareValues(oldRoomData, roomsFromMapping[oldRoomId]))
    } else {
      assert(SpotterfishCore.compareValues(oldRoomData, roomsFromMapping[mappedId]))
    }
  }
  return true
}








// DBIDMapping: key = database ID, value = journal_room_id
function extractRoomDBIDs (userSnapshot) {
  assert(checkUserSnapshot(userSnapshot))

  const result = {}

  for (const [roomId, roomData] of Object.entries(userSnapshot.rooms)) {
    assert(roomData.journal_room_id !== undefined)
    result[roomId] = roomData.journal_room_id  
  }

  return result
}



// Returns UFBWithMapping
function calcUFBWithMapping (userSnapshot) {
  assert(checkUserSnapshot(userSnapshot))

  let journal = userSnapshot.userBusinessData ? unpackJournalFromBusinessData(userSnapshot.userBusinessData) : []

  const ufbWithMapping = {
    ufb: UserJournalModule.renderJournalOutput(journal).pop().ufb,
    DBIDMapping: extractRoomDBIDs(userSnapshot)
  }
  assert(checkUFBWithMapping(ufbWithMapping))

  return ufbWithMapping
}

// Returns the signup date as an ISO string, from a user snapshot.
function getSignupDateString (userSnapshot) {
  assert(checkUserSnapshot(userSnapshot))

  return new Date(userSnapshot.user.signup_timestamp * 1000).toISOString()
}

function isSpotterfishStaffEmail (email) {
  assert(SpotterfishHelpers.isValidEmail(email))

  const allowedEmails = [
    'doug@spotterfish.io',
    'marcus.zetterquist@gmail.com',
    'markus@polyscopic.works',
    'martin@spotterfish.io',
    'provisor@yahoo.com',
    'anders.andersson@apeshape.se'
  ]

  return allowedEmails.includes(email)
}

async function getAccountArray (allUsersSnapshot) {
  assert(SpotterfishCore.isArrayInstance(allUsersSnapshot))

  const accounts = []

  for (const userSnapshot of Object.values(allUsersSnapshot)) {
    const journal = userSnapshot.userBusinessData !== undefined
      ? (await unpackJournalFromBusinessData(userSnapshot.userBusinessData))
      : []

    const e = UserJournalModule.renderJournalOutput(journal).pop()

    accounts.push({
      userId: userSnapshot.user.user_id,
      userName: userSnapshot.user.user_name,
      userEmail: userSnapshot.user.email,
      signupDate: getSignupDateString(userSnapshot),
      emailOptinStatus: e.optinEmail,
      ufbStrings: UserFeatureBitsModule.makeUFBCompactStrings(e.ufb)
    })
  }

  return accounts
}

function claimScreeningRoomSessionData (roomId) {
  assert(SpotterfishCore.isStringInstance(roomId))

  return {
    //  NOTE: We use the room ID as the ID of the shared state.
    shared_state_id: roomId
  }
}





function runUnitTests () {
  console.log('BusinessLogic.js -- START')

  prove_calcUFBFromAccount_NoRooms()
  prove_calcUFBFromAccount1()

  prove_makeReportCSV()

  prove_promiseWithoutReturnResolvesToUndefined()

  prove_unpackJournalFromBusinessData()
  prove_unpackJournalFromBusinessData_empty()

  // logAllUsersUFB()
  // logAllUsersUFBCompact()

  console.log('BusinessLogic.js -- END')
}

// TODO: Decide what functions should be exported, i.e. what is the public API.
module.exports = {
  checkUFBWithMapping,
  checkUserSnapshot,

  makeReportCSV,
  makeSharedState,
  cleanupSharedState,
  joinExistingSharedState,
  throwIfRoomNotJoinable,

  unpackJournalFromBusinessData,

  calcUFBFromAccount,
  calcUFBWithMapping,

  getSignupDateString,
  isSpotterfishStaffEmail,
  getAccountArray,
  claimScreeningRoomSessionData,

  runUnitTests
}
