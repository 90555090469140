<template>
  <v-layout style="position: absolute; top: 92px; right: 41px; width: 300px; height: 42%; min-height: 430px; z-index: 5;" id="chatSideBar">
    <v-card class="submittedmessages sfish-scrollbars" flat style="background: none;" v-if="messages && myMessages().length > 0">

      <div v-for="(item, index) in loadedMessages(chatPage)" :key="item['.key']" style="background: none;">
        <button :style="{backgroundColor: colors.borderBlue}" style="width: 100%;" v-if="loadedMessages(chatPage).length < myMessages().length && index === loadedMessages(chatPage).length -1 " @click="chatPage = chatPage + 1">
          Load More
        </button>
        <div class="messageContainer" :class="[ isFromMe(item.from_user) ? 'fromme' : 'fromother' ]" v-on:mouseover="changeHoveringActive(index)" v-on:mouseleave="changeHoveringInActive(index)">
          <div class="headerdiv">
            <img class="mini-avatar" v-show="getUser(item.from_user).pict" :style="{borderColor: colors.borderBlue}" :src="getUser(item.from_user).pict"/>
            <img class="mini-avatar" v-show="!getUser(item.from_user).pict" :style="{borderColor: colors.borderBlue}" :src="logos.mixStageLogoOnlyAvatar">

            <p class="sendername" :style="{color: lightGrayColor}">{{ limitCharacters(getUser(item.from_user).user_name, 32) }} to {{ getMessageRecipient(item) }}</p>

            <p class="timestamp" :style="{color: lightGrayColor}">{{ formatDate(item.send_time.seconds) }}</p>
          </div>
          <div v-if="active[index]">
            <!-- <v-icon small color="white lighten-1" @click="likeMessage(index)">thumb_up</v-icon>
            <v-icon v-if="isFromMe(item.from_user)" small color="white lighten-1" @click="removeMessage(item)">delete</v-icon> -->
          </div>
          <div class="messagediv" style="color: black;">
            <p v-if="item.message" class="message" v-html="checkForLinks(item.message)"></p>
            <img v-if="item.image" :src="item.image"/>
          </div>
        </div>
        <div style="font-size: 0.7em; margin-top: -8px" v-if="isNewDate(item.timestamp)" :key="index + '-separator'">{{ oldValue }}</div>
      </div>
    </v-card>
    <v-card id="chatForm" style="background: white; position: absolute; bottom: 0px; left: 0; right: 0; height: 58px; border-radius: 7px; padding-top: 12px;">
      <v-btn fab dark x-small style="position: absolute; right: -14px; top: -14px;" @click="$emit('request-close-chat')">
        <i class="material-icons tab-icon">close</i>
      </v-btn>
         
      <form @submit.prevent="addMessage($event)">
        <v-layout row fluid>
          <v-flex xs10 style="margin: auto">
            <v-text-field
              light
              ref="chatInputField"
              v-model.lazy="newMessage"
              :validate-on-blur='true'
              :label="inputLabel"
              recipient="getActiveChatGroup"
              autocomplete="off"
              clearable
              :color="colors.borderBlue"
              @click:prepend-inner="openChooseFile">
            </v-text-field>
          </v-flex>
        </v-layout>
      </form>
      <v-select
        :value="recipient"
        :items="getRecipients()"
        :prefix="'Send to:'"
        @change="setRecipient"
      />
      
    </v-card>
    <!-- <div v-if="emojiPickerDialog" style="position: absolute; top: -68px; right: 6px; border: 1px solid white; border-radius: 10px;">
        <emojiPicker
          title="Add an emoji"
          emoji="thumbs_up"
          :defaultSkin="3"
          :perLine="8"
          :showPreview="false"
          :sheetSize="64"
          :emojiSize="20"
          :infiniteScroll="false"
          @select="addEmoji"/>
    </div> -->
  </v-layout>
</template>

<script>
//@ts-nocheck
import moment from 'moment'
import { Picker } from 'emoji-mart-vue'
import { isDevelopment } from '@/../source_files/spotterfish_library/SpotterfishCore'
import { mapGetters } from 'vuex'

if (isDevelopment()) {
  // eslint-disable-next-line
  var expect = require('chai').expect
}

export default {
  components: {
    emojiPicker: Picker
  },
  name: 'message',
  props: [
    'screeningRoomSession'
  ],
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      avatarColor: require('@/lib/ui/colors.js').colors.keppel,
      spacerColor: require('@/lib/ui/colors.js').colors.spacerColor,
      markerColor: require('@/lib/ui/colors.js').colors.darkGray,
      lightGrayColor: require('@/lib/ui/colors.js').colors.lightGray,
      emojiPickerDialog: false,
      emojiCloseButton: false,
      active: [],
      chatPage: 1,
      newMessage: '',
      oldValue: 0,
      fileUploadState: 'idle',
      selectedChatUser: '',
      messagesForCurrentGroup: [],
      chatRef: undefined,
      messages: undefined,
      currentUser: undefined,
      recipient: 'everyone'
    }
  },
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::;;::::COMPUTED:::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  computed: {
    ...mapGetters(['getConnectedUsers']),
    inputLabel () {
      return 'Chat here…'
    },
    rightSide () {
      let chatSideBar = document.getElementById('chatSideBar')
      return chatSideBar
    }
  },
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::;;;::::METHODS:::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  methods: {

    limitCharacters (str, length) {
      const _str = str.toString()
      if (_str.length > length) {
        return _str.slice(0, length + 1) + '...'
      } else {
        return _str
      }
    },
    
    formatDate (value) {
      if (value) {
        let localeLanguage = ''
        if (moment.unix(value).format(' HH:MM ') !== 'Invalid date') {
          if (navigator.languages !== undefined) {
            moment.locale(navigator.languages[0])
            localeLanguage = moment.locale(navigator.language).slice(0, 2)
          } else {
            moment.locale(navigator.language)
            localeLanguage = moment.locale(navigator.language).slice(0, 2)
          }
          const date = moment.unix(value).calendar()
          if (date.length > 16) {
            if (localeLanguage === 'en') {
              return moment.unix(value).format('DD MMM YYYY')
            } else {
              return moment.unix(value).format('YYYY-MM-DD')
            }
          }
          return date
        }
      }
    },

    checkForLinks (str) {
      const newStr = str.replace(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
        return '<a target="_blank" href="' + arguments[2] + '">' + (arguments[7] || arguments[2]) + '</a>'
      })
      return newStr
    },

    getRecipients(){
      const everyone = {
        text: 'Everyone',
        value: 'everyone',
      };
      const users = this.getConnectedUsers.filter(u => Boolean(u) && u.user_id !== this.currentUser?.user_id && u.email !== '').map(({user_name, user_id}) => ({ text: user_name, value: user_id }))
      return [everyone, ...users]
    },

    setRecipient(e){
      this.recipient = e;
    },

    getMessageRecipient(message){
      if(message.recipient === 'everyone') return 'Everyone'
      return this.getUser(message.recipient)?.user_name || message.recipient
    },

    getUser (userID) {
      const userList = this.getConnectedUsers
      if (!userList) {
        return
      }
      const foundUser = userList.find(user => user.user_id === userID)
      if(foundUser) return foundUser;
      return {pict: '', user_name: userID}
    },

    isFromMe (uid) {
      return uid === this.currentUser.user_id
    },

    myMessages(){
      return this.messages.filter(message => message.recipient === 'everyone' || [message.recipient, message.from_user].includes(this.currentUser.user_id))
    },

    loadedMessages (pageIndex) {
      // Shows the currently selected last 20 messages in current group
      return this.myMessages().slice(0, 20 * pageIndex)
    },

    addMessage (e) {
      if (this.newMessage !== '') {
        this.comment = this.newMessage.trim()
        const rawChatMessageObject = {
          from_user: this.currentUser.user_id,
          send_time: new Date().getTime(),
          message: this.comment,
          recipient: this.recipient
        }

        this.chatRef.push(rawChatMessageObject).then(() => {
          this.newMessage = '';
        })
      }
    },

    removeMessage (index) {
      // DISPATCH HERE
    },

    likeMessage (index) {
      // DISPATCH HERE
    },

    unlikeMessage (index) {
      // DISPATCH HERE
    },

    addEmoji (emoji) {
      this.newMessage = this.insertAtCurrentTextPostition(this.newMessage, this.$refs.chatInputField.$refs.input.selectionStart, 0, emoji.native)
      // this.toggleEmojiPicker()
    },

    toggleEmojiPicker () {
      this.$refs.chatInputField.focus()
      if (this.emojiPickerDialog === true) {
        this.emojiPickerDialog = false
      } else {
        this.emojiPickerDialog = true
      }
    },

    openChooseFile (e) {
      this.$refs.chatInputField.focus()
    },

    changeHoveringActive (e) {
      this.$set(this.active, e, true)
    },

    changeHoveringInActive (e) {
      this.$set(this.active, e, false)
    },

    isNewDate (value) {
      return false
    },

    openFileModal (value) {
      // console.log.log(value)
    },

    insertAtCurrentTextPostition (str, index, count, add) {
      if (index < 0) {
        index = str.length + index
        if (index < 0) {
          index = 0
        }
      }
      return str.slice(0, index) + (add || '') + str.slice(index + count)
    },
  },

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::LIFECYCLE HOOKS::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  mounted () {
    this.currentUser = this.$store.state.currentSpotterfishUser
    this.chatRef = this.screeningRoomSession.screeningRoomConnection.spotterfishSession.firebase.database().ref(`shared_state/${ this.screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy['.key'] }/text_chat`);
    this.chatRef.on('value', (snapshot) => {
      if(snapshot.val()){
        this.messages = Object.values(snapshot.val()).sort((a,b) => a.send_time > b.send_time ? -1 : 1).map(message => ({...message, send_time: new Date(message.send_time)}))
      }
    })
  },

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::FILTERS::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  filters: {
    

    limit (value) {
      if (value) {
        return value.slice(0, 50)
      }
    },

    limit32 (value) {
      if (value) {
        return value.slice(0, 32)
      }
    }
  }
}
</script>

<style scoped>

.messageContainer {
  min-height: 3em;
  background: white;
  border-radius: 10px;
  width: 94%;
}

.fromme {
  margin-left: 6%;
}

.fromother {
  margin-left: 0;
}

.submittedmessages {
  background: none;
  border-radius: 6px;
  padding: 0 6px 6px 6px;
  display: flex;
  overflow: auto;
  width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 70px;
  top: 0;
  flex-direction: column-reverse;
}

.headerdiv {
  display: flex;
  min-width: 96%;
  height: 1em;
  /* background-color: green; */
  margin: 10px auto 0 4%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.likediv {
  background: #66666688;
  display: flex;
  width: 13%;
  position: absolute;
  border-radius: 3px;
  right: 10%;
  height: 1em;
  /* background-color: green; */
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}

.mini-avatar {
  border: 1px solid;
  border-radius: 50%;
  max-width: 26px;
  max-height: 26px;
  width: auto;
  height: 26px;
  padding: 2px;
  align-self: flex-start;
  margin: 10px 0 0 0
}

.sendername {
  font-family: helvetica;
  font-size: 10px;
  font-weight: bold;
  color: #AAAAAA;
  margin: 2px 0 0 12px;
}

.timestamp {
  font-size: 8px;
  font-weight: bold;
  margin: 0.3em 20px 0 auto;
  overflow: hidden;
}

.messagediv {
  display: flex;
  /* background: purple; */
  max-width: 78%;
  height: auto;
  margin: 0 auto 0px 17%;
  text-align: left;
  font-size: 0.9em;
  word-wrap: break-word;
}

.message {
  color: black;
  margin: 1px 0 0 4px;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  max-width: 99%;
}

.less-right-margin {
  margin-right: -4px;
}

</style>
