const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const isStringInstance = SpotterfishCore.isStringInstance

const SpotterfishSession = require('./SpotterfishSession')
const checkInvariantSpotterfishSession = SpotterfishSession.checkInvariantSpotterfishSession

const CloudClient = require('./CloudClient')

async function getUploadPathInfo (spotterfishSession, fileName, projectId) {
  checkInvariantSpotterfishSession(spotterfishSession)

  assert(isStringInstance(fileName))
  assert(fileName.length > 0)
  assert(isStringInstance(projectId))
  assert(projectId.length > 0)

  const result = await CloudClient.call_CFgetUploadPathInfo(spotterfishSession.firebase, fileName, projectId)

  return {
    bucketName: result.bucketName,
    uploadPath: result.uploadPath,
    token: result.token
  }
}

// filePath is relative to storageRef, will create the file on server.
// f: f(progress_percentage)
// output: { publicURL: downloadUrl, metainfo: meta }
async function uploadFileToStorage (firebase, storageRef, filePath, fileData, progress_f) {
  return new Promise((resolve, reject) => {
    const task = storageRef.child(filePath).put(fileData)
    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function progress (snapshot) {
        const currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        const progress = Math.trunc(currentProgress)
        progress_f(progress)
      },
      function (error) {
        console.error(error)
        reject(error)
      },
      function () {
        task.snapshot.ref.getMetadata().then(function (meta) {
          task.snapshot.ref.getDownloadURL().then(function (downloadUrl) {
            const response = {
              publicURL: downloadUrl,
              metainfo: meta
            }
            resolve(response)
          }).catch(error => {
            console.error(error)
            reject(error)
          })
        })
      }
    )
  })
}

function postActiveDawInputStatusToSharedState(firebase, args) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(args))
  assert(args.screeningRoomID !== undefined)
  assert(args.userId !== undefined)
  assert(SpotterfishCore.isBooleanInstance(args.trueFalse))
  try {
    firebase.database().ref(`shared_state/${args.screeningRoomID}/users/${args.userId}`).update({
      available_as_DAW_input: args.trueFalse
    })
  } catch (error) {
    throw error
  }
}

// Shared state can not be called unless user has access.
async function getSharedStateStatusForRoom(roomKey, firebase, cb) {
  const dbRef = firebase.database().ref()
  let returnValue = {
    activeUsers: undefined,
    fileState: undefined,
    uid: roomKey
  }

  try {
    const snapshot = await dbRef.child('shared_state').child(roomKey).get()
    if (snapshot.exists()) {
      const val = snapshot.val()
      console.log(val)
      returnValue = {
        // @ts-ignore
        activeUsers: Object.entries(val.users || {}).filter(([_, userDetails]) => userDetails.active).map(([userId, userDetails]) => userDetails),
        fileState: val.fileState,
        uid: roomKey
      }
      console.log('calling back', returnValue)
      cb(null, returnValue)
    } else {
      console.log('No data available')
      cb(new Error('No data available'), null)
    }
  } catch (error) {
    console.log(error)
    cb(error, roomKey)
  }
}


// function setSelectedDAWstreamer (state, args) {
//   return new Promise((resolve, reject) => {
//     console.log(args)
//     App.db.ref(`shared_state/${args.screeningRoomID}`).update({
//       selected_for_daw_stream: args.userUID
//     }).then(() => {
//       resolve(undefined)
//     }).catch((error) => {
//       reject(error)
//     })
//   })
// }


async function addMarker(firebase, markerObject) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(markerObject))
  
  try {
    const firestoreDB = firebase.firestore()

    const markerRef = firestoreDB.collection('markers')
    let newDocID

    await firestoreDB.runTransaction(async (transaction) => {
      const newDocRef = markerRef.doc()
      newDocID = newDocRef.id

      markerObject.created_date = firebase.firestore.FieldValue.serverTimestamp()
      transaction.set(newDocRef, markerObject)
    })

    const docSnapshot = await firestoreDB.collection('markers').doc(newDocID).get()
    if (docSnapshot.exists) {
      console.log(docSnapshot.data().created_date.toDate())  // Convert to JavaScript Date object
    }
    console.log('added marker with id', newDocID)
    return newDocID
  } catch (error) {
    throw error
  }
}
async function updateMarker(firebase, markerObject, userId) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(markerObject))
  assert(SpotterfishCore.isStringInstance(userId))

  try {
    const firestoreDB = firebase.firestore()

    const uid = markerObject['.key']
    delete markerObject['.key']

    let isUpdated = false

    await firestoreDB.runTransaction(async (transaction) => {
      const markerRef = firestoreDB.collection('markers').doc(uid)
      const docSnapshot = await transaction.get(markerRef)

      if (!docSnapshot.exists) {
        throw new Error('Document does not exist!')
      }
      // Add updated_date as server timestamp
      markerObject.updated_date = firebase.firestore.FieldValue.serverTimestamp()
      markerObject.updated_by = firebase.firestore.FieldValue.arrayUnion(userId)

      transaction.update(markerRef, markerObject)
      isUpdated = true
    })

    if (isUpdated) {
      const docSnapshot = await firestoreDB.collection('markers').doc(uid).get()
      if (docSnapshot.exists) {
        console.log(docSnapshot.data().updated_date.toDate())  // Convert to JavaScript Date object
      }
    }

    return isUpdated ? uid : undefined
  } catch (error) {
    console.error('Error updating marker: ', error)
    throw error
  }
}

async function saveSilentMarker(firebase, markerObject) {
  assert(SpotterfishCore.isObjectInstance(firebase))
  assert(SpotterfishCore.isObjectInstance(markerObject))

  let storedCommentType = 'GENERIC'

  if (markerObject?.markerType?.name) {
    storedCommentType = markerObject.markerType.name
  }

  const initialMarkerObject = {
    category_select: storedCommentType,
    creator: markerObject.useruid,
    currently_updating_user: '',
    description: '',
    frame_thumbnail: '',
    original_parent_marker_lane: markerObject.markerLane,
    tc_pos: markerObject.SMPTE,
    title: '',
    uid: '',
    updated_by: [],
  }

  const markerId = await addMarker(firebase, initialMarkerObject)
  return markerId
}



module.exports = {
  getUploadPathInfo,
  uploadFileToStorage,
  postActiveDawInputStatusToSharedState,
  getSharedStateStatusForRoom,
  addMarker,
  updateMarker,
  saveSilentMarker,
}
