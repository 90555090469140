// @ts-nocheck

import App from '@/App.vue'
import store from '@/store'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import * as Sentry from '@sentry/browser'
import { isChrome, isMobile } from 'mobile-device-detect'

// API

import SpotterfishAPI from '@/components/API/SpotterfishAPI'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import CloudClient from '@/../source_files/web_client/CloudClient'
import TopLevel from '@/../source_files/web_client/TopLevel'
import { isDevelopment } from '@/../source_files/spotterfish_library/SpotterfishCore'

const CryptoJS = require('crypto-js')

if (isDevelopment()) {
  // eslint-disable-next-line
  var expect = require('chai').expect
}

export default {
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::USER:::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  user_get_with_realtime_listener(state, user) {
    store.state.realtimeUserListener()
    return new Promise((resolve, reject) => {
      // Running an unbind - function if realtime listener is already set up
      store.state.realtimeUserListener()
      console.debug(
        'SETTING UP A REALTIME LISTENER FOR CHANGES IN LOGGED IN USER STATE + ' +
          user.uid +
          ' ACTIONS 28, WE WILL DETECT EVERYTHING AND UPDATE ACCORDINGLY'
      )
      store.state.realtimeUserListener = App.firestoreDB
        .collection('users')
        .doc(user.uid)
        .onSnapshot((currentUser) => {
          if (currentUser.exists) {
            const user = currentUser.data()
            user['.key'] = currentUser.id
            // console.log('ACTIONS: - RECEIVED A USER UPDATE: ', user)
          
            store.state.currentSpotterfishUser = user
            resolve()
          } else if (user !== undefined){
            console.log('Anonymous user!')
            const anonUser = {
              user_id: user.uid,
              email: 'Anonymous',
              user_name: user.displayName
            }
            store.state.currentSpotterfishUser = anonUser
            resolve()
          } else {
            reject('No user is signed in')
          }
        }, (error) => {
          console.error('Could not get realtime user listener', error)
        })
    })
  },

  user_check_account_and_permissions(state, projectScreeningRoomAndUserObj) {
    return new Promise((resolve, reject) => {
      const permissions = {}
      const user = projectScreeningRoomAndUserObj.user
      const project = projectScreeningRoomAndUserObj.project || undefined
      const screeningRoom =
        projectScreeningRoomAndUserObj.screeningRoom || undefined
      const userUID = user.uid || user.user_id || user['.key']

      if (project) {
        permissions.isProjectModerator = project.moderators.includes(userUID)
      }

      if (screeningRoom) {
        // Checks permissions in specific screening room
        permissions.hasModeratorKey =
          screeningRoom.people_with_moderator_key.includes(userUID)
        permissions.isSeated = screeningRoom.people_seated.includes(userUID)
        if (!permissions.isSeated) {
          // if uid is not present, the email might be if invited before user existed
          permissions.isSeated = screeningRoom.people_seated.includes(
            user.email
          )
        }
        permissions.isInvited = screeningRoom.project_people.includes(userUID)
      }

      // Check user permissions and available options

      if (user) {
        if (user.screening_rooms) {
          permissions.screeningRooms = user.screening_rooms.length
        } else {
          permissions.screeningRooms = 'none'
        }

        if (user.subscription_type) {
          /* @ TYPES:
            0 GUEST USER
            1 FULL BETA USER
            2 PAYING USER - TRIAL
            3 PAYING USER - PAYED
            4 PAYING USER - EXPIRED
          */
          permissions.subscriptionType = user.subscription_type
        } else {
          // USER IS ASSUMED GUEST USER
          permissions.subscriptionType = 0
        }

        if (user.subscription_seats) {
          permissions.subscriptionSeats = user.subscription_seats
        } else {
          permissions.subscriptionSeats = 1
        }

        permissions.hasGuestAccount = false // we do not look for it anymore: user.guest_account
        permissions.isFullUser = true // we do not look for it anymore. No guest users

        if (user.magic_subscription_data) {
          state
            .dispatch('decryptHash', user.magic_subscription_data)
            .then((decrypted) => {
              permissions.owedScreeninRooms = decrypted.screening_rooms
              permissions.accountExpirationDate =
                decrypted.account_expiration_date
              permissions.hasValidPurchasedScreeningRoom =
                decrypted.account_expiration_date < new Date()
              const allPermissions = {
                ...state.state.userPermissions,
                ...permissions,
              }
              state.commit('setUserPermissions', allPermissions)
              resolve(permissions)
            })
            .catch(() => {
              return false
            })
        } else {
          permissions.hasValidPurchasedScreeningRoom = false
          const allPermissions = {
            ...state.state.userPermissions,
            ...permissions,
          }
          state.commit('setUserPermissions', allPermissions)
          resolve(permissions)
        }
      } else {
        console.log('no user is passed in, resolving other permissions')
        resolve(permissions)
      }
    })
  },

  user_create(state, userObject) {
    const connection = SpotterfishAPI.open_spotterfish_connection(App.Firebase)
    return SpotterfishAPI.user_create(connection, userObject)
  },

  // fileObject = {fileName: 'A name of the file', file: file or blob}
  async user_upload_avatar(state, fileObject) {
    const self = this
    return await TopLevel.UploadAccountAvatar_TopLevel(
      App.Firebase,
      App.storageRef,
      fileObject.fileName,
      fileObject.file,
      function (progress_percentage) {
        self.progress = progress_percentage
      }
    )
  },

  user_update_pict(state, userObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('users')
        .doc(userObject.user_id)
        .update({
          pict: userObject.pict,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  user_update_username(state, userObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('users')
        .doc(userObject.user_id)
        .update({
          user_name: userObject.user_name,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  user_update(state, userObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('users')
        .doc(userObject.user_id)
        .update(userObject)
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  users_create_array(state, projects) {
    return new Promise((resolve, reject) => {
      let connectedUsersNoDuplicates = new Set()
      let moderatorsNoDuplicates = new Set()

      for (let i = 0; i < projects.length; i++) {
        for (let j = 0; j < projects[i].project_people.length; j++) {
          connectedUsersNoDuplicates.add(projects[i].project_people[j])
        }
        for (let k = 0; k < projects[i].moderators.length; k++) {
          moderatorsNoDuplicates.add(projects[i].moderators[k])
        }
      }
      resolve({
        connectedUsers: Array.from(connectedUsersNoDuplicates),
        moderators: Array.from(moderatorsNoDuplicates),
      })
    })
  },

  async users_get_connected(state, usersArray) {
    return await SpotterfishSession.getUserObjects(App.Firebase, usersArray)
  },

  users_get_connected_with_empty_slots(state, usersArray) {
    return new Promise(async (resolve, reject) => {
      let connectedUsers = []
      for (let i = 0; i < usersArray.length; i++) {
        await new Promise((resolve, reject) => {
          if (usersArray[i] === false) {
            connectedUsers.push(false)
            resolve(connectedUsers)
          } else {
            state
              .dispatch('user_get_from_uid', usersArray[i])
              .then((connectedUser) => {
                connectedUsers.push(connectedUser)
                resolve(connectedUsers)
              })
          }
        }).then((arr) => {
          if (arr.length === usersArray.length) {
            resolve(arr)
          }
        })
      }
    })
  },

  async user_get_from_uid(state, userUID) {
    return await CloudClient.call_CFgetUserObject(App.Firebase, userUID)
  },

  cf_remove_second_factor_from_user(state) {
    return new Promise((resolve, reject) => {
      return CloudClient.call_CFremoveSecondFactorFromUser(App.Firebase)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error)
          reject(error)
        })
    })
  },

  cf_check_room_verification_code(state, data) {
    return new Promise((resolve, reject) => {
      return CloudClient.call_CFcheckRoomVerificationCode(
        App.Firebase,
        data.screeningRoomId,
        data.verificationCode
      )
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error)
          reject(error)
        })
    })
  },

  user_query(state, text) {
    return new Promise((resolve, reject) => {
      let searchResults = []

      App.firestoreDB
        .collection('users')
        .where('email', '==', text)
        .get({ source: 'server' })
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          } else {
            querySnapshot.forEach((doc) => {
              searchResults.push(doc.data())
            })
            resolve(searchResults)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::VIDEO PLAYER CONTROLS::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  firebase_post_highlited_marker(state, updateObject) {
    return new Promise((resolve, reject) => {
      console.log('updating shared state with ', updateObject)
      App.db
        .ref(`shared_state/${updateObject.screeningRoomID}`)
        .update({
          highlited_marker: updateObject.marker ? updateObject.marker : false
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_shared_volume(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${updateObject.screeningRoomID}`)
        .update({
          volume: updateObject.volume,
          volume_updated_by: updateObject.useruid,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_user_seat(state, args) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${args.screeningRoomID}/users/${args.userUID}`)
        .update({
          seat: args.seat,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_light_switch_status(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${updateObject.screeningRoomID}`)
        .update({
          light_switch_status: updateObject.status,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_video_muted_status(state, args) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${args.screeningRoomID}/users/${args.userUID}`)
        .update({
          video_muted: args.onOff,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_tab_to_talk_status(state, args) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${args.screeningRoomID}/users/${args.userUID}`)
        .update({
          tab_to_talk: args.onOff,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  firebase_post_bathroom_mirror_status(state, args) {
    return new Promise((resolve, reject) => {
      App.db
        .ref(`shared_state/${args.screeningRoomID}/users/${args.userUID}`)
        .update({
          in_bathroom_mirror: args.onOff,
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::PROJECT::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  projects_get_available_with_realtime_listener(state, userUID) {
    return new Promise((resolve, reject) => {
      let projectsArray = []

      // console.log(
      //   'SETTING UP A REALTIME LISTENER FOR CHANGES IN LOGGED IN USERS PROJECTS + ' +
      //     userUID +
      //     ' ACTIONS 587, WE WILL DETECT EVERYTHING AND UPDATE ACCORDINGLY'
      // )
      store.state.realtimeProjectsForUserListener();
      
      store.state.realtimeProjectsForUserListener = App.firestoreDB
        .collection('projects')
        .where('project_people', 'array-contains', userUID)
        .orderBy('created_date', 'asc')
        .onSnapshot((project) => {
          console.log('new snapshot', project)
          if (!project.empty) {
            project.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const aProject = change.doc.data()
                aProject['.key'] = change.doc.id
                projectsArray.push(aProject)
              }
              if (change.type === 'modified') {
                const index = projectsArray.findIndex(
                  (x) => x['.key'] === change.doc.id
                )
                const changeProject = change.doc.data()
                changeProject['.key'] = change.doc.id
                projectsArray.splice(index, 1, changeProject)
                projectsArray[index] = changeProject
              }
              if (change.type === 'removed') {
                const index = projectsArray.findIndex(
                  (x) => x['.key'] === change.doc.id
                )
                projectsArray.splice(index, 1)
              }
            })
            store.state.projectsArray = projectsArray
            resolve(projectsArray)
          } else {
            console.log('No project documents!')
            store.state.projectsArray = []
            projectsArray = []
            resolve(projectsArray)
          }
        }, (error) => {
          console.error('realtimeProjectsForUserListener snapshot error', error)
        })
    })
  },

  project_get(state, projectID) {
    return SpotterfishSession.getProjectFromUID(
      App.spotterfishSession,
      projectID
    )
  },

  project_add(state, projectObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .add(projectObject)
        .then((docRef) => {
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
        })
        .catch((error) => {
          console.log('project_add: ', error)
        })
    })
  },

  project_delete(state, projectUID) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .doc(projectUID)
        .delete()
        .then(() => {
          resolve(projectUID)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  project_create_with_file_with_moderator(state, projectSettings) {
    return new Promise((resolve, reject) => {
      // console.log('screeningRoomID: ' + screeningRoomID)
      const projectObject = {
        created_date: new Date(),
        current_partially_uploaded_file: '',
        current_version_marker_lane: [],
        files: [],
        live_screening_room: 'null',
        moderators: projectSettings.moderators,
        people_seated: 0,
        previous_versions: [],
        project_description: '',
        project_description_tags: [],
        project_name: projectSettings.projectName,
        project_people: [],
        thumb: projectSettings.thumb
      }
      state.dispatch('project_add', projectObject).then((ref) => {
        resolve(ref)
      })
    })
  },

  project_update_user(state, projectUpdate) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .doc(projectUpdate.projectUID)
        .update({
          moderators: App.Firebase.firestore.FieldValue.arrayUnion(
            projectUpdate.userUID
          ),
          project_people: App.Firebase.firestore.FieldValue.arrayUnion(
            projectUpdate.userUID
          ),
        })
        .then(() => {
          resolve(undefined)
        })
    })
  },

  project_update_files(state, projectUpdate) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .doc(projectUpdate.projectUID)
        .update({
          files: App.Firebase.firestore.FieldValue.arrayUnion(
            projectUpdate.fileUID
          ),
        })
        .then(() => {
          resolve(undefined)
        })
    })
  },

  project_remove_file(state, u) {
    const fileId = u.fileUID
    const projectId = u.projectUID
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .doc(projectId)
        .update({
          files: App.Firebase.firestore.FieldValue.arrayRemove(
            fileId
          ),
        })
        .then(() => {
          resolve(undefined)
        })
    })
  },

  project_update(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('projects')
        .doc(updateObject.projectUID)
        .update(updateObject.updateFields)
        .then(() => {
          console.log('project was updated with', updateObject.updateFields)
          resolve(undefined)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::VERSION::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  version_get(state, versionID) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('versions')
        .doc(versionID)
        .get()
        .then((version) => {
          if (version.exists) {
            const ver = version.data()
            ver['.key'] = version.id
            resolve(ver)
          } else {
            reject(new Error('No version in this project'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  version_get_marker_lanes(state, versionID) {
    console.debug('getting versions for :' + versionID)

    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('versions')
        .doc(versionID)
        .get()
        .then((version) => {
          console.debug('got version: ', version.data())

          if (version.data().lanes) {
            state
              .dispatch('marker_lanes_get_all_in_array', version.data().lanes)
              .then((lanesArray) => {
                resolve(lanesArray)
              })
          } else {
            let emptyArray = []
            resolve(emptyArray)
          }
        })
    })
  },

  version_add(state, versionObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('versions')
        .add(versionObject)
        .then((docRef) => {
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
          console.error('Error adding Version: ', error)
        })
    })
  },

  version_update(state, versionObject) {
    return new Promise((resolve, reject) => {
      function jsonCopy(src) {
        return JSON.parse(JSON.stringify(src))
      }
      const target = jsonCopy(versionObject)
      // const uid = target['.key']
      delete target['.key']
      App.firestoreDB
        .collection('versions')
        .doc(versionObject['.key'])
        .update(target)
        .then(() => {
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  version_save_marker_lane(state, obj) {
    const markerLaneID = obj.markerLaneID
    const versionDBID = obj.versionDBID
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('versions')
        .doc(versionDBID)
        .update({
          lanes: App.Firebase.firestore.FieldValue.arrayUnion(markerLaneID),
        })
        .then(() => {
          resolve(undefined)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::FILE:::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  file_add(state, fileObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('files')
        .add(fileObject)
        .then((docRef) => {
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  file_remove(state, fileId) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('files')
        .doc(fileId)
        .delete()
        .then(() => {
          resolve(fileId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::SCREENING ROOM:::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  async screening_room_upload_logo(state, fileObject) {
    const self = this
    return await TopLevel.UploadLogoToScreeningRoom_TopLevel(
      App.Firebase,
      App.storageRef,
      fileObject.fileName,
      fileObject.file,
      function (progress_percentage) {
        self.progress = progress_percentage
      }
    )
  },

  screening_room_update(state, screeningRoom) {
    return new Promise((resolve, reject) => {
      // console.log(screeningRoom)
      const updateObject = JSON.parse(JSON.stringify(screeningRoom))
      const uid = screeningRoom['.key']
      delete updateObject['.key']

      App.firestoreDB
        .collection('screening_rooms')
        .doc(uid)
        .update(updateObject)
        .then(() => {
          console.debug('updated screening room')
          resolve(undefined)
        })
        .catch((error) => {
          console.error('Error updating screening room: ', error)
        })
    })
  },

  screening_room_give_user_moderator_key_hosting(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('screening_rooms')
        .doc(updateObject.screeningRoomID)
        .update({
          people_with_moderator_key:
            App.Firebase.firestore.FieldValue.arrayUnion(updateObject.userID),
        })
        .then(() => {
          console.log(
            'Successfully gave person a moderator key to Screening Room' +
              updateObject.screeningRoomID
          )
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
          console.error('Error getting user: ', error)
        })
    })
  },

  screening_room_remove_user_from_moderator_keys_hosting(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('screening_rooms')
        .doc(updateObject.screeningRoomID)
        .update({
          people_with_moderator_key:
            App.Firebase.firestore.FieldValue.arrayRemove(updateObject.userID),
        })
        .then(() => {
          console.log(
            'Successfully removed person from moderator keys in Screening Room: ' +
              updateObject.screeningRoomID
          )
          resolve(undefined)
        })
        .catch((error) => {
          reject(error)
          console.error('Error getting user: ', error)
        })
    })
  },

  screening_rooms_for_user_listener(state, {userID, callback}) {
    store.state.userScreeningRoomsListener()
    const rooms = []
    store.state.userScreeningRoomsListener = App.firestoreDB
      .collection('screening_rooms')
      .where('project_people', 'array-contains', userID)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          console.log('Change in screening room')
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              const room = change.doc.data()
              room['.key'] = change.doc.id
              rooms.push(room)
            }
            if (change.type === 'modified') {
              const index = rooms.findIndex(
                (x) => x['.key'] === change.doc.id
              )
              const room = change.doc.data()
              room['.key'] = change.doc.id
              rooms.splice(index, 1, room)
              rooms[index] = room
            }
            if (change.type === 'removed') {
              const index = rooms.findIndex(
                (x) => x['.key'] === change.doc.id
              )
              rooms.splice(index, 1)
            }            
          })
          callback(rooms)
        }
      }, (error) => {
        console.warn('user not logged in', error)
      })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::MARKER LANE::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  marker_lane_add(state) {
    const newMarkerLaneObject = {
      created_date: new Date(),
    }
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('marker_lanes')
        .add(newMarkerLaneObject)
        .then((docRef) => {
          // console.log('stored: ' + docRef.id)
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
          console.error('Error getting user: ', error)
        })
    })
  },

  marker_lane_update(state, updateObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('marker_lanes')
        .doc(updateObject.uid)
        .update(updateObject)
        .then((docRef) => {
          // console.log(`updated marker lane: ${updateObject.uid}`)
          resolve(updateObject.uid)
        })
        .catch((error) => {
          reject(error)
          console.error('Error getting user: ', error)
        })
    })
  },

  marker_lanes_get_all_in_array(state, markerLaneIDs) {
    return new Promise(async (resolve, reject) => {
      const tempArray = []
      const errors = []
  
      for (let i = 0; i < markerLaneIDs.length; i++) {
        let markerLaneId

        // hack to convert broken versions to functioning ones
        console.log(typeof markerLaneIDs[i])
        if (typeof markerLaneIDs[i] !== 'string') {
          if (Array.isArray(markerLaneIDs[i].markers) && 
              markerLaneIDs[i].markers.length > 0 && 
              markerLaneIDs[i].markers[0] && 
              typeof markerLaneIDs[i].markers[0] === 'object') {
            markerLaneId = markerLaneIDs[i].markers[0].original_parent_marker_lane;
          } else {
            markerLaneId = undefined;
          }
        } 
        else {
          markerLaneId = markerLaneIDs[i]
        }
  
        if (markerLaneId) {
          try {
            const markerLane = await App.firestoreDB
              .collection('marker_lanes')
              .doc(markerLaneId)
              .get()
  
            const data = markerLane.data()
            data['.key'] = markerLane.id
            data.uid = markerLane.id
            tempArray.unshift(data)
          } catch (error) {
            errors.push('Error fetching marker lane: ' + error.message)
          }
        } else {
          errors.push('marker lane id is undefined')
        }
  
        if (tempArray.length + errors.length === markerLaneIDs.length) {
          // Swallowing errors for now  
          resolve(tempArray)
          
        }
      }
    });
  },

  marker_lane_get_from_id(state, markerLaneID) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('marker_lanes')
        .doc(markerLaneID)
        .get()
        .then((markerLane) => {
          const data = markerLane.data()
          data['.key'] = markerLane.id
          data.uid = markerLane.id
          resolve(data)
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::MARKERS::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::


  marker_delete(state, markerID) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('markers')
        .doc(markerID)
        .delete()
        .then(() => {
          console.log('sucessfully deleted marker')
          resolve(undefined)
        })
        .catch((error) => {
          console.error('Error removing marker: ', error)
          reject(error)
        })
    })
  },

  marker_clear_updating_user_flag(state, marker) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('markers')
        .doc(marker['.key'])
        .get()
        .then((snapShot) => {
          if (snapShot.exists) {
            App.firestoreDB
              .collection('markers')
              .doc(marker['.key'])
              .update({
                currently_updating_user: '',
              })
              .then((docRef) => {
                resolve(undefined)
              })
              .catch((error) => {
                reject(error)
                console.error('Error updating marker: ', error)
              })
          }
        })
    })
  },


  markers_get_all_belonging_to_marker_lane(state, markerLaneUID) {
    return new Promise((resolve, reject) => {
      const tempArray = []
      App.firestoreDB
        .collection('markers')
        .where('original_parent_marker_lane', '==', markerLaneUID)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              const docData = doc.data()
              docData['.key'] = doc.id
              delete docData.frame_thumbnail
              tempArray.push(docData)
              resolve(tempArray)
            })
          } else {
            resolve(tempArray)
          }
        })
    })
  },

  create_version_archive(state, args) {
    const versionObject = args.versionObject
    const projectID = args.projectID
    const lanesJSONarray = []

    console.log({args})

    // ??? TODO : SHOULD BE A TRANSACTION
    return App.firestoreDB
      .collection('versions')
      .doc(versionObject['.key'])
      .get()
      .then(async (doc) => {
        const version = doc.data()
        version['.key'] = doc.id
        // console.log(version)

        // Putting the current version marker lane as the first object in lanes
        const proj = await App.firestoreDB
          .collection('projects')
          .doc(projectID)
          .get()

        const project = proj.data()

        const currentMarkerLaneUID = project.current_version_marker_lane

        const currentMarkerLane = typeof currentMarkerLaneUID === 'string' ? await App.firestoreDB.collection('marker_lanes').doc(currentMarkerLaneUID).get() : undefined
        const currentMarkers = await state.dispatch(
          'markers_get_all_belonging_to_marker_lane',
          currentMarkerLaneUID
        )
        const currentLane = {
          markers: currentMarkers,
          created_date: currentMarkerLane?.data().created_date || new Date()
        }

        lanesJSONarray.push(currentLane)

        if (version.lanes) {
          for (let i = 0; i < version.lanes.length; i++) {
            // console.log(version.lanes[i])
            const markerLane = Boolean(version.lanes[i]) ? await App.firestoreDB.collection('marker_lanes').doc(version.lanes[i]).get() : undefined
            const markers = await state.dispatch(
              'markers_get_all_belonging_to_marker_lane',
              version.lanes[i]
            )
            const lane = {
              markers: markers,
              created_date: markerLane?.data().created_date || new Date()
            }
            lanesJSONarray.push(lane)
          }
        }
        versionObject.archived_date = new Date()
        versionObject.lanes = lanesJSONarray

        // console.log('**** created Archive: ' + JSON.stringify(versionObject))
        const archivedVersion = await App.firestoreDB
          .collection('archived_versions')
          .add({
            versionObject,
          })

        const archive = {
          uid: archivedVersion.id,
          archived_date: versionObject.archived_date,
          name: versionObject.name,
          description: versionObject.description,
        }

        App.firestoreDB
          .collection('projects')
          .doc(projectID)
          .update({
            previous_versions:
              App.Firebase.firestore.FieldValue.arrayUnion(archive),
          })
          .then(() => {
            return archivedVersion.id
          })
          .catch((err) => {
            console.log(err)
            Sentry.captureException(err)
            throw err
          })
      })
  },

  chat_messages_get_all_belonging_to_marker_lane(state, markerLaneUID) {
    return new Promise((resolve, reject) => {
      const tempArray = []

      App.firestoreDB
        .collection('chat_messages')
        .where('original_parent_marker_lane', '==', markerLaneUID)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              const docData = doc.data()
              docData['.key'] = doc.id
              tempArray.push(docData)
              resolve(tempArray)
            })
          } else {
            resolve(tempArray)
          }
        })
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::CHAT MESSAGES::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  chat_messages_add(state, chatObject) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('chat_messages')
        .add(chatObject)
        .then((docRef) => {
          // console.log('stored chat message with id: ' + docRef.id)
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
          console.error('Error storing chat message: ', error)
        })
    })
  },

 

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::URL / HASH:::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  generateHash(state, obj) {
    return new Promise((resolve, reject) => {
      try {
        const cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(obj),
          process.env.VUE_APP_HASH_KEY
        ).toString()
        resolve(cipherText)
      } catch (error) {
        reject(error)
      }
    })
  },

  // ??? Use decryptSecret()
  decryptHash(state, hash) {
    return new Promise((resolve, reject) => {
      // console.log(hash)
      if (hash === undefined) {
        return undefined
      }
      const queryObject = CryptoJS.AES.decrypt(
        hash,
        process.env.VUE_APP_HASH_KEY
      )
      let res
      try {
        res = JSON.parse(queryObject.toString(CryptoJS.enc.Utf8))
        resolve(res)
      } catch (error) {
        resolve(error)
      }
    })
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::UTILITY::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::

  analytics_log_marker_export(state, exportOBJ) {
    const markerList = JSON.parse(exportOBJ.exported_marker_lane_JSON)
    
    console.log('logging export')

    SpotterfishSession.trackEvent(
      App.spotterfishSession, 
      'exported_marker_list', 
        {
          userUID: exportOBJ.user_id,
          project: exportOBJ.project_id,
          numberOfMarkers: markerList.markers.length,
          format: {
            AUDITION: markerList.AUDITION || 0,
            JSON: markerList.JSON || 0,
            LOGIC: markerList.LOGIC || 0,
            MC: markerList.MC || 0,
            PREMIERE: markerList.PREMIERE || 0,
            PT: markerList.PT || 0,
            RESOLVE: markerList.RESOLVE || 0,
            REAPER: markerList.REAPER || 0,
          }
        }
    )

  },

  aaf_push_export_json_to_db(state, exportOBJ) {
    return new Promise((resolve, reject) => {
      App.firestoreDB
        .collection('exports')
        .add(exportOBJ)
        .then((docRef) => {
          // console.log('stored: ' + docRef.id)
          resolve(docRef.id)
        })
        .catch((error) => {
          reject(error)
          console.error('Error exporting markers: ', error)
        })
    })
  },

  aaf_listen_for_download_urls(state, docid) {
    return new Promise((resolve, reject) => {
      let unsubscribe = App.firestoreDB
        .collection('exports')
        .doc(docid)
        .onSnapshot((snapshot) => {
          console.log(snapshot.data())
          if (snapshot.data().exported_marker_lane_urls) {
            // console.log('got the right data back, unsubscribing and resolving')
            unsubscribe()
            resolve(snapshot.data().exported_marker_lane_urls)
          }
        }, (error) => {
          console.error('aaf_listen_for_download_urls snapshot error', error)
        })
    })
  },

  aaf_download_from_storage(state, url) {
    return new Promise((resolve, reject) => {
      App.AAFStorage.refFromURL(url)
        .getDownloadURL()
        .then((newurl) => {
          // console.log('should get :' + newurl + 'from AAFstorage')
          var xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function (event) {
            var blob = xhr.response
            resolve(blob)
          }
          xhr.open('GET', newurl)
          xhr.send()
        })
        .catch((error) => {
          console.error(error)
        })
    })
  },

  checkBrowser(state, alertbox, color) {
    return new Promise(async (resolve) => {
      let env = 'chrome'
      if (isMobile) {
        env = 'mobile'
      } else if (!window.chrome) {
        env = 'non-chrome'
      } else if (isDevelopment()) {
        env = 'chrome'
      }

      if (env === 'chrome') {
        resolve(undefined)
      } else {
        console.log(env)
        const overRide = await alertbox.open(
          `${env === 'mobile' ? 'Mobile device detected' : 'Non-Chrome detected'}`,
          {
            line1: 'Some features will not work in your current browser. Please use Google Chrome.',
            line3: 'Override and proceed with non-supported browser?'
          },
          { color: '#6391CA' }
        )
        if(overRide === true) {
          resolve(undefined)
        } else {
          App.Firebase.auth().signOut()
          window.location.href = 'https:/support.mixstage.io'
        }
      } 
    })
  },

  removeAllRealtimeListeners(state) {
    return new Promise((resolve, reject) => {
      store.state.boundActiveVersion()
      store.state.realtimeUserListener()
      store.state.realtimeProjectsForUserListener()
      // store.state.realtimeProjectListener()
      store.state.onlineStatusRealtimeListener()
      store.state.userScreeningRoomsListener()
      resolve(undefined)
    })
  },

  getOnlineStatus(state) {
    store.state.onlineStatusRealtimeListener()
    store.state.onlineStatusRealtimeListener = App.Firebase.firestore()
      .collection('status')
      .where('state', '==', 'online')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            store.state.roomsWhereUsersAreOnline.push(
              change.doc.data().screeningRoom
            )
          }
          if (change.type === 'removed') {
            store.state.roomsWhereUsersAreOnline.splice(
              change.doc.data().screeningRoom,
              1
            )
          }
        })
      }, (error) => {
        console.error('onlineStatusRealtimeListener snapshot error', error)
      })
  },
}
