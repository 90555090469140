const { assert } = require('../spotterfish_library/SpotterfishCore')
const dougBrokenUser = require('./dougBrokenUser')

function runUnitTests () {
  console.log('Eratests -- START')

  dougBrokenUser.runUnitTests()

  console.log('Eratests -- END')
}

module.exports = {
  runUnitTests
}
