<template>
  <div class="project-people dark-panel" :class="[small ? 'scaled small' : '']">
    <span v-if="!small">{{ projectPeople.length > 1 ? 'Shared with' : 'Share project' }}</span>

    <div class="people-list">
      <div 
        class="project-user" 
        :class="{'moderator': userIsModerator(user), 'pending': userIsPendingSignup(user)}" 
        v-for="(user, index) in projectPeople" :key="user.user_id"
      >
        <div v-show="!small || (small && index < 2)">
          <v-icon
            color="#FFD700"
            class="crown"
          >
            mdi-crown
          </v-icon>
          <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">
              <div class="user" v-bind="attrs" v-on="on">
                <svg viewBox="0 0 30 33">
                  <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">
                    {{getUserInitials(user)}}
                  </text>
                </svg>
              </div>
            </template>
            <v-list v-if="!userIsOwner(user)">
              <v-list-item @click="removeUser(user)">
                Remove user<span style="margin-left: 3px;"><strong>{{ user.email ? user.email : user.user_name }}</strong></span>
              </v-list-item>
              <v-list-item @click="userIsModerator(user) ? removeModerator(user) : makeModerator0(user)">
                {{userIsModerator(user) ? 'Remove Team Member status' : 'Make Team Member'}}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-show="projectPeople.length > 2 && small" class="user more-users">
        <svg viewBox="0 0 30 33">
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">
            + {{ projectPeople.length - 3 }}
          </text>
        </svg>
      </div>
      <div v-show="(small && projectPeople.length < 2) || !small" class="user add-user" @click.stop="openAddUserDialog" style="margin-left: 0.5em;">
          <v-icon style="margin: 1px;" color="black">person_add</v-icon>
      </div>
      <v-progress-circular
        v-if="loading"
        class="spinner"
        indeterminate
        :color="colors.mainAccent"
        size="30"
      />

    </div>
        <v-dialog v-model="showAddUserDialog" width="500" overlay-opacity="0.95">
      <div class="add-user-dialog">
        <span>Add user to project <v-progress-circular v-if="sharingSpinner" indeterminate :color="colors.mainAccent" size="30" style="margin-left: 1rem; margin-bottom:4px;" /></span>
        
        <v-form v-model="addUserFormValid" @submit.prevent="handleAddUser">
          <v-text-field
            autofocus
            outlined
            color="white"
            hide-details
            v-model="userEmailToAdd"
            label="User email"
            maxlength="100"
            :rules="[hasValidEmail]"
            required
            style="margin-bottom: 1rem;"
          />
          <div v-if="showShareSpotterfish && !inviteSent" style="margin-bottom: 1rem;">
            <!-- Could not find a Spotterfish user with this email. Click <a @click.stop="sendInvite">here</a> to send an invite -->
          </div>
          <div v-if="inviteSent" style="margin-bottom: 1rem;">
            Invite sent
          </div>
          <v-btn :color="colors.mx.secondary" :disabled="!addUserFormValid" type="submit">Invite user</v-btn>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
//@ts-nocheck
import App from '@/App.vue'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  props: [
    'projectID',
    'projectDB',
    'small'
  ],
  data(){
    return {
      colors: require('@/lib/ui/colors.js').colors,

      projectPeople: [],
      projectModerators: [],
      pendingInvites: [],
      showAddUserDialog: false,
      userEmailToAdd: '',
      addUserFormValid: false,
      loading: false,
      showShareSpotterfish: false,
      sharingSpinner: false,
      inviteSent: false,
    }
  },
  methods: {
    getUserInitials(user){
      if(user?.user_name){
        const initials = user.user_name.split(' ').map(part => part[0])
        return initials.join('')
      }
    },
    async getProjectPeople(uidList, pendingInvites){
      this.loading = true
      const projectUids = uidList || this.projectDB.project_people
      const users = projectUids.filter(uid => typeof uid === 'string').map(async (uid) => {
        const user = await SpotterfishSession.getUserFromId(App.spotterfishSession.firestoreDB, uid)
        return user
      })

      this.projectPeople = await Promise.all(users)
      const pendingList = pendingInvites || this.projectDB.pending_invites || []
      for (const [index, value] of pendingList.entries()) {
        const user = {
          user_id: index,
          user_name: value,
          pendingEmail: value
        }
        this.projectPeople.push(user)
      }

      this.loading = false
      console.log('Got project people again:', this.projectPeople)
    },
    getProjectModerators(){
      this.projectModerators = this.projectDB.moderators.filter(uid => typeof uid === 'string')
      console.log(this.projectModerators)
    },
    userIsModerator(user){
      return this.projectModerators.includes(user.user_id)
    },
    userIsPendingSignup(user){
      return Boolean(user.pendingEmail)
    },
    userIsOwner(user){
      return this.projectModerators[0] === user.user_id
    },
    async handleAddUser(){
      this.loading = true
      this.sharingSpinner = true

      try{
        const base_url = window.location.origin + '/#/'
        const project = await CloudClient.call_CFshareProject(App.spotterfishSession.firebase, this.userEmailToAdd, this.projectID, base_url)
        this.getProjectPeople(project.project_people, project.pending_invites)
        this.pendingInvites = project.pending_invites
        this.userEmailToAdd = ''
        this.showAddUserDialog = false
        this.loading = false
        this.sharingSpinner = false

      } catch(e){
        console.log('Could not share project', e.message)
        if(e.message === 'This user does not exist'){
          this.showShareSpotterfish = true
        }
        this.sharingSpinner = false
        this.loading = false
      }

    },
    async removeUser(user){
      this.loading = true
      const userId = user.pendingEmail ? `$pending-${user.pendingEmail}` : user.user_id

      if (this.projectDB.used_by?.includes(userId)) {
        const conf = await this.$root.$globalConfirm.open('Project is active', {
            line1: `This user is working on this project right now. Removing them will unload their session immediately.`,
            line3: 'Proceed?'
          },
          { color: this.colors.mainAccent })

        if (!conf) { 
          this.loading = false
          return 
        }
      }
      
      try{
        const base_url = window.location.origin + '/#/'
        const project = await CloudClient.call_CFremoveFromProject(App.spotterfishSession.firebase, userId, this.projectID, base_url)
        this.getProjectPeople(project.project_people, project.pending_invites)
        this.pendingInvites = project.pending_invites
        this.loading = false
      } catch(e){
        console.log('Could not remove user from project', e)
        this.loading = false
      }
    },

    async makeModerator0 (user) {
      if (user.pendingEmail) {
        this.$emit('request-open-purchase-dialog', { user: user, caller: 'projectPeople', projectId: this.projectID })
        return
      }
      this.makeModerator(user)
    },

    async makeModerator(user){
      const base_url = window.location.origin + '/#/'
      this.loading = true
      try{
        this.projectModerators = await CloudClient.call_CFshareProjectModerator(App.spotterfishSession.firebase, user.user_id, this.projectID, base_url)
        console.log(this.projectModerators)
        this.loading = false
      } catch(e) {  
        console.error('Could not make user moderator', JSON.stringify(e))
        console.log(user)
        if (e.message === 'A user needs to be on the unlimited plan to moderate a project.') {
          this.$emit('request-open-purchase-dialog', { user: user, caller: 'projectPeople', projectId: this.projectID })
          this.loading = false
          return
        }
        alert(e.message)
        this.loading = false
      }
    },

    async removeModerator(user){
      if (user.pendingEmail) return
      this.loading = true
      try{
        const base_url = window.location.origin + '/#/'
        this.projectModerators = await CloudClient.call_CFremoveProjectModerator(App.spotterfishSession.firebase, user.user_id, this.projectID, base_url)
        console.log(this.projectModerators)
        this.loading = false
      } catch(e) {
        console.error('Could not remove user moderator', e)
        this.loading = false
      }
    },

    hasValidEmail(value){
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    },

    openAddUserDialog(){
      this.showAddUserDialog = true
      this.inviteSent = false
      this.userEmailToAdd = ''
    }

  },
  mounted(){
    this.getProjectPeople()
    this.getProjectModerators()
    this.pendingInvites = this.projectDB.pending_invites || []
  },
}
</script>
<style lang="scss" scoped>

@function scale($value) {
  @return $value * 0.2;
}

.small {
  scale: 0.6;
}

.more-users {
  background-color: #9D88B2 !important;
}
.project-people{
  margin: 0 1rem 1rem 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #373737;
  border-radius: 5px;
  text-align: left;
  span {
    text-align: left;
  }

  &.scaled {
    margin: scale(0rem) scale(1rem) scale(1rem) scale(1rem);
    padding: scale(1.5rem) scale(1rem) scale(1rem) scale(1rem);
    border: none !important;
    background: none !important;
  }

  .people-list {
    display: flex;
    margin-top: 0.5rem;
    .project-user{
      position: relative;
      .crown {
        display: none;
        position: absolute;
        top: -14px;
        left: 1px;
        margin-left: 0.375rem;
        
      }
      &.moderator{
        .crown{
          display: block;
        }
        .user{
          border: 2px solid white;
        }
      }
      &.pending {
        .user{
          border: 2px solid #393939;
          background-color: #4b4b4b;
        }
      }
    }
    .user{
      width: 30px;
      height: 30px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #ddd;
      cursor: pointer;
      margin-left: 0.25rem;
      &.add-user{
        background: #ddd;
        border: 1px dashed #fff
      }
    }
  }
}

.add-user-dialog{
  padding: 2rem;
  background: #212121;
  span{
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.spinner{
  margin-left:0.25rem;
}

</style>
