<template>
  <v-dialog v-model="dialog" :overlay-color="colors.mx.black" overlay-opacity="0.92" width="570" @keydown.esc="cancel()">
    <div class="dialog-container" :style="{ 'background-color': colors.mx.darkPanel }">
      <!-- HEADER -->
      <v-toolbar dark style="max-height: 44px;">
        <v-btn small style="width: 10%;" class="mb-4" :color="colors.mx.secondary" @click="$emit('faq-button-clicked')">FAQ</v-btn>
        <v-spacer></v-spacer>
        <h1 class="header-title mb-4">Keyboard Shotcuts</h1>
        <v-spacer></v-spacer>
        <div style="width: 10%;">
        </div>
        <v-btn fab dark small style="position: absolute; right: 4px; top: 1px; display: flex; align-items: center; justify-content: center;" 
          @click="cancel()"
        >
          <v-icon class="mt-1">close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-for="(section, i) in keyboardShorcutsList" :key="i" style="padding: 1rem 1rem;">
        <!-- SECTION HEADER -->
        <h3 class="mt-3 mb-3">{{section.header}}</h3>

        <v-row v-for="(row, index) in section.shortcuts" :key="index" class="rows">
          <!-- ROW TITLE -->
          <v-col cols="6">
            <div class="row-title">
              <h3>{{row.title}}</h3>
            </div>
          </v-col>

          <!-- KEYBOARD ICONS -->
          <v-col cols="6" class="text-right">
            <div class="icon-gridbox">
              <div v-for="(icon, iconIndex) in row.icons" :key="iconIndex" class="icon-container">
                <v-icon v-if="icon.divider" class="icon-divider">{{icon.divider}}</v-icon>
                <v-btn small :color="colors.mx.panel" v-if="icon.icon"><v-icon size="1.2em">{{icon.icon}}</v-icon></v-btn>
                <v-btn small :color="colors.mx.panel" v-if="icon.text" style="font-size: 0.8em">{{icon.text.trim()}}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-dialog>
</template>


<script>
// @ts-nocheck

export default {
  data: () => ({
    logos: require('@/lib/ui/logos.js').logos,
    colors: require('@/lib/ui/colors.js').colors,
    dialog: false,
    resolve: null,
    reject: null,
    keyboardShorcutsList: [],
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200
    }

  }),
  computed: {
  },
  methods: {
    open (keyboardShorcutsList) {
      this.dialog = true
      this.keyboardShorcutsList = keyboardShorcutsList
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(true)
      this.dialog = false
    }
  }
}
</script>

<style scoped lang="scss" >

@import '@/assets/global.scss';

.dialog-container {
  background-color: $color-mx-x-dark-panel !important;
  min-height: 55vh;
  min-width: 100%;
  max-width: 100%;
  margin: auto;
}
.toolbar {
  background-color: $color-mx-dark-panel !important;
  
}
.header-title{
  width: 80%;
  font-size: 14px; 
  text-align: center; 
}
.rows{
  height: 34px; 
  display:flex; 
  align-items: center;

}
.row-title {
  width: 100%; 
  display:flex; 
  flex-flow: row wrap; 
  align-items: center;
}
.row-title > h3 {
  font-weight: 400; 
  font-size: 0.8rem;
}

.icon-gridbox {
  width: 100%; 
  display:flex; 
  flex-flow: row wrap; 
  align-items: flex-end; 
  justify-content: flex-end;
}
.icon-container{
  margin: 0 4px 0 4px; 
  display:flex; 
  min-width:45px; 
  height: 34px; 
  align-items: center; 
  justify-content: center;
}
.icon {
  border-radius: 5px; 
  margin: 0 4px; 
  display:flex; 
  min-width: 3rem; 
  height: 3rem; 
  align-items: center; 
  justify-content: center; 
}
.icon-divider{
  display:flex; 
  width: 1rem; 
  height: 3rem; 
  align-items: center; 
  justify-content: center; 
  margin: 0 0.5rem 0 0;
}
.icon-text {
  font-size: 1.25rem; 
  font-family: 'Inter', sans-serif; 
  padding: 0 1rem;
}
</style>

