const SpotterfishCore = require('./SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isObjectInstance = SpotterfishCore.isObjectInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isArrayInstance = SpotterfishCore.isArrayInstance

const testUser = {
  metadata: {
    creationTime: 'Wed, 29 Jul 2020 17:39:09 GMT'
  }
}

function checkUser (user) {
  assert(isObjectInstance(user))
  assert(isObjectInstance(user.metadata))
  assert(isStringInstance(user.metadata.creationTime))
}

// Returns the Firebase user signup date as a timestamp.
function getSignupDateTimestamp (user) {
  checkUser(user)
  const userCreationDate = new Date(user.metadata.creationTime)
  return Math.floor(userCreationDate.getTime() / 1000) // Seconds since epoch.
}

function prove_getSignupDateTimestamp () {
  unitTestAssert(getSignupDateTimestamp(testUser) === 1596044349)
}

// Returns the Firebase user signup date as an ISO string.
function getSignupDateString (user) {
  checkUser(user)
  const timestamp = getSignupDateTimestamp(user)
  return new Date(timestamp * 1000).toISOString()
}

function prove_getSignupDateString () {
  unitTestAssert(getSignupDateString(testUser) === '2020-07-29T17:39:09.000Z')
}

// ??? Check that format matches firebase user_id, always 16 characters? Check for illegal characters.
function isValidUserID (userID) {
  if (SpotterfishCore.isStringInstance(userID) === false) {
    return false
  }
  return true
}

function isUserAnonymous (firebaseUser) {
  checkUser(firebaseUser)
  isArrayInstance(firebaseUser.providerData)
  return firebaseUser.providerData.length === 0
}

function runUnitTests () {
  console.log('User.js -- START')

  prove_getSignupDateTimestamp()
  prove_getSignupDateString()

  console.log('User.js -- END')
}

module.exports = {
  checkUser,
  getSignupDateTimestamp,
  getSignupDateString,

  isValidUserID,
  isUserAnonymous,

  runUnitTests
}
