<template>
  <v-container
    container--fluid
    v-shortkey.once="keyMappings"
    @shortkey="handleShortKeys"
    style="margin-bottom: 4px"
  >
    <v-row wrap v-show="showControls">
      <v-col
        cols="12"
        @mouseleave="onMouseLeaveWindow()"
        class="transportSliderContainer"
      >
        <markerlane
          v-if="clock"
          :markers="markers"
          :htmlOutput_selectedMarker="htmlOutput_selectedMarker"
          @request-select-marker="onSelectMarker($event)"
          @request-highlight-marker="$emit('request-highlight-marker', $event)"
          @request-delete-marker="$emit('request-delete-marker', $event)"
          @request-reposition-marker="
            $emit('request-reposition-marker', $event)
          "
          @request-update-marker-category="
            $emit('request-update-marker-category', $event)
          "
        >
        </markerlane>
        <ProgressSlider
          v-model="playPositionSliderSeconds"
          :max="Number(calcCurrentVideoLength)"
          :step="1 / videoFileFrameRate"
          ref="seekSliderRef"
          @start="isUserDraggingSeekSlider = true"
          @end="isUserDraggingSeekSlider = false; requestPositionSeconds(playPositionSliderSeconds), blur()"
          @input="onSeekSliderMove"
          @change="requestPositionSeconds($event), blur()"
          :thumbLabelContent="realtimeSMPTEHTMLAdaptor()"
        />
        <!-- :disabled="!projectName || UI_disableTimelineInput"
          id="seekSlider"
          v-model="playPositionSliderSeconds"
          step="0"
          style="margin: 8px 16px -10px 16px; height: 14px; border-radius: 3px"
          :always-dirty="true"
          :height="trackHeight"
          thumb-color="white"
          :color="colors.lightGray"
          :background-color="colors.darkGray"
          :track-color="colors.darkGray"
          @start="isUserDraggingSeekSlider = true"
          @end=";(isUserDraggingSeekSlider = false), blur()"
          @input="onSeekSliderMove"
          @change="requestPositionSeconds($event), blur()"
          :max="Number(calcCurrentVideoLength)"
          thumb-label          -->
      </v-col>
    </v-row>
    <v-row class="admin-controls" no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="3" class="smpte-container" v-show="showControls">
            <!-- LEFT SIDE ADMIN CONTROLS -->
            <div class="xtooltip-container" :class="{
                  disabled: !projectName,
                  'no-tooltip': isUserDraggingSeekSlider,
                }">
            <div class="smpte">
              <input
                style="width: 100%; max-width: calc(12.2rem)"
                :style="calcTransportStyle.smpte"
                :disabled="UI_disableTimelineInput"
                ref="SMPTEInputRef"
                type="text"
                @focus="isUserEnteringTcText = true"
                @blur="isUserEnteringTcText = false"
                @keyup.enter="blur"
                @click="selectText"
                @change="onSMPTEinputChanged"
                :value="getInteractiveTimecodeInputText()"
              />
            </div>
            <!-- <div class="tooltip-content top">{{ 
              projectName
                ? 'Time code position. Click to edit. Press enter to confirm.'
                : 'Time code position is available when a project is loaded'
            }}</div> -->
          </div>

          </v-col>
          <v-spacer></v-spacer>

          <v-col
            cols="2"
            v-show="showControls && UI_showTransportButtons"
            :style="
              calcTransportStyle.shrink1
                ? 'display: flex; justify-content: center; align-items-center;'
                : ''
            "
          >
            <!-- DAW STREAMER SELECTOR -->
            <div class="mr-1 mt-auto mb-auto">
            <InputMonitor :enabled="iAmDAWstreaming" @click="toggleDawStreamerMute"/>
            </div>
            <div class="xtooltip-container">
              <div> <!-- This div serves as the activator for the tooltip -->
                <v-select
                  dense
                  outlined
                  :disabled="!UI_showDawStreamerSelector"
                  dark
                  persistent-hint
                  :style="calcTransportStyle.dawstreamerSelect"
                  class="daw-streamer-select"
                  placeholder="Project audio"
                  :label="calcTransportStyle.shrink1 ? 'Sync Source' : 'Audio/Sync Source'"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="getActiveDawStreamers"
                  :value="getSelectedStreamer"
                  ref="streamerSelect"
                  @change="switchSelectedStreamer($event)"
                />
              </div>
              <!-- <div class="tooltip-content top">
               Choose a DAW streamer. Available streamers will appear here once started.
              </div> -->
            </div>

          </v-col>

          <v-col
            v-show="showControls"
            cols="2"
            style="
              justify-content: center;
              align-items: center;
              padding: 0;
              flex-shrink: 0;
              overflow: hidden;
            "
          >
            <!-- CENTER ADMIN CONTROLS -->
            <div
              class="toggle-button seek-button noSelect"
              :class="{
                disabled: !projectName || !UI_showVolumeSlider,
                'no-tooltip': isUserDraggingSeekSlider,
              }"
              v-show="UI_showTransportButtons && !calcTransportStyle.hide"
            >
              <svg
                v-shortkey.once="{
                  backA: ['alt', 'arrowleft'],
                  backB: [','],
                  backC: [''],
                  backFive: ['j'],
                }"
                style="
                  cursor: pointer;
                  width: 1.8rem;
                  margin-bottom: 0.2rem;
                "
                class="grow"
                @shortkey="onStepSeekKey"
                @click="
                  onStepSeekFrames(-kQuickSeekButtonDurationSeconds * 25)
                "
                @mousedown="firstButtonActive = true"
                @mouseup="firstButtonActive = false"
                xmlns="http://www.w3.org/2000/svg"
                width="47.104"
                height="53.191"
                viewBox="0 0 47.104 53.191"
              >
                <text
                  style="font-size: 1.5rem"
                  transform="translate(15.5 38.0)"
                  fill="#fff"
                >
                  {{ kQuickSeekButtonDurationSeconds }}
                </text>
                <path
                  d="M5128.471-276.293h0Zm0,0c.129-.067,8.343-8.479,8.343-8.412s2.625,2.423,2.625,2.49-5.788,5.72-5.788,5.72l5.586,5.653s-2.625,2.625-2.625,2.759S5128.6-276.227,5128.471-276.293Zm16.739-2.293q.987.049,1.969.183a22.949,22.949,0,0,1,13.169,6.539,23.05,23.05,0,0,1,0,32.6,23.049,23.049,0,0,1-32.6,0,23.054,23.054,0,0,1-1.866-30.493c1.611,1.172,3.389,2.47,4.654,3.395a17.669,17.669,0,0,0,1.523,23.249,17.666,17.666,0,0,0,24.983,0,17.665,17.665,0,0,0,0-24.984,17.585,17.585,0,0,0-9.927-4.988l-.017-.043h-.7l2.261,2.289s-2.624,2.625-2.624,2.759-8.014-8.144-8.142-8.211c.129-.067,8.344-8.479,8.344-8.412s2.625,2.423,2.625,2.49C5148.862-282.175,5146.859-280.2,5145.21-278.586Zm-7.317,2.293h0Z"
                  transform="translate(-5121 284.706)"
                  fill="#fff"
                />
                <path
                  d="M5128.471-276.293h0Zm0,0c.129-.067,8.343-8.479,8.343-8.412s2.625,2.423,2.625,2.49-5.788,5.72-5.788,5.72l5.586,5.653s-2.625,2.625-2.625,2.759S5128.6-276.227,5128.471-276.293Zm16.739-2.293q.987.049,1.969.183a22.949,22.949,0,0,1,13.169,6.539,23.05,23.05,0,0,1,0,32.6,23.049,23.049,0,0,1-32.6,0,23.054,23.054,0,0,1-1.866-30.493c1.611,1.172,3.389,2.47,4.654,3.395a17.669,17.669,0,0,0,1.523,23.249,17.666,17.666,0,0,0,24.983,0,17.665,17.665,0,0,0,0-24.984,17.585,17.585,0,0,0-9.927-4.988l-.017-.043h-.7l2.261,2.289s-2.624,2.625-2.624,2.759-8.014-8.144-8.142-8.211c.129-.067,8.344-8.479,8.344-8.412s2.625,2.423,2.625,2.49C5148.862-282.175,5146.859-280.2,5145.21-278.586Zm-7.317,2.293h0Z"
                  transform="translate(-5121 284.706)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.5"
                />
              </svg>
            </div>

            <!-- PLAY/PAUSE -->
              <div :class="{ disabled: !projectName || !UI_showVolumeSlider, 'no-tooltip': isUserDraggingSeekSlider }" v-show="UI_showTransportButtons">
                <v-icon
                  v-if="playPauseState.icon"
                  :icon="playPauseState.icon"
                  :disabled="!projectName || !UI_showVolumeSlider"
                  class="whiteContoured grow"
                  v-shortkey="{ a: ['space'], b: ['k'] }"
                  @shortkey="playPauseState.action"
                  @click="playPauseState.action"
                  x-large
                >
                  {{ playPauseState.icon }}
                </v-icon>
              </div>
            <!-- <span>{{ playPauseState.tooltip }}</span> -->

            <div
              class="toggle-button seek-button noSelect"
              :class="{
                disabled: !projectName || !UI_showVolumeSlider,
                'no-tooltip': isUserDraggingSeekSlider,
              }"
              v-show="UI_showTransportButtons && !calcTransportStyle.hide"
            >
              <svg
                style="
                  cursor: pointer;
                  width: 1.8rem;
                  margin-bottom: 0.2rem;
                "
                class="grow"
                v-shortkey.once="{
                  fwdA: ['alt', 'arrowright'],
                  fwdB: ['.'],
                  fwdC: [''],
                  fwdFive: ['l'],
                }"
                @shortkey="onStepSeekKey"
                @click="
                  onStepSeekFrames(kQuickSeekButtonDurationSeconds * 25)
                "
                @mousedown="sixthButtonActive = true"
                @mouseup="sixthButtonActive = false"
                xmlns="http://www.w3.org/2000/svg"
                width="47.104"
                height="53.191"
                viewBox="0 0 47.104 53.191"
              >
                <text
                  style="font-size: 1.5rem"
                  transform="translate(15.5 38.0)"
                  fill="#fff"
                >
                  {{ kQuickSeekButtonDurationSeconds }}
                </text>
                <path
                  d="M4941.9-276.587h0Zm0,0c-.129-.067-8.344-8.479-8.344-8.412s-2.625,2.423-2.625,2.49,5.788,5.72,5.788,5.72l-5.585,5.653s2.625,2.625,2.625,2.759S4941.772-276.521,4941.9-276.587Zm-16.739-2.293q-.987.049-1.969.183a22.947,22.947,0,0,0-13.169,6.539,23.05,23.05,0,0,0,0,32.6,23.05,23.05,0,0,0,32.6,0,23.054,23.054,0,0,0,1.865-30.493c-1.611,1.172-3.39,2.47-4.654,3.395a17.669,17.669,0,0,1-1.523,23.249,17.666,17.666,0,0,1-24.983,0,17.665,17.665,0,0,1,0-24.984,17.587,17.587,0,0,1,9.927-4.988q.01-.021.018-.043h.7l-2.261,2.289s2.625,2.625,2.625,2.759,8.014-8.144,8.142-8.21c-.129-.067-8.344-8.479-8.344-8.412s-2.625,2.423-2.625,2.49C4921.509-282.469,4923.511-280.5,4925.161-278.88Zm7.317,2.293h0Z"
                  transform="translate(-4903.271 285)"
                  fill="#fff"
                />
                <path
                  d="M4941.9-276.587h0Zm0,0c-.129-.067-8.344-8.479-8.344-8.412s-2.625,2.423-2.625,2.49,5.788,5.72,5.788,5.72l-5.585,5.653s2.625,2.625,2.625,2.759S4941.772-276.521,4941.9-276.587Zm-16.739-2.293q-.987.049-1.969.183a22.947,22.947,0,0,0-13.169,6.539,23.05,23.05,0,0,0,0,32.6,23.05,23.05,0,0,0,32.6,0,23.054,23.054,0,0,0,1.865-30.493c-1.611,1.172-3.39,2.47-4.654,3.395a17.669,17.669,0,0,1-1.523,23.249,17.666,17.666,0,0,1-24.983,0,17.665,17.665,0,0,1,0-24.984,17.587,17.587,0,0,1,9.927-4.988q.01-.021.018-.043h.7l-2.261,2.289s2.625,2.625,2.625,2.759,8.014-8.144,8.142-8.21c-.129-.067-8.344-8.479-8.344-8.412s-2.625,2.423-2.625,2.49C4921.509-282.469,4923.511-280.5,4925.161-278.88Zm7.317,2.293h0Z"
                  transform="translate(-4903.271 285)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.5"
                />
              </svg>
            </div>
            <!-- <span>{{ getSeekButtonTooltipMessage('Forward', '[L]') }}</span> -->
          </v-col>

          <v-col
            cols="5"
            style="
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              overflow: hidden;
            "
          >
            <div
              :style="
                showControls
                  ? 'margin-left: auto;'
                  : 'margin-right: auto; margin-bottom: 14px;'
              "
              class="xtooltip-container"
              style="
                flex-grow: 1;
                max-width: 70%;
                min-width: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              "
            >
              <!-- RIGHT SIDE ADMIN CONTROLS -->
              <customMarkerSelector
                v-show="showControls || showDropMarkerPanel"
                ref="markerSelector"
                class="marker-selector"
                :commentTypes="fixedCommentTypes"
                :projectLoaded="projectName ? true : false"
                :htmlOutput_selectedMarker="htmlOutput_selectedMarker"
                :calcTransportStyle="calcTransportStyle"
                @request-drop-marker="$emit('request-drop-marker', $event)"
                @request-save-marker="onRequestMarkerSave($event)"
                @request-update-marker-category="
                  $emit('request-update-marker-category', $event)
                "
                @request-stop-propagation-of-enter-key="
                  childRequestedNoPropagationOfEnterKey = true
                "
                @request-select-marker="onSelectMarker($event)"
                @request-step-through-markers="stepThroughMarkers($event)"
                @request-hide-markerselector="showDropMarkerPanel = false"
              >
              </customMarkerSelector>
              <!-- <div class="tooltip-content top">
                  Drop a marker on the Current Location [ Return ]
              </div> -->
          </div>
            <!-- VOLUME CONTROL -->
            <div
              v-show="showControls"
              style="
                flex-grow: 1;
                margin-left: auto;
                max-width: 40%;
                min-width: 30%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                overflow: hidden;
              "
            >
              <div class="volume-container">
                <div
                  class="toggle-button"
                  :class="{ 'no-tooltip': isUserDraggingSeekSlider }"
                  v-show="UI_showTransportButtons"
                >
                <VolumeControl
                  v-model="videoPlayerVolumeSliderHTMLAdaptor"
                  :isDisabled="UI_disableTransportControls || !UI_showVolumeSlider"
                  @dragging-start="isUserDraggingVolumeSlider = true"
                  @dragging-end="onVolumeDragEnd"
                    
                />
                  <!-- v-model="videoPlayerVolumeSliderHTMLAdaptor"
                  style="width: 10px"
                  :append-icon="volumeIconHTMLAdaptor"
                  hide-details -->
                  <!-- <v-slider
                    :disabled="
                      UI_disableTransportControls || !UI_showVolumeSlider
                    "
                    class="whiteContoured"
                    @mousedown="isUserDraggingVolumeSlider = true"
                    @mouseUp="isUserDraggingVolumeSlider = false"
                    color="white"
                    v-model="videoPlayerVolumeSliderHTMLAdaptor"
                    style="width: 10px"
                    :append-icon="volumeIconHTMLAdaptor"
                    hide-details
                  >
                  </v-slider> -->
                </div>
              </div>
              <!-- <div class="tooltip-content top">Shared volume</div> -->
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ts-nocheck
import App from '@/App.vue'
import store from '@/store'

import ProgressSlider from '@/components/videoplayercomponents/progressSlider.vue'
import { ref, computed, onUnmounted, watch, inject } from 'vue';
import { mapGetters } from 'vuex'

import markerlane from '@/components/commentscomponents/markerlane.vue'
import InputMonitor from '@/components/dawstreamingcomponents/inputMonitor.vue'
import VolumeControl from '@/components/videoplayercomponents/volumeControl.vue'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import CloudClient from '@/../source_files/web_client/CloudClient'
import Constants from '@/../source_files/spotterfish_library/consts'
import { assert } from '@/../source_files/spotterfish_library/SpotterfishCore'
import CalcDeltaSecondsFromUIString from '@/../source_files/spotterfish_library/CalcDeltaSecondsFromUIString'
import MarkerUtils from '@/../source_files/spotterfish_library/utils/MarkerUtils'
import customMarkerSelector from '@/components/UI/customMarkerSelector'
const ClockValue = require('@/../source_files/spotterfish_library/ClockValue')
const MasterClock = require('@/../source_files/spotterfish_library/MasterClock')

const kQuickSeekButtonDurationSeconds = 5

function calcSeekRelativeFrames(key, frameRateHz) {
  if (key.srcKey) {
    if (key.srcKey === 'backFive') {
      return -parseInt(kQuickSeekButtonDurationSeconds * frameRateHz)
    }
    if (key.srcKey === 'fwdFive') {
      return parseInt(kQuickSeekButtonDurationSeconds * frameRateHz)
    }
    if (
      key.srcKey === 'backA' ||
      key.srcKey === 'backB' ||
      key.srcKey === 'backC'
    ) {
      return -1
    }
    if (
      key.srcKey === 'fwdA' ||
      key.srcKey === 'fwdB' ||
      key.srcKey === 'fwdC'
    ) {
      return 1
    }
  }
  return 0
}

export default {
  name: 'TransportComponent',
  components: {
    ProgressSlider,
    markerlane,
    customMarkerSelector,
    InputMonitor,
    VolumeControl
  },
  props: ['onHover', 'markers'],
  setup(props, { emit }) {
    // Injected data
    const nonReactiveState = inject('nonReactiveState');
    const templateReactiveData = inject('templateReactiveData');
    const clock = inject('clock');

    // Template refs
    const seekSliderRef = ref(undefined);
    const SMPTEInputRef = ref(undefined);
    // template data
    const playPositionSliderSeconds = ref(0);
    const isUserDraggingSeekSlider = ref(false);
    const isUserEnteringTcText = ref(false);
    const isUserDraggingVolumeSlider = ref(false);
    const showDropMarkerPanel = ref(false);
    const volume = ref(10);
    const trackHeight = ref(8);
    const kQuickSeekButtonDurationSeconds = 5;
    const htmlOutput_selectedMarker = ref(undefined);

    // this approach was suggested by chatGPT, instead of the separate interval
    // it seems it fires too often, so we need to debounce it if we want to use it.
    // const clockPosition = computed(()=> {
    //   return clock.position
    // })

    // const clockWatcher = watch(clockPosition, () => {
    //   if (
    //       isUserDraggingSeekSlider.value === false &&
    //       clock.interactiveTransport
    //     ) {
    //       playPositionSliderSeconds.value =
    //         clock.mediaSyncSource === 'daw_streamer'
    //           ? clock.position
    //           : clock.interactiveTransport.pos
    //     }      
    // }, { deep: true })

    onUnmounted(() => {
      // Stops watching
      // clockWatcher()
      clearInterval(interactiveTransportTimer)
    })

    const interactiveTransportTimer = ref(
      setInterval(() => {
        if (
          isUserDraggingSeekSlider.value === false &&
          clock.interactiveTransport
        ) {
          playPositionSliderSeconds.value =
            clock.mediaSyncSource === 'daw_streamer'
              ? clock.position
              : clock.interactiveTransport.pos
        }
      }, 1000 / 30)
    )


    return {
      nonReactiveState,
      templateReactiveData,
      clock,
      
      seekSliderRef,
      SMPTEInputRef,

      playPositionSliderSeconds,
      isUserDraggingSeekSlider,
      isUserEnteringTcText,
      isUserDraggingVolumeSlider,
      showDropMarkerPanel,
      volume,
      trackHeight,
      kQuickSeekButtonDurationSeconds,
      htmlOutput_selectedMarker
    };
  },
  computed: {

    iAmDAWstreaming() {
      return this.templateReactiveData?.ui.selectedStreamer === this.templateReactiveData?.userID
    },

    UI_disableTimelineInput () {
      return this.templateReactiveData?.UI_disableTimelineInput
    },
    UI_disableTransportControls () {
      return this.templateReactiveData?.UI_disableTransportControls
    },
    UI_showTransportButtons () {
      return this.templateReactiveData?.UI_showTransportButtons
    },
    UI_showVolumeSlider () {
      return this.templateReactiveData?.UI_showVolumeSlider
    },
    UI_showMarkerListButton () {
      return this.templateReactiveData?.UI_showMarkerListButton
    },
    UI_showDawStreamerSelector () {
      return this.templateReactiveData?.UI_showDawStreamerSelector
    },
    UI_usePTMarkerColors () {
      return this.templateReactiveData?.UI_usePTMarkerColors
    },
    isUserProjectModerator () {
      return this.templateReactiveData?.isUserProjectModerator
    },
    UI_clock() {
      return this.templateReactiveData?.UI_clock
    },

    screeningRoomDBCopy() {
      return this.templateReactiveData?.screeningRoomDBCopy
    },
    refinedSharedStateCopy() {
      return this.templateReactiveData?.refinedSharedState
    },
    markerLaneID() {
      return this.templateReactiveData?.projectDBCopy?.current_version_marker_lane
    },
    userID() {
      return this.templateReactiveData?.userID
    },
    projectName() {
      return this.templateReactiveData?.projectName
    },
    roomFeatureBits() {
      return this.templateReactiveData?.roomFeatureBits
    },
    freeScreeningRoom() {
      return this.templateReactiveData?.freeScreeningRoom
    },
    //  Make getters from store-getters.
    ...mapGetters([]),

    showControls() {
      return this.onHover || !this.templateReactiveData?.lightsOff
    },
    fixedCommentTypes() {
      return this.UI_usePTMarkerColors
        ? Constants.FIXED_COMMENT_TYPES_PT
        : Constants.FIXED_COMMENT_TYPES
    },
    keyMappings() {
      let mappings = {
        silentA: ['arrowdown'],
        stepPrevious: ['shift', 'arrowleft'],
        stepNext: ['shift', 'arrowright'],
        dropMarker: ['enter'],
        close: ['escape'],
      }
      this.fixedCommentTypes.forEach((type, index) => {
        mappings[type.name] = [`${index + 1}`]
      })
      return mappings
    },

    calcCurrentVideoLength() {
      return this.clock
        ? this.clock.videoFileLengthOpt
        : 0
    },

    output_smpte() {
      return this.calcVideoplayerSMPTE()
    },

    marker_smpte() {
      return Timecode.secondsToSMPTEString(
        this.clock.position,
        this.clock.videoFileFrameRateKey
      )
    },

    videoFileFrameRate() {
      if (this.clock) {
        return Timecode.getFrameRateNickname(
          this.clock.videoFileFrameRateKey
        )
      } else {
        return undefined
      }
    },
    videoFileStartSMPTE() {
      if (this.clock) {
        return Timecode.secondsToSMPTEString(
          this.clock.videoFileOffset,
          this.clock.videoFileFrameRateKey
        )
      } else {
        return undefined
      }
    },

    //  store.getters.getVideoPlayerVolume() range 0.0 - 1.0
    volumeIconHTMLAdaptor() {
      if (store.getters.getVideoPlayerVolume === 0.0) {
        return 'volume_mute'
      } else if (store.getters.getVideoPlayerVolume <= 0.5) {
        return 'volume_down'
      } else {
        return 'volume_up'
      }
    },
    // Performance enhacement 2024-02-26
    playPauseState() {
      // Default state when no project is loaded or no clock data is available
      if (!this.projectName || !this.clock) {
        return {
          icon: 'mdi-play', // No icon to display
          tooltip: "Play/pause is available when a project is loaded", // Default tooltip message
          action: () => {}, // No action associated
        };
      }
      
      // Determine if the play or pause icon should be shown based on the playFlag
      if (this.clock.playbackTransport.playFlag) {
        return {
          icon: 'mdi-pause',
          tooltip: this.playButtonTooltipMessage.pause, // Assuming playButtonTooltipMessage is reactive and provides tooltips
          action: this.pause, // Assuming pause is a method defined in your component
        };
      } else {
        return {
          icon: 'mdi-play',
          tooltip: this.playButtonTooltipMessage.play, // Adjust based on your tooltip logic
          action: this.play, // Assuming play is a method defined in your component
        };
      }
    },
    calcTransportStyle() {
      /*

        @media (max-width: 1450px) {  
          font-size: 1.8rem; 
        }
        @media (max-width: 1078px) {  
          font-size: 1.6rem; 
        }
        @media (max-width: 850px) {  
          font-size: 1.4rem; 
        }
      */
      const transportWidth = this.templateReactiveData?.UI_transportWindowSize.width
      const shrink1 = transportWidth < 1020 ? true : false
      const shrink2 = transportWidth < 900 ? true : false

      const hide = transportWidth < 760 ? true : false

      const dawstreamerSelect = {
        width: shrink2 ? '6em' : shrink1 ? '6em' : '10em',
      }

      if (hide) {
        dawstreamerSelect.marginLeft = '1em !important;'
      }

      const smpte = hide
        ? {
            fontSize: '1.75rem',
          }
        : ''

      return {
        transportWidth,
        hide,
        shrink1,
        shrink2,
        dawstreamerSelect,
        smpte,
      }
    },

    //  0 ... 100
    videoPlayerVolumeSliderHTMLAdaptor: {
      get() {
        return store.getters.getVideoPlayerVolume * 100
      },

      set(volume) {
        const newVolume = volume / 100
        if (this.isUserDraggingVolumeSlider) {
          console.log('posting volume to firebase')
          store
            .dispatch('firebase_post_shared_volume', {
              screeningRoomID: this.screeningRoomID,
              volume: newVolume,
              useruid: this.userID,
            })
            .then(() => {this.isUserDraggingVolumeSlider = false})
        }
      },
    },

    screeningRoomID() {
      if (this.screeningRoomDBCopy) {
        return this.screeningRoomDBCopy['.key']
      } else {
        return undefined
      }
    },


    screeningRoomName() {
      return this.screeningRoomDBCopy
        ? this.screeningRoomDBCopy.name
        : undefined
    },
    playButtonTooltipMessage() {
      const baseMessages = {
        playPause: 'Play/pause',
        play: 'Play',
        pause: 'Pause',
      };

      // Conditions affecting all tooltips
      if (!this.isProjectLoaded() && !this.isDawStreamerSelected()) {
        return Object.keys(baseMessages).reduce((acc, key) => {
          acc[key] = `${baseMessages[key]} is available when a project is loaded`;
          return acc;
        }, {});
      }

      const controlledByDAWStreamer = this.isDawStreamerSelected() ? 'Controlled by DAW streamer' : null;

      return Object.keys(baseMessages).reduce((acc, key) => {
        acc[key] = controlledByDAWStreamer || `${baseMessages[key]} [SPACE or K]`
        return acc;
      }, {})
    },
    getActiveDawStreamers() {
      if (!this.refinedSharedStateCopy) return []
      const staticAudio = {
        text: Constants.STATIC_AUDIO_USERNAME,
        value: Constants.STATIC_AUDIO_STRING,
      }
      const divider = { divider: true, text: '', value: null }
      const activeDawStreamers = Object.values(
        this.refinedSharedStateCopy.users
      )
        .filter(
          (user) => user.active && user.daw_stream_ready && user.daw_streamer
        )
        .map((user) => ({
          text: user.user_name,
          value: user.user_id,
        }))
      return [staticAudio, divider, ...activeDawStreamers]
    },

    getSelectedStreamer() {
      return this.refinedSharedStateCopy?.streaming_user
    },
},

methods: {

  onVolumeDragEnd(volume) {
    console.log('should update shared state with the new value', volume)
    this.videoPlayerVolumeSliderHTMLAdaptor = volume
  },
  toggleDawStreamerMute($event) {
    this.$emit('request-toggle-daw-streamer-mute', $event)
  },

  setSelectedMarker(marker) {
    this.htmlOutput_selectedMarker = marker
    console.log('selected marker is now', marker)
  },

  /*
    Marker Lane emits three different modes, depending on state
    {
      marker: marker,
      action: 'close', 'select'
    }
    markerSelector.selectMarker returns marker or undefined
  */
  onSelectMarker(event) {
    console.log('onSelectedMarker', event)
    try {
      this.setSelectedMarker(this.$refs.markerSelector.selectMarker(event))
    } catch (e) {
      console.log(e)
    }
  },

  onRequestMarkerSave(event) {
    console.log('onRequestMarkerSave', event)
    this.$emit('request-save-marker', event)
    this.setSelectedMarker(undefined)
  },

  stepThroughMarkers(e) {
    if (this.htmlOutput_selectedMarker) {
      const sortedArray = MarkerUtils.sortMarkers(this.markers)
      const thisMarkerIndex = MarkerUtils.getSelectedMarkerIndex(
        this.htmlOutput_selectedMarker,
        sortedArray
      )
      if (
        thisMarkerIndex === -1 ||
        (thisMarkerIndex === 0 && e === -1) ||
        (thisMarkerIndex === sortedArray.length - 1 && e === 1)
      ) {
        return
      }
      this.onSelectMarker({
        marker: sortedArray[thisMarkerIndex + e],
        action: 'silent',
      })
    }
  },

  onSeekSliderMove() {
    if (
      this.videoFileFrameRate === undefined ||
      this.videoFileStartSMPTE === undefined
    ) {
      return undefined
    } else {
      const pos = this.getInteractivePlayPosition()

      if (this.isUserDraggingSeekSlider && !this.isDawStreamerSelected()) {
        console.log(pos)
        this.$emit('requested-position-change', pos)
      }
    }
  },

  realtimeSMPTEHTMLAdaptor() {
    if (
      this.videoFileFrameRate === undefined ||
      this.videoFileStartSMPTE === undefined
    ) {
      return undefined
    } else {
      const pos = this.getInteractivePlayPosition()
      return this.calcSMPTEOutputFromSeconds(pos)
    }
  },

  onBeginInteraction() {
    MasterClock.beginInteraction(this.clock)
  },

  onEndInteraction() {
    MasterClock.endInteraction(this.clock)
  },

  onPositionSliderChange(pos) {
    MasterClock.endInteraction(this.clock, pos)
    console.log(pos)
  },

  calcVideoplayerSMPTE() {
    if (this.clock) {
      assert(ClockValue.checkClock(this.clock))
      return this.calcSMPTEOutputFromSeconds(this.clock.position)
    } else {
      return '01:00:00:00'
    }
  },

  onMouseLeaveWindow() {
    if (this.isUserDraggingSeekSlider) {
      this.$refs.seekSliderRef.blur()
    }
  },

  getInteractivePlayPosition() {
    return this.isUserDraggingSeekSlider
      ? this.playPositionSliderSeconds
      : this.clock.position
  },

  onSMPTEinputChanged(e) {
    const s = e.target.value
    const a = _.cloneDeep(this.clock)
    a.videoFileOffset = a.timecodeOffsetSeconds
    const pos = CalcDeltaSecondsFromUIString.calcDeltaSecondsFromUIString(
      a,
      this.calcCurrentVideoLength,
      s
    )
    if (pos !== undefined) {
      this.requestPositionSeconds(pos)
    }
  },

  getInteractiveTimecodeInputText() {
    return this.isUserEnteringTcText
      ? this.$refs.SMPTEInputRef.value
      : this.calcVideoplayerSMPTE()
  },

  // uses timecodeOffsetSeconds from clock to display SMPTE
  calcSMPTEOutputFromSeconds(posSeconds) {
    assert(posSeconds >= 0)

    return Timecode.secondsToSMPTEString(
      posSeconds + this.clock.timecodeOffsetSeconds,
      this.clock.videoFileFrameRateKey
    )
  },

  isDawStreamerSelected() {
    return this.clock.mediaSyncSource === 'daw_streamer'
  },
  isProjectLoaded() {
    return this.projectName !== undefined
  },
  getSeekButtonTooltipMessage(keyString, keyboardShortCutString) {
    if (!this.isProjectLoaded() && !this.isDawStreamerSelected()) {
      return `${keyString} is available when a project is loaded`
    }
    return this.isDawStreamerSelected()
      ? 'Controlled by DAW streamer'
      : `${keyString} ${this.kQuickSeekButtonDurationSeconds_} seconds ${keyboardShortCutString}`
  },

  async switchSelectedStreamer(userId) {
    this.$refs.streamerSelect.blur()
    // ??? todo pop up spinner ???
    try {
      await CloudClient.call_CFselectDAWStreamerForRoom(
        App.spotterfishSession.firebase,
        userId,
        this.screeningRoomID
      )
    } catch (error) {
      console.error(error)
      debugger
    }

    // ??? todo close spinner ???
    // console.log('waiting')
    // await new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     console.log('resolving')
    //     resolve()
    //   }, 3000)
    // })
  },

  selectText() {
    this.$refs.SMPTEInputRef.select()
  },
  blur() {
    this.$refs.SMPTEInputRef.focus()
    this.$refs.SMPTEInputRef.blur()
  },
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::PLAYER CONTROLS:::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  play() {
    if (this.UI_disableTransportControls) {
      return
    }
    this.$emit('requested-play-state', true)
  },

  pause() {
    if (this.UI_disableTransportControls) {
      return
    }
    this.$emit('requested-play-state', false)
  },

  //??? Deduplicate with onStepSeekKey()
  // but needs to accumulate all seeks ???`
  onStepSeekFrames(val) {
    if (!this.UI_disableTransportControls) {
      if (val !== 0) {
        const posSeconds2 = Timecode.offsetFramesInt(
          this.clock.position,
          val,
          this.clock.videoFileFrameRateKey
        )
        this.requestPositionSeconds(posSeconds2)
      }
    }
  },

  onStepSeekKey(key) {
    if (!this.UI_disableTransportControls) {
      const relativeFrames = calcSeekRelativeFrames(
        key,
        this.videoFileFrameRate
      )
      if (relativeFrames !== 0) {
        const posSeconds2 = Timecode.offsetFramesInt(
          this.clock.position,
          relativeFrames,
          this.clock.videoFileFrameRateKey
        )
        this.requestPositionSeconds(posSeconds2)
      }
    }
  },

  requestPositionSeconds(seconds) {
    if (this.UI_disableTransportControls) {
      return
    }
    this.$emit('requested-position-change', seconds)
  },

  async handleShortKeys(e) {
    // if enter is triggered by child there is no clear way to prevent, using a member var and emit for now.
    if (
      this.childRequestedNoPropagationOfEnterKey &&
      e.srcKey === 'dropMarker'
    ) {
      this.childRequestedNoPropagationOfEnterKey = undefined
    } else {
      this.showDropMarkerPanel = true
      this.$refs.markerSelector.onShortkey(e)
    }
  },

  toggleLiveOn() {
    this.liveOn = !this.liveOn
  },

  }

};

</script>

<style lang="scss" scoped>
@import '@/assets/global.scss';

.admin-controls {
  overflow: hidden;
  flex-wrap: nowrap;
  padding: 10px 20px 0 20px;
  min-height: 64px;

  .col {
    display: flex;
    padding: 0;
  }

  .toggle-button button {
    font-size: 3.75rem !important;
  }

  .seek-button {
    margin: 0 12px;
  }

  .marker-selector-container {
    display: flex;
    justify-content: center;

    align-items: center;
    padding: 0;
    flex-shrink: 0;
    overflow: hidden;

    .marker-selector {
      margin: auto;
    }
  }
  .smpte-container {
    display: flex;
    justify-content: flex-strart;
    align-items: center;
    padding: 3px 0 6px 0 !important;
    width: 80%;
  }

  .smpte {
    background: black;
    border-radius: 3px;
    font-size: 2.2rem;

    input {
      text-align: center;
      line-height: 1;
      color: white;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.8rem;
      font-weight: bold;
      margin: 0;
    }

    &.disabled {
      input {
        color: #808080;
      }
    }
  }
}

.transportSliderContainer {
  position: relative;
}
.daw-streamer-select {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 1.6rem !important;
  text-align: left;
}

.volume-container {
  width: 50%;
  padding-top: 6px;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: none;
}

</style>
