/* eslint-disable no-unused-vars */

const _ = require('lodash')
const SpotterfishCore = require('./SpotterfishCore')
const UserFeatureBitsModule = require('./userFeatureBits.js')
const TableFormatting = require('./tableFormatting.js')

// unitTestAsserts are left on in production - normal asserts we remove by the build process 
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isValidISODateString = SpotterfishCore.isValidISODateString
const isArrayInstance = SpotterfishCore.isArrayInstance
const isObjectInstance = SpotterfishCore.isObjectInstance
const isBooleanInstance = SpotterfishCore.isBooleanInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isFunctionInstance = SpotterfishCore.isFunctionInstance


/*
  IDEA: Record more user-actions into journal: start sessions, invite user etc? A: NO, journal is for purchases, invoicing, subscriptions, products, trials.
  TODO: auto-expiry dates on commands

  Record purchase-details? Yes.
*/

/*
ERA 1 2016-01-01 — 2021-04-14
ERA 2 2021-04-14 — NOW: FREEMIUM

ERA 3 2021-12-xxx, asking existing users to subscribe.
*/

const era1ExampleDate1 = new Date('2021-04-14')
const era1ExampleDate2 = new Date('2021-04-15')
const era2StartDate = new Date('2021-04-16')
const era2ExampleDate1 = new Date('2021-04-17')
const era2ExampleDate2 = new Date('2021-04-18')

//  product_db_creation_date: era1ExampleDate1.toJSON(),
//  new Date().toISOString(),


//  Command list, era 2. 'note' is an optional field in every command

const exampleCommand_optin_email1 = { date: '2021-04-30T15:08:42.592Z', optin_email: true, note: '' }

const exampleCommand_optin_email2 = { date: '2021-04-30T15:08:42.592Z', optin_email: false, note: '' }

//  Alias for "unlock_tier2" using a promo_code
const exampleCommand_promo_code = { date: '2021-04-30T15:08:42.802Z', promo_code: 'Tyrell2021', note: '' }

const exampleCommand_mig2journal = { date: '2021-04-29T20:46:17.087Z', cmd: 'mig2journal', params: { migration_ufb: {}, account_creation_date: '2021-04-30T15:08:42.802Z' }, note: '' }

const exampleCommand_unlock_tier1 = { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier1', note: '' }
const exampleCommand_unlock_tier2_promo_code = { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { promo_code: {} }, note: '' }
const exampleCommand_unlock_tier2_payment_token = { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { payment_token: 'chargebee1234' }, note: '' }

//  Overwrites UFB setting on a per-flag / number basis. Cannot change the topology of the rooms, just their contents.
//  Every property include in ufb_fields will be copied to destination, all other properties will be left as-is.
const exampleCommand_modify_rooms = { date: '2021-04-29T20:46:17.087Z', cmd: 'modify_rooms', params: { modifier: {} }, note: '' }

const exampleCommand_delete_room = { date: '2021-04-29T20:46:17.087Z', cmd: 'delete_room', params: { room_id: '1234' }, note: '' }

const exampleCommand_erase_journal_entry = { date: '2021-04-29T20:46:17.087Z', cmd: 'erase_journal_entry', params: { entry_index: 4 }, note: '' }


/*
{
  "date": "2021-09-22T08:08:16.361Z",
  "cmd": "mig2journal",
  "params": {
    "migration_ufb": {
      "rooms": {
        "KTaly6JT1OcflYwaA4MZ": {
          "seats": 20,
          "requires_email_verification": false,
          "requires_two_factor_auth": false,
          "requires_room_pin": false,
          "domain_whitelist_enabled": false,
          "video_chat_enabled": true,
          "show_free_version_banner": false,
          "custom_background_color": false,
          "custom_logo": false
        },
        "ikEy9YJ4eTiKFytoEvAf": {
          "seats": 2,
          "requires_email_verification": false,
          "requires_two_factor_auth": false,
          "requires_room_pin": false,
          "domain_whitelist_enabled": false,
          "video_chat_enabled": true,
          "show_free_version_banner": false,
          "custom_background_color": false,
          "custom_logo": false
        },
        "nWWsqDUCNCDEyoYdON3w": {
          "seats": 10,
          "requires_email_verification": false,
          "requires_two_factor_auth": false,
          "requires_room_pin": false,
          "domain_whitelist_enabled": false,
          "video_chat_enabled": true,
          "show_free_version_banner": false,
          "custom_background_color": false,
          "custom_logo": false
        }
      }
    },
    "account_creation_date": "2020-08-06T17:25:14.000Z"
  }
}
*/

/*
{"date":"2021-05-13T21:24:22.814Z","promo_code":"$manualUpdateMay2021"}
{
  "date": "2021-09-22T07:35:56.049Z",
  "cmd": "mig2journal",
  "params": {
    "migration_ufb": {
      "rooms": {
        "azdQqRapFyFh00qBy8Pl": {
          "seats": 5,
          "requires_email_verification": false,
          "requires_two_factor_auth": false,
          "requires_room_pin": false,
          "domain_whitelist_enabled": false,
          "video_chat_enabled": true,
          "show_free_version_banner": false,
          "custom_background_color": true,
          "custom_logo": true
        }
      }
    },
    "account_creation_date": "2020-06-30T22:16:33.000Z"
  }
}
*/


//  Contains all commands, verify we can interpret them.
const exampleJournalAllCommands = [
  //  1571
  { date: '2021-04-29T20:46:17.087Z', 'optin_email': true },

  //  1317
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },

  //  2530
  {
    date: '2021-04-29T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      migration_ufb: { rooms: { 'roomid123': { seat: 5 } } },
      account_creation_date: '2021-03-30T15:08:42.802Z'
    }
  },

  //  53
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier1', note: 'cta-button' },

  //  1
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { promo_code: 'Tyrell2021' } },
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { payment_token: 'chargebee1234' } },

  //  6
  { date: '2021-04-29T20:46:17.087Z', cmd: 'modify_rooms', params: { modifier: {} } },

  //  2
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_custom', params: { ufb: {} } }

/*
  ALSO:
    //  252
    'unlock_era3_tier1'

    //  29
    'unlock_era3_tier2'
*/
]



const example_migrated1 = [
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },
  {
    date: '2021-04-31T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 5,
            "video_chat_enabled": true,
            "custom_background_color": true,
            "custom_logo": true
          }
        }
      },
      account_creation_date: '2021-03-30T20:46:17.087Z'
    }
  }
]

const example_migrated2 = [
  { date: '2021-04-29T20:46:17.087Z', 'optin_email': true },
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },
  {
    date: '2021-04-29T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 5,
            "video_chat_enabled": true,
            "custom_background_color": true,
            "custom_logo": true
          }
        }
      },
      account_creation_date: '2021-03-30T15:08:42.802Z'
    }
  },

  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier1', note: 'cta' },
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { payment_token: 'chargebee1234' } },
  { date: '2021-04-29T20:46:17.087Z', cmd: 'modify_rooms', params: { modifier: { rooms: { 1: {}, 2: {} } } } }
]




/** @type {Array<any>} */
const example_era2tier1Account = [
  {
    date: '2021-04-31T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 5,
            "video_chat_enabled": true,
            "show_free_version_banner": true
          }
        }
      },
      account_creation_date: '2021-03-30T20:46:17.087Z'
    }
  },
  { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier1', note: 'cta' }
]

/** @type {Array<any>} */
const example_era2tier2Account_Promocode = [
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },
  {
    date: '2021-04-31T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 5,
            "video_chat_enabled": true,
            "custom_background_color": true,
            "custom_logo": true
          }
        }
      },
      account_creation_date: '2021-03-30T20:46:17.087Z'
    }
  }
]

const example_era4tier1Account = [
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },
  {
    date: '2021-04-31T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 2,
            "video_chat_enabled": true,
            "custom_background_color": false,
            "custom_logo": false
          }
        }
      },
      account_creation_date: '2021-03-30T20:46:17.087Z'
    }
  },
  {'date':'2022-04-01T11:19:04.230Z','cmd':'unlock_era4_tier1'}

]

const example_era4tier2Account = [
  { date: '2021-04-29T20:46:17.087Z', 'promo_code': 'rat-poniard-octane-sheer' },
  {
    date: '2021-04-31T20:46:17.087Z',
    cmd: 'mig2journal',
    params: {
      "migration_ufb": {
        "rooms": {
          "azdQqRapFyFh00qBy8Pl": {
            "seats": 20,
            "video_chat_enabled": true,
            "custom_background_color": true,
            "custom_logo": true
          }
        }
      },
      account_creation_date: '2021-03-30T20:46:17.087Z'
    }
  },
  {'date':'2022-04-01T11:19:04.230Z','cmd':'unlock_era4_tier2'}
]

const journalHeaders = [ 'DATE', 'COMMAND', 'PARAMS', 'NOTE' ]
const outputHeader = [ 'UNLOCKS', 'OPTIN', 'MIGRATED', 'UFB', 'NEXT_RID' ]
const journalHeadersWithOutput = journalHeaders.concat(outputHeader)
const journalHeadersWithUFB = journalHeaders.concat([ 'UFB' ])


function consoleLogLinesStringLiterals (lines) {
  assert(isArrayInstance(lines))

  for (let i = 0; i < lines.length; i++) {
    console.log('\t\'' + lines[i] + '\',')
  }
}


function prove_javaScript() {
  const a = new Set()
  console.log(a)
  console.log(a.toString())


  const b = new Set([ 'x', 'y'])

  console.log(b)
  console.log(b.toString())
  console.log(Array.from(b).join(' '))

//  assert(false)
}

function sanitizeUFB(untrustedUFB){
  assert(_.isObject(untrustedUFB))

  const r = UserFeatureBitsModule.createUFB2(
    {
      user: untrustedUFB.user === undefined ? {} : _.cloneDeep(untrustedUFB.user),
      rooms: untrustedUFB.rooms === undefined ? {} : _.cloneDeep(untrustedUFB.rooms)
    }
  )
  assert(UserFeatureBitsModule.checkInvariantUFB(r))
  return r
}

// Used to pick a single existing room to upgrade etc -- NOT used for generating new journal room ID:s.
function findLowestUsedRoomID (ufb) {
  assert(UserFeatureBitsModule.checkInvariantUFB(ufb))

  const rooms = UserFeatureBitsModule.getUFBRooms(ufb)
  assert(Object.keys(rooms).length > 0)

  const keys = Object.keys(rooms)
  const a = keys.sort()
  return a[0]
}

function prove_findLowestUsedRoomID () {
  // TODO: The comparison was changed from e.g. == 10 to === 10 which uncovered that
  // keys are always expected to be strings and the comparison needs to be === '10',
  // is this correct?
  unitTestAssert(findLowestUsedRoomID({ user: {}, rooms: { 10: { seats: 5 }, 11: { seats: 5 } } }) === '10')
  unitTestAssert(findLowestUsedRoomID({ user: {}, rooms: { 20: { seats: 5 }, 11: { seats: 5 } } }) === '11')
  unitTestAssert(findLowestUsedRoomID({ user: {}, rooms: { 1: { seats: 5 } } }) === '1')
}

function checkJournal (journal) {
  assert(isArrayInstance(journal))

  journal.forEach(function (entry) {
    assert(('date' in entry) === true)

    if (('optin_email' in entry) === true) {
      assert(Object.keys(entry).length === 2)
      assert(isBooleanInstance(entry['optin_email']))
    } else if (('promo_code' in entry) === true) {
      assert(Object.keys(entry).length === 2)
      const promo_code = entry['promo_code']
      assert(isStringInstance(promo_code) && promo_code.length > 0)
    } else {
      // assert(false)
    }
  })

  return true
}


function prove_exampleJournalAllCommands () {
  unitTestAssert(checkJournal(exampleJournalAllCommands))
}

function prove_example_migrated1 () {
  unitTestAssert(checkJournal(example_migrated1))
}

function prove_example_migrated2 () {
  unitTestAssert(checkJournal(example_migrated2))
}

function prove_example_era2tier1Account () {
  unitTestAssert(checkJournal(example_era2tier1Account))
}

function prove_example_era2tier2Account_Promocode () {
  unitTestAssert(checkJournal(example_era2tier2Account_Promocode))
}


//  Returns array of lines, where each line is an array of strings.
function journalToTable (journal, maxParamsLength) {
  assert(checkJournal(journal))
  assert(SpotterfishCore.isNumberInstance(maxParamsLength) && maxParamsLength > 0)

  const result = []
  traverseJournal(
    journal,
    function (journal, i, e) {
      var command = ''
      var params = ''

      if (e.cmd !== undefined) {
        command = e.cmd
        if (e.params !== undefined) {
          const t = JSON.stringify(e.params)
          const t2 = t.length <= maxParamsLength ? t : (t.substring(0, maxParamsLength - 3) + '...')
          params = t2
        }
      } else {
        if (e.promo_code !== undefined) {
          command = 'promo_code'
          params = e.promo_code.toString()
        } else if (e.optin_email !== undefined) {
          command = 'optin_email'
          params = e.optin_email.toString()
        } else {
          assert(false)
        }
      }

      const note = e.note !== undefined ? e.note : ''

      result.push([e.date, command, params, note])
    }
  )
  assert(isArrayInstance(result))
  return result
}

function prove_journalToTable_example_migrated1 () {
  const a = journalToTable(example_migrated1, 80)
  const b = [journalHeaders].concat(a)
  const c = TableFormatting.tableToStrings(b, ' | ')
  SpotterfishCore.consoleLogLines(c)
}

function prove_journalToTable_exampleJournalAllCommands () {
  const a = journalToTable(exampleJournalAllCommands, 80)
  const b = [journalHeaders].concat(a)
  const c = TableFormatting.tableToStrings(b, ' | ')
  SpotterfishCore.consoleLogLines(c)
}


function traverseJournal (journal, f) {
  assert(checkJournal(journal))
  assert(isFunctionInstance(f))

  var result = []
  for (var i = 0; i < journal.length; i++) {
    f(journal, i, journal[i])
  }
  return result
}

function prove_traverseJournal_example_migrated1 () {
  var result = []
  traverseJournal(example_migrated1, function (journal, i, e) { result.push(e) })
  unitTestAssert(_.isEqual(result, example_migrated1))
}





// 2021-12-09 -- found in DB
const k_era2_tier2_upgrade_promo_codes = new Set([
  "$manualUpdateMay2021",
  "RSPE2021",
  "rat-poniard-octane-sheer",
  "postPerspective21",
  "ProductionExpert2021",
  "Spotterfish2021",
  "unknown",
  "Tyrell2021",
  "ASG2021"
])


function calc_promo_code(e) {
  if(isStringInstance(e.promo_code)){
    return e.promo_code
  }
  else if(isStringInstance(e.promo_code_used)){
    return e.promo_code
  }
  else {
    return ''
  }
}

function render__promo_code (acc, e) {
  assert(e.cmd === undefined)
  assert(e.promo_code !== undefined || e.promo_code_used !== undefined)

  const promo_code = calc_promo_code(e)
  assert(isStringInstance(promo_code))

  // TODO: Check exact promocode.
  // 55237:       "promo_code_used": "Tyrell2021",

  // if(/*k_era2_tier2_upgrade_promo_codes.has(code)*/true))
  return render__unlockEra2Tier2(acc)
}


//  Handle early types of journal entires that had no cmd-key.
function render__early_entry (acc, e) {
  assert(e.cmd === undefined)

  if (e.optin_email !== undefined) {
    let acc2 = _.cloneDeep(acc)
    acc2.optinEmail = e.optin_email === true ? "yes" : "no"
    return acc2
  }

  // TODO: Check exact promocode.
  // 55237:       "promo_code_used": "Tyrell2021",
  else if (e.promo_code || e.promo_code_used) {
    const code = e.promo_code + e.promo_code_used
    // if(/*k_era2_tier2_upgrade_promo_codes.has(code)*/true)){
    return render__unlockEra2Tier2(acc)
  }
  else {
    throw new Error('Journal invalid')
  }
}





//  Synthesize unlocks for old accounts.
//  Only works if there is a single room
//  NOTICE: The room ID:s will be different -- migration UFB uses real DB ID:s, the journal uses journal ID:s.
function detectUnlocksFromMigUFB(rooms) {
  assert(rooms !== undefined)

  const roomCount = Object.keys(rooms).length

  if(roomCount === 0){
    return new Set(['!blank'])
  }

  //  Detect common 1-room accounts.
  else if(roomCount === 1){
    const migrationRoomValue = rooms[Object.keys(rooms)[0]]

    //  Era 1 5 seat
    {
      const k = { 'seats': 5, 'video_chat_enabled': true }
      if(UserFeatureBitsModule.compareRoomFeatures(migrationRoomValue, k)){
        return new Set(['!era1_5seat'])
      }
    }

    //  Era 1 10 seat
    {
      const k = { 'seats': 10, 'video_chat_enabled': true }
      if(UserFeatureBitsModule.compareRoomFeatures(migrationRoomValue, k)){
        return new Set(['!era1_10seat'])
      }
    }


    //  e2t1
    {
      const k = { 'seats': 5, 'video_chat_enabled': true, 'show_free_version_banner': true }
      if(UserFeatureBitsModule.compareRoomFeatures(migrationRoomValue, k)){
        return new Set(['!era2_tier1'])
      }
    }

    //  e2t2
    {
      const k = { 'seats': 5, 'video_chat_enabled': true, 'custom_background_color': true, 'custom_logo': true }
      if(UserFeatureBitsModule.compareRoomFeatures(migrationRoomValue, k)){
        return new Set(['!era2_tier2'])
      }
    }
  }

  return new Set(['!custom'])
}

// NOTE: that this migration UFB uses room keys that are DATABASE IDs, we need to allocate journal room IDs.
function render__mig2journal (value, e) {
  assert(e.cmd === 'mig2journal')


  //migration_ufb: This is not a proper UFB, it's missing "user" etc. rooms is optional.
  const migrationUFB = sanitizeUFB(
    {
      user: {},
      rooms: e.params.migration_ufb.rooms
    }
  )
  //assert(UserFeatureBitsModule.checkInvariantUFB(e.params.migration_ufb))


  // console.log('render__mig2journal')

  const detectedUnlocks = detectUnlocksFromMigUFB(migrationUFB.rooms)

  // console.log("detected unlocks: ")
  // console.dir(detectedUnlocks, { depth: null })

  if (_.isEqual(detectedUnlocks, new Set(['!era2_tier1']))) {
    let value2 = render__unlockEra2Tier1(value)
    value2.migrated = true
    return value2
  }
  else if(_.isEqual(detectedUnlocks, new Set(['!era2_tier2']))) {
    let value2 = render__unlockEra2Tier2(value)
    value2.migrated = true
    return value2
  }

  else if(_.isEqual(detectedUnlocks, new Set(['!era1_5seat']))) {
    let value2 = render__unlockEra1_5Seat(value)
    value2.migrated = true
    return value2
  }
  else if(_.isEqual(detectedUnlocks, new Set(['!era1_10seat']))) {
    let value2 = render__unlockEra1_10Seat(value)
    value2.migrated = true
    return value2
  }

  else if(
    _.isEqual(detectedUnlocks, new Set(['!blank'])) || _.isEqual(detectedUnlocks, new Set(['!custom']))
  ) {

    // NOTICE: We have a custom UFB and need to copy it into acc.

    // NOTICE: This resets the room ID:s, in case an old-style promocode has already created a room.
    var nextFreeRoomIDAcc = 1

    // Mapping from database room ID -> journal room ID
    var mappingAcc = {}

    //  New dict of rooms, keyed on journal ID:s
    var roomsAcc = {}

    for (const [databaseRoomId, databaseRoom] of Object.entries(migrationUFB.rooms)) {
      const journalRoomId = nextFreeRoomIDAcc

      // Copy the room data for this room database ID to a new journal room ID key in a
      // temporary object, which will replace the rooms object after the loop is done.
      roomsAcc[journalRoomId] = databaseRoom

      // Map the room database ID to the journal room ID.
      mappingAcc[databaseRoomId] = journalRoomId

      nextFreeRoomIDAcc++
    }

    var value2 = _.cloneDeep(value)
    value2.ufb = UserFeatureBitsModule.createUFB2({ user: {}, rooms: roomsAcc })
    value2.migrated = true
    value2.DBIDMapping = mappingAcc
    value2.nextFreeRoomID = nextFreeRoomIDAcc

    // console.dir(value, { depth: null })
    // console.dir(value2, { depth: null })

    value2.unlocks = new Set([...value2.unlocks, ...detectedUnlocks])

    return value2
  }
  else {
    assert(false)
    return value
  }
}

// 2021-08-12 MZ: We have problems that renderJournalOutput() mutates the legacy UFB directly => breaks contract & causes side effects => Regression test 1
function prove_render__mig2journal_RegressionTest1 () {
  console.log('prove_render__mig2journal_RegressionTest1()')

  const ufb0 = UserFeatureBitsModule.make2RoomsExample()
  const ufb1 = UserFeatureBitsModule.makeBlankUFB()

  const value = {
    ufb: ufb1,
    optinEmail: "unknown",
    unlocks: new Set(),
    migrated: false,
    nextFreeRoomID: 1
  }

  const e = {
    date: '2021-04-30T15:08:42.592Z',
    cmd: 'mig2journal',
    params: {
      migration_ufb: ufb0,
      account_creation_date: '2021-04-30T15:08:42.592Z'
    }
  }

  // console.log(ufb0)
  // console.log(ufb0.rooms)

  unitTestAssert(_.isEqual(Object.keys(ufb0.rooms), [ "999", "1000" ]) === true)
  unitTestAssert(Object.keys(ufb1.rooms).length === 0)

  unitTestAssert(Object.keys(value.ufb.rooms).length === 0)
  unitTestAssert(_.isEqual(Object.keys(e.params.migration_ufb.rooms), [ "999", "1000" ]) === true)
  unitTestAssert(value.nextFreeRoomID === 1)


  const value2 = render__mig2journal(value, e)


  console.log(value)
  console.log(value2)

  // Make sure render__mig2journal() didn't change our UFB
  unitTestAssert(_.isEqual(Object.keys(ufb0.rooms), [ "999", "1000" ]) === true)
  unitTestAssert(Object.keys(ufb1.rooms).length === 0)

  unitTestAssert(Object.keys(value.ufb.rooms).length === 0)
  unitTestAssert(_.isEqual(Object.keys(e.params.migration_ufb.rooms), [ "999", "1000" ]) === true)
  unitTestAssert(_.isEqual(Object.keys(value2.ufb.rooms), [ "1", "2" ]) === true)

  unitTestAssert(value.nextFreeRoomID === 1)
  unitTestAssert(value2.nextFreeRoomID === 3)
}


//  add a room if there is none. Else it will upgrade the room with lowest ID.
//  Room #1 from ufbRoom0 will be used
function render__assignRoom0 (acc, ufb) {
  var acc2 = _.cloneDeep(acc)

  if (Object.keys(UserFeatureBitsModule.getUFBRooms(acc2.ufb)).length === 0) {
    const roomId = acc2.nextFreeRoomID++
    acc2.ufb = UserFeatureBitsModule.createUFB2({ user: ufb.user, rooms: { [roomId]: ufb.rooms[1] } })
  }
  else {
    const roomId = findLowestUsedRoomID(acc2.ufb)
    acc2.ufb.rooms[roomId] = ufb.rooms[1]
    acc2.ufb.user = ufb.user
  }
  
  return acc2
}



// --------------------------------   ERA 1


function makeEra1_5Seat (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 5, 'video_chat_enabled': true }
  }
  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}

function render__unlockEra1_5Seat (acc) {
  let acc2 = render__assignRoom0(acc, makeEra1_5Seat(1))
  acc2.unlocks = new Set(['!era1_5seat'])
  return acc2
}




function makeEra1_10Seat (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 10, 'video_chat_enabled': true }
  }
  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}

function render__unlockEra1_10Seat (acc) {
  let acc2 = render__assignRoom0(acc, makeEra1_10Seat(1))
  acc2.unlocks = new Set(['!era1_10seat'])
  return acc2
}


// --------------------------------   ERA 2


//  Returns array of journal entires to append to journal
function unlockEra2Tier1 (currentDate, note) {
  assert(isValidISODateString(currentDate))
  assert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_tier1', undefined, note)
}

function prove_unlockEra2Tier1_test1 () {
  const a = unlockEra2Tier1('2021-04-29T20:46:17.087Z', 'M was here')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier1', note: 'M was here' } ]
    )
  )
}

//  Returns array of journal entires to append to journal
function unlockEra2Tier2 (currentDate, promoCode, note) {
  assert(isValidISODateString(currentDate))
  assert(promoCode === undefined || (isStringInstance(promoCode) && promoCode.length > 0))
  assert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_tier2', promoCode, note)
}

function prove_unlockEra2Tier2_test1 () {
  const a = unlockEra2Tier2('2021-04-29T20:46:17.087Z', 'Marcus2021', 'M was here')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_tier2', params: { promo_code: 'Marcus2021' }, note: 'M was here' } ]
    )
  )
}

function makeEra2Tier1UFB (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 2, 'video_chat_enabled': true, 'show_free_version_banner': true }
  }

  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}


function makeEra2Tier2UFB (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 2, 'video_chat_enabled': true, 'show_free_version_banner': true }
  }
  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}


//  Will add a room if there is none. Else it will upgrade the room with lowest ID.
function render__unlockEra2Tier1 (acc, e) {
  let acc2 = render__assignRoom0(acc, makeEra2Tier1UFB(1))
  acc2.unlocks = new Set(['!era2_tier1'])
  return acc2
}

//  Will add a room if there is none. Else it will upgrade the room with lowest ID.
function render__unlockEra2Tier2 (acc) {
  let acc2 = render__assignRoom0(acc, makeEra2Tier2UFB(1))
  acc2.unlocks = new Set(['!era2_tier2'])
  return acc2
}




// --------------------------------   ERA 3


function makeEra3Tier1UFB (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 2, 'video_chat_enabled': true, 'show_free_version_banner': true }
  }

  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}


function makeEra3Tier2UFB (roomKey) {
  assert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 5, 'video_chat_enabled': true, 'custom_background_color': true, 'custom_logo': true }
  }
  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}

//  Returns array of journal entires to append to journal
function unlockEra3Tier1 (currentDate, note) {
  assert(isValidISODateString(currentDate))
  assert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_era3_tier1', undefined, note)
}

function prove_unlockEra3Tier1_test1 () {
  const a = unlockEra3Tier1('2021-04-29T20:46:17.087Z', 'My notes')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_era3_tier1', note: 'My notes' } ]
    )
  )
}

//  Returns array of journal entires to append to journal
function unlockEra3Tier2 (currentDate, promoCode, note) {
  assert(isValidISODateString(currentDate))
  assert(promoCode === undefined || (isStringInstance(promoCode) && promoCode.length > 0))
  assert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_era3_tier2', promoCode, note)
}

function prove_unlockEra3Tier2_test1 () {
  const a = unlockEra3Tier2('2021-04-29T20:46:17.087Z', 'Marcus2021', 'Test notes')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_era3_tier2', params: { promo_code: 'Marcus2021' }, note: 'Test notes' } ]
    )
  )
}


// --------------------------- Era4 MixStage


function makeEra4Tier1UFB (roomKey) {
  unitTestAssert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 2, 'video_chat_enabled': true, 'show_free_version_banner': false }
  }

  return UserFeatureBitsModule.createUFB2({ user: {}, rooms: rooms })
}


function makeEra4Tier2UFB (roomKey) {
  unitTestAssert(SpotterfishCore.isNumberInstance(roomKey) && roomKey > 0)

  const rooms = {
    [roomKey]: { 'seats': 20, 'video_chat_enabled': true, 'custom_background_color': false, 'custom_logo': true }
  }
  return UserFeatureBitsModule.createUFB2({ user: { daw_streaming: true }, rooms: rooms })
}

function prove_makeEra4Tier2UFB () {
  const a = makeEra4Tier2UFB(1)
  
  unitTestAssert(
    _.isEqual(
      a,
      {
        user: { daw_streaming: true },
        rooms: {
          '1': {
            seats: 20,
            video_chat_enabled: true,
            custom_background_color: false,
            custom_logo: true
          }
        }
      }
    )
  )
}

//  Returns array of journal entires to append to journal
function unlockEra4Tier1 (currentDate, note) {
  unitTestAssert(isValidISODateString(currentDate))
  unitTestAssert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_era4_tier1', undefined, note)
}

function prove_unlockEra4Tier1_test1 () {
  const a = unlockEra4Tier1('2021-04-29T20:46:17.087Z', 'My notes')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_era4_tier1', note: 'My notes' } ]
    )
  )
}

//  Returns array of journal entires to append to journal
function unlockEra4Tier2 (currentDate, promoCode, note) {
  unitTestAssert(isValidISODateString(currentDate))
  unitTestAssert(promoCode === undefined || (isStringInstance(promoCode) && promoCode.length > 0))
  unitTestAssert(note === undefined || isStringInstance(note))
  return storeUnlock(currentDate, 'unlock_era4_tier2', promoCode, note)
}

function prove_unlockEra4Tier2_test1 () {
  const a = unlockEra4Tier2('2021-04-29T20:46:17.087Z', 'Marcus2021', 'Test notes')
  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_era4_tier2', params: { promo_code: 'Marcus2021' }, note: 'Test notes' } ]
    )
  )
}



// --------------------------------   Per-user DAW stream



function render__unlockUserDAWStreaming (acc, e) {
  assert(e.cmd === 'unlock_user_dawstreaming')

  var acc2 = _.cloneDeep(acc)
  acc2.ufb.user.daw_streaming = true
  acc2.unlocks.add('!user_dawstreaming')
  return acc2
}


//  Returns array of journal entires to append to journal
function unlockUserDAWStreaming (currentDate, promoCode, note) {
  assert(isValidISODateString(currentDate))
  assert(promoCode === undefined || (isStringInstance(promoCode) && promoCode.length > 0))
  assert(note === undefined || isStringInstance(note))

  return storeUnlock(currentDate, 'unlock_user_dawstreaming', promoCode, note)
}

function prove_unlockUserDAWStreaming () {
  const a = unlockUserDAWStreaming('2021-04-29T20:46:17.087Z', 'Marcus2021', 'Test notes')

  unitTestAssert(
    _.isEqual(
      a,
      [ { date: '2021-04-29T20:46:17.087Z', cmd: 'unlock_user_dawstreaming', params: { promo_code: 'Marcus2021' }, note: 'Test notes' } ]
    )
  )
}


//  TODO: Right now era2 and era3 users gets the same features
function render__unlockEra3Tier1 (acc, e) {
  assert(e.cmd === 'unlock_era3_tier1')

  let acc2 = render__assignRoom0(acc, makeEra3Tier1UFB(1))
  acc2.unlocks = new Set(['!era3_tier1'])
  return acc2
}

//  TODO: Right now era2 and era3 users gets the same features
function render__unlockEra3Tier2 (acc, e) {
  assert(e.cmd === 'unlock_era3_tier2')

  let acc2 = render__assignRoom0(acc, makeEra3Tier2UFB(1))
  acc2.unlocks = new Set(['!era3_tier2'])
  return acc2
}

//  TODO: Right now era2 and era3 users gets the same features
function render__unlockEra4Tier1 (acc, e) {
  unitTestAssert(e.cmd === 'unlock_era4_tier1')

  let acc2 = render__assignRoom0(acc, makeEra4Tier1UFB(1))
  acc2.unlocks = new Set(['!era4_tier1'])
  return acc2
}

function render__unlockEra4Tier2 (acc, e) {
  unitTestAssert(e.cmd === 'unlock_era4_tier2')

  let acc2 = render__assignRoom0(acc, makeEra4Tier2UFB(1))
  acc2.unlocks = new Set(['!era4_tier2'])
  return acc2
}

// --------------------------------   MORE COMMANDS



function render__modify_rooms (acc, e) {
  assert(e.cmd === 'modify_rooms')

  var acc2 = _.cloneDeep(acc)
  acc2.ufb = UserFeatureBitsModule.modifyUFB(acc2.ufb, e.params.modifier)
  acc2.unlocks = new Set(['!custom'])
  return acc2
}


// --------------------------------   UNLOCK CUSTOM




//  Returns array of journal entires to append to journal
function unlockCustom (currentDate, customUFB, note) {
  assert(isValidISODateString(currentDate))
  assert(UserFeatureBitsModule.checkInvariantUFB(customUFB))
  assert(note === undefined || isStringInstance(note))

  var r = [ { date: currentDate, cmd: 'unlock_custom', params: { ufb: customUFB } } ]

  if (note !== undefined) {
    r[0].note = note
  }

  assert(checkJournal(r))
  return r
}



function prove_unlockCustom_test1 () {
  console.log('prove_unlockCustom_test1():')

  const journal = unlockCustom('2021-04-29T20:46:17.087Z', UserFeatureBitsModule.make2RoomsExample(), 'xyz')
  // console.dir(journal, { depth: null })

  const a = makeJournalReportStrings(journal)
  consoleLogLinesStringLiterals(a)
  unitTestAssert(
    _.isEqual(
      a,
      [
        'DATE                     | COMMAND       | PARAMS                                   | NOTE | UNLOCKS | OPTIN   | MIGRATED | UFB                                      | NEXT_RID',
        '2021-04-29T20:46:17.087Z | unlock_custom | {"ufb":{"user":{},"rooms":{"999":{"se... | xyz  | !custom | unknown | false    | d,999  5 ---O O- ---,1000 20 O--- O- --- | 1       '
      ]
    ) === true
  )
}

function render__unlock_custom (acc, e) {
  assert(e.cmd === 'unlock_custom')

  var acc2 = _.cloneDeep(acc)
  acc2.ufb = sanitizeUFB(e.params.ufb)
  acc2.unlocks = new Set(['!custom'])
  return acc2
}

function prove_unlock_custom_blank () {
  console.log('prove_unlock_custom_blank():')

  const journal = unlockCustom('2021-04-29T20:46:17.087Z', UserFeatureBitsModule.makeBlankUFB(), 'Test notes')
  const a = makeJournalReportStrings(journal)
  console.dir(a, { depth: null })
  consoleLogLinesStringLiterals(a)
  unitTestAssert(
    _.isEqual(
      a,
      [
        'DATE                     | COMMAND       | PARAMS                         | NOTE       | UNLOCKS | OPTIN   | MIGRATED | UFB | NEXT_RID',
        '2021-04-29T20:46:17.087Z | unlock_custom | {"ufb":{"user":{},"rooms":{}}} | Test notes | !custom | unknown | false    | d   | 1       ',
      ]
    ) === true
  )
}

function prove_unlock_custom2 () {
  console.log('prove_unlock_custom2():')

  const complexUFB = UserFeatureBitsModule.createUFB2({ user: {}, rooms: {'123': { seats: 2 }, '456': { seats: 80, video_chat_enabled: true }} })

  const journal = unlockCustom('2021-04-29T20:46:17.087Z', complexUFB, 'Test notes')
  const a = makeJournalReportStrings(journal)
  // console.dir(a, { depth: null })
  consoleLogLinesStringLiterals(a)
  unitTestAssert(
    _.isEqual(
      a,
      [
        'DATE                     | COMMAND       | PARAMS                                   | NOTE       | UNLOCKS | OPTIN   | MIGRATED | UFB                                     | NEXT_RID',
        '2021-04-29T20:46:17.087Z | unlock_custom | {"ufb":{"user":{},"rooms":{"123":{"se... | Test notes | !custom | unknown | false    | d,123  2 ---- -- ---,456 80 ---- O- --- | 1       ',
      ]
    ) === true
  )
}


// --------------------------------   renderJournalOutput()


function renderJournalEntry (acc, e) {
  //  Handle early types of journal entires that had no cmd-key.
  if (e.cmd === undefined) {
    return render__early_entry(acc, e)
  }


  else if (e.cmd === 'mig2journal') {
    return render__mig2journal(acc, e)
  }
  else if (e.cmd === 'unlock_tier1') {
    return render__unlockEra2Tier1(acc, e)
  }
  else if (e.cmd === 'unlock_tier2') {
    return render__unlockEra2Tier2(acc)
  }


  else if (e.cmd === 'unlock_era3_tier1') {
    return render__unlockEra3Tier1(acc, e)
  }
  else if (e.cmd === 'unlock_era3_tier2') {
    return render__unlockEra3Tier2(acc, e)
  }

  else if (e.cmd === 'unlock_era4_tier1') {
    return render__unlockEra4Tier1(acc, e)
  }
  else if (e.cmd === 'unlock_era4_tier2') {
    return render__unlockEra4Tier2(acc, e)
  }


  else if (e.cmd === 'modify_rooms') {
    return render__modify_rooms(acc, e)
  }

  else if (e.cmd === 'unlock_custom') {
    return render__unlock_custom(acc, e)
  }

  else if (e.cmd === 'unlock_user_dawstreaming') {
    return render__unlockUserDAWStreaming(acc, e)
  }

  else {
    throw new Error('Journal invalid')
  }
}


// Requires a migrated journal -- can have no implicit dependencies on rooms data in database!
// Returns output for each journal entry. You can use last element as current-output.
// [{ ufb, optinEmail = "yes", unlocks: [], migrated: false, nextFreeRoomID = 1, DBIDMapping }]
function renderJournalOutput (journal) {
  assert(checkJournal(journal))

  // Records the output state after each entry in journal has been executed
  var entryOutputs = []

  var acc = {
    ufb: UserFeatureBitsModule.makeBlankUFB(),
    optinEmail: "unknown",
    unlocks: new Set(),
    migrated: false,
    nextFreeRoomID: 1,
    DBIDMapping: {}
  }

  traverseJournal(journal, function (journal, i, e) {
    var acc2 = renderJournalEntry(acc, e)
    entryOutputs.push(acc2)
    acc = acc2
  })

  return entryOutputs
}

//  Makes a nice report with HEADERS and aligned columns.
function makeJournalReportStrings(journal) {
  const a = makeJournalReport(journal)
  const b = [journalHeadersWithOutput].concat(a)
  const c = TableFormatting.tableToStrings(b, ' | ')
  return c
}

function prove_renderJournalOutput_example_migrated1 () {
  console.log('prove_renderJournalOutput_example_migrated1():')

  const c = makeJournalReportStrings(example_migrated1)
  // console.dir(a, { depth: null })
  consoleLogLinesStringLiterals(c)
  unitTestAssert(
    _.isEqual(
      c,
      [
        'DATE                     | COMMAND     | PARAMS                                   | NOTE | UNLOCKS     | OPTIN   | MIGRATED | UFB                | NEXT_RID',
        '2021-04-29T20:46:17.087Z | promo_code  | rat-poniard-octane-sheer                 |      | !era2_tier2 | unknown | false    | d,1  2 ---- O- O-- | 2       ',
        '2021-04-31T20:46:17.087Z | mig2journal | {"migration_ufb":{"rooms":{"azdQqRapF... |      | !era2_tier2 | unknown | true     | d,1  2 ---- O- O-- | 2       '
      ]
    ) === true
  )
}

function prove_renderJournalOutput_example_migrated2 () {
  console.log('prove_renderJournalOutput_example_migrated2():')

  const a = renderJournalOutput(example_migrated2)
  console.log(a)
}

function prove_renderJournalOutput_example_era2tier1Account () {
  console.log('prove_renderJournalOutput_example_era2tier1Account():')

  const c = makeJournalReportStrings(example_era2tier1Account)
  consoleLogLinesStringLiterals(c)
  unitTestAssert(
    _.isEqual(
      c,
      [
        'DATE                     | COMMAND      | PARAMS                                   | NOTE | UNLOCKS     | OPTIN   | MIGRATED | UFB                | NEXT_RID',
        '2021-04-31T20:46:17.087Z | mig2journal  | {"migration_ufb":{"rooms":{"azdQqRapF... |      | !era2_tier1 | unknown | true     | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | unlock_tier1 |                                          | cta  | !era2_tier1 | unknown | true     | d,1  2 ---- O- O-- | 2       '
      ]
    ) === true
  )
}

function prove_renderJournalOutput_example_era2tier2Account_Promocode () {
  console.log('prove_renderJournalOutput_example_era2tier2Account_Promocode():')

  const c = makeJournalReportStrings(example_era2tier2Account_Promocode)
  consoleLogLinesStringLiterals(c)
  unitTestAssert(
    _.isEqual(
      c,
      [
        'DATE                     | COMMAND     | PARAMS                                   | NOTE | UNLOCKS     | OPTIN   | MIGRATED | UFB                | NEXT_RID',
        '2021-04-29T20:46:17.087Z | promo_code  | rat-poniard-octane-sheer                 |      | !era2_tier2 | unknown | false    | d,1  2 ---- O- O-- | 2       ',
        '2021-04-31T20:46:17.087Z | mig2journal | {"migration_ufb":{"rooms":{"azdQqRapF... |      | !era2_tier2 | unknown | true     | d,1  2 ---- O- O-- | 2       '
      ]
    ) === true
  )
}


function prove_renderJournalOutput_userDAWStreaming () {
  console.log('prove_renderJournalOutput_userDAWStreaming():')

  const journal0 = example_era2tier2Account_Promocode.concat(
    unlockUserDAWStreaming('2021-04-29T20:46:17.087Z', 'dawpromo0', 'DAW notes')
  )

  const c = makeJournalReportStrings(journal0)
  consoleLogLinesStringLiterals(c)
  unitTestAssert(
    _.isEqual(
      c,
      [
        'DATE                     | COMMAND                  | PARAMS                                   | NOTE      | UNLOCKS                        | OPTIN   | MIGRATED | UFB                | NEXT_RID',
        '2021-04-29T20:46:17.087Z | promo_code               | rat-poniard-octane-sheer                 |           | !era2_tier2                    | unknown | false    | d,1  2 ---- O- O-- | 2       ',
        '2021-04-31T20:46:17.087Z | mig2journal              | {"migration_ufb":{"rooms":{"azdQqRapF... |           | !era2_tier2                    | unknown | true     | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | unlock_user_dawstreaming | {"promo_code":"dawpromo0"}               | DAW notes | !era2_tier2 !user_dawstreaming | unknown | true     | D,1  2 ---- O- O-- | 2       '
      ]
    ) === true
  )
}





//  Computes the output of each element in the journal: the UFB, the unlocks etc.
//  Returns array of strings: [ unlocks, optinEmail, migrated, ufb, nextFreeRoomID ]
function makeOutString (e) {
  const line = [
    Array.from(e.unlocks).join(' '),
    e.optinEmail.toString(),
    e.migrated.toString(),
    UserFeatureBitsModule.makeUFBCompactStrings(e.ufb).join(),
    e.nextFreeRoomID.toString()
  ]
  return line
}

function makeJournalReport (journal) {
  assert(checkJournal(journal))

  const journalOutput = renderJournalOutput(journal)
  const journalText = journalToTable(journal, 40)

  assert(journalOutput.length === journal.length)
  assert(journalText.length === journal.length)

  var result = []

  //  Appends columns for the output each journal element: the UFB etc.
  for (var i = 0; i < journal.length; i++) {
    const s = makeOutString(journalOutput[i])
    const line = journalText[i].concat(s)
    result.push(line)
  }
  return result
}

function makeAdminCompactJournalReport (journal) {
  assert(checkJournal(journal))

  const journalOutput = renderJournalOutput(journal)
  const journalText = journalToTable(journal, 20)

  assert(journalOutput.length === journal.length)
  assert(journalText.length === journal.length)

  var result = []
  for (var i = 0; i < journal.length; i++) {
    const ufbString = UserFeatureBitsModule.makeUFBCompactStrings(journalOutput[i].ufb).join()
    const line = journalText[i].concat(ufbString)
    result.push(line)
  }

  return [journalHeadersWithUFB].concat(result)
}

function prove_makeJournalReport2 () {
  console.log('prove_makeJournalReport2():')

  const c = makeJournalReportStrings(example_migrated2)
  consoleLogLinesStringLiterals(c)
  unitTestAssert(
    _.isEqual(
      c,
      [
        'DATE                     | COMMAND      | PARAMS                                   | NOTE | UNLOCKS     | OPTIN | MIGRATED | UFB                | NEXT_RID',
        '2021-04-29T20:46:17.087Z | optin_email  | true                                     |      |             | yes   | false    | d                  | 1       ',
        '2021-04-29T20:46:17.087Z | promo_code   | rat-poniard-octane-sheer                 |      | !era2_tier2 | yes   | false    | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | mig2journal  | {"migration_ufb":{"rooms":{"azdQqRapF... |      | !era2_tier2 | yes   | true     | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | unlock_tier1 |                                          | cta  | !era2_tier1 | yes   | true     | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | unlock_tier2 | {"payment_token":"chargebee1234"}        |      | !era2_tier2 | yes   | true     | d,1  2 ---- O- O-- | 2       ',
        '2021-04-29T20:46:17.087Z | modify_rooms | {"modifier":{"rooms":{"1":{},"2":{}}}}   |      | !custom     | yes   | true     | d,1  2 ---- O- O-- | 2       '
      ]
    ) === true
  )
}
      

function makeSummary (journal) {
  assert(checkJournal(journal))

  const output = renderJournalOutput(journal)
  const e = output[output.length - 1]

  const ufb_compact = UserFeatureBitsModule.makeUFBCompactStrings(e.ufb).join(',')
  return {
    ufb: ufb_compact,
    unlocks: e.unlocks,
    optinEmail: e.optinEmail
  }
}

function prove_makeSummary1 () {
  const a = makeSummary(example_migrated1)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!era2_tier2']), optinEmail: 'unknown' }) === true)
}

function prove_makeSummary2 () {
  const a = makeSummary(example_migrated2)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!custom']), optinEmail: 'yes' }) === true)
}


//  Returns array of journal entires to append to journal
function storeEmailOptin (date, optin_flag) {
  assert(isValidISODateString(date))

  const r = [{ date: date, optin_email: optin_flag }]
  assert(checkJournal(r))
  return r
}

function prove_storeEmailOptin () {
  const a = storeEmailOptin('2021-04-29T20:46:17.087Z', false)
  console.log(a)
  unitTestAssert(a[0]['optin_email'] === false)

  const summary = makeSummary(a)
  unitTestAssert(_.isEqual(summary, { ufb: 'd', unlocks: new Set(), optinEmail: 'no' }) === true)
}


//  Returns array of journal entires to append to journal
function storePromoCode (date, promoCode) {
  assert(isStringInstance(promoCode) && promoCode.length > 0)

  const r = [{ date: date, 'promo_code': promoCode }]
  assert(checkJournal(r))
  return r
}

function prove_storePromoCode () {
  const a = storePromoCode('2021-04-29T20:46:17.087Z', 'DarthVader')
  console.log(a)
  unitTestAssert(a[0]['promo_code'] === 'DarthVader')

  const summary = makeSummary(a)
  console.log(summary)
  unitTestAssert(_.isEqual(summary, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!era2_tier2']), optinEmail: 'unknown' }) === true)
}


//  Returns array of journal entires to append to journal
function storeUnlock (currentDate, command, promoCode, note) {
  assert(isValidISODateString(currentDate))
  assert(command === undefined || isStringInstance(command))
  assert(promoCode === undefined || (isStringInstance(promoCode) && promoCode.length > 0))
  assert(note === undefined || isStringInstance(note))

  var r = [ { date: currentDate, cmd: command } ]

  if (note !== undefined) {
    r[0].note = note
  }
  if (promoCode !== undefined) {
    r[0].params = { promo_code: promoCode }
  }

  assert(checkJournal(r))
  return r
}



// --------------------------------   CURRENT ERA



//  Returns a number of journal entries that clients needs to append to journal.
function unlockCurrentEraTier1 (currentDate, note) {
  return unlockEra3Tier1(currentDate, note)
}


//  Returns a number of journal entries that clients needs to append to journal.
function unlockCurrentEraTier2 (currentDate, promoCode, note) {
  return unlockEra4Tier2(currentDate, promoCode, note)
}


// --------------------------------   ERA SCENARIOS


function prove_upgradeE2T1ToE3T1 () {
  const journal = example_era2tier1Account.concat(unlockEra3Tier1('2021-04-29T20:46:17.087Z', 'Test notes'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!era3_tier1']), optinEmail: 'unknown' }) === true)
}

function prove_upgradeE2T1ToE3T2 () {
  const journal = example_era2tier1Account.concat(unlockEra3Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  5 ---- O- -OO', unlocks: new Set(['!era3_tier2']), optinEmail: 'unknown' }) === true)
}

function prove_upgradeE2T2ToE3T1 () {
  const journal = example_era2tier2Account_Promocode.concat(unlockEra3Tier1('2021-04-29T20:46:17.087Z', 'Test notes'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!era3_tier1']), optinEmail: 'unknown' }) === true)
}

function prove_upgradeE2T2ToE3T2 () {
  const journal = example_era2tier2Account_Promocode.concat(unlockEra3Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  5 ---- O- -OO', unlocks: new Set(['!era3_tier2']), optinEmail: 'unknown' }) === true)
}

function prove_downgradeE3T2ToE3T1 () {
  const journal0 = example_era2tier2Account_Promocode.concat(unlockEra3Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  const journal = example_era2tier2Account_Promocode.concat(unlockEra3Tier1('2021-04-29T20:46:17.087Z', 'Would not pay'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- O--', unlocks: new Set(['!era3_tier1']), optinEmail: 'unknown' }) === true)
}

function prove_upgradeE3T2ToE4T2 () {
  const journal = example_era2tier2Account_Promocode.concat(unlockEra4Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  console.log({journal})
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'D,1 20 ---- O- --O', unlocks: new Set(['!era4_tier2']), optinEmail: 'unknown' }) === true)
}  

function prove_downgradeE4T2ToE4T1 () {
  const journal0 = example_era2tier2Account_Promocode.concat(unlockEra4Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  const journal = example_era2tier2Account_Promocode.concat(unlockEra4Tier1('2021-04-29T20:46:17.087Z', 'Would not pay'))
  const a = makeSummary(journal)
  console.log(a)
  unitTestAssert(_.isEqual(a, { ufb: 'd,1  2 ---- O- ---', unlocks: new Set(['!era4_tier1']), optinEmail: 'unknown' }) === true)
}  

function prove_eraScenarios () {
  prove_upgradeE2T1ToE3T1()
  prove_upgradeE2T1ToE3T2()
  prove_upgradeE2T2ToE3T1()
  prove_upgradeE2T2ToE3T2()
  prove_downgradeE3T2ToE3T1()
  prove_upgradeE3T2ToE4T2()
  prove_downgradeE4T2ToE4T1()
}



// --------------------------------   PACKING



// Prepare the journal for database storage, by turning it into an array of strings.
function packJournal (journal) {
  const entries = []
  for (const entry of Object.values(journal)) {
    entries.push(JSON.stringify(entry))
  }
  return entries
}

// Make an actual journal object from the stringified form it's stored in database like.
function unpackJournal (packedJournal) {
  SpotterfishCore.assert(SpotterfishCore.isArrayInstance(packedJournal))

  const journal = []
  for (const stringifiedEntry of Object.values(packedJournal)) {
    journal.push(JSON.parse(stringifiedEntry))
  }
  assert(checkJournal(journal))
  return journal
}

function prove_packUnpackJournal () {
  // TODO: We might want more advanced tests, but for now just make sure these two
  // functions are symmetrical.
  const testJournals = [
    exampleJournalAllCommands,
    example_migrated1,
    example_migrated2
  ]

  for (const journal of Object.values(testJournals)) {
    const converted = unpackJournal(packJournal(journal))
    unitTestAssert(_.isEqual(journal, converted))
  }
}





function getShortTierString(journal) {
  assert(checkJournal(journal))
  const output = renderJournalOutput(journal)
  const e = output[output.length - 1]

  console.log(Array.from(e.unlocks).join(' '))

  if(e.unlocks.has('!blank')){
    assert(_.size(e.unlocks) === 1)
    return 'blank'
  }
  else if(e.unlocks.has('!era2_tier1') || e.unlocks.has('!era3_tier1') || e.unlocks.has('!era2_tier2') || e.unlocks.has('!era4_tier1')){
    return 'tier1'
  }
  else if(e.unlocks.has('!era3_tier2') || e.unlocks.has('!era4_tier2')){
    return 'tier2'
  }
  else {
    return 'custom'
  }
}

function prove_getShortTierString_era2tier1 () {
  const a = getShortTierString(example_era2tier1Account)
  unitTestAssert(a === 'tier1')
}

function prove_getShortTierString_era2tier2 () {
  const a = getShortTierString(example_era2tier2Account_Promocode)
  unitTestAssert(a === 'tier1')
}

//  2021-12-09 DEFECT REPORT: After upgrade to tier 2 via dashboard button, tier becomes "unknown" instead of "tier2" in result from CFgetAccountTier.
function prove_getShortTierString_upgradeE2T1toE2T2 () {
  const journal = example_era2tier1Account.concat(unlockEra2Tier2('2021-04-29T20:46:17.087Z', 'Test notes'))
  const a = getShortTierString(journal)
  unitTestAssert(a === 'tier1')
}

function prove_getShortTierString_era4Tier1 () {
  const a = getShortTierString(example_era4tier1Account)
  unitTestAssert(a === 'tier1')
}

function prove_getShortTierString_era4Tier2 () {
  const a = getShortTierString(example_era4tier2Account)
  console.log(a)
  unitTestAssert(a === 'tier2')
}

function prove_getShortTierString_upgradeE2T1toE2T2tocurrentT1 () {
  const journal = example_era2tier1Account.concat(unlockEra2Tier2('2021-04-29T20:46:17.087Z', 'Test notes')).concat(unlockCurrentEraTier1('2021-04-29T20:46:17.087Z', 'test notes2'))
  console.log(journal)
  const a = getShortTierString(journal)
  unitTestAssert(a === 'tier1')
}

function prove_getShortTierString_upgradeE2T1toE2T2toCurrentT2 () {
  const journal = example_era2tier1Account.concat(unlockEra2Tier2('2021-04-29T20:46:17.087Z', 'Test notes')).concat(unlockCurrentEraTier2('2021-04-29T20:46:17.087Z', 'test notes2'))
  console.log(journal)
  const a = getShortTierString(journal)
  unitTestAssert(a === 'tier2')
}


function runUnitTests () {
  console.log('userJournal.js -- START')

  SpotterfishCore.checkCoreEnvironment()

  prove_javaScript()

  prove_findLowestUsedRoomID()

  const ufb = UserFeatureBitsModule.createUFB2({ user: {}, rooms: {} })

  prove_exampleJournalAllCommands()
  prove_example_migrated1()
  prove_example_migrated2()
  prove_example_era2tier1Account()
  prove_example_era2tier2Account_Promocode()

  prove_journalToTable_example_migrated1()
  prove_journalToTable_exampleJournalAllCommands()

  prove_traverseJournal_example_migrated1()

  prove_render__mig2journal_RegressionTest1()

  prove_renderJournalOutput_example_migrated1()

  prove_renderJournalOutput_example_migrated2()
  prove_renderJournalOutput_example_era2tier1Account()
  prove_renderJournalOutput_example_era2tier2Account_Promocode()

  prove_renderJournalOutput_userDAWStreaming()

  prove_makeJournalReport2()

  prove_makeSummary1()
  prove_makeSummary2()

  prove_storeEmailOptin()
  prove_storePromoCode()

  prove_unlockEra2Tier1_test1()
  prove_unlockEra2Tier2_test1()

  prove_unlockEra3Tier1_test1()
  prove_unlockEra3Tier2_test1()

  prove_unlockEra4Tier1_test1()
  prove_unlockEra4Tier2_test1()

  prove_makeEra4Tier2UFB()

  prove_unlockUserDAWStreaming()

  prove_unlockCustom_test1()
  prove_unlock_custom_blank()
  prove_unlock_custom2()

  prove_eraScenarios()

  prove_packUnpackJournal()

  prove_getShortTierString_era2tier1()
  prove_getShortTierString_era2tier2()
  prove_getShortTierString_upgradeE2T1toE2T2()
  prove_getShortTierString_era4Tier1()
  prove_getShortTierString_era4Tier2()
  prove_getShortTierString_upgradeE2T1toE2T2tocurrentT1()
  prove_getShortTierString_upgradeE2T1toE2T2toCurrentT2()

  console.log('userJournal.js -- END')
}

// TODO: Decide what functions should be exported, i.e. what is the public API.
module.exports = {
  checkJournal,

  journalToTable,
  renderJournalOutput,
  makeJournalReport,
  makeJournalReportStrings,
  makeAdminCompactJournalReport,
  makeSummary,

  storeEmailOptin,
  storePromoCode,
  unlockUserDAWStreaming,
  unlockCurrentEraTier1,
  unlockCurrentEraTier2,
  unlockCustom,

  packJournal,
  unpackJournal,

  getShortTierString,

  runUnitTests
}
