<template>
  <v-app
    :style="{ 'background-color': colors.mx.black }"
    :class="isMobilePhone ? 'mobile-style' : 'none'"
  >
    <v-layout justify-center>
      <v-flex
        xs12
        sm10
        md6
        lg3
        xl3
        id="login"
        style="margin: auto; color: pink;"
        :class="isMobilePhone ? 'mobile-style' : 'none'"
      >
        <v-row no-gutters>
          <v-col cols="12" 
            style="display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 6px 6px 0 0; 
            height: 80px;
            border: 1px solid"
            :style="{ 'background-color': colors.mx.dark, 'border-color': colors.mx.white}">
              <img style="width: 120px;" :src="logos.mixStageLogoWithWhiteText" />
          </v-col>
          <v-col cols="12">
            <v-card
              class="elevation-0"
              style="
                margin: auto;
                border-radius: 0 0 6px 6px;
                color: black;
              "
              :style="{ background: colors.white }"
              :class="isMobilePhone ? 'mobile-style' : 'none'"
            >
              <v-card-text>
                <v-layout justify-center>
                  <v-flex xs12>
                    <form>
                      <v-layout
                        v-if="!currentUser"
                        wrap
                        align-center
                        justify-center
                      >
                        <p 
                          v-if="!checkRegistered"
                          style="
                              width: 100%;
                              color: black;
                              font-size: 16px;
                            "
                        >
                        Welcome to MixStage™
                        <br />
                        Please enter your email to get started
                        </p>
                        <h2
                          v-if="checkRegistered && !needsPasswordUpdate"
                          style="
                            margin: 16px 0 10px 0;
                            color: black;
                            font-size: 16px;
                          "
                        >
                          Log In
                        </h2>

                        <v-flex xs12>
                          <v-text-field
                            :disabled="spinner || checkRegistered"
                            ref="usernameField"
                            :hint="usernameFieldHint"
                            label="Email"
                            outlined
                            persistent-hint
                            color="#919191"
                            light
                            dense
                            autocomplete="email"
                            class="mt-6"
                            style="color: black"
                            type="text"
                            v-model="email"
                            placeholder="you@example.com"
                            data-testid="signin-email"
                            :rules="emailRules"
                            @keydown="usernameFieldHint = ''"
                            @keyup.enter="login"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-if="checkRegistered && !needsPasswordUpdate"
                            :disabled="spinner"
                            ref="passwordField"
                            light
                            outlined
                            label="Password"
                            color="#919191"
                            dense
                            class="mb-1"
                            type="password"
                            hide-errors
                            v-model="password"
                            autocomplete="current-password"
                            placeholder="Your password"
                            data-testid="signin-pwd"
                            @keyup.enter="login"
                          />
                          <div 
                            v-if="checkRegistered" 
                            style="margin-top: -10px; font-size: 14px; cursor: pointer;"
                            :style="{ 'color': colors.mx.light, 'text-align': 'left'}" 
                            @click="PWResetDialog = true"
                            :disabled="spinner"
                          >
                          {{ needsPasswordUpdate ? 'Reset password' : 'Forgot password?' }}
                          </div>
                          <v-flex xs12>
                            <v-text-field
                              v-if="checkRegistered && needsPasswordUpdate"
                              :disabled="spinner"
                              ref="passwordField"
                              light
                              outlined
                              label="Create Password"
                              color="#919191"
                              dense
                              class="mb-1"
                              type="password"
                              hide-errors
                              v-model="password"
                              placeholder="Create strong password"
                              data-testid="signin-pwd"
                              @keyup.enter="updatePasswordAndLogIn()"
                            />
                          </v-flex>
                        </v-flex>
                        <v-flex xs12>
                          <div id="recaptchacontainer"></div>
                          <v-btn
                            v-if="!checkRegistered"
                            dark
                            large
                            :style="{
                              background: colors.mixStage_main + '!important'
                            }"
                            class="lighten-2 mt-4 elevation-5"
                            data-testid="signin-btn"
                            @click="checkIfRegistered()"
                            :disabled="spinner || !isEmailValid(email)"
                          >
                          Continue</v-btn
                          ><br />
                          <v-btn
                            v-if="checkRegistered && !needsPasswordUpdate"
                            dark
                            large
                            :style="{
                              background: colors.mixStage_main + '!important'
                            }"
                            class="lighten-2 mt-5 elevation-5"
                            data-testid="signin-btn"
                            @click="login"
                            :disabled="spinner || !isEmailValid(email)"
                          >
                          Log In</v-btn
                          >
                          <v-btn
                            v-if="checkRegistered && needsPasswordUpdate"
                            dark
                            large
                            :style="{
                              background: colors.mixStage_main + '!important'
                            }"
                            class="lighten-2 mt-5 elevation-5"
                            data-testid="signin-btn"
                            @click="updatePasswordAndLogIn"
                            :disabled="spinner || !isPasswordValid(password)"
                          >
                          Update password and Log In</v-btn
                          >

                          <br />
                          <span v-if="checkRegistered" :style="{ color: colors.mx.light, 'margin-top': '4px' }">Don't have an account? <strong style="cursor: pointer;" @click="pushToSignup()">Get started</strong></span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else justify="centered">
                        <v-card light>
                          <v-card-text align="left">
                            An error occured. Please click the button below or
                            refresh your browser.
                            <v-btn
                              dark
                              large
                              :style="{ background: colors.mixStage_main }"
                              class="lighten-2 mt-5 elevation-5"
                              @click="pushToCorrectLocation()"
                            >
                              Proceed
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-layout>
                    </form>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        

        <v-overlay
          v-if="spinner"
          persistent
          style="z-index: 1010; background-color: rgba(0, 0, 0, 0.7)"
        >
        <mixstage-spinner
          :showSpinner="true"
          :loadingMessage="!checkRegistered ? 'Searching account...' : 'Logging in...'"
          style="background-color: transparent; width: 300px; height: 100px;"
        ></mixstage-spinner>
        </v-overlay>

        <!-- ::::::::::::::PW RESET DIALOG:::::::::::::: -->
        <v-dialog v-model="PWResetDialog" dark width="400">
          <v-card dark :style="{ background: colors.mx.white, color: colors.mx.dark}">
            <v-toolbar dark style="max-height: 54px; border: 1px solid white;">
                <v-spacer></v-spacer>
                <v-toolbar-title style="font-size: 1em;">Password Reset
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn fab dark small style="position: absolute; right: 4px; top: -3px;" @click="PWResetDialog = false, resetEmail = ''">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
            <v-card-title>
              <span style="font-size: 16px;" :style="{ color: colors.mx.dark }">Please enter your registered email</span>
            </v-card-title>
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      style="margin-top: 10px"
                      outlined
                      autofocus
                      light
                      dense
                      autocomplete="current-password"
                      v-model="resetEmail"
                      :rules="emailRules"
                      label="E-mail"
                      color="black"
                      required
                    >
                    </v-text-field>
                    <div style="display: flex; justify-content: center;">
                      <v-btn
                        dark
                        :color="colors.mixStage_main"
                        style="width: 100%"
                        @click="closePasswordResetDialog()"
                        >Send</v-btn
                      >
                    </div>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
        <alert-component ref="alert"></alert-component>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import store from '@/store'
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import validator from 'validator'
import mixStageSpinner from '@/components/dashboard/mixStageSpinner.vue'

import alert from '@/components/UI/alert.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'login',
  currentUser: '',
  components: {
    'alert-component': alert,
    VueRecaptcha,
    'mixstage-spinner': mixStageSpinner

  },
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      email: '',
      passwordRules: [
        (value) => !!value || 'Password is required',
        (value) =>
          value.length >= 8 || 'Password must be at least 8 characters',
      ],

      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => validator.isEmail(v) || 'E-mail must be valid'
      ],
      textRules: [(v) => !!v || 'Required'],
      selectErrors: 'You need to select at least one',
      resetEmail: '',
      password: '',
      logos: require('@/lib/ui/logos.js').logos,
      sendingRequest: false,
      PWResetDialog: false,
      valid: '',
      usernameFieldHint: '',
      spinner: false,
      checkRegistered: false,
      needsPasswordUpdate: false,
    }
  },
  computed: {
    ...mapGetters(['']),
    currentUser() {
      return (
        App.spotterfishSession.userSession &&
        App.spotterfishSession.userSession.firebaseCurrentUser
      )
    },
    isMobilePhone() {
      return isMobile
    },
  },
  methods: {

    isEmailValid(email) {
      return this.emailRules.every((rule) => typeof rule(email) === 'boolean')
    },
    isPasswordValid(password) {
      return this.passwordRules.every((rule) => typeof rule(password) === 'boolean')
    },

    async checkIfRegistered () {
      this.spinner = true
      console.log(this.email)
      const { features, tempPasswordSet } = await CloudClient.call_CFgetaccountInfoIfEmailExist(App.Firebase, this.email.trim()) 
      this.spinner = false
      if (!features) {
        this.pushToSignup()
      }
      // TODO: Should move to Signup if not registered - pass the email along, should show login if is registered
      this.needsPasswordUpdate = tempPasswordSet
      this.checkRegistered = true
    },

    pushToSignup () {
      this.$router.push({ 
          name: 'signup', 
          query: {
          hash: this.$route.query.hash,
          email: this.email.trim(),
          betaSignup: this.$route.query.betaSignup,
          },
        }).catch((e) => {
          console.log(e)
        })
    },

    async updatePasswordAndLogIn () {
      if (this.isPasswordValid(this.password) === false) {
        return
      }
      this.spinner = true
      let updateOk = false
      try {
        console.log(this.password)
        await CloudClient.call_CFsetUserClaims(App.Firebase, this.email, this.password)   
        updateOk = true     
      } catch (error) {
        updateOk = false
        this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not update password (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }
      if (updateOk) { this.login() }
    },

    login() {
      this.spinner = true

      const self = this
      var resolver
      const appVerifier = new App.Firebase.auth.RecaptchaVerifier(
        'recaptchacontainer',
        { size: 'invisible' }
      )

      App.Firebase.auth()
        .signInWithEmailAndPassword(this.email.trim(), this.password)
        .then((user) => {
          store.commit('setLoggedInState', true)
          store.commit('setCurrentLoggedInUser', user)
          if (this.$route.query.hash) {
            this.$router
              .push({ name: 'screeningroom', query: this.$route.query })
              .catch((e) => {
                console.log(e)
              })
          } else {
            this.$router.push({ name: 'dashboard', query: this.$route.query }).catch((e) => {
              console.log(e)
            })
          }
        })
        .catch(async (error) => {
          this.spinner = false

          console.error({ error })

          if (error.code === 'auth/multi-factor-auth-required') {
            resolver = error.resolver
            // Ask user which second factor to use.
            console.log(resolver.hints)
            let hintIndex = 0

            if (resolver.hints.length && resolver.hints.length > 1) {
              hintIndex = await self.$root.$globalPrompt.open(
                `Two-factor authentication`,
                'Please select which phone we should send the pass code to:',
                resolver.hints,
                { width: '500px', color: self.colors.mixStage_main }
              )
            }
            console.log('hintIndex', hintIndex)
            if (
              resolver.hints[hintIndex].factorId ===
              App.Firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
            ) {
              var phoneInfoOptions = {
                multiFactorHint: resolver.hints[hintIndex],
                session: resolver.session,
              }
              var phoneAuthProvider = new App.Firebase.auth.PhoneAuthProvider()
              // Send SMS verification code
              return phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, appVerifier)
                .then(async function (verificationId) {
                  // Ask user for the SMS verification code.
                  const verificationCode = await self.$root.$globalPrompt.open(
                    `Enter the verification code`,
                    `We sent the verification code to your registered ${resolver.hints[hintIndex].displayName}`,
                    null,
                    { width: '500px', color: self.colors.mixStage_main }
                  )
                  var cred = App.Firebase.auth.PhoneAuthProvider.credential(
                    verificationId,
                    verificationCode
                  )
                  var multiFactorAssertion =
                    App.Firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
                  // Complete sign-in.
                  return resolver.resolveSignIn(multiFactorAssertion)
                })
                .then(function (userCredential) {
                  // User successfully signed in with the second factor phone number.
                  self.$router.push({ name: 'dashboard' }).catch((e) => {
                    console.log(e)
                  })
                })
                .catch((error) => {
                  console.log(error)
                  self.$refs.alert
                    .open(
                      `Wrong code`,
                      {
                        line1: 'Please try again.',
                      },
                      { color: self.colors.mixStage_main }
                    )
                    .then(() => {
                      window.location.reload()
                    })
                })
            } else {
              // Unsupported second factor.
              this.$refs.alert
                .open(
                  `Unsupported Second Factor`,
                  {
                    line1: error.message,
                  },
                  { color: this.colors.mixStage_main }
                )
                .then((confirm) => {
                  window.location.reload()
                })
            }
          } else if (error.code === 'auth/wrong-password') {
            // Handle other errors such as wrong password.
            this.$refs.alert
              .open(
                `Wrong password`,
                {
                  line1: error.message,
                },
                { color: this.colors.mixStage_main }
              )
              .then((confirm) => {
                if (confirm) {
                }
              })
          } else if (error.code === 'auth/user-not-found') {
            this.$refs.alert
              .open(
                'No such user',
                { line1: error.message },
                { color: this.colors.mixStage_main }
              )
              .then((confirm) => {
                if (confirm) {
                }
              })
          } else {
            this.$refs.alert
              .open(
                `Error`,
                {
                  line1: 'To proceed you need to refresh your browser.',
                },
                { color: this.colors.mixStage_main }
              )
              .then((confirm) => {
                if (confirm) {
                  window.location.reload()
                }
              })
          }
        })
    },

    loginWithExternallyGeneratedToken(token) {
      App.Firebase.auth()
        .signOut()
        .then(() => {
          App.Firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              console.log(user)
              this.$router.push({ name: 'dashboard' }).catch((e) => {
                console.log(e)
              })
            } else {
              console.log('user signed out')
            }
          })

          App.Firebase.auth()
            .signInWithCustomToken(token)
            .then(() => {
              console.log('Signed in')
            })
            .catch((error) => {
              var errorCode = error.code
              var errorMessage = error.message
              console.log(errorCode, errorMessage)
            })
        })
    },

    closePasswordResetDialog: function () {
      if (this.$refs.form.validate()) {
        this.PWResetDialog = false
        this.sendPasswordReset(this.resetEmail)
      }
    },

    // TODO: login.vue and reauth.vue have practically identical functions like this.
    sendPasswordReset: async function (email) {
      this.spinner = true
      this.sendingRequest = true
      try {
        await CloudClient.call_CFsendPasswordReset(App.Firebase, email)
        this.sendingRequest = false
        this.spinner = false
        await this.$refs.alert.open(
          'Success',
          {
            line1: 'A password reset email was sent to ' + email,
          },
          { color: this.colors.mixStage_main }
        )
      } catch (error) {
        this.sendingRequest = false
        this.spinner = false
        await this.$refs.alert.open(
          'Error',
          {
            line1: `Could not send password reset email (${error.message})`,
          },
          { color: this.colors.mixStage_main }
        )
      }
    },

    pushToCorrectLocation() {
      if (this.$route.query.token) {
        this.loginWithExternallyGeneratedToken(this.$route.query.token)
      }
      if (this.currentUser) {
        if (this.$route.query.hash) {
          this.$router
            .push({ name: 'screeningroom', query: this.$route.query })
            .catch((e) => {
              console.log(e)
            })
        } 
        else {
          this.$router.push({ name: 'dashboard', query: this.$route.query }).catch((e) => {
            console.log(e)
          })
        }
      }
    },
  },
  mounted() {
    
    CloudClient.call_wakeUpAllCloudFUnctions(App.Firebase).then(() => {
      console.log('All cloud functions are awake')
    })
    this.pushToCorrectLocation()
    console.log(this.$route.query)
    // The user came here because they tried to signup but already has an account.
    if (this.$route.params.email) {
      this.email = this.$route.params.email
      this.$nextTick(function () {
        this.$refs.passwordField?.focus()
        this.usernameFieldHint = 'You already have an account, log in instead.'
      })
    } else {
      // Need this instead of autofocus on username field, which interferes.
      this.$nextTick(this.$refs.usernameField?.focus)
    }
  },
}
</script>

<style scoped lang="scss">

img {
  float: none;
  width: 90%;
  max-width: 280px;
  margin: auto;
}

input {
  margin: 5px 0;
  width: 20%;
  min-width: 240px;
  height: 25px;
  padding: 15px;
  font-family: 'Inter', sans-serif;
}

button {
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}

p {
  font-family: 'Inter', sans-serif;
  margin-top: 40px;
  font-size: 14px;
}

p a {
  font-family: 'Inter', sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.mobile-style {
  padding-top: 10px !important;
  margin-top: 0 !important;
  background: #eeeeee !important;
  border: none !important;
}
@media only screen and (max-width: 400px) {
  .mobile-style {
    padding-top: 10 !important;
  }
}
</style>
