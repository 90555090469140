<template>
  <v-row
    no-gutters
    align="center"
    justify="center"
    transition="scale-transition"
    :style="{'max-width': '1024px'}"
    v-show="UI_showVideoChatAndSeats"
  >
  <div class="chairs" :style="{ 'margin-top': marginTop }">
      <div v-for="(row) in visibleSeatings" :key="row[0]" class="chair-row" :class="`chair-row-${rowStyle}`">
      <div
            v-for="(userId, index) in row" 
            :key="`${userId}-${index}`"
        >
          <div 
            v-if="userId !== false"
            ref="chairElements"
            class="chair"
          >
          <div class="tooltip-container" v-if="isUserModerator" @click="$emit('request-open-people-panel')">
              <i class="material-icons remove-chair-icon"
                :style="{
                  'color': colors.white, 
                  'background': colors.mx.secondary,
                  'border-radius': '50%',
                  'padding': '4px',
                  'position': 'absolute', 
                  'z-index': 3, 
                  'margin-left': rowStyle <= 5 ? '20%' : '28%', 
                  'margin-top': rowStyle <= 5 ? '10%' : '5%', 

                  'font-size': rowStyle <= 5 ? '20px' : '20px'
                }">
                group
              </i>
              <div class="tooltip-content bottom" style="margin-top: 0; width: max-content; display: flex; justify-content: center; align-items: center;">
                <span style="font-size: 0.625rem"> Manage team access</span>
              </div>
            </div>
            <div
              v-if="chairHasUser(userId) && getUser(userId) && getUser(userId).shared && getUser(userId).shared.tab_to_talk"
              :style="{ backgroundImage: 'url(' + logos.mic + ')' }"
              style="position: absolute;
                right: -5%;
                top: -10%;
                z-index: 10;
                width: 50%;
                height: 40%;
                background-position: center;
                /* background-size: contain; */
              "
            ></div>
            <videoBubble
            
              v-if="chairHasUser(userId)"
              :user="getUser(userId)"
              :activeInSession="getUser(userId).shared.active"
              :chairHasUser="chairHasUser(userId)"
              :talking="getUser(userId).streams.isTalking"
              :videoStream="getUser(userId).streams.videoStream"
              :audioMuted="getUser(userId).shared.audio_muted"
              :videoMuted="showVideoMutedCircle(userId)"
              :isMe="getUser(userId).isMe"
              :inAVSettings="getUser(userId).shared.in_bathroom_mirror"
              :useRect="true"
              
            />

            <div class="daw-stream-packet-warning"
              v-if="templateReactiveData.isUserModerator && getUser(userId).shared && getUser(userId).shared.daw_stream_drop_warning > 0"
              :class="`severity-${getUser(userId).shared.daw_stream_drop_warning}`"
              @click="$emit('request-reset-and-log-packages-lost')"
            />

            <div class="active-spinner-wrapper" v-if="showSpinner(userId)">
              <mixStageSpinner />
            </div>

            <div class="chair-user-info">
              <div v-if="!(getUser().user_id === templateReactiveData.ui.selectedStreamer)">
                <div class="tooltip-container" v-if="chairHasUser(userId)">
                  <div class="name-tag noSelect name-tag-dark" :class="{ 'name-tag-compact': useCompactNameTags }">
                    {{ chairHasUser(userId) && getUserName(getUser(userId), useCompactNameTags) }}
                    <span class="tooltip-content top">{{ getUserName(getUser(userId), useCompactNameTags) }}</span>
                  </div>
                </div>
              </div>  

              <div
                v-if="templateReactiveData.isUserModerator && chairHasUser(userId)"
                :style="{ background: colors.tooltipBackground }"
                :class="[
                  (getUser(userId).shared.buffering || getUser(userId).shared.seeking || getUser(userId).shared.can_play_through === false)
                    ? 'visible'
                    : 'hidden',
                ]"
                class="buffering-badge"
              >
                *
              </div>
            </div>
            <audio
              ref="dawStreamPlayer"
              autoplay
              playsinline
              controls
              style="width: 100px; height: 100px; display: none"
              :src-object.prop.camel="getUser(userId).streams.dawStream"
            ></audio>
          </div>
        </div>
      </div>
      </div>
  </v-row>
</template>

<script>
// @ts-nocheck
import { ref, computed, inject, onBeforeUnmount } from 'vue'
import videoBubble from '../videoBubble'
import mixStageSpinner from '@/components/dashboard/mixStageSpinner.vue'
import Constants from '@/../source_files/spotterfish_library/consts'
const logos = require('@/lib/ui/logos.js').logos
const colors = require('@/lib/ui/colors.js').colors


export default {
  components: {
    videoBubble,
    mixStageSpinner
  },
  setup(props, { emit }) {
    // Injects
    const dawStreamUpdates = inject('dawStreamUpdates')
    const templateReactiveData = inject('templateReactiveData')
    const UIUsermap = inject('UIUsermap')
    const lightsOff = ref(false)
    const on = ref('')
    const isWatchingScreenshare = ref(false)
    const screenshareWindow = ref(undefined)
    const dawsStreamerMuted = ref (false)
    const dawStreamPlayer = ref(null)

    const isUserModerator = computed(() => templateReactiveData.value?.isUserModerator)
    const UI_showVideoChatAndSeats = computed(() => templateReactiveData.value?.UI_showVideoChatAndSeats)
    const useCompactNameTags = computed(() => visibleSeatings.value.value > 5)

    const visibleSeatings = computed(()=> {
      if (templateReactiveData.value?.seatings === undefined) return []
      const visible = templateReactiveData.value.seatings.filter(seat => seat !== false)
      const split = templateReactiveData.value.videochatOnly ? 5 : 10
      const firstRow = visible.slice(0, split)
      const secondRow = visible.slice(split, 2*split)
      const thirdRow = visible.slice(2*split, 3*split)
      const fourthrow = visible.slice(3*split, 4*split)

      if (secondRow.length === 0) {
        return [firstRow]
      } 
      else {
        if (thirdRow.length === 0) {
          return [firstRow, secondRow]
        }
        else {
          if (fourthrow.length === 0) {
            return [firstRow, secondRow, thirdRow]
          }
          else {
            return [firstRow, secondRow, thirdRow, fourthrow]
          }
        }
      }
    })
    
   
    const computeRowStyle = (visibleSeats) => {
    const totalVisibleSeats = visibleSeats.reduce((acc, row) => acc + row.length, 0);
    if (totalVisibleSeats >= 12) return 12;
    if (totalVisibleSeats >= 9) return 11;
    if (totalVisibleSeats >= 6) return 8;
    return 6;
};

// Computes the margin-top for .chairs based on the row style
const computeMarginTop = (rowStyle) => {
    switch (rowStyle) {
        case 6:
            return '4rem';
        case 8:
            return '4rem';
        case 11:
            return '3rem';
        case 12:
            return '3rem';
        default:
            return '4rem';
    }
};

  // Row style computed property
  const rowStyle = computed(() => computeRowStyle(visibleSeatings.value));

  // Margin top computed property
  const marginTop = computed(() => computeMarginTop(rowStyle.value));

    
    // console.log("rowstyle:", visibleSeatings.value[0].length + visibleSeatings.value[1].length );

    const chairStyle = computed(() => {
      const width = `${templateReactiveData.value.chairStyle.width}px`
      const height = `${templateReactiveData.value.chairStyle.height}px`
      return {
        width: width,
        height: height,
        minHeight: height,
        minWidth: width,
        flexBasis: width,
      }
    })
    

    const export_setDawStreamerMutedState = (val) => {
      if (dawStreamPlayer.value && dawStreamPlayer.value.length > 0) {
        const audioElement = dawStreamPlayer.value[0]
        audioElement.muted = val
      }
    }

    const getUser = (userId) => {
      return UIUsermap.value[userId] || {}
    }
      

    const getDawStreamUpdate = (userId) => {
      return dawStreamUpdates.value[userId] || undefined
    }

    const getDAWStreamTimeCodeDisplay = (userId) => {
      return dawStreamUpdates.value[userId] ? dawStreamUpdates.value[userId].html_output_receivedTimeCodeDisplayValue : '--:--:--:--  @0fps';
    }

    const chairHasUser = (userId) => {
        return Boolean(userId) && (isUserId(userId) || isEmail(userId))
    }

    const isUserId = (value) => {
        return typeof value === 'string' && !isEmail(value)
    }

    const isEmail = (value) => {
        const a = Constants.EMAIL_REGEX.test(value)
        return a
    }

    const chairElementByUserId = (userId) => {
      return document.querySelector(`.chair[data-user-id="${userId}"]`)
    }


    const userIsConnected = (userId) => {
      const isConnected = Boolean(templateReactiveData.value?.ui.users[userId]?.active)
      return isConnected
    }

    // const userStatus = (_UIUsermap, userId, myId) => {
    //   return {
    //     userName: _UIUsermap.value[userId].shared.user_name,
    //     userEmail: _UIUsermap.value[userId].shared.user_email,
    //     connected: _UIUsermap.value[userId].shared.active,
    //     videoChatReady: _UIUsermap.value[userId].shared.video_chat_ready,
    //     videoMuted: _UIUsermap.value[userId].shared.video_muted,
    //     audioMuted: _UIUsermap.value[userId].shared.audio_muted,
    //     inBathroomMirror: _UIUsermap.value[userId].shared.in_bathroom_mirror,
    //     tabToTalk: _UIUsermap.value[userId].shared.tab_to_talk,
    //     isMe: userId === myId,
    //     isDawStreamer: _UIUsermap.value[userId].shared.daw_streamer,
    //     isDawStreamReady: _UIUsermap.value[userId].shared.daw_stream_ready,
    //     hasVideoStream: Boolean(_UIUsermap.value[userId].streams.videoStream),
    //     hasDaweStream: Boolean(_UIUsermap.value[userId].streams.dawStream),
    //     hasAudioStream: Boolean(_UIUsermap.value[userId].streams.audioStream),
    //     hasScreenShareStream: Boolean(_UIUsermap.value[userId].streams.screenshare),
    //   }
    // }

    const showVideoMutedCircle = (userId) => {
      const a = chairHasUser(userId)
              && userIsConnected(userId)
              && (!UIUsermap.value[userId].streams.videoStream || UIUsermap.value[userId].shared.video_muted || !UIUsermap.value[userId].shared.video_chat_ready)
      // console.log(chairHasUser(userId), userIsConnected(userId), getUser(userId).streams.videoStream, getUser(userId).shared.video_muted, getUser(userId).shared.video_chat_ready)
      return a
    }

    const getUserInitials = (userId) => {
      const user = UIUsermap.value[userId]
      if(user?.user_name){
        const initials = user.user_name.split(' ').map(part => part[0])
        return initials.join('')
      }
      return ''
    }

    const getUserAvatar = (userId) => {
      const user = UIUsermap.value[userId]
      if (user?.pict) {
          return null;
      }
      if (isEmail(userId)) {
          return logos.invitedImage
      }
      return logos.invitedImage
    }


    const getSeatIndexByUserId = (userId) => {
        return templateReactiveData.value.seatings.findIndex(seat => seat.userId === userId)
    }

    const getUserName = (user, onlyFirstName = false) => {
      if(user.user_name && !onlyFirstName){
        return user.user_name
      }
      if(onlyFirstName && user.user_name){
        return user.user_name.split(' ')[0]
      }
      return user.user_id
    }

    const showSpinner = (userId) => {
      if (UIUsermap.value[userId].shared.active) {
        if (getUser(userId).shared.in_bathroom_mirror === true || !getUser(userId).shared.video_chat_ready) {
          return true
        }
      }
      return false
    }


    const setEnabledStateForAllTracks = (shouldEnable) => {
      return new Promise((resolve, reject) => {

        Object.values(UIUsermap.value).forEach(user => {
          user.streams?.videoStream?.getAudioTracks().forEach((track) => {
            track.enabled = shouldEnable
          })
          user.streams?.videoStream?.getVideoTracks().forEach((track) => {
            track.enabled = shouldEnable
          })
        })

        resolve()
      })
    }

    
   


    const endWatchingScreenshare = () => {
      isWatchingScreenshare.value = false
      if (screenshareWindow.value) {
        screenshareWindow.value.close()
        screenshareWindow.value = undefined
      }
    }

    onBeforeUnmount(() => {
      endWatchingScreenshare()
    })

    // You can directly return the reactive state if you prefer not to use toRefs
    // Note: This will not break reactivity inside the template for the state object itself
    // But keep in mind, destructuring `state` in setup and returning destructured properties would lose reactivity
    // toRefs is useful when you need to destructure and maintain reactivity

    return {
      dawStreamUpdates,
      templateReactiveData,
      logos,
      colors,
      lightsOff,
      on,
      isWatchingScreenshare,
      screenshareWindow,
      isUserModerator,
      dawsStreamerMuted,
      dawStreamPlayer,
      export_setDawStreamerMutedState,
      getDawStreamUpdate,
      getDAWStreamTimeCodeDisplay,
      chairHasUser,
      isUserId,
      isEmail,
      chairElementByUserId,
      showVideoMutedCircle,
      getUserInitials,
      getUserAvatar,
      getSeatIndexByUserId,
      getUserName,
      showSpinner,
      setEnabledStateForAllTracks,
      UI_showVideoChatAndSeats,
      useCompactNameTags,
      visibleSeatings,
      rowStyle,
      chairStyle,
      getUser,
      endWatchingScreenshare,
      marginTop
    }
  }
}



</script>

<style lang="scss">

@import '@/assets/global.scss';


/* Other styles */ 
.daw-stream-packet-warning {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 60px;
  left: calc(50% - 10px);
  border-radius: 50%;
  &.severity-2{
    background: red;
  }
  &.severity-1{
    background: yellow;
  }
}
.add-to-chair-icon {
  top: 30%;
  z-index: 100;
}

.chair-user-info {
  position: absolute;
  margin-top: 0.5rem;
  width: 100%;
}

:hover.add-to-chair-icon {
  transform: scale(1.1);
}




.name-tag {
  background: #ddd;
  color: black;
  font-size: clamp(12px, 1vw, 0.6rem);
  border: 2px solid #1a1a1a;
  border-radius: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  margin: 0 auto;
  padding: 0 4px;
  z-index: 1;
  white-space: nowrap;
}

.name-tag-dark {
  width: 100%;
  background: #1a1a1a;
  color: #ddd;
}

// Used for rooms with more than five seats, where space is tight.
.name-tag-compact {
  /* margin: 0 -10%; */
  font-size: clamp(12px, 1vw, 0.6rem);
}

.tab-to-talk-label {
  position: absolute;
  width: 100px;
  left: 50%;
  margin-left: -50px;
  top: 53px;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  text-shadow: 0 0 2px black;
}

/* General styles for spinner wrapper */
.active-spinner-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 2%;
}


.buffering-badge {
  border-radius: 50%;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  /* position: absolute;
  bottom: -8px; */
  z-index: 3;
  // margin-left: -4rem; /* Must be half the width */
}

.chair .remove-chair-icon {
  visibility: hidden;
}
.chair:hover .remove-chair-icon {
  cursor: pointer;
  visibility: visible;
}

.avatar-background {
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lights-off .chairs {
  display: none;
}

.chairs.hide .chair {
  display: none;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.chair-row {
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 1;
  display: flex;
  margin-bottom: 4px;
  width: 1024px;


  &.chair-row-5{
    min-height: 240px;
  }
  &.chair-row-11{
    min-height: 100px;
  }


  &.chair-row-11:last-child{
    margin-top: 2rem;
  }
  &.chair-row-12:last-child{
    margin-top: 2rem;
  }


}
/* Chairs */
.chairs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  flex-wrap: wrap;
  margin-top: 0;
  position: absolute;
}



.chair {
  height: 100%;
  min-height: 100%;
  background-color: darken($color-mx-dark-overlay, 1%);
  border-radius: 6px;
  margin: 2px 4px;
  position: relative;
  z-index: 2;
  aspect-ratio: 135 / 170;
  // background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;

}



.video-chat-only .chair {
  width: 180px; /* Adjust width for 1-5 chairs */
  height: 180px; /* Adjust width for 1-5 chairs */
}

.video-chat-only .chair-row-8 .chair {
  width: 160px; /* Adjust width for 6-8 chairs */
  height: 160px; /* Adjust height for 6-8 chairs */
}

.video-chat-only .chair-row-11 .chair {
  width: 140px; /* Adjust width for 9-11 chairs */
  height: 140px; /* Adjust height for 9-11 chairs */
}

/* Adjust width & height for 12 or more chairs */
.video-chat-only .chair-row-12 .chair {
  width: 60px;
  height: 60px;
}

// default 
.chair-row-8 .chair {
  width: 120px; // Adjust width for 6-7 chairs
  height: 120px; // Adjust height for 6-7 chairs
}
.chair-row-11 .chair {
  width: 90px; // Adjust width for 8-10 chairs
  height: 90px; // Adjust height for 8-10 chairs
}
/* Adjust width & height for 11 or more chairs */
.chair-row-12 .chair {
  width: 60px;
  height: 60px;
}

// very small screens
@media screen and (min-width: 900px) and (min-height: 600px) {

  .chair {
    width: 140px; // Adjust width for 6-7 chairs
    height: 140px; // Adjust height for 6-7 chairs
  }

  .chair-row-8 .chair {
    width: 100px; // Adjust width for 6-7 chairs
    height: 100px; // Adjust height for 6-7 chairs
  }
  .chair-row-11 .chair {
    width: 80px; // Adjust width for 8-10 chairs
    height: 80px; // Adjust height for 8-10 chairs
  }
  /* Adjust width & height for 11 or more chairs */
  .chair-row-12 .chair {
      width: 60px;
      height: 60px;
  }
}



  //ipad mini has width of 1133px
@media screen and (min-width: 1024px) and (min-height: 740px) {

  .chair {
    width: 220px; // Adjust width for 6-7 chairs
    height: 220px; // Adjust height for 6-7 chairs
  }

  .chair-row-8 .chair {
    width: 120px; // Adjust width for 6-7 chairs
    height: 120px; // Adjust height for 6-7 chairs
  }
  .chair-row-11 .chair {
    width: 90px; // Adjust width for 8-10 chairs
    height: 90px; // Adjust height for 8-10 chairs
  }
  /* Adjust width & height for 11 or more chairs */
  .chair-row-12 .chair {
      width: 96px;
      height: 96px;
  }
}

.video-chat-only .chairs{
  gap: 2rem;
}
.video-chat-only .chair-row-8 .chairs{
  gap: 4rem;
}
.video-chat-only .chair-row {
  &.chair-row-11{
  margin-bottom: 2rem;
  }
}

// Normal screens

@media screen and (min-width: 1536px) and (min-height: 600px) {


.chair {
  width: 240px; // Adjust width for 6-7 chairs
  height: 240px; // Adjust height for 6-7 chairs

}
.chair-row-8 .chair {
  width: 180px; // Adjust width for 6-7 chairs
  height: 180px; // Adjust height for 6-7 chairs
}
.chair-row-11 .chair {
  width: 140px; // Adjust width for 8-10 chairs
  height: 140px; // Adjust height for 8-10 chairs
}
/* Adjust width & height for 11 or more chairs */
.chair-row-12 .chair {
    width: 120px;
    height: 120px;
}

.video-chat-only .chair {
  width: 220px; /* Adjust width for 1-5 chairs */
  height: 220px; /* Adjust width for 1-5 chairs */
}

.video-chat-only .chair-row-8 .chair {
  width: 270px; /* Adjust width for 6-8 chairs */
  height: 270px; /* Adjust height for 6-8 chairs */
}

.video-chat-only .chair-row-11 .chair {
  width: 210px; /* Adjust width for 9-11 chairs */
  height: 210px; /* Adjust height for 9-11 chairs */
}

/* Adjust width & height for 12 or more chairs */
.video-chat-only .chair-row-12 .chair {
  width: 130px;
  height: 130px;
}
.chair-row {
  width: 1792px;
}


.chair-row-5{
  min-height: 270px;
}
.chair-row-11{
  min-height: 210px;
}


.chair-row-11:last-child{
  margin-top: 2.5rem;
}
.chair-row-12:last-child{
  margin-top: 2.5rem;
}

}


@media screen and (min-width: 1536px) and (min-height: 900px) {

  .chair {
    width: 240px; // Adjust width for 6-7 chairs
    height: 240px; // Adjust height for 6-7 chairs

  }
  .chair-row-8 .chair {
    width: 180px; // Adjust width for 6-7 chairs
    height: 180px; // Adjust height for 6-7 chairs
  }
  .chair-row-11 .chair {
    width: 140px; // Adjust width for 8-10 chairs
    height: 140px; // Adjust height for 8-10 chairs
  }
  /* Adjust width & height for 11 or more chairs */
  .chair-row-12 .chair {
      width: 120px;
      height: 120px;
  }

  .video-chat-only .chair {
    width: 220px; /* Adjust width for 1-5 chairs */
    height: 220px; /* Adjust width for 1-5 chairs */
  }

  .video-chat-only .chair-row-8 .chair {
    width: 270px; /* Adjust width for 6-8 chairs */
    height: 270px; /* Adjust height for 6-8 chairs */
  }

  .video-chat-only .chair-row-11 .chair {
    width: 210px; /* Adjust width for 9-11 chairs */
    height: 210px; /* Adjust height for 9-11 chairs */
  }

  /* Adjust width & height for 12 or more chairs */
  .video-chat-only .chair-row-12 .chair {
    width: 165px;
    height: 165px;
  }
  .chair-row {
    width: 1792px;
  }

  .chair-row {
    width: 2048px;
  }


  .chair-row-5{
    min-height: 270px;
  }
  .chair-row-11{
    min-height: 210px;
  }


  .chair-row-11:last-child{
    margin-top: 2.5rem;
  }
  .chair-row-12:last-child{
    margin-top: 2.5rem;
  }

}


// Extra large screens
@media screen and (min-width: 2048px) {

  .chair {
    width: 300px; // Adjust width for 6-7 chairs
    height: 300px; // Adjust height for 6-7 chairs

  }
  .chair-row-8 .chair {
    width: 220px; // Adjust width for 6-7 chairs
    height: 220px; // Adjust height for 6-7 chairs
  }
  .chair-row-11 .chair {
    width: 160px; // Adjust width for 8-10 chairs
    height: 160px; // Adjust height for 8-10 chairs
  }
  /* Adjust width & height for 11 or more chairs */
  .chair-row-12 .chair {
      width: 140px;
      height: 140px;
  }
  .video-chat-only .chair {
    width: 360px; /* Adjust width for 1-5 chairs */
    height: 360px; /* Adjust width for 1-5 chairs */
  }

  .video-chat-only .chair-row-8 .chair {
    width: 320px; /* Adjust width for 6-8 chairs */
    height: 320px; /* Adjust height for 6-8 chairs */
  }

  .video-chat-only .chair-row-11 .chair {
    width: 240px; /* Adjust width for 9-11 chairs */
    height: 240px; /* Adjust height for 9-11 chairs */
  }

  /* Adjust width & height for 12 or more chairs */
  .video-chat-only .chair-row-12 .chair {
    width: 180px;
    height: 180px;
  }

  .chair-row {
    width: 2048px;
  }


  .chair-row-5{
    min-height: 240px;
  }
  .chair-row-11{
    min-height: 240px;
  }


  .chair-row-11:last-child{
    margin-top: 2.5rem;
  }
  .chair-row-12:last-child{
    margin-top: 2.5rem;
  }

}



// Extremely large screens
@media screen and (min-width: 4096px) {

  .chair {
    width: 600px; // Adjust width for 6-7 chairs
    height: 600px; // Adjust height for 6-7 chairs

  }
  .chair-row-8 .chair {
    width: 400px; // Adjust width for 6-7 chairs
    height: 400px; // Adjust height for 6-7 chairs
  }
  .chair-row-11 .chair {
    width: 320px; // Adjust width for 8-10 chairs
    height: 320px; // Adjust height for 8-10 chairs
  }
  /* Adjust width & height for 11 or more chairs */
  .chair-row-12 .chair {
      width: 200px;
      height: 200px;
  }

  .video-chat-only .chair {
    width: 720px; /* Adjust width for 1-5 chairs */
    height: 720px; /* Adjust width for 1-5 chairs */
  }

  .video-chat-only .chair-row-8 .chair {
    width: 640px; /* Adjust width for 6-8 chairs */
    height: 640px; /* Adjust height for 6-8 chairs */
  }

  .video-chat-only .chair-row-11 .chair {
    width: 480px; /* Adjust width for 9-11 chairs */
    height: 480px; /* Adjust height for 9-11 chairs */
  }

  /* Adjust width & height for 12 or more chairs */
  .video-chat-only .chair-row-12 .chair {
    width: 360px;
    height: 360px;
  }

  .chair-row {
    width: 4096px;
  }


  .chair-row-5{
    min-height: 720px;
  }
  .chair-row-11{
    min-height: 480px;
  }


  .chair-row-11:last-child{
    margin-top: 2.5rem;
  }
  .chair-row-12:last-child{
    margin-top: 2.5rem;
  }

}



.video-chat-only .chair-row {
  &.chair-row-11:last-child{
  margin-top: 0;
}
&.chair-row-12:last-child{
  margin-top: 0;
}
}


</style>