<template>
    <v-bottom-navigation
      class="px-5 py-5 dark-panel"
      fixed
      bottom
      style="z-index: 0; border-top: 2px solid black;"
    >
    <div class="text-center w-100" style="color: white;">
                <!-- © {{ new Date().getFullYear() }} — <strong>Spotterfish AB</strong> -->
              </div>
    </v-bottom-navigation>
</template>

<script>
//@ts-nocheck

export default {
  data(){
    return {
      colors: require('@/lib/ui/colors.js').colors,
    }
  },
  methods: {
    
  }
}
</script>