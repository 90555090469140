<template>
  <v-container
    fluid
    class="outer-container"
    v-shortkey.once="{
      debugBitrate: ['ctrl', 'shift', 'd'],
    }"
    @shortkey="setShowBitrateDebug"
  >
    <chairs
      @request-send-data="sendData"
      @request-open-people-panel="$emit('request-open-people-panel', $event)"
      @request-reset-and-log-packages-lost="resetDroppedOffset()"
      ref="chairsRef"
    />
    <!-- <v-btn @click="listRooms" style="margin-top: 7rem;">List rooms</v-btn> -->
    <v-snackbar v-model="showSnackbar" top :color="colors.mx.secondary">
      <div
        style="
          width: 100%;
          text-align: center;
          position: relative;
          z-index: 1000;
        "
      >
        {{ snackbarText }}
      </div>
    </v-snackbar>
    <v-dialog
      modal
      fullscreen
      v-model="showSettings"
      v-if="showSettings"
      style="position: relative; z-index: 2147483647"
    >
      <bathroom-mirror-component
        :onCancel="onSettingsCancel"
        :onContinue="onSettingsContinue"
        :approved="true"
      />
    </v-dialog>
  </v-container>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import store from '@/store'
import bathroomMirror from '@/components/webRTCcomponents/bathroomMirror.vue'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import SpotterfishCore from '@/../source_files/spotterfish_library/SpotterfishCore'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import { setBathroomMirrorStatus } from '@/components/webRTCcomponents/videoChat/helpers'

import { checkAllowedInputs } from '@/../source_files/spotterfish_library/utils/VideoChatUtils'

import userControls from '@/components/webRTCcomponents/videoChat/userControls.vue'
import chairs from '@/components/webRTCcomponents/videoChat/chairs.vue'

import Vue from 'vue'
import { onMounted, nextTick, onBeforeMount, computed, watch, ref, reactive, inject, provide } from 'vue'

export default {
  components: {
    'bathroom-mirror-component': bathroomMirror,
    userControls,
    chairs,
  },
  props: [
    'spotterfishParams',
    'tabIsPressed'
  ],

  setup(props, { emit }) {
    const colors = require('@/lib/ui/colors.js').colors
    const logos = require('@/lib/ui/logos.js').logos
    // Initialization and reactive state declarations
    const userJoinedNotificationSound = new Audio(require('@/assets/block.mp3'))
    const userLeftNotificationSound = new Audio(require('@/assets/block.mp3'))
    const dawStreamNotificationSound = new Audio(require('@/assets/user_entered.mp3'))
    const screenshareNotificationSound = new Audio(require('@/assets/user_shared_screen.mp3'))

    let lastNotificationTime = 0
    const notificationCooldown = 5000

    const playNotificationSound = (sound) => {
      const currentTime = Date.now()
      if (currentTime - lastNotificationTime > notificationCooldown) {
        sound.play()
        lastNotificationTime = currentTime
      }
    }

    const chairsRef = ref()
    const templateReactiveData = inject('templateReactiveData')
    const nonReactiveState = inject('nonReactiveState')

    const screeningRoomSession = nonReactiveState.screeningRoomSessionNonReactive
    SpotterfishCore.assert(ScreeningRoomSession.checkInvariantScreeningRoomSession(screeningRoomSession))
    const dawStreamUpdates = ref({})
    const previousMuteState = ref(undefined)
    const showSnackbar = ref(false)
    const snackbarText = ref('')
    const showSettings = ref(false)
    const getCurrentUser = computed(() => {
      return store.state.currentSpotterfishUser
    })

    const shouldPlayJoinNotificationSound = computed(() => {
      return templateReactiveData.value.isUserModerator || templateReactiveData.value.noModerator
    })

    provide('dawStreamUpdates', dawStreamUpdates)

    const isAudioMuted = computed(() => {
      return templateReactiveData.value.refinedSharedState?.users?.[getCurrentUser.value.user_id]?.audio_muted === true
    })

    const isVideoMuted = computed(() => {
      return templateReactiveData.value.refinedSharedState?.users?.[getCurrentUser.value.user_id]?.video_muted === true
    })

    // mute state is handled by sharedstate since we need to allow muteall etc. We never call these functions
    // except from this watcher, so we can safely assume that the state is correct.
    watch(
      () => isAudioMuted.value,
      (newValue) => {
        if (newValue !== undefined) {
          console.log('watcher detected change in shared state, setting audio mute', newValue)
          screeningRoomSession.videoChat.setAudioMute(newValue)
        }
      }
    )
    watch(
      () => isVideoMuted.value,
      (newValue) => {
        if (newValue !== undefined) {
          console.log('watcher detected change in shared state, setting video mute', newValue)
          screeningRoomSession.videoChat.setVideoMute()
        }
      }
    )

    watch(() => props.tabIsPressed, newValue => {
      console.log('TAB PRESSED:', newValue)
      if (newValue) {
        previousMuteState.value = isAudioMuted.value
        if (!isAudioMuted.value) {
          return
        }
        toggleAudioMute(false)
      } else {
        // Tab to talk was inactivated, switch tab to talk in shared state.
        // Restore previous mute state.
        if (previousMuteState.value !== isAudioMuted.value) {
          toggleAudioMute(previousMuteState.value)
        }
      }
    })

    const restoreLocalMuteState = () => {
      // Update the Janus instance mute state from the session mute state for this user.
      screeningRoomSession.videoChat.setAudioMute(
        isAudioMuted.value || templateReactiveData.value.refinedSharedState.mute_all
      )
      if (isVideoMuted.value === true) {
        screeningRoomSession.videoChat.unpublishOwnFeed()
      }
    }

    const setShowBitrateDebug = async () => {
      const { myFeed, users, room } =
        await screeningRoomSession.videoChat.debugBitrate()
      console.warn(users)
      console.warn('My feed', myFeed.getBitrate())
      console.warn('Room', room)
      console.warn('------')
      Object.entries(users).forEach(([userId, user]) => {
        const connectedUser = getConnectedUsers.value.find(
          (u) => u.user_id === userId
        )
        if (user.feed) {
          console.warn(
            'Feed bitrate: ',
            connectedUser.user_name,
            user.feed.getBitrate()
          )
        }
        if (user.dawStreamFeed) {
          console.warn(
            `Dawstreamer ${connectedUser.user_name} bitrate: `,
            user.dawStreamFeed.getBitrate()
          )
        }
      })
    }

    const export_setDawStreamerMutedState = (flag) => {
      chairsRef.value.export_setDawStreamerMutedState(flag)
    }
    const sendData = (data) => {
      screeningRoomSession.videoChat.sendData(data)
    }
    const resetDroppedOffset = () => {
      screeningRoomSession.videoChat.resetDroppedOffset()
    }


    const muteEveryone = () => {
      if (templateReactiveData.value.refinedSharedState?.users) {
        // Collect all updates and write them all in one go.
        const updates = {}
        for (const user of Object.values(templateReactiveData.value.refinedSharedState.users)) {
          updates[
            `shared_state/${templateReactiveData.value.screeningRoomDBID}/users/${user.user_id}/audio_muted`
          ] = true
        }
        ScreeningRoomSession.updateRoomSharedState(screeningRoomSession, {
          mute_all: true,
        })
        App.Firebase.database().ref().update(updates)
      }
    }

    // If called without an argument, flip the current mute state, otherwise set the
    // state according to the passed argument.
    const toggleAudioMute = (shouldMute) => {
      console.log('toggleAudioMute called with', shouldMute, 'isAudioMuted', isAudioMuted.value)
      const muteFlag = shouldMute === undefined ? !isAudioMuted.value : shouldMute
      console.log('toggleAudioMute called with', muteFlag)      
      props.spotterfishParams.onRequestUserMute(muteFlag)
    }

    const openBathroomMirror = async () => {
      setBathroomMirrorStatus(
        screeningRoomSession,
        getCurrentUser.value.user_id,
        true
      )

      document.querySelectorAll('.chairs')[0].classList.add('hide')

      try {
        await screeningRoomSession.videoChat.unpublishOwnFeed()
      } catch (error) {
        console.log('Error unpublishing own feed, possibly not active', error)
      }
      try {
        await chairsRef.value.setEnabledStateForAllTracks(false)
      } catch (error) {
        console.log('Error disabling all tracks, possibly not active', error)
      }
      emit('bathroom-mirror-status', true)
      showSettings.value = true
    }

    const afterCloseSettings = async () => {
      await chairsRef.value.setEnabledStateForAllTracks(true)
      emit('bathroom-mirror-status', false)

      setBathroomMirrorStatus(
        screeningRoomSession,
        getCurrentUser.value.user_id,
        false
      )

      const { video: videoAllowed, audio: audioAllowed } =
        await checkAllowedInputs()
      screeningRoomSession.videoChat.publishOwnFeed(
        audioAllowed,
        videoAllowed && !isVideoMuted.value,
        () => {
          screeningRoomSession.videoChat.setAudioMute(isAudioMuted)
        }
      )
      syncUserMediaOut()
      document.querySelectorAll('.chairs')[0].classList.remove('hide')
    }

    const syncUserMediaOut = async () => {
      try {
        const selectedAudioOut =
          localStorage.getItem('spotterfishUserAudioOut') || 'default'
        const mediaElements = document.querySelectorAll('audio, video')
        await Promise.all(
          [...mediaElements].map((element) =>
            element.setSinkId(selectedAudioOut)
          )
        )
      } catch (error) {
        console.log(error)
      }
    }

    const onSettingsCancel = () => {
      afterCloseSettings()
      showSettings.value = false
    }

    const onSettingsContinue = () => {
      afterCloseSettings()
      showSettings.value = false
    }

    const handleDawStreamingChunk = (chunk, streamingUser, jitterbufferOffset, timeStamp) => {
      // Added guards for session closure
      if (screeningRoomSession?.masterClock?.mediaSyncSource !== 'daw_streamer') {
        return false
      }

      const isPackagedFromSelectedStreamer = streamingUser.uid === templateReactiveData.value.refinedSharedState.streaming_user

      if (!isPackagedFromSelectedStreamer) return

      // Actual sync logic
      const syncData = ScreeningRoomSession.unpackDAWStreamerPackage(chunk)
      if (!syncData) return

      props.spotterfishParams.onDAWStreamPackage(chunk.timestamp, syncData, streamingUser, jitterbufferOffset, timeStamp)

      // Preparing the UI update
      const html_output_DAWplaying = syncData ? !!syncData.playing : false
      let tc_print = '--no DAW playinfo --'
      if (syncData.playInfo) {
        const frateNick = Timecode.getNicknameFromRateIndex(syncData.projectFRK)
        const receivedTimeCodeTruncated = Timecode.timecodePartsToSMPTEString4(syncData.playInfo.tc)
        tc_print = `${receivedTimeCodeTruncated} @ ${frateNick}`
      }

      // Update DAW stream updates for UI
      Vue.set(dawStreamUpdates.value, streamingUser.uid, {
        html_output_DAWplaying,
        html_output_receivedTimeCodeDisplayValue: tc_print,
      })
    }

    const addVideoChatCallbacks = () => {
      screeningRoomSession.videoChat.addCallback('onSuccess', () => {
        console.log('SUCCESS')
      })
      screeningRoomSession.videoChat.addCallback('onLocalStream', () => {
        restoreLocalMuteState()
      })
      
      screeningRoomSession.videoChat.addCallback(
        'onRemoteFeed',
        (display, rfid) => {
          if (display?.dawstream) {
            const currentUserID =
              screeningRoomSession.screeningRoomConnection
                .spotterfishSession.userSession.firebaseCurrentUser.uid
            if (display.uid == currentUserID) {
              ScreeningRoomSession.updateUserSharedState(
                screeningRoomSession,
                { daw_stream_ready: true },
                display.uid
              )
            }
          }
          else if (display?.screenshare){
            ScreeningRoomSession.updateUserSharedState(
                screeningRoomSession,
                { screen_share_feed_connected: rfid ? rfid : Math.random() },
                display.uid
              )
          }
          ScreeningRoomSession.updateUserSharedState(
              screeningRoomSession,
              { remote_feed_connected_trigger: rfid ? rfid : Math.random() },
              display.uid
            )
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onDawStreamingChunk',
        handleDawStreamingChunk
      )
      screeningRoomSession.videoChat.addCallback(
        'onUserJoined',
        (newUser) => {
          if (newUser.uid !== getCurrentUser.value.user_id) {
            if (newUser.dawstream) {
              dawStreamNotificationSound.volume = 0.5
              dawStreamNotificationSound.play()
            } else if (newUser.screensharing) {
              screenshareNotificationSound.volume = 0.3
              screenshareNotificationSound.play()
            } else {
              if (shouldPlayJoinNotificationSound.value === true) {
                userJoinedNotificationSound.volume = 0.3;
                playNotificationSound(userJoinedNotificationSound);
              }
            }
          }
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onUserLeft',
        (userLeftId) => {
          if (shouldPlayJoinNotificationSound.value === true) {
            userLeftNotificationSound.volume = 0.2;
            playNotificationSound(userLeftNotificationSound);
          }
          console.log('... USER LEFT ...', userLeftId)
          showSnackbar.value = true
          if (userLeftId === getCurrentUser.value.user_id) {
            console.warn(
              'Our main feed is now unpublished, should we close entire session???'
            )
          }
          snackbarText.value =
            userLeftId === getCurrentUser.value.user_id
              ? `One of your streams are no longer available in the session.`
              : `User ${templateReactiveData.value.refinedSharedState?.users?.[userLeftId]?.user_name} left`
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onFirstPublishOwnFeed',
        () => {
          console.log('===> After first publishOwnFeed')
          screeningRoomSession.videoChat.setAudioMute(false)
          screeningRoomSession.videoChat.setVideoMute(false)
          ScreeningRoomSession.updateUserSharedState(
            screeningRoomSession,
            { video_chat_ready: true }
          )
          restoreLocalMuteState()
        }
      )
      screeningRoomSession.videoChat.addCallback('dawStreamerLeft', () => {
        ScreeningRoomSession.updateUserSharedState(screeningRoomSession, {
          daw_stream_ready: false,
        })
        props.spotterfishParams.handleDawStreamerEnded()
      })
      screeningRoomSession.videoChat.addCallback(
        'screenshareEnded',
        () => {
          chairsRef.value.endWatchingScreenshare()
          ScreeningRoomSession.updateUserSharedState(
            screeningRoomSession,
            { video_chat_ready: true }
          )
          props.spotterfishParams.handleScreenshareEnded() 
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'dawstreamPacketDrop',
        (severity) => {
          ScreeningRoomSession.updateUserSharedState(
            screeningRoomSession,
            { daw_stream_drop_warning: severity }
          )
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onLocalScreenshare',
        (screenShareFlag) => {
          props.spotterfishParams.handleLocalScreenshareStarted(screenShareFlag)
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onLocalScreenshareEnded',
        (data) => {
          props.spotterfishParams.handleScreenshareEnded()
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'screenshareError',
        () => {
          chairsRef.value.endWatchingScreenshare()
          props.spotterfishParams.handleScreenshareEnded()
        }
      )
      screeningRoomSession.videoChat.addCallback(
        'onDataChannelMessage',
        (data) => {
          emit('data-channel-message-received', data)
        }
      )
    }

    onBeforeMount(async () => {
      addVideoChatCallbacks()

      // Set initial values to shared state based on allowed inputs
      const { video, audio } = await checkAllowedInputs()
      await ScreeningRoomSession.updateUserSharedState(
        screeningRoomSession,
        {
          video_muted: !video,
          audio_muted: templateReactiveData.value.refinedSharedState.mute_all ? true : !audio,
        }
      )
    })

    onMounted(() => {
      addVideoChatCallbacks()

      // Assuming videoChat is available in the screeningRoomSession object
      screeningRoomSession.videoChat.setAudioMute(false)
      screeningRoomSession.videoChat.setVideoMute(false)

      console.log('setting mute state in mounted')

      restoreLocalMuteState()

      if (templateReactiveData.value.refinedSharedState.mute_all) {
        ScreeningRoomSession.updateUserSharedState(screeningRoomSession, {
          audio_muted: true,
        })
      }
    })

    return {
      // Returning all the reactive states, refs, computed properties, and functions
      colors,
      logos,
      userJoinedNotificationSound,
      dawStreamNotificationSound,
      screenshareNotificationSound,
      templateReactiveData,
      screeningRoomSession,
      dawStreamUpdates,
      previousMuteState,
      showSnackbar,
      snackbarText,
      showSettings,
      isAudioMuted,
      isVideoMuted,
      // Exposing toggleAudioMute function in case it needs to be used elsewhere in the template
      toggleAudioMute,
      openBathroomMirror,
      afterCloseSettings,
      handleDawStreamingChunk,
      export_setDawStreamerMutedState,
      sendData,
      resetDroppedOffset,
      getCurrentUser,
      muteEveryone,
      setShowBitrateDebug,

      // bathroom mirror functions
      onSettingsCancel,
      onSettingsContinue,

      chairsRef,
    }
  }
}
</script>

<style lang="scss" scoped>
#audiopostr {
  overflow: hidden;
}

.outer-container {
  display: flex;
  height: 100%;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
}
</style>
