<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" v-if="message">
    <v-card style="text-align: left;">
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message.line1" class="pa-4">{{ message.line1 }}</v-card-text>
      <v-card-text v-show="!!message.line2" class="pa-4" style="font-weight: bold;"><h2>{{ message.line2 }}</h2></v-card-text>
      <v-card-text v-show="!!message.line3" class="pa-4">{{ message.line3 }}</v-card-text>
      <v-card-text v-show="!!message.line4" class="" style="font-style: italic;">{{ message.line4 }}</v-card-text>
      <v-card-text v-show="!!message.line5" class="pa-4">{{ message.line5 }}</v-card-text>
      <v-card-text v-show="!!message.date" class="" style="font-weight: bold;">{{ theDate }}</v-card-text>
      <v-card-text v-show="!!message.line6" class="pa-4">{{ message.line6 }}</v-card-text>
      <v-card-text>
        <div class="drop" @drop.prevent="emitFile($event.dataTransfer.files).then(() => {})" @dragover.prevent>
            <p>DROP LOCAL VIDEO FILE HERE TO LOAD IT IN THE PLAYER</p>

            <label for="file-input">
              <v-flex style="margin: auto; height: 2em; width: 22em; cursor: pointer;">
                <p style="margin-top: 3px; color: white;"><v-icon large>mdi-plus</v-icon></p>
              </v-flex>
            </label>
            <input id="file-input" type="file" @change="$emit('file-added', $event.target.files[0])" style="display: none">
          </div>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// @ts-nocheck

/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

const moment = require('moment')

export default {
  data: () => ({
    logos: require('@/lib/ui/logos.js').logos,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200
    }
  }),
  computed: {
    theDate () {
      const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
      moment.locale(getNavigatorLanguage())
      return moment(this.message.date).format('LLLL')
    }
  },
  methods: {
    open (title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
<style scoped>
.drop {
  color: white;
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 35px;
  text-align: center;
  vertical-align: top;
  background: black;
  border: 2px dashed #ccc;
  border-radius: 10px;
}
</style>
