const _ = require('lodash')
const UserFeatureBitsModule = require('./userFeatureBits')
const SpotterfishCore = require('./SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isObjectInstance = SpotterfishCore.isObjectInstance
const unitTestAssertThrowsError = SpotterfishCore.unitTestAssertThrowsError


// PURE FUNCTIONS -- MAYBE MOVE AND SHARE?

// These are DB rooms and use DBIDs as keys
function checkInvariantDBRooms (dbRooms) {
  assert(isObjectInstance(dbRooms))

  if (Object.keys(dbRooms).length > 0) {
    for (const [roomId, roomData] of Object.entries(dbRooms)) {
      assert(UserFeatureBitsModule.isAlphaNumericRoomKey(roomId))
      assert(isObjectInstance(roomData))
    }
  }

  return true
}


function prove_checkInvariantDBRooms () {
  unitTestAssert(checkInvariantDBRooms({}))
  unitTestAssert(checkInvariantDBRooms({'abcabcabcabcabcabcab': {}}))
  unitTestAssert(checkInvariantDBRooms({
    'abcabcabcabcabcabcab': {},
    'defdefdefdefdefdefde': {
      seats: 2
    }
  }))

  unitTestAssertThrowsError(checkInvariantDBRooms, [undefined])
}

// Check that UFB uses journal room IDs.
function checkUFBForJournalRoomIDs (ufb) {
  UserFeatureBitsModule.checkInvariantUFB(ufb)

  const rooms = UserFeatureBitsModule.getUFBRooms(ufb)
  for (const roomId of Object.keys(rooms)) {
    assert(UserFeatureBitsModule.isNumericRoomKey(roomId))
  }

  return true
}


function prove_checkUFBForJournalRoomIDs () {
  unitTestAssert(checkUFBForJournalRoomIDs(UserFeatureBitsModule.makeBlankUFB()))
  unitTestAssert(checkUFBForJournalRoomIDs(UserFeatureBitsModule.createUFB2(
    {
      user: {},
      rooms: {
        '1': { seats: 2 }
      }
    }
  )))
  assert(checkUFBForJournalRoomIDs(UserFeatureBitsModule.createUFB2(
    {
      user: {},
      rooms: {
        '1': { seats: 2 },
        '2': { seats: 2 }
      }
    }
  )))

  unitTestAssertThrowsError(checkUFBForJournalRoomIDs, [UserFeatureBitsModule.createUFB2(
    {
      user: {},
      rooms: {
        'abcabcabcabcabcabcab': { seats: 2 }
      }
    }
  )])
  unitTestAssertThrowsError(checkUFBForJournalRoomIDs, [UserFeatureBitsModule.createUFB2(
    {
      user: {},
      rooms: {
        'abcabcabcabcabcabcab': { seats: 2 },
        'defdefdefdefdefdefde': { seats: 2 }
      }
    }
  )])
}

// Check that all existing DB rooms have the `journal_room_id` key and that they differ.
function checkDBRoomsJournalRoomIDs (dbRooms) {
  assert(checkInvariantDBRooms(dbRooms))

  const seenIDs = []

  for (const roomData of Object.values(dbRooms)) {
    const i = roomData.journal_room_id
    assert(i !== undefined)
    assert(!seenIDs.includes(i))
    seenIDs.push(i)
  }

  return true
}


function prove_checkDBRoomsJournalRoomIDs () {
  unitTestAssert(checkDBRoomsJournalRoomIDs({
    'abcabcabcabcabcabcab': {
      seats: 2,
      journal_room_id: 1
    },
    'defdefdefdefdefdefde': {
      seats: 2,
      journal_room_id: 2
    }
  }))

  // One room is missing journal room ID.
  unitTestAssertThrowsError(checkDBRoomsJournalRoomIDs, [{
    'abcabcabcabcabcabcab': {
      seats: 2,
      journal_room_id: 1
    },
    'defdefdefdefdefdefde': {
      seats: 2
    }
  }])

  // Colliding journal room IDs.
  unitTestAssertThrowsError(checkDBRoomsJournalRoomIDs, [{
    'abcabcabcabcabcabcab': {
      seats: 2,
      journal_room_id: 1
    },
    'defdefdefdefdefdefde': {
      seats: 2,
      journal_room_id: 1
    }
  }])
}

// Result: { DBID: journalRoomID }, ex: { "0xfffff": "1", "dbabd123": "2" }
function getMappingFromDBRooms (dbRooms) {
  assert(checkInvariantDBRooms(dbRooms))
  assert(checkDBRoomsJournalRoomIDs(dbRooms))

  const mapping = {}

  // Expects the room IDs/keys to be the local, numerical journal room IDs.
  for (const [ roomId, roomData ] of Object.entries(dbRooms)) {
    const roomJournalId = roomData.journal_room_id
    assert(roomJournalId !== undefined)
    mapping[roomId] = roomJournalId
  }

  return mapping
}


function prove_getMappingFromDBRooms () {
  unitTestAssert(_.isEqual(getMappingFromDBRooms({
    'abcabcabcabcabcabcab': {
      seats: 2,
      journal_room_id: '1'
    },
    'defdefdefdefdefdefde': {
      seats: 2,
      journal_room_id: '2'
    }
  }), {
    'abcabcabcabcabcabcab': '1',
    'defdefdefdefdefdefde': '2'
  }))
}

// Calculates a strategy for creating and deleting DB rooms based on a given UFB and a
// { roomId1: roomData1, roomId2: roomData2, ... } object of existing DB rooms as they
// appear when fetched from database.
function calculateRoomSyncStrategy (ufb, DBIDMapping) {
  assert(UserFeatureBitsModule.checkInvariantUFB(ufb))
  assert(checkUFBForJournalRoomIDs(ufb))
  assert(isObjectInstance(DBIDMapping))

  const ufbRooms = UserFeatureBitsModule.getUFBRooms(ufb)
  const ufbRoomJournalIDs = Object.keys(ufbRooms).map(value => parseInt(value, 10))

  // These are the rooms specified in UFB that don't yet exist in room DB.
  // Use room properties from UFB to create DB room.
  // TODO: Candidate for breaking out to separate function, up to right before return.
  const journalRoomIDsToCreate = ufbRoomJournalIDs.reduce(
    (acc, e) => {
      if (Object.values(DBIDMapping).includes(e)) {
        return acc
      } else {
        const acc2 = _.cloneDeep(acc)
        acc2.push(e)
        return acc2
      }
    },
    []
  )

  const dbRoomIDsToDelete = (() => {
    let result = []
    for (const [ roomId, journalRoomID ] of Object.entries(DBIDMapping)) {
      if (Object.values(ufbRoomJournalIDs).includes(journalRoomID) === false) {
        result.push(roomId)
      }
    }
    return result
  })()

  // We need to guarantee a strategy was calculated for all existing rooms, so make sure
  // the number of rooms adds up. Starting with the number of existing rooms, then
  // adding and/or deleting rooms, should end up at the number of rooms in UFB.
  const numUFBRooms = Object.keys(ufbRooms).length
  const numExistingDBRooms = Object.keys(DBIDMapping).length
  assert(numExistingDBRooms + journalRoomIDsToCreate.length - dbRoomIDsToDelete.length === numUFBRooms)

  return {
    journalRoomIDsToCreate: journalRoomIDsToCreate,
    dbRoomIDsToDelete: dbRoomIDsToDelete
  }
}


function prove_calculateRoomSyncStrategy () {
  const testCases = [
    // No rooms in UFB and no existing rooms should do nothing.
    {
      ufb: UserFeatureBitsModule.makeBlankUFB(),
      DBIDMapping: {},
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: []
      }
    },

    // One room in UFB and no existing rooms should create that room.
    {
      ufb: UserFeatureBitsModule.createUFB2(
        {
          user: {},
          rooms: {
            '1': { seats: 2 }
          }
        }
      ),
      DBIDMapping: {},
      expected: {
        journalRoomIDsToCreate: [1],
        dbRoomIDsToDelete: []
      }
    },

    // One room in UFB that already exists should do nothing.
    {
      ufb: UserFeatureBitsModule.createUFB2(
        {
          user: {},
          rooms: { '1': { seats: 2 } }
        }
      ),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1
      },
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: []
      }
    },

    // One existing room but no rooms in UFB should delete that room.
    {
      ufb: UserFeatureBitsModule.makeBlankUFB(),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1
      },
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: ['abcabcabcabcabcabcab']
      }
    },

    // Two existing rooms but no rooms in UFB should delete both rooms.
    {
      ufb: UserFeatureBitsModule.makeBlankUFB(),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1,
        'defdefdefdefdefdefde': 2
      },
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: ['abcabcabcabcabcabcab', 'defdefdefdefdefdefde']
      }
    },

    // Two existing rooms and one of those in UFB should delete the other room.
    {
      ufb: UserFeatureBitsModule.createUFB2({
        user: {},
        rooms: {
          '1': { seats: 2 }
        }
      }),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1,
        'defdefdefdefdefdefde': 2
      },
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: ['defdefdefdefdefdefde']
      }
    },

    // Same as above, but the other room should be deleted.
    {
      ufb: UserFeatureBitsModule.createUFB2({
        user: {},
        rooms: { '2': { seats: 2 } }
      }),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1,
        'defdefdefdefdefdefde': 2
      },
      expected: {
        journalRoomIDsToCreate: [],
        dbRoomIDsToDelete: ['abcabcabcabcabcabcab']
      }
    },

    // Two rooms in UFB but two other existing rooms should replace both.
    {
      ufb: UserFeatureBitsModule.createUFB2({
        user: {},
        rooms: {
          '3': { seats: 2 },
          '4': { seats: 2 }
        }
      }),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1,
        'defdefdefdefdefdefde': 2
      },
      expected: {
        journalRoomIDsToCreate: [3, 4],
        dbRoomIDsToDelete: ['abcabcabcabcabcabcab', 'defdefdefdefdefdefde']
      }
    },

    // Two rooms in UFB, one in existing and one other, should replace the other.
    {
      ufb: UserFeatureBitsModule.createUFB2({
        user: {},
        rooms: {
          '2': { seats: 2 },
          '3': { seats: 2 }
        }
      }),
      DBIDMapping: {
        'abcabcabcabcabcabcab': 1,
        'defdefdefdefdefdefde': 2
      },
      expected: {
        journalRoomIDsToCreate: [3],
        dbRoomIDsToDelete: ['abcabcabcabcabcabcab']
      }
    }

  ]

  for (const testCase of Object.values(testCases)) {
    const roomSyncStrategy = calculateRoomSyncStrategy(testCase.ufb, testCase.DBIDMapping)
    assert(_.isEqual(roomSyncStrategy, testCase.expected))
  }
}

// Calculate a new seatings array from an old array and a desired number of seats.
function calculateSeatingsArray (originalSeatingsArray, newNumberOfSeats) {
  const newSeatingsArray = []
  const originalLength = originalSeatingsArray.length

  if (originalLength < newNumberOfSeats) {
    // We need to add seats. Keep all existing seatings and add the rest as false values.
    for (let i = 0; i < originalLength; i++) {
      newSeatingsArray.push(originalSeatingsArray[i])
    }
    const numSeatsToCreate = newNumberOfSeats - originalLength
    console.log(`Adding ${numSeatsToCreate} seats`)
    for (let i = 0; i < numSeatsToCreate; i++) {
      newSeatingsArray.push(false)
    }
  } else if (originalLength > newNumberOfSeats) {
    console.log(`Removing ${originalLength - newNumberOfSeats} seats from the end`)

    // We need to remove seats. First collect any seated users, in order, then reduce
    // the number of seats, then place users again. This allows closing gaps in the
    // instead of kicking users out.

    const users = []

    // Collect existing users.
    for (let i = 0; i < originalLength; i++) {
      if (originalSeatingsArray[i]) {
        users.push(originalSeatingsArray[i])
      }
    }

    // Seat the collected users, or false values when there are no more.
    for (let i = 0; i < newNumberOfSeats; i++) {
      if (users.length > 0) {
        newSeatingsArray.push(users.shift())
      } else {
        newSeatingsArray.push(false)
      }
    }
  } else {
    // Nothing has changed, just return a copy of the original array.
    for (let i = 0; i < newNumberOfSeats; i++) {
      newSeatingsArray.push(originalSeatingsArray[i])
    }
  }

  return newSeatingsArray
}

function prove_calculateSeatingsArray () {
  unitTestAssert(_.isEqual(
    calculateSeatingsArray([], 1),
    [false]
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray(['abc', false], 2),
    ['abc', false]
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray(['abc', false], 5),
    ['abc', false, false, false, false]
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray(['abc', 'def', false, false, false], 2),
    ['abc', 'def']
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray(['abc', false, 'def', false, false], 2),
    ['abc', 'def']
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray(['abc', false, 'def', false, 'ghi'], 3),
    ['abc', 'def', 'ghi']
  ))
  unitTestAssert(_.isEqual(
    calculateSeatingsArray([false, false, 'def', false, false], 5),
    [false, false, 'def', false, false]
  ))
}

// Calculate the three people arrays from a seatings array.
function calculatePeopleArrays (seatings, oldPeopleWithModeratorKey) {
  return {
    peopleSeated: _.compact(seatings),
    peopleWithModeratorKey: _.intersection(seatings, oldPeopleWithModeratorKey),
    projectPeople: _.compact(seatings)
  }
}

function prove_calculatePeopleArrays () {
  unitTestAssert(_.isEqual(
    calculatePeopleArrays(
      ['abc', false],
      ['abc']
    ),
    {
      peopleSeated: ['abc'],
      peopleWithModeratorKey: ['abc'],
      projectPeople: ['abc']
    }
  ))

  assert(_.isEqual(
    calculatePeopleArrays(
      ['abc', 'def'],
      ['abc', 'def', 'ghi']
    ),
    {
      peopleSeated: ['abc', 'def'],
      peopleWithModeratorKey: ['abc', 'def'],
      projectPeople: ['abc', 'def']
    }
  ))

  assert(_.isEqual(
    calculatePeopleArrays(
      ['abc', 'xyz'],
      ['abc', 'def', 'ghi']
    ),
    {
      peopleSeated: ['abc', 'xyz'],
      peopleWithModeratorKey: ['abc'],
      projectPeople: ['abc', 'xyz']
    }
  ))
}

function runUnitTests () {
  console.log('Room.js -- START')

  prove_checkInvariantDBRooms()
  prove_checkUFBForJournalRoomIDs()
  prove_checkDBRoomsJournalRoomIDs()
  prove_getMappingFromDBRooms()
  prove_calculateRoomSyncStrategy()
  prove_calculateSeatingsArray()
  prove_calculatePeopleArrays()

  console.log('Room.js -- END')
}

module.exports = {
  calculateRoomSyncStrategy,
  calculateSeatingsArray,
  calculatePeopleArrays,
  runUnitTests
}
