<template>
  <v-container class="container">
    <v-row>
      <v-col>
        <h1>Spotterfish Admin</h1>
        <p><router-link :to="{ name: 'adminGetUsers' }">Download all user data</router-link></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Find users
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Enter user ID and press enter to search"
              single-line
              hide-details
              @keyup.enter="search"
            ></v-text-field>
          </v-card-title>
          <v-card-title>
            <v-btn @click="listAllUsers">List all users</v-btn>
          </v-card-title>
          <v-data-table
            v-model="selectedUsers"
            :headers="headers"
            :items="searchResults"
            :loading="loading"
            item-key="user_id"
            show-select
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="admin-button"
          :color="colors.mainAccent"
          :disabled="selectedUsers.length === 0"
          @click="makeUsersTier1">
          Set user{{selectedUsers.length > 1 ? 's' : ''}} to tier 1
        </v-btn>
        <v-btn
          class="admin-button"
          :color="colors.mainAccent"
          :disabled="selectedUsers.length === 0"
          @click="makeUsersTier2">
          Set user{{selectedUsers.length > 1 ? 's' : ''}} to tier 2
        </v-btn>
        <v-btn
          class="admin-button"
          :color="colors.mainAccent"
          :disabled="selectedUsers.length === 0 || selectedUsers.length > 1"
          @click="makeUserDawStreamer">
          Make user daw streamer
        </v-btn>
        <v-btn
          class="admin-button"
          :color="colors.mainAccent"
          :disabled="selectedUsers.length === 0 || selectedUsers.length > 1"
          @click="openJournalDialog">
          Add journal entry to user
        </v-btn>
        <v-btn
          class="admin-button"
          :color="colors.mainAccent"
          :disabled="selectedUsers.length === 0 || selectedUsers.length > 1"
          @click="syncRooms">
          Sync rooms
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
              v-model="projectString"
              append-icon="mdi-magnify"
              label="Enter project ID and press enter to analyze it"
              single-line
              hide-details
              @keyup.enter="analyzeProject"
            ></v-text-field>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addJournalEntryDialog"
      width="700">
      <v-card>
        <v-card-title>
          Add journal entry
        </v-card-title>
        <v-card-text class="text-left">
          Current journal:<br>
          <v-textarea
            class="no-wrap-textarea"
            style="font-family: monospace;"
            :value="currentJournalReport"
            placeholder="Loading…"
            rows="10"
            no-resize
            readonly>
          </v-textarea>
        </v-card-text>
        <v-card-text class="text-left">
          Enter the JSON journal entry you want to add to this user:<br>
          <v-text-field
            v-model="newJournalEntry"
            :error-messages="addJournalEntryErrors"
            :messages="addJournalEntryMessages"
            style="font-family: monospace;"
            @keyup="validateJournalInput"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="colors.mainAccent"
            :disabled="newJournalEntry.length === 0 || addJournalEntryErrors.length > 0"
            @click="addJournalEntryToUser">
            Add entry
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//@ts-nocheck
import App from '@/App.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import UserJournal from '@/../source_files/spotterfish_library/userJournal'
import { lang } from 'moment'

export default {
  name: 'admin',

  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      headers: [
        { text: 'Username', value: 'user_name' },
        { text: 'User ID', value: 'user_id' },
        { text: 'Email', value: 'email' },
        { text: 'Tier', value: 'tier' }
      ],
      searchString: '',
      searchResults: [],
      selectedUsers: [],
      loading: false,
      addJournalEntryDialog: false,
      newJournalEntry: '',
      currentJournalReport: '',
      addJournalEntryErrors: [],
      addJournalEntryMessages: [],
      projectString: ''
    }
  },

  methods: {
    async analyzeProject () {
      const exportJSON = []

      // get project
      const projectData = await App.spotterfishSession.firestoreDB.collection('projects').doc(this.projectString).get()
      const project = projectData.exists ? projectData.data() : undefined
      console.log(project)
      exportJSON.push(JSON.stringify(project))
      // get version 
      const versionData = await App.spotterfishSession.firestoreDB.collection('versions').doc(project.current_version).get()
      const version = versionData.exists ? versionData.data() : undefined
      version['.key'] = versionData.id
      console.log(version)
      exportJSON.push(JSON.stringify(version))
      // TODO: get files
      // get markers for current markerlane
      const markers = []
      const markersData = await App.spotterfishSession.firestoreDB.collection('markers')
        .where('original_parent_marker_lane', '==', project.current_version_marker_lane).get()   
      markersData.forEach(marker => {
        markers.push(marker.data())
      })     
      console.log(markers) 
      exportJSON.push(JSON.stringify(markers))
      // get markers for each lane
      try {
        this.$store.dispatch('version_get_marker_lanes', version['.key']).then((lanes) => {
          console.log('available lanes:  ', lanes)
          exportJSON.push(JSON.stringify(lanes))
        })
      } catch (error) {
        exportJSON.push(JSON.stringify({error: error}))
      }

      const blob = new Blob([exportJSON], {
        type: `application/json;charset=utf-8`
      })
      const name = `${project.project_name}.json`
      saveAs(blob, `${name}`)
      
    },
    async makeUserDawStreamer(){
      await CloudClient.call_CFMakeUserDawStreamer(App.Firebase, this.selectedUsers[0].user_id, 'Unlocked from admin')
      this.selectedUsers = []
    },
    async search () {
      this.loading = true
      if (this.searchString) {
        this.searchResults = await CloudClient.call_CFsearchUsers(App.Firebase, this.searchString)
      } else {
        this.searchResults = []
      }
      this.loading = false
    },
    async listAllUsers () {
      this.loading = true
      this.searchResults = []
      const res = await CloudClient.call_CFgetAllUsersData(App.Firebase, 'json')
      const parsed = JSON.parse(res)
      for (const fullSnapshot of parsed) {
        // @ts-ignore
        const u = fullSnapshot.user
        const journal = []
        for (const a of fullSnapshot.userBusinessData.account_journal) {
          journal.push(JSON.parse(a))
        }
        u.tier = UserJournal.getShortTierString(journal)
        // @ts-ignore
        this.searchResults.push(u)
      }
      this.loading = false
    },

    async makeUsersTier1 () {
      if (this.selectedUsers.length === 0) {
        return
      }
      this.loading = true
      for (const selectedUser of this.selectedUsers) {
        if (selectedUser.tier === 'custom') {
          const ok = prompt('this user is on a custom tier, are you sure you want to change it to tier1 which can lead to unexpected results? write "ok" below then press ok to confirm')
          if (!ok) { 
            this.loading = false
            return
          }
        }
        // @ts-ignore
        const userId = selectedUser.user_id
        try {
          await CloudClient.call_CFmakeUserTier1(App.Firebase, userId, 'Changed to Tier1 via admin panel')
          
        } catch (error) {
          console.error(error)
          alert(`Could not change user ${userId}!`)
          this.loading = false
        }
      }
      this.loading = false
      alert(`${this.selectedUsers.length} users were successfully changed to Tier1`)
      this.listAllUsers()
    },

    async makeUsersTier2 () {
      if (this.selectedUsers.length === 0) {
        return
      }
      this.loading = true
      for (const selectedUser of this.selectedUsers) {
        if (selectedUser.tier === 'custom') {
          const ok = prompt('this user is on a custom tier, are you sure you want to change it to tier2 which can lead to unexpected results? write "ok" below then press ok to confirm')
          if (!ok) { 
            this.loading = false
            return
          }
        }
        // @ts-ignore
        const userId = selectedUser.user_id
        try {
          await CloudClient.call_CFmakeUserTier2(App.Firebase, userId, 'Changed to Tier2 via admin panel')
        } catch (error) {
          console.error(error)
          alert(`Could not upgrade user! ${userId}`)
          this.loading = false
        }
      }
      this.loading = false
      alert(`${this.selectedUsers.length} users were successfully changed to Tier2`)
      this.listAllUsers()
    },

    async openJournalDialog () {
      this.clearJournalDialog()
      // TODO: Assumes single selection for now. This is also enforced by the data table
      // component but might as well have a safe-guard here.
      if (this.selectedUsers.length === 0) {
        return
      }
      // @ts-ignore
      const userId = this.selectedUsers[0].user_id
      const report = await CloudClient.call_CFgetUserJournalReport(App.Firebase, userId)
      this.currentJournalReport = report.join('\n')
      this.addJournalEntryDialog = true
    },

    async syncRooms () {
      const userId = this.selectedUsers[0].user_id
      const res = await CloudClient.call_CFsyncRoomFromUBD(App.Firebase, userId)
      console.log(res)
    },

    clearJournalDialog () {
      this.newJournalEntry = ''
      this.currentJournalReport = ''
    },

    validateJournalInput () {
      this.addJournalEntryErrors = []
      this.addJournalEntryMessages = []

      if (this.newJournalEntry.length === 0) {
        return
      }

      let entry

      try {
        entry = JSON.parse(this.newJournalEntry)
      } catch (error) {
        // @ts-ignore
        this.addJournalEntryErrors = ['This journal entry is not valid JSON']
        return
      }

      // Insert current date in entry if it's missing.
      if (entry.date === undefined) {
        entry.date = new Date().toISOString()
        this.newJournalEntry = JSON.stringify(entry)
        // @ts-ignore
        this.addJournalEntryMessages = ['Current date was auto-inserted']
      }
    },

    async addJournalEntryToUser () {
      this.validateJournalInput()

      console.log('Adding new journal entry to user')

      // @ts-ignore
      const userId = this.selectedUsers[0].user_id

      try {
        await CloudClient.call_CFaddJournalEntry(App.Firebase, userId, this.newJournalEntry)
        alert('Journal entry successfully added!')
      } catch (error) {
        alert('Invalid journal entry')
        return
      }

      this.clearJournalDialog()
      this.addJournalEntryDialog = false
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #121212;
  height: 100vh;
}
.admin-button{
  &:disabled{
    &.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
      background-color: #1e1e1e !important;

    }
  }
}
.admin-button + .admin-button {
  margin-left: 1rem
}

</style>
