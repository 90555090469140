const SpotterfishCore = require('./SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isArrayInstance = SpotterfishCore.isArrayInstance
const isStringInstance = SpotterfishCore.isStringInstance
const compareArraysByValue = SpotterfishCore.compareArraysByValue

// Table is an array of lines. Each line is an array of column strings. Each line must have the same number of columns.
function calcTableColumnWidths (table) {
  assert(isArrayInstance(table))

  if (table.length > 0) {
    // Gets widths of the columns of line #1
    assert(isArrayInstance(table[0]))
    var columnWidthsAcc = table[0].map(function (e) {
      assert(isStringInstance(e))
      return e.length
    })
    // console.log(columnWidthsAcc)

    for (let line = 1; line < table.length; line++) {
      const lineArray = table[line]
      assert(isArrayInstance(lineArray))
      assert(lineArray.length === columnWidthsAcc.length)

      for (let column = 0; column < columnWidthsAcc.length; column++) {
        columnWidthsAcc[column] = Math.max(columnWidthsAcc[column], lineArray[column].length)
      }
    }
    // console.log(columnWidthsAcc)
    return columnWidthsAcc
  } else {
    return undefined
  }
}


function prove_calcTableColumnWidths () {
  unitTestAssert(calcTableColumnWidths([]) === undefined)
  unitTestAssert(SpotterfishCore.compareValues(calcTableColumnWidths([[ 'hello' ]]), [ 5 ]))


  const a = calcTableColumnWidths(
    [
      [ 'head1', 'header2000', 'he3' ],
      [ 'a', 'b', 'c' ],
      [ 'aaaaaaaa', 'bbbb', 'cccc' ]
    ]
  )
  unitTestAssert(compareArraysByValue(a, [ 8, 10, 4 ]) === true)
}

// Table is an array of lines. Each line is an array of column strings. Each line must have the same number of columns.
// Returns one string per line, padded to match up into columns
function tableToStrings (table, columnDivider) {
  assert(isArrayInstance(table))
  assert(isStringInstance(columnDivider) === true && columnDivider.length > 0)

  var acc = []
  if (table.length > 0) {
    const columnWidths = calcTableColumnWidths(table)

    // console.log(columnWidths)
    for (let line = 0; line < table.length; line++) {
      var lineAcc = ''
      for (let c = 0; c < columnWidths.length; c++) {
        const a = table[line][c]
        const s = a.padEnd(columnWidths[c], ' ')
        const div = (c === (columnWidths.length - 1) ? '' : columnDivider)
        lineAcc = lineAcc + s + div
      }
      acc.push(lineAcc)
    }
  }
  return acc
}


function prove_tableToStrings () {
  const a = tableToStrings(
    [

      [ 'head1', 'header2000', 'he3' ],
      [ 'a', 'b', 'c' ],
      [ 'aaaaaaaa', 'bbbb', 'cccc' ]

    ],
    ' | '
  )
  SpotterfishCore.consoleLogLines(a)
}



function runUnitTests () {
  console.log('tableFormatting.js -- START')

  prove_calcTableColumnWidths()

  prove_tableToStrings()

  console.log('tableFormatting.js -- END')
}

// TODO: Decide what functions should be exported, i.e. what is the public API.
module.exports = {
  calcTableColumnWidths,
  tableToStrings,
  runUnitTests
}
