<template>
  <div class="outercontainer" ref="markersContainerDiv">
    <div style="width: 100%; margin: -1.1em auto; background: rgba(255,255,255,0); height: 48px; display:block">
      <div class="markers-container" v-for="marker in markers" :key="marker['.key']">
        <div class="single-marker-container"
            :class="[
              belowLine ? 'upsidedown' : '',
              (marker['.key'] === isHighlightedMarker) ? 'highlighted' : 'unscaled',
              (marker['.key'] === isSelectedMarker) ? 'scaled' : 'unscaled'
            ]"
            :id="marker['.key']"
            :style="{ left: calcMarkerGUIPosition0(marker.tc_pos) }"
            v-if="marker.tc_pos"
            @contextmenu.prevent.stop="handleRightClick(marker, $event)"
        >
          <!-- Rotated Icon Container -->
          <div class="icon-inner-container">
            <i class="mdi mdi-square" @click="clicked(marker)" :style="{ color: markertype(marker).color }"></i>
          </div>
          <!-- Tooltip Container (Sibiling of Icon Container, Not Child) -->
          <div class="marker-tooltip">
            <span>{{ calcHTMLOutputSMPTE(marker.tc_pos) }}</span>
            <br v-if="marker.title" />
            <span v-if="marker.title">{{ marker.title }}</span>
            <br v-if="marker.description"/>
            <span v-if="marker.description">{{ marker.description }}</span>
          </div>
        </div>

      </div>
    </div>

    <contextMenu 
      v-if="contextMenuMarker && UI_disableTimelineInput === false" 
      :position="contextMenuPosition" 
      :isVisible="contextMenuMarker !== undefined"
      @context-menu-change="handleContextMenuChanged()"
    >
      <div @click="handleItemClick('highlight-marker', contextMenuMarker['.key'] === isHighlightedMarker)" class="d-flex align-center">
        <i class="mdi mdi-bell-alert-outline pr-1" />
          {{ contextMenuMarker['.key'] === isHighlightedMarker ? 'Remove highlight from marker' : 'Highlight marker (for all users)'}}
      </div>

      <div @click="handleItemClick('reposition-marker')" class="d-flex align-center">
        <i class="mdi mdi-update pr-1" />
          Move Marker to Playhead Position
      </div>
      
      <div @click="handleItemClick('delete-marker')" class="d-flex align-center">
        <i class="mdi mdi-delete pr-1" />
          Delete marker
      </div>

      <template v-slot:submenu-title>
          <div class="d-flex align-center">
            <i class="mdi mdi-palette pl-2 pr-2" />
              Change Marker Type 
             <i class="mdi mdi-chevron-right pr-1" style="font-size: 2em;" />
          </div>
      </template>

        <!-- Submenu content slot -->
        <template v-slot:submenu-content>
            <div class="marker-edit-category-buttons d-flex align-center">
                <v-btn
                    small
                    v-for="(item, index) in fixedCommentTypes"
                    :key="index"
                    class="markertype elevation-5 rounded gray-border"
                    @click="handleColorSelected(fixedCommentTypes[index], contextMenuMarker)"
                    :style="{
                        background: fixedCommentTypes[index].color,
                        color: fixedCommentTypes[index].textColor 
                    }"
                >
                    {{ fixedCommentTypes[index].name }}
                </v-btn>
            </div>
        </template>
    </contextMenu>

  </div>
</template>

<script>
// @ts-nocheck
import { ref, watch, computed, inject } from 'vue'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import Constants from'@/../source_files/spotterfish_library/consts'
import MarkerUtils from '@/../source_files/spotterfish_library/utils/MarkerUtils'
import contextMenu from '@/components/UI/menus/contextMenu.vue'

const colors = require('@/lib/ui/colors.js').colors
const logos = require('@/lib/ui/logos.js').logos


export default {
  name: 'markerlane',
  // Expects:
  // a boolean whether it should be rendered "upside down" or regular
  props: [
    'markers',
    'htmlOutput_selectedMarker',
  ],
  components: {
    contextMenu
  },
  setup () {

    const belowLine = false
    const zoomedTimeline = false

    const templateReactiveData = inject('templateReactiveData')
    const clock = inject('clock')

    const transportWidth = ref(templateReactiveData.value.UI_transportWindowSize.width)
    const clientWidth = ref(templateReactiveData.value.UI_transportWindowSize.width - 56)

    const lastSelectedMarker = ref(undefined)
    const contextMenuMarker = ref(undefined)
    const showSubmenu = ref(false)
    
    const highlightedMarker = computed(() => templateReactiveData.value.ui.highlightedMarker)
    const UI_disableTimelineInput = computed(() => templateReactiveData.value.UI_disableTimelineInput)
    const UI_usePTMarkerColors = computed(() => templateReactiveData.value.UI_usePTMarkerColors)

    const calcHTMLOutputSMPTE = (posSMPTE) => {
      // truncate marker TC to nearest frame (when changing project framarate)
      const tc = Timecode.truncateToClosestSMPTEifNeeded(posSMPTE, clock.videoFileFrameRateKey)
      const posSeconds = Timecode.SMPTEStringToSeconds(tc, clock.videoFileFrameRateKey)
      
      return Timecode.secondsToSMPTEString(
        Timecode.calcMarkerPositionWithOffset(posSeconds, clock),
        clock.videoFileFrameRateKey
      )
    }

    // should be part of templateReactiveData
    const calcMarkerGUIPosition0 = (markerSmpte) => {
      // performance.mark('start')
      const a = MarkerUtils.calcMarkerGUIPosition(
        markerSmpte, 
        clock, 
        clientWidth.value, 
        transportWidth.value, 
        zoomedTimeline
        )
      // performance.mark('end')
      // performance.measure('calcmarkerguiposition', 'start', 'end')
      // const measure = performance.getEntriesByName('calcmarkerguiposition')[0];
      // console.log(`calcmarkerguiposition took ${measure.duration} milliseconds`);
      return a
    }

    watch(() => templateReactiveData.value.UI_transportWindowSize.width, (newVal, oldVal) => {
      transportWidth.value = newVal
      clientWidth.value = newVal - 56
    })

    return {
      colors: colors,
      logos: logos,
      clientWidth,
      lastSelectedMarker,
      zoomedTimeline,
      contextMenuMarker,
      showSubmenu,
      highlightedMarker,
      UI_disableTimelineInput,
      UI_usePTMarkerColors,
      belowLine,
      calcMarkerGUIPosition0,
      calcHTMLOutputSMPTE
    }
  },

  computed: {
    fixedCommentTypes () {
      return this.UI_usePTMarkerColors ? Constants.FIXED_COMMENT_TYPES_PT : Constants.FIXED_COMMENT_TYPES
    },
    isHighlightedMarker () {
      console.log(this.highlightedMarker)
      return this.highlightedMarker ? this.highlightedMarker : false
    },
    isSelectedMarker () {
      return this.htmlOutput_selectedMarker ? this.htmlOutput_selectedMarker['.key'] : false
    },

  },
  methods: {
    handleContextMenuChanged(e) {
      console.log(e)
      if (e === undefined) {
        this.contextMenuMarker = undefined
      }
    },
    handleMouseOver() {
      this.showSubmenu = true;
      console.log('Mouseover triggered');
    },
    handleMouseOut() {
      this.showSubmenu = false;
      console.log('Mouseout triggered');
    },
    // Context menu stuff
    handleRightClick(marker, event) {
      this.contextMenuMarker = marker
      this.contextMenuPosition = { x: event.clientX, y: event.clientY }
    },
    handleItemClick(item, highlightFlag) {
      if (item === 'highlight-marker') {
        this.$emit('request-highlight-marker', highlightFlag ? undefined : this.contextMenuMarker)
      }
      else if (item === 'delete-marker') {
        if (this.UI_disableTimelineInput === true) { return }
        this.$emit('request-delete-marker', this.contextMenuMarker)
      }
      else if (item === 'reposition-marker') {
        if (this.UI_disableTimelineInput === true) { return }
        this.$emit('request-reposition-marker', this.contextMenuMarker)
      }
      else {
        console.log('not a valid context menu command: ', item)
      }
      
      console.log(item)
      console.log(this.contextMenuMarker)
      this.closeContextMenu()
    },
    
    handleColorSelected (markerType, marker) {
      if (this.UI_disableTimelineInput === true) { return }
      
      this.$emit(
        'request-update-marker-category', 
        { ['.key']: marker['.key'],
          category_select: markerType.name
        }
      )
    },

    closeContextMenu() {
      this.contextMenuMarker = undefined
    },

    clicked (marker) {
      if (this.UI_disableTimelineInput === true) {
        return
      }
      // Emits (via Vuex)
      // a htmlOutput_selectedMarker object,
      // a Selected Comment Type as an object
      if (marker === this.htmlOutput_selectedMarker || marker === this.lastSelectedMarker) {
        this.$emit('request-select-marker', { marker: undefined, action: 'close' })
        this.lastSelectedMarker = marker
      } else {
        this.lastSelectedMarker = marker
        this.$emit('request-select-marker', { marker: marker, action: 'select'})
      }
    },
    
    markertype (marker) {
      if (this.belowLine) {
        return {color: 'gray', icon: 'location_on'}
      }
      for (var i = 0; i < this.fixedCommentTypes.length; i++) {
        if (this.fixedCommentTypes[i].name === marker.category_select) {
          return this.fixedCommentTypes[i]
        }
      }
      return this.fixedCommentTypes[this.fixedCommentTypes.length - 1]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/global.scss';

.outercontainer {
  width: calc(100% - 32px);
  min-width: 768px;
  margin: 0 auto;
}

.single-marker-container {
  position: relative;
  height:  0.4em;
  transform: translateX(-50%);
  padding: 0;
  display: inline-block;
  font-size: 1em;
  position: absolute;
  background: none;
  text-align: center;
  top: 8px;
  width: 2px;
  
}

.marker-tooltip {
  background-color: $color-tooltip-background;
  position: absolute;
  width: 80px;
  bottom: 100%; /* Position above the marker container */
  left: 50%;
  transform: translateX(-50%) translateY(-10px); /* Adjust horizontally to center and move up */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.5s ease;
  font-size: 0.5em;
  border-radius: 4px;
  padding: 4px 2px 4px 0;
}

.single-marker-container:hover .marker-tooltip {
  visibility: visible;
  opacity: 1;
}

.icon-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 100%;
  transform: rotate(45deg); /* Rotate only the icon */
}


.highlighted .icon-inner-container {
  text-shadow: 
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,  
    -1px 1px 0 #FFF,  
    1px 1px 0 #FFF; 

}
.scaled .icon-inner-container {
  transform: translateX(-50%) scale(1.5);
  transform-origin: bottom center;
  transition: all .2s ease-in-out;

}

.unscaled:hover {
  transform: translateX(-50%) scale(2);
  transform-origin: bottom center;
  transition: all .2s ease-in-out;
}
.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid;
}

.backgroundimage {
   max-height: 18px;
   max-width: 18px;
   padding: 0;
   position: fixed;
   margin-left: -9px;
   margin-top: 2px;
   z-index: 10;
}

.marker-edit-category-buttons{
  display: flex;
  flex-wrap: wrap;
  padding: 3px;
  max-width: 280px;
  border-radius: 4px;
}
.marker-edit-category-buttons button{
  font-size: 0.8em;
  flex: 1 1 20%;
  margin-left:0.25rem;
  margin-right:0.25rem;
  margin-bottom: 2px;
}
.markers-container {
  display: block;
  width: 100%;
}
</style>
