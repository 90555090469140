const SpotterfishCore = require('../SpotterfishCore')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

function downloadUrlToPath (url) {
  // Convert a Google Cloud Storage download URL to a corresponding bucket path.
  // These URLs, obtained from .getDownloadURL() look like this:
  //
  // https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?alt=media&token=e8242215-3524-4c3f-8f23-ba5adebe55a5
  //
  // and we want to convert them to something like:
  //
  // IyXvdmuSuxjpZSae82uJ/re-animator-intro.mp4

  console.log(`Converting ${url} to path`)

  assert(SpotterfishCore.isStringInstance(url))

  if (!url) {
    throw Error('URL is empty')
  }

  const parts = url.split('/')

  // If we only got two parts this was probably already a path.
  if (parts.length === 2) {
    return url
  }

  // Get last part, which should be the bucket path.
  let path = parts[parts.length - 1]

  // Remove query string.
  path = path.split('?')[0]

  path = decodeURIComponent(path)

  if (!path.includes('/')) {
    throw Error(`Final path ${path} is missing parts`)
  }

  return path
}


function prove_downloadUrlToPath () {
  const finalPath = 'IyXvdmuSuxjpZSae82uJ/re-animator-intro.mp4'

  // Convert full download URL to path.
  unitTestAssert(downloadUrlToPath('https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?alt=media&token=e8242215-3524-4c3f-8f23-ba5adebe55a5') === finalPath)

  // Convert download URL without token to path.
  unitTestAssert(downloadUrlToPath('https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?alt=media') === finalPath)

  // Convert download URL without query string to path.
  unitTestAssert(downloadUrlToPath('https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4') === finalPath)

  // Convert download URL without base URI to path.
  unitTestAssert(downloadUrlToPath('/o/IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?alt=media&token=e8242215-3524-4c3f-8f23-ba5adebe55a5') === finalPath)

  // Convert download URL without base URI and path to path.
  unitTestAssert(downloadUrlToPath('IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?alt=media&token=e8242215-3524-4c3f-8f23-ba5adebe55a5') === finalPath)

  // Convert download URL without alt query to path.
  unitTestAssert(downloadUrlToPath('IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4?token=e8242215-3524-4c3f-8f23-ba5adebe55a5') === finalPath)

  // Convert URL-escaped path to path.
  unitTestAssert(downloadUrlToPath('IyXvdmuSuxjpZSae82uJ%2Fre-animator-intro.mp4') === finalPath)

  // Convert path to itself.
  unitTestAssert(downloadUrlToPath('IyXvdmuSuxjpZSae82uJ/re-animator-intro.mp4') === finalPath)

  // Should fail when passed URL is empty or incorrect.
  SpotterfishCore.unitTestAssertThrowsError(downloadUrlToPath, [''])
  SpotterfishCore.unitTestAssertThrowsError(downloadUrlToPath, ['IyXvdmuSuxjpZSae82uJ'])
  SpotterfishCore.unitTestAssertThrowsError(downloadUrlToPath, [null])
  SpotterfishCore.unitTestAssertThrowsError(downloadUrlToPath, [undefined])
  SpotterfishCore.unitTestAssertThrowsError(downloadUrlToPath, [123])
}

function getUploadPathInfo (fileName, projectId) {
  // Get the filename only, in case a path was passed to this function.
  fileName = fileName.split('/').pop()

  // Separate the name and extension so we can insert a random string inbetween
  // without knowing the length of the extension.
  const pathComponents = fileName.split('.')

  let fileExtension
  let baseFileName

  if (pathComponents.length > 1) {
    fileExtension = pathComponents.pop()
    baseFileName = pathComponents.join('.') // Assemble base file name again, in case it had periods in it.
  } else {
    fileExtension = ''
    baseFileName = fileName
  }

  const randomToken = Math.random().toString(16).substr(2, 10)

  // Construct the final path.
  let filePath = `${projectId}/${baseFileName}-${randomToken}`
  if (fileExtension.length > 0) {
    filePath = `${filePath}.${fileExtension}`
  }

  return {
    filePath: filePath,
    token: randomToken
  }
}

// TODO: Write tests.
// function prove_getUploadPathInfo () {}

function runUnitTests () {
  console.log('PathUtils.js -- START')

  prove_downloadUrlToPath()

  console.log('PathUtils.js -- END')
}

module.exports = {
  downloadUrlToPath,
  getUploadPathInfo,
  runUnitTests
}
