import { render, staticRenderFns } from "./projectorRoom.vue?vue&type=template&id=76aaf5f4&scoped=true"
import script from "./projectorRoom.vue?vue&type=script&lang=js"
export * from "./projectorRoom.vue?vue&type=script&lang=js"
import style0 from "./projectorRoom.vue?vue&type=style&index=0&id=76aaf5f4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76aaf5f4",
  null
  
)

export default component.exports