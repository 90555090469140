const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const isArrayInstance = SpotterfishCore.isArrayInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const isObjectInstance = SpotterfishCore.isObjectInstance

const _ = require('lodash')


function updateSharedStatePlayVector(session, t){
  assert(SpotterfishCore.isObjectInstance(t))

  const screeningRoomID = session.screeningRoomConnection.screeningRoomDBCopy['.key']
  const ref = session.screeningRoomConnection.spotterfishSession.firebase.database().ref(`shared_state/${ screeningRoomID }/playback_vector/`)
  // Could be stored in ScreeningRoomSession
  if(t.pos !== undefined && t.playFlag !== undefined){
    ref.update({ 
      position: t.pos, 
      velocity: t.playFlag ? 1.0 : 0.0,
      serverTimeStamp: session.screeningRoomConnection.spotterfishSession.firebase.database.ServerValue.TIMESTAMP,
      user: session.screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser.uid
    })
  }
  else if(t.pos !== undefined){
    ref.update({ 
      position: t.pos, 
      serverTimeStamp: session.screeningRoomConnection.spotterfishSession.firebase.database.ServerValue.TIMESTAMP,
      user: session.screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser.uid
    })
  }
  else if(t.playFlag !== undefined){
    ref.update({ 
      velocity: t.playFlag ? 1.0 : 0.0,
      serverTimeStamp:session .screeningRoomConnection.spotterfishSession.firebase.database.ServerValue.TIMESTAMP,
      user: session.screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser.uid,
    })
  }      
}

// A timingProvider reavaluates the latency to other peers - adjusts for it
// WARNING: if the offset is wrong at the beginning - it will be wrong all the time.
// ??? if you query firebase servertimoffset severl tims - is the value always the same??+
// There is also network buffering + when it arrives until it is procesed by javascript - timeStamp the event and fire later - could compare
// Hardware clock drift??
// Re-
// measure actual latency on each read
// ping - pong / 2 

/*
A) Keep using MCORP
B) Use simple firebase solution
-> C) B but better latency compensation
D) Use Chris no-server solution

GOAL: Make our client's session code 100.0% robust. Strong expcetion-safety guarantee, no matter the timing.

RX.js

// test code to measure latency better - WIP
// Get rid of faulty reports by ignoring the first one,
// ignore every first call with a new time, since it is a firebase cached return value
// Before anything else has been received - use the firebase reported time serverTimeOffset to determine latency
// this.offset = this.firstTime === undefined ? this.screeningRoomSessionNonReactive.screeningRoomConnection.serverTimeOffset : this.offset
// if ((this.firstTime === refinedSharedState.users[this.loggedInUserID].latency_measurement?.user_time) && (this.lastDate ? refinedSharedState.users[this.loggedInUserID].latency_measurement?.user_date >= this.lastDate : true)) {
//   this.offset = (performance.now() - refinedSharedState.users[this.loggedInUserID].user_time) / 2
//   this.lastDate = refinedSharedState.users[this.loggedInUserID].latency_measurement.user_date
// }
// this.firstTime = refinedSharedState.users[this.loggedInUserID].user_time
// console.warn(this.offset)

*/

function createPlaybackVectorForLocalTO (refinedSharedState, serverTimeOffset, localBaseTime, previousPlaybackVector) {
  // ignore calls that do not change the position or velocity
  if (
    refinedSharedState.playback_vector === undefined || 
    previousPlaybackVector?.serverTimeStamp === refinedSharedState.playback_vector.serverTimeStamp
  ) {
    return undefined
  }
  const pos0 = refinedSharedState.playback_vector.position || 0.0

  const calculatedOffset = (localBaseTime + performance.now() + serverTimeOffset - refinedSharedState.playback_vector.serverTimeStamp) / 1000 
  // The serverTimeOffset is a value in milliseconds that should be added to the position, in play mode only, since the play position was reported by the server servertimeoffset milliseconds ago.
  const pos = refinedSharedState.playback_vector.velocity ? pos0 + calculatedOffset : pos0

  return {
    pos: pos,
    playFlag: Boolean(refinedSharedState.playback_vector.velocity)
  }
}

module.exports = {
  updateSharedStatePlayVector,
  createPlaybackVectorForLocalTO
}