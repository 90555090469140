

/*
# SPOTTERFISH TIMING

1) Frame-index, int32
2) Position in seconds: float
3) timecode-object (hh, ss etc)
4) SMPTE-string "23:59:59:29"
5) SMPTE-PLUS-string "23:59:59:29:07"
6) MTC-timing, 8 subframes
*/

const SC = require('../SpotterfishCore')

const assert = SC.assert

const _ = require('lodash')

//  https://github.com/fireworkweb/smpte.js
const SMPTEtc = require('smpte.js')



const k_frame_round_tolerance = 0.999

const kMaxSMPTE25FPS_Seconds = 23 * 3600 + 59 * 60 + 59 + 24 / 25
const kMaxSMPTE25FPS_SMPTE = '23:59:59:24'

const kBlankSMPTEString = '--:--:--:--'



////////////////////////////////    SPOTTERFISH FRAME RATE CATALOG


//  FrameRateKey -- a string enumeration describing the timing format of the video/timecode.

// TODO: Dropframe shall be built-into the keys themselves, 'FPS_30', 'FPS_30_DF'


//  nickname = "Frame rate nick name" = causual and inexact hz.
//  WARNING: nickname-field is used in database so cannot be changed without extra work.
//  NOTICE: nickname-field was pre- 2022-05-03 used for everything: the frequency, UI-name, database-value and frameDivision.
const kFrameRateKeyCatalog = {
  'NTSC_Film':      { nickname: '23.976',      rateIndex: 4,     freq: 24000 / 1001,   frameDivision: 24,    dropFrame: false,   frameDividerSymbol: ':'},
  // 'NTSC_Film_DF':   { nickname: '23.98_DF',  rateIndex: undefined, freq: 24000 / 1001,   frameDivision: 24,    dropFrame: true,    frameDividerSymbol: ';' },
  'film':           { nickname: '24',         rateIndex: 0,      freq: 24.0,           frameDivision: 24,    dropFrame: false,   frameDividerSymbol: ':'},
  'PAL':            { nickname: '25',         rateIndex: 1,      freq: 25.0,           frameDivision: 25,    dropFrame: false,   frameDividerSymbol: ':'},
  'NTSC':           { nickname: '29.97',      rateIndex: 2,      freq: 30000 / 1001,   frameDivision: 30,    dropFrame: false,   frameDividerSymbol: ':'},
  // 'NTSC_DF':        { nickname: '29.97_DF',   rateIndex: undefined, freq: 30000 / 1001,   frameDivision: 30,    dropFrame: true,    frameDividerSymbol: ';'},
  'web':            { nickname: '30',         rateIndex: 3,      freq: 30,             frameDivision: 30,    dropFrame: false,   frameDividerSymbol: ':'},
  // 'PAL_HD':      { nickname: '50',         rateIndex: undefined, freq: 50,             frameDivision: 50     dropFrame: false  },
  // 'NTSC_HD':     { nickname: '59.94',      rateIndex: undefined, freq: 60000 / 1001,   frameDivision: 60     dropFrame: false  },
  // 'web_high':    { nickname: '60',         rateIndex: undefined, freq: 60,             frameDivision: 60     dropFrame: false  }
  //'film_16mm':    { nickname: 'film16mm',   rateIndex: undefined, freq: 60,             frameDivision: 60     dropFrame: false  }
  // 16 frames per foot
  // '35mm_4-perf':    { nickname: 'footage',    rateIndex: undefined, freq: 16.0,           frameDivision: 16,    dropFrame: false,   frameDividerSymbol: '-'},
}

function mtcFrameRateIndexToKey(mtcFrameRateIndex) {
  const key = Object.keys(kFrameRateKeyCatalog).find(key => kFrameRateKeyCatalog[key].rateIndex === mtcFrameRateIndex);
  
  if (key) {
    return key;
  }
  
  throw new Error('Illegal MTC frame rate format');
}
function getFrameRateObjectFromRateIndex(rateIndex) {
  const frameRateObject = Object.values(kFrameRateKeyCatalog).find(frameRate => frameRate.rateIndex === rateIndex);
  
  if (frameRateObject) {
    return frameRateObject;
  }
  
  throw new Error('Illegal MTC frame rate format');
}


function getFrequencyFromRateIndex(rateIndex){
  return getFrameRateObjectFromRateIndex(rateIndex).freq
}
function getNicknameFromRateIndex(rateIndex){
  return getFrameRateObjectFromRateIndex(rateIndex).nickname
}


// http://www.davidelkins.com/cam/tables.htm#ft_per_min_frames_per_foot
const kFootageCalculator = {
  // 'Super_8':        {    frames_per_foot: 72,     },
  // 'Super_8_24':     {    frames_per_foot: 20,     },
  // 'Super_8_25':     {    frames_per_foot: 20.83,  },
  // 'Super_8_30':     {    frames_per_foot: 25,     },

  // '16mm':           {    frames_per_foot: 40,     },
  // '16mm_24':        {    frames_per_foot: 36,     },
  // '16mm_25':        {    frames_per_foot: 37.5,   },
  // '16mm_30':        {    frames_per_foot: 45,     },

  // '35mm_2-perf':    {    frames_per_foot: 32,     },
  // '35mm_2-perf_24': {    frames_per_foot: 45,     },
  // '35mm_2-perf_25': {    frames_per_foot: 46.88,  },
  // '35mm_2-perf_30': {    frames_per_foot: 56.25,  },

  // '35mm_3-perf':    {    frames_per_foot: 21.33,  },
  // '35mm_3-perf_24': {    frames_per_foot: 67.5,   },
  // '35mm_3-perf_25': {    frames_per_foot: 70.31,  },
  // '35mm_3-perf_30': {    frames_per_foot: 84.38,  },

  // What pro tools uses for their footage calc
  '35mm_4-perf':    {    frames_per_foot: 16,     },
  '35mm_4-perf_24': {    frames_per_foot: 90,     },
  '35mm_4-perf_25': {    frames_per_foot: 93.75,  },
  '35mm_4-perf_30': {    frames_per_foot: 112.5,  },

  // '65mm_5-perf':    {    frames_per_foot: 12.8,   },
  // '65mm_5-perf_24': {    frames_per_foot: 112.5,  },
  // '65mm_5-perf_25': {    frames_per_foot: 117.19, },
  // '65mm_5-perf_30': {    frames_per_foot: 140.62, },
}

/*
From an audio forum: https://gearspace.com/board/post-production-forum/898755-timecode-feet-frames.html
#define FRAMES_PER_FOOT 16

void print35mm4perfFootage(int frameCount) {
  div_t ff = divmod(frameCount, FRAMES_PER_FOOT);

  printf("%i+%02i",ff.quot, ff.rem);

  // I think zero filled frames is appropriate and meets people's expectations.
      Depends on your requirements.
}
*/

function checkFrameRateKey(value){
  assert(_.isString(value))

  const a = kFrameRateKeyCatalog[value]
  assert(_.isObject(a))
  return true
}

//  We support both strings and numbers.
//  TODO: replace switch with search in kFrameRateKeyCatalog
function findFrameRateKeyFromNickname (nickname0) {
  assert(_.isNumber(nickname0) || _.isString(nickname0))
  const nickname = Number(nickname0)
  switch (nickname) {
    case 24:
      return 'film'

    case 24.000:
      return 'film'

    case 29.97:
      return 'NTSC'

    case 23.97:
      return 'NTSC_Film'
    
    case 23.976:
      return 'NTSC_Film'

    case 23.98:
      return 'NTSC_Film'

    case 25:
      return 'PAL'

    case 25.000:
      return 'PAL'

    case 30:
      return 'web'
      
    case 30.000:
      return 'web'

    
    // not calulated correctly by smpte.js

    // for now we handle these formats as legacy (never put into database from 2022-09-10)
    // and use them as "half speed"

    // case 50:
    //   return 'PAL_HD'
      
    // case 50.000:
    //   return 'PAL_HD'

    // case 59.94:
    //   return 'NTSC_HD'

    // case 60:
    //   return 'web_high'

    // case 60.000:
    //   return 'web_high'
    case 50:
      return 'PAL'
      
    case 50.000:
      return 'PAL'

    case 59.94:
      return 'web'

    case 60:
      return 'web'

    case 60.000:
      return 'web'
      
    default: 
      return undefined
  }
}
function getFrameRateDropframe(frameRateKey){
  assert(checkFrameRateKey(frameRateKey))

  return kFrameRateKeyCatalog[frameRateKey].freq
}

function getFrameRateExactFreqHz(frameRateKey){
  assert(checkFrameRateKey(frameRateKey))

  return kFrameRateKeyCatalog[frameRateKey].freq
}

function prove__getFrameRateExactFreqHz0(){
  const k = getFrameRateExactFreqHz('NTSC')
  assert(k === 30000 / 1001)
}


function getFrameRateNickname(frameRateKey){
  assert(checkFrameRateKey(frameRateKey))

  return kFrameRateKeyCatalog[frameRateKey].nickname
}



function getFrameRateFrameDivision(frameRateKey) {
  assert(checkFrameRateKey(frameRateKey))
  const frameDivision = kFrameRateKeyCatalog[frameRateKey].frameDivision
  return frameDivision
}

function getFrameDropFrame(frameRateKey) {
  assert(checkFrameRateKey(frameRateKey))
  const dropFrame = kFrameRateKeyCatalog[frameRateKey].dropFrame
  return dropFrame
}


////////////////////////////////    SMPTE.js


//  SMPTE.js uses the exact freq to specify frame rates. Docs says 29.97, but SMPTE.js code expects 30000 / 1001.
/*
  export default class FrameRate {
     static FR_23_976 = 24000 / 1001;
     static FR_24 = 24;
     static FR_25 = 25;
     static FR_29_97 = 30000 / 1001;
     static FR_30 = 30;
     static FR_50 = 50;
     static FR_59_94 = 60000 / 1001;
     static FR_60 = 60;
  }
*/

function prove__SMPTEjs_2997(){
  const result = SMPTEtc.isValidTimecode('01:01:00:00', 29.97, false)
  // if((false)){
  //   const pos0 = SMPTEtc.fromFrames(1000, 29.97, false)
  // }
}

// fails!!!
function prove__SMPTEjs5994(){
  const k = getFrameRateExactFreqHz('NTSC_HD')
  assert(SMPTEtc.isValidTimecode('01:36:49:30', k, false))
}


////////////////////////////////    SECONDS VS SMPTE STRINGS


function getSMPTEFormatString(dropFrame){
  assert(_.isBoolean(dropFrame))

  return dropFrame ? '00:00:00;00' : '00:00:00:00'
}

//  00:00:00:00
//  00:00:00;00 -- dropframes uses ;
function secondsToSMPTEString (timeSeconds, frameRateKey) {
  assert(_.isNumber(timeSeconds))
  assert(checkFrameRateKey(frameRateKey))

  const k = getFrameRateExactFreqHz(frameRateKey)

  const tc = SMPTEtc.fromSeconds(timeSeconds, k, false)
  const s = tc.toString(getSMPTEFormatString(false))
  assert(SMPTEtc.isValidTimecode(s, k, false))
  return s
}

function secondsToSMPTEStringByFrameDivision (timeSeconds, frameRateKey) {
  assert(_.isNumber(timeSeconds))
  assert(checkFrameRateKey(frameRateKey))

  const k = getFrameRateFrameDivision(frameRateKey)

  const tc = SMPTEtc.fromSeconds(timeSeconds, k, false)
  const s = tc.toString(getSMPTEFormatString(false))
  assert(SMPTEtc.isValidTimecode(s, k, false))
  return s
}


function prove__secondsToSMPTEString_0(){
  const result = secondsToSMPTEString(0, 'PAL')
  assert(result === '00:00:00:00')
}
function prove__secondsToSMPTEString_1(){
  const result = secondsToSMPTEString(1, 'PAL')
  assert(result === '00:00:01:00')
}
function prove__secondsToSMPTEString_2(){
  const result = secondsToSMPTEString(3600, 'PAL')
  assert(result === '01:00:00:00')
}
function prove__secondsToSMPTEString_3(){
  const result = secondsToSMPTEString(kMaxSMPTE25FPS_Seconds, 'PAL')
  assert(result === kMaxSMPTE25FPS_SMPTE)
}

//  MZ: Test fails, result is 29 frames. Error in SMPTEjs?
function prove__secondsToSMPTEString_4(){
  const result = secondsToSMPTEString(1, 'NTSC')
  console.log(result)
  //assert(result === '00:00:01:00')
}



function SMPTEStringToSeconds (s, frameRateKey) {
  assert(checkFrameRateKey(frameRateKey))
  const k = getFrameRateExactFreqHz(frameRateKey)
  assert(SMPTEtc.isValidTimecode(s, k, false))

  const pos = SMPTEtc.fromTimecode(s, k, false)
  const seconds = pos.durationInSeconds
  assert(_.isNumber(seconds))
  return seconds
}
function prove__SMPTEStringToSeconds_0(){
  const result = SMPTEStringToSeconds('00:00:00:00', 'PAL')
  assert(result === 0)
}
function prove__SMPTEStringToSeconds_1(){
  const result = SMPTEStringToSeconds('00:00:00:01', 'PAL')
  assert(result === 1 / 25)
}
function prove__SMPTEStringToSeconds_2(){
  const result = SMPTEStringToSeconds('00:00:01:00', 'PAL')
  assert(result === 1)
}
function prove__SMPTEStringToSeconds_3(){
  const result = SMPTEStringToSeconds(kMaxSMPTE25FPS_SMPTE, 'PAL')
  assert(result === kMaxSMPTE25FPS_Seconds)
}

function SMPTEStringToSecondsByFrameDivision (s, frameRateKey) {
  assert(checkFrameRateKey(frameRateKey))
  const k = getFrameRateFrameDivision(frameRateKey)
  assert(SMPTEtc.isValidTimecode(s, k, false))

  const pos = SMPTEtc.fromTimecode(s, k, false)
  const seconds = pos.durationInSeconds
  assert(_.isNumber(seconds))
  return seconds
}
// truncates to closest smpte pos based on original framrate being 25
// does NOT validate SMPTE 
function truncateToClosestSMPTEifNeeded (posSMPTE, frameRateKey){
  assert(checkFrameRateKey(frameRateKey))
  const frameRateHz = getFrameRateNickname(frameRateKey)
  return isValidSMPTETimeCode(posSMPTE, Number(frameRateHz), false) ? posSMPTE : truncateToClosestValidSMPTEpos(posSMPTE, frameRateKey)
}

function truncateToClosestValidSMPTEpos (s, frameRateKey) {
  assert(checkFrameRateKey(frameRateKey))
  const k = getFrameRateExactFreqHz(frameRateKey)

  const parts = smpteStringToTimeCodeParts(s, 0)
  const truncated = timecodePartsToSeconds(parts, k)
  const truncatedTC = secondsToSMPTEString(truncated, frameRateKey)
  return truncatedTC
}



////////////////////////////////    SECONDS VS FRAME INDEX



function secondsToFrameIndex (timeSeconds, frameRateKey) {
  assert(_.isNumber(timeSeconds))
  assert(checkFrameRateKey(frameRateKey))

  const k = getFrameRateExactFreqHz(frameRateKey)

  let currentTC = SMPTEtc.fromSeconds(timeSeconds, k, false)
  let frameIndex = currentTC.frameCount
  assert(_.isNumber(frameIndex))
  return frameIndex

/*
  const frameDivision = kFrameRateKeyCatalog[frameRateKey].frameDivision
  const result = timeSeconds * frameDivision
  return result
*/
}

function prove__secondsToFrameIndex_0(){
  const result = secondsToFrameIndex(0, 'PAL')
  assert(result === 0)
}
function prove__secondsToFrameIndex_1(){
  const result = secondsToFrameIndex(1, 'PAL')
  assert(result === 25)
}
function prove__secondsToFrameIndex_2(){
  const result = secondsToFrameIndex(kMaxSMPTE25FPS_Seconds, 'PAL')
  assert(result === kMaxSMPTE25FPS_Seconds * 25)
}
function prove__secondsToFrameIndex_3(){
  const result = secondsToFrameIndex(1, 'NTSC')
  assert(result === 29)
}
function prove__secondsToFrameIndex_Rounding1(){
  const frameIndex = secondsToFrameIndex(8384.72, 'PAL')
  assert(frameIndex === 209617)
}


function frameIndexToSeconds(frameIndex, frameRateKey){
  assert(_.isNumber(frameIndex))
  assert(checkFrameRateKey(frameRateKey))

  const k = getFrameRateExactFreqHz(frameRateKey)

  const pos = SMPTEtc.fromFrames(frameIndex, k, false)
  const seconds = pos.durationInSeconds
  assert(_.isNumber(seconds))
  return seconds

/*
  const frameDivision = kFrameRateKeyCatalog[frameRateKey].frameDivision
  const result = frameIndex / frameDivision
  return result
*/
}

function prove__frameIndexToSeconds_0(){
  const result = frameIndexToSeconds(0, 'PAL')
  assert(result === 0)
}
function prove__frameIndexToSeconds_1(){
  const result = frameIndexToSeconds(1, 'PAL')
  assert(result === 1 / 25)
}
function prove__frameIndexToSeconds_2(){
  const result = frameIndexToSeconds(25, 'PAL')
  assert(result === 1)
}

function prove__frameIndexToSeconds_Rounding1(){
  const posSeconds = frameIndexToSeconds(209618, 'PAL')
  assert(posSeconds === 8384.72)
}

function frameIndexToSMPTEString(frameIndex, frk) {
  assert(_.isNumber(frameIndex))
  assert(checkFrameRateKey(frk))

  const seconds = frameIndexToSeconds(frameIndex, frk)
  return secondsToSMPTEString(seconds, frk)
}

function SMPTEstringToFrameIndex(smpteString, frk) {
  assert(_.isString(smpteString))
  assert(checkFrameRateKey(frk))
  console.log(smpteString)
  const seconds = SMPTEStringToSeconds(smpteString, frk)
  return secondsToFrameIndex(seconds, frk)
}



/*
posFrames: 209617
posFrames2: 209618
posSeconds2: 8384.72
*/
function prove__scenario__conversionPrecisions(){
  const posFrames = secondsToFrameIndex(8384.72, 'PAL')
  const posFrames2 = posFrames + 1
  const posSeconds2 = frameIndexToSeconds(posFrames2, 'PAL')
  assert(posSeconds2 === 8384.72)
}

/*
  Out natural position dimension is seconds. When we calculate using frameindexes we lose precision.
  Use this function to add/subtract frames from seconds.
  It rounds frameindexes downwards to match SMPTE displays etc.

  NOTICE: Some seconds result in frameindex very very close to the next higher frame index.
  We detect this and nudge them upwards.

  Ex: seconds 8384.72 @25 fps => frame index 209617.99999999997. We turn this to 209618
*/

function secondsToFrameIndexInt(posSeconds, frk){
  assert(_.isNumber(posSeconds))
  assert(checkFrameRateKey(frk))

  const frameDivision = kFrameRateKeyCatalog[frk].frameDivision

  const posFrames0 = posSeconds * frameDivision
  const x = Math.floor(posFrames0)
  const distance = posFrames0 - x
  assert(distance >= 0)
  const posFrames = distance > k_frame_round_tolerance ? x + 1 : x
  return posFrames
}

function offsetFramesInt(posSeconds, deltaFrames, frk){
  assert(_.isNumber(posSeconds))
  assert(_.isInteger(deltaFrames))
  assert(checkFrameRateKey(frk))

  const frameDivision = kFrameRateKeyCatalog[frk].frameDivision
  const posFrames = secondsToFrameIndexInt(posSeconds, frk)

  const posFrames2 = posFrames + deltaFrames
  const posSeconds2 = posFrames2 / frameDivision

  if((false)){
    console.log('posSeconds: ' + posSeconds)
    console.log('deltaFrames: ' + deltaFrames)
    console.log('frk: ' + frk)

    console.log('frameDivision: ' + frameDivision)

    console.log('posFrames: ' + posFrames)
    console.log('posFrames2: ' + posFrames2)
    console.log('posSeconds2: ' + posSeconds2)
  }

  return posSeconds2
}
/*
posSeconds: 8384.72
deltaFrames: 1
frk: PAL
frameDivision: 25
posFrames: 209617.99999999997
posFrames2: 209618
posSeconds2: 8384.72
8384.72
*/

/*
posSeconds: 8384.72
deltaFrames: 1
frk: PAL
frameDivision: 25
posFrames0: 209617.99999999997
x: 209617
distance: 0.9999999999708962
posFrames: 209618
posFrames2: 209619
posSeconds2: 8384.76
8384.76
*/

function prove__offsetFramesInt(){
  const posSeconds2 = offsetFramesInt(8384.72, 1, 'PAL')
  console.log(posSeconds2)
  assert(posSeconds2 === 8384.76)
}









////////////////////////////////    PRIMITVE


function calculateTimeSeconds(hours, minutes, seconds, frames, quarterFrames, frameDivision){
  assert(_.isNumber(hours))
  assert(_.isNumber(minutes))
  assert(_.isNumber(seconds))
  assert(_.isNumber(frames))

  assert(_.isNumber(quarterFrames))
  assert(_.isNumber(frameDivision))

  return (seconds * 1) + (minutes * 60) + (hours * 60 * 60) + (frames / frameDivision) + (quarterFrames / frameDivision / 4)
}

function assembleFrameIndex(hours, minutes, seconds, framesWithDecimals, frameDivision){
  assert(_.isNumber(hours))
  assert(_.isNumber(minutes))
  assert(_.isNumber(seconds))
  assert(_.isNumber(framesWithDecimals))
  assert(_.isNumber(frameDivision))

  //  TODO: Make util in Timecode to use from here, from MTC and from MMC.
  const frameIndex =
    (hours * 60 * 60 * frameDivision)
    + (minutes * 60 * frameDivision)
    + (seconds * frameDivision)
    + framesWithDecimals
  return frameIndex
}



////////////////////////////////    TIME CODE PARTS



function makeTimecodeParts(hours, minutes, seconds, frames, subframes8) {
  assert(_.isNumber(hours) && hours >= 0 && hours < 24)
  assert(_.isNumber(minutes) && minutes >= 0 && minutes < 60)
  assert(_.isNumber(seconds) && seconds >= 0 && seconds < 60)
  assert(_.isNumber(frames) && frames >= 0)
   // ??? we get 12 subframes "sometimes", possibly on relocate etc
   assert(_.isNumber(subframes8) && subframes8 >= 0 && subframes8 <= 12)

  const r = { hh: hours, mm: minutes, ss: seconds, ff: frames, sf8: subframes8 }
  assert(checkTimecodeParts(r))
  return r
}

function checkTimecodeParts(value) {
  assert(SC.isObjectInstance(value))
  assert(_.isNumber(value.hh) && value.hh >= 0 && value.hh < 24)
  assert(_.isNumber(value.mm) && value.mm >= 0 && value.mm < 60)
  assert(_.isNumber(value.ss) && value.ss >= 0 && value.ss < 60)
  assert(_.isNumber(value.ff) && value.ff >= 0)
   // ??? we get 12 subframes "sometimes", possibly on relocate etc
  assert(_.isNumber(value.sf8) && value.sf8 >= 0 && value.sf8 <= 12)
  return true
}

function timecodePartsToSeconds(tc, frameDivision){
  assert(checkTimecodeParts(tc))
  assert(_.isNumber(frameDivision) && frameDivision > 0)

  return calculateTimeSeconds(tc.hh, tc.mm, tc.ss, tc.ff, tc.sf8, frameDivision)
}



//  ??? Defects: rounding sf overflows frame-index
//  Only outputs 4 fields. Subframes are rounded to nearst frame
//  Output: 00:00:00:00 ... 23:59:59:29
function timecodePartsToSMPTEString4(tc) {
  assert(checkTimecodeParts(tc))

  const hh = ('0' + tc.hh).slice(-2)
  const mm = ('0' + tc.mm).slice(-2)
  const ss = ('0' + tc.ss).slice(-2)

  // get the subframe raw data, subframe 1-4 means this frame, 5-8 means the next (by the way we read the midi data)
  let sf = ('0' + (tc.sf)).slice(-2)
  let ff
  if (tc.sf > 4) {
    // Add one frame to the frame part of the timecode, and remove 4 subframes from the subframe output
    ff = ('0' + (tc.ff + 1)).slice(-2)
    sf = ('0' + (tc.sf - 4)).slice(-2)
  } else {
    ff = ('0' + (tc.ff)).slice(-2)
  }

  return `${hh}:${mm}:${ss}:${ff}`
}

//??? Does not handle ;
function smpteStringToTimeCodeParts (smpteString, subframe) {
  assert(_.isString(smpteString) && smpteString !== kBlankSMPTEString)
  assert(_.isNumber(subframe))

  const fields = smpteString.split(':')
  return makeTimecodeParts(
    parseInt(fields[0]),
    parseInt(fields[1]),
    parseInt(fields[2]),
    parseInt(fields[3]),
    parseInt(subframe)
  )
}

// TODO: Unify with SMPTE functions
function isValidSMPTETimeCode (SMPTE, nickName, dropFrame = false) {
  const k = getExactFreqHzFromFRNick(nickName)
  const isValid = SMPTEtc.isValidTimecode(SMPTE, k, dropFrame)
  return isValid
}

function getExactFreqHzFromFRNick(nickName){
  const key = findFrameRateKeyFromNickname(nickName)
  return getFrameRateExactFreqHz(key)
}

// NEW 2022-09-04 ->
// adapter to re-calculate legacy marker positions to zero-based + offset for display AND export
function calcMarkerPositionWithOffset(posSeconds, clock) {
  // timecodeOffsetSeconds = 3600 as standard
  // videoFileOffset = 0 for new projects
  const pos = posSeconds - clock.videoFileOffset + clock.timecodeOffsetSeconds
  return pos < 0 ? 0 : pos
}

function calcMarkerPositionWithOffsetForMIDIExport(posSeconds, clock) {
  // on export the offset is calculated separately, we need markers with zero based timecode
  const pos = posSeconds - clock.videoFileOffset
  return pos < 0 ? 0 : pos
}

function handleLegacyVersionOffset (versionDetails) {

  const SMPTEstring0 = '00:00:00:00'
  let offsetSeconds = 0

  if (versionDetails.version_timecode_offset_seconds !== undefined) {
    // new project
    offsetSeconds = versionDetails.version_timecode_offset_seconds
  } else {
    // legacy project
    // Convert `video_file_start_smpte` to seconds and use it as the offset
    const SMPTEstring = versionDetails.video_file_start_smpte
    const frk = findFrameRateKeyFromNickname(versionDetails.video_framerate)
    const k = getFrameRateExactFreqHz(frk)
    const parts = smpteStringToTimeCodeParts(SMPTEstring, 0)
    const truncated = timecodePartsToSeconds(parts, k)
    offsetSeconds = truncated
  }

  // Ensure offset is not negative
  offsetSeconds = Math.max(0, offsetSeconds)

  return {
    SMPTEstring: SMPTEstring0,
    offsetSeconds: offsetSeconds
  }
}

function prove__handleLegacyVersionOffsetLegacyProject() {
  const inputLegacy = {
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 25
  };
  const expectedLegacy = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600
  }
  
  const result = handleLegacyVersionOffset(inputLegacy)
  
  console.log('Legacy Project Test', result)
  assert(_.isEqual(result, expectedLegacy))
}

function prove__handleLegacyVersionOffsetNewProjectWithOffset() {
  const inputNewWithOffset = {
    version_timecode_offset_seconds: 3600,
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 25
  }
  const expectedNewWithOffset = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600
  }
  
  const result = handleLegacyVersionOffset(inputNewWithOffset)
  
  console.log('New Project with Offset Test', result)
  assert(_.isEqual(result, expectedNewWithOffset))
}

function prove__handleLegacyVersionOffsetNewProjectWithoutOffset() {
  const inputNewWithoutOffset = {
    version_timecode_offset_seconds: 0,
    video_file_start_smpte: '00:00:00:00',
    video_framerate: 25,
  }
  const expectedNewWithoutOffset = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 0
  }
  
  const result = handleLegacyVersionOffset(inputNewWithoutOffset)
  
  console.log('New Project without Offset Test', result)
  assert(_.isEqual(result, expectedNewWithoutOffset))
}

function prove__handleLegacyVersionOffset23_976fps() {
  const input = {
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 23.976
  }
  const expected = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600
  }
  
  const result = handleLegacyVersionOffset(input)
  console.log('Legacy Project Test 23.976fps', result)
  assert(_.isEqual(result, expected))
}

function prove__handleLegacyVersionOffset24fps() {
  const input = {
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 24
  }
  const expected = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600 // 1 hour in seconds for 24 fps
  }
  
  const result = handleLegacyVersionOffset(input)
  console.log('Legacy Project Test 24fps', result)
  assert(_.isEqual(result, expected))
}

function prove__handleLegacyVersionOffset29_97fps1() {
  const input = {
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 29.97
  }
  const expected = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600 // 1 hour in seconds adjusted for 29.97 fps
  }
  
  const result = handleLegacyVersionOffset(input)
  console.log('Legacy Project Test 29.97fps', result)
  assert(_.isEqual(result, expected))
}

function prove__handleLegacyVersionOffset30fps() {
  const input = {
    video_file_start_smpte: '01:00:00:00',
    video_framerate: 30
  }
  const expected = {
    SMPTEstring: '00:00:00:00',
    offsetSeconds: 3600 // 1 hour in seconds for 30 fps
  }
  
  const result = handleLegacyVersionOffset(input)
  console.log('Legacy Project Test 30fps', result)
  assert(_.isEqual(result, expected))
}
function prove__handleLegacyVersionOffsetWithVariousScenarios() {
  const tests = [
    { smpte: '00:10:00:00', framerate: 30, expectedSeconds: 600 }, // 10 minutes
    { smpte: '00:00:10:00', framerate: 30, expectedSeconds: 10 }, // 10 seconds
    { smpte: '02:00:00:00', framerate: 30, expectedSeconds: 7200 }, // 2 hours
    { smpte: '01:00:00:00', framerate: 25, expectedSeconds: 3600 },
    { smpte: '01:00:00:01', framerate: 25, expectedSeconds: 3600.04 },
    { smpte: '00:01:00:00', framerate: 23.976, expectedSeconds: 60 },
    { smpte: '00:00:01:01', framerate: 29.97, expectedSeconds: 1.0334 },
    { smpte: '00:00:00:01', framerate: 24, expectedSeconds: 0.0417 },
    { smpte: '00:00:10:15', framerate: 30, expectedSeconds: 10.5 },
  ]

  tests.forEach((test, index) => {
    const input = {
      video_file_start_smpte: test.smpte,
      video_framerate: test.framerate
    }
    const expected = {
      SMPTEstring: '00:00:00:00',
      offsetSeconds: parseFloat(test.expectedSeconds.toFixed(4))
    }
  
    const result = handleLegacyVersionOffset(input)
    result.offsetSeconds = parseFloat(result.offsetSeconds.toFixed(4))
  
    console.log(`Test #${index + 1}: SMPTE ${test.smpte} at ${test.framerate}fps`)
    console.log('Result:', result)
    console.log('Expected:', expected)
    assert(_.isEqual(result, expected))
  })

  console.log('All tests for various scenarios passed!')
}

function runUnitTests () {
  console.log('Timecode.js -- START')

  prove__getFrameRateExactFreqHz0()
  prove__SMPTEjs_2997()
  // prove__SMPTEjs5994()

  prove__secondsToSMPTEString_0()
  prove__secondsToSMPTEString_1()
  prove__secondsToSMPTEString_2()
  prove__secondsToSMPTEString_3()
  prove__secondsToSMPTEString_4()

  prove__SMPTEStringToSeconds_0()
  prove__SMPTEStringToSeconds_1()
  prove__SMPTEStringToSeconds_2()
  prove__SMPTEStringToSeconds_3()


  prove__secondsToFrameIndex_0()
  prove__secondsToFrameIndex_1()
  prove__secondsToFrameIndex_2()
  prove__secondsToFrameIndex_3()
  prove__secondsToFrameIndex_Rounding1()

  prove__frameIndexToSeconds_0()
  prove__frameIndexToSeconds_1()
  prove__frameIndexToSeconds_2()
  prove__frameIndexToSeconds_Rounding1()

  prove__scenario__conversionPrecisions()
  prove__offsetFramesInt()

  prove__handleLegacyVersionOffsetLegacyProject()
  prove__handleLegacyVersionOffsetNewProjectWithOffset()
  prove__handleLegacyVersionOffsetNewProjectWithoutOffset()

  prove__handleLegacyVersionOffset23_976fps()
  prove__handleLegacyVersionOffset24fps()
  prove__handleLegacyVersionOffset29_97fps1()
  prove__handleLegacyVersionOffset30fps()
  prove__handleLegacyVersionOffsetWithVariousScenarios()

  console.log('Timecode.js -- END')
}

module.exports = {
  kBlankSMPTEString,

  kFrameRateKeyCatalog,
  mtcFrameRateIndexToKey,
  getFrameRateObjectFromRateIndex,
  getFrequencyFromRateIndex,
  getNicknameFromRateIndex,

  checkFrameRateKey,
  findFrameRateKeyFromNickname,
  getFrameRateExactFreqHz,
  getFrameRateNickname,
  getFrameRateFrameDivision,
  getFrameDropFrame,

  secondsToSMPTEString,
  SMPTEStringToSeconds,

  // offset etc should be in absolute time => 23.98 etc should still be calculated by the division value
  SMPTEStringToSecondsByFrameDivision,
  secondsToSMPTEStringByFrameDivision,

  truncateToClosestSMPTEifNeeded,

  secondsToFrameIndex,
  frameIndexToSeconds,

  secondsToFrameIndexInt,
  offsetFramesInt,

  frameIndexToSMPTEString,
  SMPTEstringToFrameIndex,

  makeTimecodeParts,
  checkTimecodeParts,
  timecodePartsToSeconds,

  assembleFrameIndex,

  timecodePartsToSMPTEString4,
  smpteStringToTimeCodeParts,

  isValidSMPTETimeCode,
  getExactFreqHzFromFRNick,

  calcMarkerPositionWithOffset,
  calcMarkerPositionWithOffsetForMIDIExport,

  handleLegacyVersionOffset,

  runUnitTests
}

