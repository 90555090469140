<template>
  <!-- Wrapper for the upload widget -->
  <div class="uploadWidget-wrapper">
    <!-- Container for the main content -->
    <div
      style="background: none; height: 100%"
    >
      <!-- Upload box -->
      <div class="upload-box">
        <!-- Drop area for files -->
        <div
          class="drop"
          :class="getStyle(caller).classes"
          @drop.prevent="addFiles($event, 'drop', inEditWindow)"
          @click="openBrowseFiles"
          @dragover.prevent
          v-if="!uploading"
        >
        <!-- Tooltip for the upload area -->
        <v-tooltip bottom :color="colors.black">
          <template v-slot:activator="{ on }">
            <!-- Card for the upload area -->
            <v-card 
              v-on="on"
              elevation="2"
              class="pointer-on-hover"
              :style="getStyle(caller).card"
            >
              <!-- Text for the upload area -->
              <span :style="getStyle(caller).span">{{inEditWindow ? 'Add files to project' : 'Add project'}}</span>
              <!-- Icon for adding files -->
              <v-icon id="createCircle" size="40" :style="getStyle(caller).icon">mdi-plus-circle-outline</v-icon>
            </v-card>
          </template>
          <!-- Additional information and supported file types -->
          <div style="padding: 4px 4px 10px 4px;">
            <div style="text-align: left; line-height: 2; font-weight: 600; color: purple;">{{ inEditWindow ? 'Click or drag and drop to add files to this project ' : 'Click or drag and drop' }}</div>

            <div style="text-align: left; line-height: 2; font-size: 0.8em;">Supported files:</div>
            <div style="text-align: left; line-height: 1; font-size: 0.8em;"><v-icon small>mdi-video</v-icon> .mp4 files with h264 codec</div>
            <div style="text-align: left; line-height: 1; font-size: 0.8em;"><v-icon small>mdi-volume-high</v-icon> .wav or .mp3</div>
          </div>
        </v-tooltip>
        </div>


        <input
          type="file"
          ref="fileInput"
          @change="addFiles($event, 'fileInput')"
          accept="audio/wav, audio/mp3, video/mp4, video/webm"
          style="display: none;"
          multiple
        />
        <!-- Display while uploading -->
        <div v-if="uploading">
          <p>Uploading...</p>
        </div>
      </div>


      <!-- Spinner for indicating progress -->
      <div style="position: absolute; top: 40%; width: 100%; color: black">
        <v-progress-circular
          v-if="spinner"
          :size="40"
          :width="7"
          :color="colors.mainAccent"
          indeterminate
        >
        </v-progress-circular>
      </div>
    </div>
    
    <!-- Hidden elements for video and audio processing -->
    <video id="video" controls style="display:none;" crossorigin="anonymous"></video>
    <canvas id="canvas" width="480" height="270" style="display:none;"></canvas>
    <canvas ref="audioCanvas" width="480" height="270" style="display:none;" crossorigin="anonymous"></canvas>

    <!-- Dialog for displaying upload status -->
    <v-dialog
      dark
      persistent
      width="480"
      v-model="uploading"
      overlay-opacity="0.95"
    >
      <!-- Dialog content -->
      <div
        style="
          text-align: center;
          background: black;
          border: 2px solid #ccc;
          border-radius: 10px;
        "
      >
        <!-- Upload status label -->
        <p class="upload-status">
          {{ getStatusLabel() }}
        </p>
        
        <!-- Uploaded file items -->
        <div class="upload-item"
          v-for="upload in fileUploads"
          :key="`upload-${upload.fileHash}`"
          :class="{hasError: upload.error, canceled: upload.canceled, done: upload.uploadProgress === 100}"
        >
          <!-- Close button for cancelling uploads -->
          <v-icon class="close-btn" :disabled="upload.preparing" @click="cancelUpload(upload)">cancel</v-icon>
          <!-- File information -->
          <div class="item-info">
            <div class="file-name">
              <p>
                {{ upload.file.name }}
              </p>
              <span>
                <!-- Badge for indicating successful upload -->
                <v-icon
                  class="done-badge"
                  color="green"
                  small
                >
                  mdi-check-decagram
                </v-icon>
                <!-- Error message for failed uploads -->
                <span v-if="upload.error" class="file-error">Could not upload file. Please check file type and try again</span>
              </span>
            </div>
            <!-- Upload progress -->
            <span class="upload-progress" v-if="!upload.canceled && !upload.preparing">
              <div class="upload-percent">
                <span>{{ upload.uploadProgress || '0' }} %</span>
              </div>
              <v-progress-linear
                :rotate="360"
                :value="upload.uploadProgress"
              />
            </span>
            
            <!-- Message while preparing upload -->
            <div v-if="upload.preparing" class="upload-preparing">
              <v-progress-circular
                :size="20"
                :width="2"
                :color="colors.mainAccent"
                indeterminate
              />
              <span>Analyzing file...</span>
            </div>
          </div>
        </div>
        
        <!-- Buttons for canceling or closing the dialog -->
        <div style="padding: 30px">
          <v-btn v-if="uploadingInProgress" :disabled="!fileTransferStarted" @click="cancelUpload()">
            Cancel
          </v-btn>
          <v-btn class="close-dialog-btn" v-if="!uploadingInProgress" :disabled="!fileTransferStarted && !hasErrors" @click="uploadStopped">CANCEL</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { MediaUploader } from './MediaUploader'
import { mimes } from '@/../source_files/spotterfish_library/utils/MimeTypes'
import VideoPlayerUtils from '@/../source_files/spotterfish_library/utils/VideoPlayerUtils'

const findMimeType = (file) => {
  if(!file.type) throw new Error('This does not seem to be a supported file?')

  const matchingMime = mimes.find(mime => mime.m === file.type)

  if(matchingMime?.isSupported){
    return matchingMime.hasVideo
  }
  throw new Error(`This format ${file.type} is not supported, please convert to a supported format before uploading.`)
}

export default {
  name: 'uploadwidget',
  components: {},
  props: ['projectUID', 'shouldUpdateVersion', 'caller'],
  data() {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      fileUploads: [
        // {
        //   fileHash: 'abab',
        //   file: {
        //     name: '352410435-biljetter olof wretling.pdf'
        //   },
        //   uploadProgress: 0,
        //   error: true
        // },
        // {
        //   fileHash: 'abab12',
        //   file: {
        //     name: '2. 011054544-patagonia-beagle-channelmp4-su_preview.mp4'
        //   },
        //   uploadProgress: 100,
        // },
        // {
        //   fileHash: 'abab122',
        //   file: {
        //     name: '2. 011054544-patagonia-beagle-channelmp4-su_preview.mp4'
        //   },
        //   canceled: false,
        //   uploadProgress: 65,
        //   preparing: true
        // },
      ],
      uploading: false,
      pausedTask: false,
      status: 'PREPARING UPLOAD OF',
      spinner: false,

      uploader: undefined,
      fileTransferStarted: false,
    }
  },
  computed: {
    uploadingInProgress(){
      return this.fileUploads.filter(upload => !upload.error && !upload.canceled).some(upload => upload.uploadProgress < 100)
    },
    hasErrors(){
      return this.fileUploads.some(upload => upload.error)
    },
    inEditWindow() {
      return this.caller === 'projectEdit'
    }
  },
  methods: {
    getStyle(caller) {
      switch (caller) {
        case 'projectEdit':
          return { 
            card: `
              aspect-ratio: 32/9; 
              border-radius: 6px;
              height: 100%;
              display: flex;
              flex-direction: column;
              background-color: ${this.colors.mx.xDark} !important; 
              background-clip: content-box;
              align-items: center;`,
            span: 'margin-top: 5%;',
            icon: 'margin-top: 3%; margin: auto;',
            classes: ''
          }
        case 'dashboardButton':
        return { 
          card: `
            max-width: 92%; 
            margin: -10px auto; 
            height: 27px;
            background-color: #303136 !important; 
            border-radius: 4px;`,
          span: `
            font-family: Roboto,sans-serif !important; 
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            font-size: .8rem;
            letter-spacing: .0892857143em 
            font-weight: 500 !important; 
            color: #FFFFFF;
            margin-top: 14px;`,
          icon: 'color: #FFFFFF; font-size: 1em; margin: 2px 0 0 4px;',
          classes: ''
        }      
        default:
          return {
            card: `
              border-radius: 6px;
              margin-top: -38px;
              width: 100%;
              height: 70%;
              display: flex;
              flex-direction: column;
              justify-content: center;`,
            span: 'margin-top: 14px;',
            icon: 'margin: 20px;',
            classes: 'grid-item'
          }
      }
    },
    openBrowseFiles(){
      console.log('should open file selector', this.$refs.fileInput)
      this.$refs.fileInput.click()
    },
    setUploadValue(fileHash, value){
      const thisIdx = this.fileUploads.findIndex(upload => upload.fileHash === fileHash)

      // for vue3:
      // this.fileUploads[thisIdx] = {
      //   ...this.fileUploads[thisIdx],
      //   ...value
      // }
      this.$set(this.fileUploads, thisIdx, {
        ...this.fileUploads[thisIdx],
        ...value
      })
    },
    getStatusLabel(){
      if(this.pausedTask){
        return 'Paused'
      }
      let label = 'Preparing upload'
      if(this.uploadingInProgress){
        label = 'Uploading'
      } else {
        label = 'Upload'
        if(this.hasErrors){
          label = `${label} (with errors)`
        }
      }
      return label
    },

    async getThumbnail (file) {
      console.log(file)
      return new Promise(async (resolve, reject) => {
        try {

          const hasVideo = findMimeType(file)
          if (hasVideo === false) {

            const canvas = this.$refs.audioCanvas
            const context = canvas.getContext('2d')
            const audioCtx = new (window.AudioContext || window.webkitAudioContext)()

            try {
              const audioWaveformURL = await VideoPlayerUtils.generateAudioWaveform(file, canvas, audioCtx, context, this.colors.mx.secondary)
              console.log('Image URL:', audioWaveformURL)
              resolve(audioWaveformURL)
            } catch (error) {
              console.error('Error generating waveform:', error)
              resolve(undefined)
            }
          }

          const objectURL = URL.createObjectURL(file)
          const video = document.getElementById('video')
          const canvas = document.getElementById('canvas')
          const context = canvas.getContext('2d')
  
          video.src = objectURL
  
          video.addEventListener('seeked', function() {
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            const imageUrl = canvas.toDataURL('image/jpg')
            console.log(imageUrl)
            resolve(imageUrl)
          })
  
          video.addEventListener('loadeddata', function() {
            try {
              // move to 20 % in before taking screenshot
              video.currentTime = video.duration * 0.2
            } catch (error) {
              console.log(error)
              resolve(undefined)
            }
          })
  
  
          video.addEventListener('error', (e) => {
            console.log('Error occurred:', video.error)
            resolve(undefined)
          })
  
          video.load()            
        } catch (error) {
          resolve(undefined)
        }
      })
    },
    async addFiles(event, type, startFlag) {
      if (startFlag === false) {
        return
      }

      this.uploading = true;
      this.spinner = true
      console.log('Add files:', event, type)
      const projUID = this.projectUID || this.$route.query.project
      const fileList = type === 'fileInput' ? event.target.files : event.dataTransfer.files
      
      let thumbnailImages = []

      for (let index = 0; index<fileList.length; index++) {
        try {
          thumbnailImages[index] = await this.getThumbnail(fileList[index])
        } catch (error) {
          thumbnailImages[index] = undefined
        }
      }

      console.log(thumbnailImages)
      
      const dispatchAction = (action, data) => this.$store.dispatch(action, data)
      const onStatusChange = async (status, data) => {
        switch(status){
          case 'fileAdded':
            this.fileUploads.push(data)
            break;
          case 'prepareDone':
            this.setUploadValue(data.fileHash, {
              preparing: false
            })
            break;
          case 'uploadPathError':
            this.showMessage(`Could not get upload path (${data})`)
            break;
          case 'uploadStarted':
            console.log('Upload started with files:', data)
            break;
          case 'paused':
            this.pausedTask = true
            break;
          case 'uploadingInProgress':
            this.pausedTask = false
            this.fileTransferStarted = true

            if(data.uploadProgress !== undefined){
              this.setUploadValue(data.fileHash, {
                uploadProgress: data.uploadProgress
              })
            }
            break;
          case 'addError':
            this.setUploadValue(data.fileHash, {
              error: true,
              preparing: false
            })
            break;
          case 'firebaseUploadError':
            console.error('Error uploading file (firebase):', data)
            this.uploadStopped()
            break;
          case 'uploadError':
            console.error('Error uploading file:', data)
            if(!['not-found', 'storage/canceled'].includes(data.code)){
              this.showMessage('Error uploading file, please try again')
            }
            this.uploadStopped()
            break;
          case 'fileUploaded':
            this.setUploadValue(data.fileHash, { uploadProgress: 100 })
            console.log('File done!', data)
            break;
          case 'allFilesUploaded':
            if(!data.errors){
              this.uploadStopped()
            }
            this.$emit('project-uploaded', data.projectId)
            break;
          case 'uploadError':
            console.error('uploadError', data)
            this.uploadStopped()
            this.showMessage(data.message)
            break;
          case 'fileCanceled':
            this.setUploadValue(data.fileHash, { canceled: true })
            break;
          case 'uploadCanceled':
            this.uploadStopped()
            break;

        }
      }
      
      this.uploader = MediaUploader({fileList, projectId: projUID, shouldUpdateVersion: this.shouldUpdateVersion, dispatchAction, onStatusChange, spotterfishSession: App.spotterfishSession, thumbnailImages})

      try{
        this.uploader.startUpload()
      } catch(error){
        console.error(error)
        this.uploading = false
        this.showMessage(error.message)
      }
    },

    async showMessage(text){
      await this.$root.$globalAlert.open(
          'File error',
          {
            line1: text,
          },
          { color: this.colors.mainAccent }
        )
    },

    uploadStopped(){
      // After an upload is done or cancelled or failed
      this.uploading = false
      this.spinner = false
      this.fileTransferStarted = false
      this.fileUploads = []
      this.$refs.fileInput.value = ''
      this.$emit('upload-stopped')
    },
    
    cancelUpload(upload){
      if(upload){
        console.log('Cancel:', upload)
        this.uploader.cancelUpload(upload.fileHash)
      } else {
        this.uploader.cancelAll()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.uploadWidget-wrapper {
  width: 100%;
  height: 100%;

}
.drop {
  color: white;
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
}
.upload-box {
  background-color: none;
  padding: 0 0 0 0;
  text-align: center;
  border: none;
  border-radius: 2px;
}
.upload-status{
  text-transform: uppercase;
  font-weight: bold;
  padding: 30px 0 5px 0;
}
.upload-item{
  padding: 1rem;
  background: #1d1d1d88;
  margin: 0.5rem 1rem;
  display: flex;
  border-radius: 3px;
  &.hasError{
    color: #f47f7f;
    .close-btn{
      visibility: hidden;
    }
  }
  &.canceled{
    color: rgb(187, 187, 187);
    .close-btn{
      visibility: hidden;
    }
  }
  &.done{
    .file-name{
      .done-badge{
        display: inline-block;
      }
    }
    .close-btn{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .close-btn{
    margin-right: 1rem;
    cursor: pointer;
  }
  .item-info{
    width: 90%;
  }
  .file-name{
    display: flex;
   
    max-width: 25.5rem;
    .done-badge{
      display: none;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      text-align: left;
    }
    span {
       font-weight: bold;
      text-align: left;
    }
  }
  .upload-preparing{
    text-align: left;
    margin-top: 0.5rem;
    span{
      margin-left: .5rem;
    }
  }
  .upload-progress{
    margin-top: 1rem;
    .upload-percent{
      /* width: 3rem; */
      font-size: 11px;
      text-align: right;
    }
    .v-progress-linear{
      width: 100%;
    }
  }
  .file-error{
    font-size: 11px;
    text-align: left;
    display: block;
  }
}
.horisontal-lines-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  position: relative;
  margin: 0.75rem 0 0.5rem 0;
}
.horisontal-line {
  height: 1px;
  background-color: #e9e9ed;
  padding: 1px;
  width: 2.5rem;
}
.browse-files-button {
  width: 8rem;
  height: 2.25rem;
  margin-bottom: 1rem;
  background: #6b91c5;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  transition-duration: 0.4s;
}
.browse-files-button:hover {
  opacity: 0.9;
}
</style>
