const CryptoJS = require('crypto-js')
const SpotterfishCore = require('./SpotterfishCore')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert


function makeHash (inputString) {
  assert(SpotterfishCore.isStringInstance(inputString))

  console.log(`Hashing input input string "${inputString}"`)

  const hash = CryptoJS.SHA256(inputString)
  const string = CryptoJS.enc.Base64.stringify(hash)
  const shortString = string.substring(0, 14)

  console.log(`Calculated short hash string "${shortString}"`)

  return shortString
}


function prove_makeHash () {
  // This example is from when we used the 'sha2' package, just to make sure the
  // behaviour didn't change when switching crypto package.
  unitTestAssert(makeHash("fCFfwtY62SlYdaRzZX4c") === "rmSAU868zU268A")

  SpotterfishCore.unitTestAssertThrowsError(makeHash, [null])
  SpotterfishCore.unitTestAssertThrowsError(makeHash, [[1, 2, 3]])
  SpotterfishCore.unitTestAssertThrowsError(makeHash, [{foo: 123}])
}

function runUnitTests () {
  console.log('Crypto.js -- START')

  prove_makeHash()

  console.log('Crypto.js -- END')
}

module.exports = {
  makeHash,
  runUnitTests
}
