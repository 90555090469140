// @ts-nocheck

function normalizeDate(created_date) {
    if (typeof created_date === 'string') {
        // It's an ISO date string
        return new Date(created_date).getTime();
    } else if (created_date && ('seconds' in created_date || '_seconds' in created_date)) {
        // Firebase Timestamp
        let seconds = created_date.seconds || created_date._seconds;
        let nanoseconds = created_date.nanoseconds || created_date._nanoseconds || 0;
        return seconds * 1000 + nanoseconds / 1e6;
    } else {
        throw new Error('Unknown date format');
    }
}

/*
  Date may be passed in as:
   1. undefined
   2. string
   3. epoch number
   4. date object
*/

function prettyFormatDate(date) {

  let _date
  const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
  ];

  if (date === undefined) { return '' }

  try {
    if (typeof date === 'string') {
        _date = new Date(date)
    }
    else if (typeof date === 'number') {
      _date = new Date(date)
    }
    else {
      _date = date
    }
    const day = _date.getDate();
    const monthIndex = _date.getMonth();
    const year = _date.getFullYear();
  
    return `${monthNames[monthIndex]} ${day}, ${year}`;    
  } catch (error) {
    return ''
  }
}


function splitProjectsByType (projects) {
    const splitProjects = {
      myProjects: [],
      sharedProjects: [],
      demoProjects: [],
    }
    projects.forEach(element => {
      if(element.is_demo_project === true) {
        splitProjects.demoProjects.push(element)
      }
      else {
        splitProjects.myProjects.push(element)
      }
    })
    
    splitProjects.myProjects.sort((a, b) => normalizeDate(b.created_date) - normalizeDate(a.created_date));
    console.log(splitProjects)
    return splitProjects
  }

  
module.exports = {
    prettyFormatDate,
    normalizeDate,
    splitProjectsByType
}
  