<template>
  <div>
    <div style="padding: 2px 0 8px 0; width: 1000px; overflow: hidden;" v-show="!isDemoProjectsRow" class="grid-item auto-height">
      <div style="text-align: left; line-height: 2; font-weight: 600; color: purple;">Click to Edit</div>

      <div style="text-align: left; line-height: 2; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">Name: {{ project.project_name }}</div>
      <div style="text-align: left; line-height: 1; font-size: 0.8em;">Description: {{ project.project_description ? project.project_description : '-' }}</div>
      <div style="text-align: left; line-height: 1; font-size: 0.8em;">Tags: <span style="font-weight: 600;">{{ prettyPrintTags(project.project_description_tags) }}</span></div>

      <div style="text-align: left; line-height: 1; font-size: 0.8em;">Created: {{ normDate(project.created_date) }}</div>
      <div style="text-align: left; line-height: 1; font-size: 0.8em;">Currently Open: {{ project.used_by?.length ? 'Yes' : 'No' }}</div>
    </div>

    <div style="padding: 4px 4px 10px 4px;" v-show="isDemoProjectsRow">
      <div style="text-align: left; line-height: 2; font-weight: 600; color: purple;">Demo Project</div>

      <div style="text-align: left;">Name: {{ project.project_name }}</div>
      <div style="text-align: left; line-height: 1; font-size: 0.8em;">Description: Can not be edited</div>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

import DisplayUtils from '@/lib/ui/DisplayUtils'


export default {
  props: ['project', 'isDemoProjectsRow'],
  components: {
  },
  name: 'tooltipContent',
  data: function () {
    return {
      // UI
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
    }
  },
  methods: {
    prettyPrintTags (stringsArray) {
      return stringsArray.length ? stringsArray.join(' ') : '-'
    },

    normDate (date) {
      return DisplayUtils.prettyFormatDate(DisplayUtils.normalizeDate(date))
    },
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.bordered {
  border: 8px solid #6592c8 !important;
}

.plain {
  border: 2px solid rgb(60, 60, 60);
}

.opaque-on-hover {
  opacity: 0.5;
}

.opaque-on-hover:hover {
  opacity: 0.2;
}

.edit-project-overlay {
  height: 3rem;
  transition: 0ms;
}
.project-card .edit-project-overlay {
  visibility: hidden;
}
.project-card:hover .edit-project-overlay {
  visibility: visible;
}
.project-name{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.4rem;
  max-width: 95%;
  display: inline-block;
}

.auto-height {
  height: auto !important;
  aspect-ratio: auto !important;
}
</style>
