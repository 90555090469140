<template>
  <v-app dark style="overflow: hidden; height: 100%; max-width: 100vw;">
    <spotterfishHeader 
      ref="header"
      :setSpinner="setSpinner" 
      :setLoadingMessage="setLoadingMessage" 
      :accountTier="accountTier" 
      :isDAWstreamer="isDAWstreamer" 
      :intercomLoaded="intercomLoaded"
      :isStripeUser="isStripeUser()"
      @recalculate-feature-bits="recalculateFeatureBits()"
      @request-start-session="openScreeningRoom()"
      @request-toggle-menu="toggleMenu()"
      @request-open-purchase-dialog="onRequestOpenPurchaseDialog($event)"
    />
    <v-navigation-drawer
      :fixed="!isSmallScreen"
      v-model="dashboardDrawer"
      app
      width="290"
      background
      left
      class="dark-panel darken"
      style="border-right: 0.1px solid;"
      :style="{ 'border-color': colors.darkGray }"
    >
      <v-container fluid style="width: 100%; padding: 0;">
        <v-row no-gutters style="width: 100%;" class="pl-3 pr-3">

            <div  class="pt-4 pl-4 pr-4 pb-9" style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                <img
                  :class="isSmallScreen ? 'mixstage-logo-small mt-1' : 'mixstage-logo-large'"
                  :src="logos.mixStageLogoWithWhiteText"
                />
              <v-btn
                v-if="isSmallScreen"
                style="margin-right: -8px;"
                fab
                dark
                x-small
                color="gray"
                @click="toggleMenu"
              >
                  <v-icon dark> mdi-chevron-left </v-icon>
              </v-btn>
            </div>
            
          <v-col cols="12" class="text-left mb-4">
            <userAccountMenu :intercomLoaded="intercomLoaded" :isStripeUser="isStripeUser()" @request-toggle-intercom="gotoHelp()"/>
          </v-col>

          <v-col cols="12" class="pb-6">
            <v-text-field
              :style="{ width: '92%', margin: 'auto' }"
              color="white"
              dark
              compact
              small
              single-line
              hide-details
              solo
              :append-icon="$store.state.dashboardProjectSearch ? 'mdi-close-circle-outline' : 'mdi-magnify'"
              @click:append="$store.state.dashboardProjectSearch = ''"
              v-model="$store.state.dashboardProjectSearch"
              label="Search Projects"
              placeholder="Search Project"
            >
              <template v-slot:label>
                <span style="font-size: 0.9em">Search Projects</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" class="mb-5">
            <v-btn
              :style="{ width: '92%' }"
              :color="colors.mx.dawStreamerButton"
              @click="openDawStreamerPanel"
              small
              dark
            >
              Open DAW streamer
            </v-btn>
          </v-col>

          <v-col cols="12">
            <uploadwidget @project-uploaded="onProjectUploaded" caller="dashboardButton" style="width: 100%;"></uploadwidget>
          </v-col>

        </v-row>
      </v-container>
    </v-navigation-drawer>

      <v-main>
        <v-row no-gutters justify="start" style="height: 100%;" class="dark-panel">
          <v-col cols="12" style="padding: 0;">
            <!-- tabs are part of the top navigation -->
            <v-tabs-items v-model="$store.state.dashboardTab">
              <v-tab-item eager style="height: 100%; min-height: 0px;" class="dark-panel">
                <projects ref="projects" :caller="'dashboard'" @request-load-in-mixstage="handleAddProjectToHash" @request-open-purchase-dialog="onRequestOpenPurchaseDialog($event)" :setSpinner="setSpinner" :setLoadingMessage="setLoadingMessage" :viewOption="$store.state.dashboardView" />
              </v-tab-item>
              <v-tab-item eager style="height: 100%;" class="dark-panel">
                <screeningrooms :setSpinner="setSpinner" ref="screeningRooms" :accountTier="accountTier" :isDAWstreamer="isDAWstreamer" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

      <!-- MODALS -->

      <v-overlay
        attach="#videoPlayerRow"
        :value="showSpinner"
        persistent
        width="400"
        elevation="0"
        color="#080808"
        :opacity="0.65"
      >
        <v-row align="center" justify="center">
          <spinner :showSpinner="showSpinner" :loadingMessage="loadingMessage" />
        </v-row>
      </v-overlay>
      
      <v-dialog v-model="showPaymentDone" max-width="400" overlay-opacity="0.95">
        <v-card :style="{ background: colors.mx.panel }" style="border: 1px solid #1e1e1e">
          <v-card-title>Upgrade account</v-card-title>
          <v-card-text style="text-align: left; margin: 20px 0 0 4px;">
            <p>{{ paymentDoneMessage }}</p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
    <!-- <spotterfishFooter /> -->

    <newUpdateBox ref="newUpdateBox"></newUpdateBox>
    <confirmBox ref="confirm"></confirmBox>


  </v-app>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { ref, computed } from 'vue'
import store from '@/store'
import newUpdateBox from '@/components/UI/newUpdateBox.vue'
import confirmBox from '@/components/UI/confirm.vue'
import spotterfishHeader from '@/components/dashboard/header.vue'
import spotterfishFooter from '@/components/dashboard/footer.vue'
import projects from '@/components/dashboard/projects.vue'
import screeningrooms from '@/components/dashboard/screeningrooms.vue'
import spinner from '@/components/dashboard/mixStageSpinner.vue'
import uploadwidget from '@/components/projectscomponents/uploadWidget_restyle.vue'
import userAccountMenu from '@/components/userscomponents/userAccountMenu.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import SpotterfishHelpers from '@/../source_files/spotterfish_library/SpotterfishHelpers'
import { trackWindowSize } from '@/lib/ui/windowUtils'

export default {
  name: 'dashboard',
  components: {
    newUpdateBox,
    confirmBox,
    spotterfishHeader,
    spotterfishFooter,
    screeningrooms,
    projects,
    spinner,
    uploadwidget,
    userAccountMenu
},
  setup (props, { emit } ) {
    const { windowWidth } = trackWindowSize()
    const colors = require('@/lib/ui/colors.js').colors
    const logos = require('@/lib/ui/logos.js').logos
    const showSpinner = ref(false)
    const showPaymentDone = ref(false)
    const loadingMessage = ref('')
    const paymentDoneMessage = ref('')
    const dashboardDrawer = ref(true)
    const analytics = {
      didLogFacebookOpenedDashboard: false,
    }
    const accountTier = ref(undefined)
    const isDAWstreamer = ref(false)
    const tab = ref(undefined)
    const emailVerified = ref(false)
    const intercomLoaded = ref(false)
    const intercomOpen = ref(false)
    const checkForTrailingSessionInterval = ref(undefined)
    const isSmallScreen = computed(() => {
      return windowWidth.value < 1264
    })
    return {
      colors,
      logos,
      showSpinner,
      showPaymentDone,
      loadingMessage,
      paymentDoneMessage,
      analytics,
      accountTier,
      isDAWstreamer,
      tab,
      emailVerified,
      intercomLoaded,
      intercomOpen,
      checkForTrailingSessionInterval,
      isSmallScreen,
      store,
      dashboardDrawer
    }
  },
  methods: {
    openDawStreamerPanel() {
      this.dawStreamerPanel = window.open(
        `${window.location.origin + '/#/'}dawstreamer`,
        'PROJECTOR ROOM',
        'left=100,top=100,width=640,height=660, titlebar=no, toolbar=no, location=no, menubar=no,status=no'
      )
      this.dawStreamerPanel.document.title = 'Mix Stage DAW Streamer'

      const timer = setInterval(() => {
        if (this.dawStreamerPanel.closed) {
          clearInterval(timer)
          // console.log('daw streamer window was closed')
        }
      }, 1000)
    },
    // all events to open purchaseDialog are emitted down here, and then shown via header.
    async onRequestOpenPurchaseDialog (e) {
      console.log('onRequestOpenPurchaseDialog', e)
      await this.$refs.header.openpurchaseDialog(e)
    },

    consoleLog (v) {
      console.log(v)
    },

    isStripeUser () {
      return this.$store.state.currentSpotterfishUser?.payment_data?.stripe_id ? true : false
    },

    onProjectUploaded(project) {
      const id = project['.key'] ? project['.key'] : project
      this.$refs.projects.onNewProjectEditRequest(id)
    },

    async recalculateFeatureBits () {
      this.accountTier = await CloudClient.call_CFgetAccountTier(App.Firebase)
      this.isDAWstreamer = App.spotterfishSession.userSession.ufb.user?.daw_streaming
    },
    
    logFacebookOpenedDashboard() {
      if (this.analytics.didLogFacebookOpenedDashboard === false) {
        // eslint-disable-next-line
        fbq('trackCustom', 'OpenDashboard')
        this.analytics.didLogFacebookOpenedDashboard = true
      }
    },

    handleScreeningRoomImageReady() {
      this.screeningRoomLogoScale = 1
    },

    setSpinner(isSpinning){
      this.showSpinner = isSpinning
    },
    setLoadingMessage(loadingMessage){
      this.loadingMessage = loadingMessage
    },
    openScreeningRoom (projectId) {
      this.$refs.screeningRooms.enterScreeningRoom(undefined, projectId)
    },
    async checkURLParams() {
      console.log(this.$route.query)
      if(this.$route.query.open_billing_and_plans === 'true')
      { 
        this.setSpinner(true)
        try {
          const result = await CloudClient.call_CFcreateStripePortalSession(App.Firebase)
          const url = result.url
          console.log(url)
          window.location.href = url
          this.storingspinner = false

        } catch (error) {
          alert('could not initialize payment portal', error)
          this.storingspinner = false

        }
        
      }
      if(this.$route.query.paymentSuccess === 'true')
      {
        this.showPaymentDone = true
        this.paymentDoneMessage = 'Thank you for your purchase! Please allow for a minute for the changes to take effect.'
      }
      if(this.$route.query.paymentSuccess === 'false'){
        this.showPaymentDone = true
        this.paymentDoneMessage = 'Purchase cancelled'
      }
      if(this.$route.query.openScreeningRoom === 'true'){     
        console.log('should open sceeening room')     
        const conf = await this.$root.$globalConfirm.open('You have been invited to a project', {
          line1: `Do you want to open a screening room to watch it?`,
          line2: `${ this.$route.query.projectName ? 'Project: ' + this.$route.query.projectName : '' }`,
          line3: `Once in - click on the project on the left.`
        },
        { color: this.colors.mainAccent })
        // TODO: add the dbid to hash
        if (conf) { this.openScreeningRoom() }
      }
    },
    async handleAddProjectToHash (projectId) {
      this.openScreeningRoom(projectId)
    },
    toggleMenu() {
      this.dashboardDrawer = !this.dashboardDrawer
    },
    gotoHelp() {
      if(window.window.Intercom) {
        if (this.intercomOpen) {
          window.Intercom('hide')
        }
        else {
          window.Intercom('show')
        }
        this.intercomOpen = !this.intercomOpen
      }
    },
    loadIntercom (hideFlag) {
        if (window.Intercom) return // Already loaded
        const user = this.$store.state.currentSpotterfishUser
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://widget.intercom.io/widget'
        script.onload = () => {
          window.Intercom('boot', {
            app_id: 'r2pragn0',
            email: user.email,
            created_at: new Date().getTime(),
            name: user.user_name,
            user_id: user.user_id
          })
          window.Intercom('showSpace', 'home')
          window.Intercom('showSpace', 'messages')
          if (hideFlag) { window.Intercom('hide') }
          else { window.Intercom('show') }
          this.intercomLoaded = true
        }
        document.head.appendChild(script)
    },
    unloadIntercom () {
      if (window.Intercom) {
        window.Intercom('shutdown')
      }
      
      const script = document.querySelector('script[src="https://widget.intercom.io/widget/r2pragn0D"]')
      if (script) {
        script.remove()
      }
      
      // Optionally delete the Intercom object to completely remove it
      delete window.Intercom
      this.intercomLoaded = false
      this.intercomOpen = false
      console.log(window.Intercom)
    }
  },
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::LIFECYCLE HOOKS::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  async beforeMount() {
    CloudClient.call_wakeUpAllCloudFUnctions(App.Firebase)
    this.accountTier = await CloudClient.call_CFgetAccountTier(App.Firebase)
    this.isDAWstreamer = App.spotterfishSession.userSession.ufb.user?.daw_streaming

    App.spotterfishSession.userSession.addSessionListener('user_business_data', this.recalculateFeatureBits)

    // If a message was passed when routing here, display it in a dialog.
    const messageId = this.$route.params.messageId
    if (messageId === 'room_changed') {
      await this.$root.$globalAlert.open(
        'Room configuration changed',
        {
          line1:
            'The session was interrupted because the screening room configuration changed.',
          line3: 'No data has been lost. Please rejoin the room.',
        },
        {
          color: this.colors.mainAccent,
        }
      )
    }
  },

  async mounted() {

    await this.$store.dispatch('checkBrowser', this.$root.$globalConfirm)
    // hiding alert hee as well - no need to
    let needsPageRefresh = await SpotterfishHelpers.alertIfSessionError(this.$root.$globalAlert, this.colors.mainAccent, true)
    if (needsPageRefresh) {
      window.location.reload()
    }
    this.checkForTrailingSessionInterval = setInterval(async () => {
      needsPageRefresh = await SpotterfishHelpers.alertIfSessionError(this.$root.$globalAlert, this.colors.mainAccent, true)
      if (needsPageRefresh) {
        window.location.reload()
      }
    }, 1000)
    
    this.checkURLParams()
    this.loadIntercom(true)
    this.dashboardDrawer = !this.isSmallScreen
  },

  beforeDestroy() {
    clearInterval(this.checkForTrailingSessionInterval)
    this.unloadIntercom()
  }
}
</script>

<style lang="scss" scoped>

$color-gray-1: #505050;
$color-gray-2: #2F2F2F;
$color-gray-3: #2D2D2D;
$color-gray-4: #2B2B2B;
$color-gray-5: #1E1E1E;
$color-black2: #181818;

.mixstage-logo-large {
  display: block; 
  height: 54px; 
  margin: 20px auto 40px;
}
.mixstage-logo-small {
  display: block; 
  height: 24px; 
}

.close-menu {
  width: 100%; 
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  background-color: $color-gray-5;
}

.v-icon.v-icon:after {
  background-color: transparent;
  border-radius: 0;
}

.titletext {
  font-family: 'Raleway', sans-serif;
  color: #688fee;
  font-size: 40px;
}

.bullettext {
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 36px;
}

.slightly-rounded {
  border-radius: 4px;
}

.selected {
  background-color: rgba(var(--v-theme-lightpanel)) !important;
}

.bordered {
  border: 8px solid #6592c8 !important;
}

.plain {
  border: 2px solid rgb(60, 60, 60);
}

.noborder {
  border: none;
}

.opaque {
  opacity: 0.1;
}

.nonOpaque {
  opacity: 1;
}

.opaque-on-hover {
  opacity: 1;
}

.opaque-on-hover:hover {
  opacity: 0.6;
}

.v-list-item:hover {
  background: #333333;
}

.title-font {
  font-size: 1.4em;
}

.scaleable-wrapper {
  resize: both;
  position: relative;
  background: none;
}

.scaler {
  width: 1024px;
  height: 1024px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.85);
  transform-origin: center center;
}

.text-hover:hover span {
  color: #7195ef;
}

@media only screen and (max-width: 1850px) {
  .title-font {
    font-size: 1em;
  }
}

.no-tooltip {
  pointer-events: none;
}

.room-name-wrapper {
  width: 100%;
  line-height: 1.14;
}

.room-name {
  width: 100%;
  overflow-x: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

#avatarEditorCanvas {
  display: block;
}

.support-button {
  text-align: right;
  color: white;
  border-radius: 50%;
  font-size: 2em !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.account-menu-item {
  font-size: 0.8em;
}


</style>
