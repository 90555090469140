//#TEMP: This snippet demonstate a Nuendo problem on return-to-start-pos-on-stop: it sends fullframe (=OK) then sends more QFs (= BAD)

const kNuendo_stopProblem =
`
22:39:19.755	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
22:39:19.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 33
22:39:19.766	From IAC Driver Bus 1	MTC Quarter Frame		F1 4B
22:39:19.774	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
22:39:19.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 61
22:39:19.791	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
22:39:19.799	From IAC Driver Bus 1	MTC Quarter Frame		F1 03
22:39:19.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
22:39:19.856	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
22:39:19.856	From IAC Driver Bus 1	MTC Quarter Frame		F1 33
22:39:19.856	From IAC Driver Bus 1	MTC Quarter Frame		F1 4B
22:39:19.856	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
22:39:19.856	From IAC Driver Bus 1	MTC Quarter Frame		F1 61
22:39:19.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
22:39:19.866	From IAC Driver Bus 1	MTC Quarter Frame		F1 05
22:39:19.874	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
22:39:19.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
22:39:19.891	From IAC Driver Bus 1	MTC Quarter Frame		F1 33
22:39:19.899	From IAC Driver Bus 1	MTC Quarter Frame		F1 4B
22:39:19.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
22:39:19.956	From IAC Driver Bus 1	MTC Quarter Frame		F1 61
22:39:19.956	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
22:39:19.956	From IAC Driver Bus 1	MTC Quarter Frame		F1 07
22:39:19.956	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
22:39:19.956	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
22:39:19.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 33
22:39:19.966	From IAC Driver Bus 1	MTC Quarter Frame		F1 4B
22:39:19.974	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
0	From IAC Driver Bus 1	SysEx		F0 7F 7F 01 01 61 2B 35 08 F7
22:39:19.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 61
22:39:19.991	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
22:39:19.999	From IAC Driver Bus 1	MTC Quarter Frame		F1 09
22:39:20.008	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
22:39:20.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
22:39:20.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 33
22:39:20.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 4B
22:39:20.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
22:39:20.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 61 
`

//After stop we get a few queued -up messages, and if they are long enough to get a lock we start playing.


/*
# Nuendo

# 2022-05-30 Nuendo

# Setup Nuendo + MTC & MMC output:
Preferences / MIDI / Sync / MIDI sync Project Settings
MTC  and MMC Checked for IAC Driver

# RESULT

1. Sends no start message
2. During loop QF are sent +  Sysex full frame on relocate
*/


//# Playing short loop: 01:00:01:28 --- 01:00:02:29, @30fps

const kNuendo_playingShortLoop =
`
20:26:17.142	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.142	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.149	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:17.157	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:17.165	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.174	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.182	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.190	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.199	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.207	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.215	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:17.224	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:17.232	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.256	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.256	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.257	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.265	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.274	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.282	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:17.290	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:17.299	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.307	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.315	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.324	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.332	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.369	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.369	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:17.369	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:17.369	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.374	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.382	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.390	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.399	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.407	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.415	From IAC Driver Bus 1	MTC Quarter Frame		14
20:26:17.424	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:17.432	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.483	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.484	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.484	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.484	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.484	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.484	From IAC Driver Bus 1	MTC Quarter Frame		0
20:26:17.490	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.499	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.507	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.515	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.524	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.532	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		2
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.597	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.599	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.607	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.615	From IAC Driver Bus 1	MTC Quarter Frame		4
20:26:17.624	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.632	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.709	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.710	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 01 1C F7
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.710	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.715	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.724	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.732	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.740	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:17.830	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.832	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.840	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:17.892	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:17.899	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:17.907	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:17.915	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:17.930	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:17.938	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		33
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		0
20:26:18.009	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.013	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.022	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.030	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.038	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.120	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.120	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.120	From IAC Driver Bus 1	MTC Quarter Frame		2
20:26:18.120	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.121	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.121	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.121	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.121	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.121	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.122	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.130	From IAC Driver Bus 1	MTC Quarter Frame		4
20:26:18.138	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.223	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.223	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.223	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.223	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.224	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.230	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.238	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:18.339	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.347	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.412	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.413	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.422	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.430	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.438	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.447	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		14
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.522	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.530	From IAC Driver Bus 1	MTC Quarter Frame		0
20:26:18.538	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.547	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		2
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.633	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.638	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.647	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		4
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.699	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.705	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.713	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.722	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.730	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:18.731	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 01 1C F7
20:26:18.738	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.747	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.814	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.815	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.822	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.830	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.838	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.847	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.928	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:18.929	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:18.932	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:18.938	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:18.947	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		33
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		0
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.052	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.057	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.066	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		2
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.121	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.124	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.132	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.141	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.149	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.157	From IAC Driver Bus 1	MTC Quarter Frame		4
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.227	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:19.232	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.241	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.249	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.257	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.333	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.334	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.334	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.341	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.349	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.357	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:19.366	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		12
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.461	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.666	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		14
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		16
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		0
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.667	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		2
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.668	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.747	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.747	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.747	From IAC Driver Bus 1	MTC Quarter Frame		4
20:26:19.747	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 01 1C F7
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.748	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.749	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.757	From IAC Driver Bus 1	MTC Quarter Frame		6
20:26:19.766	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.868	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 01 1C F7
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		8
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		48
20:26:19.868	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 01 0C F7
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		64
20:26:19.868	From IAC Driver Bus 1	MTC Quarter Frame		80
20:26:19.874	From IAC Driver Bus 1	MTC Quarter Frame		97
20:26:19.882	From IAC Driver Bus 1	MTC Quarter Frame		118
20:26:19.938	From IAC Driver Bus 1	MTC Quarter Frame		10
20:26:19.938	From IAC Driver Bus 1	MTC Quarter Frame		17
20:26:19.938	From IAC Driver Bus 1	MTC Quarter Frame		34
20:26:19.938	From IAC Driver Bus 1	MTC Quarter Frame		48
`


const kNuendo_locates = undefined


//# Play linearly from pos 0. Notice: sends MMC STOP *and* MMC LOCATE when user hits stop

const kNuendo_playLinearAndStop =
`
20:27:14.881	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 09 01 F7
20:27:16.638	From IAC Driver Bus 1	MTC Quarter Frame		1
20:27:16.651	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.655	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.663	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:16.671	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:16.680	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:16.688	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:16.696	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:16.705	From IAC Driver Bus 1	MTC Quarter Frame		3
20:27:16.713	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.721	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.730	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:16.738	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:16.764	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:16.764	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:16.764	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:16.771	From IAC Driver Bus 1	MTC Quarter Frame		5
20:27:16.780	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.788	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.796	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:16.805	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:16.813	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:16.821	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:16.830	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:16.838	From IAC Driver Bus 1	MTC Quarter Frame		7
20:27:16.873	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.873	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.874	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:16.874	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:16.880	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:16.888	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:16.896	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:16.905	From IAC Driver Bus 1	MTC Quarter Frame		9
20:27:16.913	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.921	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.930	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:16.938	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		11
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:16.989	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:16.996	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.005	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.013	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.021	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.030	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.038	From IAC Driver Bus 1	MTC Quarter Frame		13
20:27:17.110	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.110	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.110	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.110	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.110	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.111	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.111	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.111	From IAC Driver Bus 1	MTC Quarter Frame		15
20:27:17.113	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.121	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.130	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.138	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.245	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		1
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.246	From IAC Driver Bus 1	MTC Quarter Frame		3
20:27:17.247	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		5
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.340	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.347	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		7
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		9
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.476	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.483	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		11
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		13
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		41
20:27:17.603	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.605	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.613	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		1
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		3
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.728	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:17.730	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		5
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		7
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:17.870	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.871	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.880	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		9
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:17.962	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:17.963	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:17.971	From IAC Driver Bus 1	MTC Quarter Frame		11
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		13
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.062	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.063	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.071	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.179	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.179	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		15
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		1
20:27:18.180	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		3
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.288	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.289	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.296	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		5
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.373	From IAC Driver Bus 1	MTC Quarter Frame		7
20:27:18.380	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.388	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		9
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.477	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.480	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.488	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.590	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.590	From IAC Driver Bus 1	MTC Quarter Frame		11
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		13
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		17
20:27:18.591	From IAC Driver Bus 1	MTC Quarter Frame		42
20:27:18.596	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		1
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.661	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.663	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.671	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.680	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.688	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		3
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.768	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.771	From IAC Driver Bus 1	MTC Quarter Frame		5
20:27:18.780	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.788	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		7
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.881	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.888	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.998	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		9
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		11
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:18.999	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		118
20:27:19.118	From IAC Driver Bus 1	SysEx		Universal Real Time 10 bytes	F0 7F 7F 01 01 61 00 09 01 F7
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		13
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		16
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		43
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		48
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		64
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		80
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		97
20:27:19.118	From IAC Driver Bus 1	MTC Quarter Frame		118
`



module.exports = {
	kNuendo_stopProblem,
	kNuendo_playingShortLoop,
	kNuendo_locates,
	kNuendo_playLinearAndStop
}
