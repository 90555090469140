// @ts-nocheck

/*
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::Put all logos and global images here and require them with:::::::::
:::::::::::::::::::::::logos: require('@/lib/ui/logos.js').logos::::::::::::::::::::
:::::::::::::::::::::and then v-bind:src="logos.logoKeppel" etc::::::::::::::::::
*/

exports.logos = {
  allowAutoPlay: require('@/assets/images/img/allowAutoPlay.png'),
  backward: require('@/assets/images/spotterfishIcons/backward.svg'),
  bubble1: require('@/assets/images/spotterfishIcons/bubble1.svg'),
  bubble2: require('@/assets/images/spotterfishIcons/bubble2.svg'),
  camera: require('@/assets/images/spotterfishIcons/camera.svg'),
  centeredSmall: require('@/assets/images/spotterfishIcons/Avatar-logo-centered.svg'),
  invitedImage: require('@/assets/images/spotterfishIcons/invited.svg'),
  chairImage: require('@/assets/images/spotterfishIcons/chair.svg'),
  chairImageCentered: require('@/assets/images/spotterfishIcons/chairNew.svg'),
  closeInspectorHover: require('@/assets/images/spotterfishIcons/button_closeinspector_mouseoverselect.svg'),
  closeInspectorRegular: require('@/assets/images/spotterfishIcons/button_closeinspector_regular.svg'),
  crown: require('@/assets/images/spotterfishIcons/crown.svg'),
  crownEmpty: require('@/assets/images/spotterfishIcons/crownEmpty.svg'),
  deleteMarkerHover: require('@/assets/images/spotterfishIcons/button_deletemarker_mouseoverselect.svg'),
  deleteMarkerRegular: require('@/assets/images/spotterfishIcons/button_deletemarker_regular.svg'),
  exit: require('@/assets/images/spotterfishIcons/exit.svg'),
  fiveChairs: require('@/assets/images/5chairs.png'),
  fivechairsAndScreen: require('@/assets/images/5_chairs.png'),
  forward: require('@/assets/images/spotterfishIcons/forward.svg'),
  icons: {
    marker: require('@/assets/images/spotterfishIcons/spotterfish_marker.svg'),
    add: require('@/assets/images/spotterfishIcons/add.svg')
  },
  logoPosterImageBlack: require('@/assets/images/spotterfishIcons/Logo_fishicon-whitebg-min.png'),
  logoPosterImageBlackSmallSquare: require('@/assets/images/spotterfishIcons/Logo_fishicon-whitebg-min-square.png'),
  logoPosterImageWhite: require('@/assets/images/spotterfishIcons/Logo_fishicon-blackbg-min.png'),
  logoPosterImageWhipPlayerWhite: require('@/assets/images/spotterfishIcons/whipStream.png'),

  logoPosterImageWhiteSmaller: require('@/assets/images/spotterfishIcons/Logo_fishicon-blackbg-min-smaller.png'),
  logoWithTextSmall: require('@/assets/images/Logo-horz-whitetext_spotterfish.png'),
  mixStageLogoOnly: require('@/assets/images/logoSmaller.webp'),
  mixStageLogoOnlySmall: require('@/assets/images/logo-x-small.webp'),
  mixStageLogoOnlyAvatar: require('@/assets/images/logo-fullwidth.webp'),

  mixStageLogoWithWhiteText: require('@/assets/images/cropped-MixStage-logo-1.webp'),
  mixStageLogoWithBlackText: require('@/assets/images/cropped-MixStage-logo-1.webp'),

  manychairsAndScreen: require('@/assets/images/manychairs_and_screen.png'),
  mic: require('@/assets/images/spotterfishIcons/mic.svg'),
  movieScreen: require('@/assets/images/moviescreen_center.png'),
  pause: require('@/assets/images/spotterfishIcons/pause.svg'),
  screeningRoom: require('@/assets/images/spotterfishIcons/icon_screeningroom-film.svg'),
  sflogoWhite: require('@/assets/images/spotterfishLQfull.png'),
  sflogoWhiteSmallCentered: require('@/assets/images/sf_small_centered.png'),
  speaker0: require('@/assets/images/spotterfishIcons/speaker0.svg'),
  speaker1: require('@/assets/images/spotterfishIcons/speaker1.svg'),
  speaker2: require('@/assets/images/spotterfishIcons/speaker2.svg'),
  speaker3: require('@/assets/images/spotterfishIcons/speaker3.svg'),
  tenchairsAndScreen: require('@/assets/images/10_chairs.png'),
  tickets: require('@/assets/images/tickets.png'),
  twochairsAndScreen: require('@/assets/images/2chairs_and_screen.png'),
  popcorn: require('@/assets/images/spotterfishIcons/popcorn.svg'),
  cameraOff: require('@/assets/images/spotterfishIcons/video-off.svg'),
  arrowBack: require('@/assets/images/spotterfishIcons/arrow_back_ios.svg')

}
