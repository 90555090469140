<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card style="text-align: left;">
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="subtitle" class="pa-4">{{ subtitle }}</v-card-text>
      <v-select
          v-if="selectionData"
          style="width: 60%; margin: auto;"
          :items="selectionData"
          v-model="selectedPhone"
          label="Phones"
          solo>
      </v-select>
      <v-text-field v-else autofocus outlined color="white" style="width: 96px; margin: 40px auto 0 auto; font-size: 21px; padding: 0;" v-model="verificationCode"></v-text-field>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="options.color" @click="agree">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// @ts-nocheck

const moment = require('moment')

export default {
  data: () => ({
    logos: require('@/lib/ui/logos.js').logos,
    dialog: false,
    resolve: null,
    reject: null,
    verificationCode: null,
    title: null,
    subtitle: null,
    selectionData: null,
    selectedPhone: undefined,
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200
    }
  }),
  computed: {
    theDate () {
      const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
      moment.locale(getNavigatorLanguage())
      return moment(this.message.date).format('LLLL')
    }
  },
  methods: {
    open (title, subtitle, selectionData, options) {
      this.dialog = true
      this.title = title
      this.subtitle = subtitle
      if (selectionData) {
        this.selectionData = []
        for (const phone of selectionData) {
          this.selectionData.push(phone.phoneNumber)
        }
        this.selectedPhone = this.selectionData[0]
      }

      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      // return verificationCode or selected index
      let returnValue = this.verificationCode || this.selectionData.findIndex(data => data === this.selectedPhone)
      this.resolve(returnValue)
      this.dialog = false
      this.resolve = null
      this.reject = null
      this.verificationCode = null
      this.title = null
      this.subtitle = null
      this.selectionData = null
      this.selectedPhone = null
    },
    cancel( ) {
      this.agree()
    }
  }
}
</script>
