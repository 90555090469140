<template>
    <div>
        <div style="margin-top: auto; margin-bottom: auto;" class="tooltip-trigger top center" :data-tooltip="tooltipMessage"> 
        <v-btn @click="toggleDisabled" style="width: 2rem" :disabled="!enabled" :color="isActive ? 'gray' : 'warning'" icon="">
        <v-icon>mdi-alpha-i-box</v-icon>
        </v-btn>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        enabled: {
            type: Boolean,
            default: true
        }
    },
  data(){
      return {
          isActive: false,
      }
  },
  computed: {
      tooltipMessage() {
            if (!this.enabled) return 'Input Monitoring toggle: Available when you are streaming.'
            return this.isActive ? 'Input Monitor Muted: Click to hear your DAW stream' : 'Input Monitor Active: Click to mute your own DAW stream'
      }
  },
  methods: {
      toggleDisabled() {
          this.isActive = !this.isActive;
          this.$emit('click', this.isActive)
          // console.log("toggle:", this.isDisabled)
      },
  }
}
</script>