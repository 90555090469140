<template>
  <v-dialog modal persistent fullscreen v-model="dialog" style="position:relative; z-index: 2147483647;">
    <div style="display: flex; align-items: center; justify-content: center; height: 100%; vidth: 100%; background: black;">
      <v-card style="width: 40%; min-width: 600px; margin: 2% auto;">

        <div style="display: flex; align-items: center; justify-content: space-between;">
          <v-btn style="margin-left: 10px; width: 100px;" @click="moveBack()"><v-icon>mdi-chevron-left</v-icon>BACK</v-btn>
          <p style="font-size: 2em; margin-top: 10px;">Two-factor Authentication</p>
          <p style="width: 100px;"></p>
        </div>

        <v-stepper v-model="twofactorPage">
          <v-stepper-header>
            <v-stepper-step :complete="twofactorPage > 1" :color="colors.mainAccent" step="1">LOG IN</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="twofactorPage > 2" :color="colors.mainAccent" step="2">ADD PHONE NUMBER</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="twofactorPage > 3" :color="colors.mainAccent" step="3">VERIFY</v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card style="height: 500px;">
                  <v-layout justify-center>
                    <v-flex xs12>
                      <form>
                      <v-layout
                        wrap
                        align-center
                        justify-center
                        justify-content-center>
                        <v-flex xs8 style="margin-top: 100px;">
                          <v-text-field
                            disabled
                            outlined
                            dark
                            dense
                            autofocusclass="mt-6 transparent"
                            type="text"
                            v-model="email"
                            placeholder="Email"
                            @keyup.enter="login"/>
                        </v-flex>
                        <v-flex xs8>
                          <v-text-field
                            dark
                            outlined
                            dense
                            type="password"
                            v-model="password"
                            placeholder="Password"
                            @keyup.enter="start"/>
                        </v-flex>
                        <v-flex xs12>
                            <v-btn dark large :style="{background: colors.mainAccent}" class="lighten-2 mt-5 elevation-5" @click="login">
                              Log In
                            </v-btn>
                          <br/>
                        </v-flex>
                      </v-layout>
                      </form>
                    </v-flex>
                  </v-layout>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">

              <v-card style="height: 500px;">
                  <v-layout justify-center>
                    <v-flex xs12>
                      <form>
                      <v-layout
                        wrap
                        align-center
                        justify-center
                        justify-content-center>
                        <v-flex xs10 style="margin-top: 140px;">
                          <vue-phone-number-input
                            :preferred-countries="['US', 'SE', 'FR']"
                            fetch-country
                            id="phoneInput"
                            :valid-color="colors.mainAccent"
                            required
                            outlined
                            @update="updatedPhoneNumber"
                            dark
                            dense
                            autofocus
                            class="mt-6"
                            v-model="phone"
                            @keyup.enter="startVerification"/>
                        </v-flex>
                        <v-flex xs12 style="margin-top: 100px;">
                            <div id="recaptchacontainer"></div>
                            <v-btn :disabled="!phoneNumberValid" dark large :style="{background: colors.mainAccent}" class="lighten-2 mt-5 elevation-5" @click="startVerification">
                              Send Verification
                            </v-btn>
                          <br/>
                        </v-flex>
                          <v-flex xs12 style="margin-top: 20px;" v-if="mayRemove">
                            <v-btn @click="$store.dispatch('cf_remove_second_factor_from_user').then(() => {
                              $root.$globalAlert.open(`DONE`, {line1: 'All devices unregistered'},{ color: colors.mainAccent })
                              }).catch((error) => { $root.$globalAlert.open(`Error`, {line1: error.message},{ color: colors.mainAccent })})">
                              REMOVE ALL PREVIOUSLY REGISTERED DEVICES
                            </v-btn>
                          <br/>
                        </v-flex>
                      </v-layout>
                      </form>
                    </v-flex>
                  </v-layout>
              </v-card>

            </v-stepper-content>

            <v-stepper-content step="3">

              <v-card style="height: 500px;">
                  <v-layout justify-center>
                    <v-flex xs12>
                      <form>
                      <v-layout
                        wrap
                        align-center
                        justify-center
                        justify-content-center>
                        <v-flex xs12 style="margin-top: 100px;">
                          <p>Enter the code we have sent to your phone number: {{interNationalFormatedNumber}} </p>
                        </v-flex>
                        <v-flex xs4 style="margin-top: 10px; text-align: center;">
                          <v-text-field
                            type="text"
                            id="codeInput"
                            required
                            outlined
                            dark
                            dense
                            autofocus
                            class="mt-6 centered-input"
                            style="text-align: center; width: 5em; margin: auto;"
                            v-model="verificationCode"
                            color="white"
                            @keyup.enter="finishVerification"/>
                        </v-flex>
                        <v-flex xs12 style="margin-top: 100px;">
                            <v-btn :disabled="!verificationCodeValid" dark large :style="{background: colors.mainAccent}" class="lighten-2 mt-5 elevation-5" @click="finishVerification">
                              FINISH
                            </v-btn>
                          <br/>
                        </v-flex>
                      </v-layout>
                      </form>
                    </v-flex>
                  </v-layout>
              </v-card>

            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'


export default {
  data () {
    return {
      twofactorPage: 1,
      dialog: true,
      colors: require('@/lib/ui/colors.js').colors,
      password: undefined,
      appVerifier: undefined,
      phone: undefined,
      phoneNumber: undefined,
      phoneNumberValid: undefined,
      verificationId: undefined,
      verificationCode: '',
      user: undefined,
      mayRemove: false
    }
  },
  components: {
    'vue-phone-number-input': VuePhoneNumberInput
  },
  computed: {
    email () {
      return App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser.email
    },
    verificationCodeValid () {
      return this.verificationCode.length === 6 || false
    },
    interNationalFormatedNumber () {
      if (this.phoneNumber && this.phoneNumber.formatInternational) {
        return this.phoneNumber.formatInternational
      } else {
        return ''
      }
    }
  },
  methods: {
    updatedPhoneNumber (phoneNumberData) {
      console.log(JSON.stringify(phoneNumberData))
      this.phoneNumberValid = phoneNumberData.isValid
      this.phoneNumber = phoneNumberData
    },
    moveBack () {
      if (this.twofactorPage === 1) {
        console.log('should route back')
        this.$router.push({ name: 'dashboard' })
          .catch((e) => {
            console.log(e)
          })
      } else {
        this.twofactorPage = this.twofactorPage - 1
      }
    },
    async login () {
      App.Firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          console.log('signed id correctly with user and password')
          this.twofactorPage += this.twofactorPage
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            // Handle other errors such as wrong password.
            this.$root.$globalAlert.open(
              `Wrong password`, {
                line1: error.message
              },
              { color: this.colors.mainAccent })
          } else if (error.code === 'auth/multi-factor-auth-required') {
            this.twofactorPage += this.twofactorPage
            // This will happen if multi factor auth is already enabled, which is fine. Moving on
            console.log(error)
          } else {
            this.$root.$globalAlert.open(
              `Error`, {
                line1: error.message
              },
              { color: this.colors.mainAccent })
          }
        })
    },
    async startVerification () {
      console.log('started Verification process')
      const self = this
      this.user = App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser
      console.log(this.user)
      let appVerifier
      try {
        appVerifier = await new App.Firebase.auth.RecaptchaVerifier('recaptchacontainer', {'size': 'invisible'})
      } catch (error) {
        console.log(error)
      }

      const credential = App.Firebase.auth.EmailAuthProvider.credential(
        this.email,
        this.password
      )
      console.log('Signing in with credentials: ', credential)
      try {
        await this.user.reauthenticateWithCredential(credential)
        await getSession()
      } catch (error) {
        // Already enrolled a phone, need to use that to log in again
        if (error.code === 'auth/multi-factor-auth-required') {
          let resolver = error.resolver
          // Ask user which second factor to use.
          console.log(resolver.hints)

          let hintIndex = 0

          if (resolver.hints.length && resolver.hints.length > 1) {
            hintIndex = await self.$root.$globalPrompt.open(`Two-factor authentication`,
              'Please select which phone we should send the pass code to:',
              resolver.hints, { width: '500px', color: self.colors.mainAccent }
            )
          }

          console.log('hintIndex', hintIndex)
          if (resolver.hints[hintIndex].factorId === App.Firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
            var phoneInfoOptions = { multiFactorHint: resolver.hints[hintIndex], session: resolver.session }
            const phoneAuthProvider = new App.Firebase.auth.PhoneAuthProvider()
            console.log(phoneInfoOptions)
            // Send SMS verification code
            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier)
              .then(async function (verificationId) {
                console.log(verificationId)

                // Ask user for the SMS verification code.
                const verificationCode = await self.$root.$globalPrompt.open(`Enter verification code`,
                  `Sent to PREVIOUSLY REGISTERED: ${resolver.hints[hintIndex].displayName}`,
                  null, { width: '500px', color: self.colors.mainAccent }
                )
                var cred = App.Firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
                var multiFactorAssertion = App.Firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
                // Complete sign-in.
                return resolver.resolveSignIn(multiFactorAssertion)
              })
              .then(function (userCredential) {
                // User successfully signed in with the second factor phone number.
                // Continue with adding another phone
                self.mayRemove = true
                getSession()
              }).catch((error) => {
                self.$root.$globalAlert.open(
                  `Error`, {
                    line1: error.message
                  },
                  { color: this.colors.mainAccent })
                  .then((confirm) => {
                    // Does this work
                    console.log('code wrong, try again')
                  })
              })
          } else {
            // Unsupported second factor.
            self.$root.$globalAlert.open(
              `Unsupported Second Factor`, {
                line1: error.message
              },
              { color: this.colors.mainAccent })
          }
        } else if (error.code === 'auth/wrong-password') {
          // Handle other errors such as wrong password.
          self.$root.$globalAlert.open(
            `Wrong password`, {
              line1: error.message
            },
            { color: this.colors.mainAccent })
        } else {
          self.$root.$globalAlert.open(
            `Error`, {
              line1: error.message
            },
            { color: this.colors.mainAccent })
        }
      }
      async function getSession () {
        const phoneNumber = self.phoneNumber.formatInternational

        self.user.multiFactor.getSession().then(function (multiFactorSession) {
          // Specify the phone number and pass the MFA session.
          var phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
          }
          var phoneAuthProvider = new App.Firebase.auth.PhoneAuthProvider()
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions, appVerifier)
        })
          .then((verificationId) => {
            self.verificationId = verificationId
            self.twofactorPage = 3
          })
          .catch((error) => {
            self.$root.$globalAlert.open(
              `Error`, {
                line1: error.message
              },
              { color: self.colors.mainAccent })
              .then(() => {
                window.location.reload()
              })
            console.log(error)
          })
      }
    },
    async finishVerification () {
      // Ask user for the verification code.
      try {
        var cred = App.Firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.verificationCode)
        var multiFactorAssertion = App.Firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
        // Complete enrollment.
        console.log('finishing up enrollment')
        const mfaDisplayName = `Phone number ending with: ${this.phoneNumber.phoneNumber.slice(-3)}`
        await this.user.multiFactor.enroll(multiFactorAssertion, mfaDisplayName)
        console.log('enrolement completed')
        this.$root.$globalAlert.open(
          `Success`, {
            line1: 'Your phone number has been added to your account'
          },
          { color: this.colors.mainAccent })
          .then((confirm) => {
            if (this.$route.query && this.$route.query.hash) {
              this.$router.push({
                name: 'lobby',
                query: this.$route.query
              })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              this.$router.push('dashboard')
                .catch((e) => {
                  console.log(e)
                })
            }
          })
          .catch((error) => {
            console.log(error)
            this.$root.$globalAlert.open(
              `Wrong code`, {
                line1: 'Please try again.'
              },
              { color: this.colors.mainAccent })
              .then(() => {
                window.location.reload()
              })
          })
      } catch (error) {
        console.log(error)
        this.$root.$globalAlert.open(
          `Error`, {
            line1: error.message
          },
          { color: this.colors.mainAccent })
          .then((confirm) => {
            // Do we need to clear recaptcha here?
            if (error.message === 'Non-development mode Session Info given in development mode request.') {
              if (this.$route.query.hash) {
                this.$router.push({
                  name: 'lobby',
                  query: this.$route.query
                })
                  .catch((e) => {
                    console.log(e)
                  })
              } else {
                this.$router.push('dashboard')
                  .catch((e) => {
                    console.log(e)
                  })
              }
            }
          })
      }
    }
  },
  mounted () {
  }
}
</script>

<style>
</style>
