import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'

const setUserIsTalking = (screeningRoomSession, userId, isTalking) => {
  const userTalkingPath = `shared_state/${screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy['.key']}/users/${userId}/talking`
  screeningRoomSession.screeningRoomConnection.spotterfishSession.firebase.database().ref(userTalkingPath).set(isTalking)
}
const toggleVideoMute = (screeningRoomSession, userId) => {
  const isMuted = screeningRoomSession.screeningRoomConnection.refinedSharedState?.users?.[userId]?.video_muted === true
  // Update local janus instance
  screeningRoomSession.videoChat.setVideoMute(!isMuted)

  const userVideoMutePath = `shared_state/${screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy['.key']}/users/${userId}/video_muted`
  screeningRoomSession.screeningRoomConnection.spotterfishSession.firebase.database().ref(userVideoMutePath).set(!isMuted)
  return !isMuted
}
const toggleAudioMute = (screeningRoomSession, userId) => {
  
  const isMuted = screeningRoomSession.screeningRoomConnection.refinedSharedState?.users?.[userId]?.audio_muted === true
  // Update local janus instance
  screeningRoomSession.videoChat.setAudioMute(!isMuted)
  if(isMuted){
    // If muted, it will be unmuted after update so we set mute_all to false
    ScreeningRoomSession.updateRoomSharedState(screeningRoomSession, {mute_all: false})
  }

  const userAudioMutePath = `shared_state/${screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy['.key']}/users/${userId}/audio_muted`
  screeningRoomSession.screeningRoomConnection.spotterfishSession.firebase.database().ref(userAudioMutePath).set(!isMuted)
  return !isMuted
}
const setBathroomMirrorStatus = (screeningRoomSession, userId, onOff) => {
  const path = `shared_state/${screeningRoomSession.screeningRoomConnection.screeningRoomDBCopy['.key']}/users/${userId}/in_bathroom_mirror`
  screeningRoomSession.screeningRoomConnection.spotterfishSession.firebase.database().ref(path).set(onOff)
}

export {
  setUserIsTalking,
  toggleVideoMute,
  toggleAudioMute,
  setBathroomMirrorStatus
}