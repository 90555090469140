const WebClientImplementation = require('./WebClientImplementation')

//  f: f(progress_percentage)
//  output: { publicURL: downloadUrl, metainfo: meta }
async function UploadLogoToScreeningRoom_TopLevel (firebase, storageRef, fileName, fileData, progress_f) {
  return WebClientImplementation.uploadFileToStorage(firebase, storageRef, 'room_logos/' + fileName, fileData, progress_f)
}

async function UploadAccountAvatar_TopLevel (firebase, storageRef, fileName, fileData, progress_f) {
  return WebClientImplementation.uploadFileToStorage(firebase, storageRef, 'profile_pics/' + fileName, fileData, progress_f)
}

module.exports = {
  UploadLogoToScreeningRoom_TopLevel,
  UploadAccountAvatar_TopLevel
}
