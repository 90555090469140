<template>
  <div class="mx-select-wrapper">
    <select
      v-model="selectedValue"
      @change="emitChange"
      :disabled="disabled"
      class="mx-select"
    >
      <option v-if="!hasOptions" disabled value="">{{ noDataText }}</option>
      <option v-else-if="!selectedValue" disabled value="">{{ placeholder }}</option>
      <option v-for="item in items" :key="item.value" :value="item.value">
        {{ item.text }}
      </option>
    </select>
    <span class="mdi mdi-chevron-down chevron-icon"></span>
  </div>
</template>



<script>
import { defineComponent, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'MxSelect',
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    noDataText: {
      type: String,
      default: 'No options available',
    },
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.value);

    const hasOptions = computed(() => props.items && props.items.length > 0);

    watch(
      () => props.value,
      (newValue) => {
        selectedValue.value = newValue;
      }
    );

    const emitChange = () => {
      emit('input', selectedValue.value);
      emit('change', selectedValue.value);
    };

    return {
      selectedValue,
      hasOptions,
      emitChange,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/global.scss';

.mx-select-wrapper {
  width: 100%;
  position: relative;
}

.mx-select {
  width: 100%;
  padding: 8px;
  padding-right: 30px; /* Make space for the icon */
  background-color: $color-mx-dark-panel;
  color: white;
  border: 1px solid #555;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    border-color: #777; /* or any color you prefer */
    box-shadow: none; /* remove the default blue outline */
  }
}

.mx-select:disabled {
  background-color: #555;
  color: #ffffff;
}

.chevron-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: white;
}

.mx-select option {
  background-color: #333;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
