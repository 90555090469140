
const MCORP_APP_ID = '8406496389366726064'
const SPOTTERFISH_TIMECODE_BYTELENGTH = 12
const STATIC_AUDIO_STRING = 'static_audio'
const STATIC_AUDIO_USERNAME = 'Project audio'

const DAW_STREAM_STRING = 'daw_stream'

/* 
'stereo': 0, // to disable stereo (to force mono audio)
'sprop-stereo': 1,
'maxaveragebitrate': 500 * 1024 * 8, // 500 kbits
'maxplaybackrate': 500 * 1024 * 8, // 500 kbits
'cbr': 0, // disable cbr
'useinbandfec': 1, // use inband fec
'usedtx': 1, // use dtx
'maxptime': 3
*/

/*
  https://www.giacomovacca.com/2016/09/opus-negotiation-for-practical-man.html
*/
const SDP_SETTINGS = {
  MAX_AVERAGE_BITRATE: 512000,
  MAX_SAMPLE_RATE: 48000,
  AUDIO_BITRATE: 512,
  MIN_PACKAGE_TIME: 10,
  PACKAGE_TIME: 10,
  MAX_PACKAGE_TIME: 60,
  USE_INBAND_FEC: 1,
  USE_DTX: 1,
  USE_CBR: 0
}

const STANDARD_VERSION_TIMECODE_OFFSET_SECONDS = 3600 // 1h 01:00:00:00 as video/audio editing software

const ERROR_VIDEO_SOURCE = { 
  src: 'https://firebasestorage.googleapis.com/v0/b/audiopostr.appspot.com/o/error_files%2F720p-No-Video.mp4?alt=media&token=5d8fc9f3-a1f0-42a0-a2c4-5b62fce21fa0', 
  type: 'video/mp4' 
}

const KEYBOARD_SHORTCUT_LIST = [
  {
    shortcuts: [
      {title: 'Rewind 5 seconds', icons: [{text: 'J'}]},
      {title: 'Play / pause', icons: [{text: ' Space '}, {divider: 'mdi-slash-forward', text: 'K'}]},
      {title: 'Forward 5 seconds', icons: [{text: 'L'}]},
      {title: 'Nudge playhead 1 frame back', icons: [{text: ','}]},
      {title: 'Nudge playhead 1 frame forward', icons: [{ text: '.' }]}
    ],
    header: 'Playback' 
  },
  {
    shortcuts: [
      {title: 'Drop marker and open inspector', icons: [{icon: 'mdi-keyboard-return'}]},
      {title: 'Drop silent marker', icons: [{icon: 'mdi-arrow-down'}, {divider: 'mdi-slash-forward', text: 'M'}]},
      {title: 'Drop silent (specific color/type)', icons: [{text: '1'}, {divider: 'mdi-minus', text: '8'}]},
      {title: 'Select previous marker', icons: [{icon: 'mdi-arrow-left'}]},
      {title: 'Select next marker', icons: [{ icon: 'mdi-arrow-right' }]}
    ],
    header: 'Markers' 
  }
]

// Pro tools safe
const FIXED_COMMENT_TYPES_PT = [
  {name: 'DIALOG', color: '#6046FC', textColor: 'white', icon: 'location_on'},  // Blue
  {name: 'MUSIC', color: '#903ED9', textColor: 'white', icon: 'location_on'},   // Magenta
  {name: 'FX', color: '#38CB74', textColor: 'white', icon: 'location_on'},      // Green
  {name: 'FOLEY', color: '#2AE5E2', textColor: 'black', icon: 'location_on'},   // Cyan
  {name: 'GENERIC', color: '#E0E0E0', textColor: 'black', icon: 'location_on'}, // Light Grey
  {name: 'VO', color: '#E8C830', textColor: 'black', icon: 'location_on'},      // Goldish Yellow
  {name: 'ADR', color: '#A62341', textColor: 'white', icon: 'location_on'},     // Richer Red
  {name: 'BG', color: '#424242', textColor: 'white', icon: 'location_on'},      // Dark Grey
]

const FIXED_COMMENT_TYPES = [
  {name: 'DIALOG', color: '#6435FF', textColor: 'white', icon: 'location_on'},
  {name: 'MUSIC', color: '#9231DB', textColor: 'white', icon: 'location_on'},
  {name: 'FX', color: '#FB338F', textColor: 'white', icon: 'location_on'},
  {name: 'FOLEY', color: '#FF7BD4', textColor: 'white', icon: 'location_on'},
  {name: 'GENERIC', color: '#E0E0E0', textColor: 'black', icon: 'location_on'},
  {name: 'VO', color: '#E8C830', textColor: 'black', icon: 'location_on'},
  {name: 'ADR', color: '#A62341', textColor: 'white', icon: 'location_on'},
  {name: 'BG', color: '#424242', textColor: 'white', icon: 'location_on'}
]



// nice colors
// const FIXED_COMMENT_TYPES = [
//   {name: 'DIALOG', color: '#2AE5E2', icon: 'location_on'},
//   {name: 'MUSIC', color: '#E6210E', icon: 'location_on'},
//   {name: 'FOLEY', color: '#3E45FE', icon: 'location_on'},
//   {name: 'FX', color: '#DFDF02', icon: 'location_on'},
//   {name: 'BG', color: '#000000', icon: 'location_on'},
//   {name: 'VO', color: '#9230DD', icon: 'location_on'},
//   {name: 'ADR', color: '#00DA32', icon: 'location_on'},
//   {name: 'GENERIC', color: '#FFFFFF', icon: 'location_on'}
// ]



const SESSION_ROLES = [

  {name: 'Reviewer', color: '#DFE002', icon: 'location_on'},
  {name: 'Actor', color: '#FFB00A', icon: 'location_on'},
  {name: 'Director', color: '#9231DB', icon: 'location_on'},
  {name: 'Supervising Sound Editor', color: '#FF7BD4', icon: 'location_on'},
  {name: 'Producer', color: '#6435FF ', icon: 'location_on'},
  {name: 'Exectutive', color: '#E7200A', icon: 'location_on'},
  {name: 'Sound editor', color: '#FB338F', icon: 'location_on'},
  {name: 'Film Editor', color: '#FA7326', icon: 'location_on'},
  {name: 'Dialog Editor', color: '#FF7BD4', icon: 'location_on'},
  {name: 'Music Editor', color: '#FFB00A', icon: 'location_on'},
  {name: 'Effects editor', color: '#FB338F', icon: 'location_on'},
  {name: 'Foley Editor', color: '#E7200A', icon: 'location_on'},
  {name: 'Re-recording mixer', color: '#FA7326', icon: 'location_on'},
  {name: 'Composer', color: '#FFB00A', icon: 'location_on'},
  
]

const sortedRoles = SESSION_ROLES.sort((a, b) => {
  if (a.name === 'Reviewer') return -1
  if (b.name === 'Reviewer') return 1
  return a.name.localeCompare(b.name)
})


const ICE_SERVERS = [
  {urls: 'turn:turnstun.audiopostr.com:443', username: 'spotterfish', credential: 'turnrelay'},
  {urls: 'stun:turnstun.audiopostr.com:443'},
  {urls: 'stun:stun.l.google.com:19302'}
]

const SEGMENT_WRITE_KEY = '2UCKnuevKYESmu7FRfT53PM3rAFRLj5b'

const TIER_MESSAGES = {
  tier4: ' for MixStage™ Custom',
  tier3: ' for MixStage™',
  tier2: ' for MixStage™ Team',
  tier1: ' for MixStage™ Review'
}

const SENDGRID_TEMPLATES_SPOTTERFISH = {
  'project-shared':           'd-f62e624fb9a74e4faeec4d4a75749832',
  'invite-to-session':        'd-97b35ec6410e45459898426795ffc8d3',
  'canceled-project-sharing': 'd-4e67ef1d3e4e47cb83afc8dc349e446c',
  'canceled-moderator':       'd-321a22e2229b4596b9647a0d968d40de',
  'made-moderator':           'd-d5c6c5ef7c1a4ca5a9c2551a29a8b1f5',

  'password-reset':           'd-4e826410ba3d482dae386aaf5f71f97d',
  'verify-account':           'd-9a7b425be462424bb22ee1f684834a13',
  'mobile-signup':            'd-b37957ee348c402fa5dc19d731bfe764',
  'puchased-room':            'd-cf9583c4f1ef491b97e3b00763b889e3',
}

const SENDGRID_TEMPLATES_MIXSTAGE = {
  'invite-to-session':        'd-3671ab8828614b0d86371cda9816bbf8',

  'project-shared':           'd-906268495479430bab47198eafefd018',
  'canceled-project-sharing': 'd-574c483b3e95471f8eb6586e0446a5a9',

  'made-moderator':           'd-39c380553684498b975bd40e7a14d87d',
  'canceled-moderator':       'd-ab12a756cb624d5990e8a3c6289ab6b9',

  'verify-account':           'd-344640d73d5a4271bca92a0195496515',
  'password-reset':           'd-38aa22a8f7164dff9132dff94bb2fa99',
  'mobile-signup':            'd-4aa0176cc95a46709a3ff76e65ad57c1',

  'puchased-room':            'd-3961a533f0bc4f0c80ef607dbc9a2287',
  'puchased-subsciption':     'd-720b20adf0184525be2d0083ed349e24', 
  
  'made-moderator-needs-user-upgrade':  'd-a4c5b825b6ed41e4a3e3f54eed0d6913',
  'made-moderator-upgraded-by-oher':    'd-25cd915324a04997bd7bd446b310e4c7',
  
  'ask-other-user-to-pay':    'd-226dbe84b9ff4f78ac43e2850d7145cd',
  
  'welcome-to-beta':          'd-47dd33fd02424b629815507b31346ab0',
  'verify-beta':              'd-92a4df0650a64c13862dc90660288c62',

  'purchase-succeeded':                 'd-720b20adf0184525be2d0083ed349e24',
  'purchase-succeeded-additional-user': 'd-52bd087d2997452ca539598f6700d4bd'
}

/*
Team purchase: 
  prctbl_1NwhXsJBbvypu4wDM8oTMwuy
Additional seats: 
  prctbl_1NwhgOJBbvypu4wDHayEQXYc
*/

const STRIPE_PUBLISHABLE_KEY_PROD = "pk_live_51IsjqcJBbvypu4wDwcAnRLOli5Iqby28TG9D0tshonLdNsGQsVCMq5qDdwHkJSpDIa8lu5XluV9gGWamGafa5Xuo00Nt7ZHPpU"
const STRIPE_PUBLISHABLE_KEY_DEV = "pk_test_51IsjqcJBbvypu4wDQmBQVOk1wFDDH2E31WbTOm6c8VeEcC32MmU2xTTxxePDHe4D4x1ycjkbgSkGmwdSHZP0cvaV00JY3DIgIq"

const _ADDITIONAL_TEAM_MEMBERS_PRICING_TABLE_ID = "prctbl_1O2DYyJBbvypu4wDky0tCuDt"
const _MIXSTAGE_UNLIMITED_PRICING_TABLE_ID = "prctbl_1O2DO7JBbvypu4wDXU0sjeDD"

const MIXSTAGE_UNLIMITED_PRICE_ID = "price_1OBxr9JBbvypu4wD5JnOrsAf"
const MIXSTAGE_UNLIMITED_PRICE_ID_DEV = "price_1NuU3CJBbvypu4wD7rh36d1L"
const MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID = "price_1OBxp5JBbvypu4wDFR5OBjDe"
const MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID_DEV = "price_1NuX2qJBbvypu4wDBlcJn2mx"

const STRIPE_PRICING_TABLES = [
  // tier 1 + dawstreaming
  _MIXSTAGE_UNLIMITED_PRICING_TABLE_ID,
  // tier 1
  _MIXSTAGE_UNLIMITED_PRICING_TABLE_ID,
  // MixStage tier 2
  _ADDITIONAL_TEAM_MEMBERS_PRICING_TABLE_ID,
  // Spotterfish tier 2
  _MIXSTAGE_UNLIMITED_PRICING_TABLE_ID,
  // Custom
  _ADDITIONAL_TEAM_MEMBERS_PRICING_TABLE_ID,
  // last resort
  _MIXSTAGE_UNLIMITED_PRICING_TABLE_ID
]

const STRIPE_PRICING_TABLES_DEV = [
  // tier 1 + dawstreaming
  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx',
  // tier 1
  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx',
  // MixStage tier 2
  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx',
  // Spotterfish tier 2
  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx',
  // Custom
  'prctbl_1NuX8cJBbvypu4wDgQpjXC6c',
  // last resort
  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx'
]

const ACCOUNT_NAMES = [
  { readbableName: 'Spotterfish Free', shortString: 'legacy_free_with_dawstream' },
  { readbableName: 'MixStage™ Basic', shortString: 'review' },  'prctbl_1NuU8UJBbvypu4wD9Z2uDlHx',
  { readbableName: 'MixStage™ Unlimited', shortString: 'team' },
  { readbableName: 'MixStage Premium', shortString: 'legacy_premium' },
  { readbableName: 'MixStage™ Custom', shortString: 'custom' },
  { readbableName: 'MixStage™', shortString: '' }
]

const ACCOUNT_TYPES = {
  BASIC: {
    status: "basic",
    cta: "Upgrade",
    plan: "Basic plan",
    tooltip: "With the Basic plan, you have access to standard review features. Upgrade for more advanced capabilities.",
  },
  EXPIRED: {
    status: "basic",
    cta: "Upgrade",
    plan: "Free trial expired",
    tooltip: "Your free trial has ended. Consider upgrading to continue accessing MixStage™'s features without interruption.",
  },
  UNLIMITED: {
    status: "unlimited",
    cta: "Purchase Team Member seats",
    plan: "Unlimited",
    tooltip: "The Unlimited plan includes DAW streaming, 20 seat rooms, allows for adding more team members to your projects and more, with full access to all features.",
  },
  FREE_TRIAL: {
    status: "freeTrial",
    cta: "Add Card Details",
    plan: (daysLeft) => `Free trial - ${daysLeft} days left`,
    tooltip: "You're currently on a free trial. Add your card details to ensure uninterrupted access to MixStage™ after the trial period.",
  },
  REVIEW: {
    status: "Basic",
    cta: "Start Free Trial",
    plan: "Basic plan",
    tooltip: "You are eligible for a free trial. This is a great opportunity to explore all that MixStage™ offers without initial commitment.",
  },
  CUSTOM: {
    status: "custom",
    cta: "Purchase Team Member seats",
    plan: "Custom Plan - Exclusive",
    tooltip: "Your account has a custom setup, reflecting specific arrangements or features tailored to your needs. Manage your account for details.",
  }
};



const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TEMP_PASSWORD = 'dukanaldrigloggainmeddethar'

module.exports = {
  MCORP_APP_ID,
  SPOTTERFISH_TIMECODE_BYTELENGTH,
  STATIC_AUDIO_STRING,
  STATIC_AUDIO_USERNAME,
  DAW_STREAM_STRING,
  ERROR_VIDEO_SOURCE,
  KEYBOARD_SHORTCUT_LIST,
  FIXED_COMMENT_TYPES_PT,
  FIXED_COMMENT_TYPES,
  FIXED_SESSION_ROLES: sortedRoles,
  SDP_SETTINGS,
  STANDARD_VERSION_TIMECODE_OFFSET_SECONDS,
  ICE_SERVERS,
  SEGMENT_WRITE_KEY,
  TIER_MESSAGES,
  SENDGRID_TEMPLATES_SPOTTERFISH,
  SENDGRID_TEMPLATES_MIXSTAGE,
  STRIPE_PUBLISHABLE_KEY_PROD,
  STRIPE_PUBLISHABLE_KEY_DEV,
  STRIPE_PRICING_TABLES,
  STRIPE_PRICING_TABLES_DEV,
  MIXSTAGE_UNLIMITED_PRICE_ID,
  MIXSTAGE_UNLIMITED_PRICE_ID_DEV,
  MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID,
  MIXSTAGE_ADDITIONAL_TEAM_MEMBERS_PRICE_ID_DEV,
  ACCOUNT_NAMES,
  ACCOUNT_TYPES,
  EMAIL_REGEX,
  TEMP_PASSWORD
}

