<template>
  <div id="wrapper">
    <div v-if="!error">
      <p>
        Requested format: <span>{{ format }}</span>
      </p>
      <p v-if="!contents">
        Fetching data…
      </p>
      <p v-if="contents">
        <a href="#" @click.prevent="downloadFile">{{ fileName }}</a>
      </p>
    </div>
    <div v-if="error">
      <p>{{ error }}</p>
      <p>Select format:</p>
      <p>
        <router-link :to="{name: 'adminGetUsers', query: {format: 'json'}}">JSON</router-link>
        —
        <router-link :to="{name: 'adminGetUsers', query: {format: 'csv'}}">CSV</router-link>
      </p>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { saveAs } from 'file-saver'
import moment from 'moment'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'getUsers',

  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      format: '',
      error: '',
      contents: ''
    }
  },

  watch: {
    $route (to, from) {
      this.format = to.query.format
      this.contents = ''
      this.checkFormatAndFetchData()
    }
  },

  computed: {
    fileName () {
      const dateString = moment().format('YYYY-MM-DD')
      return `user-export-${dateString}.${this.format}`
    },

    fileType () {
      if (this.format === 'json') {
        return 'application/json'
      } else if (this.format === 'csv') {
        return 'text/csv'
      } else {
        return 'text/plain'
      }
    }
  },

  methods: {
    async checkFormatAndFetchData () {
      if (this.format !== 'json' && this.format !== 'csv') {
        this.error = `'${this.format}' is not a supported format.`
        return
      }

      this.error = ''

      // Format is fine, call the cloud function to fetch data.
      try {
        this.contents = await CloudClient.call_CFgetAllUsersData(App.Firebase, this.format)
      } catch (error) {
        await this.$root.$globalAlert.open('Error', {
          line1: `Could not get contents (${error.message})`
        },
        { color: this.colors.mainAccent })
      }
    },

    async downloadFile () {
      if (this.contents.length === 0) {
        return
      }

      const blob = new Blob([this.contents], {
        type: `${this.fileType};charset=utf-8`
      })

      saveAs(blob, this.fileName)
    }
  },

  beforeMount () {
    this.format = this.$route.query.format
    this.checkFormatAndFetchData()
  }
}

</script>

<style lang="sass" scoped>

#wrapper
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center

</style>
