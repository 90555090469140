const SpotterfishCore = require('./SpotterfishCore')
const Timecode = require('./utils/Timecode')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isDevelopment = SpotterfishCore.isDevelopment
const isBooleanInstance = SpotterfishCore.isBooleanInstance
const isObjectInstance = SpotterfishCore.isObjectInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const isStringInstance = SpotterfishCore.isStringInstance

const _ = require('lodash')

//  https://github.com/fireworkweb/smpte.js
const SMPTEtc = require('smpte.js')

// TODO: Deprecated
function toFrames (SMPTE, rateHz, dropFrame = false) {
  const k = Timecode.getExactFreqHzFromFRNick(rateHz)
  assert(isNumberInstance(k))
  assert(isBooleanInstance(dropFrame))

  //??? Bad guard
  if (SMPTEtc.isValidTimecode(SMPTE, k, dropFrame)) {
    const pos = SMPTEtc.fromTimecode(SMPTE, k, dropFrame)
    const frameIndex = pos.frameCount
    assert(isNumberInstance(frameIndex))
    return frameIndex
  } else {
    console.log('WRONG SMPTE FORMAT: ' + SMPTE)
    return 0
  }
}

// TODO: Deprecated
function toSeconds (frames, rateHz, dropFrame = false) {
  const k = Timecode.getExactFreqHzFromFRNick(rateHz)
  assert(isNumberInstance(frames))
  assert(isNumberInstance(k))
  assert(isBooleanInstance(dropFrame))

  const pos = SMPTEtc.fromFrames(frames, k, dropFrame)
  const seconds = pos.durationInSeconds
  assert(isNumberInstance(seconds))
  return seconds
}



function addSMPTEseparators (str) {
  // Strings may be input shorter than a full smpte, so we need to reverse the string before insert to handle that
  console.log(`str ${str}`)
  let cleanedString = str
  if (str.charAt(0) === '-' || str.charAt(0) === '+') {
    cleanedString = parseInt(str).toString()
  }
  console.log(`cleanedString ${cleanedString}`)
  let reversed = [...cleanedString].reverse().join('')
  console.log(`reversed ${reversed}`)
  console.log(`reversed length ${reversed.length}`)
  let regExPattern = new RegExp('(.{' + 2 + '})', 'g')
  let fullString = reversed.replace(regExPattern, '$1' + ':')
  console.log(`fullString ${fullString}`)
  if (fullString[fullString.length - 1] === ':') {
    fullString = fullString.substring(0, fullString.length - 1)
  }
  console.log(`fullString cleaned ${fullString}`)
  let reversedBack = [...fullString].reverse().join('')
  console.log(reversedBack)
  console.log(`reversedBack ${reversedBack}`)
  return reversedBack
}

function partialSMPTEtoSMPTE (partialSMPTE) {
  console.log(partialSMPTE)
  assert(isStringInstance(partialSMPTE))
  let correctedPartialSMPTE = partialSMPTE
  const isValid = new RegExp(/(^([0-1][0-9]|[0-2][0-3]):([0-5][0-9]):([0-5][0-9])[:;]([0-6][0-9])$)/)
  
  // Check if smpte is entered with : or not
  let hasColon = partialSMPTE.indexOf(':')
  if (hasColon === -1) {
    // Add colons to input SMPTE
    correctedPartialSMPTE = addSMPTEseparators(partialSMPTE)
    console.log(correctedPartialSMPTE)
  }

  const SMPTEArray = correctedPartialSMPTE.split(':').reverse()
  console.log(SMPTEArray)
  const ff = SMPTEArray[0] ? SMPTEArray[0].padStart(2, '0') : '00'
  const ss = SMPTEArray[1] ? SMPTEArray[1].padStart(2, '0') : '00'
  const mm = SMPTEArray[2] ? SMPTEArray[2].padStart(2, '0') : '00'
  const hh = SMPTEArray[3] ? SMPTEArray[3].padStart(2, '0') : '00'
  const SMPTE = `${hh}:${mm}:${ss}:${ff}`
  console.log('converted smpte: ' + SMPTE)
  if (isValid.test(SMPTE)) {
    return SMPTE
  } else {
    throw new Error('wrong format')
  }
}


/*
  '+10': add 10 frames
  '-200': subtract 00:00:02:00
*/

function convertTimeCodeInputToOffsetSeconds (value, currentFrameRate, currentFrameCount, currentOffsetTime, currentVideoLength) {
  assert(isStringInstance(value))
  assert(currentFrameRate !== undefined)
  assert(currentFrameCount !== undefined)
  assert(currentOffsetTime !== undefined)
  assert(currentVideoLength !== undefined)
  console.log(value)
  console.log(currentFrameRate)
  console.log(currentFrameCount)
  console.log(currentOffsetTime)
  console.log(currentVideoLength)
  
  function calculateSMPTESeek (v) {
    // Catch exception and ignore it if it happens
    let valueInSeconds
    try {
      const valueinframes = toFrames(v, currentFrameRate)
      valueInSeconds = toSeconds(valueinframes, currentFrameRate)
    } catch (error) {
      throw error
    }
    const result = valueInSeconds - currentOffsetTime
    console.log('result ' + result)
    console.log('valueInSeconds ' + valueInSeconds)
    console.log('currentOffsetTime ' + currentOffsetTime)
    if (valueInSeconds < currentOffsetTime) {
      return 0
    } else if (result >= currentVideoLength) {
      return currentVideoLength
    } else {
      return result
    }
  }


  if (Timecode.isValidSMPTETimeCode(value, currentFrameRate)) {
    return calculateSMPTESeek(value)
  }

  const covertedInput = addSMPTEseparators(value)
  if (Timecode.isValidSMPTETimeCode(covertedInput, currentFrameRate)) {
    return calculateSMPTESeek(covertedInput)
  } else if (parseInt(value) === 0) {
    return 0
  } else if (value.charAt(0) === '-' || value.charAt(0) === '+') {
    try {
      // Create SMPTE from input value and seek + or -
      const correctedSMPTE = partialSMPTEtoSMPTE(value.substring(1))
      const changeFrames = toFrames(correctedSMPTE, currentFrameRate)
      const valueinframes = value.charAt(0) === '+' ? parseInt(currentFrameCount) + parseInt(changeFrames) : parseInt(currentFrameCount) - parseInt(changeFrames)
      const valueInSeconds = toSeconds(valueinframes, currentFrameRate)
      const result = valueInSeconds - currentOffsetTime
      // Decide that we can never seek to earlier than start time
      if (result > 0) {
        return result
      } else {
        return 0
      }
    } catch (error) {
      console.log(error)
    }
  } else if (covertedInput.length < 11) {
    console.log('Creating full SMPTE from Partial SMPTE')
    const correctedSMPTE = partialSMPTEtoSMPTE(value)
    const frames = toFrames(correctedSMPTE, currentFrameRate)
    let valueInSeconds = toSeconds(frames, currentFrameRate)
    // If lower than offset time - Assume the user missed the offset, add it to the value
    if (Math.sign(valueInSeconds - currentOffsetTime) === -1) {
      console.log('Assuming the user missed adding hour - adding it')
      valueInSeconds = valueInSeconds + currentOffsetTime
    }
    return valueInSeconds - currentOffsetTime
  } else {
    throw new Error('Not a valid time code')
  }
}



function prove__ConvertTimeCodeInputToOffsetSeconds () {
  let tests = [
    // 0
    {
      value: '01:02:00:00',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 120
    },
    // 1
    {
      value: '01020000',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 120
    },
    // 2
    {
      value: '+02:00:00',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 120
    },
    // 3
    {
      value: '+020000',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 120
    },
    // 4
    {
      value: '+02000',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 20
    },
    // 5
    {
      value: '+0200',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 2
    },
    // 6
    {
      value: '+020',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 0.8000000000001819
    },
    // 7
    {
      value: '+2',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 0.07999999999992724
    },
    // 8
    {
      value: '+02',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 0.07999999999992724
    },
    // 9
    {
      value: '0',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 0
    },
    // 10
    {
      value: '-01:00:00',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 60
    },
    // 11
    {
      value: '-010000',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 60
    },
    // 12
    {
      value: '-01000',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 110
    },
    // 13
    {
      value: '-0100',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 119
    },
    // 14
    {
      value: '-010',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 119.59999999999991
    },
    // 15
    {
      value: '-01',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 119.96000000000004
    },
    // 16
    {
      value: '01010000',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 60
    },
    // 17
    {
      value: '01:01:00:00',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 25,
      videoStartSMPTE: '01:00:00:00',
      result: 60
    },
    // 18
    {
      value: '01:01:00:00',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 24,
      videoStartSMPTE: '01:00:00:00',
      result: 60
    },

    // 19
    {
      value: '01:01:00:00',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 29.97,
      videoStartSMPTE: '01:00:00:00',
      result: 60.059999999999945
    },

    // 20
    {
      value: '01:01:00:00',
      currentSMPTE: '01:02:00:00',
      videoFileFrameRate: 23.98,
      videoStartSMPTE: '01:00:00:00',
      result: 60.059999999999945
    },

    // // 21
    // {
    //   value: '01:01:00:00',
    //   currentSMPTE: '01:02:00:00',
    //   videoFileFrameRate: 59.94,
    //   videoStartSMPTE: '01:00:00:00',
    //   result: 60.059999999999945
    // },

    // // 22
    // {
    //   value: '01:01:00:00',
    //   currentSMPTE: '01:02:00:00',
    //   videoFileFrameRate: 50,
    //   videoStartSMPTE: '01:00:00:00',
    //   result: 60
    // },
    // // 23
    {
      value: '020000',
      currentSMPTE: '01:00:00:00',
      videoFileFrameRate: 24,
      videoStartSMPTE: '01:00:00:00',
      result: 120
    }
  ]
  for (let [index, test] of tests.entries()) {
    console.log(`running test no ${index}`)
    let value = test.value
    let currentSMPTE = test.currentSMPTE
    let currentFrameRate = test.videoFileFrameRate
    console.log(currentFrameRate)
    let currentFrameCount = toFrames(currentSMPTE, currentFrameRate, false)
    let currentStartSMPTE = test.videoStartSMPTE
    let currentVideoLength = 3600
    let currentOffsetTime = toSeconds(
      toFrames(
        currentStartSMPTE,
        currentFrameRate,
        false
      ),
      currentFrameRate,
      false
    )
    const res = convertTimeCodeInputToOffsetSeconds(value, currentFrameRate, currentFrameCount, currentOffsetTime, currentVideoLength)
    unitTestAssert(res === test.result)
  }
}


// DEPRECATED
function calcDeltaSecondsFromUIString(clock, videoLength, s){
  const frameRateHz = parseFloat(Timecode.getFrameRateNickname(clock.videoFileFrameRateKey))
  const posFrames = Timecode.secondsToFrameIndex(clock.position + clock.videoFileOffset, clock.videoFileFrameRateKey)
  try {
    const seekValue = convertTimeCodeInputToOffsetSeconds(
      s,
      frameRateHz,
      posFrames,
      clock.videoFileOffset,
      videoLength
    )
    console.log(seekValue)
    return seekValue
  }
  catch(error){
    return undefined
  }
}



function runUnitTests () {
  console.log('Timecode.js -- START')

  prove__ConvertTimeCodeInputToOffsetSeconds()

  console.log('Timecode.js -- END')
}

module.exports = {
  calcDeltaSecondsFromUIString,
  addSMPTEseparators,
  runUnitTests
}

