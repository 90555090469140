<template>
  <div>
    <v-app-bar
      app
      ref="toolbar"
      fixed
      clipped-left
      clipped-right
      class="dark-panel"
      height="65"
      extension-height="120"
    >
      <a href="https://www.mixstage.io" target="_blank" v-if="!isSmallScreen">
        <img
          style="display: block; height: 40px; margin: 30px 0 0 20px;"
          :src="logos.mixStageLogoWithWhiteText"
        />
      </a>
      <v-toolbar-items v-if="isSmallScreen">
        <div  class="tooltip-trigger right" data-tooltip="Open Menu" style="display: flex; justify-content: center; align-items: center;" @click="$emit('request-toggle-menu')">
          <i class="material-icons" style="font-size: 2rem;">more_vert</i>
        </div>
        </v-toolbar-items>
        <v-spacer  v-if="isSmallScreen"></v-spacer>
      
      <v-spacer  v-if="!isSmallScreen"></v-spacer>
      
      <!-- User plan container -->
      <v-toolbar-items style="display: flex; justify-content: center; align-items: center; margin-right: 1rem;">
        <div style="background-color: #303030; border-radius: 0.25rem; display: flex; justify-content: center; align-items: center; padding: 4px 0.5rem; height: 49px; border: 1px solid #4F4F4F;">
              <!-- CTA user plan btn -->
              <button @click="decideAndActBasedOnUserStatus()" style="background-color: #A149FC; padding: 0.25rem 0.375rem; display: flex; justify-items: center; align-items: center;margin-right: 0.5rem; border-radius: 0.250rem">
                <p style="margin-bottom: 0; font-weight: 500">{{ userStatus.userAccountCTA}}</p>
              </button>  

              <!-- account plan text -->
              <p :style="{ marginBottom: '0', fontWeight: '500', color: countdownColor }" v-if="userStatus.accountPlan">{{ userStatus.accountPlan }}</p>
              <!-- tooltip -->
              <v-tooltip bottom :color="colors.tooltipBackground" style="z-index: 9999;">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="colors.smoke" style="margin: 0 0 0 8px !important; font-size: 1em;" v-bind="attrs" v-on="on" >help</v-icon>
                </template>
                <p style="max-width: 36rem; text-align: left; width: 200px;">
                  {{ userStatus.tooltip }}</p>
              </v-tooltip>  

        </div>  
      </v-toolbar-items>



        <!-- Upgrade account btn -->
          <!-- <v-toolbar-items v-if="!isSmallScreen" style="padding-right: 20px;">
              <v-btn
                style="height: auto !important; margin: 13px 1px 13px 0; border-radius: 4px;"
                :color="colors.mx.panel"
                @click.stop="$emit('request-open-purchase-dialog', undefined)"
                small
              >
                <span style="font-weight: 500;" :style="{ 'font-size': hasFullAccount() ? '0.85em' : '0.98em' }">{{ hasFullAccount() ? 'Add Team Members' : 'Upgrade Account' }} </span>
              </v-btn>
          </v-toolbar-items> -->

        

      <template v-slot:extension>
        <v-toolbar class="dark-panel" elevation="0">
            <!-- Second row app bar -->
            <v-spacer></v-spacer>
            <v-btn :color="colors.mixStage_main" class="mb-4 ml-2 pointer-on-hover" @click="$emit('request-start-session')">
              Start Session
              <v-icon class="ml-1">mdi-play-circle-outline</v-icon>
            </v-btn>


          <template v-slot:extension>
            <!-- Third row app bar -->
            <v-tabs
              class="mixstage-tabs"
              v-model="$store.state.dashboardTab"
              bg-color="panel"
              color="white"
              slider-color="secondary"
            >
              <v-tab class="tabtitle" value="projects" prepend-icon="mdi-folder-play-outline">Projects</v-tab>
              <v-tab class="tabtitle" value="rooms" prepend-icon="mdi-video-marker">Sessions</v-tab>
              <!-- <v-tab class="tabtitle" value="streaming" prepend-icon="mdi-video-wireless-outline">Streaming</v-tab> -->
            </v-tabs>
            <v-spacer></v-spacer>

            <!-- Right hand side lower panel -->
            <div v-show="$store.state.dashboardTab === 0" style="width: 4em; margin-right: -7px;">
                <v-icon @click="$store.state.dashboardView = 'grid'" size="1.5em" :color="colors.smoke" class="view-option" :class="{ 'sel': $store.state.dashboardView === 'grid' }">apps</v-icon>
                <v-icon @click="$store.state.dashboardView = 'list'" size="1.5em" :color="colors.smoke" class="view-option" :class="{ 'sel': $store.state.dashboardView === 'list' }">reorder</v-icon>
              </div>

          </template>
        </v-toolbar>
      </template>
    </v-app-bar>

    <v-dialog
      v-model="settingsPanel"
      max-width="800"
      v-if="currentUser"
      style="overflow: auto"
      overlay-opacity="0.95"
    >
      <usersettingscomponent
        @close-button-clicked="settingsPanel = false"
      />
    </v-dialog>

    <purchaseDialogBox
      v-if="purchaseDialog"
      :show="purchaseDialog"
      :accountTier="accountTier"
      :isDAWstreamer="isDAWstreamer"
      :purchaseData="purchaseData"
      @close-upgrade-dialog="closepurchaseDialog"
    />
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import { ref, computed, onMounted, onBeforeMount } from 'vue'
import { trackWindowSize } from '@/lib/ui/windowUtils'

import store from '@/store'
import firebaseUserHandler from '@/components/API/firebaseUserHandler'
import usersettingscomponent from '@/components/userscomponents/sfUsersettings.vue'
import purchaseDialogBox from '@/components/API/upgradeDialogBox.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import userAccountMenu from '@/components/userscomponents/userAccountMenu.vue'
import StripeUtils from '@/../source_files/spotterfish_library/utils/StripeUtils'
import * as Sentry from '@sentry/browser'
import Constants from '@/../source_files/spotterfish_library/consts'


export default {
  name: 'spotterfishHeader',
  props: ['setSpinner', 'setLoadingMessage', 'accountTier', 'isDAWstreamer', 'intercomLoaded', 'isStripeUser'],
  components: {
    usersettingscomponent,
    purchaseDialogBox,
    userAccountMenu,
  },
  setup(props, { emit }) {
    const colors = require('@/lib/ui/colors.js').colors
    const logos = require('@/lib/ui/logos.js').logos
    const { windowWidth } = trackWindowSize()

    const hamburgerMenuOpen = ref(false)
    const settingsPanel = ref(false)
    const purchaseDialog = ref(false)
    const currentUser = store.state.currentSpotterfishUser
    const purchaseData = ref(undefined)

    const userStatus = ref({
      accountString: "freeTrial",
      hasMadePurchase: false,
      hasPaymentMethod: false,
      daysLeftInTrial: 10,
    })

    const closepurchaseDialog = () => {
      purchaseDialog.value = false
      purchaseData.value = undefined
    }

    const openpurchaseDialog = async (extraData) => {
      console.log('opening purchase dialog', extraData)
      SpotterfishSession.trackEvent(
        App.spotterfishSession,
        'upgrade_account_button_clicked',
          {
            userUID: App.spotterfishSession.userSession.firebaseCurrentUser.uid,
            email: App.spotterfishSession.userSession.firebaseCurrentUser.email,
          }
      )

      props.setSpinner(true)
      try {
        props.setSpinner(false)
        purchaseData.value = extraData
        console.log('purchaseData', purchaseData.value)
        purchaseDialog.value = true
      } catch (error) {
        props.setSpinner(false)
      }
    }

    const openpurchaseDialogFromQuery = () => {
      const queryParts = window.location.href.split('?')
      if(queryParts.length > 1){
        const params = new URLSearchParams(queryParts[1])
        if(params.get('openUpgrade') === 'true'){
          purchaseDialog.value = true
        }
      }
    }

     /* action types portal session::
        payment_method_update - allows the customer to update their payment method
        subscription_cancel - allows the customer to cancel their subscription
        subscription_update - allows the customer to update their subscription (prices etc.)
        subscription_update_confirm - allows the customer to confirm their subscription update
        if undefined - standard portal session
      */ 
      /* action types Checkout Session::
        userId and email is read from context
        priceItem, 
        additionalUsers boolean if we should display the additional fields
        allow_promotion_codes (bolean, whether to allow it or not)
      */
      const decideAndActBasedOnUserStatus = async () => {
        props.setSpinner(true)
        try {
          let resultUrl;
          const returnUrl = window.location.href
          switch (userStatus.value.accountString) {
            case Constants.ACCOUNT_TYPES.FREE_TRIAL.status:
              if (!userStatus.value.hasPaymentMethod) {
                // Direct user to update their payment method if on free trial without a payment method
                resultUrl = (await CloudClient.call_CFcreateStripePortalSession(App.Firebase, 'payment_method_update')).url;
              } else {
                // Other action for users with a payment method in free trial
                resultUrl = (await CloudClient.call_CFcreateStripePortalSession(App.Firebase, undefined)).url;
              }
              break;
            case Constants.ACCOUNT_TYPES.REVIEW.status:
              resultUrl = (await CloudClient.call_CFStripeCreateCheckoutSession(App.Firebase, StripeUtils.getStripePricingId(), false, true, returnUrl)).url;
              break;
            case Constants.ACCOUNT_TYPES.BASIC.status:
              resultUrl = (await CloudClient.call_CFStripeCreateCheckoutSession(App.Firebase, StripeUtils.getStripePricingId(), false, true, returnUrl)).url;
              break;
            case Constants.ACCOUNT_TYPES.UNLIMITED.status:
              resultUrl = (await CloudClient.call_CFStripeCreateCheckoutSession(App.Firebase, StripeUtils.getStripePricingId('additional'), true, true, returnUrl)).url;
            case Constants.ACCOUNT_TYPES.CUSTOM.status:
            resultUrl = (await CloudClient.call_CFStripeCreateCheckoutSession(App.Firebase, StripeUtils.getStripePricingId('additional'), true, true, returnUrl)).url;
            break;
            default:
              resultUrl = (await CloudClient.call_CFcreateStripePortalSession(App.Firebase, undefined)).url;
              break;
          }
          props.setSpinner(false)
          window.location.href = resultUrl;
        } catch (error) {
          props.setSpinner(false)
          console.error(error);
          Sentry.captureException(error);
          alert('Could not initialize payment portal, the MixStage™ team has been notified');
        }
      };


    onBeforeMount(async () => {
      /*
      {
          "hasMadePurchase": false,
          "hasPaymentMethod": false,
          "daysLeftInTrial": null
      }
      */
      userStatus.value = localStorage.getItem('userStatus') ? JSON.parse(localStorage.getItem('userStatus')) : userStatus.value
      const stripeUserRecord = await CloudClient.call_CFgetStripeUserInfo(App.Firebase)
      console.log(stripeUserRecord)
      userStatus.value = StripeUtils.calcUserAccountInfo(stripeUserRecord, props.accountTier, props.isStripeUser)
      localStorage.setItem('userStatus', JSON.stringify(userStatus.value))
    })

    const countdownColor = computed(() => {
      return (userStatus.value.accountString === "freeTrial" && userStatus.value.daysLeftInTrial && userStatus.value.daysLeftInTrial <= 10) ? '#FF6666' : '';
    })

    const isVerified = computed(() => {
      return firebaseUserHandler.getLoggedInUser().emailVerified
    })

    const isSmallScreen = computed(() => {
      return windowWidth.value < 1264
    })


    onMounted(async () => {
      openpurchaseDialogFromQuery()
    })

    return {
      // remove user from here when replaced with actual data
      colors, 
      logos,
      userStatus,
      hamburgerMenuOpen,
      settingsPanel,
      purchaseDialog,
      currentUser,
      purchaseData,
      countdownColor,
      isVerified,
      isSmallScreen,

      closepurchaseDialog,
      openpurchaseDialog,
      decideAndActBasedOnUserStatus,
    }
  }    
}
</script>

<style lang="scss" scoped>

  .tabtitle {
    font-weight: 500;
    font-size: 13px;
    color: rgba(var(--v-theme-lightgray));
  }

  .mixstage-tabs {
    padding-left: 320px;
  }
  @media (max-width: 1263px) {
    .mixstage-tabs {
        padding-left: 0;
    }
  }

  .view-option {
    border-radius: 3px;
    margin: 2px;
    padding: 1px;
    &.sel {
      background-color: #595959;
      color: white;
    }
  }

</style>