// "Language extensions" go in this file, stuff we would have wanted built into
// JavaScript. Can be replaced with built-in functions if they ever appear natively in
// the future.

// REQUIREMENTS
// - Needs a Node environment.

// Good candidates to put in this file:
// - Logging code
// - Testing utilities etc.
// - Errors and error codes

// TODO: Remove functions already provided by Lodash: https://docs-lodash.com/v4/

function checkCoreEnvironment () {
  const allowedEnvs = [
    'development',
    'production',
    'testing'
  ]
  return process !== undefined && process.env && process.env.NODE_ENV && allowedEnvs.includes(process.env.NODE_ENV)
}

// Dev server or emulator
function isDevelopment () {
  return process.env.NODE_ENV === 'development'
}

function isRunningInEmulator() {
  return process.env.FUNCTIONS_EMULATOR === 'true'
}

function isProduction () {
  return process.env.NODE_ENV === 'production'
}

function isTesting () {
  return process.env.NODE_ENV === 'testing'
}

function unitTestAssert (flag) {
  if (flag === true) {
  }
  else {
    debugger
    const error = new Error('Assertion failed')

    if (isDevelopment()) {
      // TODO: Log callstack, should be in object above.
      console.debug('Assertion failed at:', error.stack)
      console.debug(error.message)
      debugger
    }
    else if (isProduction()) {
      console.debug(error.message)
    }
    else if (isTesting()) {
      console.debug(error.message)
      debugger
    }
    else {
      debugger
    }

    throw error
  }
}


function assert (flag) {
  if (flag === true) {
  }
  else {
    debugger
    const error = new Error('Assertion failed')

    if (isDevelopment()) {
      // TODO: Log callstack, should be in object above.
      console.debug('Assertion failed at:', error.stack)
      console.debug(error.message)
      debugger
    }
    else if (isProduction()) {
      console.debug(error.message)
    }
    else if (isTesting()) {
      console.debug(error.message)
      debugger
    }
    else {
      debugger
    }

    throw error
  }
}

function unitTestAssertThrowsError (fn, args) {
  // TODO: For now, skip these asserts in the browser, to avoid a long series of
  // debugger stops that are not really helpful in that context.
  if (typeof window === 'undefined') {
    try {
      fn(...args)
      assert(false)
    } catch (error) {
      // Success
    }
  }
  else {
    console.warn(`Skipped unitTestAssertThrowsError for ${fn.name} call since we are in a browser`)
  }
}

function consoleLogLines (lines) {
  assert(isArrayInstance(lines))

  for (let i = 0; i < lines.length; i++) {
    console.log(lines[i])
  }
}


function prove_MissingObjectKeyVsUndefined () {
  const test = { magic: 3 }

  assert(test.magic !== undefined)
  assert(test.missing === undefined)

  assert(test.magic !== undefined)
  assert(test.missing === undefined)

  assert(test.hasOwnProperty('magic') === true)
  assert(test.hasOwnProperty('missing') === false)

  assert(('magic' in test) === true)
  assert(('missing' in test) === false)
}


function prove_JavaScript () {
  prove_MissingObjectKeyVsUndefined()
}

// Returns TRUE only if s is a valid JS *object*. It's not null, undefined, a string or an array.
function isObjectInstance (s) {
  return (s !== null) &&
    (s instanceof Object) &&
    (Array.isArray(s) === false) &&
    ((s instanceof Function) === false)
}


function prove_isObjectInstance () {
  assert(isObjectInstance({}) === true)
  assert(isObjectInstance({ one: 1, two: 2 }) === true)

  assert(isObjectInstance(null) === false)
  assert(isObjectInstance(undefined) === false)
  assert(isObjectInstance('txt') === false)
  assert(isObjectInstance(3) === false)
  assert(isObjectInstance(function () {}) === false)
  assert(isObjectInstance([]) === false)
}

// Returns TRUE only if s is a valid JS *object*. It's not null, undefined, a string or an array.
function isArrayInstance (s) {
  return s !== null && Array.isArray(s)
}


function prove_isArrayInstance () {
  assert(isArrayInstance([]) === true)
  assert(isArrayInstance([ 1, 2 ]) === true)

  assert(isArrayInstance(null) === false)
  assert(isArrayInstance(undefined) === false)
  assert(isArrayInstance('txt') === false)
  assert(isArrayInstance(3) === false)
  assert(isArrayInstance(function () {}) === false)
  assert(isArrayInstance({}) === false)
}

function isStringInstance (s) {
  return typeof s === 'string'
}

function isBooleanInstance (s) {
  return typeof s === 'boolean'
}

function isNumberInstance (s) {
  return typeof s === 'number'
}

function isValidISODateString (s) {
  return s === new Date(s).toJSON()
}


function prove_isValidISODateString () {
  assert(isValidISODateString('2021-04-29T15:46:09.867Z') === true)

  assert(isValidISODateString('2021-04-29T15:46:09.867Zaaa') === false)
  assert(isValidISODateString('2021-04-29T15:46:09.867') === false)
  assert(isValidISODateString('2021-04-29T15:46:09') === false)
  assert(isValidISODateString('2021-04-29') === false)
}

function isFunctionInstance (s) {
  return s !== null && s instanceof Function
}


function prove_isFunctionInstance () {
  assert(isFunctionInstance(function () {}) === true)
  assert(isFunctionInstance(13) === false)
  assert(isFunctionInstance(null) === false)
  assert(isFunctionInstance(undefined) === false)
}

// Makes deep comparison by-value
function compareValues (a, b) {
  if (a === undefined) {
    return b === undefined
  }
  else if (a === null) {
    return b === null
  }
  else if (isObjectInstance(a)) {
    return isObjectInstance(b) && compareObjectsByValue(a, b)
  }
  else if (isArrayInstance(a)) {
    return isArrayInstance(b) && compareArraysByValue(a, b)
  }
  else if (isStringInstance(a)) {
    return isStringInstance(b) && a === b
  }
  else if (isBooleanInstance(a)) {
    return isBooleanInstance(b) && a === b
  }
  else if (isNumberInstance(a)) {
    return isNumberInstance(b) && a === b
  }
  else {
    assert(false)
    return false
  }
}


function prove_compareValues () {
  assert(compareValues(undefined, undefined) === true)
  assert(compareValues(undefined, 3) === false)
  assert(compareValues(3, undefined) === false)


  assert(compareValues([], {}) === false)
  assert(compareValues({}, []) === false)

  assert(compareValues({}, {}) === true)
  assert(compareValues({ a: 1 }, { a: 1 }) === true)
  assert(compareValues({ a: 1, b: 2 }, { a: 1, b: 2 }) === true)
  assert(compareValues({ a: 1, b: 3 }, { a: 1, b: 2 }) === false)
  assert(compareValues({ a: 1, b: 2 }, { b: 2, a: 1 }) === true)

  // Make sure nested objects work.
  assert(compareValues({ a: 'aaa', b: 'bbb' }, { a: 'aaa', b: 'bbb' }) === true)
  assert(compareValues({ a: 'aaa', b: {} }, { a: 'aaa', b: {} }) === true)
  assert(compareValues({ a: 'aaa', b: { c: 1000 } }, { a: 'aaa', b: { c: 1000 } }) === true)
  assert(compareValues({ a: 'aaa', b: { c: 1001 } }, { a: 'aaa', b: { c: 1000 } }) === false)

  assert(compareValues([], []) === true)


  assert(compareValues(1, 1) === true)
  assert(compareValues(1, 2) === false)
  assert(compareValues(1, 'hello') === false)

  assert(compareValues(true, true) === true)
  assert(compareValues(true, false) === false)

  assert(compareValues(new Set(['a', 'b']), new Set(['a', 'b'])) === true)
  assert(compareValues(new Set(['a', 'b']), new Set(['b', 'a'])) === true)
/*
  MZ: Gave up with this, let's use lodash instead
  assert(compareValues(new Set(['a', 'b']), new Set(['a'])) === false)
  assert(compareValues(new Set(['a', 'b']), 'done') === false)
*/
}

function compareArraysByValue (a, b) {
  assert(isArrayInstance(a) === true)
  assert(isArrayInstance(b) === true)

  if (a.length !== b.length) {
    return false
  }
  else {
    for (var i = 0; i < a.length; i++) {
      if (compareValues(a[i], b[i]) === false) {
        return false
      }
    }
    return true
  }
}


function prove_compareArraysByValue () {
  assert(compareArraysByValue([], []) === true)
  assert(compareArraysByValue([1, 2, 3], [1, 2, 3]) === true)

  assert(compareArraysByValue([1, 2, 3], [1, 2, 3, 4]) === false)
  assert(compareArraysByValue([], [1, 2, 3]) === false)
  assert(compareArraysByValue([1, 2, 3], []) === false)
}


// Slow implementation?
function compareObjectsByValue (a, b) {
  assert(isObjectInstance(a) === true)
  assert(isObjectInstance(b) === true)

  // NOTE: Order of keys in Object depends partially on insert-order. We need to sort keys before comparing?
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(b).sort()

  if (compareArraysByValue(aKeys, bKeys) === false) {
    return false
  }

  for (var i = 0; i < aKeys.length; i++) {
    const key = aKeys[i]

    const aValue = a[key]
    const bValue = b[key]

    if (compareValues(aValue, bValue) === false) {
      return false
    }
  }
  return true
}


function prove_compareObjectsByValue () {
  assert(compareObjectsByValue({}, {}) === true)
  assert(compareObjectsByValue({ one: 1 }, {}) === false)
  assert(compareObjectsByValue({ one: 1 }, { one: 1 }) === true)
  assert(compareObjectsByValue({ one: 1, two: 2 }, { one: 1, two: 2 }) === true)
  assert(compareObjectsByValue({ one: 1, two: 2 }, { two: 2, one: 1 }) === true)
  assert(compareObjectsByValue({ one: 'a', two: 2 }, { one: 'a', two: 2 }) === true)

  const a = { rooms: {} }
  const b = { rooms: {} }
  assert(compareObjectsByValue(a, b) === true)
}

function compareObjectKeys (a, b) {
  // NOTE: Order of keys in Object depends partially on insert-order. We need to sort keys before comparing?
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(a).sort()
  return compareArraysByValue(aKeys, bKeys)
}

function compareObjects (a, b) {
  return compareObjectKeys(a, b) && compareObjectsByValue(a, b)
}

function copyByValue (a) {
  return JSON.parse(JSON.stringify(a))
}

function castObjectValuesToString (obj) {
  const newObj = {}
  for (const [key, value] of Object.entries(obj).map(el => [el[0], el[1].toString()])) {
    newObj[key] = value
  }
  return newObj
}


function prove_castObjectValuesToString () {
  assert(compareValues(castObjectValuesToString({'a': 1, 'b': 2}), {'a': '1', 'b': '2'}))
  assert(compareValues(castObjectValuesToString({'a': 1, 'b': '2'}), {'a': '1', 'b': '2'}))
  assert(compareValues(castObjectValuesToString({'a': '1', 'b': '2'}), {'a': '1', 'b': '2'}))
}

// Return the first index in array at which there is a falsy value. An optional start
// index can be passed, default is to start search at start of array.
function getFirstEmptyArrayIndex (array, startIndex = 0) {
  assert(isArrayInstance(array))

  if (startIndex > array.length - 1) {
    return -1
  }

  for (let i = startIndex; i < array.length; i++) {
    if (!array[i]) {
      return i
    }
  }

  return -1
}

function prove_getFirstEmptyArrayIndex () {
  // Without start index.
  assert(getFirstEmptyArrayIndex([]) === -1)
  assert(getFirstEmptyArrayIndex([undefined]) === 0)
  assert(getFirstEmptyArrayIndex(['foo']) === -1)
  assert(getFirstEmptyArrayIndex(['foo', undefined]) === 1)
  assert(getFirstEmptyArrayIndex(['foo', undefined, false]) === 1)
  assert(getFirstEmptyArrayIndex(['foo', 3, undefined]) === 2)

  // With start index.
  assert(getFirstEmptyArrayIndex([], 10) === -1)
  assert(getFirstEmptyArrayIndex([undefined], 10) === -1)
  assert(getFirstEmptyArrayIndex([undefined, 'foo', undefined], 1) === 2)
  assert(getFirstEmptyArrayIndex([false, undefined, 'foo'], 2) === -1)
  assert(getFirstEmptyArrayIndex(['foo', 'bar', false, false, false], 5) === -1)
}








function visitObjectKV (a, f) {
  assert (isObjectInstance(a))

  const keys = Object.keys(a).sort()
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = a[key]

    const result = visitValue(value, f)
    if(result === 'stop'){
      return 'stop';
    }
  }
  return 'continue'
}

function visitArrayElements (a, f) {
  assert (isArrayInstance(a))

  if (a.length > 0) {
    for (let i = 0; i < a.length; i++) {
      const result = visitValue(a[i], f)
      if(result === 'stop'){
        return 'stop';
      }
    }
  }
  return 'continue'
}

function visitValue (a, f) {
  if (a === undefined) {
    return f.onUndefined ? f.onUndefined() : 'continue'
  }
  else if (a === null) {
    return f.onNull ? f.onNull() : 'continue'
  }
  else if (isObjectInstance(a)) {
    if(f.onObject){
      return f.onObject(a)
    }
    else {
      return visitObjectKV(a, f)
    }
  }
  else if (isArrayInstance(a)) {
    if(f.onArray){
      return f.onArray(a)
    }
    else {
      return visitArrayElements(a, f)
    }
  }
  else if (isStringInstance(a)) {
    return f.onString ? f.onString(a) : 'continue'
  }
  else if (isBooleanInstance(a)) {
    return f.onBoolean ? f.onBoolean(a) : 'continue'
  }
  else if (isNumberInstance(a)) {
    return f.onNumber ? f.onNumber(a) : 'continue'
  }
  else {
    assert(false)
    return 'stop'
  }
}

function prove__visitValue(){
  let numberCount = 0
  unitTestAssert(visitValue(0, { onNumber: (v) => { numberCount++; return 'continue' } }) === 'continue')
  unitTestAssert(numberCount === 1)
}

function prove__visitValue2(){
  let numberCount = 0
  unitTestAssert(
    visitValue(
      { a: 10, b: 20 },
      { onNumber: (v) => { numberCount++; return 'continue' } }
    ) === 'continue'
  )
  assert(numberCount === 2)
}

function prove__visitValue3(){
  let numberCount = 0
  unitTestAssert(
    visitValue(
      { a: 10, b: 20, c: { hello: 48 } },
      { onNumber: (v) => { numberCount++; return 'continue' } }
    ) === 'continue'
  )
  unitTestAssert(numberCount === 3)
}



//  MZ: This is used to make sure Vue hasn't contaminated our object with reactive-features.
//  TODO: Avoid couopling between all code and Vue at this level

function prove__isVueReactive__simpleObj() {
  const temp = { a: 3, b: 4 }
  assert(isVueReactive0(temp) === false)
}

function isVueReactive0(value){
  assert(isObjectInstance(value))

  if (value.__ob__) { /*It's observed by vue*/
    return true;
  }
  else {
    return false
  }
}

function isVueReactive(value){
  return isVueReactive0(value)
}

function isVueReactiveDeep(value){
  const result = visitValue(
    value,
    {
      onObject: (v) => {
        if(isVueReactive0(v)){
          return 'stop'
        }
        else {
          return visitObjectKV(v)
        }
      },
      onArray: (v) => {
        if(isVueReactive0(v)){
          return 'stop'
        }
        else {
          return visitArrayElements(v)
        }
      }
/*
      ,
      onString: isVueReactive0,
      onBoolean: isVueReactive0,
      onNumber: isVueReactive0
*/
    }
  )
  return result === 'stop'
}




function runUnitTests () {
  console.log('spotterfishCore.js -- START')

  prove_JavaScript()
  prove_isObjectInstance()
  prove_isArrayInstance()
  prove_isValidISODateString()
  prove_isFunctionInstance()
  prove_compareValues()
  prove_compareArraysByValue()
  prove_compareObjectsByValue()
  prove_castObjectValuesToString()
  prove_getFirstEmptyArrayIndex()

  prove__visitValue()
  prove__visitValue2()
  prove__visitValue3()

  prove__isVueReactive__simpleObj()

  console.log('spotterfishCore.js -- END')
}

module.exports = {
  checkCoreEnvironment,
  isDevelopment,
  isProduction,
  isTesting,
  isRunningInEmulator,

  assert,
  unitTestAssert,
  unitTestAssertThrowsError,
  consoleLogLines,

  isObjectInstance,
  isArrayInstance,
  isStringInstance,
  isBooleanInstance,
  isValidISODateString,
  isFunctionInstance,
  isNumberInstance,

  compareValues,
  compareArraysByValue,
  compareObjectsByValue,
  compareObjectKeys,
  compareObjects,

  copyByValue,
  castObjectValuesToString,
  getFirstEmptyArrayIndex,

  visitValue,
  visitObjectKV,
  visitArrayElements,

  isVueReactive,

  runUnitTests
}
