const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const Constants = require('../spotterfish_library/consts.js')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const isObjectInstance = SpotterfishCore.isObjectInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isFunctionInstance = SpotterfishCore.isFunctionInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const isBooleanInstance = SpotterfishCore.isBooleanInstance
const isArrayInstance = SpotterfishCore.isArrayInstance
const Timecode = require('../spotterfish_library/utils/Timecode')
const ClockValue = require('../spotterfish_library/ClockValue')
const SyncData = require('../spotterfish_library/SyncData')
// REMEBER TO UNCOMMENT
const {TimingObject} = require('timing-object')
const SharedStateTiming = require('../web_client/SharedStateTiming')


const _ = require('lodash')

/* :::::::::::::::::::::::::::::: TRANSPORT  :::::::::::::::::::::::::::::::: */


//  { playFlag: true, pos: 0 }, both members can be undefined
function checkTransport(t){
  assert(SpotterfishCore.isObjectInstance(t))
  assert(t.playFlag === undefined || _.isBoolean(t.playFlag))
  assert(t.pos === undefined || _.isNumber(t.pos))

  return true
}


/* :::::::::::::::::::::::::::::: TIMING  :::::::::::::::::::::::::::::::: */

function checkInvariantTiming(value) {
  assert(isObjectInstance(value))
  assert(isObjectInstance(value.timingProviderTO))
  assert(isObjectInstance(value.dawStreamerTO))
  return true
}

function closeTiming(timing) {
  assert(checkInvariantTiming(timing))

  timing.timingProviderTO = undefined

  timing.dawStreamerTO.update({velocity: 0.0})
  // timing.dawStreamerTO.off()
  timing.dawStreamerTO = undefined
}

function getTOTransport(to) {
  assert(isObjectInstance(to))

  const obj = to.query()
  if(obj === undefined){
    return undefined
  }
  else {
    assert(typeof obj.position === 'number' || obj.position === undefined)
    assert(typeof obj.velocity === 'number' || obj.velocity === undefined)

    return {
      pos: obj.position,
      playFlag: obj.velocity !== undefined ? (obj.velocity === 0.0 ? false : true) : undefined
    }
  }
}

function setTOTransport(to, t){
  assert(isObjectInstance(to))
  assert(checkTransport(t))

  if(t.pos !== undefined && t.playFlag !== undefined){
    to.update({ position: t.pos, velocity: t.playFlag ? 1.0 : 0.0 })
  }
  else if(t.pos !== undefined){
    to.update({ position: t.pos })
  }
  else if(t.playFlag !== undefined){
    to.update({ velocity: t.playFlag ? 1.0 : 0.0 })
  }
  else {
  }
}

function onSharedStateVector (m, t) {
  const shouldUpdate = getSyncSource(m) === 'timing_provider' && m.timing.timingProvider.__timingProviderSource === 'shared_state_timing'
  console.log(shouldUpdate)
  if(shouldUpdate){
    setTOTransport(m.timing.timingProviderTO, t)
  }
}

async function initializeTO (timingProvider) {
  // assert(SpotterfishCore.isObjectInstance(TimingSRC))
  assert(SpotterfishCore.isObjectInstance(timingProvider) || timingProvider === undefined)

  try {

    const timingObject = new TimingObject(timingProvider)

    return timingObject
  }
  catch (error) {
    debugger
    console.log(error)
    throw new Error('Could not initialize timing object')
  } 
}



/* :::::::::::::::::::::::::::::: MASTERCLOCK  :::::::::::::::::::::::::::::::: */



function checkInvariantMasterClock(m) {
  assert(isObjectInstance(m))
  assert(isNumberInstance(m.videoLength) && m.videoLength >= 0.0)

  assert(_.isBoolean(m.localOverrideFlag))
  assert(checkTransport(m.localTransport))

  assert(_.isNumber(m.hideMCorpLatencyTimeoutMS_pos))
  assert(_.isNumber(m.hideMCorpLatencyTimeoutMS_playFlag))

  assert(isStringInstance(m.mediaSyncSource) && (m.mediaSyncSource === 'timing_provider' || m.mediaSyncSource === 'daw_streamer'))

  assert(isArrayInstance(m.externalTimeUpdates))
  // assert (m.motionApp === undefined || isObjectInstance(m.motionApp))
  assert(m.timing === undefined || checkInvariantTiming(m.timing))

  assert(m.syncData === undefined || isObjectInstance(m.syncData))

  return true
}

async function createMasterClock(timingProvider, onTimingProviderTO_change, videoLength, mediaSyncSource){
  assert(isObjectInstance(timingProvider))
  assert(isFunctionInstance(onTimingProviderTO_change))
  assert(isNumberInstance(videoLength) && videoLength >= 0.0)
  assert(mediaSyncSource === 'timing_provider')
  
  const timing = {
    timingProviderTO: timingProvider.__timingProviderSource !== 'shared_state_timing' ? await initializeTO(timingProvider) : await initializeTO(undefined),
    dawStreamerTO: await initializeTO(undefined),
    timingProvider: timingProvider,
  }
  assert(checkInvariantTiming(timing))

  const masterClock = {
    videoLength: videoLength,

    localOverrideFlag: false,
    localTransport: {},
    hideMCorpLatencyTimeoutMS_pos: performance.now(),
    hideMCorpLatencyTimeoutMS_playFlag: performance.now(),

    mediaSyncSource: mediaSyncSource,
    externalTimeUpdates: [],
    // motionApp: motionApp,
    timing: timing,

    // object or undefined
    syncData: undefined
  }
  assert(checkInvariantMasterClock(masterClock))


  // ??? TODO, we get 'illegal handler' error on these
  // on exit masterClock.timing may be undefined
  // masterClock.timing.timingProviderTO.on(
  //   'change',
  //   () => {
  //     assert(checkInvariantMasterClock(masterClock))
  //     if (!masterClock.timing) { return }
  //     const pos = getMcorpServerPositionOptional(masterClock.timing)
  //     if(pos !== undefined) {
  //       onTimingProviderTO_change(pos)
  //     }
  //   }
  // )

  return masterClock
}

function prove_createMasterClock(){
  //const result = await (motion_app_id, motion_name, onTimingProviderTO_change);
}


/*
function isMasterClockReady(m){
  assert(checkInvariantMasterClock(m))

  const ready =
    (m.mediaSyncSource === 'timing_provider')
    || (m.mediaSyncSource === 'daw_streamer' && m.syncData !== undefined)
  return ready 
}
*/

function onVideoPlayerTimeUpdate(m){
  assert(checkInvariantMasterClock(m))

}

function getSyncSource(m){
  assert(checkInvariantMasterClock(m))

  return m.mediaSyncSource
}

function selectSyncSource(m, source){
  assert(checkInvariantMasterClock(m))
  assert(isStringInstance(source))
  assert(source === 'timing_provider' || source === 'daw_streamer')

  m.mediaSyncSource = source

  assert(checkInvariantMasterClock(m))
}

function getCurrentTO(m) {
  assert(checkInvariantMasterClock(m))

  if(m.mediaSyncSource === 'timing_provider'){
    return m.timing.timingProviderTO
  }
  else if(m.mediaSyncSource === 'daw_streamer'){
    return m.timing.dawStreamerTO
  }
  else {
    assert(false)
  }
}

function setVideoLength(m, videoLength){
  assert(checkInvariantMasterClock(m))
  assert(isNumberInstance(videoLength) && videoLength >= 0.0)

  m.videoLength = videoLength

  assert(checkInvariantMasterClock(m))
}

function getClockValue(m, frameRateKeyOpt, videoFileOffset, timecodeOffsetSeconds){
  assert(checkInvariantMasterClock(m))
  assert(frameRateKeyOpt === undefined || Timecode.checkFrameRateKey(frameRateKeyOpt))
  assert(_.isNumber(videoFileOffset) && videoFileOffset >= 0)
  assert(_.isNumber(timecodeOffsetSeconds) && timecodeOffsetSeconds >= 0)

  const latencyHiddenFlag_pos = m.hideMCorpLatencyTimeoutMS_pos > performance.now()
  const latencyHiddenFlag_playFlag = m.hideMCorpLatencyTimeoutMS_playFlag > performance.now()

  const haveVideoFlag = m.videoLength !== undefined && m.videoLength > 0
  const videoFileFrameRateKey = frameRateKeyOpt !== undefined ? frameRateKeyOpt : 'film'
  const videoFileLength = m.videoLength

  const mcorpTransport0 = getTOTransport(m.timing.timingProviderTO)
  const mcorpTransport1 = haveVideoFlag ? mcorpTransport0 : { pos: 0, playFlag: false }
  const localOverrideT = { pos: latencyHiddenFlag_pos ? m.localTransport.pos : undefined, playFlag: latencyHiddenFlag_playFlag ? m.localTransport.playFlag : undefined }
  const mcorpTransport = mergeTransports(localOverrideT, mcorpTransport1)

  const dawStreamerTransport = getTOTransport(m.timing.dawStreamerTO)

  const t0 = m.mediaSyncSource === 'timing_provider' ? mcorpTransport : dawStreamerTransport
  const playbackTransport = t0 !== undefined ? t0 : { pos: 0, playFlag: false }

  const localOverrideFlag = m.localOverrideFlag

  const interactiveTransport = localOverrideFlag ? m.localTransport : playbackTransport

  const result = {
    //  Old, lossy data
    // frameRateKey: videoFileFrameRateKey,
    // offset: videoFileOffset,
    // length: m.videoLength,
    position: Math.max(0, playbackTransport.pos),
    playFlag: playbackTransport.playFlag,


    playbackTransport: playbackTransport,
    interactiveTransport: interactiveTransport,

    latencyHiddenFlag_pos: latencyHiddenFlag_pos,
    latencyHiddenFlag_playFlag: latencyHiddenFlag_playFlag,

    videoFileFrameRateKey: videoFileFrameRateKey,
    videoFileLengthOpt: videoFileLength,
    videoFileOffset: videoFileOffset,
    timecodeOffsetSeconds: timecodeOffsetSeconds,
    //videoPlayerTransport: {},
    localTransport: m.localTransport,
    mcorpTransport: mcorpTransport,
    dawStreamerTransport: dawStreamerTransport,

    mediaSyncSource: m.mediaSyncSource
  }

  assert(ClockValue.checkClock(result))
  return result
}


function beginInteraction(m){
  m.localOverrideFlag = true
}

function setInteractiveTransport(m, t){
  m.localTransport = t
}

function endInteraction(m){
  m.localOverrideFlag = false
}


function closeMasterClock(value){
  assert(checkInvariantMasterClock(value))

  closeTiming(value.timing)
  value.timing = undefined

  value.mediaSyncSource = undefined
  value.motionApp = undefined
}

function calcJitterbufferOffset(jitterbufferOffset, timestamp, syncData) {
  // console.log(report)

  
  const ts = String(timestamp * 1000)
  const tsTruncated = Number(ts.split('').slice(-9).join(''))
  
  const encodingDelaySeconds = (syncData.rtpTimestamp - tsTruncated) / 1000000
  const totalOffset = jitterbufferOffset - 0.180
  return isNaN(jitterbufferOffset) ? 0.02 : totalOffset + 0.02
}

function onWhipStreamPackage (m, syncpackage, offset, whipOffsetFrames) {
  assert(checkInvariantMasterClock(m))
  assert(SyncData.checkSyncData(syncpackage.syncData))
  assert(_.isNumber(offset))
  assert(_.isNumber(whipOffsetFrames))


  let frk;
  let frkInfo;
  
  if (syncpackage.syncData) {
    frk = Timecode.mtcFrameRateIndexToKey(syncpackage.syncData.mtcFrameRateIndex);
    frkInfo = Timecode.kFrameRateKeyCatalog[frk];
    if (frkInfo === undefined) {
      throw new Error('Illegal frame rate ' + frk);
    }
  }
  
  const projectFrk = Timecode.mtcFrameRateIndexToKey(syncpackage.frameRateIndex);
  const frameRateObject = Timecode.kFrameRateKeyCatalog[projectFrk];
  
  const syncTime0 = syncpackage.syncData.posFramesDecimal / frkInfo.freq;
  if (syncTime0 === undefined) return;
  
  // at 23.976 etc PT plays back slower, we compensate that by multilying the time code / position by 
  // MTC frequence / projector room frequncy setting i.e syncTime0 * frkInfo.freq / projectFRK.freq
  const syncTime = syncTime0 * frkInfo.freq / frameRateObject.freq

  if (syncTime !== undefined) {
    const pos2 = syncTime - offset

    let compensationOffset
    if (syncpackage.syncData.playFlag && whipOffsetFrames) {
      compensationOffset = whipOffsetFrames / frkInfo.freq
    } else {
      compensationOffset = 0.01
    }
    //  NOTICE: We don't clamp position to video length here. That is done when consuming the daw-position.
    const pos3 = pos2 >= 0 ? pos2 + compensationOffset : 0
    setTOTransport(m.timing.timingProviderTO, { pos: pos3, playFlag: syncpackage.syncData.playing })

    m.syncData = syncpackage.syncData
  }
}

function onDAWStreamPackage (m, syncData, offset, jitterbufferOffset, timeStamp) {
  assert(checkInvariantMasterClock(m))
  assert(SyncData.checkSyncData(syncData))
  assert(_.isNumber(offset))

  let frk
  let frkInfo

  if (syncData.playInfo) {
    frk = Timecode.mtcFrameRateIndexToKey(syncData.playInfo.rateIndex)
    frkInfo = Timecode.kFrameRateKeyCatalog[frk]
    if(frkInfo === undefined){
      debugger
      throw new Error('Illegal frame rate ' + frk)
    }
  }
  const projectFrk = Timecode.mtcFrameRateIndexToKey(syncData.projectFRK)
  const frameRateObject = Timecode.kFrameRateKeyCatalog[projectFrk]
  
  const syncTime0 = syncData.playInfo ? SyncData.getSyncDataTime(syncData) : undefined

  if(syncTime0 === undefined) return

  // at 23.976 etc PT plays back slower, we compensate that by multilying the time code / position by 
  // MTC frequence / projector room frequncy setting i.e syncTime0 * frkInfo.freq / projectFRK.freq
  const syncTime = syncTime0 * frkInfo.freq / frameRateObject.freq

  if (syncTime !== undefined) {
    const pos2 = syncTime - offset

    let compensationOffset
    if (syncData.playing && jitterbufferOffset) {
      compensationOffset = calcJitterbufferOffset(jitterbufferOffset, timeStamp, syncData)
    } else {
      compensationOffset = 0.01
    }
    //  NOTICE: We don't clamp position to video length here. That is done when consuming the daw-position.
    const pos3 = pos2 >= 0 ? pos2 + compensationOffset : 0
    setTOTransport(m.timing.dawStreamerTO, { pos: pos3, playFlag: syncData.playing })

    m.syncData = syncData
  }
}

function mergeTransports(override, base){
  return {
    playFlag: override.playFlag !== undefined ? override.playFlag : base.playFlag,
    pos: override.pos !== undefined ? override.pos : base.pos
  }
}


function userRequestTransport (srs, t) {
  // Importing checkInvariantScreeningRoomSession gets complicated, breaking out masterclock for now.
  const m = srs.masterClock
  assert(checkInvariantMasterClock(m))
  assert(checkTransport(t))
  
  if(getSyncSource(m) === 'timing_provider'){
    let t2 = _.cloneDeep(t)
    const t0 = getTOTransport(m.timing.timingProviderTO)
    if(t0 !== undefined){

      //  Request PLAY when at end of video => reposition to start of video. 
      if(t.playFlag !== undefined && t.playFlag === true && t0.playFlag === false && (t0.pos !== undefined && t0.pos >= m.videoLength)){
        t2.pos = 0
      }

       //  If user pressed pause - update timing object with current stop time - hides latency further
       if(t.playFlag !== undefined && t.playFlag === false && t0.playFlag === true && (t0.pos !== undefined && t2.pos !== 0)){
        t2.pos = t0.pos
        console.log('hiding')
      }
      const merge = mergeTransports(t2, t0)

      if(_.isEqual(merge, t0) === false){
        // TODO: Use switch to set transport correctly
        setTOTransport(m.timing.timingProviderTO, t2)
        // We just fire this away to shared state, this is an async function that we do not await
        SharedStateTiming.updateSharedStatePlayVector(srs, t2)
        if(t2.playFlag !== undefined){
          m.hideMCorpLatencyTimeoutMS_playFlag = performance.now() + 1000
        }
        if(t2.pos !== undefined){
          m.hideMCorpLatencyTimeoutMS_pos = performance.now() + 1000
        }
        m.localTransport = t2
      }
    }
    else {
      assert(false)
    }
  }
  else {
    console.log(`Using ${getSyncSource(m)} as sync source - ignoring user transport request`)
    //  NOP
  }
}

function runUnitTests () {
  console.log('MasterClock -- start')

  prove_createMasterClock()
  //??? todo

  console.log('MasterClock -- end')
}

module.exports = {
  checkInvariantMasterClock,
  createMasterClock,
  closeMasterClock,
  checkInvariantTiming,

  getSyncSource,
  selectSyncSource,

  onVideoPlayerTimeUpdate,
  getCurrentTO,
  getClockValue,
  setVideoLength,

  onSharedStateVector,

  beginInteraction,
  setInteractiveTransport,
  endInteraction,

  onDAWStreamPackage,

  onWhipStreamPackage,

  userRequestTransport,

  runUnitTests
}