export default {
  findInArray (arr, id) {
    if (!arr.length) {
      return
    }
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === id) {
        return true
      }
    }
    return false
  },
  removeFromArray (arr, id) {
    if (!arr.length) {
      return
    }
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === id) {
        arr.splice(i, 1)
      }
    }
    return false
  },
  getFromArray (arr, id) {
    return arr.find(o => o['.key'] === id)
  },
  sortByKey (array, key) {
    return array.sort(function (a, b) {
      var x = a[key]
      var y = b[key]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  }
}
