<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" @click:outside="cancel">
    <v-toolbar dark :color="colors.mainAccent" dense flat style="width: 100%;">
        <v-spacer></v-spacer>
        <v-toolbar-title v-if="step === 1" class="white--text">Is MixStage video playback blocked?</v-toolbar-title>
        <v-toolbar-title v-if="step === 2" class="white--text">Computer/bandwidth settings</v-toolbar-title>

        <v-spacer></v-spacer>
    </v-toolbar>
    <div style="display: flex; align-items: start; flex-direction: column; text-align: left; padding: 1rem;" :style="{ 'background-color': colors.darkGray }">
      
      <div v-if="step === 1">
        <p class="info-text header">
          <span>Your browser may be blocking the Spotterfish Video player </span>
          <span>(to protect you from autoplaying web sites)</span>
        </p>
        <div style="align-self: center; height: 236px;">
          <video ref="videoAutoplayVideo" @canplaythrough="setVideoLoadingTime()" style="width: 420px; margin: 0 auto;" controls src="https://firebasestorage.googleapis.com/v0/b/audiopostrdev.appspot.com/o/QNMCj54HLLcada0AZ7mq%2Fbig_buck_bunny_1080p_surround-6ff901f2ac.mp4?alt=media&token=827a7d86-35be-4ced-9bb1-a5163ae95b5a" />
        </div>
        <div class="solution-box" style="margin-top: 14px;">
          <p class="info-text header">
            SOLUTION: 
          </p>
          <p class="info-text">
            - Press play on the video above and let it stay in play mode for 10 seconds
          </p>
          <p class="info-text">
            - Pull volume up and down while playing
          </p>

          <p class="info-text">
            - Make sure you have no "prevent autoplay"-extensions loaded in you browser
          </p>
        </div>
      </div>

      <div v-if="step === 2">
        <div class="info-text header" style="display: flex; align-items: center; justify-content: space-between; background-color: #1f1f1f; padding: 10px 0 10px 0">
          <span style="padding-left: 10px;">CPU/GPU: </span>
          <span style="font-size: 0.8em; font-weight: bold;">{{getGPUScoreComment(gpuTier.fps)}}</span> <br />
        </div>
        <div class="solution-box" style="margin: -20px 0 40px 0;">
          
          <div style="align-self: center; height: 120px;">
            <p class="info-text">
              - Close other CPU/GPU-heavy applications on your computer
            </p>
            <div style="margin-top: 40px;">
              <v-checkbox v-model="usePitch" label="Reel-to-reel mode (less CPU-heavy, but will introduce audio pitch changes on playback start)"></v-checkbox>
              <!-- <v-slider label="Jump Threshold:" thumb-label min="0" max="2" step="0.1" v-model="syncSettings.threshold"></v-slider>
              <v-slider label="Time Constant:" thumb-label min="0" max="2" step="0.5" v-model="syncSettings.timeConstant"></v-slider>
              <v-slider label="Tolerance:" thumb-label min="0.005" max="2" step="0.005" v-model="syncSettings.tolerance"></v-slider> -->
            </div>
          </div>
        </div>

        <div class="info-text header" style="display: flex; align-items: center; justify-content: space-between; background-color: #1f1f1f; padding: 10px 0 10px 0">
          <span style="padding-left: 10px;">NETWORK: </span>
          <span style="font-size: 0.8em; font-weight: bold;">{{getNetworkScoreComment(videoLoadMeasure.loadTime).split(',')[0]}}</span> <br />
        </div>
        <div class="solution-box" style="margin: -20px 0 20px 0;">

          <div style="align-self: center; height: 120px;">
            <p class="info-text">
              - Do not download or upload large files while in session
            </p>
            <p class="info-text">
              - Streaming 4k files may require above 20Mbit/second
            </p>
            <!-- <div style="margin-top: 20px;">
              <v-checkbox :value="useLowResVideo" @change="useLowResVideo = $event" label="Choose a web safe video if available"></v-checkbox>
            </div> -->
            <!-- <div style="margin-top: 40px;">
              <v-checkbox v-model="plaseWait" label="Ask moderator to wait for me before each playback"></v-checkbox>
            </div> -->
          </div>
        </div>
      </div>

      <div style="align-self: end;">
        <v-btn v-if="step === 2" class="button" style="align-self: start;" :color="colors.gray" @click="setStartVideoLoadTime(), step--">PREVIOUS STEP</v-btn>
        <v-btn v-if="step === 1" class="button" style="align-self: end;" :color="colors.gray" @click="$refs.videoAutoplayVideo.pause(), step++">ADVANCED</v-btn>
        <v-btn class="button" :color="colors.mainAccent" @click.native="agree">OK</v-btn>

      </div>
    </div>
  </v-dialog>
</template>

<script>
// @ts-nocheck
import { getGPUTier } from 'detect-gpu'

export default {
  data: () => ({
    colors: require('@/lib/ui/colors.js').colors,
    dialog: false,
    step: 1,
    usePitch: undefined,
    syncSettings: {
      threshold: 1,
      timeConstant: 0.5,
      tolerance: 0.025
    },
    useLowResVideo: undefined,
    videoLoadMeasure: {
      startTime: undefined,
      endTime: undefined,
      loadTime: undefined
    },
    gpuTier: undefined,
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200
    },
    messageStrings: [
      'Top score, not likely an issue',
      'Very good score, not likely an issue',
      `Good score, not likely an issue`,
      `Possible problem, please check checkox below`,
      `Too slow, you need to check the checkbox below`
    ]
  }),
  computed: {

  },
  methods: {
    setStartVideoLoadTime() {
      this.videoLoadMeasure.startTime = performance.now()
    },
    setVideoLoadingTime() {
      console.log('Setting laod time')
      this.videoLoadMeasure.endTime = performance.now()
      this.videoLoadMeasure.loadTime = this.videoLoadMeasure.endTime  - this.videoLoadMeasure.startTime
      console.log('Load time: ' + this.videoLoadMeasure.loadTime)
    },
    getGPUScoreColor (fps) {
      if (fps > 200) { return 'green' }
      else if (fps > 100) { return 'green'  }
      else if (fps > 60) { return 'yellow' }
      else if (fps > 30) { return 'orange' }
      else if (fps > 15) { return 'red' }
      else { return 'red' }
    },
    getGPUScoreComment (fps) {
      if (fps > 200) { return this.messageStrings[0] }
      else if (fps > 100) {return this.messageStrings[1] }
      else if (fps > 60) { return this.messageStrings[2] }
      else if (fps > 30) { return this.messageStrings[3] }
      else if (fps > 15) { return this.messageStrings[4] }
      else { return this.messageStrings[4] }
    },
    getNetworkScoreComment (networkLoadTime) {
      if (networkLoadTime > 8000) { return this.messageStrings[4] }
      else if (networkLoadTime > 6000) {return this.messageStrings[3] }
      else if (networkLoadTime > 4000) {return this.messageStrings[3] }
      else if (networkLoadTime > 2000) { return this.messageStrings[2] }
      else if (networkLoadTime > 1000) { return this.messageStrings[1] }
      else { return this.messageStrings[0] }
    },
    
    cancel (){
      this.$store.state.modals.videoAutoPlayFix = false
    },
    async agree () {
      if (localStorage.getItem('spotterfishUserUsePitch') === 'true' !== this.usePitch) {
        await this.$root.$globalAlert.open(
            `Restart of service needed`,
            {
              line1: 'To use your new setting we need to reload this web page'
            },
            { color: this.colors.mainAccent }
          )
        localStorage.setItem('spotterfishUserUsePitch', this.usePitch)
        window.location.reload()
      }
      localStorage.setItem('spotterfishUserUseLowResVideo', this.useLowResVideo)

      // if (localStorage.getItem('spotterfishSyncSettings') !== JSON.stringify(this.syncSettings)) {
      //   if(this.syncSettings.tolerance > this.syncSettings.threshold) {
      //     alert('tolerance needs to be lower than threshold, setting it to the same value')
      //     this.syncSettings.tolerance = this.syncSettings.threshold
      //   }
      //   await this.$root.$globalAlert.open(
      //       `Restart of service needed`,
      //       {
      //         line1: 'To use your new setting we need to reload this web page'
      //       },
      //       { color: this.colors.mainAccent }
      //     )
      //   localStorage.setItem('spotterfishSyncSettings', JSON.stringify(this.syncSettings))
      //   window.location.reload()
      // }
      this.$store.state.modals.videoAutoPlayFix = false
    }
  },
  beforeMount () {
    this.setStartVideoLoadTime()
    this.usePitch = localStorage.getItem('spotterfishUserUsePitch') === 'true' ? true : false
    // let syncSettings = localStorage.getItem('spotterfishSyncSettings')
    // if (syncSettings) {
    //   console.log(syncSettings)
    //   syncSettings = JSON.parse(syncSettings)
    //   syncSettings.threshold = Number(syncSettings.threshold)
    //   syncSettings.timeConstant = Number(syncSettings.timeConstant)
    //   syncSettings.tolerance = Number(syncSettings.tolerance)
    //   console.log('retreived sync settings from localstorage', syncSettings)
    //   this.syncSettings = syncSettings
    // } else {
    //   console.log('-- using default syncsettings', this.syncSettings)
    // }
    console.log('savedPitchSetting is set to ' + this.usePitch)
  },
  async mounted (){
    this.dialog = true
    this.gpuTier = await getGPUTier()
    this.useLowResVideo = localStorage.getItem('spotterfishUserUseLowResVideo')
  }
}
</script>

<style lang="scss" scoped>
.info-text {

} 
.solution-box {
  border-radius: 3px;
  background-color: #333333;
  padding: 10px;
}
.button {
  margin: 20px 5px 0 5px;
}
.header{
  font-size: 1.075em;
    margin: 10px 0 20px 0;
  }
</style>