const SpotterfishCore = require('../SpotterfishCore')
const _ = require('lodash');

/**
 * 
 * {
    "position": 0,
    "playFlag": false,
    "playbackTransport": {
        "playFlag": false,
        "pos": 0
    },
    "interactiveTransport": {
        "playFlag": false,
        "pos": 0
    },
    "latencyHiddenFlag_pos": false,
    "latencyHiddenFlag_playFlag": false,
    "videoFileFrameRateKey": "film",
    "videoFileLengthOpt": 0,
    "videoFileOffset": 0,
    "timecodeOffsetSeconds": 0,
    "localTransport": {},
    "mcorpTransport": {
        "playFlag": false,
        "pos": 0
    },
    "dawStreamerTransport": {
        "pos": 0,
        "playFlag": false
    },
    "mediaSyncSource": "timing_provider"
}
 *  */


function cloneVue(v) {
  if(_.isObject(v)){
    let r = {}
    mergeVueObjects(r, v)
    return r
  }
  else {
    return v
  }
}


/*
  Designed as workaround for vue.js. Vue.js patches objects with get/set listerners etc. We want to avoid this work.
  Avoids store operations to dest if possible -- to avoid vue triggering updates.

  Recursively copies properties from source to dest.
  Dest object hiearchy may have more properties than source - those will be uneffected.
  Source can contain new properties: both values & objects.
  When dest contains an objects, that *object* will never be replaced, instead its contents will be updated.

  Skips vue '__ob__' properties
*/

//  TODO: Rename to "mergeVueObjects()"
function mergeVueObjects(dest, source) {
  SpotterfishCore.assert(_.isObject(dest))
  SpotterfishCore.assert(_.isObject(source))

  Object.keys(source).forEach(key => {
    if(key !== '__ob__'){
      //  Add new property?
      if(dest[key] === undefined){
        dest[key] = cloneVue(source[key])
      }

      //  Update existing property.
      else {
        if(_.isObject(source[key])){
          mergeVueObjects(dest[key], source[key])
        }
        else {
          //  Avoid store if values are the same.
          if(dest[key] !== source[key]) {
            dest[key] = source[key]
          }
        }
      }
    }
  })
}

/*
Vue Reactivity for New Properties: When adding new properties to a Vue reactive object (like dest), simply assigning dest[key] = value might not make the new properties reactive 
  if they're added after the object has been made reactive by Vue. To ensure new properties are reactive, you should use Vue.set(dest, key, value) for Vue 2 or dest[key] = value 
  inside Vue.nextTick() in Vue 3, since Vue 3's reactivity system automatically handles this.

  Deep Clone with cloneVue: The function cloneVue(source[key]) is mentioned but not defined here. Assuming it performs a deep clone, 
  it's crucial to ensure that this cloning respects Vue's reactivity if source[key] is a reactive object. 
  Otherwise, you might end up breaking reactivity for nested objects.

  Avoiding Unnecessary Updates: The check if(dest[key] !== source[key]) before assigning new values is good for preventing unnecessary updates. However, for deep objects, 
*/

function mergeVueObjectsDeep(dest, source, VueInstance) {
  // Assert that both dest and source are objects
  SpotterfishCore.assert(_.isObject(dest));
  SpotterfishCore.assert(_.isObject(source));

  Object.keys(source).forEach(key => {
    if (key !== '__ob__') {
      const destValue = dest[key];
      const sourceValue = source[key];

      // Check if the source value is an array of primitives
      if (Array.isArray(sourceValue) && sourceValue.every(item => typeof item !== 'object')) {
        // Replace the dest array with the source array directly
        VueInstance.$set(dest, key, sourceValue.slice());
      } else if (_.isObject(sourceValue) && !_.isEqual(destValue, sourceValue)) {
        // For objects, the existing logic applies
        if (_.isObject(destValue)) {
          mergeVueObjectsDeep(destValue, sourceValue, VueInstance);
        } else {
          // If dest has a scalar where source has an object, replace it
          VueInstance.$set(dest, key, _.cloneDeep(sourceValue));
        }
      } else if (destValue !== sourceValue) {
        // For scalar values, update only if they are different
        VueInstance.$set(dest, key, sourceValue);
      }
    }
  });
}







function runUnitTest() {
  prove__mergeVueObjectsDeep__nop();
  prove__mergeVueObjectsDeep__onePropNoChange();
  prove__mergeVueObjectsDeep__oneProp1Change();
  prove__mergeVueObjectsDeep__deepProps();
  prove__mergeVueObjectsDeep__skipOb();
  prove__mergeVueObjectsDeep__addProp();
  prove__mergeVueObjectsDeep__primitiveArrayHandling()
  prove__mergeVueObjects__nop()
  prove__mergeVueObjects__onePropNoChange()
  prove__mergeVueObjects__oneProp1Change()
  prove__mergeVueObjects__deepProps()
  prove__mergeVueObjects__skipOb()
  prove__mergeVueObjects__addProp()
}

const VueInstanceMock = {
  $set: (obj, key, value) => { obj[key] = value; }
};

function prove__mergeVueObjectsDeep__nop() {
  let r = {};
  mergeVueObjectsDeep(r, {}, VueInstanceMock);
  console.log('nop:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, {}));
}

function prove__mergeVueObjectsDeep__onePropNoChange() {
  let r = { "position": 0 };
  mergeVueObjectsDeep(r, { "position": 0 }, VueInstanceMock);
  console.log('onePropNoChange:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0 }));
}

function prove__mergeVueObjectsDeep__oneProp1Change() {
  let r = { "position": 0 };
  mergeVueObjectsDeep(r, { "position": 1 }, VueInstanceMock);
  console.log('oneProp1Change:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 1 }));
}

function prove__mergeVueObjectsDeep__deepProps() {
  let r = { "position": 0, child: { "magic": 10 } };
  mergeVueObjectsDeep(r, { "position": 0, child: { "magic": 11 } }, VueInstanceMock);
  console.log('deepProps:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0, child: { "magic": 11 } }));
}

function prove__mergeVueObjectsDeep__skipOb() {
  const r = { "position": 0, "__ob__": 666, child: { "magic": 10 } };
  mergeVueObjectsDeep(r, { "position": 0, "__ob__": 667, child: { "magic": 11 } }, VueInstanceMock);
  console.log('skipOb:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0, "__ob__": 666, child: { "magic": 11 } }));
}

function prove__mergeVueObjectsDeep__addProp() {
  const r = { child: { "magic": 10, "pos": 2 } };
  mergeVueObjectsDeep(r, { child: { "magic": 10 } }, VueInstanceMock);
  console.log('addProp:', r);
  SpotterfishCore.unitTestAssert(_.isEqual(r, { child: { "magic": 10, "pos": 2 } }));
}

function prove__mergeVueObjectsDeep__primitiveArrayHandling() {
  let dest = {
    items: [1, 2, 3] // Initial array of primitive values
  };
  let source = {
    items: [2, 3, 4, 5] // Source array to merge, containing some overlapping and some unique values
  };
  mergeVueObjectsDeep(dest, source, VueInstanceMock);
  console.log('primitiveArrayHandling:', dest);

  // Since we've decided to simply replace arrays, we expect the destination array to directly mirror the source array
  SpotterfishCore.unitTestAssert(_.isEqual(dest, {
    items: [2, 3, 4, 5] // Expected result after merge
  }));
}


function prove__mergeVueObjects__nop(){
  let r = {}
  mergeVueObjects(r, {})
  console.log(r)
  SpotterfishCore.unitTestAssert(_.isEqual(r, {}))
}

function prove__mergeVueObjects__onePropNoChange(){
  let r = { "position": 0 }
  mergeVueObjects(r, { "position": 0 })
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0 }))
}
function prove__mergeVueObjects__oneProp1Change(){
  let r = { "position": 0 }
  mergeVueObjects(r, { "position": 1 })
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 1 }))
}

function prove__mergeVueObjects__deepProps(){
  let r = { "position": 0, child: { "magic": 10 } }
  mergeVueObjects(r, { "position": 0, child:{ "magic": 11 } })
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0, child:{ "magic": 11 } }))
}

function prove__mergeVueObjects__skipOb(){
  const r = { "position": 0, "__ob__": 666, child: { "magic": 10 } }
  mergeVueObjects(r,{ "position": 0, "__ob__": 667, child: { "magic": 11 } })
  SpotterfishCore.unitTestAssert(_.isEqual(r, { "position": 0, "__ob__": 666, child:{ "magic": 11 } }))
}

function prove__mergeVueObjects__addProp(){
  const r = { child: { "magic": 10, "pos": 2 } }
  mergeVueObjects(r,{ child: { "magic": 10 } })
  SpotterfishCore.unitTestAssert(_.isEqual(r, { child:{ "magic": 10, "pos": 2 } }))
}


module.exports = {
  mergeVueObjects,
  mergeVueObjectsDeep,
  runUnitTest
}