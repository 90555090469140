<template>
  <div>
    <v-menu offset-y @input="initializeComponent" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-row no-gutters v-bind="attrs" v-on="on">
          <v-btn text :style="{ width: '100% !important' }" style="font-size: 0.7em;">
            <v-row no-gutters>
              <v-col cols="1">
                <v-avatar size="20">
                  <img v-if="getUser().pict" :src="getUser().pict" />
                  <v-icon v-if="!getUser().pict">mdi-account-circle</v-icon>
                </v-avatar> 
              </v-col>
              <v-col cols="10" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="mt-1">
                <div style="max-width: 186px; margin: 0 0 0 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ getUser().user_name }}'s account</div>
              </v-col>
              <v-col cols="1">
                <v-icon style="font-size: 2em;">mdi-chevron-down</v-icon>
              </v-col> 
            </v-row>
          </v-btn>
        </v-row>
      </template>
      <v-list class="text-start">
        <v-list-item style="display: flex; align-items: center; justify-content: center;">
          <div class="profile-picture">
            <v-tooltip right :color="colors.mx.dark">
              <template v-slot:activator="{ on, attrs }">
                <div class="picture-bubble" ref="pictureBubble" style="position: relative; pointer-events: none;">
                  <vue-avatar
                    :image="myUser.pict || undefined"
                    :borderRadius=100
                    :width=120
                    :height=120
                    style="pointer-events: auto;"
                    :scale="scale"
                    ref="vueavatar"
                    @vue-avatar-editor:image-ready="onImageReady"
                  >
                  </vue-avatar>
                  <!-- Activator overlay -->
                  <div v-bind="attrs" v-on="on" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"></div>
                </div>
              </template>
              <span>Edit profile picture</span> <br/>
              <span>Click to Select Image</span><br/>
              <span>Drag to Move</span><br/>
              <span>Scroll to Zoom</span><br/>
              <span>Close to Save</span>
              
            </v-tooltip>
            <v-tooltip right :color="colors.mx.dark">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click.stop="removeImage" size="1.5em" style="position: absolute; right: 10%; top: 45%;">delete</v-icon>
              </template>
              <span>Remove picture</span> 
              
            </v-tooltip>
          </div>

        </v-list-item>
        
        <v-list-item style="width: 100%; text-align: left;">
          <v-text-field class="mt-6" outlined dense label="Display Name" :value="getUser().user_name" :rules="[hasDisplayName]" @click.stop="" @change="updateUserName($event)" />
        </v-list-item>
        
        <v-list-item :disabled="emailVerified">
          <v-list-item-avatar>
            <v-icon>{{ emailVerified ? 'mail_lock' : 'mail_unlock' }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ emailVerified ? `Email: ${getUser().email}`: `Verify email: ${getUser().email}` }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop.prevent="passwordInput = true" tag="button" style="width: 100%; text-align: left;">
          <v-list-item-avatar>
            <v-icon>key</v-icon>
          </v-list-item-avatar> 
          <v-list-item-title>Change password</v-list-item-title>
        </v-list-item>

        <v-list-item :disabled="!emailVerified" @click.stop.prevent="$router.push('enroll')" tag="button" style="width: 100%; text-align: left;">
          <v-list-item-avatar>
            <v-icon>lock</v-icon>
          </v-list-item-avatar> 
          <v-list-item-title>Two-factor Authtentication</v-list-item-title>
        </v-list-item>


        <v-list-item :disabled="!isStripeUser" @click.stop="goToPaymentPortal()" tag="button" style="width: 100%; text-align: left;">
          <v-list-item-avatar>
            <v-icon>credit_card</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Billing & Plans</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="signOut()" tag="button" style="width: 100%; text-align: left;">
          <v-list-item-avatar>
            <v-icon>logout</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-menu>
<!-- 
<v-form v-model="formIsValid" @submit.prevent="handleSubmit">

      <div class="settings">

        <div class="profile-picture">
          <h4>Profile image</h4>
          <div class="picture-bubble">
            <vue-avatar
              :image="myUser.pict || logos.centeredSmall"
              :borderRadius=200
              :width=220
              :height=220
              :scale="scale"
              ref="vueavatar"
              @vue-avatar-editor:image-ready="onImageReady">
            </vue-avatar>
          </div>
    
          <div style="width: 300px; margin: auto;">
            <v-slider
              v-model="scale"
              min=1
              max=3
              step=0.02
              append-icon="zoom_in"
              prepend-icon="zoom_out">
            </v-slider>
          </div>
    
        </div>
    
        <div class="input-row">
          <input type="text" />
        </div>
        <div class="input-row">
          <v-text-field outlined label="EMAIL" readonly disabled :value="myUser.email" class="item" />
          <v-btn class="item" :disabled="isVerified" large @click.stop.prevent="sendVerificationEmail">
            <span v-if="isVerified">
              <v-icon
                class="verified-badge"
                color="green"
                small
              >
                mdi-check-decagram
              </v-icon>
              email verified
            </span>
            <span v-if="!isVerified">
              <v-icon
                class="verified-badge"
                color="red"
                small>
                mdi-alert-decagram
              </v-icon>
              Verify email
            </span>
          </v-btn>
        </div>
        <div class="input-row">
          <v-text-field outlined label="DISPLAY NAME" v-model="myUser.user_name" :rules="[hasDisplayName]" />
        </div>
        <div class="input-row">
          <div class="button-with-label item">
            <label>Change password</label>
            <v-btn large @click.stop.prevent="passwordInput = true">Change password</v-btn>
          </div>
          <div class="button-with-label item">
            <label>Two-factor authentication</label>
            <v-btn large :disabled="!isVerified" @click.stop.prevent="$router.push('enroll')">Set up</v-btn>
          </div>
        </div>
    
        <div class="action-buttons">
          <v-btn large @click="$emit('close-button-clicked')">Cancel</v-btn>
          <v-btn type="submit" large :color="colors.mainAccent" :disabled="!formIsValid">Save</v-btn>
        </div>
      </div>
    </v-form>
     -->

     <v-dialog v-model="passwordInput" max-width=500>
      <div class="change-password">
        <h4>Change password</h4>
        <v-form v-model="passwordChangeIsValid" @submit.prevent="updatePassword">
          <v-text-field
            outlined
            autofocus
            label="Old Password"
            v-model="oldPassword"
            :type="showPW ? 'text' : 'password'"
            placeholder="Enter your old password"
            name="oldpwd"
          />
          <v-text-field
            outlined
            label="New Password"
            v-model="password"
            :type="showPW ? 'text' : 'password'"
            :success="!!password"
            :rules="[passwordRules.required, passwordRules.min]"
            placeholder="Choose a Password, 8 characters min"
            :append-icon="showPW ? 'visibility' : 'visibility_off'"
            @click:append="showPW = !showPW"
            name="newpwd"
          />
          <div class="save-new-password">
            <v-btn :disabled="!passwordChangeIsValid" :color="colors.mainAccent" type="submit">SAVE</v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-overlay v-model="storingspinner" width=100 rounded persistent style="z-index: 1010;">
      <v-progress-circular
        :size="100"
        :width="7"
        :color="colors.mainAccent"
        indeterminate>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import {VueAvatar} from 'vue-avatar-editor-improved'
import firebase from 'firebase/app'
import 'firebase/auth'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'usersettings',
  components: {
    VueAvatar
  },
  props: ['intercomLoaded', 'isStripeUser'],
  data: function () {
    return {
      // UI
      colors: require('@/lib/ui/colors.js').colors,
      logos: require('@/lib/ui/logos.js').logos,
      verificationSpinner: false,
      storingspinner: false,
      displaynameInput: false,
      passwordInput: false,
      userName: '',
      password: '',
      oldPassword: '',
      showPW: false,
      scale: 1,
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`)
      },
      formIsValid: false,
      passwordChangeIsValid: false,
    }
  },
  computed: {

    emailVerified () {
      return App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser.emailVerified
    },
    myUser () {
      const aUser = this.$store.state.currentSpotterfishUser
      if (aUser) {
        return {
          email: aUser.email,
          user_name: aUser.user_name,
          pict: aUser.pict,
          uid: aUser.user_id
        }
      } else {
        return {
          email: '',
          user_name: '',
          pict: '',
          uid: ''
        }
      }
    },
    intercomStatus () {
      return window.Intercom ? true : false
    }
  },
  beforeDestroy() {
    const pictureBubble = this.$refs.pictureBubble
    if (pictureBubble) {
      pictureBubble.removeEventListener('wheel', this.handleZoom)
    }  
  },

  methods: {
    signOut() {
      this.$store.dispatch('removeAllRealtimeListeners').then(() => {
        this.$router.push({ name: 'signout' })
      })           
    },

    async goToPaymentPortal() {
      this.storingspinner = true

      try {
        const result = await CloudClient.call_CFcreateStripePortalSession(App.Firebase)
        const url = result.url
        console.log(url)
        window.location.href = url
        this.storingspinner = false

      } catch (error) {
        alert('could not initialize payment portal', error)
        this.storingspinner = false

      }
    },

    removeImage () {
      console.log('attempting to remove image')
      console.log(this.$refs.vueavatar)
      this.$refs.vueavatar.resetImage()
      console.log(this.$refs.vueavatar)

    },

    async initializeComponent(event) {
      this.$nextTick(() => {
        if (this.$refs.pictureBubble && event === true) {
          this.$refs.pictureBubble.removeEventListener('wheel', this.handleZoom)
          this.$refs.pictureBubble.addEventListener('wheel', this.handleZoom)
        }
      })
      if (event === false) {
        await this.saveImage()
      }
    },
    async updateUserName (name) {
      const updateUser = {
          user_id: this.myUser.uid,
          user_name: name
        }
        await this.$store.dispatch('user_update', updateUser)
    },
    handleZoom(event) {
      if (event.deltaY < 0) {
        this.scale += 0.1
      } else {
        this.scale -= 0.1
      }
      this.scale = Math.min(Math.max(this.scale, 0.5), 3)
    },

    getUser() {
      const user = this.$store.state.currentSpotterfishUser
      if (user) {
        return {
          email: user.email,
          user_name: user.user_name,
          pict: user.pict,
          uid: user.user_id
        }
      } else {
        return {
          email: '',
          user_name: '',
          pict: '',
          uid: ''
        }
      }
    },

    async sendVerificationEmail () {
      this.verificationSpinner = true
      try {
        await CloudClient.call_CFsendVerificationEmail(App.Firebase)
      } catch (error) {
        await this.$root.$globalAlert.open('Error', {
          line1: `Could not send verification email (${error.message})`
        },
        { color: this.colors.mainAccent })
      }
      this.verificationSpinner = false
    },

    onImageReady: function onImageReady () {
      this.scale = 1
    },

    hasValidEmail(value){
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    },
    hasDisplayName(value){
      return value && value.length > 0
    },

    updateUser(){
      this.storingspinner = true
      this.$store.dispatch('user_update', {user_id: this.myUser.uid, user_name: this.myUser.user_name}).then(() => {
        this.storingspinner = false
      })
    },

    async saveImage () {

      if (this.$refs.vueavatar.imageLoaded) {
        this.storingspinner = true
        
        const img = this.$refs.vueavatar.getImageScaled()
  
        img.toBlob(async (blob) => {
          try {
            const resp = await this.$store.dispatch('user_upload_avatar', {
              fileName: this.myUser.uid,
              file: blob
            })
            const updateUser = {
              user_id: this.myUser.uid,
              pict: resp.publicURL
            }
            await this.$store.dispatch('user_update', updateUser)
          } catch (error) {
            console.error('Failed to save image', error)
          } finally {
            this.storingspinner = false
          }
        }, 'image/jpeg')
      } else {
        // if image was reset, remove it from database
        const updateUser = {
          user_id: this.myUser.uid,
          pict: ''
        }
        await this.$store.dispatch('user_update', updateUser)
      }
    },

    async updatePassword(){
      await this.savePassword(this.password, this.oldPassword)
    },
    async savePassword (newPassword, oldPassword) {
      firebase.auth().signInWithEmailAndPassword(App.spotterfishSession.userSession.firebaseCurrentUser.email, oldPassword).then(async () => {
        App.spotterfishSession.userSession.firebaseCurrentUser.updatePassword(newPassword).then(async () => {
          await this.$root.$globalAlert.open('Password updated', {
            line1: `Your password was successfully changed`,
            },
          { color: this.colors.mainAccent })

          this.password = ''
          this.oldPassword = ''
          this.passwordInput = false

        }).catch(async (error) => {
          await this.$root.$globalAlert.open('Error', {
            line1: `We were unable to update you password, please log out and send use the password reset function instead.`,
            },
            { color: this.colors.mainAccent }
          )
          console.log('error: ', error)
        })
      })
        .catch(async (error) => {
          console.log(error)
          await this.$root.$globalAlert.open('Wrong password', {
            line1: `Please try again, or log out to use the password reset function in the login window.`,
            },
            { color: this.colors.mainAccent }
          )
        })
    },
  }
}
</script>
<style>
#avatarEditorCanvas {
  display: block;
  margin: 20px auto;
}
</style>

<style lang="scss">
.sf-user-settings{
  .heading{
    background-color: #000;
    color: #fff;
    padding: .5rem 0;
    h3{
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .settings{
    background-color: #202020;
    padding: 2rem 6rem;
  }
  .profile-picture{
    display: flex;
    flex-direction: column;
    align-items: center;
    .picture-bubble{
      overflow: hidden;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      canvas#avatarEditorCanvas {
        margin-left: -25px;
        margin-top: -25px;
      }
    }
  }
  .input-row{
    display: flex;
    .item{
      width: 50%;
      &:first-child{
        margin-right:0.5rem;
      }
      &:nth-child(2){
        margin-left:0.5rem;
      }
    }
    .button-with-label {
      text-align: left;
      label{
        display: block;
        text-transform: uppercase;
      }
      button{
        width: 100%;
      }
      width: 50%;
    }
  }
  .action-buttons{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    button {
      width: 7rem;
    }
    button:first-child{
      margin-right: 0.5rem;
    }
    button:nth-child(2){
      margin-left: 0.5rem;
    }
  }
  button {
    height: 56px !important;
  }
  h4{
    text-transform: uppercase;
    font-weight: normal;
    padding-bottom: 1rem;
  }
}

.change-password{
  padding: 1rem;
  background-color: #202020;
  h4{
    text-transform: uppercase;
    font-weight: normal;
    padding-bottom: 1rem;
  }
}
</style>
