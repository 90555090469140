// helper.js

async function checkAllowedInputs() {
  const deviceList = await navigator.mediaDevices.enumerateDevices()
  const video = deviceList.some((dev) => dev.kind === 'videoinput' && dev.deviceId !== '')
  const audio = deviceList.some((dev) => dev.kind === 'audioinput' && dev.deviceId !== '')

  return { video, audio }
}

function checkAndResetLocalStorage(devices) {
  const audioInputId = localStorage.getItem('spotterfishUserAudioIn')
  const videoInputId = localStorage.getItem('spotterfishUserVideoIn')
  const audioOutputId = localStorage.getItem('spotterfishUserAudioOut')

  const audioInputExists = devices.some(device => device.kind === 'audioinput' && device.deviceId === audioInputId)
  const videoInputExists = devices.some(device => device.kind === 'videoinput' && device.deviceId === videoInputId)
  const audioOutputExists = devices.some(device => device.kind === 'audiooutput' && device.deviceId === audioOutputId)

  if (audioInputId && !audioInputExists) {
    localStorage.removeItem('spotterfishUserAudioIn')
    console.log('Audio input device ID not found, resetting local storage for audio input.')
  }
  if (videoInputId && !videoInputExists) {
    localStorage.removeItem('spotterfishUserVideoIn')
    console.log('Video input device ID not found, resetting local storage for video input.')
  }
  if (audioOutputId && !audioOutputExists) {
    localStorage.removeItem('spotterfishUserAudioOut')
    console.log('Audio output device ID not found, resetting local storage for audio output.')
  }
}

async function requestPermissionsAndCheckInputs() {
  const deviceList = await navigator.mediaDevices.enumerateDevices()
  const hasVideoInput = deviceList.some(dev => dev.kind === 'videoinput' && dev.deviceId !== '')
  const hasAudioInput = deviceList.some(dev => dev.kind === 'audioinput' && dev.deviceId !== '')

  let permissionsGranted = { audio: false, video: false }

  if (hasAudioInput) {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
      audioStream.getTracks().forEach(track => track.stop())
      permissionsGranted.audio = true
    } catch (audioError) {
      console.error('Audio permission denied:', audioError)
    }
  }

  if (hasVideoInput) {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({ video: true })
      videoStream.getTracks().forEach(track => track.stop())
      permissionsGranted.video = true
    } catch (videoError) {
      console.error('Video permission denied:', videoError)
    }
  }

  return { permissionsGranted, hasVideoInput, hasAudioInput }
}

function createPermissionsMessage(permissionsGranted, hasVideoInput, hasAudioInput) {
  let message

  if (!hasAudioInput || !hasVideoInput) {
    if (!hasVideoInput && !hasAudioInput) {
      message = 'No camera and microphone present.'
    } else if (!hasAudioInput) {
      message = 'No microphone present.'
    } else if (!hasVideoInput) {
      message = 'No camera present.'
    }
  } else if (!permissionsGranted.audio || !permissionsGranted.video) {
    message = 'Your browser has denied access to '
    if (!permissionsGranted.audio && !permissionsGranted.video) {
      message += 'your camera and microphone.'
    } else if (!permissionsGranted.audio) {
      message += 'your microphone.'
    } else if (!permissionsGranted.video) {
      message += 'your camera.'
    }
  }
  return message
}

function mockNoDevices(noVideo = false, noAudio = false) {
  // @ts-ignore
  navigator.mediaDevices.enumerateDevices = async () => {
    const devices = []
    if (!noAudio) {
      devices.push({ kind: 'audioinput', deviceId: 'audio-device-id', label: 'Mock Microphone' })
      devices.push({ kind: 'audiooutput', deviceId: 'audio-device-id', label: 'Mock Speakers' })
    }
    if (!noVideo) {
      devices.push({ kind: 'videoinput', deviceId: 'video-device-id', label: 'Mock Camera' })
    }
    return devices
  }

  navigator.mediaDevices.getUserMedia = async (constraints) => {
    // @ts-ignore
    if (constraints.audio && noAudio) {
      throw new Error('No audio devices found')
    }
    // @ts-ignore
    if (constraints.video && noVideo) {
      throw new Error('No video devices found')
    }
    const tracks = []
    // @ts-ignore
    if (constraints.audio && !noAudio) {
      const audioContext = new AudioContext()
      const oscillator = audioContext.createOscillator()
      const destination = audioContext.createMediaStreamDestination()
      oscillator.connect(destination)
      oscillator.start()
      tracks.push(destination.stream.getAudioTracks()[0])
    }
    // @ts-ignore
    if (constraints.video && !noVideo) {
      const canvas = document.createElement('canvas')
      canvas.width = 640
      canvas.height = 480
      const stream = canvas.captureStream()
      tracks.push(stream.getVideoTracks()[0])
    }
    return new MediaStream(tracks)
  }
}

function measureLevel(stream, callback) {
  const audioContext = new AudioContext({ latencyHint: 'playback' })
  const analyser = audioContext.createAnalyser()
  const microphone = audioContext.createMediaStreamSource(stream)

  microphone.connect(analyser)
  const doMeasure = () => {
    const binCount = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(binCount)
    // @ts-ignore
    const averageLeft = _.sum(binCount) / binCount.length
    callback(Math.round(averageLeft))
    requestAnimationFrame(doMeasure)
  }
  doMeasure()
}

function gotDevices(deviceInfos, audioInputArray, videoInputArray, audioOutputArray) {
  audioInputArray.length = 0
  videoInputArray.length = 0
  audioOutputArray.length = 0

  deviceInfos.forEach(deviceInfo => {
    const option = { value: deviceInfo.deviceId, text: deviceInfo.label || 'unknown' }
    if (deviceInfo.kind === 'audioinput') {
      audioInputArray.push(option)
    } else if (deviceInfo.kind === 'audiooutput') {
      audioOutputArray.push(option)
    } else if (deviceInfo.kind === 'videoinput') {
      videoInputArray.push(option)
    }
  })
}


function getSecondOccurrenceIndex(str, pat, n = 2) {
  if (!str) return -1
  let i = -1
  while (n-- && i++ < str.length) {
    i = str.indexOf(pat, i)
    if (i < 0) break
  }
  return i
}

function attachSinkId(element, sinkId, audioOutputArray, selectedAudioOutput, callback) {
  if (element && typeof element.sinkId !== 'undefined') {
    element.setSinkId(sinkId)
      .then(() => {
        callback(true)
        localStorage.setItem('spotterfishUserAudioOut', sinkId)
      })
      .catch((error) => {
        callback(false)
        console.error(error)
        if (audioOutputArray.length) {
          selectedAudioOutput.value = audioOutputArray[0]?.value
          localStorage.removeItem('spotterfishUserAudioOut')
        }
      })
  } else {
    console.warn('Browser does not support output device selection.')
  }
}

function handleError(err, allowedInputs, startInputs, handleGlobalError, globalAlert) {
  handleGlobalError(err)

  if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
    globalAlert('Could not access webcam and/or mic', {
      line1: 'Open your system preferences and ensure that your browser has permission to access webcam and microphone.'
    })
  } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
    alert(
      'Webcam and/or microphone is blocked by your OS, please check your system preferences to ensure Chrome has access to your webcam and microphone. Please check our troubleshooting guide for more information.'
    )
  } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
    const userAudioIn = localStorage.getItem('spotterfishUserAudioIn')
    const userVideoIn = localStorage.getItem('spotterfishUserVideoIn')
    const userAudioOut = localStorage.getItem('spotterfishUserAudioOut')

    if (userAudioIn || userVideoIn || userAudioOut) {
      localStorage.removeItem('spotterfishUserAudioIn')
      localStorage.removeItem('spotterfishUserVideoIn')
      localStorage.removeItem('spotterfishUserAudioOut')
    } else {
      alert('Codec not supported')
    }
  } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
    const { video, audio } = allowedInputs
    startInputs({ video, audio }, false)

    if (!video || !audio) {
      const errorMessage = `No ${!video ? 'video' : ''} ${!audio ? 'audio' : ''}`.trim()
      alert(errorMessage)
      console.error(errorMessage)
      throw new Error(errorMessage)
    } else {
      alert('Permission denied for media devices. Please allow access to your camera and microphone.')
    }
  } else if (err.name === 'TypeError') {
    alert('Media constraints are not valid. Please check your settings.')
  } else {
    alert(`An unknown error occurred: ${err.message}`)
  }
}

module.exports = {
  checkAllowedInputs,
  checkAndResetLocalStorage,
  requestPermissionsAndCheckInputs,
  createPermissionsMessage,
  mockNoDevices,
  measureLevel,
  gotDevices,
  getSecondOccurrenceIndex,
  attachSinkId,
  handleError
}
