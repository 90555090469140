// @ts-nocheck

function getPayloadFromSdp (sdp, payloadType) {
  // Utility function to get the Opus or Opus RED payload (negotiated by the session) 
  // We need it to tell Chrome what to expect when we set output to stereo / surround
  function getCodecPayloadType (sdpLine) {
    const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+')
    const result = sdpLine.match(pattern)
    return (result && result.length === 2) ? result[1] : null
  }
  
  function findLine (sdpLines, prefix, substr) {
    return findLineInRange(sdpLines, 0, -1, prefix, substr)
  }
  
  function findLineInRange (sdpLines, startLine, endLine, prefix, substr) {
    const realEndLine = endLine !== -1 ? endLine : sdpLines.length
    for (let i = startLine; i < realEndLine; ++i) {
      if (sdpLines[i].indexOf(prefix) === 0) {
        if (!substr ||
          sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
          return i
        }
      }
    }
    return null
  }

  const sdpLines = sdp.split('\r\n')
  const index = payloadType === 'red' ? findLine(sdpLines, 'a=rtpmap', 'red/48000/2') : findLine(sdpLines, 'a=rtpmap', 'opus/48000/2')
  let payload
  if (index) {
    payload = getCodecPayloadType(sdpLines[index])
  } else {
    payload = payloadType === 'red' ? 63 : 97
  }
  return payload
}
  

module.exports  = {

    getPayloadFromSdp

}
