import Vue from 'vue'
import Vuex from 'vuex'
import actions from '@/store/actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    // ************************************************
    // **************** STATIC VALUES *****************
    // ************************************************
    SUBSCRIPTION_TYPES: [
      'Yearly ($10/mo)',
      'Monthly ($12/mo)'
    ],
    
    dashboardTab: undefined,
    dashboardProjectSearch: '',
    dashboardView: 'grid',

    // ************************************************
    // *********** REALTIME LISTENERS *****************
    // ************************************************

    usersInLobby: undefined,

    boundActiveVersion: function () { return 0 },

    // ****** REALTIME FUNCION ********
    realtimeUserListener: function () { return 0 },
    // *********** RESULT *************
    currentSpotterfishUser: undefined,

    // ****** REALTIME FUNCION ********
    realtimeProjectsForUserListener: function () { return 0 },
    // *********** RESULT *************
    projectsArray: [],

    // ****** REALTIME FUNCION ********
    onlineStatusRealtimeListener: function () { return 0 },
    // *********** RESULT *************
    roomsWhereUsersAreOnline: [],

    activeStreamersListener: function () { return 0 },

    userScreeningRoomsListener: function () { return 0 },

    // ************************************************
    // ******************* UI ETC *********************
    // ************************************************

    saved: false,

    // ************************************************
    // ******************* UI ETC *********************
    // ************************************************

    connectedUsers: [],

    markerLane: undefined,

    selectedMarker: undefined,

    selectedCommentType: '',

    // Imperative values. Our Spotterfish views will not load unless they are present:
    loggedIn: false,
    currentLoggedInUser: undefined,
    userPermissions: undefined,
    currentVersion: undefined,

    videoPlayerVolume: 1.0,
    // Initial Values, could be kept like this

    screenshotCaptures: '',

    modals: {
      videoAutoPlayFix: false
    }
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::GETTERS:::::::::::::::::::::::::::::::::::
  // :::::::::::::::State may be retreived directly in vuex,::::::::::::::::::::
  // ::::::::::::::::::but it seems cleaner to use getters::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  getters: {
    getUsersInLobby: state => {
      return state.usersInLobby
    },

    getConnectedUsers: state => {
      return state.connectedUsers
    },
    getMarkerLane: state => {
      return state.markerLane
    },

    getCurrentLoggedInUser: state => {
      return state.currentLoggedInUser
    },

    getVideoPlayerVolume: state => {
      return state.videoPlayerVolume
    },

    getScreenshotCaptures: state => {
      return state.screenshotCaptures
    },
  },

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::SETTERS:::::::::::::::::::::::::::::::::::
  // :::::::::::::::Mutations are Always synchronous in Vuex::::::::::::::::::::
  // :::::::::::::::::::::for Async, use 'Actions' below::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  mutations: {
    setUsersInLobby (state, payload) {
      state.usersInLobby = payload
    },
    setConnectedUsers (state, payload) {
      state.connectedUsers = payload
    },

    setLoggedInState (state, payload) {
      state.loggedIn = payload
    },
    setCurrentLoggedInUser (state, payload) {
      state.currentLoggedInUser = payload
    },
    setUserPermissions (state, payload) {
      state.userPermissions = payload
    },

    setSelectedCommentType (state, payload) {
      state.selectedCommentType = payload
    },

    setVideoPlayerVolume (state, volume) {
      state.videoPlayerVolume = volume
    },
  },
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::ACTIONS:::::::::::::::::::::::::::::::::::::
  // ::::::::::::Actions may be asynchrounus or synchronous in Vuex:::::::::::::
  // :but for Synchronous tasks we are often better off using 'Mutations' above:
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //
  actions: actions
})
