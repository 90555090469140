<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" lg="8" style="margin: auto;">
        <h2 class="mt-10">You do not have access to this part of MixStage™</h2>
        <h3 class="mb-10">Purchase your subscription below</h3>
        <div class="dark-panel pt-10 pb-10 pl-10 pr-10" style="min-height: 600px; display: flex; align-items: center; justify-content: center; border-radius: 6px;">
          <stripe-pricing-table
              :pricing-table-id="getPricingtable()"
              :publishable-key="getStripeKey()"
              >
            </stripe-pricing-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import App from '@/App.vue'
import paymentForm from '@/components/payment/paymentForm.vue'
import StripeUtils from '@/../source_files/spotterfish_library/utils/StripeUtils'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'accessDenied',
  components: {
    paymentForm,
  },
  props: [
  ],
  data () {
    return {
      scriptLoaded: false,
      scriptElement: undefined,
      accountTier: undefined,
      isDAWstreamer: undefined,
    }
  },
  methods: {

    async recalculateFeatureBits () {
      this.accountTier = await CloudClient.call_CFgetAccountTier(App.Firebase)
      this.isDAWstreamer = App.spotterfishSession.userSession.ufb.user?.daw_streaming
    },

    getPricingtable() {
      return StripeUtils.getStripePricingTableId(this.accountTier, this.isDAWstreamer)
    },

    getStripeKey() {
      return StripeUtils.getStripeKey()
    },

    getAccountName () {
      return StripeUtils.getAccountName(this.accountTier, this.isDAWstreamer)
    },

    loadStripeScript() {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://js.stripe.com/v3/pricing-table.js'
      script.onload = () => {
        this.scriptLoaded = true
      }
      script.onerror = () => {
        console.error('Failed to load Stripe script')
      }
      document.body.appendChild(script)
      // @ts-ignore
      this.scriptElement = script // Save the reference
    },

    unloadStripeScript() {
      if (this.scriptElement) {
        // @ts-ignore
        this.scriptElement.remove() // Remove the script from the DOM
      }
    },
  },
  async mounted () {
    // @ts-ignore
    App.spotterfishSession.userSession.addSessionListener('user_business_data', this.recalculateFeatureBits)
    await this.recalculateFeatureBits()
    this.loadStripeScript()
  },
  beforeDestroy(){
    if (this.scriptLoaded) { this.unloadStripeScript() }
  }
}

</script>
