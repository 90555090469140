<template>
  <div>
    <v-container class="wrapper">
      <!-- HEADER -->
      <v-toolbar dark style="max-height: 44px;">
          <v-btn
            text
            dark
            
            class="mb-5"
            @click="$emit('request-copy-short-link')"
            :style="{ color: colors.mx.white, 'font-size': '0.8em' }"
          >
          <v-icon style="margin-right: 0.5rem; font-size: 1em;">share</v-icon>           
           Copy
            {{ isAudienceRoom ? 'Quick' : 'Invite' }} link</v-btn
          >
          <v-spacer></v-spacer>

          <!-- <v-btn
            text
            dark
            class="mb-5"
            @click="addMultipleUsers"
            >
          <v-icon style="margin-right: 0.5rem; font-size: 1em;">group_add</v-icon>           
           Add muliple users, comma separated
          </v-btn> -->
          <v-text-field 
            solo 
            dense 
            small 
            class="mt-1"
            style="margin-right: 0.5rem; width: 8em; font-size: 0.8em;"
            v-model="inviteListText" 
            placeholder="Add muliple users. Comma sepated" 
            @click:append="addMultipleUsers()">
            <template v-slot:append>
                <v-icon 
                  @click="addMultipleUsers"
                  :color="colors.mx.white"
                  style="outline: none !important;"
                >
                  group_add
                </v-icon>
            </template>
          </v-text-field>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

        <v-btn fab dark small style="position: absolute; right: 4px; top: 0; display: flex; align-items: center; justify-content: center;" 
          @click="$emit(
            'close-hosting-clicked'),
            (activeChair = undefined),
            (searching = false)"
        >
          <v-icon class="mt-1">close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <!-- USER REQUESTING ACCESS -->
      <v-row justify="center">
        <v-col>
          <v-card
            :color="colors.mx.darkPanel"
            v-if="userQueue.length > 0"
          >
            <h4>Requesting access:</h4>
            <v-simple-table dark dense fixed-header style="margin: 10px; padding: 4px; border: 1px solid;" :style="{'border-color': colors.mx.secondary}">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="user in userQueue"
                    :key="'requesting' + user.user_id"
                  >
                    <td class="text-left td-username">{{ user.user_name }}</td>
                    <td class="text-left">
                      <v-tooltip top :color="colors.tooltipBackground">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-if="!user.emailVerified"
                            v-on="on"
                            class="verified-badge"
                            color="red"
                            small
                          >
                            mdi-alert-decagram
                          </v-icon>
                        </template>
                        <span>User email is not verified</span>
                      </v-tooltip>
                      <v-tooltip top :color="colors.tooltipBackground">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-if="user.emailVerified"
                            v-on="on"
                            class="verified-badge"
                            color="green"
                            small
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>User email is verified</span>
                      </v-tooltip>
                      {{ user.email }}
                    </td>

                    <td class="text-right">
                      <v-btn small @click="removeUserFromLobby(user)">
                        Reject user
                      </v-btn>
                    </td>

                    <td class="text-left">
                      <v-btn
                        :disabled="!findFirstEmptyChair"
                        dark
                        small
                        :color="colors.mx.secondary"
                        @click="
                          onAddRequestingAccesUserToSeat(
                            user,
                            findFirstEmptyChair - 1,
                            false
                          )
                        "
                      >
                        Allow User
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <!-- PEOPLE LIST -->
          <v-card style="padding: 0.875rem">
            <v-simple-table dark dense fixed-header class="people-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left text-subtitle-2">
                      <v-icon v-if="!allSelected" small class="th-icons" @click="toggleSelectAll()">check_box_outline_blank</v-icon>
                      <v-icon v-if="allSelected" small class="th-icons" @click="toggleSelectAll()">check_box</v-icon>
                    </th>
                    <th class="text-left text-subtitle-2">
                      <v-icon small class="th-icons">mdi-seat</v-icon>Seat
                    </th>
                    <th class="text-left text-subtitle-2">
                      <v-icon small class="th-icons">mdi-account</v-icon>
                      User
                    </th>
                    <th class="text-left text-subtitle-2">
                      <v-icon small class="th-icons">mdi-email</v-icon> Email
                    </th>
                    <!-- <th class="text-left text-subtitle-2">
                      <v-icon small class="th-icons">mdi-shape</v-icon> Role
                    </th> -->
                    <th class="text-left text-subtitle-2">
                      <v-icon small class="th-icons">mdi-crown</v-icon> Status
                    </th>
                    <th class="text-left text-subtitle-2">
                      <v-tooltip top :color="colors.tooltipBackground">
                        <template v-slot:activator="{ on }">
                          <div v-on="on" style="display: flex; align-items: center; justify-content: space-around;">
                            <v-icon small class="th-icons">mdi-send</v-icon>Invitation
                            <v-switch  :value="inviteImmediately" @change="onToggleInviteImmediately" class="mt-4" :color="colors.mx.white" style="transform: scale(0.8); margin: 4px 0;"></v-switch>
                          </div>
                        </template>
                        <span>{{ getInviteTooltip() }}</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left text-subtitle-2"></th>
                  </tr>
                </thead>
                <tbody
                  v-for="(seat, index) in UI_screeningRoomDBCopy.seats"
                  :key="'seated' + seat"
                >
                  <tr
                    v-if="activeChair !== index"
                    class="no-select-chair"
                    @click.stop="onChairClicked(index)"
                  >
                    <td class="text-left" style="width: 1em">
                      <v-icon small v-if="!isSelected(index) && !isOwner(index) && isOccupied(index)" @click="toggleSelectRow(index)">check_box_outline_blank</v-icon>
                      <v-icon small v-if="isSelected(index) && !isOwner(index) && isOccupied(index)" @click="toggleSelectRow(index)">check_box</v-icon>
                    </td>
                    <td class="text-left" style="width: 1em">
                      {{ index + 1 }}
                    </td>

                    <td
                      v-if="seatPending(index)"
                      class="text-left pending td-username"
                    >
                      Invitation Pending
                    </td>
                    <td
                      v-else-if="!seatedUsers[index]"
                      class="text-left pending td-username"
                    ></td>
                    <td v-else class="text-left td-username">
                      {{ seatedUsers[index]['user_name'] }}
                    </td>

                    <td
                      v-if="seatPending(index)"
                      class="text-left pending td-email"
                    >
                      {{ seatedUsers[index]['user_id'] }}
                    </td>
                    <td
                      v-else-if="!seatedUsers[index]"
                      class="text-left pending td-email"
                    ></td>
                    <td
                      v-else-if="userAtSeatIsAnonymous(index)"
                      class="text-left td-email"
                    ></td>
                    <td v-else class="text-left td-email">
                      <v-tooltip top :color="colors.tooltipBackground">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="
                              !hasVerifiedEmail(seatedUsers[index])
                            "
                            v-on="on"
                            class="verified-badge"
                            color="red"
                            small
                          >
                            mdi-alert-decagram
                          </v-icon>
                        </template>
                        <span>User email is not verified</span>
                      </v-tooltip>
                      <v-tooltip top :color="colors.tooltipBackground">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="
                              seatedUsers[index]['user_id'] &&
                              hasVerifiedEmail(seatedUsers[index])
                            "
                            v-on="on"
                            class="verified-badge"
                            color="green"
                            small
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>User email is verified</span>
                      </v-tooltip>
                      {{ seatedUsers[index]['email'] }}
                    </td>


                    <!-- ROLES -->
                    <!-- <td v-if="userAtSeatIsAnonymous(index)"></td>

                    <td v-if="!userAtSeatIsAnonymous(index)" class="text-left">
                      <v-select
                        dense
                        :items="roles"
                        item-text="name"
                        outlined
                        item-value="name"
                        :value="seatedUsers[index]['user_id'].role"
                        label="Session Role"
                        hint="Select a session role"
                        @input="setUserRole(user.id, $event)"
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <span :style="{ color: item.color }">{{ item.name }}</span>
                          </div>
                        </template>
                      </v-select>
                    </td> -->

                    <!-- STATUS -->
                    <td v-if="userAtSeatIsAnonymous(index)"></td>
                    <td v-if="!userAtSeatIsAnonymous(index)" class="text-left">
                      <v-btn
                        width="100"
                        small
                        size="1.5em"
                        v-show="
                          UI_screeningRoomDBCopy.seatings[index] &&
                          isModerator(UI_screeningRoomDBCopy.seatings[index])
                        "
                        :color="colors.mx.panel"
                        @click.prevent="
                          makeUserNonModerator(seatedUsers[index], UI_screeningRoomDBCopy)
                        "
                        >
                        <div class="hosting-button">
                          <div>Moderator</div>
                        
                          <v-icon
                            size="1.5em"
                            color="#FFD700"
                            style="margin-left: 0.375rem"
                            >mdi-crown</v-icon
                          >
                        </div>
                      </v-btn>
                      <v-btn
                        width="100"
                        size="1.5em"
                        small
                        :style="{ background: colors.mx.panel }"
                        v-show="
                          UI_screeningRoomDBCopy.seatings[index] &&
                          !isModerator(UI_screeningRoomDBCopy.seatings[index])
                        "
                        @click.prevent="makeUserModerator(seatedUsers[index])"
                        >
                        <div class="hosting-button">
                          <div>Participant</div>
                          <v-icon
                            size="1.5em"
                            :color="colors.mx.lightPanel"
                            style="margin-left: 0.375rem"
                            >mdi-crown</v-icon
                          >
                        </div>
                      </v-btn>
                    </td>
                    
                    <td v-if="userAtSeatIsAnonymous(index)"></td>
                    <td v-if="!userAtSeatIsAnonymous(index)" class="text-left">
                      <v-btn
                        width="120"
                        small
                        v-if="seatedUsers[index] && !isOwner(index)"
                        :style="{ background: colors.mx.panel }"
                        @click="inviteUser(seatedUsers[index])"
                      >
                      <div class="hosting-button">
                        <div>Send reminder</div>
                        <v-icon
                            size="1.2em"
                            :color="colors.mx.lightPanel"
                            style="margin-left: 0.375rem"
                            >mdi-send</v-icon
                          >
                      </div>
                      </v-btn>
                      <v-btn
                        width="120"
                        small
                        v-if="!seatedUsers[index] && !isOwner(index)"
                        :style="{ background: colors.mx.secondary }"
                        @click=";(searching = true), (activeChair = index)"
                      >
                      <div class="hosting-button">
                        <div>Add user</div>
                      </div>
                      </v-btn>
                      <v-btn
                        width="120"
                        small
                        v-if="isOwner(index)"
                        :style="{ background: colors.mx.secondary }"
                        @click="remindSelected()"
                      >
                      <div class="hosting-button">
                        <div>Remind {{ selected.length ? 'selected' : 'all' }}</div>
                      </div>
                      </v-btn>
                    </td>

                    <td class="text-left" style="width: 1em">
                      <v-tooltip :index="index" bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn 
                            v-on="on"
                            @mouseover="setTooltipForDeleteForever(index)"
                            @mouseleave="clearTooltip"
                            icon 
                            small 
                            :color="colors.mx.panel" 
                            v-show="!isOwner(index)"
                          >
                            <v-icon
                              size="1.4em"
                              :color="colors.mx.white"
                              v-if="(isOccupied(index) && !isOwner(index)) || seatPending(index)"
                              @click="removeUserFromSeat(seatedUsers[index], index)"
                            >
                              delete_forever
                            </v-icon>
                          </v-btn>

                          <v-btn 
                            v-on="on"
                            @mouseover="setTooltipForDeleteSweep"
                            @mouseleave="clearTooltip"
                            icon 
                            small 
                            style="padding-left: 2px;" 
                            v-show="isOwner(index)"
                          >
                            <v-icon
                              size="1.6em"
                              :color="colors.mx.secondary"
                              @click="removeAllUsers()"
                            >
                              delete_sweep
                            </v-icon>
                          </v-btn>

                          <v-btn 
                            v-on="on"
                            @mouseover="setTooltipForUndo"
                            @mouseleave="clearTooltip"
                            icon 
                            small 
                            style="padding-left: 2px;" 
                            v-show="isOwner(index) && hasStoredRemovedUserIds()"
                          >
                            <v-icon
                              size="1.6em"
                              :color="colors.mx.secondary"
                              @click="undoRemoveUserFromSeat()"
                            >
                              undo
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>{{ currentTooltip }}</span>
                      </v-tooltip>

                    </td>
                  </tr>
                  <!-- SEARCH BAR -->
                  <!-- SB - CHAIR NUMBER-->
                  <tr
                    v-if="searching && activeChair == index"
                    style="background: gray"
                  >
                    <td style="text-align: left" colspan="1">
                      {{ activeChair + 1 }}
                    </td>

                    <!-- SB - SEARCH FIELD -->
                    <td colspan="3">
                      <v-text-field
                        autofocus
                        clearable
                        label="Type email here"
                        v-model="searchUserText"
                        @keyup="searchUser"
                        color="white"
                      >
                      </v-text-field>
                    </td>
                    <td colspan="1"></td>
                    <!-- SB - BUTTONS -->
                    <td colspan="1" class="text-left">
                      <p style="margin: 0">
                        <v-btn
                          class="hosting-button"
                          :color="colors.mx.secondary"
                          :disabled="!isValidEmail(searchUserText)"
                          width="120"
                          small
                          :style="{
                            color: colors.mx.white,
                          }"
                          @click="
                            addUserToSeat(
                              searchUserText,
                              activeChair
                            ).then(() => {})
                          "
                          >Add to seat</v-btn
                        >
                      </p>
                    </td>
                    <td colspan="1"></td>
                  </tr>
                </tbody>
                <tfoot style="width: 100%; height: 0.2rem"></tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-overlay v-model="spinner" width="100" rounded persistent overlay-color="#080808" overlay-opacity="0.63">
        <div class="spinner-wrapper">
          <mixstage-spinner
            :showSpinner="true"
            :loadingMessage="'Updating room configuration'"
            style="background-color: transparent; width: 300px; height: 100px; padding: 0 0 10% 13%;">
          </mixstage-spinner>
        </div>
      </v-overlay>

      <v-dialog v-model="invitePanel.open" width="400" height="300">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <p>An invitation was email sent to:</p>
            <p>{{ invitePanel.email }}</p>
            <v-btn small @click="invitePanel.open = false">Close</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="inviteErrorPanel.open" width="400" height="300">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <p>Could not send invitation email:</p>
            <p>{{ inviteErrorPanel.email }}</p>
            <p>Copy the invitation link instead</p>
            <v-btn small @click="inviteErrorPanel.open = false">Close</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="userAlreadySeated" max-width="434">
        <v-card>
          <v-card-title style="justify-content: center"
            >This user is already added</v-card-title
          >
          <v-card-text
            >Each user may only occupy one chair<br /><br />
            <v-btn small :color="colors.mx.secondary" @click="userAlreadySeated = false"
              >Close</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="removingMyself" max-width="434">
        <v-card>
          <v-card-title style="justify-content: center"
            >You are removing yourself from this screening room</v-card-title
          >
          <v-card-text
            >You will not be able to participate in any video chat until you add
            yourself via invite<br /><br />
            <v-btn small :color="colors.mx.secondary" @click="removingMyself = false"
              >Close</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
// @ts-nocheck

import * as Sentry from '@sentry/browser'
import App from '@/App.vue'
import store from '@/store'
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue'

const colors = require('@/lib/ui/colors.js').colors
const logos = require('@/lib/ui/logos.js').logos


import CloudClient from '@/../source_files/web_client/CloudClient'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import _ from 'lodash'
import Constants from '@/../source_files/spotterfish_library/consts'
import spinner from '@/components/dashboard/mixStageSpinner.vue'

function extractEmails(inputString) {
    // Regular expression to match email addresses (both enclosed in angle brackets and plain)
    const emailRegex = /<([^>]+)>|([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/g;
    const matches = [...inputString.matchAll(emailRegex)];
    return matches.map(match => match[1] || match[2]);
}

function checkEmails(inputArray) {
  for (let i = 0; i < inputArray.length; i++) {
    const testEmail = inputArray[i]
    if (Constants.EMAIL_REGEX.test(testEmail)) {
        console.log("Email matches the regex");
    } else {
        throw new Error(`Email ${testEmail} is not properly formatted`)
    }
  }
} 

function sanitizeUserList(users, selected, owner, userId) {
  if (!users) return
  const onlySelected = selected ? selected.length > 0 : false

  const userIds = onlySelected 
    ? selected
        .map(index => users[index])
        .filter(user => user !== false)
        .map(user => user.user_id)
    : users
        .filter(user => user !== false)
        .map(user => user.user_id)     
  // Remove the owner from the list if present
  const ownerIndex = userIds.indexOf(owner)
  if (ownerIndex !== -1) {
      userIds.splice(ownerIndex, 1)
  }

  // Remove the current user from the list if present
  const userIndex = userIds.indexOf(userId)
  if (userIndex !== -1) {
      userIds.splice(userIndex, 1)
  }
  
  return userIds
}

export default {
  props: {
    usersInLobby: {
      type: Array,
      required: false,
    },
    allowedDomains: {
      type: Array,
      required: false,
    },
    requiresEmailVerification: {
      type: Boolean,
      required: false,
    },
    isAudienceRoom: {
      type: Boolean,
      required: true,
    },
    requestedSelectedChair: {
      type: Number,
      required: false,
    },
  },
  components: {
    'mixstage-spinner': spinner
  },
  setup(props, { emit }) {
    const nonReactiveState = inject('nonReactiveState')
    const templateReactiveData = inject('templateReactiveData')

    const headers = [
      {
        value: 'action',
        sortable: false,
        align: 'left',
      },
    ]
    const invitedUsersHeaders = [
      {
        text: 'REMOVE USER',
        value: 'action',
        sortable: false,
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'user_name',
      },
      {
        text: 'Pict',
        align: 'right',
        sortable: false,
        value: 'pict',
      },
    ]
    const roles = Constants.FIXED_SESSION_ROLES

    const getCurrentUser = () => {
      console.log(store.state.currentSpotterfishUser)
      return store.state.currentSpotterfishUser
    }

    // Reactive state for arrays, objects, and primitives
    const allSelected = ref(false)
    const selected = ref([])
    const inviteListText = ref('')
    const inviteImmediately = ref(false)
    const userQueue = ref([])
    const hovering = ref(false)
    const searching = ref(false)
    const spinner = ref(true)
    const searchUserText = ref('')
    const seatings = ref([])
    const seatedUsers = ref([])
    const searchResults = ref([])
    const activeChair = ref(undefined)
    const verificationCheckInterval = ref(undefined)
    const userAlreadySeated = ref(false)
    const removingMyself = ref(false)
    const invitationLink = ref(false)
    const removedUserIds = ref(undefined)
    const invitePanel = reactive({
      open: false,
      link: '',
      email: '',
    })
    const inviteErrorPanel = reactive({
      open: false,
      email: '',
    })

    const currentTooltip = ref('')

    const getUsersFromUids = async (usersArray) => {
      return new Promise(async (resolve, reject) => {
        const userIds = usersArray.filter(id => id !== false)

        const _connectedUsers = await CloudClient.call_CFgetMultipleUserObjects(App.Firebase, userIds, nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'])

        // Create a lookup map for the connected users using their user_id attribute
        const userLookup = Object.fromEntries(_connectedUsers.map(user => [user.user_id, user]))

        // Map each uid in usersArray to its corresponding user object based on user_id or keep it as uid
        const result = usersArray.map(uid => {
          if (uid === false) {
            return false
          }
          if (userLookup[uid]) {
            return userLookup[uid]
          }
          return uid
        })

        resolve(result)
      })
    }

    const updateUserVerificationStatus = async () => {
      if (seatedUsers.value === undefined || seatedUsers.value?.length === 0) { return }
      try {
        const arr = seatedUsers.value.filter(user => user !== false)
        const verifiedUserIds = await CloudClient.call_CFgetUsersVerified(App.Firebase, arr)

        // Loop through connectedUsers and update the emailVerified flag
        for (const user of seatedUsers.value) {
          if (verifiedUserIds.includes(user.user_id)) {
            user.emailVerified = true
          } else if (user){
            user.emailVerified = false
          }
        }
      } catch (error) {
        await this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not get verified users (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }
    }

    // Computed properties
    const UI_screeningRoomDBCopy = computed(() => {
      return templateReactiveData.value.screeningRoomDBCopy
    })
  
    const moderatorsInScreeningRoom = computed(() => {
      return UI_screeningRoomDBCopy.value?.people_with_moderator_key;
    })

    const findFirstEmptyChair = computed(() => {
      return UI_screeningRoomDBCopy.value?.seatings.indexOf(false) + 1 || false
    })

    // Watchers -- keep to a bare minimum
    watch(() => UI_screeningRoomDBCopy, async (newScreeningRoom) => {

      const newRoomSeatings = newScreeningRoom.value.seatings
      const newRoomRequestingAccess = newScreeningRoom.value.requesting_access

      if (newRoomSeatings ===  seatings.value && newRoomRequestingAccess === userQueue.value){
        console.log('new and old room are equal')
        return
      }
      
      console.log('ScreeningRoom changed:')
      seatings.value = newRoomSeatings
      seatedUsers.value = await getUsersFromUids(newRoomSeatings)
      userQueue.value = await getUsersFromUids(newRoomRequestingAccess) 
      store.commit('setConnectedUsers', seatedUsers.value)

    }, { deep: true })


    // Lifecycle hooks
    onMounted(async () => {

      const room = nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy

      // Caching for quicker updates
      seatings.value = room.seatings
      seatedUsers.value = store.state.connectedUsers
      seatedUsers.value = await getUsersFromUids(room.seatings)
      userQueue.value = await getUsersFromUids(room.requesting_access) 

      store.commit('setConnectedUsers', seatedUsers.value)

      spinner.value = false
      verificationCheckInterval.value = setInterval(() => {
        updateUserVerificationStatus()
      }, 30000)
    })

    onBeforeUnmount(() => {
      if (verificationCheckInterval.value) {
        clearInterval(verificationCheckInterval.value)
      }
    })

    return {
      store,
      colors,
      logos,
      allSelected,
      selected,
      inviteListText,
      inviteImmediately,
      userQueue,
      hovering,
      searching,
      spinner,
      searchUserText,
      seatedUsers,
      searchResults,
      activeChair,
      verificationCheckInterval,
      userAlreadySeated,
      removingMyself,
      invitationLink,
      removedUserIds,
      invitePanel,
      inviteErrorPanel,
      headers,
      invitedUsersHeaders,
      roles,
      currentTooltip,
      getUsersFromUids,
      nonReactiveState,
      templateReactiveData,
      getCurrentUser,
      updateUserVerificationStatus,
      UI_screeningRoomDBCopy,
      moderatorsInScreeningRoom,
      findFirstEmptyChair,
    }
  },
  methods: {
    getInviteTooltip() {
      if (this.inviteImmediately) {
        return `Auto email on:  Emails will be sent immediately after user is added to the room`
      } else {
        return `Auto email off: No emails will be sent automatically to users. You can still use the buttons below to send email invites`
      }
    },
    onToggleInviteImmediately(val) {
      console.log(val)
      this.inviteImmediately = val
    },
    setTooltipForDeleteForever(index) {
      this.currentTooltip = !this.isOwner(index) ? 'Remove user' : 'Remove selected users';
    },
    setTooltipForDeleteSweep() {
      this.currentTooltip = 'Remove all users';
    },
    setTooltipForUndo() {
      this.currentTooltip = `Undo removal of last user${this.removedUserIds.length > 1 ? 's' : ''}`
    },
    clearTooltip() {
      this.currentTooltip = '';
    },
    isOwner(index) {
      return this.seatedUsers[index] && this.seatedUsers[index]['user_id'] 
        ? this.seatedUsers[index].user_id === this.nonReactiveState?.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy.owner
        : false
    },
    isOccupied(index) {
      return this.seatedUsers[index] ? true : false
    },

    isSelected(index) {
      return this.selected.indexOf(index) !== -1
    },
    toggleSelectRow(index) {
      if (this.isSelected(index)) {
        this.selected.splice(this.selected.indexOf(index), 1)
      } else {
        this.selected.push(index)
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.seatedUsers
          .map((u, i) => i)
          .filter(index => !this.isOwner(index))  // filter out "me"
      }
      this.allSelected = !this.allSelected
    },

    onChairClicked(index) {
      if(!this.seatedUsers[index]) { 
        (this.searching = true), (this.activeChair = index)
      }
      else {
        this.searching = false
        this.activeChair = undefined
      }
    },
    setActiveChair(index) {
      this.activeChair = index
      this.searching = !!this.activeChair
    },

    seatPending(index) {
      return (
        this.seatedUsers[index] &&
        !this.seatedUsers[index]['user_name'] &&
        this.seatedUsers[index]['user_id']
      )
    },

    userAtSeatIsAnonymous(index) {
      return (
        this.seatedUsers[index] &&
        !this.seatedUsers[index]['email'] &&
        this.seatedUsers[index]['user_name']
      )
    },

    isModerator(uid) {
      if (!uid) {
        return false
      } else if (this.moderatorsInScreeningRoom.indexOf(uid) === -1) {
        return false
      } else {
        return true
      }
    },

    hasVerifiedEmail(user) {
      if (!user) {
        return false
      } else if (user.emailVerified) {
        return true
      } else {
        return false
      }
    },

    isValidEmail(email) {
      return Constants.EMAIL_REGEX.test(email)
    },

    isAllowedDomain(email) {
      // This is a relatively simple test, a more rigorous test is done by the cloud
      // functions etc. to make sure we don't let anything through.

      if (this.allowedDomains.length === 0) {
        return true
      }

      const emailParts = email.split('@')

      if (emailParts.length !== 2) {
        return false
      }

      const emailDomain = emailParts[1]

      return this.allowedDomains.includes(emailDomain.toLowerCase())
    },

    searchUser() {
      if (this.searchUserText) {
        this.searchUserText = this.searchUserText.toLowerCase()
        store.dispatch('user_query', this.searchUserText).then((res) => {
          this.searchResults = res
        })
      } else {
        this.searchResults = []
      }
    },

    hasStoredRemovedUserIds() {
      return Boolean(this.removedUserIds)
    },

    findInArray(arr, id) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === id) {
          return true
        }
      }
      return false
    },

    async onAddRequestingAccesUserToSeat(user, chairNo, emailFlag) {
      try {
        await this.addUserToSeat(user, chairNo, emailFlag)
        await this.removeUserFromLobby(user)
      } catch (error) {
        console.log(error)
        this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not add user to seat (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }
    },

    async removeUserFromLobby(user) {
      this.spinner = true
      this.userQueue = this.userQueue.filter(u => u.user_id !== user.user_id)
      const userId = user.user_id
      try {
        const resp = await CloudClient.call_CFremoveUserFromScreeningRoomLobby(App.Firebase, this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'], userId)
        console.log(resp)
        this.spinner = false
      } catch (error) {
        this.spinner = false
        Sentry.captureException(error, {
          user: {
            uid: userId,
          },
          extra: {
            room_id: this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'],
          },
        })
      }
    },

    addMultipleUsers() {
      try {
        const sanitizedEmailList = extractEmails(this.inviteListText) || []
        checkEmails(sanitizedEmailList)
        this.inviteListText = undefined
        this.addUserToSeat(sanitizedEmailList, undefined)
      } 
      catch (error) {
        console.log(error)
        this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not add user to seat (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }
    },

    async addUserToSeat(userOrEmailsOrArray, chairIndex, shouldSendEmail) {
      this.spinner = true
      let _shouldSendEmail = shouldSendEmail
      if (shouldSendEmail === undefined) {
        _shouldSendEmail = this.inviteImmediately
      }
      const inputArray = Array.isArray(userOrEmailsOrArray) ? userOrEmailsOrArray : [userOrEmailsOrArray]

      const emails = inputArray.map(item => 
        typeof item === 'object' && item.email ? item.email : item
      )

      const uniqueEmails = [...new Set(emails)];
    
      try {
        await CloudClient.call_CFaddUsersToSeats(
          App.Firebase,
          uniqueEmails,
          chairIndex,
          this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key']
        )

        for (const email of uniqueEmails) {
          if (this.isAllowedDomain(email)) {
            if (_shouldSendEmail) {
              await this.inviteUser(email)
            }
          }
        }
        SpotterfishSession.trackEvent(
          App.spotterfishSession, 
          'invited_to_room', 
          {
            userUID: this.getCurrentUser().user_id,
            invitedEmail: uniqueEmails
          }
        )
      } catch (error) {
        console.log(error)
        this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not add users ${uniqueEmails} to seat (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }

      this.spinner = false
      this.activeChair = undefined
      this.searching = false
      this.searchUserText = ''
    },


    async undoRemoveUserFromSeat() {
      this.spinner = true
      try {
        this.addUserToSeat(this.removedUserIds, undefined, false)
        this.removedUserIds = undefined
      } catch (error) {
        console.log(error)
        this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not undo remove user from seat (${error.message})`,
          },
          { color: this.colors.mx.secondary }
        )
      }
    },


    async removeAllUsers() {
        const onlySelected = this.selected.length > 0
        const conf = await this.$root.$globalConfirm.open(
            `Remove users?`,
            {
                line1: `You are removing ${onlySelected ? 'selected' : 'all'} users from this room. They will not be able to enter the room without being added by a moderator.`,
                line3: 'Press cancel to abort.'
            },
            { color: this.colors.mainAccent }
        )
        if (!conf) {
            return
        }

        this.spinner = true
        const userIds = sanitizeUserList(this.seatedUsers, this.selected, this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy.owner, this.getCurrentUser().user_id)
        this.removedUserIds = userIds

        try {
            await CloudClient.call_CFremoveUserFromSeatAndSharedState(App.Firebase, this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'], userIds)
            this.seatedUsers = []
            this.activeChair = undefined
            this.searching = false
        } catch (error) {
          console.log(error)
            await this.$root.$globalAlert.open(
                'Error',
                {
                    line1: `Could not remove all users from room (${error.message})`,
                },
                { color: this.colors.mx.secondary }
            )
        }
        this.spinner = false
    },

    async removeUserFromSeat(user, chairIndex) {
      this.spinner = true      
      const me = await CloudClient.call_CFgetUserObject(App.Firebase, this.getCurrentUser().user_id)
      if (user.user_id === me.user_id && this.isOwner(chairIndex) === false) {
        let conf = confirm(
          'You are removing yourself from this seat. You will not be able to enter the room without being added by a moderator.'
        )
        if (!conf) {
          this.spinner = false
          return
        }
      }

      try {
        await CloudClient.call_CFremoveUserFromSeatAndSharedState(
          App.Firebase,
          this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'],
          user.user_id
        )
        this.seatedUsers.splice(chairIndex, 1, false)
        this.removedUserIds = user.user_id
        this.activeChair = undefined
        this.searching = false
      } catch (error) {
        console.log(error)
        await this.$root.$globalAlert.open(
          'Error',
          {
            line1: `Could not remove user from seat (${error.message})`,
          },
          { color: this.colors.mx.secondary}
        )
      }

      this.spinner = false
    },

    makeUserModerator(user) {
      const updateObj = {
        screeningRoomID: this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'],
        userID: user.user_id,
      }

      console.log(updateObj)
      store
        .dispatch('screening_room_give_user_moderator_key_hosting', updateObj)
        .then(() => {
          console.log('made Moderator')
        })
    },

    async makeUserNonModerator(user, screeningRoomDBCopy) {
      
      const me = await CloudClient.call_CFgetUserObject(App.Firebase, this.getCurrentUser().user_id)

      if (user.user_id === me.user_id) {
        alert('You can not remove yourself from moderator list')
        return
      }
      if (user.user_id === screeningRoomDBCopy.owner) {
        alert('You can not remove the owner from moderator list')
        return
      }
      const updateObj = {
        screeningRoomID: this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'],
        userID: user.user_id,
      }

      console.log(updateObj)
      store
        .dispatch(
          'screening_room_remove_user_from_moderator_keys_hosting',
          updateObj
        )
        .then(() => {
          console.log('removed moderator')
        })
    },

    remindSelected () {
      console.log(this.seatedUsers)
      
      if (this.selected.length) {
        this.selected.forEach((index) => {
          this.inviteUser(this.seatedUsers[index])
        })
      } else {
        this.seatedUsers.forEach((user, index) => {
          if (user && user.email && this.isOwner(index) === false) {
            this.inviteUser(user)
          }
        })
      }
    },

    async inviteUser(invitee) {
      let email

      if (invitee.email) {
        email = invitee.email
      } else if (invitee.user_id) {
        email = invitee.user_id
      } else {
        email = invitee
      }

      const base_url = window.location.origin + '/#/'
      try {
        await CloudClient.call_CFsendUserInvite(
          App.Firebase,
          email,
          this.nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key'],
          base_url
        )

        // Always display an email verification warning for non-existent users.
        this.displayEmailVerificationWarning()

        // Show success dialog.
        this.invitePanel.email = email
        this.invitePanel.open = true
      } catch (error) {
        console.error(error)
        this.inviteErrorPanel.email = email
        this.inviteErrorPanel.open = true
      }
    },

    async displayEmailVerificationWarning() {
      if (this.requiresEmailVerification) {
        console.log(error)
        await this.$root.$globalAlert.open(
          'Verified email required',
          {
            line1:
              'The added user has to verify their email address before they can access this room.',
          },
          {
            color: this.colors.mx.secondary,
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/global.scss';

.wrapper {
  width: 100%; 
  overflow-x: hidden;
  padding: 0;
  background-color: $color-mx-dark-panel;
}

.v-data-table__empty-wrapper:hover {
  background: none !important;
}

.selectedavatar {
  border: 1px solid white;
  border-radius: 3px;
}

.margin {
  margin: 90px 0 60px 0;
}

.moviescreen {
  background-position: center;
  -webkit-background-size: 126% 88%;
  -moz-background-size: 126% 88%;
  -o-background-size: 126% 880%;
  background-size: 126% 88%;
  margin-top: -20px;
}

.largerroom {
  -webkit-background-size: 176% 78%;
  -moz-background-size: 176% 78%;
  -o-background-size: 176% 78%;
  background-size: 176% 78%;
}

.pending {
  color: #666666;
}

.no-select-chair:hover {
  background-color: transparent !important;
}
.verified-badge {
  margin-left: 2px;
  margin-top: -2px;
}

.people-table {
  th,
  td {
    white-space: nowrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.139);
    max-height: 2em;
  }
  .th-icons {
    margin-right: 0.25rem;
  }

  .td-username,
  .td-email {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    font-size: 0.8em;
    @media all and (max-width: 950px) {
      max-width: 100px;
    }
  }
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
thead th:nth-child(1) {
  width: 6%;
}
thead th:nth-child(2) {
  width: 6%;
}
thead th:nth-child(3) {
  width: 20%;
}

thead th:nth-child(4) {
  width: 31%;
}

thead th:nth-child(5) {
  width: 14%;
}
thead th:nth-child(6) {
  width: 14%;
}
thead th:nth-child(7) {
  width: 4%;
}

.hosting-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
}

</style>
