// @ts-nocheck

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  getLoggedInUser () {
    let currentUser = firebase.auth().currentUser
    return currentUser
  },
  getLoggedInUserId () {
    return firebase.auth().currentUser.uid
  }
}
