<template>
  <v-card style="text-align: center; background: #212121; overflow: hidden;">
    <v-toolbar dark style="max-height: 54px;">
        <v-spacer></v-spacer>
        <v-toolbar-title class="toolbar-title">
          Edit MixStage™ appearance
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn fab dark small style="position: absolute; right: 4px; top: 6px;" @click="$emit('request-close-edit-screeningroom')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row v-if="editedRoom" class="d-flex justify-center align-center">
          <v-col cols="12">

            <v-row v-if="editedRoom" class="d-flex justify-center align-center" style="margin-top: 20px;">
              <v-col cols="12" class="d-flex justify-center align-center">
                  <p style="font-size: 0.7em; z-index: 4;">
                  Logo Image {{ accountTier === 'tier1' ? '(Premium feature)' : ''}}
                </p>
              </v-col>

              <v-col cols="12"  class="d-flex justify-center align-center" style="margin-top: -55px;">
                <vue-avatar
                  style="
                    border: 1px solid #212121;
                    background: #212121;
                  "
                  :color="[11, 11, 11, 0.5]"
                  :image="editedRoom.custom_logo"
                  :width="240"
                  :height="240"
                  :border-radius="120"
                  :rounded="true"
                  :scale="editedRoom.custom_logo_scale"
                  placeholderSvg='<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65"><defs><style>.cls-1{fill:#FFF;}</style></defs><title>Upload_Upload</title><polygon class="cls-1" points="41.91 28.2 32.59 18.65 23.09 28.39 24.17 29.44 31.87 21.54 31.87 40.05 33.37 40.05 33.37 21.59 40.83 29.25 41.91 28.2"/><polygon class="cls-1" points="40.66 40.35 40.66 44.35 24.34 44.35 24.34 40.35 22.34 40.35 22.34 44.35 22.34 46.35 24.34 46.35 40.66 46.35 42.66 46.35 42.66 44.35 42.66 40.35 40.66 40.35"/></svg>'
                  ref="vueAvatarRoomLogo"
                />
              </v-col>
              <v-col cols="12" style="margin-top: -94px; z-index: 2;">
                <a @click="clearCustomLogo" style="color: white; font-size: 0.7em;">Clear current logo</a>
              </v-col>
              <v-col cols="6" style="margin-top: -30px; z-index: 3;">
                  <v-slider
                    color="white"
                    v-model="editedRoom.custom_logo_scale"
                    min="0"
                    max="3"
                    step="0.02"
                    append-icon="zoom_in"
                    prepend-icon="zoom_out"
                  />           
              </v-col>
            </v-row>

            <v-row style="margin-top: -26px;">
              <v-col cols="12" class="d-flex justify-start align-center">
                <p>Room name</p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center align-center">
                  <v-text-field
                    outlined
                    dense
                    clearable
                    color="white"
                    v-model="editedRoom.name"
                    dark
                    style="color: white; text-align: left; margin-top: -34px;"
                    @keyup.esc="editScreeningRoom = false"
                    @keyup.enter="onSaveClicked(editedRoom)"
                  />
              </v-col>
            </v-row>

            <v-row v-if="editedRoom" no-gutters>
              <v-col cols="12">
                <div style="min-width: 100%; text-align: left;">
                  Marker color scheme {{ accountTier === 'tier1' ? '(Premium feature)' : ''}}
                </div>
                <div 
                  class="d-flex justify-space-around align-center mt-2" 
                  style="border-radius: 6px;"
                  :style="{'background-color': colors.mx.xDark }">
                  <div style="display: flex; align-items: center;">
                      <div style="margin-right: 16px;">
                          <div>MixStage™ colors</div>
                          <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
                              <div v-for="i in mxColors" :key="i.name" style="height: 10px; width: 14px; margin-right: 4px;" :style="{ 'background-color': i.color }"></div>
                          </div>
                      </div>
                      <v-switch dense class="pt-6" :color="colors.mx.secondary" v-if="editedRoom" v-model="editedRoom.usePTMarkerColors"></v-switch>
                      <div style="margin-left: 16px;">
                          <div>PT colors</div>
                          <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
                              <div v-for="i in ptColors" :key="i.name" style="height: 10px; width: 14px; margin-right: 4px;" :style="{ 'background-color': i.color }"></div>
                          </div>
                      </div>
                  </div>
                </div>
              </v-col>
          </v-row>

            <v-row class="d-flex justify-center" style="margin-top: 30px;">
              <v-col cols="3">
                <v-btn small style="width: 100px;" :color="colors.gray" @click="$emit('request-close-edit-screeningroom')"
                  >CANCEL</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn small style="width: 100px;"
                  :color="colors.mx.secondary"
                  @click="onSaveClicked()"
                  >SAVE</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-overlay v-model="showSpinner" width="100" rounded persistent overlay-color="#080808" overlay-opacity="0.63">
        <div class="spinner-wrapper">
          <mixstage-spinner
            :showSpinner="showSpinner"
            :loadingMessage="'Updating room configuration'"
            style="background-color: transparent; width: 300px; height: 100px; padding: 0 0 10% 13%;">
          </mixstage-spinner>
        </div>
      </v-overlay>    </v-card>
</template>

<script>
// @ts-nocheck
import { VueAvatar } from 'vue-avatar-editor-improved'
import Constants from '@/../source_files/spotterfish_library/consts'
import spinner from '@/components/dashboard/mixStageSpinner.vue'

export default {
  name: 'dashboard-screeningrooms-edit-screeningroom',
  props: [
    'selectedRoom', 
    'accountTier',
  ],
  components: {
    VueAvatar,
    'mixstage-spinner': spinner
  },
  data(){
    return {
      logos: require('@/lib/ui/logos.js').logos,
      colors: require('@/lib/ui/colors.js').colors,
      currentUser: undefined,
      editedRoom: undefined,
      mxColors: Constants.FIXED_COMMENT_TYPES,
      ptColors: Constants.FIXED_COMMENT_TYPES_PT,
      showSpinner: false
    }
  },
  methods: {
    async onSaveClicked() {
      this.showSpinner = true
      
      try {
        if (!this.editedRoom.custom_logo_cleared && this.$refs.vueAvatarRoomLogo && this.accountTier !== 'tier1') {
          try {
            const img = this.$refs.vueAvatarRoomLogo.getImage()
            if (img) {
              const blob = await new Promise((resolve) => img.toBlob(resolve))
              const originalFileName = 'logo'
              const response = await this.$store.dispatch(
                'screening_room_upload_logo',
                {
                  fileName: `${this.editedRoom}/${originalFileName}`,
                  file: blob,
                }
              )
              this.editedRoom.custom_logo = response.publicURL
            }            
          } catch (error) {
            console.log('could not save room logo, probably not set')
          }
        } else if (this.editedRoom.custom_logo_cleared){
          this.editedRoom.custom_logo = null
        } else {
          this.editedRoom.custom_logo = this.editedRoom.custom_logo
        }
        console.log(this.editedRoom.usePTMarkerColors)
        this.$emit('request-save-screeningroom', this.editedRoom)
        
      } catch (error) {
        console.log(error)
        await this.$root.$globalAlert.open(
          `Error saving room configuration`,
          {
            line1:
            `Something went wrong, please try again.`,
          },
          {
            color: this.colors.mx.panel,
          }
        )      }
      this.showSpinner = false
    },

    onToggleMarkerStyle(room) {
      room.usePTMarkerColors = !room.usePTMarkerColors
    },

    clearCustomColor() {
      this.editedRoom.background_color = '#000000FF'
    },

    clearCustomLogo() {
      if (this.$refs.vueAvatarRoomLogo) {
        this.editedRoom.custom_logo = undefined
        this.editedRoom.custom_logo_scale = 1
        this.$refs.vueAvatarRoomLogo.resetImage()
        this.editedRoom.custom_logo_cleared = true
        this.onSaveClicked()
      }
    },
  },
  async mounted(){
    this.editedRoom = {
      '.key': this.selectedRoom['.key'],
      name: this.selectedRoom.name,
      usePTMarkerColors: this.selectedRoom.use_PT_Marker_colors === undefined ? false : Boolean(this.selectedRoom.use_PT_Marker_colors),
      custom_logo: this.selectedRoom.custom_logo || '',
      custom_logo_scale: this.selectedRoom.custom_logo_scale || 1,
      background_color: this.selectedRoom.background_color || '#000000FF'
    }
    console.log(this.editedRoom)
    this.$forceUpdate()
  }
}

</script>

<style>

</style>