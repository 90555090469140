<template>
  <div>
    <v-dialog
      modal
      fullscreen
      v-model="showSettings"
      v-if="showSettings"
      style="position: relative; z-index: 2147483647"
    >
      <bathroom-mirror-component
        ref="bathroomMirror"
        :approved="approved"
        :sessionActive="sessionActive"
        :roomName="roomName"
        :requiresEmailVerification="requiresEmailVerification"
        :requiresTwoFactorAuth="requiresTwoFactorAuth"
        :requiresRoomPin="requiresRoomPin"
        :onContinue="onSettingsContinue"
        :onCancel="onSettingsCancel"
        @knock-knock="knockOnDoor()"
        @updated-room-verification-code="updateVerificationCode($event)">
      </bathroom-mirror-component>
    </v-dialog>
    <alert ref=alert></alert>
    <audio ref="joinRoomAudioElement" />
  </div>
</template>

<script>
// @ts-nocheck

import bathroomMirror from '@/components/webRTCcomponents/bathroomMirror.vue'
import App from '@/App.vue'
import alert from '@/components/UI/alert.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'
import SpotterfishHelpers from '@/../source_files/spotterfish_library/SpotterfishHelpers'

export default {
  components: {
    'bathroom-mirror-component': bathroomMirror,
    alert
  },
  data () {
    return {
      logos: require('@/lib/ui/logos.js').logos,
      colors: require('@/lib/ui/colors.js').colors,
      approvedSound: require('@/assets/join_room_enabled.mp3'),
      deniedSound: require('@/assets/join_room_disabled.mp3'),
      approved: false,
      sessionActive: false,
      previousApprovedValue: undefined, 
      roomName: '',
      roomId: '',
      requiresEmailVerification: false,
      requiresTwoFactorAuth: false,
      requiresRoomPin: false,
      approvedSnapshot: function () { return 0 },
      listenForApprovalInterval: undefined,
      showSettings: false,
    }
  },
  methods: {
    joinRoomEnabledSound (flag){
      // the "flag" prop will show whether user has been approved, or if they have been removed from the room
      this.$refs.joinRoomAudioElement.src = flag ? this.approvedSound :this.deniedSound
      this.$refs.joinRoomAudioElement.volume = 0.5
      this.$refs.joinRoomAudioElement.play()
    },
    clearAllIntervals () {
      // console.log('cleared intervals')
      // Return value of clearInterval() is undefined.
      if (this.listenForApprovalInterval !== undefined) {
        this.listenForApprovalInterval = clearInterval(this.listenForApprovalInterval)
      }
    },

    updateVerificationCode (code) {
      console.log(code)
      this.roomVerificationCode = code
    },

    async knockOnDoor () {

      const link = await this.$store.dispatch('decryptHash', this.$route.query.hash)
      // console.log('Using link:', link)

      // Always check access status before knock, to avoid requests etc. for users that
      // already have access.
      await this.getRoomAccessStatus(link)

      if (this.requiresEmailVerification && this.requires_two_factor_auth && this.requires_room_pin) {
        try {
          await CloudClient.call_CFsendPasscodeSMS(App.Firebase, this.roomId)
        } catch (error) {
          await this.$root.$globalAlert.open('Error', {
            line1: `Could not send passcode SMS (${error.message})`
          },
          { color: this.colors.mainAccent })
        }
      }

      if (!this.approved) {
        console.log('Requesting access to screening room')
        try {
          const result = await CloudClient.call_CFrequestAccessToScreeningRoom(App.Firebase, link.sroom)
          console.log('Result from call_CFrequestAccessToScreeningRoom:', result)
        } catch (error) {
          console.log('Error from call_CFrequestAccessToScreeningRoom:', error)
          // await this.$root.$globalAlert.open('Error', {
          //   line1: `Could not request access to room (${error.message})`
          // },
          // { color: this.colors.mainAccent })
        }
      }
    },

    async getRoomAccessStatus (link) {
      try {
        const room = await App.firestoreDB.collection('screening_rooms').doc(link.sroom).get()
        const roomFeatureBits = await CloudClient.call_CFcalculateFeatureBitsForRoom(App.Firebase, link.sroom)

        this.requiresEmailVerification = roomFeatureBits.requires_email_verification || false
        this.requiresTwoFactorAuth = roomFeatureBits.requires_two_factor_auth || false
        this.requiresRoomPin = roomFeatureBits.requires_room_pin || false

        // console.log('have access')
        this.roomId = link.sroom
        this.roomName = room.data().name

        // console.log('Checking access based on this room snapshot:', room.data())
        this.approved = room.data().project_people.indexOf(App.spotterfishSession.userSession.firebaseCurrentUser.uid) !== -1
      } catch (error) {
        // This will be triggered often, since access controls prevents us from accessing this until we are members
        console.log('no access yet')
        this.approved = false
      }
    },

    async setActiveToFalse (link) {
      try{
        await App.spotterfishSession.firebase.database().ref(`shared_state/${ link.sroom }/users/${ App.spotterfishSession.userSession.firebaseCurrentUser.uid }`)
            .update({active: false})
      }
      catch(e){
        console.log('Could not set user to active: false')
      }
    },

    onSettingsCancel(){
      this.showSettings = false
      this.clearAllIntervals()
      this.$router.push('dashboard')

    },

    onSettingsContinue(){
      this.showSettings = false
      this.clearAllIntervals()
      this.$router.push({
        name: 'screeningroom',
        query: {
          hash: this.$route.query.hash
        },
        params: {
          roomVerificationCode: this.roomVerificationCode
        }
      })
    },
    updateSessionStatus (stat) {
      console.log({stat})
      this.sessionActive = stat.length ? true : false
    }
  },

  async mounted () {
    await this.$store.dispatch('checkBrowser', this.$root.$globalConfirm)

    const shouldHideAlertForReload = true
    const needsPageRefresh = await SpotterfishHelpers.alertIfSessionError(this.$root.$globalAlert, this.colors.mainAccent, shouldHideAlertForReload)
    if (needsPageRefresh) {
      window.location.reload()
    }
    // Add a listener for changes in project people:
    const link = await this.$store.dispatch('decryptHash', this.$route.query.hash)
    this.setActiveToFalse(link)
    await this.getRoomAccessStatus(link)

    App.spotterfishSession.userSession.addSessionListener(
        'activeSessions', 
        this.updateSessionStatus,
        App.spotterfishSession.userSession.firebaseCurrentUser.uid,
        [{'.key': link.sroom}]
      )

    this.previousApprovedValue = this.approved
    this.listenForApprovalInterval = setInterval(async () => {
      await this.getRoomAccessStatus(link)
      if (this.approved !== this.previousApprovedValue) {
        this.joinRoomEnabledSound(this.approved)
        if (this.approved === false && this.previousApprovedValue === true) {
          this.$root.$globalAlert.open('Access denied', {
            line1: `You have been removed from the Session by the host.`,
            line3: 'Reload this page to request access again.'
          },
          { color: this.colors.mainAccent.secondary })
        }
        this.previousApprovedValue = this.approved
      }
    }, 2000)

    this.showSettings = true
  },
  beforeDestroy () {
    try {
      App.spotterfishSession.userSession.removeSessionListener('activeSssions', this.updateSessionStatus)            
    } catch (error) {
      console.log('No callbacks to clear')
    }
    this.clearAllIntervals()
    this.approvedSnapshot()
    this.showSettings = false
  }
}

</script>

<style scoped>

</style>
