const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const Constants = require('../spotterfish_library/consts.js')
const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const _ = require('lodash')


/*
  Protools and Logic Audio does not output MTC full frames.
  http://duc.avid.com/showthread.php?p=1243287
  If you enable MMC they output MMC LOCATE commands (format2)


  MMC: MMC sysexes contains MMC command(s). We handle this in two steps: extract MMC-command from sysex, then examine MMC-command.
*/

/*
    LOGIC
        240, 127, 127, 6, 68, 6, 1, 1,  0, 5, 0, 0, 247

    PROTOOLS
      240, 127, 127,  6,  1,  247

      MMC LOCATE, format2 commands
      240, 127, 127,  6,  68, 6,  1,  3,  38, 17, 38, 0, 247
      240, 127, 127,  6,  68, 6,  1,  3,  34, 23, 51, 0, 247

      f0    7f  7f    6   44  6   1   3   22  17  33  0 f7
                DD                                            Device ID
                      MMC
                          AA  AA  AA  AA  AA  AA  AA  AA      MMC command
                          CMD                                 0x44 = LOCATE
                              CC                              bytecount = 6
                                  SC                          Subcommand = 1 => format 2
                                      hr  mn  sc  fr  ff      Standard time specification, type (ff)
*/
const kTestMMC_Protools_STOP_sysex1 = new Uint8Array([ 240, 127, 127,  6,  1,  247 ])


const kTestMMC_Protools_LOCATE_format2_sysex1 = new Uint8Array([ 240, 127, 127, 6, 68, 6, 1, 3,  38, 17, 38, 0, 247])
const kTestMMC_Protools_LOCATE_format2_sysex2 = new Uint8Array([ 240, 127, 127, 6, 68, 6, 1, 3,  34, 23, 51, 0, 247])


//  This is a special format for encoding timecode, similar but not the same as MTC full frame
//  See MIDI SPECIFICATION 1.0 p.209
function unpackMMCStandardTimeCode(buffer){
  assert(SpotterfishCore.isObjectInstance(buffer))

  if(buffer.byteLength !== 5){
    throw Error('Invalid MMC standard time code')
  }

  const view = new DataView(buffer)

  const hoursAndType_0tthhhhh = view.getUint8(0)
  const minutes_0cmmmmmm = view.getUint8(1)
  const seconds_0kssssss = view.getUint8(2)
  const frames_0gifffff = view.getUint8(3)

  const finalByte = view.getUint8(4)

  if(hoursAndType_0tthhhhh < 0x80 && minutes_0cmmmmmm < 0x80 && seconds_0kssssss < 0x80 && frames_0gifffff < 0x80){
    const timeType = (hoursAndType_0tthhhhh & 0x60) >> 5
    const hours = hoursAndType_0tthhhhh & 0x1f

    const colorFrameFlag = ((minutes_0cmmmmmm >> 6) & 1) === 1
    const minutes = minutes_0cmmmmmm & 0x3f

    const k = ((seconds_0kssssss >> 6) & 1) === 1
    const seconds = seconds_0kssssss & 0x3f

    const sign = ((frames_0gifffff >> 6) & 1) === 1
    const i = ((frames_0gifffff >> 5) & 1)
    const frames = frames_0gifffff & 0x1f

    // 0 ... 99
    let fractionalFrames = 0
    if(i === 0){
        const fractionalFrames_0bbbbbbb = finalByte
        fractionalFrames = fractionalFrames_0bbbbbbb
    }
    else {
      const st_0evdnxxx = finalByte
      const e = ((st_0evdnxxx >> 6) & 1) === 1
      const v = ((st_0evdnxxx >> 5) & 1) === 1
      const d = ((st_0evdnxxx >> 4) & 1) === 1
      const n = ((st_0evdnxxx >> 3) & 1) === 1
      const xxx = st_0evdnxxx & 0x07
    }

    if(hours < 24 && minutes < 60 && seconds < 60 && frames < 30 && fractionalFrames < 100){
      return { timeType, hours, minutes, seconds, frames, sign, fractionalFrames }
    }
    else {
      throw Error('Invalid MMC standard time code')
    }
  }
  else {
    throw Error('Invalid MMC standard time code')
  }
}

function prove__unpackMMCStandardTimeCode0(){
  const result = unpackMMCStandardTimeCode(new Uint8Array([ 3,  38, 17, 38, 0]).buffer)
  console.dir(result, { depth: null })
  unitTestAssert(_.isEqual(
    { timeType: 0, sign: false, hours: 3, minutes: 38, seconds: 17, frames: 6, fractionalFrames: 0 },
    result
  ))
}




//  midi data as ArrayBuffer
//  Checks sysex/MMC headers
function isMMCCommand(sysex){
  assert(SpotterfishCore.isObjectInstance(sysex))

  if(sysex.byteLength >= 5){
    const view = new DataView(sysex)

    const header0 = view.getUint8(0)
    const header1 = view.getUint8(1)
    const subID1 = view.getUint8(3)
    const eox = view.getUint8(sysex.byteLength - 1)

    return header0 === 0xf0 && header1 === 0x7f && subID1 === 0x06 && eox === 0xf7
  }
  else { return false }
}

function prove__isMMCCommand0(){
  unitTestAssert(isMMCCommand(kTestMMC_Protools_LOCATE_format2_sysex1.buffer) === true)
}
function prove__isMMCCommand1(){
  unitTestAssert(isMMCCommand(new Uint8Array([ 240, 127, 127, 5, 68, 6, 1, 3,  38, 17, 38, 0, 247]).buffer) === false)
}


//  Returns new ArrayBuffer with MMC command
function extractMCCCommandBody(sysex){
  assert(SpotterfishCore.isObjectInstance(sysex))
  assert(isMMCCommand(sysex))

  const view = new DataView(sysex)

  const deviceID = view.getUint8(2)
  const body = sysex.slice(4, sysex.byteLength - 1)
  assert(SpotterfishCore.isObjectInstance(body))

  return body
}

function prove__extractMCCCommandBody0(){
  const result = extractMCCCommandBody(kTestMMC_Protools_LOCATE_format2_sysex1.buffer)
  console.dir(result, { depth: null })
  unitTestAssert(_.isEqual(
    new Uint8Array([ 68, 6, 1, 3,  38, 17, 38, 0]).buffer,
    result
  ))
}





////////////////////////////////    STOP


//  MMC-command body as ArrayBuffer
//  Checks length and headers
//  See MIDI SPECIFICATION p.221
function isMMCCommand_STOP(body){
  assert(SpotterfishCore.isObjectInstance(body))

  //  NOTICE: accessing outside view's buffer throws exception.
  const view = new DataView(body)

  const command = view.getUint8(0)
  return command === 0x01
}

function prove__isMMCCommand_STOP0(){
  unitTestAssert(isMMCCommand_STOP(extractMCCCommandBody(kTestMMC_Protools_STOP_sysex1.buffer)) === true)
}




////////////////////////////////    LOCATEFormat2



//  MMC-command body as ArrayBuffer
//  Checks length and headers
//  See MIDI SPECIFICATION p.229
function isMMCCommand_LOCATEFormat2(body){
  assert(SpotterfishCore.isObjectInstance(body))

  //  NOTICE: accessing outside view's buffer throws exception.
  const view = new DataView(body)

  const command = view.getUint8(0)
  const subCommand = view.getUint8(2)

  return command === 0x44 && subCommand === 0x01
}


//  buffer is ArrayBuffer.
//  Throws if invalid format.
//  Expects isMMCCommand_LOCATEFormat2() to have passed
function unpackMMCCommand_LOCATEFormat2(body){
  assert(SpotterfishCore.isObjectInstance(body))
  assert(isMMCCommand_LOCATEFormat2(body))

  const view = new DataView(body)

  const byteCount = view.getUint8(1)
  if(byteCount !== 6){
    throw Error('Invalid MTC LOCATE Format2 message')
  }

  const timeBytes = body.slice(3, 3 + 5)
  const time = unpackMMCStandardTimeCode(timeBytes)
  return time
}



function prove__unpackMMCCommandLOCATE0(){
  const result = unpackMMCCommand_LOCATEFormat2(
    extractMCCCommandBody(kTestMMC_Protools_LOCATE_format2_sysex1.buffer)
  )
  console.dir(result, { depth: null })
  assert(_.isEqual({ timeType: 0, sign: false, hours: 3, minutes: 38, seconds: 17, frames: 6, fractionalFrames: 0 }, result))
}
function prove__unpackMMCCommandLOCATE1(){
  const result = unpackMMCCommand_LOCATEFormat2(
    extractMCCCommandBody(kTestMMC_Protools_LOCATE_format2_sysex2.buffer)
  )
  console.dir(result, { depth: null })

  assert(_.isEqual(
    { timeType: 0, sign: false, hours: 3, minutes: 34, seconds: 23, frames: 19, fractionalFrames: 0 },
    result
  ))
}


/*
onMIDI(data){
  if(MTC.isMTCQuarterFrame(data)){
    const value = MTC.unpackMTCQuarterFrame(data)
    MTC.accQF(myAcc, value)
  }
  else if(MTC.isMTCFullFrame(data)){
      const value = MTC.unpackMTCFullFrame(mmcCommand)
  }
  else if(MMC.isMMCCommand(data)){
    const mmcCommand = MMC.extractMCCCommandBody(data)
    if(MMC.isMMCCommand_STOP(mmcCommand)){
    }
    else if(MMC.isMMCCommand_LOCATEFormat2(mmcCommand)){
      const value = MMC.unpackMMCCommand_LOCATEFormat2(mmcCommand)
    }
  }
  else {

  }
}
*/


function runUnitTests () {
  console.log('MMC -- start')

  prove__unpackMMCStandardTimeCode0()

  prove__isMMCCommand0()
  prove__isMMCCommand1()

  prove__extractMCCCommandBody0()

  prove__isMMCCommand_STOP0()

  prove__unpackMMCCommandLOCATE0()
  prove__unpackMMCCommandLOCATE1()

  console.log('MMC -- end')
}

module.exports = {
  isMMCCommand,
  extractMCCCommandBody,

  isMMCCommand_STOP,

  isMMCCommand_LOCATEFormat2,
  unpackMMCCommand_LOCATEFormat2,

  runUnitTests
}
