<template>
  <div
    class="user-controls"
    :style="{ justifyContent: templateReactiveData.clientConfig.toolbarEnabled ? 'normal' : 'end' }"
  >
<!-- LEFT SIDE USER CONTROLS (name of room) -->

    <div v-if="templateReactiveData.freeScreeningRoom">
      {{ null }}
    </div>

    <div v-else style="display: flex; align-items: center; margin-top: 2px;" class="hide-on-small">
      <img
        v-if="customLogo"
        class="brand-logo"
        :src="customLogo"
        alt="Brand Logo"
      />
      <h2
        style="font-weight: 400; font-size: 18px; margin-left: 4px; margin-bottom: 4px;"
      >
        <span v-if="templateReactiveData.roomName">{{ templateReactiveData.roomName }}</span>
      </h2>
    </div>
    
    <!-- RIGHT SIDE USER CONTROLS -->

    <div
      class="toggle-button"
      style="margin-left: auto; display: flex; gap: 0.75rem; margin-right: 1rem; margin-top: 7px"
    >
      <!-- SCREENSHARE ON/OFF -->

      <div class="tooltip-trigger toggle-icon" :class="[{ 'disabled': tabIsPressed}, {'__secondary': isScreensharing,}]" :data-tooltip="isScreensharing ? 'Stop sharing' : 'Share screen'">
        <i
          v-show="templateReactiveData.UI_showVideoChatControls"
          @click="onToggleScreenshare"
          class="material-icons"
          :class="[
            {
              'disabled': isTogglingScreenShare,
            },
        ]"
        >
            monitor
        </i>
      </div>
      <!-- MICROPHONE ON/OFF -->
      <div class="tooltip-trigger toggle-icon" :class="{ 'disabled': tabIsPressed }" data-tooltip="Toggle computer mic on/off">
        <i
          v-show="templateReactiveData.UI_showVideoChatControls"
          @click="onToggleAudioMute()"
          v-hotkey="keymap"
          class=" material-icons"
        >
          {{ getUserState.audio_muted ? 'mic_off' : 'mic'  }}
          </i>
      </div>


      <!-- CAMERA ON/OFF -->
      <div class="tooltip-trigger toggle-icon bottom left" :class="{ 'disabled': tabIsPressed }" data-tooltip="Toggle computer camera on/off">
        <i
          v-show="templateReactiveData.UI_showVideoChatControls"
          @click="onToggleVideoMute()"
          v-hotkey="keymap"
          class="material-icons"
        >
        {{ getUserState.video_muted ? 'videocam_off' : 'videocam' }}
          </i>
      </div>
      
      <!-- Manage team members -->
      <template>
        <div>
          <v-menu nudge-left="80" offset-y  @input="menuStateUpdate">
            <template v-slot:activator="{ on }">
                <div class="tooltip-trigger toggle-icon __secondary bottom left" :class="{ 'disabled': tabIsPressed || menuIsOpen }" data-tooltip="Handle user settings and text chat">
                  <v-badge
                      :content="numberOfPeopleInLobby()"
                      :value="numberOfPeopleInLobby()"
                      offset-x="8"
                      offset-y="8"
                      color="red"
                      overlap
                    >
                      <i
                        v-on="computedEvents(on)"
                        class="material-icons"
                      >
                        group
                      </i>
                    </v-badge>
                </div>
            </template>

            
            <v-list style="margin-top: 1rem; text-align: left; z-index: 1001;">
              <v-list-item 
                @click="$emit('request-copy-short-link')"
                  v-if="templateReactiveData.UI_showVideoChatControls"
              >
                <v-icon 
                  style="margin-right: 0.5rem"
                > 
                  share
                </v-icon>
                <v-list-item-title>Copy Invite link</v-list-item-title>
              </v-list-item>

              <v-list-item 
                @click="$emit('request-show-hosting-panel')"
                  v-if="templateReactiveData.UI_showVideoChatControls"
                  :disabled="!templateReactiveData.isUserModerator"
                >
                <v-badge
                  :content="numberOfPeopleInLobby()"
                  :value="numberOfPeopleInLobby()"
                  offset-x="30"
                  offset-y="20"
                  color="red"
                  overlap
                >
                  <v-icon 
                    style="margin-right: 0.5rem"
                  > 
                    group
                  </v-icon>
                </v-badge>
                <v-list-item-title> {{ templateReactiveData.isUserModerator? 'Manage team access' : 'Team (Moderators only)'  }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="$emit('request-mute-all')"
                :disabled="!templateReactiveData.isUserModerator"
              >
                <v-icon style="margin-right: 0.5rem">
                  mic
                </v-icon>
                <v-list-item-title> {{ templateReactiveData.isUserModerator? 'Mute all participants' : 'Mute all (Moderators only)'  }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openChat" >
                <v-badge
                  :content="numberOfUnseenTextMessages"
                  :value="numberOfUnseenTextMessages"
                  offset-x="20"
                  offset-y="20"
                  :color="colors.mainAccent"
                  overlap
                >
                  <v-icon style="margin-right: 0.5rem">
                    chat
                  </v-icon>
                </v-badge>
                <v-list-item-title>Text chat</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <!-- DROPDOWN MENU AUDIO/VIDEO, SHORTCUTS -->
      
      <template>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="tooltip-trigger toggle-icon bottom center" data-tooltip="More">
                <i
                  class="material-icons">more_vert</i>
              </div>
            </template>
            <v-list style="margin-top: 1rem; text-align: left">
              <v-list-item @click="openBathroomMirror()" v-if="templateReactiveData.UI_showVideoChatControls">
                <v-icon style="margin-right: 0.5rem"> mdi-cog </v-icon>
                <v-list-item-title>Audio & Video settings</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="$emit('keyboard-shortcuts-button-clicked')"
              >
                <v-icon style="margin-right: 0.5rem">
                  mdi-apple-keyboard-command
                </v-icon>
                <v-list-item-title>Help & Keyboard shortcuts</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$store.state.modals.videoAutoPlayFix = true">
                <v-icon style="margin-right: 0.5rem">
                  mdi-help-circle-outline
                </v-icon>
                <v-list-item-title>Playback issues?</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <!-- CHAT -->
      <!-- <v-tooltip bottom :color="colors.tooltipBackground">
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            
            class="toggle-button user-controls-icons"
            :style="{'background-color': colors.mainAccent}"
            v-show="templateReactiveData.clientConfig.toolbarEnabled"
          >
            <v-badge
              :content="numberOfUnseenTextMessages"
              :value="numberOfUnseenTextMessages"
              offset-x="20"
              offset-y="20"
              color="red"
              overlap
            >
              <v-icon @click="openChat">mdi-chat</v-icon>
            </v-badge>
          </div>
        </template>
        <span>Text chat with team members</span>
      </v-tooltip> -->

      <!-- FULL SCREEN -->
      <div class="tooltip-trigger bottom left" :data-tooltip="html_output_isFullscreen ? 'Exit full screen' : 'Enter full screen'">

          <div class="toggle-icon">
            <i
              class="no-select material-icons"
              @click="
                $emit('fullscreen-toggle'),
                  (html_output_isFullscreen = !html_output_isFullscreen)
              "
            >
              {{
                html_output_isFullscreen
                  ? 'fullscreen_exit'
                  : 'fullscreen'
              }}</i>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
//@ts-nocheck
import { ref, computed, onMounted, inject } from 'vue'
import store from '@/store'

import { toggleAudioMute, toggleVideoMute } from '@/components/webRTCcomponents/videoChat/helpers'
const colors = require('@/lib/ui/colors.js').colors

export default {
  props: [
    'openBathroomMirror',
    'tabIsPressed',
    'handleTabKeyDown',
    'handleTabKeyUp',
    'customLogo',
    'isScreensharing',
    'numberOfPeopleInLobby'
  ],
  setup(props, { emit }) {

    const nonReactiveState = inject('nonReactiveState')
    const _templateReactiveData = inject('templateReactiveData')
    const templateReactiveData = computed(() => _templateReactiveData.value)

    const html_output_isFullscreen = ref(false)
    const projectIsLoaded = ref(false)
    const seenChatMessageCount = ref(0)
    const messages = ref([])
    const isOpen = ref(false)
    const menuIsOpen = ref(false)
    const isTogglingScreenShare = ref(false)
    const chatRef = ref(null)

    const setupChat = () => {
      chatRef.value = nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.spotterfishSession.firebase.database().ref(`shared_state/${nonReactiveState.screeningRoomSessionNonReactive.screeningRoomConnection.screeningRoomDBCopy['.key']}/text_chat`);
      chatRef.value.on('value', (snapshot) => {
        if (snapshot.val()) {
          messages.value = Object.values(snapshot.val());
          if (isOpen.value) {
            seenChatMessageCount.value = getMessages().length;
          }
        }
      });
    };

    const getCurrentUser = () => {
      return store.state.currentSpotterfishUser
    }

    const numberOfUnseenTextMessages = computed(() => {
      return messages.value.length - seenChatMessageCount.value;
    })

    const keymap = computed(() => {
      return {
        tab: {
          keydown: props.handleTabKeyDown,
          keyup: props.handleTabKeyUp,
        },
      }
    })

    const getUserState = computed(() => {
      return templateReactiveData.value?.ui.users?.[getCurrentUser().user_id] || {}
    })

    // Methods
    const menuStateUpdate = (value) => {
      console.log(value)
      menuIsOpen.value = value
    }
    const computedEvents = (on) => {
      if (menuIsOpen.value) {
        return { ...on }
      }
      return { ...on }
    }
    const getMessages = () => {
      return messages.value.filter(message => message.recipient === 'everyone' || [message.recipient, message.from_user].includes(getCurrentUser()?.user_id))
    }

    const onToggleAudioMute = () => {
      toggleAudioMute(nonReactiveState.screeningRoomSessionNonReactive, getCurrentUser().user_id)
    }
    const onToggleVideoMute = () => {
      toggleVideoMute(nonReactiveState.screeningRoomSessionNonReactive, getCurrentUser().user_id)
    }
    const onToggleScreenshare = () => {
      if (isTogglingScreenShare.value === true) return
      isTogglingScreenShare.value = true
      emit('screenshare-toggle')
      setTimeout(() => {
        isTogglingScreenShare.value = false
      }, 2000)
    }
    const openChat = () => {
      isOpen.value = !isOpen.value
      if(isOpen.value){
        seenChatMessageCount.value = messages.value.length
      }
      emit('chat-active')
    }


    onMounted(() => {
      html_output_isFullscreen.value = !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)
      setupChat()
    })
    
    return {
      colors: colors,
      store: store,
      html_output_isFullscreen,
      nonReactiveState,
      templateReactiveData,

      projectIsLoaded,
      seenChatMessageCount,
      messages,
      isOpen,
      menuIsOpen,
      isTogglingScreenShare,
      chatRef,
      numberOfUnseenTextMessages,
      keymap,
      menuStateUpdate,
      computedEvents,
      getMessages,
      getCurrentUser,
      getUserState,
      onToggleAudioMute,
      onToggleVideoMute,
      onToggleScreenshare,
      openChat
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/global.scss';

.toggle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-mx-light-panel;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: $color-mx-x-light-panel;
  }

}
.brand-logo {
  border: 1px solid black;
  border-radius: 50%;
  max-width: 30px;
  max-height: 30px;
  aspect-ratio: 1/1;
  margin: 0 10px;
  /* border: 1px solid rgba(255, 255, 255, 0.303); */
}

.user-controls {
  position: absolute;
  background-color: rgba(13,13,13,0.6);
  top: 0;
  right: 0px;
  left: 0px;
  display: flex;
  padding: 0 0 0 9rem; /* Make room for the Exit button */
}

.__gray {
  background-color: #404040;
}
.__blue {
  background-color: #6b91c5;
}

.__secondary {
  background-color: #A149FC;
  &:hover{
    background-color: #b26dfc;
  }
}

</style>