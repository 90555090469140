<template>
  <div></div>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'authRedirect',
  async beforeMount () {
    // Needs to have relative paths passed is as ?loggedInURL=/upgrade etc
    let redirectTo = 'https://www.mixstage.io/'

    const loggedInURL = this.$route.query.loggedInURL
    if (!loggedInURL) {
      console.error('No loggedInURL was passed')
    }
    
    const loggedOutURL = this.$route.query.loggedOutURL
    if (!loggedOutURL) {
      console.error('No loggedOutURL was passed')
    }

    try {
      redirectTo = await CloudClient.call_CFauthRedirect(App.Firebase, loggedInURL, loggedOutURL)
    } catch (error) {
      await this.$root.$globalAlert.open('Error', {
        line1: error.message
      },
      { color: this.colors.mainAccent })
    }

    if (redirectTo.indexOf('/') === 0) {
      this.$router.push(redirectTo)
        .catch((e) => {
          console.log(e)
        })
    } else {
      window.location.href = redirectTo
    }
  }
}
</script>
