import JanusClient from '@/lib/janus/januscjs'
import { isBooleanInstance, isDevelopment, isObjectInstance, isArrayInstance, isFunctionInstance, isNumberInstance, isStringInstance } from '@/../source_files/spotterfish_library/SpotterfishCore'
import ScreeningRoomConnection from '@/../source_files/web_client/ScreeningRoomConnection'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import CloudClient from '@/../source_files/web_client/CloudClient'
import DawStreamerUtils from '@/../source_files/spotterfish_library/utils/DawStreamerUtils.js'

import MixStageJanusErrors from '@/../source_files/web_client/MixStageJanusErrors'

import Constants from '@/../source_files/spotterfish_library/consts'
import adapter from 'webrtc-adapter'
import { checkAllowedInputs } from '@/../source_files/spotterfish_library/utils/VideoChatUtils'
import _ from 'lodash'
import { assert } from '@/../source_files/spotterfish_library/SpotterfishCore'
import * as Sentry from '@sentry/browser'


const safeJsonParse = (str) => {
  try {
    return JSON.parse(str)
  } catch (error) {
    console.log('Failed to parse JSON:', error)
    return undefined
  }
}



const UserMap = () => {
  let users = {}

  const getUsers = () => users
  const addUser = (userId, initialData = {}) => {
    if(!users[userId]){
      users[userId] = {
        videoStream: undefined,
        audioStream: undefined,
        feed: undefined,
        dawStream: undefined,
        dawStreamerRfid: undefined,
        screenshare: undefined,
        screenshareRfid: undefined,
        ...initialData
      }
    }
  }
  const updateUser = (userId, data = {}) => {
    if(users[userId]){
      users[userId] = {
        ...users[userId],
        ...data
      }
    }
  }
  const removeUser = (userId) => {
    users = _.omit(users, userId)
  }
  const getUser = (userId) => {
    return users[userId] || false
  }
  

  return {
    getUsers,
    addUser,
    updateUser,
    removeUser,
    getUser
  }
}



/*
ABOUT USER LISTS & PUBLISHING


1) Shared state contains all users connected to a session = ScreeningRoomConnection. No videochat involved.
2) Janus attached users = users in janus session 'attached'
3) Janus published users




subscriber ---> event == 'attached'

publisher ----> event == 'joined'



*/




/*

# TODO

2) merge VideoChatSession() & openVideoChatSession()

3) Replace callback system with simpler design.

4) Use opne(), setParams(), close() structure

5) Published-mode: either make it an encapsulated state or split to VideoChatSession + PublishMe() 


# DONE

1) Rename member variables with "_" postfix. "janus" -> "janus_"



# STATES

1) No videochat client

2) Videochat client starting up, client doesn't hve it yet.

3) Videochat client open & running.

  onMessage()
  SetParams()  my mic on/off, my cam on/off, 

4) unpublished: unpublishOwnFeed()



# DESIGN

a = await VideochatSession()

  xxx
b = await PublishMe(a)
StopPublish(b)

c = await PublishMe(a)



# ALT

A) Videochat(params, onMessage, onCriticalError), setParams( includes publish-flag ), close()

B) VideoChat(params, onMessage, onCriticalError), setParams(), close()
  + PublishMe(params, onMessage, onCriticalError), setParams(), close()
*/

function checkInvariantVideoChatSession(videoChat) {
  assert(isObjectInstance(videoChat))
  return true
}


function checkInvariantVideoChatData(videoChatData) {
  assert(isNumberInstance(videoChatData.roomSize) && videoChatData.roomSize >= 0)
  assert(isStringInstance(videoChatData.video_room_server) || videoChatData.video_room_server === undefined )
  assert(isStringInstance(videoChatData.video_room_turnstun_servers) || videoChatData.video_room_turnstun_servers === undefined)
  assert(isStringInstance(videoChatData.iceTransportMethods) || videoChatData.iceTransportMethods === undefined)
  assert(isStringInstance(videoChatData.screeningRoomId))
  assert(isObjectInstance(videoChatData.currentUser) && isStringInstance(videoChatData.currentUser.uid))
  assert(isStringInstance(videoChatData.janusSecret))
  assert(isBooleanInstance(videoChatData.isAudienceRoom))
  assert(isFunctionInstance(videoChatData.onCriticalError))
  return true
}


//  Constructor
const VideoRoomCallbacks = () => {

  //  For each callback key, store a list of functions.
  //  "onError" is a LIST of callbacks, and so on.
  const callbacks_ = {}

  let pendingIntervals_ = []
  let clearingAwaits_ = false


  const addCallback = (callbackName, fn) => {
    assert(_.isFunction(fn))

    if(!callbacks_[callbackName]){
      callbacks_[callbackName] = [fn]
    } else {
      callbacks_[callbackName].push(fn)
    }
  }

  //  Creates NOP-function if callback is missing.
  const getCallback = (callbackName) => {
    if(callbacks_[callbackName]){
      return function(...args) { 
        callbacks_[callbackName].forEach(fn => fn(...args))
      }
    }
    else {
      return function(){ console.warn(`callback '${callbackName}' not registered`)}
    }
  }

  const awaitCallback = async (callbackName, timedoutCallback, maxRetries = 100, retryTimeSeconds = 500,) => {
    let retry_ = 0;
    let interval_ = undefined

    return new Promise((resolve, reject) => {
      interval_ = setInterval(
        () => {
          if(!pendingIntervals_.includes(interval_)){
            pendingIntervals_.push(interval_)
          }

          if(clearingAwaits_) {
            clearInterval(interval_)
            reject('Clearing')
          }

          if(maxRetries > 0 && retry_ >= maxRetries){
            reject('No callback found')
            clearInterval(interval_)
          }

          if(callbacks_[callbackName]){
            resolve(getCallback(callbackName))
            clearInterval(interval_)
          }

          console.warn(`No callback '${callbackName}' yet, retrying: ${retry_ + 1}`)
          retry_ += 1

          if(retry_ > maxRetries && typeof timedoutCallback === 'function'){
            timedoutCallback()
          }
        },
        retryTimeSeconds
      )
    })
  }

  const clearAwaits = () => {
    clearingAwaits_ = true
    console.log('Clear all awaiting callbacks!', pendingIntervals_)

    pendingIntervals_.forEach(a => clearInterval(a))
    pendingIntervals_ = []
  }

  return {
    addCallback,
    getCallback,
    awaitCallback,
    clearAwaits
  }
}


const getVideoRoomServer = (video_room_server) => {
  try {
    console.log('trying to use video room server from the database' + video_room_server)
    if (video_room_server) {
      const serverURL = video_room_server
      return serverURL
    } else {
      throw new Error('missing in database')
    }
  } catch (e) {
    return 'https://sf1.audiopostr.com/janus'
  }
}

const getIceServers = (video_room_turnstun_servers) => {
  const iceServers = safeJsonParse(video_room_turnstun_servers)
  console.log('read these Ice servers from the database: ', iceServers)
  const selectedIceServers = iceServers !== undefined ? iceServers : Constants.ICE_SERVERS
  console.log('Using specific ICE Servers: ', selectedIceServers)
  return selectedIceServers
}

const getIceTransportPolicy = (iceTransportMethods) => {
  // if we set this to 'relay' in the db we will force use the turn server
  console.log('Using ICE transport method', iceTransportMethods)
  return iceTransportMethods || 'all'
}





const attachJanus = (janus, uid, callbacks) => {
  janus.attach(
    {
      plugin: 'janus.plugin.videoroom',
      opaqueId: `Spotterfish-${ uid }-${JanusClient.randomString(12)}`,

      success: async function (pluginHandle) {
        // console.log('Successfully attached video room plugin')
        // console.log('Plugin attached! ('+pluginHandle.getPlugin() +', id=' +pluginHandle.getId() +')')
        callbacks.onSuccess(pluginHandle)
        // console.log('  -- This is a publisher/manager')
      },

      error: function (error) {
        console.error('Error when attaching video room plugin: ' + error)
        callbacks.onCriticalError(error)
      },

      mediaState: function (on, medium) {
        console.log(
          'Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium
        )
        callbacks.onMediaStateChanged(on, medium)
      },
      
      webrtcState: function (on) {
        console.log(`Janus says our WebRTC PeerConnection is ${on ? 'up' : 'down'} now`)
        callbacks.onWebRTCStateChanged(on, undefined)
        if (!on) {
          console.error('User lost peer connection in room')
        }
      },

      onmessage: callbacks.onMessage,
      onlocalstream: callbacks.onLocalStream,
      oncleanup: callbacks.onCleanUp
    }
  )
}


const startJanus = (init, callbacks) => {
  const iceServers = getIceServers(init.video_room_turnstun_servers)
  const videoRoomServer = getVideoRoomServer(init.video_room_server)
  console.log('using video room server', videoRoomServer)
  const iceTransportPolicy = getIceTransportPolicy(init.iceTransportMethods)
  
  // @ts-ignore
  JanusClient.webRTCAdapter = adapter
  let janus = new JanusClient(
    {
      server: videoRoomServer,
      iceServers,
      iceTransportPolicy,
      success: function () {
        attachJanus(janus, init.currentUser.uid, callbacks)
      },
      error: function (error) {
        console.error('Error when starting Janus: ' + error)
        const _error = new MixStageJanusErrors.JanusInitializationError('Error when starting Janus: ' + error);
        callbacks.onError(_error)
      },
      destroyed: function () {
        console.log('Janus connection destroyed')
      },
    }
  )
  return janus
}

const init2 = async (init, callbacks) => {
  return new Promise(
    (resolve, reject) => {
      JanusClient.init(
        {
          debug: false, //isDevelopment(), // 'all' in demo

          // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
          dependencies: JanusClient.useDefaultDependencies(),

          callback: (e) => {
            if (!JanusClient.isWebrtcSupported()) {
              console.error('WebRTC is not supported.', e)
              const _error = new MixStageJanusErrors.WebRTCNotSupportedError('WebRTC is not supported.');
              reject(_error)
            }
            else {
              const janus = startJanus(init, callbacks)
              resolve(janus)
            }
          },
        }
      )
    }
  )
}

//  Returns { key: janusid, value: firebase user ID }
const readJanusUserMap = async (pluginHandle, room, pin) => {
  assert(pluginHandle !== undefined)
  assert(_.isString(room))
  assert(_.isString(pin))

  return new Promise((resolve, reject) => {
    var listparticipantsMessage = { request: 'listparticipants', room: room, pin: pin }
    pluginHandle.send(
      {
        message: listparticipantsMessage,
        success: (resp) => {
          try {
            let result

            const numberOfConnectedUsers = resp?.participants ? resp.participants.length : 0
            if (numberOfConnectedUsers > 0) {
              // Add all current participants to our janusId/userId map, replacing the
              // entire object to make sure we're in sync.
              result = resp.participants.reduce((acc, curr) => {
                const parsedDisplay = safeJsonParse(curr.display)
                const uid = parsedDisplay ? parsedDisplay.uid : undefined
                return { ...acc, [curr.id]: uid }
              }, {})
            }
            resolve(result)
          } catch (error) {
            reject(error)
          }
        },
        error: function (err) {
          const error_ = new MixStageJanusErrors.JanusListParticipantsError('Error when listing participants: ' + JSON.stringify(err))
          reject(error_)
        },
      }
    )
  })
};


//  This is constructor that returns a Janus client.
//  Member functions uses local vairables of VideoChatSession() as state == huge scope.
const VideoChatSession = (initData0) => {
  assert(checkInvariantVideoChatData(initData0))


  ////////////////////////////////    WORKS AS PRIVATE MEMBER VARIABLES
  let janus_
  let pluginHandle_
  let screensharingPluginHandle_
  let janusUserMap_ = {}
  let private_id_

  const userMap_ = UserMap()
  const videoCallbacks_ = VideoRoomCallbacks()

  const init_ = initData0

  let report_
  let severityLevel_ = 0
  let droppedOffset_ = 0
  let statsInterval_ 
  let keepAliveIntervals_ = []
  
  const keepAlive = (videoRoomHandle) => {
    if(videoRoomHandle) {
      videoRoomHandle.send({ "message": { "request": "keepalive" } })
    }
    keepAliveIntervals_.push(setTimeout(keepAlive, 30000))
  }

  const stopKeepAlive = () => {
    keepAliveIntervals_.forEach(a => clearInterval(a))
  }

  const bitrateDependingOnRoomSize = () => {
    const numSeats = init_.roomSize
    if (numSeats > 10) {
      return 256000 // 64000 is The minimum chrome exepts, but that is too low
    } else if (numSeats > 5) {
      return 512000
    } else {
      return 1024000
    }
  }

  // *********** Re-connection logic ************

  const handleReconnection = async () => {
    console.log('Handling reconnection...');
    await reconnectJanusPlugin(pluginHandle_, janus_);
  };

  const reconnectJanusPlugin = async (pluginHandle, janus) => {
    try {
      await janus.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: pluginHandle.opaqueId,
        success: (newPluginHandle) => {
          console.log('Successfully reattached plugin');
          pluginHandle_ = newPluginHandle; // Update the handle to the new one
          rejoinRoom(newPluginHandle);
          forceICERestart(newPluginHandle);
        },
        error: (error) => {
          console.error('Error reattaching plugin:', error);
        },
        onmessage: pluginHandle.onmessage,
        onlocalstream: pluginHandle.onlocalstream,
        onremotestream: pluginHandle.onremotestream,
        oncleanup: pluginHandle.oncleanup,
      });
    } catch (error) {
      console.error('Failed to reconnect to Janus plugin:', error);
    }
  };
  
  const forceICERestart = (pluginHandle) => {
    const configure = {
      request: 'configure',
      restart: true,
      pin: init_.janusSecret,
      audio: true,
      video: true,
    };
  
    pluginHandle.send({
      message: configure,
      success: (response) => {
        console.log('ICE restart forced:', response)
      },
      error: (error) => {
        console.error('Error forcing ICE restart:', error)
      }
    })
  }  

  const rejoinRoom = (pluginHandle) => {
    const { currentUser, screeningRoomId, janusSecret } = init_;
    
    const register = {
      request: 'join',
      restart: true,
      room: screeningRoomId,
      pin: janusSecret,
      ptype: 'publisher',
      display: JSON.stringify({
        name: currentUser.email,
        uid: currentUser.uid,
      }),
    };
  
    pluginHandle.send({
      message: register,
      success: (response) => {
        console.log('Rejoined the room:', response);
      },
      error: (error) => {
        console.error('Error rejoining the room:', error);
      }
    });
  };

  // *********** END Re-connection logic ************

  
  const makeSureVideoRoomExists = () => {
    return new Promise(async (resolve, reject) => {
      // Parameters for creating the room. Only parameters with non-default arguments
      // are included here, see https://janus.conf.meetecho.com/docs/videoroom.html
      // for full list.
      let createRoomMessage = {
        request: 'create',
        room: init_.screeningRoomId,
        permanent: false,
        pin: init_.janusSecret,
        videocodec: 'vp8,vp9,h264,av1,h265', // vp8|vp9|h264 (video codec to force on publishers, default=vp8) can be a comma separated list in order of preference, e.g., vp9,vp8,h264)
        audiocodec: 'opus,multiopus', // opus|g722|pcmu|pcma|isac32|isac16 (audio codec to force on publishers, default=opus) can be a comma separated list in order of preference, e.g., opus,pcmu)
        description: 'Spotterfish.io',
        secret: init_.janusSecret,
        publishers: 1000, // self.roomFeatureBitsCopy.seats,
        bitrate: bitrateDependingOnRoomSize(),
        fir_freq: 1, // <send a Full Intra Requeast (FIR) to publishers every fir_freq seconds> (0=disable)
        opus_fec: true,
        audiolevel_ext: true,
        audiolevel_event: true,
        audio_active_packets: 50,
        audio_level_average: 60,
        notify_joining: true,
        is_private: false,
      }

      pluginHandle_.send({
        message: createRoomMessage,
        success: function (resp) {
          // Notice, success is called for error {"videoroom":"event","error_code":427,"error":"Room 2 already exists"}
          if (resp.error_code) {
            if (resp.error_code === 427) {
              resolve(undefined)
            } else {
              const _error = new MixStageJanusErrors.JanusRoomError('Error when creating room: ' + resp.error);
              reject(_error);
            }
          } else {
            if (resp.videoroom === 'created' && resp.room === init_.screeningRoomId) {
              resolve(undefined)
            } else {
              const _error = new MixStageJanusErrors.UnknownJanusError('Unexpected response when creating room.');
              reject(_error);
            }
          }
        },
        error: function (err) {
          const _error = new MixStageJanusErrors.JanusRoomError('Error when creating room: ' + err);
          console.error(_error);
          reject(_error);
        },
      })
    })
  }

  const updateNumberOfPresenters = async () => {
    return new Promise((resolve, reject) => {
      const updateMessage = {
        request: 'edit',
        room: init_.screeningRoomId,
        pin: init_.janusSecret,
        secret: init_.janusSecret,
        new_publishers: init_.roomSize * 2,
      }
      pluginHandle_.send({
        message: updateMessage,
        success: function (resp) {
          resolve(resp)
        },
        error: function (err) {
          const error_ = new MixStageJanusErrors.JanusUpdatePresentersError('Error when updating number of presenters: ' + err)
          reject(error_)
        },
      })
    })
  }

  const registerUsername = (succCB, errCB) => {
    const {email, uid} = init_.currentUser
    
    var register = {
      request: 'join',
      room: init_.screeningRoomId,
      pin: init_.janusSecret,
      ptype: 'publisher',
      display: JSON.stringify({
        name: email,
        uid,
      }),
    }
    pluginHandle_.send({
      message: register,
      success(resp) {
        succCB(resp)
      },
      error(err) {
        errCB(err)
      },
    })
  }

  const destroyRoom = () => {
    return new Promise(async (resolve, reject) => {
      var destroyRoomMessage = {
        request: 'destroy',
        room: init_.screeningRoomId,
        pin: init_.janusSecret,
        secret: init_.janusSecret,
        permanent: false,
      }
      pluginHandle_?.send({
        message: destroyRoomMessage,
        success: function (resp) {
          resolve(resp)
        },
        error: function (err) {
          reject(err)
        },
      })
    })
  }

  const checkIfRoomExists = () => {
    return new Promise((resolve, reject) => {
      var checkRoomMessage = {
        request: 'exists',
        room: init_.screeningRoomId,
        pin: init_.janusSecret,
      }
      pluginHandle_.send({
        message: checkRoomMessage,
        success(resp) {
          console.log(
            'Successfully checked if room exists: ' + JSON.stringify(resp)
          )
          resolve(resp)
        },
        error(err) {
          // Expected to throw, create a new room if not
          const error_ = new MixStageJanusErrors.JanusRoomError('Expected Error when checking if room exists:' + err)
          console.error(error_)
          reject(error_)
        },
      })
    })
  }
  
  const leaveVideoRoom = async () => {
    if (!pluginHandle_) {
      console.debug(
        'Janus instance no longer exists, skipping leave video room'
      )
      return
    }
    return new Promise((resolve, reject) => {
      var leaveMessage = {
        request: 'leave',
        pin: init_.janusSecret,
      }
      pluginHandle_.send({
        message: leaveMessage,
        success(resp) {
          const {uid} = init_.currentUser
          console.log('User successfully left video room: ', {uid})
          userMap_.removeUser(uid)
          stopKeepAlive()
          // userMap_ = removeFromUserMapByFirebaseId(userMap_, uid)
          resolve(resp)
        },
        error(err) {
          const error_ = new MixStageJanusErrors.JanusParticipantError('Error when leaving video room: ' + err)
          janus_.destroy({
            success: () => {
              console.log('Successfully destroyed after leave error')
            }
          })
          videoCallbacks_.getCallback('onError')(error_)
        },
      })
    })
  }

  const getLocalStreams = async (video = true, audio = true) => {
    const { uid } = init_.currentUser;
  
    const selectedAudioInput = localStorage.getItem('spotterfishUserAudioIn') || undefined;
    const selectedVideoInput = localStorage.getItem('spotterfishUserVideoIn') || undefined;
    const echoCancellation = localStorage.getItem('spotterfishUserEchoCancellation') !== 'false';
    const audioSource = selectedAudioInput;
    const videoSource = selectedVideoInput;
  
    console.log(echoCancellation);
  
    const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  
    let audioConstraints = audio ? {
      deviceId: audioSource ? { exact: audioSource } : undefined,
      autoGainControl: supportedConstraints.autoGainControl ? false : undefined,
      echoCancellation: supportedConstraints.echoCancellation ? echoCancellation : undefined,
      noiseSuppression: supportedConstraints.noiseSuppression ? true : undefined,
    } : false;
  
    let videoConstraints = video ? {
      deviceId: videoSource ? { exact: videoSource } : undefined,
    } : false;
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: audioConstraints, video: videoConstraints });
      if (audio) userMap_.updateUser(uid, { audioStream: stream });
      if (video) userMap_.updateUser(uid, { videoStream: stream });
      return stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
  
      // Fallback logic if either audio or video fails
      let fallbackAudioStream = null;
      let fallbackVideoStream = null;
  
      if (audio) {
        try {
          fallbackAudioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          userMap_.updateUser(uid, { audioStream: fallbackAudioStream });
        } catch (audioError) {
          console.error('Fallback audio error:', audioError);
        }
      }
  
      if (video) {
        try {
          fallbackVideoStream = await navigator.mediaDevices.getUserMedia({ video: true });
          userMap_.updateUser(uid, { videoStream: fallbackVideoStream });
        } catch (videoError) {
          console.error('Fallback video error:', videoError);
        }
      }
  
      if (!fallbackAudioStream && !fallbackVideoStream) {
        console.warn('No media streams available. Starting session without media.');
        return new MediaStream(); // Return an empty MediaStream to allow the session to start
      }
  
      const combinedStream = new MediaStream();
      if (fallbackAudioStream) {
        fallbackAudioStream.getAudioTracks().forEach(track => combinedStream.addTrack(track));
      }
      if (fallbackVideoStream) {
        fallbackVideoStream.getVideoTracks().forEach(track => combinedStream.addTrack(track));
      }
  
      return combinedStream;
    }
  }
      
  
  const publishOwnFeed = async (useAudio, useVideo, onPublished = () => {}) => {
    // Publish our stream
    const stream = await getLocalStreams(useVideo, useAudio);

    pluginHandle_.createOffer({
      // Add data:true here if you want to publish datachannels as well
      stream: stream,
      // media: {
      //   audioRecv: false,
      //   videoRecv: false,
      //   audioSend: useAudio,
      //   videoSend: useVideo,
      //   data: true,
      //   audio: {
      //     deviceId: {
      //       exact: audioSource,
      //     },
      //   },
      //   video: {
      //     deviceId: {
      //       exact: videoSource,
      //     },
      //   },
      // }, 
      // Publishers are sendonly
      // If you want to test simulcasting (Chrome and Firefox only), then
      // pass a ?simulcast=true when opening this demo page: it will turn
      // the following 'simulcast' property to pass to janus.js to true
      simulcast: false,
      success: function (jsep) {
        console.debug('Got publisher SDP!')
        console.debug(jsep)

        // Set audio/video to unmute at first to prevent feed to appear in other chats before we are in the room
        var publish = {
          request: 'configure',
          pin: init_.janusSecret,
          audio: useAudio,
          video: useVideo,
        }


        if(typeof onPublished === 'function') onPublished()
        // You can force a specific codec to use when publishing by using the
        // audiocodec and videocodec properties, for instance:
        //     publish['audiocodec'] = 'opus'
        // to force Opus as the audio codec to use, or:
        //     publish['videocodec'] = 'vp9'
        // to force VP9 as the videocodec to use. In both case, though, forcing
        // a codec will only work if: (1) the codec is actually in the SDP (and
        // so the browser supports it), and (2) the codec is in the list of
        // allowed codecs in a room. With respect to the point (2) above,
        // refer to the text in janus.plugin.videoroom.cfg for more details
        pluginHandle_.send({
          message: publish,
          jsep: jsep,
        })
      },
      error: async function (error) {
        console.error('WebRTC error: ' + JSON.stringify(error))
        const audioStream = await getLocalStreams(false, true);

        // Trying to publish just audio
        pluginHandle_.createOffer({
          // Add data:true here if you want to publish datachannels as well
          stream: audioStream,
          // media: {
          //   audioRecv: false,
          //   videoRecv: false,
          //   audioSend: useAudio,
          //   videoSend: false,
          //   data: true,
          //   audio: {
          //     deviceId: {
          //       exact: audioSource,
          //     },
          //   },
          // }, 
          // Publishers are sendonly
          // If you want to test simulcasting (Chrome and Firefox only), then
          // pass a ?simulcast=true when opening this demo page: it will turn
          // the following 'simulcast' property to pass to janus.js to true
          simulcast: false,
          success: function (jsep) {
            console.debug('Got publisher SDP!')
            console.debug(jsep)
            var publish = {
              request: 'configure',
              pin: init_.janusSecret,
              audio: useAudio,
              video: false,
            }
            // You can force a specific codec to use when publishing by using the
            // audiocodec and videocodec properties, for instance:
            //     publish['audiocodec'] = 'opus'
            // to force Opus as the audio codec to use, or:
            //     publish['videocodec'] = 'vp9'
            // to force VP9 as the videocodec to use. In both case, though, forcing
            // a codec will only work if: (1) the codec is actually in the SDP (and
            // so the browser supports it), and (2) the codec is in the list of
            // allowed codecs in a room. With respect to the point (2) above,
            // refer to the text in janus.plugin.videoroom.cfg for more details
            pluginHandle_.send({
              message: publish,
              jsep: jsep,
            })
          },
          error: function (error) {
            console.log(error)
            alert('no audio, no video')
          },
        })
      },
    })
  }

  const removeSpotterfishTCfromChunk = (chunk, tcBytelenght) => {
    // Remove Time code from stream
    if (chunk.data.byteLength > tcBytelenght) {
      chunk.data = chunk.data.slice(
        0,
        chunk.data.byteLength -
        tcBytelenght
      )
    }
  }

  const getConnectionStats = (remoteFeed) => {
    let report
    if(remoteFeed.webrtcStuff.pc.getStats) {
      return remoteFeed.webrtcStuff.pc.getStats(null).then((stats) => {
        stats.forEach((report0) => {
          if (report0.type === 'inbound-rtp' && report0.kind === 'audio') {
            report = report0
          }
        })
        return report
      })
    }
  }


  const resetDroppedOffset = () => {
    console.warn(report_)
    Sentry.captureEvent(report_)
    droppedOffset_ = report_.packetsLost
  }


  const packetDropHandler = (report) => {
    const lossPercent = ((report.packetsLost - droppedOffset_) / report.packetsReceived) * 100
    
    if(lossPercent < 2 && severityLevel_ !== 0){
      severityLevel_ = 0
      console.log('NEW SEVERITY LVL 0')
      videoCallbacks_.getCallback('dawstreamPacketDrop')(0)
    } else if(_.inRange(lossPercent, 2, 5) && severityLevel_ !== 1){
      severityLevel_ = 1
      console.log('NEW SEVERITY LVL 1')
      videoCallbacks_.getCallback('dawstreamPacketDrop')(1)
    } else if(lossPercent > 5 && severityLevel_ !== 2) {
      severityLevel_ = 2
      console.log('NEW SEVERITY LVL 2')
      videoCallbacks_.getCallback('dawstreamPacketDrop')(2)
    }
    return lossPercent
  }

  // OBS stream Left, right, center, left surround, right surrond and LFE
  // left - center -right -right surround left -surround
  // OBS stream channel mapping : channel_mapping=0,1,4,3,2,5
  // OUR channel mapping channel_mapping=0,4,1,5,3,5 ITU-R BS.2051-4???
  // OBS strean 7.1 channel mapping left 0 center 2 right 1 ls rs rsl rsr lfe
  // https://gitlab.freedesktop.org/gstreamer/gst-plugins-base/-/issues/770
  //https://github.com/node-webrtc/node-webrtc/issues/603

  const openWhipStream = async (remoteFeed, jsep, displayData) => {
    console.log(displayData)
    const format = displayData.format

    console.log('[[WhipStream]] sdp', jsep.sdp)

    const tcBytelenght = Constants.SPOTTERFISH_TIMECODE_BYTELENGTH
    let jitterbufferOffset = 0

    // const FIVE_DOT_ONE_SDP_STRINGS_FILM_C24 = ['multiopus/48000/6', 'channel_mapping=0,1,4,3,2,5;num_streams=4;coupled_streams=2']
    const FIVE_DOT_ONE_SDP_STRINGS_SMPTE_ITU = ['multiopus/48000/6', 'channel_mapping=0,1,4,5,2,3;num_streams=4;coupled_streams=2']
    const SEVEN_DOT_ONE_SDP_STRINGS = [
      'multiopus/48000/8',  // 8 channels for 7.1 setup
      'channel_mapping=0,6,1,2,3,4,5,7;num_streams=5;coupled_streams=3'  // Updated channel mapping and stream counts  
    ];
    const STEREO_SDP_STRINGS = [
      'opus/48000/2',
      'stereo=1; sprop-stereo=1;'
    ]
    const ATMOS_SDP_STRINGS = [
      //TBA
    ]
    
    const usedFormat = format === '5.1' ? FIVE_DOT_ONE_SDP_STRINGS_SMPTE_ITU : format === '7.1' ? SEVEN_DOT_ONE_SDP_STRINGS : STEREO_SDP_STRINGS

    console.log('[[WhipStream]] used format', usedFormat)

    remoteFeed.createAnswer({
      jsep: jsep,
      media: { audioSend: false, videoSend: false, data: true },
      customizeSdp(jsep) {
        // Offer stereo or multiopus
        jsep.sdp = jsep.sdp
        .replace('opus/48000/2', usedFormat[0])
        .replace(
          'useinbandfec=1',
          `useinbandfec=1;${usedFormat[1]};` //524288
        )
      },      
      success: function (jsep) {
        console.log('[[WhipStream]] final sdp', jsep.sdp)
        var body = { request: 'start', room: init_.screeningRoomId }
        remoteFeed.send({ message: body, jsep: jsep })

      },
      error: function (error) {
        const error_ = new MixStageJanusErrors.JanusDAWStreamError('[[dawstreamStream]] WebRTC error:' + error)
        init_.onCriticalError(error_)
      }
    })
  }




  const openLiveDAWStream = async (remoteFeed, jsep) => {
    const streamingUser = safeJsonParse(remoteFeed.rfdisplay)
    // for calculation of jitterbuffer
    let previousReport = {
      timestamp: 0
    }

    const tcBytelenght = Constants.SPOTTERFISH_TIMECODE_BYTELENGTH
    let jitterbufferOffset = 0
    

    remoteFeed.createAnswer({
      jsep: jsep,
      media: { audioSend: false, videoSend: false, data: true },
      customizeSdp(jsep) {
        const opusPayload = DawStreamerUtils.getPayloadFromSdp(jsep.sdp, 'opus')
        if (opusPayload) {
          let sdpLines = jsep.sdp.split('\n')
          let modified = false
      
          for (let i = 0; i < sdpLines.length; i++) {
            if (sdpLines[i].includes(`a=rtpmap:${opusPayload} opus/48000/2`)) {
              let fmtpLine = `a=fmtp:${opusPayload} minptime=${Constants.SDP_SETTINGS.MIN_PACKAGE_TIME};ptime=${Constants.SDP_SETTINGS.PACKAGE_TIME};stereo=1; sprop-stereo=1; maxaveragebitrate=${Constants.SDP_SETTINGS.MAX_AVERAGE_BITRATE};maxplaybackrate=${Constants.SDP_SETTINGS.MAX_SAMPLE_RATE}`
              sdpLines.splice(i + 1, 0, fmtpLine)
              modified = true
              break
            }
          }
      
          if (modified) {
            jsep.sdp = sdpLines.join('\n');
          }
        }
      },
      
      success: function (jsep) {
        console.log('[[dawstreamStream]] final sdp', jsep.sdp)
        var body = { request: 'start', room: init_.screeningRoomId }
        remoteFeed.send({ message: body, jsep: jsep })

      },
      error: function (error) {
        const error_ = new MixStageJanusErrors.JanusDAWStreamError('[[dawstreamStream]] WebRTC error:' + error)
        init_.onCriticalError(error_)
      },
      receiverTransforms: {
        // Do not know why webpack thinks this is missing, but this fixes it.
        // eslint-disable-next-line
        audio: new TransformStream({
          start() {
            console.log('[[dawstreamStream]] started receiving a stream')
          },
          flush() {
          },
          transform: (chunk, controller) => {

            // Check chunk size
            if (chunk.data.byteLength > 1000) {
              console.warn('My be dropping CHUNK - WARNING: Update Chrome to newest version');
            } 

            // Get connection stats and process chunk
            // 2024-02-05 We are checking stats on each package, but not awaiting the result.
            getConnectionStats(remoteFeed).then((report) => {
              report_ = report;
              if (report['timestamp'] !== previousReport['timestamp']) {
                previousReport = _.cloneDeep(report);
                jitterbufferOffset = (report['jitterBufferDelay']) / (report['totalSamplesReceived'])
              }
            }).catch(error => {
              console.log(error)
            })
            // Removed jitterbufferoffset
            videoCallbacks_.getCallback('onDawStreamingChunk')(chunk, streamingUser, 0, chunk.timestamp)
            removeSpotterfishTCfromChunk(chunk, tcBytelenght)
            controller.enqueue(chunk)

          }
        },
        {
          // 2024-02-05 We are handling the backpressure by setting the highwatermark to approx one chunk.
          highWaterMark: 1
        }
        ),
      },
    })
  }

  const reconnectJanus = async () => {
    console.log('RECONNECT JANUS')
    await janus_.destroy()
    await init2(init_, videoCallbacks_)
  }

  const receiveSpotterfishAVFeed = (remoteFeed, jsep) => {
    remoteFeed.createAnswer({
      jsep,
      // Add data:true here if you want to subscribe to datachannels as well
      // (obviously only works if the publisher offered them in the first place)
      media: {
        audioSend: false,
        videoSend: false,
        data: true,
      },

      success: (jsep) => {
        console.debug('Got SDP!')
        console.debug(jsep)
        console.log('[[spotterfishAVfeed]] final sdp', jsep.sdp)

        var body = {
          request: 'start',
          room: init_.screeningRoomId,
          pin: init_.janusSecret,
        }
        remoteFeed.send({
          message: body,
          jsep: jsep,
        })
      },

      error: function (error) {
        console.error('WebRTC error: ' + JSON.stringify(error))
      },
    })
  }


  const newRemoteFeed = async (id, display, audio, video) => {
    // A new feed has been published, create a new plugin handle and attach to it as a listener
    console.log('NEW REMOTE FEED', id, display)

    const parsedDisplay = safeJsonParse(display)

  
    //??? hack, smells we have life-time problems.
    if (!pluginHandle_) {
      console.debug('Janus instance no longer exists, skipping number of connected users')
    }
    else {
      janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)
    }


    var remoteFeed = null
    // if(isScreensharing && JSON.parse(display).uid === init_.currentUser.uid) {
    //   console.warn('Do not share to self', init_.currentUser.uid)
    //   janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)
    //   console.log(janusUserMap_)
    //   return
    // }
    // BEFORE: when attaching to remote feed the opqueid will be the same for all feeds from a user
    janus_.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: `${parsedDisplay?.uid}-${JanusClient.randomString(12)}`,
      success: function (pluginHandle) {
        remoteFeed = pluginHandle
        remoteFeed.simulcastStarted = false
        // console.log(`Plugin attached (${remoteFeed.getPlugin()}, id=${remoteFeed.getId()})`)
        // console.log('  -- This is a subscriber')
        // We wait for the plugin to send us an offer
        var listen = {
          request: 'join',
          room: init_.screeningRoomId,
          pin: init_.janusSecret,
          ptype: 'subscriber',
          feed: id,
          private_id: private_id_,
        }

        remoteFeed.send({
          message: listen,
        })
      },

      error: function (error) {
        console.error(`Error when attaching Janus video room plugin (${error})`)
      },

      onmessage: async function (msg, jsep) {
        const { videoroom, id, error, display, substream, temporal, room, started } = msg
        var event = videoroom
        console.log('===> msg, jsep', msg, jsep)
        if (error !== undefined && error !== null) {
          console.error(error)
        } else if (event !== undefined && event !== null) {
          
          const parsedDisplay = safeJsonParse(display)

          if (event === 'attached') {
            const remoteUser = parsedDisplay
            
            remoteFeed.rfid = id
            remoteFeed.rfdisplay = display

            // Adduser is safe, it returns if user already exists
            userMap_.addUser(
              remoteUser.uid,
              {
                feed: remoteFeed,
                dawStreamerRfid: remoteUser.dawstream === true ? remoteFeed.rfid : undefined,
                screenshareRfid: remoteUser.screensharing ? remoteFeed.rfid : undefined,
                whipStreamRfid: remoteUser.whipstream === true ? remoteFeed.rfid : undefined,
              }
            )

            if(remoteUser.dawstream && userMap_.getUser(remoteUser.uid)){
              userMap_.updateUser(remoteUser.uid, {
                dawStreamerRfid: remoteFeed.rfid,
              })
            }
            if(remoteUser.screensharing && userMap_.getUser(remoteUser.uid)){
              console.log('===> SCREENSHARING FEED!', remoteFeed)
              userMap_.updateUser(remoteUser.uid, {
                screenshareRfid: remoteFeed.rfid,
              })
            }
            if(remoteUser.whipstream && userMap_.getUser(remoteUser.uid)){
              console.log('===> WHIPSTREAM FEED!', remoteFeed)
              // Call back to main with this feed as current whipstream
              userMap_.updateUser(remoteUser.uid, {
                whipStreamRfid: remoteFeed.rfid,
              })
            }

            console.log('Successfully attached to feed ' + remoteFeed.rfid + ' (' + remoteFeed.rfdisplay + ') in room ' + room, msg)

            if (!pluginHandle_) {
              console.debug('Janus instance do not exists, skipping number of connected users')
            }
            else {
              janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)
            }

            videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
          }


          else if (event === 'event') {
            // Check if we got an event on a simulcast-related event from this publisher
            if (
              (substream !== null && substream !== undefined) ||
              (temporal !== null && temporal !== undefined)
            ) {
              if (!remoteFeed.simulcastStarted) {
                remoteFeed.simulcastStarted = true
              }
            }
          }
        }

        if (jsep !== undefined && jsep !== null) {
          console.debug('Handling remote SDP', jsep.sdp)
          // Check if this stream is expected to be a Spotterfish Encoded Live Stream
          const parsedDisplay = safeJsonParse(display)
          if (parsedDisplay?.dawstream) 
          {
            openLiveDAWStream(remoteFeed, jsep)
          } 
          else if (parsedDisplay?.whipstream) 
          {
            openWhipStream(remoteFeed, jsep, parsedDisplay)
          }
          else {
            // Normal voice chat, whip stream or screensharing      
            receiveSpotterfishAVFeed(remoteFeed, jsep)
          }
        }
      },

      ondataopen: async () => {
        // Data channel is open
      },

      /*
      new
        The ICE agent is gathering addresses or is waiting to be given remote candidates through calls to RTCPeerConnection.addIceCandidate() (or both).

      checking
        The ICE agent has been given one or more remote candidates and is checking pairs of local and remote candidates against one another to try to find a compatible match, but has not yet found a pair which will allow the peer connection to be made. It is possible that gathering of candidates is also still underway.

      connected
        A usable pairing of local and remote candidates has been found for all components of the connection, and the connection has been established. It is possible that gathering is still underway, and it is also possible that the ICE agent is still checking candidates against one another looking for a better connection to use.

      completed
        The ICE agent has finished gathering candidates, has checked all pairs against one another, and has found a connection for all components.

      failed
        The ICE candidate has checked all candidates pairs against one another and has failed to find compatible matches for all components of the connection. It is, however, possible that the ICE agent did find compatible connections for some components.

      disconnected
        Checks to ensure that components are still connected failed for at least one component of the RTCPeerConnection. This is a less stringent test than failed and may trigger intermittently and resolve just as spontaneously on less reliable networks, or during temporary disconnections. When the problem resolves, the connection may return to the connected state.

      closed
        the ICE agent for this RTCPeerConnection has shut down and is no longer handling requests.
      */

      iceState: function(state) {
        console.log('**************************')
        console.log("ICE state changed to " + state);

        if (state === 'failed' || state === 'disconnected' || state === 'closed') {
          const error_ = new MixStageJanusErrors.IceConnectionError('ICE failed: ' + state)
          videoCallbacks_.getCallback('onError')(error_)
        }
      },

      slowLink: function(uplink, lost, mid) {
        console.warn("Video chat reports problems " + (uplink ? "sending" : "receiving") +
          " packets on mid " + mid + " (" + lost + " lost packets)");
      },

      webrtcState: function (on) {
        if(on){
          const parsedDisplay = safeJsonParse(display)
          if (parsedDisplay) {
            videoCallbacks_.getCallback('onRemoteFeed')(parsedDisplay, remoteFeed.rfid)
          }
        }
        console.log('Janus says this WebRTC PeerConnection (feed #' + remoteFeed.rfid + ') is ' + (on ? 'up' : 'down') + ' now')
      },
      /*
        new
          The ICE agent is gathering addresses or is waiting to be given remote candidates through calls to RTCPeerConnection.addIceCandidate() (or both).

        checking
          The ICE agent has been given one or more remote candidates and is checking pairs of local and remote candidates against one another to try to find a compatible match, but has not yet found a pair which will allow the peer connection to be made. It is possible that gathering of candidates is also still underway.

        connected
          A usable pairing of local and remote candidates has been found for all components of the connection, and the connection has been established. It is possible that gathering is still underway, and it is also possible that the ICE agent is still checking candidates against one another looking for a better connection to use.

        completed
          The ICE agent has finished gathering candidates, has checked all pairs against one another, and has found a connection for all components.

        failed
          The ICE candidate has checked all candidates pairs against one another and has failed to find compatible matches for all components of the connection. It is, however, possible that the ICE agent did find compatible connections for some components.

        disconnected
          Checks to ensure that components are still connected failed for at least one component of the RTCPeerConnection. This is a less stringent test than failed and may trigger intermittently and resolve just as spontaneously on less reliable networks, or during temporary disconnections. When the problem resolves, the connection may return to the connected state.

        closed
          the ICE agent for this RTCPeerConnection has shut down and is no longer handling requests.
      */
  
      onremotestream: function (stream) {
        // Important to note is that this event will fire twice for every remote
        // stream, once for video and once for audio, but both events will point at
        // the same MediaStream object. This is a bit confusing, but by design:
        //
        //   https://github.com/meetecho/janus-gateway/issues/1313
        //
        // Just make sure the code here can handle this situation.
        // Answers from Chris G,
        // **Data packets:
        // **the packets within a DataChannel can be configured. But the default is that they will be in order and the browser retries forever.

        // **cool. what about audio packets?
        // **
        // No, those get happily dropped.
        // **can they arrive out of order?
        // **No, I'm pretty sure that everything that comes too late is ignored.
        // Or in other words there can be drop outs but no reorder of the audio.
        // But there are all sorts of "dirty" tricks which get used to make it sound "better". There is for example comfort noise which gets added when the audio has a drop off.
        // And the audio sometimes alters the playbackrate.
        try {
          console.log('NEW REMOTE STREAM:', remoteFeed)
          console.log(`Got a new remote stream with id ${remoteFeed.rfid}`)
          const remoteUser = safeJsonParse(remoteFeed.rfdisplay)

          if (remoteUser === undefined) {
            throw new Error('User is undefined')
          }

          const isDawstreamingStream = remoteUser.dawstream
          const isScreenSharingStream = remoteUser.screensharing
          const isWhipStreamingStream = remoteUser.whipstream

          if(!userMap_.getUser(remoteUser.uid)){
            userMap_.addUser()
          }
          if (isDawstreamingStream && userMap_.getUser(remoteUser.uid).dawStreamerRfid === remoteFeed.rfid) {
            userMap_.updateUser(remoteUser.uid, { dawStream: stream })
          } 
          else if (isScreenSharingStream && userMap_.getUser(remoteUser.uid).screenshareRfid === remoteFeed.rfid) {
            userMap_.updateUser(remoteUser.uid, { screenshare: stream })
            console.log(userMap_.getUser(remoteUser.uid))
          }
          else if (isWhipStreamingStream && userMap_.getUser(remoteUser.uid).whipStreamRfid === remoteFeed.rfid) {
            userMap_.updateUser(remoteUser.uid, { whipStream: stream })
            console.log(userMap_.getUser(remoteUser.uid))
            videoCallbacks_.getCallback('onWhipStream')(userMap_.getUsers())
          } else {

            const hasAudio = stream.getAudioTracks().length > 0;
            const hasVideo = stream.getVideoTracks().length > 0;
        
            if (hasAudio) {
              userMap_.updateUser(remoteUser.uid, { audioStream: stream });
            }
        
            if (hasVideo) {
              userMap_.updateUser(remoteUser.uid, { videoStream: stream });
            }
          }
          videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
        } catch (error) {
          console.error(error)
        }
      },

      oncleanup: function () {
        const remoteUser = safeJsonParse(remoteFeed.rfdisplay)

        if (remoteUser?.dawstream || remoteUser?.screensharing) {
          return
        }
        console.debug(
          `Cleaning up after user ${remoteUser?.uid} (${remoteUser?.name})`
        )

        if (remoteUser) {
          userMap_.removeUser(remoteUser.uid)
        }
        videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
      },

      ondata: (e) => {
        const messageData = safeJsonParse(e)
        const message = safeJsonParse(messageData.text)
        if (message.type === 'sync') {
          videoCallbacks_.getCallback('onDataChannelSyncMessage')(message)
        }
        else {
          videoCallbacks_.getCallback('onDataChannelMessage')(message)
        }
      },
    })
  }


  const attachPublishers = (list) => {
    // Check message for new publishers and attach them. This is used in several
    // places below and this function is just to consolidate the functionality.

    for (let f in list) {
      let id = list[f]['id']
      let display = list[f]['display']
      let audio = list[f]['audio_codec']
      let video = list[f]['video_codec']

      console.log(`Adding remote feed [${id}] ${display} (audio: ${audio}, video: ${video})`)
      newRemoteFeed(id, display, audio, video)
      videoCallbacks_.getCallback('onUserJoined')(safeJsonParse(display))
    }
  }

  const publishOwnFeedFirstTime = (useAudio, useVideo, isAudienceRoom) => {
    publishOwnFeed(useAudio, useVideo, async () => {
      console.log('====> PUBLISH OWN FEED, FIRST TIME')
      setVideoMute(true)
      setAudioMute(true)

      if (isAudienceRoom && !useAudio && !useVideo) {
        console.log('This is an audience room, not trying to await onfirstPublishOwnFeed')
        return
      }
      try {
        const cb = await videoCallbacks_.awaitCallback('onFirstPublishOwnFeed', () => {
          Sentry.captureEvent({
            message: `User could not connect to video chat, timed out. UserId: ${init_.currentUser.uid}`,
          })
          videoCallbacks_.getCallback('onError')(new Error('Init timed out'))
        })
        if(typeof cb === 'function'){
          cb()
        }
      } catch (error) {
        console.error('Could not publish own feed')
        videoCallbacks_.getCallback('onError')(new Error('Could not publish own feed'))
      }
    })
  }

  const handleJanusMessage = async (msg, jsep) => {
    console.log('===> JANUS MESSAGE', msg, jsep)
    const {videoroom, id, private_id, room, publishers, unpublished, leaving, error, error_code, audio_codec, video_codec} = msg;
    var event = videoroom;

    const { audio: audioAllowed, video: videoAllowed } = await checkAllowedInputs()

    let useAudio = audioAllowed
    let useVideo = videoAllowed

    const handleUserJoined = () => {
      // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
      private_id_ = private_id
      console.log(`Successfully joined room ${room} with id ${id}`);

      if (init_.isAudienceRoom) {
        console.log('this is an audience room. do not publish audio or video')
        useAudio = false
        useVideo = false
      }

      publishOwnFeedFirstTime(useAudio, useVideo, init_.isAudienceRoom)

      if (publishers !== undefined && publishers !== null) {
        attachPublishers(publishers)
      }
    }

    const handleUserIsTalking = () => {
      const userId = janusUserMap_[id]
      if (userId === undefined) {
        console.error(`Got a talking event from a Janus user ${id} that is not in our map`)
      } else {
        const isTalking = event === 'talking'
        if(userMap_.getUser(userId)?.dawStreamerRfid !== id){
          userMap_.updateUser(userId, {
            isTalking
          })
          videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
        }
      }
    }

    const handleScreenshareUnpublish = () => {
      const userLeaving = unpublished
      console.log(`unpublished set to: ${unpublished}`);

      const leavingUserUid = janusUserMap_[userLeaving];
      
      const leavingUser = userMap_.getUser(leavingUserUid)
      console.log('SCREENSHARE UNPUBLISH', userLeaving, leavingUser)
      
      leavingUser.screenshare?.getTracks().forEach(track => {
        track.stop()
      })

      if (leavingUser.screenshareRfid === userLeaving){
        userMap_.updateUser(leavingUserUid, {
          screenshare: undefined,
          screenshareRfid: undefined
        })
        videoCallbacks_.getCallback('screenshareEnded')()
        videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
      }
    }

    const handleUserLeaving = async (userLeaving) => {
      try {
        const leavingUserUid = janusUserMap_[userLeaving]
        const leavingUser = userMap_.getUser(leavingUserUid)
    
        if (!leavingUser) {
          if (userLeaving === 'ok') {
            console.warn('We hang up our own main feed now, the user that is leaving the room is us')
            pluginHandle_.hangup()
    
            const { uid } = init_.currentUser
            userMap_.updateUser(uid, {
              videoStream: undefined,
              audioStream: undefined,
              feed: undefined,
            })
    
            videoCallbacks_.getCallback('onUserLeft')(uid)
            return
          }
          console.warn("Could not find user info for the feed that's leaving: " + userLeaving)
          return
        }
    
        if (userLeaving === leavingUser.feed?.rfid) {
          // This means the main feed is leaving
          const user = safeJsonParse(leavingUser.feed.rfdisplay)
          if (user) {
            const _uid = user.uid
            userMap_.removeUser(_uid)
          }
        } else if (userLeaving === leavingUser.screenshareRfid) {
          // This means the screenshare feed is leaving
          console.warn('We hang up the screenshare feed now')
          screensharingPluginHandle_?.detach()
          userMap_.updateUser(leavingUserUid, {
            screenshareRfid: undefined,
          })
          videoCallbacks_.getCallback('screenshareEnded')()
        } else if (leavingUser.dawStreamerRfid === userLeaving) {
          // This means the DAW streamer feed is leaving
          console.log('DAW Streamer leaving', leavingUser)
          userMap_.updateUser(leavingUserUid, {
            dawStream: undefined,
            dawStreamerRfid: undefined,
          })
          clearInterval(statsInterval_)
          videoCallbacks_.getCallback('dawStreamerLeft')()
        } else if (leavingUser.whipStreamRfid === userLeaving) {
          // This means the whipStream feed is leaving
          console.log('WhipStream leaving', leavingUser)
          userMap_.updateUser(leavingUserUid, {
            whipStream: undefined,
            whipStreamRfid: undefined,
          })
          videoCallbacks_.getCallback('whipStreamLeft')()
        } else {
          // Handle other scenarios
          console.warn("Unrecognized feed type leaving: " + userLeaving)
          leavingUser?.feed?.detach()
          videoCallbacks_.getCallback('onUserLeft')(leavingUserUid)
          userMap_.removeUser(leavingUserUid)
        }
    
        videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
      } catch (error) {
        console.error("Error in handleUserLeaving:", error)
      }
    }
        
      

    const handleJsep = () => {
      console.debug('Handling SDP')
      console.debug(jsep)
      pluginHandle_.handleRemoteJsep({
        jsep,
      })
      const {uid} = init_.currentUser

      const me = userMap_.getUser(uid) // userMap_[uid]

      // Check if any of the media we wanted to publish has
      // been rejected (e.g., wrong or unsupported codec)
      if (me?.audioStream?.getAudioTracks()?.length > 0 && !audio_codec ) {
        // Audio has been rejected
        console.log('Our audio stream has been rejected, viewers will not hear us')
      }
      if ( me?.videoStream?.getVideoTracks()?.length > 0 && !video_codec ) {
        // Video has been rejected
        console.log('Our video stream has been rejected, viewers will not see us')
      }

    }

    if (event !== undefined && event !== null) {
      if (event === 'joined') {
        handleUserJoined()
      }

      else if (event === 'destroyed') {
        // ??? If this happens during a session - how to handle it???
        console.warn('The Video Room has been destroyed.')
      }

      else if (event === 'talking' || event === 'stopped-talking') {
        handleUserIsTalking()
      }

      else if (event === 'event') {
        if (publishers !== undefined && publishers !== null) {
          attachPublishers(publishers)
        }

        else if (leaving !== undefined && leaving !== null) {
          handleUserLeaving()
        }

        else if (unpublished !== undefined && unpublished !== null) {
          handleScreenshareUnpublish()
          clearInterval(statsInterval_)          // handleUserLeaving()
        }

        else if (error !== undefined && error !== null) {
          if (error_code === 426) {
            // This is a 'no such room' error: give a more meaningful description
            console.log(`Apparently room the room does not exist. Please add a new ticket at support.mapletone.com`)
            console.error(error)
          }
          else {
            console.error(error)
          }
        }
      }
    }
    if (jsep !== undefined && jsep !== null) {
      handleJsep()
    }
  }


  const leaveRoomAndDeleteIfEmpty = async () => {
    console.log('Leaving room and will delete if empty')
    return new Promise(async (resolve, reject) => {

      try {

        //??? hack, smells we have life-time problems.
        if (!pluginHandle_) {
          console.debug('Janus instance no longer exists, skipping number of connected users')
        }
        else {
          janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)
        }

        videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
        const length = Object.keys(janusUserMap_).length

        if (length === 0) {
          await leaveVideoRoom()
          await destroyRoom()
          await checkIfRoomExists()
          resolve('Destroyed room')
        } else {
          await leaveVideoRoom()
          resolve('Left room')
        }
        janus_.destroy({
          success: () => {
            console.log('Connection successfully destroyed')
          }
        })
      } catch (error) {
        console.log(error)
        janus_.destroy()
        reject(error)
      }
    })
  }

  const leave = async () => {
    await leaveVideoRoom()
    videoCallbacks_.clearAwaits()
    janus_.destroy()
  }

  const unpublishOwnFeed = () => {
    return new Promise((resolve, reject) => {
      // Unpublish our stream
      var unpublish = {
        request: 'unpublish',
        pin: init_.janusSecret,
      }
      pluginHandle_.send({
        message: unpublish,
        success: (resp) => {
          console.log('Successfully unpublished own feed')
          const {uid} = init_.currentUser
          userMap_.updateUser(uid, {
            videoStream: undefined,
            audioStream: undefined,
          })
          videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
          resolve(resp)
        },
        error: (err) => {
          const error_ = new MixStageJanusErrors.JanusUnpublishFeedError('Error when unpublishing own feed: ' + err)
          reject(error_)
        },
      })
    })
  }

  const toggleVideoForAll = (videoState) => {
    const audioAndVideo = {
      request: 'configure',
      pin: init_.janusSecret,
      audio: true,
      video: videoState,
    }
    pluginHandle_.send({
      message: audioAndVideo,
      success: (resp) => {
        console.log(`Successfully set Janus video to: ${videoState ? 'on' : 'off'}`)
        // We need to check shared state for mute state before deciding
        // self.localVideoTracks[0].enabled = !this.isVideoMuted
      },
      error: (err) => {
        console.error('Error when toggling Janus video: ' + err)
      },
    })
  }

  const setAudioMute = (shouldMute) => {
    console.log(pluginHandle_)
    if(shouldMute){
      pluginHandle_.muteAudio()
    }
    else{
      pluginHandle_.unmuteAudio()
    }
  }
  const setVideoMute = (shouldMute) => {
    if(shouldMute){
      pluginHandle_.muteVideo()
    }
    else{
      pluginHandle_.unmuteVideo()
    }
  }

  const startVideoChat = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const callbacks = {

          onSuccess: async (pluginHandle) => {
            pluginHandle_ = pluginHandle

            try {
              await makeSureVideoRoomExists()

              janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)

              videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
  
              await updateNumberOfPresenters()
              registerUsername(() => {
                resolve(pluginHandle)
              }, () => {
                console.error('failed registering username')
              })
            } catch (err) {
              console.error('Error when getting Janus users after attach: ' + err)
              reject(err)
            }
          },

          onError: (error) => {
            const error_ = new MixStageJanusErrors.JanusGeneralVideoChatError('Video chat error: ' + error)
            videoCallbacks_.getCallback('onError')(error_)
            throw error
          },

          onMessage: handleJanusMessage,

          onCleanUp: () => {
            console.log('clean up')
            console.log('Got a cleanup notification, we are unpublished now.')
            // cameraAndAudioStream = null
          },

          onLocalStream: async (stream) => {


            //??? hack, smells we have life-time problems.
            if (!pluginHandle_) {
              console.debug('Janus instance no longer exists, skipping number of connected users')
              return
            }
            janusUserMap_ = await readJanusUserMap(pluginHandle_, init_.screeningRoomId, init_.janusSecret)
            
            const {uid} = init_.currentUser
            userMap_.addUser(uid)
            userMap_.updateUser(uid, {
              videoStream: stream,
              audioStream: stream
            })

            videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
            videoCallbacks_.getCallback('onLocalStream')(userMap_.getUsers())
            keepAlive(pluginHandle_)
          },
          onWebRTCStateChanged: (on, medium) => {
            videoCallbacks_.getCallback('onWebRTCStateChanged')(on, medium)
          },
          onMediaStateChanged: (on, medium) => {
            videoCallbacks_.getCallback('onMediaStateChanged')(on, medium)
          },
          localScreenshareEnded: () => {
            videoCallbacks_.getCallback('onLocalScreenshareEnded')()
          }
        }

        janus_ = await init2(init_, callbacks)
      } catch (error) {
        videoCallbacks_.getCallback('onLocalStream')(userMap_.getUsers()) // onError(error)
        videoCallbacks_.getCallback('onError')(error)
        reject()
      }
    })
  }

  const updateRoomForScreensharing = () => {
    return new Promise((resolve, reject) => {

      const updateMessage = {
        request: 'edit',
        room: init_.screeningRoomId,
        pin: init_.janusSecret,
        secret: init_.janusSecret,
        new_bitrate: Constants.SDP_SETTINGS.MAX_AVERAGE_BITRATE * 8,
        audiocodec: 'opus,multiopus',
        audiolevel_event: false,
        audiolevel_ext: false
      }
      pluginHandle_.send({
        message: updateMessage,
        success: (resp) => {
          console.log('Successfully updated room to higher quality: ' + JSON.stringify(resp))
          resolve(true)
        },
        error: function (err) {
          console.error('Error when updating room to higher quality: ' + err)
          reject(err)
        }
      })
    })
  }  

  const closeAllMediaStreams = async () => {
    const { uid } = init_.currentUser
    const user = userMap_.getUser(uid)
    await closeScreenshareStream(user)
    await closeVideoStream(user)
    await closeAudioStream(user)
  }
  const closeScreenshareStream = async (user) => {
    try {
      if (user && typeof user === 'object') {
        if (user.screenshare !== false && user.screenshare !== undefined && user.screenshare?.getTracks() !== undefined) {
          const promises = user.screenshare.getTracks().map(track => track.stop());
          await Promise.all(promises);
        }
        user.screenshare = undefined;
        userMap_.updateUser(user.uid, {
          screenshare: undefined
        });
      } else {
        console.log('no user object yet');
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const closeVideoStream = async (user) => {
    try {
      if (user && typeof user === 'object') {
        if (user.videoStream !== false && user.videoStream !== undefined && user.videoStream.getTracks() !== undefined) {
          const promises = user.videoStream.getTracks().map(track => track.stop());
          await Promise.all(promises);
        }
        user.videoStream = undefined;
        userMap_.updateUser(user.uid, {
          videoStream: undefined
        });
      } else {
        console.log('no user object yet');
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const closeAudioStream = async (user) => {
    try {
      if (user && typeof user === 'object') {
        if (user.audioStream !== false && user.audioStream !== undefined && user.audioStream.getTracks() !== undefined) {
          const promises = user.audioStream.getTracks().map(track => track.stop());
          await Promise.all(promises);
        }
        user.audioStream = undefined;
        userMap_.updateUser(user.uid, {
          audioStream: undefined
        });
      } else {
        console.log('no user object yet');
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const unpublishOwnScreenshare = (diconnectedByUser) => {
    //  this does not work, since if using the chrome stop share we do not get an argument to check
    return new Promise((resolve, reject) => {
      if (!screensharingPluginHandle_ || screensharingPluginHandle_.webrtcStuff.started) return reject(new Error("Screensharing plugin handle is not available, or we are already closing"));
      // Unpublish our stream
      const unpublish = {
        request: 'unpublish',
        pin: init_.janusSecret,
      }
      screensharingPluginHandle_.send({
        message: unpublish,
        success: () => {
          console.log('Successfully unpublished screenshare')
          const {uid} = init_.currentUser
          
          try {
            const leavingUser = userMap_.getUser(uid)
            leavingUser.screenshare?.getTracks().forEach(track => {
              track.stop()
            })
            leavingUser.screenshare = undefined
            
            console.log('tracks stopped')         
          } catch (error) {
            console.log(error)
          }

          userMap_.updateUser(uid, {
            screenshare: undefined
          })
          videoCallbacks_.getCallback('onLocalScreenshareEnded')()
          videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
          resolve(true)
        },
        error: (err) => {
          console.error(err)          
          reject(err)
        },
      })
    })
  }  
  
  const getLocalStream = async () => {

    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30 }
      }
    })
    return stream
  }

  const publishScreenSharing = async (handle, stream0) => {
    console.log('Publishing screensharing')
    // If we already have obtained the stream 
    // this is a reconnect using the same local stream
    let stream
    if (!stream0) {
      await updateRoomForScreensharing()
      try {
        stream = await getLocalStream()        
      } catch (error) {
        // This happens when the user cancels screen share
        // How to handle : no access to screen???
        console.log(error)
        // @ts-ignore
        if (error.message === 'Permission denied by system') {
          alert('System prevented screen sharing, check your Operating System settings. You need to allow screen recording for chrome. For more info visit support.mixstage.io')
        }
        return
      }
    }
    else {
      stream = stream0
    }

    stream.getTracks().forEach(track => {
      track.onended = async () => {
        console.log('closing screenshare')
        try {
          await unpublishOwnScreenshare(true)          
        } catch (error) {
          console.log(error)
        }
      }
    })

    handle.createOffer(
      {
        stream: stream,
        success: function(jsep) {
          var publish = {
            request: 'configure',
            pin: init_.janusSecret,
            secret: init_.janusSecret,
            audio: false,
            video: true,
          }
          console.log('success publishing')
          console.log('publish', publish, jsep)
          handle.send({
            message: publish,
            jsep: jsep,
          })
        },
        error: function(error) {
          const error_ = new MixStageJanusErrors.JanusScreenshareError('Sceenshare error' + error)
          //TODO:: handle this also in same error handler
          videoCallbacks_.getCallback('screenshareError')(error_)
        }
      });
  }


  const startScreenSharing = async () => {
    console.log('Start screensharing')
    const {uid} = init_.currentUser

    janus_.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: `Spotterfish-${uid}-screenShare-${JanusClient.randomString(12)}`,
  
      success: async function (pluginHandle) {
        screensharingPluginHandle_ = pluginHandle
        const {email, uid} = init_.currentUser
        var register = {
          request: 'join',
          room: init_.screeningRoomId,
          pin: init_.janusSecret,
          ptype: 'publisher',
          display: JSON.stringify({
            name: 'screensharing--' + email,
            uid,
            screensharing: true
          }),
        }
        screensharingPluginHandle_.send({
          message: register,
          success:() => {
            console.log('registered username')
          }
        })  
      },
      onlocalstream: async (stream) => {
        console.log('received a local stream - updating screen share props')
        userMap_.updateUser(uid, {
          screenshare: stream,
          screenshareRfid: stream.id
        })
        console.log(userMap_.getUser(uid)) 
        videoCallbacks_.getCallback('onUsers')(userMap_.getUsers())
        videoCallbacks_.getCallback('onLocalScreenshare')(stream)
      },

      onmessage: (msg, jsep) => {
        console.log('---> janus message', msg, jsep)
        const event = msg['videoroom']
        if(event){
          if(event === 'joined'){
            // this is us, if we already have a stream in our userMap we connect that
            try {
              publishScreenSharing(screensharingPluginHandle_, userMap_.getUser(uid).screenshare)
            }
            catch (error) {
              console.log(error)
              userMap_.updateUser(uid, {
                screenshare: undefined,
                screenshareRfid: undefined
              })
              videoCallbacks_.getCallback('screenshareEnded')()
            }
          }
        }
        if(jsep !== undefined && jsep !== null){
          console.debug('Handling SDP')
          console.debug(jsep)
          screensharingPluginHandle_.handleRemoteJsep({
            jsep,
          })
        }
        keepAlive(screensharingPluginHandle_)
      },

      error: function (error) {
        const error_ = new MixStageJanusErrors.JanusScreenshareError('Video room plugin error in screenshare: ' + error)
        // TODO: handle this in error cb central also
        userMap_.updateUser(uid, {
          screenshare: undefined,
          screenshareRfid: undefined
        })
        videoCallbacks_.getCallback('screenshareEnded')()
      },
  
      mediaState: async function (on, medium) {
        console.log(
          'Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium
        )
        videoCallbacks_.getCallback('onMediaStateChanged')(on, medium)
        if(!on){
          try {
            await unpublishOwnScreenshare(false)          
          } catch (error) {
            console.log(error)
          }
        }
      },
  
      webrtcState: function (on) {
        console.log(`Janus says our screensharehandle WebRTC PeerConnection is ${on ? 'up' : 'down'} now`)
        videoCallbacks_.getCallback('onWebRTCStateChanged')(on, undefined)
        if (!on) {
          console.error('User lost screen sharing peer connection in room')
          userMap_.updateUser(uid, {
            screenshare: undefined,
            screenshareRfid: undefined
          })
          videoCallbacks_.getCallback('screenshareEnded')()        }
      },
  
      // onmessage: onMessage,
      // onlocalstream: onLocalStream,
  
      // oncleanup: onCleanUp
    })
    
  }

  const getJanusUsers = () => userMap_.getUsers()

  const addCallback = (callbackName, fn) => {
    videoCallbacks_.addCallback(callbackName, fn)
  }

  const debugBitrate = async () => {
    const room = await listRooms()
    const users = userMap_.getUsers()

    return {
      room,
      users,
      myFeed: pluginHandle_,
    }
  }

  const listRooms = () => {
    return new Promise((resolve, reject) => {
      var message = {
        request: 'list',
        room: init_.screeningRoomId,
        pin: init_.janusSecret,
      }
      pluginHandle_.send({
        message,
        success: (response) => {
          console.log(response)
          const room = response.list.find(room => room.room === init_.screeningRoomId)
          console.warn('ROOM', room)
          resolve(room)
        }
      }) 
    })
  }

  const sendData = (data) => {

    let message = {
      videoroom: 'message',
      transaction: JanusClient.randomString(12),
      room: init_.screeningRoomId,
      pin: init_.janusSecret,
      text: JSON.stringify(data),
    }
    // Note: messages are always acknowledged by default. This means that you'll
    // always receive a confirmation back that the message has been received by the
    // server and forwarded to the recipients. If you do not want this to happen,
    // just add an ack:false property to the message above, and server won't send
    // you a response (meaning you just have to hope it succeeded).
    pluginHandle_.data({
      text: JSON.stringify(message),
      error: (reason) => {console.error(reason) },
      success: (e) => { 
        console.log('message delivered', e)
       }
    })
  }

  const getCallback = (callbackName) => {
    return videoCallbacks_.getCallback(callbackName);
  };

  return {
    checkInvariant: checkInvariantVideoChatData,
    startVideoChat,
    handleReconnection,
    //  Only called by openVideoChatSession()
    leaveRoomAndDeleteIfEmpty,

    //  openVideoChatSession()
    leave,

    //  Hide / show user's cam + audio
    unpublishOwnFeed,
    publishOwnFeed,

    toggleVideoForAll,
    setAudioMute,
    setVideoMute,
    sendData,
    
    getJanusUsers,
    addCallback,
    getCallback,

    startScreenSharing,
    closeAllMediaStreams,
    unpublishOwnScreenshare,
    debugBitrate,
    resetDroppedOffset
  }
}


//  Contains support fof anon-users.
const calcIDAndPermission = async (screeningRoomConnection) => {
  const screeningRoomDBID = screeningRoomConnection.screeningRoomDBCopy['.key']
  const janusSecret = await CloudClient.call_CFhashInput(
    screeningRoomConnection.spotterfishSession.firebase,
    screeningRoomDBID
  )

  let { uid, email} = screeningRoomConnection.spotterfishSession.userSession.firebaseCurrentUser
  if (!email) { 
    email = 'anon' + uid 
  }

  return {
    screeningRoomId: screeningRoomDBID,
    currentUser: { uid, email },
    janusSecret
  }
}


const terminateSessionFromJanusInitError = async (screeningRoomConnection) => {
  await screeningRoomConnection.sharedStateUserActiveRef.set(false);
  screeningRoomConnection.sharedStateUserActiveRef.off();
}

const handleCriticalError = async (err, errorCallback, screeningRoomConnection, idAndPermission) => {

  const sentryData = {
    user: {
      uid: idAndPermission.currentUser.uid,
    },
    extra: {
      room_id: idAndPermission.screeningRoomId,
    },
  }

  if (err instanceof MixStageJanusErrors.WebRTCNotSupportedError) {
    alert('WebRTC is not supported in your browser. Please use a supported browser or update your current browser.');
    sentryData.extra.errorType = 'Mission Critical Error: User was thrown from session.'
    Sentry.captureException(err, sentryData)
    await terminateSessionFromJanusInitError(screeningRoomConnection);
    errorCallback(err);
  } 
  else if (err instanceof MixStageJanusErrors.JanusInitializationError) {
    alert('Failed to start Video Chat session. Please check your network connection or try again later. For more information, visit our support page.');
    sentryData.extra.errorType = 'Mission Critical Error: User was thrown from session.'
    Sentry.captureException(err, sentryData)
    await terminateSessionFromJanusInitError(screeningRoomConnection);
    errorCallback(err);
  } 
  else if (err instanceof MixStageJanusErrors.JanusReconnectError) {
    alert('Several attempts to re-connect our servers failed. Please check your network connection and try again. For more information, visit our support page.');
    sentryData.extra.errorType = 'Mission Critical Error: User was thrown from session.'
    Sentry.captureException(err, sentryData)
    await terminateSessionFromJanusInitError(screeningRoomConnection);
    errorCallback(err);
  } 
  else {
    console.error('Critical error:', err);
    sentryData.extra.errorType = 'Mission Critical Error: User remained in session.'
    Sentry.captureException(err, sentryData)
    errorCallback(err);
  }
}

const handleNonCriticalError = async (error, videoChat, idAndPermission, onCriticalError, maxRetries = 3, retryInterval = 2000) => {
  console.error('Janus runtime error:', error);
  // Log specific error messages based on the error type
  let errorType
  let retry = false
  if (error instanceof MixStageJanusErrors.JanusRoomError) {
    errorType = 'Error related to Janus room operations.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusParticipantError) {
    errorType = 'Error related to Janus participants.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusWebRTCError) {
    errorType = 'Error related to WebRTC operations.'
    retry = true
  } 
  else if (error instanceof MixStageJanusErrors.JanusDAWStreamError) {
    errorType = 'Error related to DAW stream.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusListParticipantsError) {
    errorType = 'Error related to listing participants.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusUpdatePresentersError) {
    errorType = 'Error related to updating presenters.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusScreenshareError) {
    errorType = 'Error related to screensharing.'
  } 
  else if (error instanceof MixStageJanusErrors.JanusGeneralVideoChatError) {
    errorType = 'General video chat error.'
    retry = true
  } 
  else if (error instanceof MixStageJanusErrors.IceConnectionError) {
    errorType = 'ICE connection error'
    retry = true
  }
  else {
    errorType = 'An unknown error occurred.'
  }
    
  Sentry.captureException(error, {
    user: {
      uid: idAndPermission.currentUser.uid,
    },
    extra: {
      room_id: idAndPermission.screeningRoomId,
      errorType: errorType
    },
  });

  if (retry) {
    const attemptReconnect = async (retries) => {
      if (retries > maxRetries) {
        console.error('Max reconnection attempts reached.');
        onCriticalError(new MixStageJanusErrors.JanusReconnectError('Re-connection attempts failed'));
        return;
      }
  
      try {
        console.log(`Attempting to reconnect... (${retries + 1}/${maxRetries})`);
        await videoChat.handleReconnection();
        const users = videoChat.getJanusUsers();
        videoChat.getCallback('onUsers')(users);
      } catch (reconnectError) {
        console.error('Reconnection attempt failed:', reconnectError);
        setTimeout(() => attemptReconnect(retries + 1), retryInterval);
      }
    };
  
    attemptReconnect(0);
  }
};

// const testAllErrors = (videoChat, idAndPermission, onCriticalError) => {
//   const errorSequence = [
//     new MixStageJanusErrors.IceConnectionError('Simulated ICE connection error.'),
//     new MixStageJanusErrors.JanusRoomError('Simulated Janus room error.'),
//     new MixStageJanusErrors.JanusParticipantError('Simulated Janus participant error.'),
//     new MixStageJanusErrors.JanusWebRTCError('Simulated WebRTC error.'),
//     new MixStageJanusErrors.JanusDAWStreamError('Simulated DAW stream error.'),
//     new MixStageJanusErrors.JanusListParticipantsError('Simulated list participants error.'),
//     new MixStageJanusErrors.JanusUpdatePresentersError('Simulated update presenters error.'),
//     new MixStageJanusErrors.JanusScreenshareError('Simulated screenshare error.'),
//     new MixStageJanusErrors.JanusGeneralVideoChatError('Simulated general video chat error.'),
//     new MixStageJanusErrors.IceConnectionError('Simulated ICE connection error.'),
//   ];

//   const timeouts = []

//   const clearAllTimeouts = () => {
//     timeouts.forEach(timeout => clearTimeout(timeout))
//   }

//   errorSequence.forEach((error, index) => {
//     const timeout = setTimeout(async () => {
//       console.log('Simulating Error: ', error)
//       await handleNonCriticalError(error, videoChat, idAndPermission, onCriticalError)
//     }, index * 10000)
//     timeouts.push(timeout)
//   })

//   // Schedule a failed reconnect attempt 10 seconds after the last error
//   const finalTimeout = setTimeout(async () => {
//     console.log('Simulating failed reconnect attempt...')
//     clearAllTimeouts()
//     try {
//       onCriticalError(new MixStageJanusErrors.JanusReconnectError('Final reconnect attempt failed'))
//     } catch (error) {
//       console.error('final critical error failed:', error)
//     }
//   }, (errorSequence.length + 1) * 10000)

//   timeouts.push(finalTimeout)
// }


// Follows API contract guides.
// WARNING: errorCallback can called before this function returns
async function openVideoChatSession (screeningRoomConnection, params, errorCallback) {
  assert(ScreeningRoomConnection.checkInvariantScreeningRoomConnection(screeningRoomConnection))
  assert(isFunctionInstance(errorCallback))
  
  let videoChatEffect = undefined

  try {
    const idAndPermission = await calcIDAndPermission(screeningRoomConnection)
    
    const onCriticalError = async (err) => {
      // Only session breaking errors will be caught here. 
      // 2024-06 -> errors will be shown before user is exited from the session
      handleCriticalError(err, errorCallback, screeningRoomConnection, idAndPermission)
    }

    const videoChatData = {
      roomSize: params.seats,
      video_room_server: screeningRoomConnection.screeningRoomDBCopy.video_room_server,
      video_room_turnstun_servers: screeningRoomConnection.screeningRoomDBCopy.video_room_turnstun_servers,
      iceTransportMethods: screeningRoomConnection.screeningRoomDBCopy.iceTransportMethods,
      screeningRoomId: idAndPermission.screeningRoomId,
      currentUser: idAndPermission.currentUser,
      janusSecret: idAndPermission.janusSecret,
      isAudienceRoom: params.isAudienceRoom,
      onCriticalError
    };

    videoChatEffect = await new Promise(
      async (resolve) => {
        const videoChat = VideoChatSession(videoChatData)
        try {
          videoChat.addCallback('onError', async (error) => {
            await handleNonCriticalError(error, videoChat, idAndPermission, onCriticalError);
          })
          await videoChat.startVideoChat()
          // testAllErrors(videoChat, idAndPermission, onCriticalError)
          resolve(videoChat)
        } catch (error) {
          handleCriticalError(error, errorCallback, screeningRoomConnection, idAndPermission)
          await videoChat.leave()
        }
      }
    )

    assert(checkInvariantVideoChatSession(videoChatEffect))
    return videoChatEffect
  }

  catch(error){
    debugger
    if(videoChatEffect !== undefined){
      ScreeningRoomConnection.updateUserSharedState2(screeningRoomConnection.sharedStateID, { video_chat_ready: false })
    
      try {
        await videoChatEffect.leaveRoomAndDeleteIfEmpty()
      } catch (error) {
        console.log(error)
      }
    }
    throw error
  } 
}

async function closeVideoChatSession (s) {
  assert(checkInvariantVideoChatSession(s))
  
  try {
    await s.closeAllMediaStreams()
    await s.leaveRoomAndDeleteIfEmpty()
  } catch (error) {
    console.log(error)
  }
}



export default  { openVideoChatSession, closeVideoChatSession }
