// @ts-nocheck
const Timecode = require('./Timecode')
const SpotterfishCore = require('../SpotterfishCore')
const assert = SpotterfishCore.assert
const _ = require('lodash')
const Constants = require('../consts')

function getMCColor (category) {
  switch (category) {
    case 'MUSIC':
      return 'Red'
    case 'ADR':
      return 'Green'
    case 'VO':
      return 'Blue'
    case 'DIALOG':
      return 'Cyan'
    case 'GENERIC':
      return 'Magenta'
    case 'FX':
      return 'Yellow'
    case 'BG':
      return 'Black'
    case 'ATMOS':
      return 'Black'
    case 'FOLEY':
      return 'White'
  }
}

const getDVresolveColor = (color) => {
  return {
      'Red': 'ResolveColorRed',
      'Green': 'ResolveColorGreen',
      'Blue': 'ResolveColorBlue',
      'Cyan': 'ResolveColorCyan',
      'Magenta': 'ResolveColorPink',
      'Yellow': 'ResolveColorYellow',
      'Black': 'ResolveColorPurple',
      'White': 'ResolveColorPurple'
  }[color]
}

function getHexColor (category) {
  const c = Constants.FIXED_COMMENT_TYPES.find((type) => type.name === category)
  return c?.color || '#8B7391'
}

/*
STEINBERG CSV FORMAT
 Timecode       Category    Description     Comment               User
 00:00:00:00    'Generic'	 'First marker'	  'A long Description'	'A users Full name'     
 00:00:05:00    'Dialog'	 'Second Marker'	'A long Description'  'A users Full name'   
 00:00:20:00    'Foley'	   'Third Marker'	  'A long Description'  'A users Full name'
*/

function createSteinbergCSV (markerTrack) {
  const frk = markerTrack.frk
  const CSVlines = []
  let index = 0

  // Add CSV header line first.
  CSVlines.push([
    'Timecode',
    'Category',
    'Description',
    'Comment',
    'User'
  ])

  for (const marker of markerTrack.markers) {
    index++
    const tc_start = Timecode.frameIndexToSMPTEString(marker.position + markerTrack.SMPTEstart, frk)
    const tc_end = Timecode.frameIndexToSMPTEString(marker.position + marker.length + markerTrack.SMPTEstart, frk)
    const length = Timecode.frameIndexToSMPTEString((Timecode.SMPTEstringToFrameIndex(tc_end, frk) - Timecode.SMPTEstringToFrameIndex(tc_start, frk)), frk)

    CSVlines.push([
      tc_start,
      marker.markerType,
      marker.title,
      marker.comment,
      marker.user
    ])
  }
  let output = ''
  for (const line of CSVlines) {
    output += line.join(',') + '\n'
  }
  return output
}

/*
REAPER CSV FORMAT
 R+INDEX  Name                                                  Start         End           Lenght       Color 
 R1       'Generic'	+ 'First marker'	+  'A long Description'   00.00.00.00   00.00.00.01   0.0.1        7FFCC3
 R2       'Dialog'	+ 'Second Marker'	+  'A long Description'   00.01.00.00   00.01.00.01   0.0.1        7FFCC3
 R3       'Foley'	  + 'Third Marker'	+  'A long Description'   00.02.00.00   00.02.00.01   0.0.1        7FFCC3
*/

/*
#,Name,Start,End,Length,Color
R1,test 1,2.1.00,2.3.00,0.2.00,
R2,test 2,3.1.00,4.1.00,1.0.00,7FFCC3
*/

function createReaperCSV (markerTrack) {
  const frk = markerTrack.frk
  const CSVlines = []
  let index = 0
  // Add CSV header line first.
  /*
  The file should have 1-6 fields per line, separated by whitespace or commas. The first line of the file may be a header row. Allowable header fields are: "Number", "Name", "Start", "End", "Length", "Color".
  */
  CSVlines.push([
    '#',
    'Name',
    'Start',
    'Color'
  ])

  for (const marker of markerTrack.markers) {
    index++
    const tc_start = Timecode.frameIndexToSMPTEString(marker.position + markerTrack.SMPTEstart, frk)

    CSVlines.push([
      index,
      `${marker.markerType} - ${marker.title} -- ${marker.comment}` ,
      tc_start,
      getHexColor(marker.markerType).slice(1)
    ])
  }
  let output = ''
  for (const line of CSVlines) {
    output += line.join(',') + '\n'
  }
  return output
}

/*
#   Name  Start	  Duration  Time	  Format	  Type	  Description	
                marker_JSON.append({
                  "Name": this_marker['markerType'] + ' ' + this_marker['title'],
                  "Start": tc_start,
                  "Duration": length,
                  "Time Format": "{} fps".format(marker_track['fps']),
                  "Type": "Cue",
                  "Description": this_marker['comment']
                })
*/

function createAuditionCSV (markerTrack) {
    const frk = markerTrack.frk
    const CSVlines = []
    let index = 0
    CSVlines.push([
      'Name',
      'Start',
      'Duration',
      'Time Format',
      'Type',
      'Description'
    ])
  
    for (const marker of markerTrack.markers) {
      index++
      const tc_start = Timecode.frameIndexToSMPTEString(marker.position + markerTrack.SMPTEstart, frk)
      const dur = '00:00:00:01'
      const format = `${markerTrack['fps']} fps`
      CSVlines.push([
        `${marker.markerType} - ${marker.title}` ,
        tc_start,
        dur,
        format,
        'Cue',
        `${marker.comment}`
      ])
    }

    let output = ''
    for (const line of CSVlines) {
      output += line.join('\t') + '\n'
    }
    return output
}


/*
TITLE: Name
FCM: NON-DROP FRAME

001  001      V     C        01:52:33:03 01:52:33:03 01:52:33:03 01:52:33:03  
Comment text |C:ResolveColorRed |M:MUSIC  |D:1

002  001      V     C        02:27:01:19 02:27:01:19 02:27:01:19 02:27:01:19  
Another comment text |C:ResolveColorRed |M:MUSIC  |D:1

003  001      V     C        02:35:46:02 02:35:46:02 02:35:46:02 02:35:46:02  
A third Comment text |C:ResolveColorRed |M:MUSIC  |D:1
*/

function createResolveEDL (markerTrack) {

  const frk = markerTrack.frk
  let edlString = ''
  const edlFormat = 'NON-DROP FRAME'
  let index = 0
  const title = 'TestEDL'
  
  // title line
  edlString += `TITLE: ${title}\n`
  edlString += `FCM: ${edlFormat}\n\n`
  
  for (const marker of markerTrack.markers) {
    index++
    const tc_start = Timecode.frameIndexToSMPTEString(marker.position + markerTrack.SMPTEstart, frk)
    const tc_end = Timecode.frameIndexToSMPTEString(marker.position + marker.length + markerTrack.SMPTEstart, frk)
    // DO NOT REMOVE WHITE SPACE!
    edlString += `${String(index).padStart(3, '0')}  ${String(index).padStart(3, '0')}      V     C        ${tc_start} ${tc_end} ${tc_start} ${tc_end}  \n`
    const hasTitle = Boolean(marker.title)
    const hasDescription = Boolean(marker.comment)
    edlString +=
      `${marker.title}
       ${marker.comment} ${hasDescription ? ' \n' : ''}
       USER: ${marker.user}
       |C:${getDVresolveColor(marker.markerColor)} |M:${marker.markerType} |D:1 |N:TEST\n\n`
  }

  return edlString
}

/*
{
    "position": 86,
    "length": 0,
    "markerColor": "Cyan",
    "markerStatus": 1,
    "markerIcon": 1,
    "markerType": "DIALOG",
    "createdTime": null,
    "createdDate": null,
    "title": "",
    "comment": ""
    "user"
  }
*/

function createFullCSV (markerTrack) {
  const frk = markerTrack.frk
  const frameRateNick = Timecode.getFrameRateNickname(frk)
  const CSVlines = []
  let index = 0
  // Add CSV header line first.
  
  CSVlines.push([
    '#',
    'Type',
    'Title',
    'Description',
    `TC @ ${frameRateNick}`,
    'Date',
    'User',
    'Color'
  ])

  for (const marker of markerTrack.markers) {
 
    index++
    const tc_start = Timecode.frameIndexToSMPTEString(marker.position + markerTrack.SMPTEstart, frk)
    const tc_end = Timecode.frameIndexToSMPTEString(marker.position + marker.length + markerTrack.SMPTEstart, frk)
    const length = Timecode.frameIndexToSMPTEString((Timecode.SMPTEstringToFrameIndex(tc_end, frk) - Timecode.SMPTEstringToFrameIndex(tc_start, frk)), frk)

    CSVlines.push([
      String(index).padStart(3, '0'),
      marker.markerType,
      marker.title,
      marker.comment,
      tc_start,
      marker.createdDate,
      marker.user,
      getHexColor(marker.markerType),

    ])
  }
  let output = ''
  for (const line of CSVlines) {
    output += line.join(',') + '\n'
  }
  return output
}

function runUnitTests () {
  console.log('CSVUtils.js -- START')
  console.log('MarkerUtils.js -- END')
}

module.exports = {
  runUnitTests,
  createSteinbergCSV,
  createReaperCSV,
  createAuditionCSV,
  createResolveEDL,
  createFullCSV
}
