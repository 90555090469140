<template>
  <!-- TODO: THIS IS NOT VERY DRY, A SIMPLE COPY OF THE comments components with some changes -->
   <v-container class="dark-panel">
      <v-layout row>
        <v-flex xs12 style="max-width: 100%;">
            <v-toolbar height="44px" class="dark-panel">
              <v-spacer />
              <v-spacer />
              <v-icon size="1em" left>location_on</v-icon>
              Markers
              <v-spacer />
              <v-spacer />
            </v-toolbar>
            
            <v-container class="panel" v-if="isModerator && templateReactiveData.isUserProjectModerator" style="min-height: 150px;">
              <v-row>
                <v-col cols="12" style="margin: auto;" class="panel mt-4 pb-0 pt-1">
                  <v-row>
                    <v-col cols="12" style="margin: auto;" v-if="isModerator && templateReactiveData.isUserProjectModerator">
                      <v-select
                        :items="availableLanes"
                        item-value="uid"
                        :value="markerLane"
                        @change="handleSelectChange"
                        color="white"
                        solo
                        hide-details
                        dense
                        :label="availableLanes.length ? 'Select marker list' : 'Current list'"
                      >
                        <template v-slot:prepend>
                          <v-tooltip bottom :color="colors.tooltipBackground">
                            <template v-slot:activator="{ on }">
                              <v-btn style="margin-top: -0.3em;" icon v-on="on" @click="$emit('new-marker-session-clicked')">
                                <v-icon style="font-size: 1.8em;">archive</v-icon>
                              </v-btn>
                            </template>
                            <span>Save list &</span><br /><span>remove current markers from timeline</span>
                          </v-tooltip>
                        </template>

                        
                        <template slot="selection" slot-scope="data">
                          {{getNameWithDefault(data.item.name)}} - {{ formatDate(data.item.created_date.seconds) }}
                        </template>

                        <template slot="item" slot-scope="data">
                            <div style="width: 320px; text-align: left;">
                              {{getNameWithDefault(data.item.name)}} - {{ formatDate(data.item.created_date.seconds) }}
                            </div>
                            <div class="checkbox">
                              <v-icon v-if="data.item['.key'] == selectedMarkerLane">done</v-icon>
                            </div>
                        </template>

                        <template v-slot:no-data>
                          <v-alert :value="true" style="background: #0F0F0F; !important; margin: 0;">
                            <div v-if="selectedMarkerLane">
                              No saved marker list
                            </div>
                          </v-alert>
                        </template>

                      </v-select>
                    </v-col>

                  </v-row>

                </v-col>
                
                  <v-col cols="12" class="">
                    <v-text-field
                      dense
                      solo
                      v-model="search"
                      append-icon="search"
                      label="Search/Filter Markers"
                      single-line
                      hide-details>

                      <template v-slot:prepend>
                        <v-tooltip bottom :color="colors.tooltipBackground">
                          <template v-slot:activator="{ on }">
                            <v-btn v-if="isModerator" style="margin-top: -0.3em;" icon v-on="on" @click="$emit('close-fullscreen'), markerDownloadDialog = true">
                              <v-icon v-if="isModerator" style="font-size: 1.8em;">cloud_download</v-icon>
                            </v-btn>               
                          </template>
                          <span>Download current marker list</span><br /><span>for import into other systems</span>
                        </v-tooltip>
                        </template>
                        
                    </v-text-field>
                  </v-col>
                </v-row>
              
            </v-container>

            <div class="marker-list-wrapper">

              <v-data-table
                style="width: 100%;"
                ref="markersTable"
                :headers="commentsHeaders"
                :items="markers"
                :items-per-page="18"
                :expanded="expanded"
                :footer-props="{
                  'items-per-page-options': [20, 50],
                  'items-per-page-text': 'Markers'
                }"
                :search="search"
                item-key=".key"
                :item-class="itemRowBackground"
                class="marker-table dark-panel no-padding-table"
                @click:row="handleRowClick"
                
              >
              <template v-slot:item.frames="{ item }">
                <v-tooltip top :color="colors.tooltipBackground">
                  <span> Move playhead to marker position:</span><br /><span> {{ calcHTMLOutputSMPTE(item.tc_pos) }} </span>
                  <template v-slot:activator="{on}">
                    <v-btn 
                      fab 
                      x-small 
                      :disabled="templateReactiveData.dawStreamerSelectedAsSyncSource"
                      v-on="on" 
                      @click.stop="seekToMarker(item)" 
                      style="margin: 0 0 0 -10px;" 
                      :style="`background: ${getButtonColor(item)}`"
                    >
                      <span  style="margin-top: 2px;">
                        <v-icon size="1.8em" >keyboard_tab</v-icon>
                        <!-- <span style="font-size: 0.7em;"> {{ calcHTMLOutputSMPTE(item.tc_pos) }}</span> -->
                      </span>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.title="slotData">
                <v-tooltip top :color="colors.tooltipBackground">
                  <span @click="toggleExpand(slotData)">{{slotData.item.title}}</span>
                  <template v-slot:activator="{on}">
                    <div style="font-size: 0.9em; width: 5em;" v-on="on" class="marker-title table-text">{{slotData.item.title}}</div>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.description="slotData">
                <v-tooltip top :color="colors.tooltipBackground">
                  <span @click="toggleExpand(slotData)">{{slotData.item.description}}</span>
                  <template v-slot:activator="{on}">
                    <div style="font-size: 0.9em;" v-on="on" class="marker-description table-text">{{slotData.item.description}}</div>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:item.category_select="slotData">
                <v-btn
                  x-small
                  style="width: 20px;"
                  :style="{ 
                    background: getColorFromMarker(slotData.item.category_select).color,
                    color: getColorFromMarker(slotData.item.category_select).textColor
                  }"
                >
                  <span style="font-size: 0.5em;">{{slotData.item.category_select}}</span>
                </v-btn>              
              </template>

              <template v-slot:item.user="{item}">
                <v-tooltip top :color="colors.tooltipBackground">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="border-radius: 50%; padding: 4px 1px 4px 1px; background: rgb(90, 90, 90);" class="mini-avatar">
                      <span style="padding: 4px; font-size: 0.8em;">{{ item.user ? item.user.slice(0,1) : '' }}</span>   
                    </span>
                  </template>
                  <span>
                    {{ item.user }}
                  </span>
                </v-tooltip>
              </template>

              <template v-slot:item.delete="{item}">
                <v-tooltip top :color="colors.tooltipBackground">
                  <span>Delete marker</span>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="font-size: 1em;" @click.stop="removeComment(item)">delete</v-icon>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" style="background: #0F0F0F; !important; margin-top: 14px;">
                  <div v-if="selectedMarkerLane">
                    No Markers
                  </div>
                </v-alert>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expanded-marker-edit">
                  <div>
                    <v-text-field
                      style="width: 99%; margin: auto;"
                      autofocus
                      ref="title"
                      outlined
                      :value="item.title"
                      counter=32
                      maxlength="32"
                      dense
                      autocomplete="off"
                      label="Title"
                      @change="handleTitleChange($event, item)"
                      @keyup.esc.native="onCancel(item)"
                      color="white" 
                    />
                    <v-textarea
                      outlined
                      label="Description"
                      :value="item.description"
                      @change="handleDescriptionChange($event, item)"
                      hide-details
                      no-resize
                    />
                    <div class="marker-edit-category-buttons">
                      <v-btn
                      small
                      v-for="(commentType, index) in fixedCommentTypes"
                      :key="index"
                      class="markertype elevation-5 rounded gray-border"
                      @click="onChangeCommentType(fixedCommentTypes[index], item), item"
                      :style="{
                        background: fixedCommentTypes[index].color,
                        color: fixedCommentTypes[index].textColor 
                      }"
                      >
                      {{ fixedCommentTypes[index].name }}
                    </v-btn>
                  </div>
                  
                  <v-row no-gutters class="mt-4 mb-4 mid-panel">
                    <v-col cols="6" style="margin: auto;">
                      <v-row>
                        <v-col cols="8" style="margin: 10px 0 !important; padding-right: 0 !important;">
                          <v-tooltip top :color="colors.tooltipBackground">
                            <template v-slot:activator="{ on }">
                              <input
                                v-on="on"
                                style="width: 6em; color: white; border: 1px solid white; border-radius: 3px; padding: 0.3em; "
                                ref="timecode"
                                outlined
                                dense
                                autocomplete="off"
                                label="Timecode"
                                @focus="isUserEnteringTcText = true"
                                @blur="isUserEnteringTcText = false"
                                v-on:keyup.enter="blur"
                                @click="selectText"
                                @change="onSMPTEinputChanged($event, item)"
                                :value="getInteractiveTimecodeInputText(item)"
                                color="white"
                              />
                            </template>
                            <span>Timecode</span> <br />
                            <span>Flexible input: full timecode, minutes, seconds, frames</span><br />
                            <span>or +/- & value for offset</span>
                            <br />
                            <span>Colons are optional</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="1" style="margin: 10px 0 !important; padding: 10px 0 !important; ">
                          <v-tooltip top :color="colors.tooltipBackground">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="handleAppendIconClick(item)">
                                <v-icon>update</v-icon>
                              </v-btn>
                            </template>
                            <span>Move marker to playhead position</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-btn small width="100" style="margin-left: 0px;" color="gray" @click="onCancel(item)">Cancel</v-btn>
                  <v-btn small width="100" style="margin-left: 10px;" :color="colors.mainAccent" @click="saveMarker(item)">SAVE</v-btn>
                    <!-- ALPHA FEATURE-->
                    <div v-if="templateReactiveData.dawStreamCapabilitiesEnabled" style="margin-top: 10px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
                      <v-btn small v-if="markerInitialData && markerInitialData.frame_thumbnail_tc !== markerInitialData.tc_pos" :disabled="getButtonColor(item) === 'none'" width="100" style="margin: 10px;" @click="$emit('request-screenshot', { item: item, cb: (m) => { markerInitialData = m } })">
                        <v-icon>camera</v-icon>
                      </v-btn>
                      <img
                        v-if="item && item.frame_thumbnail_tc === item.tc_pos"
                        width="320"
                        :src="item.frame_thumbnail"
                      />
                    </div>
                  </div>
                </td>
              </template>
            </v-data-table>
          </div>
      </v-flex>

        <v-dialog v-model="markerDownloadDialog" width="320" dark>
          <v-card>
            <v-card-title class="justify-center">Marker List Export</v-card-title>
            <v-card-text>
              <!-- <v-checkbox :color="colors.mainAccent" v-model="selected" label="PRO TOOLS AAF" value="pt"></v-checkbox>
              <v-checkbox :color="colors.mainAccent" v-model="selected" label="MEDIA COMPOSER AAF" value="mc"></v-checkbox> -->
              <v-btn :color="colors.mx.secondary" class="download-button lighte-1" @click="onExportMarkers('pt')">Pro Tools AAF</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button" @click="onExportMarkers('logic')">Logic Pro XML</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-1" @click="onCreateCSV(markers, getExportName(availableLanes), 'steinberg')">Nuendo CSV</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-2" @click="onCreateCSV(markers, getExportName(availableLanes), 'reaper')">Reaper CSV</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-3" @click="onCreateCSV(markers, getExportName(availableLanes), 'audition')">Audition CSV</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-4" @click="onCreateCSV(markers, getExportName(availableLanes), 'resolve')">Resolve EDL</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-5" @click="onExportMarkers('mc')">Media Composer AAF</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-6" @click="createAndDownloadMIDIfile(markers, getExportName(availableLanes))">MIDI (PT, NUENDO, DP)</v-btn>
              <v-btn :color="colors.mx.secondary" class="download-button darker-7" @click="onCreateCSV(markers, getExportName(availableLanes), 'all')">Generic CSV</v-btn>

              <!-- <v-text-field
                style="margin: auto; width: 80%; height: 50px;"
                height="60"
                outlined
                dense
                v-model="listName"
                color="white"
                label="Please give it a name"
                hide-details>
              </v-text-field> -->
            </v-card-text>
            <v-btn @click="markerDownloadDialog = false" style="margin-bottom: 20px;">CLOSE</v-btn>
            <!-- <v-btn :color="colors.mainAccent" style="margin: 0 0 20px 10px;" :disabled="listName.length < 1 || selected.length < 1" @click="onExportMarkers(), markerDownloadDialog = false">CREATE FILES</v-btn> -->
          </v-card>
        </v-dialog>
        <v-dialog v-model="bakingAAFDialog" persistent width="320">
          <v-card style="padding: 20px 0;">
            <v-card-text>
            <div v-if="!bakingAAFdone">
                Preparing files for download, <br/>please wait<br/><br/>
                <mixstage-spinner
                  :style="{ 'background-color': 'transparent', 'width': '280px', 'padding-left': '13%', 'height': '80px' }"
                ></mixstage-spinner>
                
              </div>
              <v-btn style="margin: 10px;" v-if="ptAAF" @click="download(ptAAF, '-PT.aaf')">DOWNLOAD PT MARKER</v-btn>
              <v-btn style="margin: 10px;" v-if="mcAAF" @click="download(mcAAF, '-MC.aaf')">DOWNLOAD MC MARKER</v-btn>
              <v-btn style="margin: 10px;" v-if="logic" @click="download(logic, '-LOGIC.zip')">DOWNLOAD LOGIC MARKER</v-btn>
            </v-card-text>
            <v-btn style="margin: 10px;" @click="resetModels()">CLOSE</v-btn>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
</template>

<script>
// @ts-nocheck
import App from '@/App.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import MarkerUtils from '@/../source_files/spotterfish_library/utils/MarkerUtils'
import MIDIUtils from '@/../source_files/spotterfish_library/utils/MIDIUtils'
import Timecode from '@/../source_files/spotterfish_library/utils/Timecode'
import Constants from'@/../source_files/spotterfish_library/consts'
import { assert } from '@/../source_files/spotterfish_library/SpotterfishCore'
import CalcDeltaSecondsFromUIString from '@/../source_files/spotterfish_library/CalcDeltaSecondsFromUIString'
import WebClientImplementation from '@/../source_files/web_client/WebClientImplementation'
import mixStageSpinner from '@/components/dashboard/mixStageSpinner.vue'
import SpotterfishSession from '@/../source_files/web_client/SpotterfishSession'
import CloudClient from '@/../source_files/web_client/CloudClient'


// in this module we need access to version_timecode_offset_seconds from fileStateXL
// marker timecodes should be calculated using version_timecode_offset_seconds field and new field

export default {
  name: 'markeredit',
  props: {
    updateprop_clock: undefined,
    projectDBID: {
      type: String,
      required: true
    },
    versionDBCopy: {
      type: Object,
      required: true
    },
    connectedUsers: {
      type: Array,
      required: true
    },
    userUID: {
      type: String,
      required: true
    },
    markerLane: {
      type: String,
      required: false
    },
    markers: {
      type: Array,
      required: false
    },
    availableLanes: {
      type: Array,
      required: false
    },
    isModerator: {
      type: Boolean,
      required: true
    },
    templateReactiveData: {
      type: Object,
      required: true
    }
  },
  components: {
    'mixstage-spinner': mixStageSpinner
  },
  data () {
    return {
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      /* ::::::::::::::::::::::::::::::::UI:::::::::::::::::::::::::::::::::: */
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      commentsHeaders: [
        { text: 'Seek', sortable: true, value: 'frames', class: 'narrowlist', align: 'center'},
        { text: 'Title', align: 'left', value: 'title', class: 'narrowlist' },
        { text: 'Comment', align: 'left', value: 'description', class: 'narrowlist' },
        { text: 'Cat', align: 'center', value: 'category_select', class: 'narrowlist', width: '12px' },
        { text: '', align: 'center', value: 'user', class: 'narrowlist', width: '14px' },
        { text: '', sortable: false, value: 'delete', align: 'center', class: 'narrowlist', width: '16px'  }

      ],
      colors: require('@/lib/ui/colors.js').colors,
      avatarColor: require('@/lib/ui/colors.js').colors.keppel,
      spacerColor: require('@/lib/ui/colors.js').colors.spacerColor,
      users: [],
      search: '',
      expanded: [],
      selectedMarker: undefined,
      // MODELS
      categorySelector: false,
      markerInitialData: undefined,
      markerDownloadDialog: false,
      bakingAAFDialog: false,
      bakingAAFdone: false,
      ptAAF: false,
      mcAAF: false,
      logic: false,

      listName: '',
      max32chars: v => v.length <= 32 || 'Input too long!',
      processedMarkers: []
    }
  },
  
  computed: {
    ...mapGetters([
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      /* ::::::::::::::::::STATE GETTERS, HANDLED BY VUEX:::::::::::::::::::: */
      /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
      'getConnectedUsers',
      // 'getMarkers'
    ]),
    fixedCommentTypes () {
      return this.templateReactiveData.UI_usePTMarkerColors ? Constants.FIXED_COMMENT_TYPES_PT : Constants.FIXED_COMMENT_TYPES
    },
    selectedMarkerLane() {
      return this.markerLane
    },
    commentTypes () {
      const tempArray = []
      for (var i = 0; i < this.fixedCommentTypes.length; i++) {
        tempArray.push(this.fixedCommentTypes[i].name)
      }
      return tempArray
    },
    isUserProjectModerator () {
      return this.templateReactiveData.isUserProjectModerator
    }
  },

  mounted () {
    // if (!this.connectedUsers || !this.versionDBCopy || !this.projectDBID) {
    //   this.$router.push({ name: 'dashboard' })
    //     .catch((e) => {
    //       console.log(e)
    //     })
    // }
  },
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::METHODS::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  methods: {

    handleAppendIconClick(item) {
      console.log('handleAppendIconClick', item)
      this.onResetMarkerPositionToPlayPosition(item)
    },

    onResetMarkerPositionToPlayPosition (marker) {
      const offset = this.updateprop_clock.videoFileOffset ?  this.updateprop_clock.videoFileOffset : 0
      const pos = Timecode.secondsToSMPTEString(
        this.updateprop_clock.position + offset,
        this.updateprop_clock.videoFileFrameRateKey
      )

      marker.tc_pos = pos
      console.log('onResetMarkerPositionToPlayPosition', marker, pos)
    },

    handleSelectChange(newValue) {
      this.$emit('marker-lane-selected', newValue)
    },
    getButtonColor (item) {
      const playPos = Timecode.secondsToSMPTEString(this.updateprop_clock.position + this.updateprop_clock.timecodeOffsetSeconds, this.updateprop_clock.videoFileFrameRateKey)
      const isEqual = playPos === this.calcHTMLOutputSMPTE(item.tc_pos)
      
      if (isEqual) {
        return this.colors.mainAccent
      } else {
        return ''
      }
    },
    


    itemRowBackground (item) {
      return item === this.selectedMarker ? 'selected-marker' : 'non-selected-marker'
    },
    
    calcHTMLOutputSMPTE(posSMPTE) {

      const tc = Timecode.truncateToClosestSMPTEifNeeded(posSMPTE, this.updateprop_clock.videoFileFrameRateKey)
      const posSeconds = Timecode.SMPTEStringToSeconds(tc, this.updateprop_clock.videoFileFrameRateKey)
      
      return Timecode.secondsToSMPTEString(
        Timecode.calcMarkerPositionWithOffset(posSeconds, this.updateprop_clock),
        this.updateprop_clock.videoFileFrameRateKey
      )
    },

    getNameWithDefault (nameString) {
      return nameString?.length > 0 ? nameString : 'Spotting'
    },
    getExportName (lanes) {
      console.log(lanes)
      const lane = lanes.find(x => x['.key'] === this.selectedMarkerLane)
      const name = lane?.name
      const createdDate = lane?.created_date.seconds || new Date()
      return this.getNameWithDefault(name) + '-' + this.formatDateForExport(createdDate)
    },

    toggleExpand (slotData) {

      if(!slotData){
        this.selectedMarker = this.expanded[0]
        this.expanded = [];
        return;
      }
      
      const indexExpanded = this.expanded.findIndex(i => i['.key'] === slotData.item['.key']);

      if (indexExpanded > -1) {
        this.expanded = []
        this.selectedMarker = slotData.item
      } else {
        this.expanded = [slotData.item]
        this.markerInitialData = _.cloneDeep(slotData.item)
        this.scrollIntoVIew()
        this.movePlayheadTomarkerIfPlaybackStopped(slotData.item)
      }
      this.$emit('marker-selected-in-list', indexExpanded === 0 ? undefined : slotData.item)
    },

    movePlayheadTomarkerIfPlaybackStopped (marker) {
      console.log(this.updateprop_clock)
      if (this.updateprop_clock.playFlag === false && this.updateprop_clock.mediaSyncSource === 'timing_provider') {
        this.seekToMarker(marker)
      }
    },

    handleRowClick (item) {
      this.toggleExpand({item})
    },

    async updateComment (comment) {
      try {
        const updateObject = _.cloneDeep(comment)

        updateObject.currently_updating_user = ''

        if (comment.category_select) {
          updateObject.category_select =  comment.category_select
        }

        await WebClientImplementation.updateMarker(App.Firebase, updateObject, this.userUID)
        SpotterfishSession.trackEvent(App.spotterfishSession, 'user_updated_marker_in_marker_panel', updateObject)

        MarkerUtils.processArray(App.Firebase, CloudClient, this.updateprop_clock, this.connectedUsers, this.markers)
        return
      } catch (error) {
        throw error
      } 
    },

    async onChangeCommentType (type, item) {
      item.category_select = type.name
      // await this.updateComment({...item, category_select: type.name})
    },

    removeComment (comment) {
      this.$emit('request-delete-marker', comment)
    },

    deleteMarkerLane (markerLaneID, versionDB) {
      this.spotterfishParams.deleteMarkerLane(versionDB['.key'], markerLaneID)
    },

    createAndDownloadMIDIfile (markers, listName) {
      console.log(JSON.stringify(markers))
      const jsonList = MarkerUtils.formatForMIDIFile(markers, this.updateprop_clock)
      console.log(JSON.stringify(jsonList))
      const midiBlob = MIDIUtils.jsonToMidi(jsonList)
      this.createDownloadObject(midiBlob, listName)
    },

    async onCreateCSV (markerList0, exportName, csvType) {

      assert(_.isArray(markerList0))
      assert(_.isString(exportName))
      assert(_.isString(csvType))
      
      let conf = await this.checkExportOnlySearched()
     
      const markerList = conf ? this.$refs.markersTable.$children[0].filteredItems : markerList0

      const selected = []
      const listName = this.listName
      const videoFrameRateNickname = this.versionDBCopy.video_framerate
      const videoFileStartSMPTEString = this.versionDBCopy.video_file_start_smpte
      const userID = this.userUID
      const projectKey = this.projectDBID
      const versionDBCopy = this.versionDBCopy
      const clock = this.updateprop_clock

      try {
        const exportJSONforCloudFunction = await MarkerUtils.createJSONforCloudFunctionExport(
          markerList, 
          listName, 
          videoFrameRateNickname, 
          videoFileStartSMPTEString, 
          userID, 
          projectKey,
          versionDBCopy,
          selected,
          clock
        )
  
        this.$store.dispatch('analytics_log_marker_export', exportJSONforCloudFunction)
  
        // console.log((exportJSONforCloudFunction.exported_marker_lane_JSON))
        const res = MarkerUtils.JSONtoCSV(exportJSONforCloudFunction.exported_marker_lane_JSON, csvType)
  
        const blob = new Blob([res], {
          type: `text/csv;charset=utf-8`
        })
        const name = `${exportName} - ${csvType}${csvType === 'resolve' ? '.edl' : '.csv'}`
        saveAs(blob, `${name}`)
      } catch (error) {
        await this.$root.$globalAlert.open(
            `Export error`,
            {
              line1: error
            },
            { color: this.colors.mainAccent }
          )
      }
    },

    createDownloadObject (midiBlob, listName) {
      if (typeof window !== 'undefined') {
        // This is "probbly" run in a browser
        const midiBlobForWebDownload = MIDIUtils.convertFileToBlobURLforWeb(midiBlob)

        if (!listName) {
          return 0
        } else {
          const link = document.createElement('a')
          link.href = midiBlobForWebDownload
          link.setAttribute('download', listName + '.mid')
          document.body.appendChild(link)
          link.click()
        }
      } else {
        throw new Error('No browser window, is this run in a node environment?')
        // for reference: 
        // let fs = require('fs')
        // fs.writeFileSync(markerName + '.mid', midiBlob.toBytes(), 'binary')
      }
    },

    getColorFromMarker (marker) {
      if (marker) {
        for (var i = 0; i < this.fixedCommentTypes.length; i++) {
          if (this.fixedCommentTypes[i].name === marker) {
            return this.fixedCommentTypes[i]
          }
        }
        return this.fixedCommentTypes[this.fixedCommentTypes.length - 1]
      }
    },

    scrollIntoVIew() {
      console.log('scrolling into view')
      this.$nextTick(() => {
        const selectedElement = document.getElementsByClassName('expanded-marker-edit')
        selectedElement[0].scrollIntoView({behavior: "smooth", block: "center"})
      })
    },

    onCancel (item) {
      console.log({item})
      item = this.markerInitialData
      console.log({item})
      this.$emit('request-reset-marker', item)
      this.toggleExpand({ item : item })
    },

    handleDescriptionChange(value, item){
      item.description = value
    },

    handleTitleChange(value, item){
      item.title = value
    },

    async saveMarker(item) {
      await this.updateComment(item)
      this.toggleExpand({ item: item })
    },

    calcCurrentVideoLength() {
      return this.updateprop_clock
        ? this.updateprop_clock.videoFileLengthOpt
        : 0
    },

    getInteractiveTimecodeInputText(marker) {
      return this.isUserEnteringTcText
        ? this.$refs.timecode.value
        : this.calcHTMLOutputSMPTE(marker.tc_pos)
    },

    blur() {
      this.$refs.timecode.focus()
      this.$refs.timecode.blur()
    },

    selectText() {
      this.$refs.timecode.select()
    },

    onSMPTEinputChanged(e, marker) {
      const s = e.target.value
      const a = _.cloneDeep(this.updateprop_clock)
      a.videoFileOffset = a.timecodeOffsetSeconds
      const pos = CalcDeltaSecondsFromUIString.calcDeltaSecondsFromUIString(
        a,
        this.calcCurrentVideoLength,
        s
      )
      const smpte = Timecode.secondsToSMPTEString(pos, this.updateprop_clock.videoFileFrameRateKey)
      if (smpte !== undefined) {
       marker.tc_pos = smpte
       this.blur()
      }
    },

    async checkExportOnlySearched() {
      if(this.$refs.markersTable.$children[0].filteredItems.length !== this.markers.length) {
        console.log('should open confirms')
        return await this.$root.$globalConfirm.open(
          `Export only filtered markers?`,
          {
            line1: 'You are about to export a filtered list of markers. This means that some markers will not be included in the export.',
            line3: 'Cancel will export all markers.'
          },
          { color: this.colors.mainAccent }
        )
      } else {
        return false
      }
    },

    //??? Move to screeningroom.vue
    async onExportMarkers (exportType) {
      let conf = await this.checkExportOnlySearched()
     
      const markerList = conf ? this.$refs.markersTable.$children[0].filteredItems : this.markers
      const listName = this.listName
      const videoFrameRateNickname = this.versionDBCopy.video_framerate
      const videoFileStartSMPTEString = this.versionDBCopy.video_file_start_smpte
      const userID = this.userUID
      const projectKey = this.projectDBID
      const versionDBCopy = this.versionDBCopy
      const clock = this.updateprop_clock

      this.bakingAAFDialog = true
      this.bakingAAFdone = false

      const selected =  [exportType]

      const exportJSONforCloudFunction = await MarkerUtils.createJSONforCloudFunctionExport(
        markerList, 
        listName, 
        videoFrameRateNickname, 
        videoFileStartSMPTEString, 
        userID, 
        projectKey,
        versionDBCopy,
        selected,
        clock
      )
      console.log((exportJSONforCloudFunction))

      this.$store.dispatch('analytics_log_marker_export', exportJSONforCloudFunction)

      const documentKey = await this.$store.dispatch('aaf_push_export_json_to_db', exportJSONforCloudFunction)

      // when AAF creation is done, the download URL:s will be available here
      this.$store.dispatch('aaf_listen_for_download_urls', documentKey).then((urls) => {
        this.bakingAAFdone = true
        const urlObj = JSON.parse(urls)
        this.ptAAF = urlObj.PT
        this.mcAAF = urlObj.MC
        this.logic = urlObj.LOGIC
      })
    },

    download (url, suffix) {
      this.$store.dispatch('aaf_download_from_storage', url).then((blob) => {
        const newurl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        document.body.appendChild(link)
        link.href = newurl
        link.download = this.listName + suffix
        link.click()
        window.URL.revokeObjectURL(newurl)
      })
    },

    resetModels () {
      this.bakingAAFDialog = false
      this.listName = ''
      this.ptAAF = false
      this.mcAAF = false
      this.logic = false
    },

    limitTo (text, length) {
      if (text) {
        const sliced = text.slice(0, length)
        if (sliced.length !== text.length) {
          return sliced + '...'
        } else {
          return text
        }
      }
    },

    prettyDate (date) {
      return new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    },

    seekToMarker (item) {
      const tc = Timecode.truncateToClosestSMPTEifNeeded(item.tc_pos, this.updateprop_clock.videoFileFrameRateKey)
      const seekSeconds = Timecode.SMPTEStringToSeconds(tc, this.updateprop_clock.videoFileFrameRateKey)
      const seekSeconds2 = seekSeconds - this.updateprop_clock.videoFileOffset
      this.selectedMarker = item
      this.$emit('requested-position-change', seekSeconds2)
    },
    
    formatDate (value) {
      if (value) {
        if (moment.unix(value).format(' HH:MM ') !== 'Invalid date') {
          if (navigator.languages !== undefined) {
            moment.locale(navigator.languages[0])
          } else {
            moment.locale(navigator.language)
          }
          return moment.unix(value).format('L')
        }
      }
    },
    
    formatDateForExport (value) {
      if (value) {
        if (moment.unix(value).format(' HH:MM ') !== 'Now') {
          if (navigator.languages !== undefined) {
            moment.locale(navigator.languages[0])
          } else {
            moment.locale(navigator.language)
          }
          return moment.unix(value).format('MMM Do')
        }
      }
    }
  },
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::FILTERS::::::::::::::::::::::::::::::::: */
  /* :::TODO: Make global filters: https://vuejs.org/v2/guide/filters.html::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  filters: {
    shortenfordisplay (text) {
      if (text && text.length > 15) {
        return 'ID: ' + text.slice(0, 4)
      } else {
        return text
      }
    },
    limittoone (text) {
      if (text) {
        return text.slice(0, 1)
      }
    },
    
  }
}
</script>

<style lang="scss">
@import '@/assets/global.scss';

.narrowlist {
  font-size: 0.6em;
  padding: 0 0 0 2px !important;
  margin: 0 !important;
  text-align: left !important;
  outline:none;
}

.nopadding {
  padding: 0 0 0 0 !important;
}

.narrowlist-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-data-table__expanded__row{
  td {
    background-color: #1A1A1A;
    border-color: rgba(0, 128, 0, 0) !important;
  }
}


.marker-table {
  flex: 1;
  overflow-y: auto;
  .v-data-table__wrapper{
    height: calc(80vh);
    overflow-y: auto;
  }
  
  tr {    
    cursor: pointer;
  }

  .selected-marker{
    background-color: #222;
    // animation: pulse 5s;
  }
  @keyframes pulse {
    0% {
      background-color: #222;
    }
    100% {
      background-color: none;
    }
  }
}
</style>

<style scoped lang="scss">
@import '@/assets/global.scss';



.mini-avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 2px 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mini-image {
  border-radius: 2px;
  width: 42px;
  height: 24px;
  margin: 2px 0 0 2px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mini-image-pic {
  display: block;
  max-width:42px;
  max-height:24px;
  width: auto;
  height: auto;
}

.larger-image {
  border-radius: 2px;
  width: 168px;
  height: 96px;
  margin: 2px 0 0 2px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

}

.larger-image-pic {
  display: block;
  max-width:100%;
  max-height:100%;
  width: auto;
  height: auto;
}

.smallbtn {
  margin: 0 8px 20px 8px;
  padding-top: 0;
}

.modalbutton {
  margin: 0;
  padding: 0;
}

.checkbox {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 3px;
}

.table-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.marker-title{
  width: 5rem;
}
.marker-description {
  width: 6rem;
}
.marker-table{
  td {
    padding: 0;
  }
}

.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}
.expanded-marker-edit{
  background-color: #1A1A1A;
  & > div {
    padding: 1rem 0;
  }
}
.marker-edit-category-buttons{
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem 0 2rem;
  margin-bottom: 1rem;
  button {
    font-size: 0.8em;
    flex: 1 1 20%;
    margin-left:0.25rem;
    margin-right:0.25rem;
    margin-bottom: 0.5rem;
  }
}

.download-button {
  width: 94%;
  margin: 4px;

  &.darker-1 {
    filter: brightness(97%);
  }
  &.darker-2 {
    filter: brightness(94%);
  }
  &.darker-3 {
    filter: brightness(91%);
  }
  &.darker-4 {
    filter: brightness(88%);
  }
  &.darker-5 {
    filter: brightness(85%);
  }
  &.darker-6 {
    filter: brightness(82%);
  }
  &.darker-7 {
    filter: brightness(79%);
  }

  &:hover {
    filter: brightness(110%);
  }
}

.marker-list-wrapper {
  height: calc(100vh - 194px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}



</style>
