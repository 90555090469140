/*
# 2022-05-30 Pro Tools ultimate version 2022.5.0

# Setup Protools + MTC & MMC output:
Preferences/Synchronization: Machine Follows Edit Insertion/Scrub [X], Non-Linear Devices (cues)
Setup/Peripherals/Synchronization/MTC Reader and Generator TAB/MTC Generator Port: IAC Driver, Bus 1
Setup/Peripherals/Synchronization/Machine Control TAB/ MIDI Machine Control Master [X] Enable, IAC Driver, Bus 1
TRAANSPORT/ MTC (button on / blue)

# RESULT

1. Sends no start message
2. During loop, only QF are sent, no MTC fullframe or MMC
3. At stop, MMC STOP is sent.
4. Never sends MTC full frame
5. Does not send QF in stop mode
*/




//# Playing short loop: 01:00:07:06 --- 01:00:07:24, @30fps

const kProTools_playingShortLoop =
`
12:23:46.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:46.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:46.736	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:46.744	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:46.752	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:46.760	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:46.769	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:46.777	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:46.786	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:46.794	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:46.802	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:46.810	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:46.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:46.829	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:46.836	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:46.844	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:46.852	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:46.861	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:46.869	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:46.877	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:46.886	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:46.894	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:46.902	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:46.910	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:46.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:46.935	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:46.936	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:46.944	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:46.952	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:46.960	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:46.969	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:46.977	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:46.986	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:46.994	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.002	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.011	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.042	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.042	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.044	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.052	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:47.060	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.069	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.077	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.086	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.094	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.102	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.111	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.119	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:47.145	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.145	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.145	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.152	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.160	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.169	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.177	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.186	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:47.194	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.202	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.210	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.219	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.252	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.252	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.252	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.252	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:47.261	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.269	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.277	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.286	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.294	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.302	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.310	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.319	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:47.353	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.353	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.353	To IAC Driver Bus 1	MTC Quarter Frame		48

12:23:47.353	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.360	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.369	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.377	To IAC Driver Bus 1	MTC Quarter Frame		118


12:23:47.386	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:47.394	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:47.402	To IAC Driver Bus 1	MTC Quarter Frame		39
12:23:47.411	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.419	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.459	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.459	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.459	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.459	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:47.461	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.469	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.477	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.486	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.494	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.502	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.511	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.519	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:47.561	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.561	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.561	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.561	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.561	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.569	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.577	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.586	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:47.594	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.602	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.611	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.619	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.627	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.663	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.664	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.664	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:47.664	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.669	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.677	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.686	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.694	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.702	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.711	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:47.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.765	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.765	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.765	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.765	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.769	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.777	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.786	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:47.794	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.802	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.811	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.827	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.858	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.858	To IAC Driver Bus 1	MTC Quarter Frame		118

12:23:47.858	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:47.861	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.869	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.877	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.886	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.894	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.902	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.911	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:47.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:47.927	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:47.964	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:47.964	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:47.964	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:47.964	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:47.969	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:47.977	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:47.986	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:47.994	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.002	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.011	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.027	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.059	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.059	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.059	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:48.061	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.069	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.077	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.086	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.094	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.102	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.111	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.119	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:48.127	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.161	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.161	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.161	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.161	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.169	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.177	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.186	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:48.194	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.202	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.211	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.219	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.227	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.264	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.264	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.264	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:48.264	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.269	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.277	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.286	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.294	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.302	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.311	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.319	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:48.327	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.367	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.367	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.367	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.367	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.369	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.377	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.386	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:48.394	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:48.402	To IAC Driver Bus 1	MTC Quarter Frame		40
12:23:48.411	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.419	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.427	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.467	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.467	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.467	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:48.467	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.469	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.477	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.486	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.494	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.502	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.511	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.519	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:48.527	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.536	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.567	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.567	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.567	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.569	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.577	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.586	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:48.594	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.602	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.611	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.619	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.627	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.636	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.671	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.671	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:48.671	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.671	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.677	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.686	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.694	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.702	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.711	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:48.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.736	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.776	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.776	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.776	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.776	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.777	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.786	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:48.794	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.802	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.811	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.827	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.836	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.869	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.869	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:48.869	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.869	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.877	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.886	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.894	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.902	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.911	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:48.927	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:48.936	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:48.973	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:48.973	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:48.973	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:48.973	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:48.977	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:48.986	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:48.994	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.002	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.011	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.027	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.036	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.078	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.078	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:49.078	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.078	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.078	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.086	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.094	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.102	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.111	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.119	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:49.127	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.136	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.144	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.181	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.181	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.181	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.181	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.186	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:49.194	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.202	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.211	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.219	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.227	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.236	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.244	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.275	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:49.275	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.275	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.277	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.286	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.294	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.302	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.311	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.319	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:49.327	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.336	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.344	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.377	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.377	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.377	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.377	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.386	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:49.394	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:49.402	To IAC Driver Bus 1	MTC Quarter Frame		41
12:23:49.411	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.419	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.427	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.436	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.444	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.482	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:49.482	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.482	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.482	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.486	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.494	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.502	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.511	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.519	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:49.527	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.536	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.544	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.582	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.582	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.582	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.582	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.586	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:49.594	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.602	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.611	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.619	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.627	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.636	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.644	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.679	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:49.679	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.679	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.679	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.686	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.694	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.702	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.711	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:49.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.736	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.744	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.782	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.782	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.782	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.782	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.786	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:49.794	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.802	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.811	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.827	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.836	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.844	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.852	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:49.889	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.889	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.889	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.889	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.894	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.902	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.911	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:49.927	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:49.936	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:49.944	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:49.952	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:49.990	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:49.990	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:49.990	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:49.990	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:49.994	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.002	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.011	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.027	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.036	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.044	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.052	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:50.084	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.084	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.084	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.086	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.094	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.102	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.111	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.119	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:50.127	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.136	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.144	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.152	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.189	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.189	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.189	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.189	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:50.194	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.202	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.211	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.219	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.227	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.236	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.244	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.252	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:50.292	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.292	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.292	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.292	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.294	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.302	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.311	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.319	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:50.327	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.336	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.344	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.352	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.394	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.394	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.394	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.394	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:50.394	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:50.402	To IAC Driver Bus 1	MTC Quarter Frame		42
12:23:50.411	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.419	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.427	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.436	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.444	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.452	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:50.461	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.491	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.491	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.491	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.494	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.502	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.511	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.519	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:50.527	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.536	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.544	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.552	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.561	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.593	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.593	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.593	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:50.594	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.602	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.611	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.619	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.627	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.636	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.644	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.652	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:50.661	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.693	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.693	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.693	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.694	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.702	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.711	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:50.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.736	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.744	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.752	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.761	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.799	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.799	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.799	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:50.799	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.802	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.811	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.827	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.836	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.844	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.852	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:50.861	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.895	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.895	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.895	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.895	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:50.902	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:50.911	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:50.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:50.927	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:50.936	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:50.944	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:50.952	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:50.961	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.003	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.003	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.003	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:51.003	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.003	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.011	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.027	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.036	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.044	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.052	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:51.061	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.104	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.104	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.104	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.104	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.111	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.119	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:51.127	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.136	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.144	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.152	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.161	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.169	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.209	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.209	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:51.209	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.209	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.211	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.219	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.227	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.236	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.244	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.252	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:51.261	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.269	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.300	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.300	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.300	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.302	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.311	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.319	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:51.327	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.336	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.344	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.352	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.361	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.369	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.405	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.405	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:51.405	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:51.405	To IAC Driver Bus 1	MTC Quarter Frame		43
12:23:51.411	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.419	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.427	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.436	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.444	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.452	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:51.461	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.469	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.506	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.506	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.506	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.506	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.511	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.519	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:51.527	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.536	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.544	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.552	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.561	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.569	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.612	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.612	To IAC Driver Bus 1	MTC Quarter Frame		4
12:23:51.612	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.612	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.612	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.619	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.627	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.636	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.644	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.652	To IAC Driver Bus 1	MTC Quarter Frame		6
12:23:51.661	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.669	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.705	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.705	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.705	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.705	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.711	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.719	To IAC Driver Bus 1	MTC Quarter Frame		8
12:23:51.727	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.736	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.744	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.752	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.761	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.769	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.777	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.810	To IAC Driver Bus 1	MTC Quarter Frame		10
12:23:51.810	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.810	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.811	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.819	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.827	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.836	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.844	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.852	To IAC Driver Bus 1	MTC Quarter Frame		12
12:23:51.861	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.869	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.877	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.911	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.912	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.912	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:51.919	To IAC Driver Bus 1	MTC Quarter Frame		14
12:23:51.927	To IAC Driver Bus 1	MTC Quarter Frame		16
12:23:51.936	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:51.944	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:51.952	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:51.961	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:51.969	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:51.977	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:52.014	To IAC Driver Bus 1	MTC Quarter Frame		0
12:23:52.014	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:52.014	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:52.014	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:52.019	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:52.027	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:52.036	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:52.044	To IAC Driver Bus 1	MTC Quarter Frame		118
12:23:52.052	To IAC Driver Bus 1	MTC Quarter Frame		2
12:23:52.061	To IAC Driver Bus 1	MTC Quarter Frame		17
12:23:52.069	To IAC Driver Bus 1	MTC Quarter Frame		44
12:23:52.077	To IAC Driver Bus 1	MTC Quarter Frame		48
12:23:52.111	To IAC Driver Bus 1	MTC Quarter Frame		64
12:23:52.111	To IAC Driver Bus 1	MTC Quarter Frame		80
12:23:52.111	To IAC Driver Bus 1	MTC Quarter Frame		97
12:23:52.111	To IAC Driver Bus 1	SysEx		Universal Real Time 6 bytes	F0 7F 7F 06 01 F7
12:23:52.111	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 07 26 00 F7
`

//# Some locates by clicking timeline in stop mode

const kProTools_locates =
`
12:18:44.368	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 00 20 00 F7
12:18:44.382	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 00 20 00 F7
12:18:44.425	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 00 20 00 F7
12:18:44.428	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 00 20 00 F7
12:18:45.152	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 02 2C 00 F7
12:18:45.204	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 02 2C 00 F7
12:18:45.234	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 02 2C 00 F7
12:18:45.234	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 02 2C 00 F7
12:18:45.822	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 04 38 00 F7
12:18:45.873	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 04 38 00 F7
12:18:45.900	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 04 38 00 F7
12:18:45.901	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 04 38 00 F7
12:18:46.581	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 07 26 00 F7
12:18:46.626	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 07 26 00 F7
12:18:46.679	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 07 26 00 F7
12:18:46.680	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 07 26 00 F7
`

//# Play linearly from pos 0. Notice: sends MMC STOP *and* MMC LOCATE when user hits stop

const kProTools_playLinearAndStop =
`
12:19:48.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.690	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:48.690	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:48.690	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:48.690	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:48.695	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.703	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.720	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.728	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:48.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:48.745	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:48.753	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:48.793	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.793	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.793	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.793	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.795	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:48.803	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:48.812	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:48.820	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:48.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.845	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.886	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:48.886	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:48.886	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:48.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:48.895	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.904	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.920	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:48.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:48.946	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:48.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:48.991	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:48.991	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:48.991	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:48.991	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:48.995	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:49.004	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.012	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:49.020	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.029	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.037	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.045	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.093	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:49.093	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.093	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:49.093	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.095	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.112	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.120	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:49.137	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.145	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:49.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.196	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.196	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.196	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.196	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.196	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:49.204	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.212	To IAC Driver Bus 1	MTC Quarter Frame		34
12:19:49.220	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.229	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.245	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:49.293	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.293	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.293	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.295	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.304	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.312	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.320	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.329	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:49.337	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.345	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.354	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.396	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.396	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.396	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.396	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:49.404	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.412	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.420	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.437	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.445	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:49.500	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.500	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.500	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.500	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.504	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.512	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.520	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:49.537	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.545	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.603	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.603	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.603	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.603	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:49.604	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.612	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.620	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.662	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:49.696	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.696	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.696	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.696	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.704	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.720	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.729	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:49.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:49.745	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.754	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.802	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.802	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.802	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.802	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:49.804	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.812	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.820	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.845	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:49.905	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.905	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.905	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.905	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.905	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:49.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:49.920	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:49.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:49.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:49.945	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:49.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:49.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:49.970	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.008	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.008	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.008	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:50.008	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.012	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:50.020	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.029	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.037	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.045	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:50.070	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.106	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:50.106	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.106	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.106	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.112	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.120	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:50.137	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.145	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:50.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.170	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.208	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.209	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.209	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:50.209	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.212	To IAC Driver Bus 1	MTC Quarter Frame		35
12:19:50.220	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.229	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.245	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:50.270	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.308	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.308	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.308	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.308	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.312	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.320	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.329	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:50.337	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.345	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.354	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.370	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.413	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.413	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.413	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:50.413	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.413	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.420	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.437	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.445	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:50.470	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.509	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.509	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.509	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.509	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.512	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.520	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:50.537	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.545	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.615	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.615	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:50.615	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.615	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.620	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.662	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:50.670	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.679	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.716	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.716	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.716	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.716	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.720	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.729	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:50.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:50.745	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.754	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.770	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.816	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.816	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:50.816	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.816	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.820	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.846	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:50.870	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.879	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.912	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.912	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.912	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:50.920	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:50.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:50.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:50.945	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:50.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:50.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:50.970	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:50.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.014	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.014	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:51.015	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.015	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:51.020	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.029	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.037	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.045	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:51.070	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.079	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:51.116	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.116	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.116	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.116	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.120	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:51.137	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.147	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:51.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.170	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.224	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.224	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:51.224	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.224	To IAC Driver Bus 1	MTC Quarter Frame		36
12:19:51.224	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.229	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.245	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:51.270	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.279	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.316	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.316	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.316	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.316	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.320	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.329	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:51.337	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.345	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.354	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.370	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.420	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.420	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:51.420	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.420	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.420	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.437	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.445	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:51.470	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.479	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.521	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.521	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.521	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.521	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:51.537	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.545	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.627	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:51.627	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.627	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.627	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.679	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:51.679	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.679	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.705	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.705	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.720	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.729	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:51.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:51.745	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.754	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.770	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.822	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:51.822	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.822	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.822	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.846	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:51.870	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.879	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.926	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.926	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.926	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.926	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:51.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:51.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:51.945	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:51.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:51.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:51.970	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:51.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:51.987	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.032	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:52.032	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.032	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:52.032	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.032	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.037	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.045	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:52.070	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.079	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:52.087	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.125	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.125	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.125	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.125	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:52.137	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.145	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:52.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.171	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.187	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.232	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:52.232	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.232	To IAC Driver Bus 1	MTC Quarter Frame		37
12:19:52.232	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.232	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.245	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:52.270	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.279	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.287	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.296	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.332	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.333	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.333	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.333	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:52.337	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.345	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.354	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.370	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.387	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.395	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:52.425	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.425	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.425	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.437	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.446	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:52.470	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.479	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.495	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.528	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.528	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.528	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:52.537	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.546	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.595	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:52.633	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.633	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.633	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.633	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.662	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:52.670	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.679	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.695	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.736	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.736	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.736	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.736	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:52.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:52.745	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.754	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.770	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.795	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:52.831	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.831	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.831	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.831	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.845	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:52.870	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.879	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.897	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.934	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.934	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.934	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.934	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:52.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:52.945	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:52.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:52.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:52.970	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:52.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:52.987	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:52.996	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:53.004	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.038	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:53.038	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.038	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.038	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.045	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:53.070	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.079	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:53.087	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.095	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.141	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.141	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.141	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:53.141	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.145	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:53.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.170	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.187	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.195	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:53.204	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.236	To IAC Driver Bus 1	MTC Quarter Frame		38
12:19:53.236	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.236	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.245	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:53.271	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.279	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.287	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.295	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.304	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.339	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.339	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.339	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:53.339	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.345	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.354	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.370	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.387	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.396	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:53.404	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.445	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.445	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.445	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.445	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.445	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:53.470	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.479	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.495	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.504	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.546	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.546	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.546	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:53.546	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.546	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.596	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:53.604	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.612	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.640	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.640	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.641	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.645	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.654	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.662	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:53.671	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.679	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.696	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.704	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.745	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.745	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:53.745	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:53.745	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.754	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.770	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.795	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:53.804	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.812	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.848	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.848	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.848	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.848	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.854	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:53.870	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.879	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.895	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.904	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.951	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.951	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:53.951	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:53.951	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:53.954	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:53.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:53.970	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:53.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:53.987	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:53.995	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:54.004	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.012	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:54.046	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.046	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.046	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.046	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.054	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:54.070	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.079	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:54.087	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.095	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.112	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.148	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.148	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:54.148	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.148	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:54.154	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.170	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.187	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.196	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:54.204	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.212	To IAC Driver Bus 1	MTC Quarter Frame		39
12:19:54.252	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.252	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.252	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.252	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.254	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.262	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:54.270	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.279	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.287	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.295	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.304	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.312	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.358	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.358	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:54.358	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.358	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.358	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.370	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.387	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.396	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:54.404	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.412	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.420	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.451	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.451	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.451	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.454	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.462	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:54.471	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.479	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.495	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.504	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.512	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.520	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.552	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:54.552	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.552	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.554	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.562	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.595	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:54.604	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.612	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.621	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.657	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.657	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.658	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.658	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.662	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:54.670	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.679	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.696	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.704	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.721	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.756	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:54.756	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:54.756	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.756	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.762	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.771	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.796	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:54.804	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.812	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.820	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.856	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.856	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.856	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.856	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.862	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:54.870	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.879	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.896	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.904	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.920	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.958	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:54.958	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:54.958	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:54.958	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:54.962	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:54.971	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:54.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:54.987	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:54.996	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:55.004	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.012	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:55.020	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.062	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.062	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.062	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.062	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.062	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:55.071	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.079	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:55.087	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.096	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.112	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.120	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:55.162	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.162	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:55.162	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.162	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.171	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.187	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.196	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:55.204	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.212	To IAC Driver Bus 1	MTC Quarter Frame		40
12:19:55.220	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.229	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.264	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.265	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.265	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.265	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:55.270	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.279	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.287	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.295	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.304	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.312	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.321	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.329	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:55.362	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.362	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.362	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.362	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.371	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.387	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.396	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:55.404	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.412	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.421	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.463	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.463	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.463	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.463	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:55.471	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.479	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.496	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.504	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.512	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.521	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:55.564	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.564	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.564	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.565	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.570	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.596	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:55.604	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.612	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.621	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.663	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.664	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.664	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.664	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:55.671	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.679	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.696	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.704	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.721	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.729	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:55.764	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:55.764	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.764	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.764	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.770	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.796	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:55.804	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.812	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.821	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.867	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.867	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.867	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.867	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:55.871	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.879	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.896	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.904	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.921	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:55.968	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:55.968	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:55.968	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:55.968	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:55.971	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:55.979	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:55.987	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:55.996	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:56.004	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.012	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:56.021	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.029	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.037	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.071	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.071	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.071	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:56.071	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.079	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:56.087	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.096	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.104	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.112	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.121	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.129	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:56.137	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.171	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:56.172	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.172	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.172	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.179	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.187	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.196	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:56.204	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.212	To IAC Driver Bus 1	MTC Quarter Frame		41
12:19:56.221	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.229	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.237	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.269	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.269	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.269	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:56.271	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.279	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.287	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.296	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.304	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.312	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.321	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.329	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:56.337	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.373	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.373	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.373	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.373	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.379	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.387	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.396	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:56.404	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.412	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.421	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.429	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.437	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.472	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.472	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.472	To IAC Driver Bus 1	MTC Quarter Frame		6
12:19:56.472	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.479	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.487	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.496	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.504	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.512	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.521	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.529	To IAC Driver Bus 1	MTC Quarter Frame		8
12:19:56.537	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.574	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.574	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.574	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.574	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.579	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.587	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.596	To IAC Driver Bus 1	MTC Quarter Frame		10
12:19:56.604	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.612	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.621	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.629	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.637	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.678	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.678	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.678	To IAC Driver Bus 1	MTC Quarter Frame		12
12:19:56.679	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.679	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.687	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.696	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.704	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.712	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.721	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.729	To IAC Driver Bus 1	MTC Quarter Frame		14
12:19:56.737	To IAC Driver Bus 1	MTC Quarter Frame		16
12:19:56.778	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.778	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.778	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.779	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.779	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.787	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.796	To IAC Driver Bus 1	MTC Quarter Frame		0
12:19:56.804	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.812	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.821	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.829	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.837	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.846	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.879	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.879	To IAC Driver Bus 1	MTC Quarter Frame		2
12:19:56.879	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.879	To IAC Driver Bus 1	MTC Quarter Frame		42
12:19:56.887	To IAC Driver Bus 1	MTC Quarter Frame		48
12:19:56.896	To IAC Driver Bus 1	MTC Quarter Frame		64
12:19:56.904	To IAC Driver Bus 1	MTC Quarter Frame		80
12:19:56.912	To IAC Driver Bus 1	MTC Quarter Frame		97
12:19:56.921	To IAC Driver Bus 1	MTC Quarter Frame		118
12:19:56.929	To IAC Driver Bus 1	MTC Quarter Frame		4
12:19:56.937	To IAC Driver Bus 1	MTC Quarter Frame		17
12:19:56.942	To IAC Driver Bus 1	SysEx		Universal Real Time 6 bytes	F0 7F 7F 06 01 F7
12:19:56.943	To IAC Driver Bus 1	SysEx		Universal Real Time 13 bytes	F0 7F 7F 06 44 06 01 61 00 00 20 00 F7
`


//	Pro tools 02:46:30.00 - 02:46:32:00 (start -> 4 times loop -> stoop command @30fps)
const kProTools_loop2 =
`
71433.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71433.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71433.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71433.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71433.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71433.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71433.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71433.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71433.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71433.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71433.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71433.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71433.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71433.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71433.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71433.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71433.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71433.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71434.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71434.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71434.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71434.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71434.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71434.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71434.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71434.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71434.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71434.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71434.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71434.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 2E
71434.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71434.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71434.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71434.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71434.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71434.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71434.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71434.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71434.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71434.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71434.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71434.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71434.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71434.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71435.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71435.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71435.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71435.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71435.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71435.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71435.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71435.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71435.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71435.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71435.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71435.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 2F
71435.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 31
71435.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71435.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71435.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71435.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71435.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71435.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71435.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71435.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71435.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71435.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71435.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71435.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71435.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71435.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71435.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71435.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71436.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71436.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71436.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71436.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71436.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71436.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71436.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71436.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71436.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71436.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71436.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71436.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 20
71436.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71436.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71436.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71436.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71436.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71436.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71436.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71436.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71436.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71436.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71436.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71436.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71436.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71436.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71437.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71437.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71437.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71437.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71437.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71437.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71437.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71437.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71437.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71437.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71437.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71437.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 21
71437.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71437.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71437.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71437.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71437.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71437.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71437.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71437.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71437.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71437.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71437.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71437.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71437.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71437.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71438.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71438.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71438.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71438.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71438.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71438.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71438.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71438.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71438.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71438.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71438.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71438.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 22
71438.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71438.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71438.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71438.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71438.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71438.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71438.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71438.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71438.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71438.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71438.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71438.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71438.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71438.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71439.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71439.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71439.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71439.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71439.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71439.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71439.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71439.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71439.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71439.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71439.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71439.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 23
71439.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71439.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71439.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71439.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71439.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71439.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71439.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71439.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71439.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71439.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71439.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71439.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71439.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71439.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71440.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71440.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71440.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71440.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71440.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71440.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71440.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71440.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71440.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71440.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71440.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71440.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 24
71440.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71440.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71440.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71440.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71440.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71440.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71440.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71440.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71440.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71440.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71440.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71440.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71440.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71440.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.032	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.040	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.048	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.057	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71441.065	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.073	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.082	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.090	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.098	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.107	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.115	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.123	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71441.132	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.140	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.148	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.157	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.165	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.173	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.182	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.190	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71441.198	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.207	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.215	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.223	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.232	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.240	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.248	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.257	From IAC Driver Bus 1	MTC Quarter Frame		F1 0E
71441.265	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.273	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.282	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.290	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.298	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.307	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.315	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.323	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71441.332	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.340	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.348	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.357	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.365	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.373	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.382	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.390	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71441.398	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.407	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.415	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.423	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.432	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.440	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.448	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.457	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71441.465	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.473	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.482	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.490	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.498	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.507	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.515	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.523	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71441.532	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.540	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.548	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.557	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.565	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.573	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.582	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.590	From IAC Driver Bus 1	MTC Quarter Frame		F1 08
71441.598	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.607	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.615	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.623	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.632	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.640	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.648	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.657	From IAC Driver Bus 1	MTC Quarter Frame		F1 0A
71441.665	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.673	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.682	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.690	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.698	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.707	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.715	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.723	From IAC Driver Bus 1	MTC Quarter Frame		F1 0C
71441.732	From IAC Driver Bus 1	MTC Quarter Frame		F1 11
71441.740	From IAC Driver Bus 1	MTC Quarter Frame		F1 25
71441.748	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.757	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.765	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.773	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.782	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.790	From IAC Driver Bus 1	MTC Quarter Frame		F1 00
71441.798	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.807	From IAC Driver Bus 1	MTC Quarter Frame		F1 26
71441.815	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.823	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.832	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.840	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.848	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.857	From IAC Driver Bus 1	MTC Quarter Frame		F1 02
71441.865	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.873	From IAC Driver Bus 1	MTC Quarter Frame		F1 26
71441.882	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.890	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.898	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.907	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.915	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
71441.923	From IAC Driver Bus 1	MTC Quarter Frame		F1 04
71441.932	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71441.940	From IAC Driver Bus 1	MTC Quarter Frame		F1 26
71441.948	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71441.957	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
71441.965	From IAC Driver Bus 1	MTC Quarter Frame		F1 52
71441.973	From IAC Driver Bus 1	MTC Quarter Frame		F1 62
71441.982	From IAC Driver Bus 1	MTC Quarter Frame		F1 76
0.000	From IAC Driver Bus 1	SysEx		F0 7F 7F 06 01 F7
0.000	From IAC Driver Bus 1	SysEx		F0 7F 7F 06 44 06 01 62 2E 1E 24 00 F7
71441.990	From IAC Driver Bus 1	MTC Quarter Frame		F1 06
71441.998	From IAC Driver Bus 1	MTC Quarter Frame		F1 10
71442.007	From IAC Driver Bus 1	MTC Quarter Frame		F1 26
71442.015	From IAC Driver Bus 1	MTC Quarter Frame		F1 32
71442.023	From IAC Driver Bus 1	MTC Quarter Frame		F1 4E
`
module.exports = {
	kProTools_playingShortLoop,
	kProTools_locates,
	kProTools_playLinearAndStop,
	kProTools_loop2
}

