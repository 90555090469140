// This file must be a complete abstraction from Vue, Vuex etc. and code in here is not
// allowed to depend on any such objects, imports etc.

//  Firebase on()-function
//  https://firebase.google.com/docs/reference/node/firebase.database.Reference#on
//  on() returns no value. Callback is called later, when data changes.
//  MS: The callback will be called with current data, even if data doesn't change. Callback happens when data arrives, not synchronousl inside on()

//  https://firebase.google.com/docs/reference/js/v8/firebase.database.Query#on
//  "This is the primary way to read data from a Database. Your callback will be triggered for the initial data and again whenever the data changes. Use off( ) to stop receiving updates. See Retrieve Data on the Web for more details."

//  IMPORTANT: Firebase on() calls the callback reentrantly if callback function uses await!!

/*
moderator_RequestProject():
  call cloudfunction
    updates firebase: screeningRoomDB
    update firestore: sharedState

??? We do not know in which order updates happens for this or other clients.

Firebase and firestore caches updates that are performed locally
*/

const CryptoJS = require('crypto-js')

const SpotterfishCore = require('../spotterfish_library/SpotterfishCore')
const assert = SpotterfishCore.assert
const isArrayInstance = SpotterfishCore.isArrayInstance
const isStringInstance = SpotterfishCore.isStringInstance
const isNumberInstance = SpotterfishCore.isNumberInstance
const isObjectInstance = SpotterfishCore.isObjectInstance
const MasterClock = require('../spotterfish_library/MasterClock')
const SyncData = require('../spotterfish_library/SyncData')

const Timecode = require('../spotterfish_library/utils/Timecode')
const SpotterfishSession = require('./SpotterfishSession')
const CloudClient = require('./CloudClient')
const Constants = require('../spotterfish_library/consts')

const ScreeningRoomConnection = require('./ScreeningRoomConnection')

const SharedStateTiming = require('../web_client/SharedStateTiming.js')


const _ = require('lodash')


function checkInvariantScreeningRoomSession (s) {
  // on hot reload screeningRoomSession may be undefined
  // let's allow it
  if (s === undefined) return true

  assert(SpotterfishCore.isVueReactive(s) === false)

  // TODO: Check presence etc. for relevant database fields.
  assert(SpotterfishCore.isObjectInstance(s))


  assert(ScreeningRoomConnection.checkInvariantScreeningRoomConnection(s.screeningRoomConnection))

  // assert(SpotterfishCore.isObjectInstance(s.mcorpLib))

  assert(MasterClock.checkInvariantMasterClock(s.masterClock))
  assert(_.isNumber(s.dawPackageTimestamp) && s.dawPackageTimestamp >= 0)

  assert(SpotterfishCore.isFunctionInstance(s.userIsActiveStreamerListener))

  // TODO: this may be null or an object
  // assert(SpotterfishCore.isObjectInstance(s.screeningRoomListener))
  assert(SpotterfishCore.isArrayInstance(s.initialConnectedUsers))

  return true
}

function getUsersInLobby (screeningRoomDB) {
  // Always returns an array with users.

  assert(ScreeningRoomConnection.checkInvariantScreeningRoomDB(screeningRoomDB))

  if (screeningRoomDB.requesting_access) {
    assert(Array.isArray(screeningRoomDB.requesting_access))
    return screeningRoomDB.requesting_access
  } else {
    return []
  }
}

function getUsersInLobby2 (session2) {
  assert(checkInvariantScreeningRoomSession(session2))
  return getUsersInLobby(session2.screeningRoomConnection.screeningRoomDBCopy)
}

// TODO: Refactor to not depend on process
function decryptSecret (hash) {
  console.log(hash)
  const queryObject = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_HASH_KEY)
  return JSON.parse(queryObject.toString(CryptoJS.enc.Utf8))
}

function encryptSecret (obj) {
    try {
      const cipherText = CryptoJS.AES.encrypt(
        JSON.stringify(obj),
        process.env.VUE_APP_HASH_KEY
      ).toString()
      return cipherText
    } catch (error) {
      throw error      
    }
}

function setScreeningRoomDBChangedCallback(s, f){
  assert(checkInvariantScreeningRoomSession(s))

  ScreeningRoomConnection.setScreeningRoomDBChangedCallback(s.screeningRoomConnection, f)

  assert(checkInvariantScreeningRoomSession(s))
}

function setSharedStateChangedCallback(s, f){
  assert(checkInvariantScreeningRoomSession(s))

  ScreeningRoomConnection.setSharedStateChangedCallback(s.screeningRoomConnection, f)

  assert(checkInvariantScreeningRoomSession(s))
}


const openTimingProvider = async (mcorpParams, timingProviderSource) => {
  assert(SpotterfishCore.isObjectInstance(mcorpParams))
  assert(SpotterfishCore.isStringInstance(timingProviderSource) && (timingProviderSource === 'mcorp_timing_provider' || timingProviderSource === 'shared_state_timing'))

  try {
    if (timingProviderSource === 'mcorp_timing_provider') {
      let temp = await initMCorpTimingProvider(mcorpParams.mcorpLib, mcorpParams.motion_app_id, mcorpParams.motion_name)
      temp.__timingProviderSource = timingProviderSource
      return temp
    }
    else {
      // Shared state timing is always available, but does not follow the timingProvider specs. Returning a dummy object.
      let temp = {}
      temp.__timingProviderSource = timingProviderSource
      return temp
    }
  } catch (error) {
    // TODO: rollback??? Are there side effects?
    throw (error)
  }
}

const closeTimingProvider = (timingProvider) => {
  // destroy can not be performed on MCORP
  // Shared state timing provider has no close mechanism
}


const initMCorpTimingProvider = async (MCorp, motion_app_id, motion_name) => {
  // DETAILS: http://dev.mcorp.no/app_api.html
  const motionApp = MCorp.app(
    motion_app_id,
    { anon: true, msvs: [motion_name] }
  )

  let connectionState = undefined

  //  NOTICE: We block on motionApp.init() has entered 'open' state.
  await /** @type {Promise<void>} */(new Promise(
    (resolve, reject) => { 
      motionApp.on(
        'readystatechange',
        (newState) => {
          if (!connectionState || connectionState === 'closed') {
            connectionState = newState 
          }
          else if (connectionState === 'init' && newState === 'closed') {
            console.log('[[sync]] CONNECTION FAILED')
            connectionState = newState 
            reject(new Error('Could not open motion app'))
          }
          else if (newState === 'open') {
            console.log('[[sync]] properly connected to the sync server')
            connectionState = newState
            resolve()
          }
          else if (newState === 'closed') {
            console.log('[[sync]] closed connection to sync server')
            connectionState = newState 
          }
        }
      )
      motionApp.init()
    }
  ))
  motionApp.off('readystatechange')

  const timingProvider = motionApp.motions[motion_name]

  // For compatibility when using MCORP and our timingprovider - add these
  // Patches the timing provider object
  {
    timingProvider.addEventListener = (...args) => {
      if (args[0] !== 'adjust') {
          return timingProvider.on(...args)
      }
    }

    timingProvider.removeEventListener = (...args) => {
        if (args[0] !== 'adjust') {
            return timingProvider.off(...args)
        }
    }
    let readyState = 'connecting'
    Object.defineProperty(timingProvider, 'readyState', { get: () => readyState })
    timingProvider.on('readystatechange', (event) => readyState = event)
    const update = timingProvider.update
    timingProvider.update = (...args) => {
        update.apply(timingProvider, args)
    
        return Promise.resolve()
    }
  }

  timingProvider.on('change', (e) => {
    console.log('[[sync]] timing provider updated', e)
  })
  return timingProvider
}


//  A web client X (except dawstream clients) monitors the global active_streamers.
//  If it finds a peer daw-streamer client (same user_id), it attempts to store the screening room session's ID
//  in the active_streamers, so the daw-streamer can connect to janus etc.
async function screeningRoomSession__onActiveStreamersSnapshot(srs, snapshot, currentUserId, spotterfishSession, currentRoomId){
  assert(checkInvariantScreeningRoomSession(srs))
  assert(_.isObject(snapshot))

  if (!snapshot.empty) {
    console.log('[[stream]] Got a snapshot from active streamers')
    snapshot.forEach(async (doc) => {
      if (doc.data().current_screening_room_session !== currentRoomId) {
        console.log(`user ${ currentUserId } will be added as a daw streamer`)

        const res = await CloudClient.call_CFupdateActiveDAWStreamer(spotterfishSession.firebase, currentRoomId, true)
        console.log(res)
      }
    })
  }
}



// Follows API contract guides.
// WARNING: errorCallback can called before this function returns
async function openScreeningRoomSession (
  spotterfishSession,
  mcorpLib,
  hash0,
  errorCallback,
) {
  // assert(checkInvariantSpotterfishSession(spotterfishSession))
  // ???


  let screeningRoomConnection = undefined

  //  These are effect-related variables that needs to be closed on exceptions.
  let timingProviderEffect = undefined
  let masterClockEffect = undefined
  let userIsActiveStreamerListenerEffect = () => {}

  const hash = decryptSecret(hash0)

  // TODO: should we look at which user the email was for here as well?
  console.debug('invited user was: ' + hash.email)
  console.debug('invited user was invited by: ' + hash.invitedBy)

  // First of all, set up the realtime database session, since write permissions for
  // any data in that state depends on the user ID being in there in the first place.
  const currentUserId = spotterfishSession.userSession.firebaseCurrentUser.uid
  const currentRoomId = hash.sroom
  const invitedBy = hash.invitedBy
    
  console.log(`[[session]] Initiating session allocation using userID = ${ currentUserId } and roomId = ${ currentRoomId }`)

  try {
    const { session, motion_app_id, motion_name } = await ScreeningRoomConnection.openScreeningRoomConnection(
      spotterfishSession,
      hash.sroom,

      //  fuseBlownFunc()
      (e) => {
        errorCallback(e)
      }
    )
    screeningRoomConnection = session



    // This switch 'timingProviderSource' is responsible for handling different behaviours of chris and mcorp timing providers during setup
    const timingProviderSource = 'shared_state_timing'

    const mcorpParams = {
      mcorpLib: mcorpLib,
      motion_app_id: motion_app_id,
      motion_name: motion_name
    }

    // if second argument is 'janus_timing_provider' we use Chris' TP, if string is 'mcorp_timing_provider' we use timing_provider, if string is 'shared_state_timing' we use our own, it is also a fallback
    timingProviderEffect = await openTimingProvider(mcorpParams, timingProviderSource)



    masterClockEffect = await MasterClock.createMasterClock(
      timingProviderEffect,
      //  IMPORTANT: This keeps coming regardless which sync mode (daw/mcorp) we are using. The MCorp TO always exists.
      (pos) => {},
      // 2024-06-> default value is 12 hours length
      12 * 3600,
      'timing_provider'
    )


    // METRICS
    // TODO: Maybe defined logging and analytics centrally in SpotterfishCore so we can
    // just shut off e.g. analytics globally instead of having checks like these.
    if (spotterfishSession.firebaseAnalytics) {

      const params = {
        userUID: currentUserId,
        screeningRoomUID: screeningRoomConnection.screeningRoomDBCopy['.key'],
        invitedBy: invitedBy
      }
      
      SpotterfishSession.trackEvent(spotterfishSession, 'entered_screening_room', params)
      
    }

    // ?? we both get the objects and added a cb to update them 
    // this needs to listen for changes in user objects, this chain is obsolete
    const initialConnectedUsers = await SpotterfishSession.getUserObjects(
      spotterfishSession.firebase,
      screeningRoomConnection.screeningRoomDBCopy.people_seated
    )

    const screeningRoomSession = {
      screeningRoomConnection: screeningRoomConnection,
      mcorpLib: mcorpLib,

      timingProvider: timingProviderEffect,

      masterClock: masterClockEffect,
      dawPackageTimestamp: 0,

      userIsActiveStreamerListener: undefined,

      initialConnectedUsers: initialConnectedUsers
    }


    //  A web client X (except dawstream clients) monitors the global active_streamers.
    //  If it finds a peer daw-streamer client (same user_id), it attempts to store the screening room session's ID
    //  in the active_streamers, so the daw-streamer can connect to janus etc.
    userIsActiveStreamerListenerEffect = spotterfishSession.firestoreDB
    .collection('active_streamers')
    .where('owner_user_id', '==', currentUserId)
    .onSnapshot(
      async (snapshot) => {
        await screeningRoomSession__onActiveStreamersSnapshot(screeningRoomSession, snapshot, currentUserId, spotterfishSession, currentRoomId)
      },
      async (error) => {
        await errorCallback(error)
      }
    )
    // @ts-ignore
    screeningRoomSession.userIsActiveStreamerListener = userIsActiveStreamerListenerEffect


    assert(checkInvariantScreeningRoomSession(screeningRoomSession))

    return screeningRoomSession
  }

  catch(error){
    // TODO: Catch all exceptions and call Sentry.captureException(error) AND reject.

    userIsActiveStreamerListenerEffect()
    
    if(masterClockEffect !== undefined){
      MasterClock.closeMasterClock(masterClockEffect)
      masterClockEffect = undefined
    }

    if(timingProviderEffect !== undefined){
      closeTimingProvider(timingProviderEffect)
      timingProviderEffect = undefined
    }



//??? Is this OK?
    if(screeningRoomConnection !== undefined){
      ScreeningRoomConnection.updateUserSharedState2(
        screeningRoomConnection.sharedStateID,
        { video_chat_ready: false, daw_stream_ready: false }
      )
    }
/*    
    if(videoChatEffect !== undefined && screeningRoomConnection !== undefined){
      ScreeningRoomConnection.updateUserSharedState2(screeningRoomConnection.sharedStateID, { video_chat_ready: false, daw_stream_ready: false })
    
      try {
        await videoChatEffect.leaveRoomAndDeleteIfEmpty()
      } catch (error) {
        console.log(error)
      }
    }
*/



    throw error
  } 
}

// MSR: needs to be able to be called several times
async function closeScreeningRoomSession (s) {
  // assert(checkInvariantScreeningRoomSession(s))
  try {
    s.userIsActiveStreamerListener()    
  } catch (error) {
    console.log('userIsActiveStreamerListener could not be called', error)
  }

  if (s.masterClock) {
    MasterClock.closeMasterClock(s.masterClock)
    s.masterClock = undefined
  }
  if (s.screeningRoomConnection) {
    ScreeningRoomConnection.closeScreeningRoomConnection(s.screeningRoomConnection)
    s.screeningRoomConnection = undefined
  }
}

async function updateScreeningRoomSession(srs){
  assert(checkInvariantScreeningRoomSession(srs))

  // ??? 
  await ScreeningRoomConnection.updateScreeningRoomConnection(srs.screeningRoomConnection)

  assert(checkInvariantScreeningRoomSession(srs))
}


function generateURLtoScreeningRoom (options) {
  assert(SpotterfishCore.isObjectInstance(options))
  let routerInfo
  if (options.playerOnly) {
    routerInfo = 'quicklogin?hash='
  } else {
    routerInfo = 'screeningroom?hash='
  }
  let baseUrl = options.baseUrl
  if (!options.baseUrl) {
    baseUrl = process.env.VUE_APP_BASE_URL
  }
  // TODO MSR, for audience rooms we should add an expiry date here
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(
    {
      sroom: options.screeningRoom, 
      email: options.email, 
      invitedBy: options.invitedBy, 
      inviterUsername: options.inviterUsername,
      playerOnly: options.playerOnly || false,
      projectId: options.projectId || undefined,
    }), process.env.VUE_APP_HASH_KEY).toString()
  
  return baseUrl + routerInfo + encodeURIComponent(cipherText)
}

async function checkIfScreeningRoomExists (firestoreDB, screeningRoomId) {
  assert(isObjectInstance(firestoreDB))
  assert(isStringInstance(screeningRoomId))
  const doc = await firestoreDB.collection('screening_rooms').doc(screeningRoomId).get()
  return doc.exists
}

async function getInviterNameFromURLparams (hash) {
  assert(SpotterfishCore.isStringInstance(hash))
  if (hash) {
    const link = decryptSecret(hash)
    return link.inviterUsername || 'Spotterfishuser'
  }
  return 'Spotterfishuser'
}

async function getStreamingUsersArrayFromActiveStreamers(firebase, activeUsers) {
  let users = [{
    user_name: Constants.STATIC_AUDIO_USERNAME,
    user_id: Constants.STATIC_AUDIO_STRING,
    divider: false,
    seat: undefined

  }, {
    user_name: Constants.STATIC_AUDIO_USERNAME,
    user_id: Constants.STATIC_AUDIO_STRING,
    divider: true,
    header: '______________________________',
    seat: undefined
  }]

  const userArr = _.isArray(activeUsers) && activeUsers.length === 0 ? [] : await SpotterfishSession.getUserObjects(firebase, activeUsers)

  for (const u of userArr) {
    users.push(u)
  }
  
  return users
}


function setProjectToError (s) {
  assert(checkInvariantScreeningRoomSession(s))

  const fileStatePath = `shared_state/${ s.screeningRoomConnection.sharedStateID }/file_state/`
  const fileStateRef = s.screeningRoomConnection.spotterfishSession.firebase.database().ref(fileStatePath)
  fileStateRef.set('error')
}

async function ingestLiveStream(s, userId, flag, format) {
  assert(checkInvariantScreeningRoomSession(s))
  assert(isStringInstance(userId))

  const path = `shared_state/${ s.screeningRoomConnection.sharedStateID }/ingested_feed_active/`
  const stateRef = s.screeningRoomConnection.spotterfishSession.firebase.database().ref(path)
  
  if (flag) {
    stateRef.set(userId)
    await CloudClient.call_CFselectDAWStreamerForRoom(
      s.screeningRoomConnection.spotterfishSession.firebase,
      Constants.STATIC_AUDIO_STRING,
      s.screeningRoomConnection.sharedStateID
    )
    return await CloudClient.call_CFCreateWhipEndpoint(s.screeningRoomConnection.spotterfishSession.firebase, { roomId: s.screeningRoomConnection.sharedStateID, format })
  }
  else {
    stateRef.set(false)
    return await CloudClient.call_CFDestroyWhipEndpoint(s.screeningRoomConnection.spotterfishSession.firebase, { roomId: s.screeningRoomConnection.sharedStateID})
  }
}

  // TODO: Move this function somewhere else?
//  NOTICE: This is unsafe data directly from outside world = validate
//  Timecode data is 12 bytes added to end of WebRTC audio frame ALWAYS.
//  -----------------------------------C--------    C: 
//  -------------------------------<012345678901>
//  chunk data: <aaaaaaa...aaaaaaa><bbbbbbbbbbbb>
function unpackDAWStreamerPackage (chunk) {
  assert(isObjectInstance(chunk))

  if(chunk.data.byteLength >= Constants.SPOTTERFISH_TIMECODE_BYTELENGTH) {
    const mtcBuffer = chunk.data.slice(chunk.data.byteLength - Constants.SPOTTERFISH_TIMECODE_BYTELENGTH, chunk.data.byteLength)
    assert(SpotterfishCore.isObjectInstance(mtcBuffer))
    assert(mtcBuffer.byteLength === Constants.SPOTTERFISH_TIMECODE_BYTELENGTH)

    //??? Add more runtime validation to syncData.
    const syncData = SyncData.unpackSyncData(mtcBuffer)
    assert(SyncData.checkSyncData(syncData))
    return syncData
  }
  else {
    return undefined
  }
}

function onDAWStreamPackage (screeningRoomSession, packageTimestamp, syncData, dawstreamingClientUser, jitterbufferOffset, timeStamp) {
  assert(checkInvariantScreeningRoomSession(screeningRoomSession))
  assert(SyncData.checkSyncData(syncData))
  assert(isObjectInstance(dawstreamingClientUser))

  const isDAWStreamSelected = screeningRoomSession.masterClock.mediaSyncSource === 'daw_streamer'
  const isPackagedFromSelectedStreamer = (dawstreamingClientUser.uid === screeningRoomSession.screeningRoomConnection.refinedSharedState.streaming_user)
  const clock = getClockWithDefault(screeningRoomSession)
  if(isPackagedFromSelectedStreamer && isDAWStreamSelected) {
    // offset needs to be calculated from the clock. Offset in video file is deprecated 2022-09->
    const offset = clock.timecodeOffsetSeconds !== undefined ? clock.timecodeOffsetSeconds : clock.videoFileOffset
    MasterClock.onDAWStreamPackage(
      screeningRoomSession.masterClock,
      syncData,
      offset,
      jitterbufferOffset,
      timeStamp
    )

    screeningRoomSession.dawPackageTimestamp = packageTimestamp
  }
}

function onWhipStreamPackage (screeningRoomSession, syncPackage) {
  assert(checkInvariantScreeningRoomSession(screeningRoomSession))
  assert(SyncData.checkSyncData(syncPackage.syncData))

  const clock = getClockWithDefault(screeningRoomSession)
  
  const whipOffsetFrames = syncPackage.syncOffset ? syncPackage.syncOffset - 40 : - 40

  MasterClock.onWhipStreamPackage(
    screeningRoomSession.masterClock,
    syncPackage,
    clock.timecodeOffsetSeconds,
    whipOffsetFrames
  )
}

function onVideoPlayerTimeUpdate(screeningRoomSession){
  assert(checkInvariantScreeningRoomSession(screeningRoomSession))

  MasterClock.onVideoPlayerTimeUpdate(screeningRoomSession.masterClock)
}

function getClockWithDefault (screeningRoomSession) {
  assert(checkInvariantScreeningRoomSession(screeningRoomSession))

  const m = screeningRoomSession.masterClock
  const fs = screeningRoomSession.screeningRoomConnection.refinedSharedState && screeningRoomSession.screeningRoomConnection.refinedSharedState.fileStateXL ? screeningRoomSession.screeningRoomConnection.refinedSharedState.fileStateXL : undefined
  return MasterClock.getClockValue(
    m,
    fs !== undefined ? fs.frameRateKey : undefined,
    fs !== undefined ? fs.offset : 0,
    fs !== undefined ? fs.timecodeOffsetSeconds : 0
  )
}

function getClockFromPackage (screeningRoomSession, syncPackage) {
  assert(checkInvariantScreeningRoomSession(screeningRoomSession))

  const m = screeningRoomSession.masterClock
  const fs = syncPackage
  return MasterClock.getClockValue(
    m,
    fs !== undefined ? Timecode.mtcFrameRateIndexToKey(fs.frameRateIndex) : undefined,
    fs !== undefined ? 0 : 0,
    fs !== undefined ? 0 : 0
  )
}



async function updateUserSharedState(s, value){
  assert(checkInvariantScreeningRoomSession(s))
  assert(SpotterfishCore.isObjectInstance(value))

  ScreeningRoomConnection.updateUserSharedState2(s.screeningRoomConnection, value)
}



async function updateRoomSharedState(s, value){
  assert(checkInvariantScreeningRoomSession(s))
  assert(SpotterfishCore.isObjectInstance(value))

  const screeningRoomID = s.screeningRoomConnection.screeningRoomDBCopy['.key']
  
  await new Promise((resolve, reject) => {
    s.screeningRoomConnection.spotterfishSession.firebase.database().ref(`shared_state/${ screeningRoomID }`)
    .update(value)
    .then(() => { resolve(undefined) })
    .catch((error) => {
      reject(error)
    })
  })
}

async function setUserMute(s, muteFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(muteFlag))

  updateUserSharedState(s, { audio_muted: muteFlag })
}

async function setSeekingStatus(s, seekFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(seekFlag))

  updateUserSharedState(s, { seeking: seekFlag })
}

async function setBufferingFlag(s, bufferingFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(bufferingFlag))

  updateUserSharedState(s, { buffering: bufferingFlag })
}

async function setCanPlayThroughFlag(s, canPlayThroughFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(canPlayThroughFlag))

  updateUserSharedState(s, { can_play_through: canPlayThroughFlag })
}


async function deleteMarkerLane(s, versionID, markerLaneID){
  await new Promise((resolve, reject) => {
    s.screeningRoomConnection.spotterfishSession.firestore.collection('versions').doc(versionID)
    .update({ lanes: s.screeningRoomConnection.spotterfishSession.firebase.firestore.FieldValue.arrayRemove(markerLaneID) })
    .then(() => { resolve(undefined) })
    .catch((err) => {
      reject(err)
    })
  })
}

async function enableTransportFeature(s, showFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(showFlag))

  updateRoomSharedState(s, { transport_controls_enabled: showFlag })
}


async function enableMarkerListFeature(s, showFlag){
  assert(checkInvariantScreeningRoomSession(s))
  assert(_.isBoolean(showFlag))

  updateRoomSharedState(s, { marker_list_enabled: showFlag })
}

// unused, may be used later
// async function enableKeepRoomAlive(s, flag){
//   assert(checkInvariantScreeningRoomSession(s))
//   assert(_.isBoolean(flag))

//   const screeningRoomID = s.screeningRoomDB['.key']
  
//   await new Promise((resolve, reject) => {
//     srs.screeningRoomConnection.spotterfishSession.firebase.database().ref(`shared_state/${ screeningRoomID }/users/keep_room_alive`)
//     .update(
//       { active: flag,  
//         timeStamp: Date.now()
//       }
//     )
//     .then(() => { resolve(undefined) })
//     .catch((error) => {
//       reject(error)
//     })
//   })
// }

// used to initialize Janus with proper AV settings, not exported
function getRoomFeatureBits0(screeningRoomDB, refinedSharedState){

  assert(ScreeningRoomConnection.checkInvariantScreeningRoomDB(screeningRoomDB))

  if(screeningRoomDB && refinedSharedState){
    const roomId = screeningRoomDB.journal_room_id

    assert(refinedSharedState.ufb !== undefined)
    assert(refinedSharedState.ufb.rooms !== undefined)

    const roomFB = refinedSharedState.ufb.rooms[roomId]
    return roomFB
  }
  else {
    return undefined
  }
}

function getRoomFeatureBits(s){
  // ??? MZ: Should use getUFBRooms() instead of accessing .rooms directly. I don't know
  // how to access getUFBRooms() here. This code should work anyways as written.

  assert(checkInvariantScreeningRoomSession(s))

  if(s.screeningRoomConnection && s.screeningRoomConnection.screeningRoomDBCopy && s.screeningRoomConnection.refinedSharedState){
    const roomId = s.screeningRoomConnection.screeningRoomDBCopy.journal_room_id

    assert(s.screeningRoomConnection.refinedSharedState.ufb !== undefined)
    assert(s.screeningRoomConnection.refinedSharedState.ufb.rooms !== undefined)

    const roomFB = s.screeningRoomConnection.refinedSharedState.ufb.rooms[roomId]
    return roomFB
  }
  else {
    return undefined
  }
}


function getScreeningRoomOwner(srs){
  assert(checkInvariantScreeningRoomSession(srs))

  return srs.screeningRoomConnection.screeningRoomDBCopy.owner
}

//  Used to detect first-access to room.
function getScreeningRoomProjectLoadCount(srs){
  assert(checkInvariantScreeningRoomSession(srs))

  const projectLoadCount = srs.screeningRoomConnection.screeningRoomDBCopy.project_load_count
  return projectLoadCount === undefined ? 0 : projectLoadCount
}

async function getVersionMarkerLanes(firestoreDB, versionID) {
  try {
    const versionRef = firestoreDB.collection('versions').doc(versionID)
    const versionData = await versionRef.get()
    
    if (!versionData.exists) {
      console.debug(`Version with ID ${versionID} doesn't exist.`)
      return []
    }
    
    console.debug('got version:', versionData.data())
    
    const lanes = versionData.data().lanes
    
    if (!lanes || lanes.length === 0) {
      return []
    }
    
    const lanesArray = await getMarkerLanesInArray(firestoreDB, lanes)
    
    return lanesArray
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}


async function getMarkerLanesInArray(firestoreDB, markerLaneIDs) {
  try {
    const tempArray = []
    const promises = []

    for (let i = 0; i < markerLaneIDs.length; i++) {
      let markerLaneId

      if (typeof markerLaneIDs[i] !== 'string') {
        if (Array.isArray(markerLaneIDs[i].markers) && 
            markerLaneIDs[i].markers.length > 0 && 
            markerLaneIDs[i].markers[0] && 
            typeof markerLaneIDs[i].markers[0] === 'object') {
          markerLaneId = markerLaneIDs[i].markers[0].original_parent_marker_lane
        } else {
          markerLaneId = undefined
        }
      } else {
        markerLaneId = markerLaneIDs[i]
      }

      if (markerLaneId) {
        const markerLanePromise = firestoreDB.collection('marker_lanes').doc(markerLaneId).get().then((markerLane) => {
          if (markerLane.exists) {
            const data = markerLane.data()
            data['.key'] = markerLane.id
            data.uid = markerLane.id
            tempArray.unshift(data)
          }
        })

        promises.push(markerLanePromise)
      }
    }

    await Promise.all(promises)

    return tempArray
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}

async function listenForMarkers(srs, firestoreDB, markerLaneID, callback) {
  const markersArray = []
  
  srs.screeningRoomConnection.markerListener()

  try {
    srs.screeningRoomConnection.markerListener = firestoreDB
      .collection('markers')
      .where('original_parent_marker_lane', '==', markerLaneID)
      .onSnapshot(snapshot => {
        console.debug('Realtime listener change')
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const addedMarker = change.doc.data()
            if (!addedMarker.tc_pos || typeof addedMarker.tc_pos !== 'string') { 
              console.log('Marker error, skipping', addedMarker)
              return 
            }
            addedMarker['.key'] = change.doc.id

            if (change.doc.data().created_date && typeof change.doc.data().created_date.toDate === 'function') {
              addedMarker.created_date = change.doc.data().created_date.toDate()
            }
            if (change.doc.data().updated_date && typeof change.doc.data().updated_date.toDate === 'function') {
              addedMarker.updated_date = change.doc.data().updated_date.toDate()
            }

            markersArray.push(addedMarker)
          }
          if (change.type === 'modified') {
            const index = markersArray.findIndex(x => x['.key'] === change.doc.id)
            const changeMarker = change.doc.data()
            changeMarker['.key'] = change.doc.id
            console.log({changeMarker})

            if (change.doc.data().created_date && typeof change.doc.data().created_date.toDate === 'function') {
              changeMarker.created_date = change.doc.data().created_date.toDate()
            }

            if (change.doc.data().updated_date && typeof change.doc.data().updated_date.toDate === 'function') { 
              changeMarker.updated_date = change.doc.data().updated_date.toDate()
            }
            
            if (!changeMarker.tc_pos || typeof changeMarker.tc_pos !== 'string') { 
              console.log('Marker error, skipping', changeMarker)
              return 
            }
            markersArray.splice(index, 1, changeMarker)
          }
          if (change.type === 'removed') {
            const index = markersArray.findIndex(x => x['.key'] === change.doc.id)
            markersArray.splice(index, 1)
          }
        })

        callback(markersArray)
      })
  } catch (error) {
    console.error('listenForMarkers snapshot error', error)
    throw error
  }
}


function runUnitTests () {
  console.log('ScreeningRoomSession.js -- START')

  const testMinimalScreeningRoomObject = {
    '.key': 'screeningRoomId1',
    'current_project': '',
    'marker_lane': '',
    'name': '',
    'owner': '',
    'people_seated': [],
    'people_with_moderator_key': [],
    'project_people': [],
    'requires_email_verification': false,
    'requires_room_pin': false,
    'requires_two_factor_auth': false,
    'daw_streaming_enabled': false,
    'seatings': [
      'uid',
      false,
      false,
      false,
      false
    ],
    'seats': 5,
    'video_room_server': ''
  }

  const testRegularScreeningRoomObject = {
    '.key': 'screeningRoomId2',
    'current_project': '',
    'marker_lane': '',
    'name': 'Test Screening Room',
    'owner': '9aQ7gFuMhRSop9Ohpdd4s93MUmV2',
    'people_seated': ['9aQ7gFuMhRSop9Ohpdd4s93MUmV2', 'iYAN3DueeLQX0yetskIDWbzUEW32'],
    'people_with_moderator_key': ['9aQ7gFuMhRSop9Ohpdd4s93MUmV2'],
    'project_people': ['9aQ7gFuMhRSop9Ohpdd4s93MUmV2', 'iYAN3DueeLQX0yetskIDWbzUEW32'],
    'requesting_access': ['abc'],
    'requires_email_verification': false,
    'requires_room_pin': false,
    'requires_two_factor_auth': false,
    'daw_streaming_enabled': false,
    'seatings': [
      '9aQ7gFuMhRSop9Ohpdd4s93MUmV2',
      'iYAN3DueeLQX0yetskIDWbzUEW32',
      false,
      false,
      false
    ],
    'seats': 5,
    'video_room_server': ''
  }

  assert(ScreeningRoomConnection.checkInvariantScreeningRoomDB(testMinimalScreeningRoomObject))
  assert(ScreeningRoomConnection.checkInvariantScreeningRoomDB(testRegularScreeningRoomObject))

  let users

  users = getUsersInLobby(testMinimalScreeningRoomObject)
  assert(Array.isArray(users))
  assert(users.length === 0)

  users = getUsersInLobby(testRegularScreeningRoomObject)
  assert(Array.isArray(users))
  assert(users.length === 1)
  console.log('ScreeningRoomSession.js -- END')
}


function checkInvariantScreeningRoomSessionDB (val) {
  assert(SpotterfishCore.isVueReactive(val) === false)
  assert(SpotterfishCore.isObjectInstance(val))
  return true
}

function proxy_checkInvariantFileStateXL (file) {
  return ScreeningRoomConnection.checkInvariantFileStateXL(file)
}

function proxy_checkInvariantRefinedSharedState (s) {
  return ScreeningRoomConnection.checkInvariantRefinedSharedState(s)
}
module.exports = {
  checkInvariantScreeningRoomSession,
  checkInvariantScreeningRoomSessionDB,

  getUsersInLobby,
  getUsersInLobby2,

  decryptSecret,
  encryptSecret,

  openScreeningRoomSession,
  closeScreeningRoomSession,
  updateScreeningRoomSession,
  setScreeningRoomDBChangedCallback,
  setSharedStateChangedCallback,

  proxy_checkInvariantFileStateXL,
  proxy_checkInvariantRefinedSharedState,

  generateURLtoScreeningRoom,
  getInviterNameFromURLparams,
  checkIfScreeningRoomExists,
  getStreamingUsersArrayFromActiveStreamers,
  setProjectToError,
  ingestLiveStream,

  unpackDAWStreamerPackage,
  onDAWStreamPackage,

  onWhipStreamPackage,

  onVideoPlayerTimeUpdate,
  getClockWithDefault,

  setUserMute,
  setSeekingStatus,
  setBufferingFlag,
  setCanPlayThroughFlag,
  deleteMarkerLane,
  enableTransportFeature,
  getRoomFeatureBits0,
  enableMarkerListFeature,
  getRoomFeatureBits,
  getScreeningRoomOwner,
  getScreeningRoomProjectLoadCount,

  updateRoomSharedState,
  updateUserSharedState,

  getVersionMarkerLanes,

  listenForMarkers,

  runUnitTests
}