<template>
  <v-app style="background: black;" :class="isMobilePhone ? 'mobile-style' :  'none'">
  <v-app-bar app v-if="!isMobilePhone" ref="toolbar" fixed clipped-left clipped-right :style="{background: colors.black}" >
    <img style="width: 158px" :src="logos.mixStageLogoWithWhiteText" />
    <v-spacer></v-spacer>
  </v-app-bar>
  <v-layout justify-center>
    <v-flex xs12 sm10 md6 lg3 xl3 id="login" style="margin: auto;" :class="isMobilePhone ? 'mobile-style' :  'none'">
      <v-card class="elevation-0" style="background: #EEEEEE; margin: auto; margin-top: 0; border-radius: 6px; color: black;" :class="isMobilePhone ? 'mobile-style' :  'none'">
        <v-card-text>
          <v-layout justify-center>
            <v-flex xs12>
              <form>  
                <v-layout 
                  wrap
                  align-center
                  justify-center
                  justify-content-center>
                  <v-flex xs12 style="color: black; font-size: 16px; margin: 20px 0 20px 0;">You were invited by <strong>{{inviter}}</strong></v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs10>
                        <v-text-field
                          :disabled="spinner"
                          ref="nicknameField"
                          persistent-hint
                          outlined
                          color="#919191"
                          light
                          dense
                          text-align="center"
                          hide-details
                          autocomplete="off"
                          class="mt-6 centered-input"
                          style="color: black;"
                          type="text"
                          v-model="nickname"
                          placeholder="Nickname"
                          @keyup.enter="joinAnonymously()"/>
                      </v-flex>
                      <v-flex xs8>
                        <v-btn
                          dark
                          large
                          style="width: 100%"
                          :style="{ background: spinner || nickname === '' ? '#aaa !important' : colors.mainAccent }"
                          class="lighten-2 mt-5 elevation-5"
                          @click="joinAnonymously"
                          :disabled="spinner">
                          Join
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </form>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-overlay v-model="spinner" width=100 rounded persistent style="z-index: 1010;">
      <v-progress-circular
        :size="100"
        :width="7"
        :color="colors.mainAccent"
        indeterminate>
      </v-progress-circular>
    </v-overlay>
<alert-component ref="alert"></alert-component>
</v-flex>
</v-layout>
</v-app>
</template>

<script>
// @ts-nocheck

import App from '@/App.vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import { isMobile } from 'mobile-device-detect'

import alert from '@/components/UI/alert.vue'
import ScreeningRoomSession from '@/../source_files/web_client/ScreeningRoomSession'
import CloudClient from '@/../source_files/web_client/CloudClient'

export default {
  name: 'quicklogin',
  components: {
    'alert-component': alert,
  },
  data: function () {
    return {
      colors: require('@/lib/ui/colors.js').colors,
      inviter: '',
      logos: require('@/lib/ui/logos.js').logos,
      nickname: '',
      spinner: false,
    }
  },

  computed: {
    currentUser () {
      return App.spotterfishSession.userSession && App.spotterfishSession.userSession.firebaseCurrentUser
    },
    isMobilePhone () {
      return isMobile
    }
  },

  methods: {
   
    async joinAnonymously () {
      this.spinner = true
      try {
        const userCredential = await App.Firebase.auth().signInAnonymously()
        userCredential.user.updateProfile({
          displayName: this.nickname
        })
      } catch (error) {
        this.spinner = false
        this.$refs.alert.open(
          `Error`, {
            line1: error.message
          },
          { color: this.colors.mainAccent })
          .then((confirm) => {
            if (confirm) {
              window.location.reload()
            }
          })
      }
    },

    async onFBAuthstateCHanged() {
      App.Firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          console.log(`User is signed in as anonymous user ${user}`)
          let queryData
          
          try {
            queryData = await ScreeningRoomSession.decryptSecret(this.$route.query.hash)
          } 
          catch (error) {
            await this.$refs.alert.open('Error', {
              line1: `${error.message}`
            },
            { color: this.colors.mainAccent })
          }

          store.commit('setLoggedInState', true)
          store.commit('setCurrentLoggedInUser', user)

          try {
            const roomId = queryData.sroom
            console.log(`Anonymous user will try to move to room ${roomId}`)

            await CloudClient.call_CFaddAnonymousUserToRoom(App.Firebase, user.uid, roomId)
            this.$router.replace({ name: 'screeningroom', query: this.$route.query })
              .catch((e) => {
                console.log(e)
              })
            this.spinner = false
          } 
          catch (error) {

            this.spinner = false
            await this.$refs.alert.open('Error', {
              line1: `${error.message}`
            },
            { color: this.colors.mainAccent })
          }

        } else {
          console.log('User is not logged in')
        }
      })
    }
  },

  async beforeMount () {
    this.inviter = await ScreeningRoomSession.getInviterNameFromURLparams(this.$route.query.hash)
    this.$refs.nicknameField.focus()
    // this will react when firbase SDK says we are logged in
    this.onFBAuthstateCHanged()
  }
}
</script>

<style scoped>

/deep/ .centered-input input {
  text-align: center
}

img {
  float: none;
  width: 90%;
  max-width: 280px;
  margin: auto;
}

input {
  margin: 5px 0;
  width: 20%;
  min-width: 240px;
  height: 25px;
  padding: 15px;
  font-family: 'Roboto', sans-serif;
}

p {
  font-family: 'Roboto', sans-serif;
  margin-top: 40px;
  font-size: 14px;
}

p a {
  font-family: 'Roboto', sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.mobile-style {
  padding-top: 10px !important;
  margin-top: 0 !important;
  background: #eee !important;
  border: none !important;
}

</style>
