<template>
  <div class="info-wrapper">
    <div class="info-box">
      <div style="margin-bottom: 40px;">
        <h3>Detailed instructions</h3>
        <p>Click <a href="ProToolsLogicNuendo-Spotterfishv1.04.pdf" target="_blank">here</a> to download detailed instructions</p>
      </div>
      <div style="background-color: rgb(39, 39, 39); margin-bottom: 40px; padding: 1rem;">
      <h3>1. Audio</h3>
      <p>Select a virtual sound card or loopback device (PT aux, Blackhole etc) </p>
      <p>When stream is selected in room you will see the audio level moving.</p>
      </div>
      <div style="background-color: rgb(39, 39, 39); margin-bottom: 40px; padding: 1rem;">
      <h3>2. Midi</h3>
      <p>Select your MIDI Time Code input. (Apple IAC works well)</p>
      <p>When MTC is received you will see the counter move, and the frame rate will be detected. Use both MMC AND MTC</p>
      </div>
      <div style="background-color: rgb(39, 39, 39); padding: 1rem;">
      <h3>3. Compensation</h3>
      <p>- Use the 'OFFSET' setting to compensate for driver latency etc.</p>
      <p>- MTC transmits only 24, 25 or 30 fps, use 'PROJECT FRAMERATE' to convert to your desired format.</p>

      </div>
    </div>
  </div>

</template>

<script>
export default {

}

</script>

<style scoped lang="scss">
.info-wrapper{
  background-color: #1e1e1e;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-box{
  text-align: left;
}
</style>