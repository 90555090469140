const SpotterfishCore = require('./SpotterfishCore')

const assert = SpotterfishCore.assert
const unitTestAssert = SpotterfishCore.unitTestAssert

const compareValues = SpotterfishCore.compareValues
const isBooleanInstance = SpotterfishCore.isBooleanInstance


/*
function prove_getScreeningRoomSessionClientConfig () {
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(false, false), {
    isAudienceMember: false,
    showVideoChat: true,
    toobarEnabled: true,
    lightSwitchEnabled: true,
    readOnlyTimeline: false
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(false, true), {
    isAudienceMember: false,
    showVideoChat: false,
    toobarEnabled: true,
    lightSwitchEnabled: false,
    readOnlyTimeline: false
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(true, false), {
    isAudienceMember: true,
    showVideoChat: true,
    toobarEnabled: false,
    lightSwitchEnabled: false,
    readOnlyTimeline: true
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(true, true), {
    isAudienceMember: true,
    showVideoChat: false,
    toobarEnabled: false,
    lightSwitchEnabled: false,
    readOnlyTimeline: true
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(false, false), {
    isAudienceMember: false,
    showVideoChat: true,
    toobarEnabled: true,
    lightSwitchEnabled: true,
    readOnlyTimeline: false
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(false, true), {
    isAudienceMember: false,
    showVideoChat: false,
    toobarEnabled: true,
    lightSwitchEnabled: false,
    readOnlyTimeline: false
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(true, false), {
    isAudienceMember: true,
    showVideoChat: true,
    toobarEnabled: false,
    lightSwitchEnabled: false,
    readOnlyTimeline: true
  }))
  unitTestAssert(compareValues(getScreeningRoomSessionClientConfig(true, true), {
    isAudienceMember: true,
    showVideoChat: false,
    toobarEnabled: false,
    lightSwitchEnabled: false,
    readOnlyTimeline: true
  }))
}
*/
function runUnitTests () {
  console.log('ClientConfig.js -- START')

  //prove_getScreeningRoomSessionClientConfig()

  console.log('ClientConfig.js -- END')
}

module.exports = {
  // getScreeningRoomSessionClientConfig,
  runUnitTests
}
