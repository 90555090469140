const CloudClient = require('./CloudClient')

const initUserBusinessDataListener = (session, callbacks, userId) => {
  let previousAccount
  return session.firestoreDB.collection('user_business_data').doc(userId).onSnapshot(async () => {
    const result = await CloudClient.call_CFcalculateAccountFeatureBitsReadOnly(session.firebase)
    const accountTier = await CloudClient.call_CFgetAccountTier(session.firebase)
    const account = {
      accountTier,
      dawStreaming: result.ufb.user?.daw_streaming
    }
    session.userSession.ufb = result.ufb
    session.userSession.DBIDMapping = result.DBIDMapping
    // ...magic => data
    callbacks.forEach(fn => fn(account, previousAccount))

    previousAccount = {...account}
  })
}

const initRoomListener = (session, callbacks, userId) => {
  const rooms = []
  return session.firestoreDB
    .collection('screening_rooms')
    .where('project_people', 'array-contains', userId)
    .onSnapshot((querySnapshot) => {
      console.log('querySnapshot', querySnapshot.empty)
      if (!querySnapshot.empty) {
        console.log('Change in screening room')
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const room = change.doc.data()
            room['.key'] = change.doc.id
            rooms.push(room)
          }
          if (change.type === 'modified') {
            const index = rooms.findIndex(
              (x) => x['.key'] === change.doc.id
            )
            const room = change.doc.data()
            room['.key'] = change.doc.id
            rooms.splice(index, 1, room)
            rooms[index] = room
          }
          if (change.type === 'removed') {
            const index = rooms.findIndex(
              (x) => x['.key'] === change.doc.id
            )
            rooms.splice(index, 1)
          }            
        })
        callbacks.forEach(fn => fn(rooms))
      }
    }, (error) => {
      console.warn('user not logged in', error)
    })
}

const initActiveSessionsListener = (session, callbacks, userId, rooms) => {
  // Create an object to hold rooms with their .key values as the key for fast lookup
  const roomMap = Object.fromEntries(rooms.map(room => [room['.key'], room]))
  // Return the unsubscribe function for the Firestore listener
  return session.firestoreDB
    .collection('screening_sessions')
    .onSnapshot(
      (querySnapshot) => {
        const activeRooms = []
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const roomKey = doc.id
            console.log('session in room', roomKey, 'is active')
            // Check if room exists in roomMap
            if (roomMap.hasOwnProperty(roomKey)) {
              const room = { ...doc.data(), '.key': roomKey }
              activeRooms.push(roomKey)
            }
          })

          // Execute all callbacks with matching active rooms array
          callbacks.forEach((fn) => fn(activeRooms))
        }
      },
      (error) => {
        console.warn("user not logged in", error)
      }
    )
}


const initProjectListener = (session, callbacks, userId) => {
  let projects = []
  return session.firestoreDB
    .collection('projects')
    .where('project_people', 'array-contains', userId)
    .orderBy('created_date', 'asc')
    .onSnapshot((project) => {
      console.log('new snapshot', project)
      if (!project.empty) {
        project.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const aProject = change.doc.data()
            aProject['.key'] = change.doc.id
            if (aProject.removed !== true) {
              projects.push(aProject)
            }
          }
          if (change.type === 'modified') {
            const index = projects.findIndex(
              (x) => x['.key'] === change.doc.id
            )
            const changeProject = change.doc.data()
            changeProject['.key'] = change.doc.id
            if (changeProject.removed !== true) {
              projects.splice(index, 1, changeProject)
              projects[index] = changeProject
            }
          }
          if (change.type === 'removed') {
            const index = projects.findIndex(
              (x) => x['.key'] === change.doc.id
            )
            projects.splice(index, 1)
          }
        })
        callbacks.forEach(fn => fn(projects))
      } else {
        console.log('No project documents, resetting array!')
        projects = []
        callbacks.forEach(fn => fn([]))
      }
    }, (error) => {
      console.error('realtimeProjectsForUserListener snapshot error', error)
    })
}


const SessionListener = (session, userId) => {

  const add = (type, fn, clearListeners = false, rooms = []) => {
    const listener = listeners[type]
    if(!listener){
      console.warn(`No listener for type ${type}`)
      throw new Error('No listener')
    }
    if(clearListeners){
      listener.callbacks.clear()
    }
    if(listener.callbacks.size === 0 && listener.init){
      listener.listener = listener.init(session, listener.callbacks, userId, rooms)
    }
    listener.callbacks.set(fn, fn)
  }


  const listeners = {
    user_business_data: {
      init: initUserBusinessDataListener,
      callbacks: new Map(),
    },
    rooms: {
      init: initRoomListener,
      callbacks: new Map(),
    },
    projects: {
      init: initProjectListener,
      callbacks: new Map(),
    },
    activeSessions: {
      init: initActiveSessionsListener,
      callbacks: new Map(),
    }
  }

  const stopFirebaseListeners = () => {
    Object.values(listeners).forEach(listener => {
      if(typeof listener.listener === 'function'){
        listener.listener()
      }
    })
  }
  const stopFirebaseListener = (type) => {
    if(typeof listeners[type].listener === 'function'){
      listeners[type].listener()
    }
  }
  const remove = (type, id) => {
    listeners[type].callbacks.delete(id)
    if(listeners[type].callbacks.size === 0){
      // Stop listening on firebase if we have no callbacks
      listeners[type].listener?.()
    }
  }
  return {
    add,
    remove,
    stopFirebaseListeners,
    stopFirebaseListener,
  }
}

export default SessionListener