const SpotterfishCore = require('../SpotterfishCore')
const assert = SpotterfishCore.assert
const isDevelopment = SpotterfishCore.isDevelopment

let Midi = require('jsmidgen')

if (isDevelopment()) {
  // eslint-disable-next-line
  var expect = require('chai').expect
}


function toDeltaTime (
  newTimeInMicroseconds,
  oldTimeInMicroseconds,
  tempoInBpm = 120,
  ticksPerBeat = 9600) {
  /**
  *   Calculates Delta time from marker Time in seconds, tempo and ticksPerBeat
  *   deltaTime = seconds * tempoInBpm * ticksPerBeat / 60 (tempo in beats per second?)
  *   1s * 120bpm * 9600ticksPerBeat / 60 => 19200
  *   10s * 120bpm * 9600ticksPerBeat / 60 => 192000
  *   60s * 120bpm * 9600ticksPerBeat / 60 => 1152000
  *   3600s * 120bpm * 9600ticksPerBeat / 60 => 69120000
  **/
  // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
  if (isDevelopment()) {
    expect(newTimeInMicroseconds, 'in MIDIUtils/toDeltaTime() [newTime]').to.be.a('number')
    expect(oldTimeInMicroseconds, 'in MIDIUtils/toDeltaTime() [oldTime]').to.be.a('number')
    expect(newTimeInMicroseconds, 'in MIDIUtils/toDeltaTime() [newTime]').to.be.at.least(oldTimeInMicroseconds)
    expect(tempoInBpm, 'in MIDIUtils/toDeltaTime() [tempoInBpm]').to.be.a('number')
    expect(ticksPerBeat, 'in MIDIUtils/toDeltaTime() [ticksPerBeat]').to.be.a('number')
  }
  // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
  // Check difference between old and new marker time
  let differenceInSeconds = (newTimeInMicroseconds - oldTimeInMicroseconds) / 1000000
  // Creating delta time from difference in time and MIDI-settings
  let deltaTime = Math.round(differenceInSeconds * tempoInBpm * ticksPerBeat / 60)

  return {deltaTime: deltaTime, oldTimeInMicroseconds: newTimeInMicroseconds}
}

function jsonToMidi (songJson) {
  // Using jsmidgen to convert our JSON to MIDI
  /**
  *   Ecpects a JSON file containing markers and proper headers created with
  *   returns a javascript blob which can be written to a MIDI file
  **/
  // for docs etc: https://github.com/dingram/jsmidgen
  // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
  if (isDevelopment()) {
    expect(songJson, 'in MIDIUtils/jsonToMidi() [songJson]').to.include.keys([
      'header',
      'tracks'
    ])
    expect(songJson.header, 'in MIDIUtils/jsonToMidi() [songJson.header]').to.include.keys([
      'formatType',
      'ticksPerBeat',
      'trackCount'
    ])
  }
  // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
  let fileConfig = {
    ticks: songJson.header.ticksPerBeat
  }
  var file = new Midi.File(fileConfig)
  // We will have two tracks in our JSON, the first containing all our markers
  songJson.tracks.forEach(function (t) {
    var track = new Midi.Track()
    file.addTrack(track)
    // Looping through the events (markers etc) on our tracks
    t.forEach(function (event) {
      if (event.subtype === 'setTempo') {
        // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
        if (isDevelopment()) {
          expect(event, 'in MIDIUtils/jsonToMidi() [event]').to.include.keys([
            'deltaTime',
            'microsecondsPerBeat',
            'subtype',
            'type'
          ])
        }
        // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
        // This is the standard way of using tempo in midi,
        // AudioPostr might as well use a real tempo value?
        // Or calculate by 60000000 / bpm and insert perhaps,
        // in case we ever need to convert back from MIDI, i. e. import Pro Tools markers
        let microsecondsPerBeat = event.microsecondsPerBeat
        let microsecondsPerMin = 60000000
        let bpm = microsecondsPerMin / microsecondsPerBeat
        track.setTempo(bpm, event.deltaTime)
      } else if (event.subtype === 'marker') {
        // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
        if (isDevelopment()) {
          expect(event, 'in MIDIUtils/jsonToMidi() [event]').to.include.keys([
            'deltaTime',
            'text',
            'subtype',
            'type'
          ])
        }
        // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
        let MetaEvent = new Midi.MetaEvent({
          time: event.deltaTime,
          type: Midi.MetaEvent.MARKER,
          data: event.text
        })
        track.addEvent(MetaEvent)
      } else if (event.subtype === 'smpteOffset') {
        // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
        if (isDevelopment()) {
          expect(event, 'in MIDIUtils/jsonToMidi() [event]').to.include.keys([
            'deltaTime',
            'frame',
            'frameRate',
            'hour',
            'min',
            'sec',
            'subframe',
            'subtype',
            'type'
          ])
        }
        // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
        let MetaEvent = new Midi.MetaEvent({
          time: event.deltaTime,
          type: Midi.MetaEvent.SMPTE,
          data: [event.hour, event.min, event.sec, event.frame, event.subframe]
        })
        track.addEvent(MetaEvent)
      } else if (event.subtype === 'trackName') {
        // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
        if (isDevelopment()) {
          expect(event, 'in MIDIUtils/jsonToMidi() [event]').to.include.keys([
            'deltaTime',
            'text',
            'subtype',
            'type'
          ])
        }
        // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
        let MetaEvent = new Midi.MetaEvent({
          time: event.deltaTime,
          type: Midi.MetaEvent.TRACK_NAME,
          data: event.text
        })
        track.addEvent(MetaEvent)
      } else if (event.subtype === 'endOfTrack') {
        // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
        if (isDevelopment()) {
          expect(event, 'in MIDIUtils/jsonToMidi() [event]').to.include.keys([
            'deltaTime',
            'subtype',
            'type'
          ])
        }
        // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
        let MetaEvent = new Midi.MetaEvent({
          time: event.deltaTime,
          type: Midi.MetaEvent.END_OF_TRACK
        })
        track.addEvent(MetaEvent)
      }
    })
  })
  // Our file now contains all of the MIDI data
  return file
}


function convertFileToBlobURLforWeb (file) {
  /**
  *   Converts a file created with jsmidgen to a javascript Blob url.
  *   Important findings below, WEB: we need an Uint8 typed array for this to work
  *   returns a url to the blob for download.
  **/
  let byteFile = file.toBytes()
  // We need a Uint8 typed array to store the blob in, to avoid UTF8 encoding.
  // Using a standard Javascript array will result in corrupted files
  var u8 = new Uint8Array(byteFile.length)

  for (var i = 0; i < byteFile.length; i++) {
    u8[i] = byteFile[i].charCodeAt(0)
  }
  // Now we write the typed array to the Blob instead of the string
  var blob = new Blob([u8], {
    type: 'audio/midi'
  })
  const blobUrl = URL.createObjectURL(blob)
  return blobUrl
}


function bpmToMicrosecondsPerBeat (bpm) {
  // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
  if (isDevelopment()) {
    expect(bpm, 'in MIDIUtils/bpmToMicrosecondsPerBeat() [bpm]').to.be.a('number')
  }
  // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
  let microsecondsPerMin = 60000000
  let microsecondsPerBeat = microsecondsPerMin / bpm
  return microsecondsPerBeat
}


function microsecondsPerBeatToBPM (microsecondsPerBeat) {
  // :::::::::::::::::::::::::::::ASSERT:::::::::::::::::::::::::::::::::::
  if (isDevelopment()) {
    expect(microsecondsPerBeat, 'in MIDIUtils/microsecondsPerBeatToBPM() [microsecondsPerBeat]').to.be.a('number')
  }
  // :::::::::::::::::::::::::::END ASSERT:::::::::::::::::::::::::::::::::
  let microsecondsPerMin = 60000000
  let bpm = microsecondsPerMin / microsecondsPerBeat
  return bpm
}


function runUnitTests () {
  console.log('MIDIUtils.js -- START')


  console.log('MIDIUtils.js -- END')
}

module.exports = {
  toDeltaTime,
  jsonToMidi,
  convertFileToBlobURLforWeb,
  bpmToMicrosecondsPerBeat,
  microsecondsPerBeatToBPM,
  runUnitTests
}

